import { API } from 'aws-amplify';
import Me from '../../../_types/Me';
import GET_LIST_KPI_RULES_BY_ORG from '../queries/GET_LIST_KPI_RULES_BY_ORG';

type ListKPIRulesByOrgApiResponse = {
  data: {
    listKPIRulesByOrg: {
      items: any;
    };
  };
};

const getKpis = async ({ me }: { me: Me }) => {
  const results = (await API.graphql({
    query: GET_LIST_KPI_RULES_BY_ORG,
    variables: {
      orgID: me.orgID,
    },
  })) as ListKPIRulesByOrgApiResponse;

  const newKpiList = results.data.listKPIRulesByOrg.items.map((kpi: any) => ({
    value: kpi.id,
    label: kpi.title,
    rule: kpi,
  }));

  // Sort by name
  const sortedKpiList = newKpiList.sort((a: any, b: any) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
    if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
    return 0;
  });

  return sortedKpiList;
};

export default getKpis;
