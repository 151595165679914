import { Storage } from 'aws-amplify';

export default class UserProfile {
  constructor(data) {
    this.data = data;
  }

  loadAvatar() {
    return Storage.get(this?.data?.me?.imageName);
  }
}
