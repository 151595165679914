import { API } from 'aws-amplify';
import gql from 'graphql-tag';

const fetchUserRolePosition = async (userID, teamID) => {
  try {
    // NOTE: This is for user-specific MemberType
    const getUserMemberTypeQuery = gql`
      query getUser($userID: ID = "f3104592-39d1-42f3-ab02-430c4200b9b8") {
        getUser(id: $userID) {
          id
          name
          functionalRoles
          role
          orgs {
            items {
              id
              memberType
              orgID
            }
          }
        }
      }
    `;

    // NOTE: This is for team-specific MemberRole
    const getUserMemberRoleQuery = gql`
      query circleMemberByUserID(
        $userID: ID = "60d9848f-a489-4287-9808-2bb347370d03"
      ) {
        circleMemberByUserID(userID: $userID) {
          items {
            id
            isActive
            memberType
            memberRole
            status
            createdAt
            userID
            circleID
          }
        }
      }
    `;

    // let user;
    const getUserMemberTypeRet = await API.graphql({
      query: getUserMemberTypeQuery,
      variables: { userID },
    });
    const user = getUserMemberTypeRet.data.getUser;

    const getUserMemberRoleRet = await API.graphql({
      query: getUserMemberRoleQuery,
      variables: { userID },
    });
    // console.log('fetchUserRolePosition memberRoleRet: ', getUserMemberRoleRet);
    const userCircleMembersArr =
      getUserMemberRoleRet.data.circleMemberByUserID.items;

    // use teamID to get the right memberRole for the right team out of all of the user's circleMember entries
    for (const circleMember of userCircleMembersArr) {
      // console.log(
      //   'fetchUserRolePosition circleMember.circleID === teamID: ',
      //   circleMember.circleID,
      //   teamID,
      //   circleMember.circleID === teamID,
      //   circleMember.id
      // );
      if (circleMember.circleID === teamID) {
        user.memberRole = circleMember.memberRole;
        user.circleMemberID = circleMember.id;
      }
    }

    for (const myOrg of user.orgs.items) {
      if (myOrg.orgID === global.me.orgID) {
        user.memberType = myOrg.memberType;
        user.orgMemberID = myOrg.id;
      }
    }

    // NOTE: user.functionalRoles is user-specific, the team-specific memberRole is what is used
    // const functionalRolesArr = user.functionalRoles;

    // // Format the functional role
    // let userFunctionalRole = '';
    // if (functionalRolesArr) {
    //   for (const role of functionalRolesArr) {
    //     // Skip if blank role
    //     if (role === '') continue;

    //     // Put & for multiple roles
    //     if (userFunctionalRole !== '') {
    //       userFunctionalRole += ' & ';
    //     }
    //     userFunctionalRole += role[0].toUpperCase() + role.slice(1);
    //   }
    // }

    // user.functionalRole = userFunctionalRole;

    const memberType = user.orgs?.items[0]?.memberType;
    if (memberType) user.memberType = memberType;

    return user;
  } catch (err) {
    console.error('fetchUserRolePosition error: ', err);
  }
};

export default fetchUserRolePosition;
