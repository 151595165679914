import React from 'react';
// import AdvanceOptions from '../new-game-inputs/AdvanceOptions';
import ImageThumbnail from '../new-game-inputs/ImageThumbnail';
import AddContestants from '../new-game-inputs/AddContestants';
import NewGameState from '../_types/NewGameState';
import GameImage from '../new-game-inputs/GameImage';
import BracketContestants from '../new-game-inputs/BracketContestants';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const Step3: React.FC<Props> = ({ newGameState, onChangeNewGameState }) => {
  if (newGameState.contestType === 'bracket_manual') {
    // NOTE: Because formik isn't used, newGameState needs to have rules key, can't use the missionRules key like on mobile. If this does get implemented on web eventaully, manual brackets will need to set values.rules from formik to the first roundValues's rules and other games will need values.rules set to values.missionRules
    // NOTE: If formik is implemented, can match the logic on mobile where values.rules is set to the missionRules which is set by logic commented out below
    // const missionRules =
    //   bracketData?.roundValues !== undefined &&
    //   bracketData?.roundValues.length > 0
    //     ? bracketData?.roundValues[0].rules
    //     : values.missionRules;

    // values.rules = missionRules;
    return (
      <>
        <BracketContestants
          newGameState={newGameState}
          onChangeNewGameState={onChangeNewGameState}
        />
        <GameImage
          newGameState={newGameState}
          onChangeNewGameState={onChangeNewGameState}
        />
      </>
    );
  }

  return (
    <>
      <AddContestants
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
      />
      <ImageThumbnail
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
      />
      {/* NOTE: Removed from Step 3 per GG-1045 */}
      {/* <AdvanceOptions
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
      /> */}
    </>
  );
};

export default Step3;
