import CreatePostArgs from '../_types/CreatePostArgs';
import circleFeedBlast from './circleFeedBlast';
import feedBlast from './feedBlast';

const createNewPost = async (
  args: CreatePostArgs & { assigneeType: string; assigneeID: string }
) => {
  const { assigneeType, assigneeID, ...commonParams } = args;

  if (assigneeType === 'circle') {
    await circleFeedBlast({
      circleID: assigneeID,
      ...commonParams,
    });
  } else {
    await feedBlast({
      userID: assigneeID,
      ...commonParams,
    });
  }
};

export default createNewPost;
