/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const findCircles = /* GraphQL */ `
  query FindCircles($orgID: String!, $queryString: String!) {
    findCircles(orgID: $orgID, queryString: $queryString) {
      id
      extID
      name
      description
      location
      scope
      inviterType
      approverType
      status
      isActive
      isHidden
      imageName
      imageType
      avatarImageName
      communityID
      startDate
      endDate
      launchDate
      orgID
      nextLaunchFeedBlast
      nextExpireFeedBlast
      permissions
      parentCircleID
      levelID
      tangoAccountID
      isLeague
      level {
        id
        name
        description
        levelNum
        orgID
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      org {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missions {
        items {
          id
          circleID
          orgID
          startDate
          endDate
          missionID
          isPaid
          currentPosition
          previousPosition
          rewardDisposition
          doneActionCount
          goalActionCount
          rewardCount
          trophiesAwarded
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          circleID
          status
          memberType
          priority
          circlePoints
          lifetimeCirclePoints
          isActive
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      rewards {
        items {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      parent {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      children {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      preferences {
        items {
          id
          orgID
          isHidden
          preferenceType
          name
          entityType
          entityID
          preferences
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listUserMissionsByInterval = /* GraphQL */ `
  query ListUserMissionsByInterval(
    $orgID: String!
    $interval: String!
    $circles: [String]!
    $startDate: String!
    $endDate: String!
  ) {
    listUserMissionsByInterval(
      orgID: $orgID
      interval: $interval
      circles: $circles
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const listUserMissionsByIntervalMissionFilter = /* GraphQL */ `
  query ListUserMissionsByIntervalMissionFilter(
    $orgID: String!
    $missionID: String!
    $interval: String!
    $circles: [String]!
    $startDate: String!
    $endDate: String!
  ) {
    listUserMissionsByIntervalMissionFilter(
      orgID: $orgID
      missionID: $missionID
      interval: $interval
      circles: $circles
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const listUserMissionsCircleSummary = /* GraphQL */ `
  query ListUserMissionsCircleSummary(
    $orgID: String!
    $interval: String!
    $circles: [String]!
    $startDate: String!
    $endDate: String!
  ) {
    listUserMissionsCircleSummary(
      orgID: $orgID
      interval: $interval
      circles: $circles
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const summarizeKPIForUser = /* GraphQL */ `
  query SummarizeKPIForUser(
    $orgID: String!
    $interval: String!
    $startDate: String!
    $endDate: String!
    $KPIRuleID: String!
    $userID: String!
  ) {
    summarizeKPIForUser(
      orgID: $orgID
      interval: $interval
      startDate: $startDate
      endDate: $endDate
      KPIRuleID: $KPIRuleID
      userID: $userID
    )
  }
`;
export const summarizeKPIForCircle = /* GraphQL */ `
  query SummarizeKPIForCircle(
    $orgID: String!
    $interval: String!
    $startDate: String!
    $endDate: String!
  ) {
    summarizeKPIForCircle(
      orgID: $orgID
      interval: $interval
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const summarizeKPIForAll = /* GraphQL */ `
  query SummarizeKPIForAll(
    $orgID: String!
    $interval: String!
    $startDate: String!
    $endDate: String!
    $KPIRuleID: String!
  ) {
    summarizeKPIForAll(
      orgID: $orgID
      interval: $interval
      startDate: $startDate
      endDate: $endDate
      KPIRuleID: $KPIRuleID
    )
  }
`;
export const userKPIForOrgES = /* GraphQL */ `
  query UserKPIForOrgES(
    $orgID: String!
    $interval: String!
    $startDate: String!
    $endDate: String!
  ) {
    userKPIForOrgES(
      orgID: $orgID
      interval: $interval
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const userMissionForOrgES = /* GraphQL */ `
  query UserMissionForOrgES(
    $orgID: String!
    $interval: String!
    $startDate: String!
    $endDate: String!
  ) {
    userMissionForOrgES(
      orgID: $orgID
      interval: $interval
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const userKPIForUserES = /* GraphQL */ `
  query UserKPIForUserES(
    $userID: String!
    $interval: String!
    $startDate: String!
    $endDate: String!
  ) {
    userKPIForUserES(
      userID: $userID
      interval: $interval
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const userMissionForUserES = /* GraphQL */ `
  query UserMissionForUserES(
    $userID: String!
    $interval: String!
    $startDate: String!
    $endDate: String!
  ) {
    userMissionForUserES(
      userID: $userID
      interval: $interval
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const listAddressesInPolygon = /* GraphQL */ `
  query ListAddressesInPolygon(
    $orgID: String!
    $polygon: AWSJSON!
    $size: Int
    $from: Int
  ) {
    listAddressesInPolygon(
      orgID: $orgID
      polygon: $polygon
      size: $size
      from: $from
    )
  }
`;
export const listAddressesInRadius = /* GraphQL */ `
  query ListAddressesInRadius(
    $orgID: String!
    $distance: String!
    $lat: Float!
    $lon: Float!
    $size: Int
    $from: Int
  ) {
    listAddressesInRadius(
      orgID: $orgID
      distance: $distance
      lat: $lat
      lon: $lon
      size: $size
      from: $from
    )
  }
`;
export const listAllDeals = /* GraphQL */ `
  query ListAllDeals($request: AWSJSON!) {
    listAllDeals(request: $request)
  }
`;
export const getLiveStreamInfoAPI = /* GraphQL */ `
  query GetLiveStreamInfoAPI($getLiveStreamInfoAPI: ID!) {
    getLiveStreamInfoAPI(getLiveStreamInfoAPI: $getLiveStreamInfoAPI)
  }
`;
export const getLeaderboards = /* GraphQL */ `
  query GetLeaderboards($request: AWSJSON!) {
    getLeaderboards(request: $request)
  }
`;
export const getLeaderboard = /* GraphQL */ `
  query GetLeaderboard($request: AWSJSON!) {
    getLeaderboard(request: $request)
  }
`;
export const getDispostionforDeals = /* GraphQL */ `
  query GetDispostionforDeals($request: AWSJSON!) {
    getDispostionforDeals(request: $request)
  }
`;
export const getMyInfo = /* GraphQL */ `
  query GetMyInfo($request: AWSJSON!) {
    getMyInfo(request: $request)
  }
`;
export const getKPILeaderboard = /* GraphQL */ `
  query GetKPILeaderboard($request: AWSJSON!) {
    getKPILeaderboard(request: $request)
  }
`;
export const getPinAddress = /* GraphQL */ `
  query GetPinAddress($lat: String!, $lon: String!) {
    getPinAddress(lat: $lat, lon: $lon) {
      id
      orgID
      street
      address
      city
      region
      country
      postalCode
      location {
        lat
        lon
      }
      lastVisited
      nextVisit
      currentOwnerID
      lastVisitedByID
      lastVisitedByName
      currentDispositionID
      status
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const getBracketActuals = /* GraphQL */ `
  query GetBracketActuals($missionID: ID!) {
    getBracketActuals(missionID: $missionID)
  }
`;
export const getDashboard = /* GraphQL */ `
  query GetDashboard($dashboardID: ID) {
    getDashboard(dashboardID: $dashboardID)
  }
`;
export const getLesson = /* GraphQL */ `
  query GetLesson($id: ID!) {
    getLesson(id: $id) {
      id
      orgID
      name
      description
      isDeleted
      startDate
      endDate
      details {
        items {
          id
          lessonID
          orgID
          name
          description
          order
          youTubeURL
          flexFields
          startDate
          endDate
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listLessons = /* GraphQL */ `
  query ListLessons(
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        name
        description
        isDeleted
        startDate
        endDate
        details {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLessonDetail = /* GraphQL */ `
  query GetLessonDetail($id: ID!) {
    getLessonDetail(id: $id) {
      id
      lessonID
      orgID
      name
      description
      order
      youTubeURL
      flexFields
      startDate
      endDate
      isDeleted
      assignments {
        items {
          id
          lessonDetailID
          orgID
          assignedToID
          assignedToType
          flexAttributes
          startedTmst
          completedTmst
          percentComplete
          isStarted
          isComplete
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      lesson {
        id
        orgID
        name
        description
        isDeleted
        startDate
        endDate
        details {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listLessonDetails = /* GraphQL */ `
  query ListLessonDetails(
    $filter: ModelLessonDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessonDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lessonID
        orgID
        name
        description
        order
        youTubeURL
        flexFields
        startDate
        endDate
        isDeleted
        assignments {
          nextToken
        }
        lesson {
          id
          orgID
          name
          description
          isDeleted
          startDate
          endDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLessonAssignment = /* GraphQL */ `
  query GetLessonAssignment($id: ID!) {
    getLessonAssignment(id: $id) {
      id
      lessonDetailID
      orgID
      assignedToID
      assignedToType
      flexAttributes
      startedTmst
      completedTmst
      percentComplete
      isStarted
      isComplete
      isDeleted
      lessonDetail {
        id
        lessonID
        orgID
        name
        description
        order
        youTubeURL
        flexFields
        startDate
        endDate
        isDeleted
        assignments {
          nextToken
        }
        lesson {
          id
          orgID
          name
          description
          isDeleted
          startDate
          endDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listLessonAssignments = /* GraphQL */ `
  query ListLessonAssignments(
    $filter: ModelLessonAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessonAssignments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonDetailID
        orgID
        assignedToID
        assignedToType
        flexAttributes
        startedTmst
        completedTmst
        percentComplete
        isStarted
        isComplete
        isDeleted
        lessonDetail {
          id
          lessonID
          orgID
          name
          description
          order
          youTubeURL
          flexFields
          startDate
          endDate
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCreditCardAuths = /* GraphQL */ `
  query GetCreditCardAuths($id: ID!) {
    getCreditCardAuths(id: $id) {
      id
      orgID
      amount
      amountCharged
      feePercent
      referenceDepositID
      status
      creditCardToken
      userID
      creditCardLabel
      lastFourDigits
      expirationDate
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listCreditCardAuthss = /* GraphQL */ `
  query ListCreditCardAuthss(
    $filter: ModelcreditCardAuthsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreditCardAuthss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        amount
        amountCharged
        feePercent
        referenceDepositID
        status
        creditCardToken
        userID
        creditCardLabel
        lastFourDigits
        expirationDate
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExportableData = /* GraphQL */ `
  query GetExportableData($id: ID!) {
    getExportableData(id: $id) {
      id
      name
      description
      allowStartDate
      allowEndDate
      allowAll
      allowChangeEmail
      createdAt
      updatedAt
    }
  }
`;
export const listExportableDatas = /* GraphQL */ `
  query ListExportableDatas(
    $filter: ModelExportableDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExportableDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        allowStartDate
        allowEndDate
        allowAll
        allowChangeEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExportedData = /* GraphQL */ `
  query GetExportedData($id: ID!) {
    getExportedData(id: $id) {
      id
      orgID
      exportDataID
      lastExportTmst
      exportableData {
        id
        name
        description
        allowStartDate
        allowEndDate
        allowAll
        allowChangeEmail
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listExportedDatas = /* GraphQL */ `
  query ListExportedDatas(
    $filter: ModelExportedDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExportedDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        exportDataID
        lastExportTmst
        exportableData {
          id
          name
          description
          allowStartDate
          allowEndDate
          allowAll
          allowChangeEmail
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExportedDataHistory = /* GraphQL */ `
  query GetExportedDataHistory($id: ID!) {
    getExportedDataHistory(id: $id) {
      id
      orgID
      exportDataID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listExportedDataHistorys = /* GraphQL */ `
  query ListExportedDataHistorys(
    $filter: ModelExportedDataHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExportedDataHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        exportDataID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getServiceArea = /* GraphQL */ `
  query GetServiceArea($id: ID!) {
    getServiceArea(id: $id) {
      id
      orgID
      name
      description
      coords {
        lat
        lon
      }
      zipCodes
      calendars {
        items {
          id
          orgID
          scheduleDate
          areaID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listServiceAreas = /* GraphQL */ `
  query ListServiceAreas(
    $filter: ModelServiceAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        name
        description
        coords {
          lat
          lon
        }
        zipCodes
        calendars {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getCalendar = /* GraphQL */ `
  query GetCalendar($id: ID!) {
    getCalendar(id: $id) {
      id
      orgID
      scheduleDate
      areaID
      area {
        id
        orgID
        name
        description
        coords {
          lat
          lon
        }
        zipCodes
        calendars {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      slots {
        items {
          id
          orgID
          calendarID
          startTime
          endTime
          available
          scheduled
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listCalendars = /* GraphQL */ `
  query ListCalendars(
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        scheduleDate
        areaID
        area {
          id
          orgID
          name
          description
          zipCodes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        slots {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getCalendarSlot = /* GraphQL */ `
  query GetCalendarSlot($id: ID!) {
    getCalendarSlot(id: $id) {
      id
      orgID
      calendarID
      startTime
      endTime
      appointments {
        items {
          id
          orgID
          slotID
          dealID
          notes
          status
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      available
      scheduled
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listCalendarSlots = /* GraphQL */ `
  query ListCalendarSlots(
    $filter: ModelCalendarSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        calendarID
        startTime
        endTime
        appointments {
          nextToken
        }
        available
        scheduled
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getCalendarAppointment = /* GraphQL */ `
  query GetCalendarAppointment($id: ID!) {
    getCalendarAppointment(id: $id) {
      id
      orgID
      slotID
      dealID
      notes
      status
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      slot {
        id
        orgID
        calendarID
        startTime
        endTime
        appointments {
          nextToken
        }
        available
        scheduled
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listCalendarAppointments = /* GraphQL */ `
  query ListCalendarAppointments(
    $filter: ModelCalendarAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarAppointments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        slotID
        dealID
        notes
        status
        deal {
          id
          orgID
          categoryID
          extID
          name
          sortName
          description
          searchString
          ownersString
          value
          notes
          status
          currentStageID
          currentOwnerID
          defaultCircleID
          currentOwnerType
          currentDispositionID
          nextFollowupDate
          reminderSentTmst
          flexAttributes
          previousFlexAttributes
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        slot {
          id
          orgID
          calendarID
          startTime
          endTime
          available
          scheduled
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getMessageThrottle = /* GraphQL */ `
  query GetMessageThrottle($id: ID!) {
    getMessageThrottle(id: $id) {
      id
      lastReceivedTmst
      lastSentTmst
      throttledMessage
      createdAt
      updatedAt
    }
  }
`;
export const listMessageThrottles = /* GraphQL */ `
  query ListMessageThrottles(
    $filter: ModelMessageThrottleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageThrottles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastReceivedTmst
        lastSentTmst
        throttledMessage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLivestream = /* GraphQL */ `
  query GetLivestream($id: ID!) {
    getLivestream(id: $id) {
      id
      orgID
      streamID
      name
      description
      isStreaming
      isAvailable
      assetIDs
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listLivestreams = /* GraphQL */ `
  query ListLivestreams(
    $filter: ModelLivestreamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLivestreams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        streamID
        name
        description
        isStreaming
        isAvailable
        assetIDs
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getPersonCommission = /* GraphQL */ `
  query GetPersonCommission($id: ID!) {
    getPersonCommission(id: $id) {
      id
      orgID
      userID
      commissionEndDate
      amount
      status
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listPersonCommissions = /* GraphQL */ `
  query ListPersonCommissions(
    $filter: ModelPersonCommissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonCommissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        userID
        commissionEndDate
        amount
        status
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getPersonCommissionDetails = /* GraphQL */ `
  query GetPersonCommissionDetails($id: ID!) {
    getPersonCommissionDetails(id: $id) {
      id
      orgID
      userID
      personCommissionID
      dealID
      stageID
      dispositionID
      amount
      status
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listPersonCommissionDetailss = /* GraphQL */ `
  query ListPersonCommissionDetailss(
    $filter: ModelPersonCommissionDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonCommissionDetailss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        userID
        personCommissionID
        dealID
        stageID
        dispositionID
        amount
        status
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getPersonCommissionModel = /* GraphQL */ `
  query GetPersonCommissionModel($id: ID!) {
    getPersonCommissionModel(id: $id) {
      id
      orgID
      stageID
      dispositionID
      userID
      commissionModelID
      commissionType
      scheduleType
      schedule {
        valueType
        tierMaxValue
        tierPaymentValue
        tierPaymentPercent
      }
      startDate
      endDate
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listPersonCommissionModels = /* GraphQL */ `
  query ListPersonCommissionModels(
    $filter: ModelPersonCommissionModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonCommissionModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        stageID
        dispositionID
        userID
        commissionModelID
        commissionType
        scheduleType
        schedule {
          valueType
          tierMaxValue
          tierPaymentValue
          tierPaymentPercent
        }
        startDate
        endDate
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getCommissionModel = /* GraphQL */ `
  query GetCommissionModel($id: ID!) {
    getCommissionModel(id: $id) {
      id
      orgID
      stageID
      dispositionID
      name
      description
      commissionType
      scheduleType
      schedule {
        valueType
        tierMaxValue
        tierPaymentValue
        tierPaymentPercent
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listCommissionModels = /* GraphQL */ `
  query ListCommissionModels(
    $filter: ModelCommissionModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommissionModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        stageID
        dispositionID
        name
        description
        commissionType
        scheduleType
        schedule {
          valueType
          tierMaxValue
          tierPaymentValue
          tierPaymentPercent
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getUserAddress = /* GraphQL */ `
  query GetUserAddress($id: ID!) {
    getUserAddress(id: $id) {
      id
      userID
      orgID
      type
      address1
      address2
      city
      region
      country
      postalCode
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listUserAddresss = /* GraphQL */ `
  query ListUserAddresss(
    $filter: ModelUserAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        orgID
        type
        address1
        address2
        city
        region
        country
        postalCode
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      orgID
      street
      address
      city
      region
      country
      postalCode
      location {
        lat
        lon
      }
      lastVisited
      nextVisit
      currentOwnerID
      lastVisitedByID
      lastVisitedByName
      currentDispositionID
      status
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listAddresss = /* GraphQL */ `
  query ListAddresss(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        street
        address
        city
        region
        country
        postalCode
        location {
          lat
          lon
        }
        lastVisited
        nextVisit
        currentOwnerID
        lastVisitedByID
        lastVisitedByName
        currentDispositionID
        status
        deal {
          id
          orgID
          categoryID
          extID
          name
          sortName
          description
          searchString
          ownersString
          value
          notes
          status
          currentStageID
          currentOwnerID
          defaultCircleID
          currentOwnerType
          currentDispositionID
          nextFollowupDate
          reminderSentTmst
          flexAttributes
          previousFlexAttributes
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getGeoFence = /* GraphQL */ `
  query GetGeoFence($id: ID!) {
    getGeoFence(id: $id) {
      id
      orgID
      points {
        lat
        lon
      }
      assigneeID
      assigneeType
      currentUserID
      status
      name
      description
      possibleAddresses
      knockedAddresses
      tags
      lastVisited
      scheduledDate
      createdBy
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      currentUser {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listGeoFences = /* GraphQL */ `
  query ListGeoFences(
    $filter: ModelgeoFenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeoFences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        points {
          lat
          lon
        }
        assigneeID
        assigneeType
        currentUserID
        status
        name
        description
        possibleAddresses
        knockedAddresses
        tags
        lastVisited
        scheduledDate
        createdBy
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        currentUser {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getDealOwnerHistory = /* GraphQL */ `
  query GetDealOwnerHistory($id: ID!) {
    getDealOwnerHistory(id: $id) {
      id
      dealID
      orgID
      userID
      ownerType
      isActive
      percent
      isCalculateKPIs
      isMissionCredit
      notes
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listDealOwnerHistorys = /* GraphQL */ `
  query ListDealOwnerHistorys(
    $filter: ModelDealOwnerHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDealOwnerHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dealID
        orgID
        userID
        ownerType
        isActive
        percent
        isCalculateKPIs
        isMissionCredit
        notes
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        deal {
          id
          orgID
          categoryID
          extID
          name
          sortName
          description
          searchString
          ownersString
          value
          notes
          status
          currentStageID
          currentOwnerID
          defaultCircleID
          currentOwnerType
          currentDispositionID
          nextFollowupDate
          reminderSentTmst
          flexAttributes
          previousFlexAttributes
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getDeal = /* GraphQL */ `
  query GetDeal($id: ID!) {
    getDeal(id: $id) {
      id
      orgID
      categoryID
      extID
      name
      sortName
      description
      searchString
      ownersString
      value
      notes
      status
      currentStageID
      currentOwnerID
      defaultCircleID
      currentOwnerType
      currentDispositionID
      disposition {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextFollowupDate
      reminderSentTmst
      pinAddress {
        id
        orgID
        street
        address
        city
        region
        country
        postalCode
        location {
          lat
          lon
        }
        lastVisited
        nextVisit
        currentOwnerID
        lastVisitedByID
        lastVisitedByName
        currentDispositionID
        status
        deal {
          id
          orgID
          categoryID
          extID
          name
          sortName
          description
          searchString
          ownersString
          value
          notes
          status
          currentStageID
          currentOwnerID
          defaultCircleID
          currentOwnerType
          currentDispositionID
          nextFollowupDate
          reminderSentTmst
          flexAttributes
          previousFlexAttributes
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      stage {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      flexAttributes
      previousFlexAttributes
      actions {
        items {
          id
          userMissionID
          userID
          missionID
          ownerID
          KPIRuleID
          actionCount
          currentActionCount
          rewardCount
          currentRewardCount
          comments
          imageName
          imageType
          extID
          dealID
          flexAttributes
          goalCount
          actionTimestamp
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      images {
        items {
          id
          orgID
          dealID
          description
          type
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      comments {
        items {
          id
          orgID
          dealID
          comment
          dispositionID
          followupDate
          contactType
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      appointments {
        items {
          id
          orgID
          slotID
          dealID
          notes
          status
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      transactions {
        items {
          id
          dealID
          orgID
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      ownerHistory {
        items {
          id
          dealID
          orgID
          userID
          ownerType
          isActive
          percent
          isCalculateKPIs
          isMissionCredit
          notes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listDeals = /* GraphQL */ `
  query ListDeals(
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getDealTransactions = /* GraphQL */ `
  query GetDealTransactions($id: ID!) {
    getDealTransactions(id: $id) {
      id
      dealID
      orgID
      flexAttributes
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listDealTransactionss = /* GraphQL */ `
  query ListDealTransactionss(
    $filter: ModelDealTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDealTransactionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dealID
        orgID
        flexAttributes
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getDealComments = /* GraphQL */ `
  query GetDealComments($id: ID!) {
    getDealComments(id: $id) {
      id
      orgID
      dealID
      comment
      dispositionID
      disposition {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      followupDate
      contactType
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listDealCommentss = /* GraphQL */ `
  query ListDealCommentss(
    $filter: ModelDealCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDealCommentss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        dealID
        comment
        dispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        followupDate
        contactType
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getDealImage = /* GraphQL */ `
  query GetDealImage($id: ID!) {
    getDealImage(id: $id) {
      id
      orgID
      dealID
      description
      type
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listDealImages = /* GraphQL */ `
  query ListDealImages(
    $filter: ModelDealImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDealImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        dealID
        description
        type
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getStageGoal = /* GraphQL */ `
  query GetStageGoal($id: ID!) {
    getStageGoal(id: $id) {
      id
      orgID
      ownerID
      ownerType
      stageID
      goalCount
      goalValue
      startDate
      endDate
      frequency
      parentStageGoalID
      stage {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listStageGoals = /* GraphQL */ `
  query ListStageGoals(
    $filter: ModelStageGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStageGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        ownerID
        ownerType
        stageID
        goalCount
        goalValue
        startDate
        endDate
        frequency
        parentStageGoalID
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getKPIRuleGoal = /* GraphQL */ `
  query GetKPIRuleGoal($id: ID!) {
    getKPIRuleGoal(id: $id) {
      id
      orgID
      dimensionID
      KPIRuleID
      value
      parentKPIRuleID
      dimension
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      parentKPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      startDate
      endDate
      frequency
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listKPIRuleGoals = /* GraphQL */ `
  query ListKPIRuleGoals(
    $filter: ModelKPIRuleGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKPIRuleGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        dimensionID
        KPIRuleID
        value
        parentKPIRuleID
        dimension
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        parentKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        startDate
        endDate
        frequency
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getStage = /* GraphQL */ `
  query GetStage($id: ID!) {
    getStage(id: $id) {
      id
      orgID
      title
      description
      action
      actionPastTense
      measure
      measurePlural
      valueUnit
      valueUnitPlural
      countUnit
      countUnitPlural
      iconName
      iconType
      iconColor
      flexAttributes
      sequence
      winProbability
      pinColor
      icon
      isMapDefault
      isDisplay
      isActive
      isNoDealDefault
      categoryID
      category {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        enablePindrop
        stages {
          nextToken
        }
        dispositions {
          nextToken
        }
        flexAttributes
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      imageName
      imageType
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listStages = /* GraphQL */ `
  query ListStages(
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getStageCategory = /* GraphQL */ `
  query GetStageCategory($id: ID!) {
    getStageCategory(id: $id) {
      id
      orgID
      title
      iconName
      iconType
      iconColor
      isDefault
      enablePindrop
      stages {
        items {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      dispositions {
        items {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      flexAttributes
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listStageCategorys = /* GraphQL */ `
  query ListStageCategorys(
    $filter: ModelStageCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStageCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        enablePindrop
        stages {
          nextToken
        }
        dispositions {
          nextToken
        }
        flexAttributes
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getStageDisposition = /* GraphQL */ `
  query GetStageDisposition($id: ID!) {
    getStageDisposition(id: $id) {
      id
      orgID
      categoryID
      title
      isSetupDeal
      requireDate
      deleteType
      order
      iconName
      iconType
      iconColor
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listStageDispositions = /* GraphQL */ `
  query ListStageDispositions(
    $filter: ModelStageDispositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStageDispositions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getTrophy = /* GraphQL */ `
  query GetTrophy($id: ID!) {
    getTrophy(id: $id) {
      id
      orgID
      title
      description
      imageName
      imageType
      place
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listTrophys = /* GraphQL */ `
  query ListTrophys(
    $filter: ModelTrophyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrophys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        title
        description
        imageName
        imageType
        place
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getMissionTrophy = /* GraphQL */ `
  query GetMissionTrophy($id: ID!) {
    getMissionTrophy(id: $id) {
      id
      orgID
      missionID
      title
      description
      imageName
      imageType
      place
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listMissionTrophys = /* GraphQL */ `
  query ListMissionTrophys(
    $filter: ModelMissionTrophyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMissionTrophys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        missionID
        title
        description
        imageName
        imageType
        place
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getPersonTrophy = /* GraphQL */ `
  query GetPersonTrophy($id: ID!) {
    getPersonTrophy(id: $id) {
      id
      orgID
      userID
      count
      title
      description
      imageName
      imageType
      place
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listPersonTrophys = /* GraphQL */ `
  query ListPersonTrophys(
    $filter: ModelPersonTrophyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonTrophys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        userID
        count
        title
        description
        imageName
        imageType
        place
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getKPIRule = /* GraphQL */ `
  query GetKPIRule($id: ID!) {
    getKPIRule(id: $id) {
      id
      orgID
      type
      timeframe
      title
      sourceType
      formula
      triggerID
      dimensions
      action
      actionPastTense
      valueUnit
      valueUnitPlural
      categoryID
      isHidden
      stage {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      category {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        enablePindrop
        stages {
          nextToken
        }
        dispositions {
          nextToken
        }
        flexAttributes
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      disposition {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      goals {
        items {
          id
          orgID
          dimensionID
          KPIRuleID
          value
          parentKPIRuleID
          dimension
          startDate
          endDate
          frequency
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      aggs {
        items {
          id
          orgID
          userKey
          count
          sum
          val
          goal
          dimensionID
          KPIRuleID
          dimension
          currentPosition
          previousPosition
          mostRecentDimensionID
          leaderboard
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      format
      roundPrecision
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listKPIRules = /* GraphQL */ `
  query ListKPIRules(
    $filter: ModelKPIRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKPIRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getKPIValueAggs = /* GraphQL */ `
  query GetKPIValueAggs($id: ID!) {
    getKPIValueAggs(id: $id) {
      id
      orgID
      userKey
      count
      sum
      val
      goal
      dimensionID
      KPIRuleID
      dimension
      currentPosition
      previousPosition
      mostRecentDimensionID
      leaderboard
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listKPIValueAggss = /* GraphQL */ `
  query ListKPIValueAggss(
    $filter: ModelKPIValueAggsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKPIValueAggss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        userKey
        count
        sum
        val
        goal
        dimensionID
        KPIRuleID
        dimension
        currentPosition
        previousPosition
        mostRecentDimensionID
        leaderboard
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPersonActivityFeed = /* GraphQL */ `
  query GetPersonActivityFeed($id: ID!) {
    getPersonActivityFeed(id: $id) {
      id
      userID
      isRead
      orgID
      type
      showAfter
      showAfterEpoch
      showUntilEpoch
      extID
      createdAt
      updatedAt
      activityFeed {
        id
        event
        header
        details
        imageName
        imageType
        chartType
        chartData
        orgID
        type
        showAfterEpoch
        showUntilEpoch
        showAfter
        extID
        createdAt
        createdBy
        createdByUser {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        people {
          nextToken
        }
        emoticons {
          nextToken
        }
        changedID
        changedComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        changedEmoticon {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        changedCommentEmoticon {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        updatedAt
        lastUpdatedBy
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
    }
  }
`;
export const listPersonActivityFeeds = /* GraphQL */ `
  query ListPersonActivityFeeds(
    $filter: ModelPersonActivityFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonActivityFeeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        isRead
        orgID
        type
        showAfter
        showAfterEpoch
        showUntilEpoch
        extID
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      nextToken
    }
  }
`;
export const getActivityFeed = /* GraphQL */ `
  query GetActivityFeed($id: ID!) {
    getActivityFeed(id: $id) {
      id
      event
      header
      details
      imageName
      imageType
      chartType
      chartData
      orgID
      type
      showAfterEpoch
      showUntilEpoch
      showAfter
      extID
      createdAt
      createdBy
      createdByUser {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      comments {
        items {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      people {
        items {
          id
          userID
          isRead
          orgID
          type
          showAfter
          showAfterEpoch
          showUntilEpoch
          extID
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      emoticons {
        items {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        nextToken
      }
      changedID
      changedComment {
        id
        content
        userID
        imageName
        imageType
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        parentComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        emoticons {
          nextToken
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      changedEmoticon {
        id
        activityFeedID
        type
        userID
        orgID
        createdAt
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        updatedAt
      }
      changedCommentEmoticon {
        id
        commentID
        type
        userID
        orgID
        createdAt
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        updatedAt
      }
      updatedAt
      lastUpdatedBy
      isDeleted
    }
  }
`;
export const listActivityFeeds = /* GraphQL */ `
  query ListActivityFeeds(
    $filter: ModelActivityFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityFeeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event
        header
        details
        imageName
        imageType
        chartType
        chartData
        orgID
        type
        showAfterEpoch
        showUntilEpoch
        showAfter
        extID
        createdAt
        createdBy
        createdByUser {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        people {
          nextToken
        }
        emoticons {
          nextToken
        }
        changedID
        changedComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        changedEmoticon {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        changedCommentEmoticon {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        updatedAt
        lastUpdatedBy
        isDeleted
      }
      nextToken
    }
  }
`;
export const getActivityFeedEmoticon = /* GraphQL */ `
  query GetActivityFeedEmoticon($id: ID!) {
    getActivityFeedEmoticon(id: $id) {
      id
      activityFeedID
      type
      userID
      orgID
      createdAt
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      updatedAt
    }
  }
`;
export const listActivityFeedEmoticons = /* GraphQL */ `
  query ListActivityFeedEmoticons(
    $filter: ModelActivityFeedEmoticonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityFeedEmoticons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityFeedID
        type
        userID
        orgID
        createdAt
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommentEmoticon = /* GraphQL */ `
  query GetCommentEmoticon($id: ID!) {
    getCommentEmoticon(id: $id) {
      id
      commentID
      type
      userID
      orgID
      createdAt
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      updatedAt
    }
  }
`;
export const listCommentEmoticons = /* GraphQL */ `
  query ListCommentEmoticons(
    $filter: ModelCommentEmoticonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentEmoticons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commentID
        type
        userID
        orgID
        createdAt
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      content
      userID
      imageName
      imageType
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      activityFeed {
        id
        event
        header
        details
        imageName
        imageType
        chartType
        chartData
        orgID
        type
        showAfterEpoch
        showUntilEpoch
        showAfter
        extID
        createdAt
        createdBy
        createdByUser {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        people {
          nextToken
        }
        emoticons {
          nextToken
        }
        changedID
        changedComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        changedEmoticon {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        changedCommentEmoticon {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        updatedAt
        lastUpdatedBy
        isDeleted
      }
      parentComment {
        id
        content
        userID
        imageName
        imageType
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        parentComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        emoticons {
          nextToken
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      comments {
        items {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      emoticons {
        items {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        nextToken
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        userID
        imageName
        imageType
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        parentComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        emoticons {
          nextToken
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      nextToken
    }
  }
`;
export const getMission = /* GraphQL */ `
  query GetMission($id: ID!) {
    getMission(id: $id) {
      id
      type
      contestType
      name
      description
      rewardLimit
      rewardCount
      rewardType
      actionPastTense
      actionTypePlural
      rewardID
      rewardName
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      scope
      ownerID
      ownerType
      startDate
      endDate
      launchDate
      startDateEpoch
      endDateEpoch
      launchDateEpoch
      KPIRuleID
      tieKPIRuleID
      imageName
      imageType
      questType
      questOrder
      location
      actionCount
      action
      actionType
      minGoal
      actionCountType
      completePictureRequired
      isDeleted
      nextLaunchFeedBlast
      nextExpireFeedBlast
      tags
      bracket
      orgID
      mostRecentUserID
      trophiesAwarded
      rewardsAwarded
      org {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      tieKPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      autoAssign
      assignToChildCircles
      autoCreateMonths
      permissions
      categoryID
      flexFields
      useStepCounter
      isCircleOnly
      isPublic
      rewardDisposition
      useAutoGoal
      autoGoalTimeframe
      autoGoalTimeframeCount
      autoGoalFactor
      minAutoGoal
      roundGoalPrecision
      advanceType
      nextMissionID
      topNumberAdvance
      useAutoCreate
      autoCreateTimeframe
      autoCreateTimeframeCount
      autoCreateStartDate
      autoCreateEndDate
      category {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        doNotShow
        missions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circles {
        items {
          id
          circleID
          orgID
          startDate
          endDate
          missionID
          isPaid
          currentPosition
          previousPosition
          rewardDisposition
          doneActionCount
          goalActionCount
          rewardCount
          trophiesAwarded
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          playMission
          missionID
          circleID
          status
          isCompleted
          isVerified
          isPaid
          imageName
          imageType
          doneActionCount
          goalActionCount
          location
          rewardCount
          rewardDisposition
          currentPosition
          previousPosition
          permissions
          orgID
          KPIRuleID
          action
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          flexFields
          useStepCounter
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      missions {
        items {
          id
          questID
          missionID
          reservedByID
          reservationExpiration
          isComplete
          sequence
          permissions
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      trophies {
        items {
          id
          orgID
          missionID
          title
          description
          imageName
          imageType
          place
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      rewards {
        items {
          id
          orgID
          missionID
          rewardID
          points
          minActionCount
          place
          isTie
          awardedToIDs
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listMissions = /* GraphQL */ `
  query ListMissions(
    $filter: ModelMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMissionCategory = /* GraphQL */ `
  query GetMissionCategory($id: ID!) {
    getMissionCategory(id: $id) {
      id
      orgID
      title
      iconName
      iconType
      iconColor
      isDefault
      doNotShow
      missions {
        items {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listMissionCategorys = /* GraphQL */ `
  query ListMissionCategorys(
    $filter: ModelMissionCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMissionCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        title
        iconName
        iconType
        iconColor
        isDefault
        doNotShow
        missions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getMissionReward = /* GraphQL */ `
  query GetMissionReward($id: ID!) {
    getMissionReward(id: $id) {
      id
      orgID
      missionID
      rewardID
      points
      minActionCount
      place
      isTie
      awardedToIDs
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listMissionRewards = /* GraphQL */ `
  query ListMissionRewards(
    $filter: ModelMissionRewardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMissionRewards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        missionID
        rewardID
        points
        minActionCount
        place
        isTie
        awardedToIDs
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getQuestMission = /* GraphQL */ `
  query GetQuestMission($id: ID!) {
    getQuestMission(id: $id) {
      id
      questID
      quest {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      reservedByID
      reservationExpiration
      isComplete
      sequence
      permissions
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listQuestMissions = /* GraphQL */ `
  query ListQuestMissions(
    $filter: ModelQuestMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestMissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questID
        quest {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        missionID
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        reservedByID
        reservationExpiration
        isComplete
        sequence
        permissions
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getCircleLevel = /* GraphQL */ `
  query GetCircleLevel($id: ID!) {
    getCircleLevel(id: $id) {
      id
      name
      description
      levelNum
      orgID
      circles {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listCircleLevels = /* GraphQL */ `
  query ListCircleLevels(
    $filter: ModelCircleLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCircleLevels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        levelNum
        orgID
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getCircle = /* GraphQL */ `
  query GetCircle($id: ID!) {
    getCircle(id: $id) {
      id
      extID
      name
      description
      location
      scope
      inviterType
      approverType
      status
      isActive
      isHidden
      imageName
      imageType
      avatarImageName
      communityID
      startDate
      endDate
      launchDate
      orgID
      nextLaunchFeedBlast
      nextExpireFeedBlast
      permissions
      parentCircleID
      levelID
      tangoAccountID
      isLeague
      level {
        id
        name
        description
        levelNum
        orgID
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      org {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missions {
        items {
          id
          circleID
          orgID
          startDate
          endDate
          missionID
          isPaid
          currentPosition
          previousPosition
          rewardDisposition
          doneActionCount
          goalActionCount
          rewardCount
          trophiesAwarded
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          circleID
          status
          memberType
          priority
          circlePoints
          lifetimeCirclePoints
          isActive
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
          user {
            id
            name
            functionalRoles
            isDeleted
            imageName
            imageType
          }
        }
        nextToken
      }
      rewards {
        items {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      parent {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      children {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      preferences {
        items {
          id
          orgID
          isHidden
          preferenceType
          name
          entityType
          entityID
          preferences
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listCircles = /* GraphQL */ `
  query ListCircles(
    $filter: ModelCircleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCircles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      extID
      username
      email
      phoneNumber
      name
      birthdate
      imageName
      imageType
      location
      nameVisibility
      phoneNumberVisibility
      emailVisibility
      birthdateVisibility
      pictureVisibility
      locationVisibility
      initials
      pronoun
      role
      status
      expoToken
      orgID
      title
      avatarID
      levelID
      circleLevel {
        id
        name
        description
        levelNum
        orgID
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      agreedToTerms
      agreedToTermsAt
      tags
      addresses {
        items {
          id
          userID
          orgID
          type
          address1
          address2
          city
          region
          country
          postalCode
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      circles {
        items {
          id
          userID
          circleID
          status
          memberType
          priority
          circlePoints
          lifetimeCirclePoints
          isActive
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      rewards {
        items {
          id
          userID
          rewardID
          purchaseType
          status
          purchaseCount
          permissions
          orgID
          approverID
          errorMessages
          statusTmst
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      friends {
        items {
          id
          userID
          friendID
          relationship
          status
          isFriend
          permissions
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      orgs {
        items {
          id
          userID
          orgID
          status
          memberType
          orgPoints
          lifetimeOrgPoints
          isActive
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      kpis {
        items {
          id
          orgID
          userKey
          count
          sum
          val
          goal
          dimensionID
          KPIRuleID
          dimension
          currentPosition
          previousPosition
          mostRecentDimensionID
          leaderboard
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      trophies {
        items {
          id
          orgID
          userID
          count
          title
          description
          imageName
          imageType
          place
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      lessonAssignments {
        items {
          id
          lessonDetailID
          orgID
          assignedToID
          assignedToType
          flexAttributes
          startedTmst
          completedTmst
          percentComplete
          isStarted
          isComplete
          isDeleted
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getPreferences = /* GraphQL */ `
  query GetPreferences($id: ID!) {
    getPreferences(id: $id) {
      id
      orgID
      isHidden
      preferenceType
      name
      entityType
      entityID
      preferences
      createdAt
      updatedAt
    }
  }
`;
export const listPreferencess = /* GraphQL */ `
  query ListPreferencess(
    $filter: ModelPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreferencess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        isHidden
        preferenceType
        name
        entityType
        entityID
        preferences
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrg = /* GraphQL */ `
  query GetOrg($id: ID!) {
    getOrg(id: $id) {
      id
      name
      domains
      contactEmail
      contactName
      contactAddress
      contactAddress2
      contactCity
      contactState
      contactZip
      contactPhone
      configParms
      hubspotID
      restrictionType
      circles {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          orgID
          status
          memberType
          orgPoints
          lifetimeOrgPoints
          isActive
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      preferences {
        items {
          id
          orgID
          isHidden
          preferenceType
          name
          entityType
          entityID
          preferences
          createdAt
          updatedAt
        }
        nextToken
      }
      permissions
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listOrgs = /* GraphQL */ `
  query ListOrgs(
    $filter: ModelOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getOrgMember = /* GraphQL */ `
  query GetOrgMember($id: ID!) {
    getOrgMember(id: $id) {
      id
      userID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      orgID
      org {
        id
        name
        domains
        contactEmail
        contactName
        contactAddress
        contactAddress2
        contactCity
        contactState
        contactZip
        contactPhone
        configParms
        hubspotID
        restrictionType
        circles {
          nextToken
        }
        users {
          nextToken
        }
        preferences {
          nextToken
        }
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      status
      memberType
      orgPoints
      lifetimeOrgPoints
      isActive
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listOrgMembers = /* GraphQL */ `
  query ListOrgMembers(
    $filter: ModelOrgMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        orgID
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        memberType
        orgPoints
        lifetimeOrgPoints
        isActive
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getSVG = /* GraphQL */ `
  query GetSVG($id: ID!, $type: String!) {
    getSVG(id: $id, type: $type) {
      id
      type
      name
      html
      top
      leftMargin
      rightMargin
      width
      height
      isActive
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listSVGs = /* GraphQL */ `
  query ListSVGs(
    $id: ID
    $type: ModelStringKeyConditionInput
    $filter: ModelSVGFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSVGs(
      id: $id
      type: $type
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        name
        html
        top
        leftMargin
        rightMargin
        width
        height
        isActive
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getCircleMember = /* GraphQL */ `
  query GetCircleMember($id: ID!) {
    getCircleMember(id: $id) {
      id
      userID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      circleID
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      status
      memberType
      priority
      circlePoints
      lifetimeCirclePoints
      isActive
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listCircleMembers = /* GraphQL */ `
  query ListCircleMembers(
    $filter: ModelCircleMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCircleMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circleID
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        memberType
        priority
        circlePoints
        lifetimeCirclePoints
        isActive
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getCircleMission = /* GraphQL */ `
  query GetCircleMission($id: ID!) {
    getCircleMission(id: $id) {
      id
      circleID
      orgID
      startDate
      endDate
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      isPaid
      currentPosition
      previousPosition
      rewardDisposition
      doneActionCount
      goalActionCount
      rewardCount
      trophiesAwarded
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listCircleMissions = /* GraphQL */ `
  query ListCircleMissions(
    $filter: ModelCircleMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCircleMissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        circleID
        orgID
        startDate
        endDate
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missionID
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        isPaid
        currentPosition
        previousPosition
        rewardDisposition
        doneActionCount
        goalActionCount
        rewardCount
        trophiesAwarded
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getUserMission = /* GraphQL */ `
  query GetUserMission($id: ID!) {
    getUserMission(id: $id) {
      id
      userID
      playMission
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      circleID
      circle {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      status
      isCompleted
      isVerified
      isPaid
      imageName
      imageType
      doneActionCount
      goalActionCount
      location
      rewardCount
      rewardDisposition
      currentPosition
      previousPosition
      permissions
      orgID
      KPIRuleID
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      action
      startDate
      endDate
      launchDate
      startDateEpoch
      endDateEpoch
      launchDateEpoch
      flexFields
      useStepCounter
      actions {
        items {
          id
          userMissionID
          userID
          missionID
          ownerID
          KPIRuleID
          actionCount
          currentActionCount
          rewardCount
          currentRewardCount
          comments
          imageName
          imageType
          extID
          dealID
          flexAttributes
          goalCount
          actionTimestamp
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listUserMissions = /* GraphQL */ `
  query ListUserMissions(
    $filter: ModelUserMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        playMission
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missionID
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        circleID
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        isCompleted
        isVerified
        isPaid
        imageName
        imageType
        doneActionCount
        goalActionCount
        location
        rewardCount
        rewardDisposition
        currentPosition
        previousPosition
        permissions
        orgID
        KPIRuleID
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        action
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        flexFields
        useStepCounter
        actions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getUserMissionAction = /* GraphQL */ `
  query GetUserMissionAction($id: ID!) {
    getUserMissionAction(id: $id) {
      id
      userMissionID
      userID
      missionID
      ownerID
      KPIRuleID
      actionCount
      currentActionCount
      rewardCount
      currentRewardCount
      comments
      imageName
      imageType
      extID
      dealID
      flexAttributes
      goalCount
      deal {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      userMission {
        id
        userID
        playMission
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missionID
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        circleID
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        isCompleted
        isVerified
        isPaid
        imageName
        imageType
        doneActionCount
        goalActionCount
        location
        rewardCount
        rewardDisposition
        currentPosition
        previousPosition
        permissions
        orgID
        KPIRuleID
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        action
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        flexFields
        useStepCounter
        actions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      actionTimestamp
      orgID
      KPIRule {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listUserMissionActions = /* GraphQL */ `
  query ListUserMissionActions(
    $filter: ModelUserMissionActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMissionActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userMissionID
        userID
        missionID
        ownerID
        KPIRuleID
        actionCount
        currentActionCount
        rewardCount
        currentRewardCount
        comments
        imageName
        imageType
        extID
        dealID
        flexAttributes
        goalCount
        deal {
          id
          orgID
          categoryID
          extID
          name
          sortName
          description
          searchString
          ownersString
          value
          notes
          status
          currentStageID
          currentOwnerID
          defaultCircleID
          currentOwnerType
          currentDispositionID
          nextFollowupDate
          reminderSentTmst
          flexAttributes
          previousFlexAttributes
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        userMission {
          id
          userID
          playMission
          missionID
          circleID
          status
          isCompleted
          isVerified
          isPaid
          imageName
          imageType
          doneActionCount
          goalActionCount
          location
          rewardCount
          rewardDisposition
          currentPosition
          previousPosition
          permissions
          orgID
          KPIRuleID
          action
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          flexFields
          useStepCounter
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        actionTimestamp
        orgID
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getReward = /* GraphQL */ `
  query GetReward($id: ID!) {
    getReward(id: $id) {
      id
      name
      description
      rewardType
      personLimit
      limitCount
      value
      valueType
      remainingCount
      imageName
      imageType
      tags
      validFrom
      validTo
      launchDate
      isActive
      cost
      costType
      giftcardValue
      minGiftcardAmount
      maxGiftcardAmount
      utid
      tangoAccountID
      ownerType
      ownerID
      approverType
      location
      giftcardDisclaimer
      missions {
        items {
          id
          orgID
          missionID
          rewardID
          points
          minActionCount
          place
          isTie
          awardedToIDs
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      users {
        items {
          id
          userID
          rewardID
          purchaseType
          status
          purchaseCount
          permissions
          orgID
          approverID
          errorMessages
          statusTmst
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      userdetails {
        items {
          id
          userRewardID
          userID
          rewardID
          orgID
          missionID
          purchaseType
          status
          errorMessages
          statusTmst
          purchaseCount
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      orgID
      nextLaunchFeedBlast
      nextExpireFeedBlast
      permissions
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listRewards = /* GraphQL */ `
  query ListRewards(
    $filter: ModelRewardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRewards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getUserReward = /* GraphQL */ `
  query GetUserReward($id: ID!) {
    getUserReward(id: $id) {
      id
      userID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      rewardID
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      purchaseType
      status
      purchaseCount
      permissions
      orgID
      approverID
      details {
        items {
          id
          userRewardID
          userID
          rewardID
          orgID
          missionID
          purchaseType
          status
          errorMessages
          statusTmst
          purchaseCount
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      errorMessages
      statusTmst
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listUserRewards = /* GraphQL */ `
  query ListUserRewards(
    $filter: ModelUserRewardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRewards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        rewardID
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        purchaseType
        status
        purchaseCount
        permissions
        orgID
        approverID
        details {
          nextToken
        }
        errorMessages
        statusTmst
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getUserRewardDetails = /* GraphQL */ `
  query GetUserRewardDetails($id: ID!) {
    getUserRewardDetails(id: $id) {
      id
      userRewardID
      userID
      rewardID
      orgID
      missionID
      mission {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      userReward {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        rewardID
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        purchaseType
        status
        purchaseCount
        permissions
        orgID
        approverID
        details {
          nextToken
        }
        errorMessages
        statusTmst
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      reward {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      purchaseType
      status
      errorMessages
      statusTmst
      purchaseCount
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listUserRewardDetailss = /* GraphQL */ `
  query ListUserRewardDetailss(
    $filter: ModelUserRewardDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRewardDetailss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userRewardID
        userID
        rewardID
        orgID
        missionID
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        userReward {
          id
          userID
          rewardID
          purchaseType
          status
          purchaseCount
          permissions
          orgID
          approverID
          errorMessages
          statusTmst
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        purchaseType
        status
        errorMessages
        statusTmst
        purchaseCount
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getUserGiftcardAccount = /* GraphQL */ `
  query GetUserGiftcardAccount($id: ID!) {
    getUserGiftcardAccount(id: $id) {
      id
      userID
      orgID
      name
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      rewards {
        items {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      circles {
        items {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listUserGiftcardAccounts = /* GraphQL */ `
  query ListUserGiftcardAccounts(
    $filter: ModelUserGiftcardAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserGiftcardAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        orgID
        name
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        rewards {
          nextToken
        }
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getUserFriend = /* GraphQL */ `
  query GetUserFriend($id: ID!) {
    getUserFriend(id: $id) {
      id
      userID
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      friendID
      friend {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      relationship
      status
      isFriend
      permissions
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      isDeleted
    }
  }
`;
export const listUserFriends = /* GraphQL */ `
  query ListUserFriends(
    $filter: ModelUserFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        friendID
        friend {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        relationship
        status
        isFriend
        permissions
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const getIntegration = /* GraphQL */ `
  query GetIntegration($id: ID!) {
    getIntegration(id: $id) {
      id
      partner
      orgID
      partnerCode
      refreshToken
      accessToken
      expiration
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listIntegrations = /* GraphQL */ `
  query ListIntegrations(
    $filter: ModelIntegrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partner
        orgID
        partnerCode
        refreshToken
        accessToken
        expiration
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIntegrationXref = /* GraphQL */ `
  query GetIntegrationXref($id: ID!) {
    getIntegrationXref(id: $id) {
      id
      orgID
      partnerID
      partnerKeyName
      partnerKeyValue
      ourKeyName
      ourKeyValue
      mappingJSON
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listIntegrationXrefs = /* GraphQL */ `
  query ListIntegrationXrefs(
    $filter: ModelIntegrationXrefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationXrefs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        partnerID
        partnerKeyName
        partnerKeyValue
        ourKeyName
        ourKeyValue
        mappingJSON
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAddressByAddress = /* GraphQL */ `
  query ListAddressByAddress(
    $city: String
    $postalCodeStreetAddress: ModelAddressByAddressCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddressByAddress(
      city: $city
      postalCodeStreetAddress: $postalCodeStreetAddress
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        street
        address
        city
        region
        country
        postalCode
        location {
          lat
          lon
        }
        lastVisited
        nextVisit
        currentOwnerID
        lastVisitedByID
        lastVisitedByName
        currentDispositionID
        status
        deal {
          id
          orgID
          categoryID
          extID
          name
          sortName
          description
          searchString
          ownersString
          value
          notes
          status
          currentStageID
          currentOwnerID
          defaultCircleID
          currentOwnerType
          currentDispositionID
          nextFollowupDate
          reminderSentTmst
          flexAttributes
          previousFlexAttributes
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listDealOwnerHistoryByDealID = /* GraphQL */ `
  query ListDealOwnerHistoryByDealID(
    $dealID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealOwnerHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDealOwnerHistoryByDealID(
      dealID: $dealID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dealID
        orgID
        userID
        ownerType
        isActive
        percent
        isCalculateKPIs
        isMissionCredit
        notes
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        deal {
          id
          orgID
          categoryID
          extID
          name
          sortName
          description
          searchString
          ownersString
          value
          notes
          status
          currentStageID
          currentOwnerID
          defaultCircleID
          currentOwnerType
          currentDispositionID
          nextFollowupDate
          reminderSentTmst
          flexAttributes
          previousFlexAttributes
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const dealByCategoryStatus = /* GraphQL */ `
  query DealByCategoryStatus(
    $categoryID: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DealByCategoryStatus(
      categoryID: $categoryID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const dealByOwnerStage = /* GraphQL */ `
  query DealByOwnerStage(
    $currentOwnerID: ID
    $currentStageID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DealByOwnerStage(
      currentOwnerID: $currentOwnerID
      currentStageID: $currentStageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listDealCommentsByDeal = /* GraphQL */ `
  query ListDealCommentsByDeal(
    $dealID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDealCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDealCommentsByDeal(
      dealID: $dealID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        dealID
        comment
        dispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        followupDate
        contactType
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listKPIRuleGoalsByRuleIDDimension = /* GraphQL */ `
  query ListKPIRuleGoalsByRuleIDDimension(
    $KPIRuleID: ID
    $dimensionID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKPIRuleGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKPIRuleGoalsByRuleIDDimension(
      KPIRuleID: $KPIRuleID
      dimensionID: $dimensionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        dimensionID
        KPIRuleID
        value
        parentKPIRuleID
        dimension
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        parentKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        startDate
        endDate
        frequency
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listStageByCategorySequence = /* GraphQL */ `
  query ListStageByCategorySequence(
    $categoryID: ID
    $sequence: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStageByCategorySequence(
      categoryID: $categoryID
      sequence: $sequence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        title
        description
        action
        actionPastTense
        measure
        measurePlural
        valueUnit
        valueUnitPlural
        countUnit
        countUnitPlural
        iconName
        iconType
        iconColor
        flexAttributes
        sequence
        winProbability
        pinColor
        icon
        isMapDefault
        isDisplay
        isActive
        isNoDealDefault
        categoryID
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listStageDispositionByCategoryID = /* GraphQL */ `
  query ListStageDispositionByCategoryID(
    $categoryID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStageDispositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStageDispositionByCategoryID(
      categoryID: $categoryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listStageDispositionByCategoryIDOrder = /* GraphQL */ `
  query ListStageDispositionByCategoryIDOrder(
    $categoryID: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStageDispositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStageDispositionByCategoryIDOrder(
      categoryID: $categoryID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        categoryID
        title
        isSetupDeal
        requireDate
        deleteType
        order
        iconName
        iconType
        iconColor
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listKPIRulesByOrg = /* GraphQL */ `
  query ListKPIRulesByOrg(
    $orgID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelKPIRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKPIRulesByOrg(
      orgID: $orgID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        type
        timeframe
        title
        sourceType
        formula
        triggerID
        dimensions
        action
        actionPastTense
        valueUnit
        valueUnitPlural
        categoryID
        isHidden
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          enablePindrop
          flexAttributes
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        goals {
          nextToken
        }
        aggs {
          nextToken
        }
        format
        roundPrecision
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const kPIValueAggsByUserKey = /* GraphQL */ `
  query KPIValueAggsByUserKey(
    $userKey: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKPIValueAggsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    KPIValueAggsByUserKey(
      userKey: $userKey
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        userKey
        count
        sum
        val
        goal
        dimensionID
        KPIRuleID
        dimension
        currentPosition
        previousPosition
        mostRecentDimensionID
        leaderboard
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const feedByDateNew = /* GraphQL */ `
  query FeedByDateNew(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPersonActivityFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedByDateNew(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        isRead
        orgID
        type
        showAfter
        showAfterEpoch
        showUntilEpoch
        extID
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      nextToken
    }
  }
`;
export const feedByUpdatedAt = /* GraphQL */ `
  query FeedByUpdatedAt(
    $userID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPersonActivityFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedByUpdatedAt(
      userID: $userID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        isRead
        orgID
        type
        showAfter
        showAfterEpoch
        showUntilEpoch
        extID
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      nextToken
    }
  }
`;
export const feedByShowAfter = /* GraphQL */ `
  query FeedByShowAfter(
    $userID: ID
    $showAfter: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPersonActivityFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedByShowAfter(
      userID: $userID
      showAfter: $showAfter
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        isRead
        orgID
        type
        showAfter
        showAfterEpoch
        showUntilEpoch
        extID
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      nextToken
    }
  }
`;
export const listActivityFeedByScheduleSendEpoch = /* GraphQL */ `
  query ListActivityFeedByScheduleSendEpoch(
    $orgID: ID
    $showAfterEpoch: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityFeedByScheduleSendEpoch(
      orgID: $orgID
      showAfterEpoch: $showAfterEpoch
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event
        header
        details
        imageName
        imageType
        chartType
        chartData
        orgID
        type
        showAfterEpoch
        showUntilEpoch
        showAfter
        extID
        createdAt
        createdBy
        createdByUser {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        people {
          nextToken
        }
        emoticons {
          nextToken
        }
        changedID
        changedComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        changedEmoticon {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        changedCommentEmoticon {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        updatedAt
        lastUpdatedBy
        isDeleted
      }
      nextToken
    }
  }
`;
export const commentByDate = /* GraphQL */ `
  query CommentByDate(
    $createdAt: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentByDate(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        userID
        imageName
        imageType
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        parentComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        emoticons {
          nextToken
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      nextToken
    }
  }
`;
export const byEndDate = /* GraphQL */ `
  query ByEndDate(
    $endDate: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEndDate(
      endDate: $endDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const kPIRuleID = /* GraphQL */ `
  query KPIRuleID(
    $KPIRuleID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    KPIRuleID(
      KPIRuleID: $KPIRuleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMissionByEndDate = /* GraphQL */ `
  query ListMissionByEndDate(
    $orgID: ID
    $endDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMissionByEndDate(
      orgID: $orgID
      endDate: $endDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMissionsByOrgName = /* GraphQL */ `
  query ListMissionsByOrgName(
    $orgID: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMissionsByOrgName(
      orgID: $orgID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        contestType
        name
        description
        rewardLimit
        rewardCount
        rewardType
        actionPastTense
        actionTypePlural
        rewardID
        rewardName
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        scope
        ownerID
        ownerType
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        KPIRuleID
        tieKPIRuleID
        imageName
        imageType
        questType
        questOrder
        location
        actionCount
        action
        actionType
        minGoal
        actionCountType
        completePictureRequired
        isDeleted
        nextLaunchFeedBlast
        nextExpireFeedBlast
        tags
        bracket
        orgID
        mostRecentUserID
        trophiesAwarded
        rewardsAwarded
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        tieKPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        autoAssign
        assignToChildCircles
        autoCreateMonths
        permissions
        categoryID
        flexFields
        useStepCounter
        isCircleOnly
        isPublic
        rewardDisposition
        useAutoGoal
        autoGoalTimeframe
        autoGoalTimeframeCount
        autoGoalFactor
        minAutoGoal
        roundGoalPrecision
        advanceType
        nextMissionID
        topNumberAdvance
        useAutoCreate
        autoCreateTimeframe
        autoCreateTimeframeCount
        autoCreateStartDate
        autoCreateEndDate
        category {
          id
          orgID
          title
          iconName
          iconType
          iconColor
          isDefault
          doNotShow
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circles {
          nextToken
        }
        users {
          nextToken
        }
        missions {
          nextToken
        }
        trophies {
          nextToken
        }
        rewards {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCircleLevelByOrg = /* GraphQL */ `
  query ListCircleLevelByOrg(
    $orgID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCircleLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCircleLevelByOrg(
      orgID: $orgID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        levelNum
        orgID
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listCirclesByOrg = /* GraphQL */ `
  query ListCirclesByOrg(
    $orgID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCircleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCirclesByOrg(
      orgID: $orgID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listCirclesByParentCircleID = /* GraphQL */ `
  query ListCirclesByParentCircleID(
    $parentCircleID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCircleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCirclesByParentCircleID(
      parentCircleID: $parentCircleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listCirclesByTangoAccountID = /* GraphQL */ `
  query ListCirclesByTangoAccountID(
    $tangoAccountID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCircleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCirclesByTangoAccountID(
      tangoAccountID: $tangoAccountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listUsersByOrg = /* GraphQL */ `
  query ListUsersByOrg(
    $orgID: ID
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByOrg(
      orgID: $orgID
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const orgMemberByUser = /* GraphQL */ `
  query OrgMemberByUser(
    $userID: ID
    $orgID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrgMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OrgMemberByUser(
      userID: $userID
      orgID: $orgID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        orgID
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        memberType
        orgPoints
        lifetimeOrgPoints
        isActive
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const circleMemberByCircleID = /* GraphQL */ `
  query CircleMemberByCircleID(
    $circleID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCircleMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    circleMemberByCircleID(
      circleID: $circleID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circleID
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        memberType
        priority
        circlePoints
        lifetimeCirclePoints
        isActive
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const circleMemberByUserID = /* GraphQL */ `
  query CircleMemberByUserID(
    $userID: ID
    $circleID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCircleMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    circleMemberByUserID(
      userID: $userID
      circleID: $circleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circleID
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        memberType
        priority
        circlePoints
        lifetimeCirclePoints
        isActive
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const userMissionByUserIdLaunchDate = /* GraphQL */ `
  query UserMissionByUserIdLaunchDate(
    $userID: ID
    $launchDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserMissionByUserIdLaunchDate(
      userID: $userID
      launchDate: $launchDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        playMission
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missionID
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        circleID
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        isCompleted
        isVerified
        isPaid
        imageName
        imageType
        doneActionCount
        goalActionCount
        location
        rewardCount
        rewardDisposition
        currentPosition
        previousPosition
        permissions
        orgID
        KPIRuleID
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        action
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        flexFields
        useStepCounter
        actions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const userMissionByUserIdStartDate = /* GraphQL */ `
  query UserMissionByUserIdStartDate(
    $userID: ID
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserMissionByUserIdStartDate(
      userID: $userID
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        playMission
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missionID
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        circleID
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        isCompleted
        isVerified
        isPaid
        imageName
        imageType
        doneActionCount
        goalActionCount
        location
        rewardCount
        rewardDisposition
        currentPosition
        previousPosition
        permissions
        orgID
        KPIRuleID
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        action
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        flexFields
        useStepCounter
        actions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const byUserKPIRuleID = /* GraphQL */ `
  query ByUserKPIRuleID(
    $userID: ID
    $KPIRuleID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUserKPIRuleID(
      userID: $userID
      KPIRuleID: $KPIRuleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        playMission
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missionID
        mission {
          id
          type
          contestType
          name
          description
          rewardLimit
          rewardCount
          rewardType
          actionPastTense
          actionTypePlural
          rewardID
          rewardName
          scope
          ownerID
          ownerType
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          KPIRuleID
          tieKPIRuleID
          imageName
          imageType
          questType
          questOrder
          location
          actionCount
          action
          actionType
          minGoal
          actionCountType
          completePictureRequired
          isDeleted
          nextLaunchFeedBlast
          nextExpireFeedBlast
          tags
          bracket
          orgID
          mostRecentUserID
          trophiesAwarded
          rewardsAwarded
          autoAssign
          assignToChildCircles
          autoCreateMonths
          permissions
          categoryID
          flexFields
          useStepCounter
          isCircleOnly
          isPublic
          rewardDisposition
          useAutoGoal
          autoGoalTimeframe
          autoGoalTimeframeCount
          autoGoalFactor
          minAutoGoal
          roundGoalPrecision
          advanceType
          nextMissionID
          topNumberAdvance
          useAutoCreate
          autoCreateTimeframe
          autoCreateTimeframeCount
          autoCreateStartDate
          autoCreateEndDate
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
        }
        circleID
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        status
        isCompleted
        isVerified
        isPaid
        imageName
        imageType
        doneActionCount
        goalActionCount
        location
        rewardCount
        rewardDisposition
        currentPosition
        previousPosition
        permissions
        orgID
        KPIRuleID
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        action
        startDate
        endDate
        launchDate
        startDateEpoch
        endDateEpoch
        launchDateEpoch
        flexFields
        useStepCounter
        actions {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listRewardsByOrgName = /* GraphQL */ `
  query ListRewardsByOrgName(
    $orgID: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRewardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRewardsByOrgName(
      orgID: $orgID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        rewardType
        personLimit
        limitCount
        value
        valueType
        remainingCount
        imageName
        imageType
        tags
        validFrom
        validTo
        launchDate
        isActive
        cost
        costType
        giftcardValue
        minGiftcardAmount
        maxGiftcardAmount
        utid
        tangoAccountID
        ownerType
        ownerID
        approverType
        location
        giftcardDisclaimer
        missions {
          nextToken
        }
        users {
          nextToken
        }
        userdetails {
          nextToken
        }
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listUserRewardsByUserReward = /* GraphQL */ `
  query ListUserRewardsByUserReward(
    $userID: ID
    $rewardID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserRewardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRewardsByUserReward(
      userID: $userID
      rewardID: $rewardID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        rewardID
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        purchaseType
        status
        purchaseCount
        permissions
        orgID
        approverID
        details {
          nextToken
        }
        errorMessages
        statusTmst
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listUserRewardsByOrgCreatedAt = /* GraphQL */ `
  query ListUserRewardsByOrgCreatedAt(
    $orgID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserRewardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRewardsByOrgCreatedAt(
      orgID: $orgID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        rewardID
        reward {
          id
          name
          description
          rewardType
          personLimit
          limitCount
          value
          valueType
          remainingCount
          imageName
          imageType
          tags
          validFrom
          validTo
          launchDate
          isActive
          cost
          costType
          giftcardValue
          minGiftcardAmount
          maxGiftcardAmount
          utid
          tangoAccountID
          ownerType
          ownerID
          approverType
          location
          giftcardDisclaimer
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        purchaseType
        status
        purchaseCount
        permissions
        orgID
        approverID
        details {
          nextToken
        }
        errorMessages
        statusTmst
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const listUserGiftCardAccountByUser = /* GraphQL */ `
  query ListUserGiftCardAccountByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserGiftcardAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserGiftCardAccountByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        orgID
        name
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        rewards {
          nextToken
        }
        circles {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
    }
  }
`;
export const searchAddresss = /* GraphQL */ `
  query SearchAddresss(
    $filter: SearchableAddressFilterInput
    $sort: SearchableAddressSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAddresss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        orgID
        street
        address
        city
        region
        country
        postalCode
        location {
          lat
          lon
        }
        lastVisited
        nextVisit
        currentOwnerID
        lastVisitedByID
        lastVisitedByName
        currentDispositionID
        status
        deal {
          id
          orgID
          categoryID
          extID
          name
          sortName
          description
          searchString
          ownersString
          value
          notes
          status
          currentStageID
          currentOwnerID
          defaultCircleID
          currentOwnerType
          currentDispositionID
          nextFollowupDate
          reminderSentTmst
          flexAttributes
          previousFlexAttributes
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
      total
    }
  }
`;
export const searchDeals = /* GraphQL */ `
  query SearchDeals(
    $filter: SearchableDealFilterInput
    $sort: SearchableDealSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDeals(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        orgID
        categoryID
        extID
        name
        sortName
        description
        searchString
        ownersString
        value
        notes
        status
        currentStageID
        currentOwnerID
        defaultCircleID
        currentOwnerType
        currentDispositionID
        disposition {
          id
          orgID
          categoryID
          title
          isSetupDeal
          requireDate
          deleteType
          order
          iconName
          iconType
          iconColor
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextFollowupDate
        reminderSentTmst
        pinAddress {
          id
          orgID
          street
          address
          city
          region
          country
          postalCode
          lastVisited
          nextVisit
          currentOwnerID
          lastVisitedByID
          lastVisitedByName
          currentDispositionID
          status
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        stage {
          id
          orgID
          title
          description
          action
          actionPastTense
          measure
          measurePlural
          valueUnit
          valueUnitPlural
          countUnit
          countUnitPlural
          iconName
          iconType
          iconColor
          flexAttributes
          sequence
          winProbability
          pinColor
          icon
          isMapDefault
          isDisplay
          isActive
          isNoDealDefault
          categoryID
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        flexAttributes
        previousFlexAttributes
        actions {
          nextToken
        }
        images {
          nextToken
        }
        comments {
          nextToken
        }
        appointments {
          nextToken
        }
        transactions {
          nextToken
        }
        ownerHistory {
          nextToken
        }
        imageName
        imageType
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
      total
    }
  }
`;
export const searchKPIValueAggss = /* GraphQL */ `
  query SearchKPIValueAggss(
    $filter: SearchableKPIValueAggsFilterInput
    $sort: SearchableKPIValueAggsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchKPIValueAggss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        orgID
        userKey
        count
        sum
        val
        goal
        dimensionID
        KPIRuleID
        dimension
        currentPosition
        previousPosition
        mostRecentDimensionID
        leaderboard
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        circle {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchCircles = /* GraphQL */ `
  query SearchCircles(
    $filter: SearchableCircleFilterInput
    $sort: SearchableCircleSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCircles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        extID
        name
        description
        location
        scope
        inviterType
        approverType
        status
        isActive
        isHidden
        imageName
        imageType
        avatarImageName
        communityID
        startDate
        endDate
        launchDate
        orgID
        nextLaunchFeedBlast
        nextExpireFeedBlast
        permissions
        parentCircleID
        levelID
        tangoAccountID
        isLeague
        level {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        org {
          id
          name
          domains
          contactEmail
          contactName
          contactAddress
          contactAddress2
          contactCity
          contactState
          contactZip
          contactPhone
          configParms
          hubspotID
          restrictionType
          permissions
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        missions {
          nextToken
        }
        users {
          nextToken
        }
        rewards {
          nextToken
        }
        parent {
          id
          extID
          name
          description
          location
          scope
          inviterType
          approverType
          status
          isActive
          isHidden
          imageName
          imageType
          avatarImageName
          communityID
          startDate
          endDate
          launchDate
          orgID
          nextLaunchFeedBlast
          nextExpireFeedBlast
          permissions
          parentCircleID
          levelID
          tangoAccountID
          isLeague
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        children {
          nextToken
        }
        preferences {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
      total
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        friends {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
      total
    }
  }
`;
export const searchUserMissionActions = /* GraphQL */ `
  query SearchUserMissionActions(
    $filter: SearchableUserMissionActionFilterInput
    $sort: SearchableUserMissionActionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUserMissionActions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userMissionID
        userID
        missionID
        ownerID
        KPIRuleID
        actionCount
        currentActionCount
        rewardCount
        currentRewardCount
        comments
        imageName
        imageType
        extID
        dealID
        flexAttributes
        goalCount
        deal {
          id
          orgID
          categoryID
          extID
          name
          sortName
          description
          searchString
          ownersString
          value
          notes
          status
          currentStageID
          currentOwnerID
          defaultCircleID
          currentOwnerType
          currentDispositionID
          nextFollowupDate
          reminderSentTmst
          flexAttributes
          previousFlexAttributes
          imageName
          imageType
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        userMission {
          id
          userID
          playMission
          missionID
          circleID
          status
          isCompleted
          isVerified
          isPaid
          imageName
          imageType
          doneActionCount
          goalActionCount
          location
          rewardCount
          rewardDisposition
          currentPosition
          previousPosition
          permissions
          orgID
          KPIRuleID
          action
          startDate
          endDate
          launchDate
          startDateEpoch
          endDateEpoch
          launchDateEpoch
          flexFields
          useStepCounter
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        actionTimestamp
        orgID
        KPIRule {
          id
          orgID
          type
          timeframe
          title
          sourceType
          formula
          triggerID
          dimensions
          action
          actionPastTense
          valueUnit
          valueUnitPlural
          categoryID
          isHidden
          format
          roundPrecision
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      nextToken
      total
    }
  }
`;
export const listProductByCategoryID = /* GraphQL */ `
  query ListProductByCategoryID(
    $categoryID: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductByCategoryID(
      categoryID: $categoryID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgID
        categoryID
        extID
        name
        description
        frequency
        reservice
        defaultCharge
        defaultLength
        defaultInitialCharge
        minRecurringCharge
        minInitialCharge
        regularService
        initialService
        seasonStart
        seasonEnd
        defaultFollowupDelay
        flexAttributes
        productCode
        productFamily
        listPrice
        startDate
        endDate
        units
        status
        isDeleted
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        createdAt
        categoryID
        createdBy
        defaultCharge
        # defaultFollowupDelay
        # defaultInitialCharge
        # defaultLength
        description
        endDate
        extID
        frequency
        flexAttributes
        id
        isDeleted
        initialService
        lastUpdatedBy
        listPrice
        minInitialCharge
        minRecurringCharge
        name
        orgID
        productCode
        productFamily
        # regularService
        # reservice
        # seasonEnd
        # seasonStart
        startDate
        units
        status
        updatedAt
      }
    }
  }
`;
