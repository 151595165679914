import Me from '../../../_types/Me';
import NewGameState from '../NewGame/_types/NewGameState';
import Contestant from '../_types/Contestant';
import getContestantCircles from './getContestantCircles';
import getContestantPersons from './getContestantPersons';

const getContestants = async (args: {
  newGameState: NewGameState;
  me: Me;
}): Promise<Contestant[]> => {
  const { newGameState, me } = args;
  let contestants: Contestant[] = [];

  if (newGameState.scope === 'circle') {
    contestants = await getContestantCircles({
      orgID: me.orgID,
      userID: me.id,
    });
  } else if (newGameState.scope === 'person') {
    contestants = await getContestantPersons({
      orgID: me.orgID,
    });
  } else {
    return contestants;
  }

  return contestants;
};
export default getContestants;
