import React from 'react';
import { Box } from '@mui/material';

export default function Dashboard() {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        paddingTop: 90,
      }}
    >
      <iframe
        width={'100%'}
        height={'100%'}
        src={
          'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/251322644128/dashboards/fe7be1db-828f-4c2a-a699-0ad582de66c6/sheets/fe7be1db-828f-4c2a-a699-0ad582de66c6_7ff91726-8d0e-4228-af2c-fc5c9cf691e8/visuals/fe7be1db-828f-4c2a-a699-0ad582de66c6_b891e29f-ec1a-4464-9567-ac957850968a?directory_alias=gamify'
        }
      ></iframe>
    </Box>
  );
}
