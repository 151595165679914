import React from 'react';
import StepperView from './Stepper.view';

interface Props {
  steps: string[];
  activeStep: number;
  onChangeActiveStep: (newActiveStep: number) => void;
}

const StepperContainer: React.FC<Props> = ({
  steps,
  activeStep,
  onChangeActiveStep,
}) => {
  return (
    <StepperView
      steps={steps}
      activeStep={activeStep}
      onChangeActiveStep={onChangeActiveStep}
    />
  );
};

export default StepperContainer;
