import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { Text, View } from '@aws-amplify/ui-react';
import { API, Storage } from 'aws-amplify';
import gql from 'graphql-tag';
import './Training.css';
import YouTube from 'react-youtube';

export default function TrainingVideoPage() {
  const { lessonId, category } = useParams();
  const navigate = useNavigate();
  const [lessonData, setLessonData] = useState(null);

  const getLesson = async () => {
    const myQuery = gql`
      query GetLessonDetail($id: ID!) {
        getLessonDetail(id: $id) {
          id
          name
          description
          youTubeURL
          order
          imageName
          imageType
          posterImageName
          category
          createdAt
        }
      }
    `;
    let res;
    try {
      res = await API.graphql({
        query: myQuery,
        variables: { id: lessonId },
      });
    } catch (error) {
      alert("Couldn't Get Lessons!");
      console.log('We Got a Problem --- update later');
      return false;
    }
    const lesson = res.data.getLessonDetail;
    if (lesson.posterImageName === '') lesson.posterImageName = null;
    if (lesson.youTubeURL === '') lesson.youTubeURL = null;
    if (!lesson.youTubeURL) {
      lesson.videoURL = await Storage.get(lesson.imageName);
    } else {
      lesson.videoURL = 'null';
    }
    setLessonData(lesson);
  };

  const goBack = () => {
    navigate(`/training/${lessonData.category}`);
  };

  const convertDate = (date) => {
    const originalDate = new Date(date);
    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(originalDate);
  };

  useEffect(() => {
    if (!lessonId || !category) return;
    if (global.trainingVideos && global.trainingVideos[category]) {
      setLessonData(
        global.trainingVideos[category].find((vid) => vid.id === lessonId)
      );
    } else {
      getLesson();
    }
  }, []);

  return (
    <View id="single-video-page-view">
      <Box style={{ padding: '3vh 7vw' }}>
        <Text
          onClick={goBack}
          className="hov"
          style={{ color: 'white', fontSize: '1.2rem', fontWeight: 'bold' }}
        >
          {'< Back to Videos'}
        </Text>
        <Box
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '15px',
          }}
        >
          <Box style={{ maxWidth: 960 }}>
            {lessonData && !lessonData.youTubeURL && (
              <video
                autoPlay={true}
                width={960}
                height={585}
                controls
                controlsList="nodownload"
              >
                <source src={lessonData.videoURL} />
              </video>
            )}
            {lessonData && lessonData.youTubeURL && (
              <YouTubePlayer videoId={lessonData.youTubeURL} />
            )}
            {lessonData && (
              <Box style={{ marginTop: '25px', padding: '2px' }}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Text
                    style={{
                      color: 'white',
                      fontSize: '1.3rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {lessonData.name}
                  </Text>
                  <Box
                    style={{
                      margin: '0 15px',
                      height: '1.3rem',
                      width: '0.1rem',
                      backgroundColor: 'white',
                    }}
                  />
                  <Text
                    style={{
                      color: 'white',
                    }}
                  >
                    {convertDate(lessonData.createdAt)}
                  </Text>
                </Box>
                <Text
                  style={{
                    color: 'white',
                    marginTop: '2rem',
                    overflowWrap: 'anywhere',
                  }}
                >
                  {lessonData.description}
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </View>
  );
}

const YouTubePlayer = (props) => {
  const { videoId } = props;

  const opts = {
    height: '585',
    width: '960',
    playerVars: {
      autoplay: 1, // 1 enables autoplay, 0 disables it
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};
