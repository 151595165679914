import React from 'react';
import NormalDetailsView from './NormalDetails.view';
import Game from '../../_types/Game';

interface Props {
  game: Game;
}

const NormalDetailsContainer = ({ game }: Props) => {
  return <NormalDetailsView game={game} />;
};

export default NormalDetailsContainer;
