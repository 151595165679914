import { styled } from '@mui/material/styles';
import { MenuItem, Select, Button, TextField } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';

export const StyledTextField = styled(TextField)`
  border-raduis: 5px;
`;

export const StyledSelect = styled(Select)(({ customWidth }) => ({
  '& .MuiOutlinedInput-input': {
    backgroundColor: 'none',
    borderRadius: 8,
    width: customWidth || '16ch',
    border: '1px solid #D0D2D8',
    // padding: theme.spacing(1),
    padding: '8px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

export const StyledMenuItem = styled(MenuItem)`
  border-left: 1px solid #d0d2d8;
  border-right: 1px solid #d0d2d8;
  position: relative;
  transform: translateY(15px);
  border-bottom: 1px solid #d0d2d8;

  background-color: white;

  &:not(.Mui-selected):hover {
    background-color: #fac8b2;
    color: #ff6a00;
  }
  &.Mui-selected {
    background-color: #fac8b2;
    color: #ff6a00;
  }
  &.Mui-selected:hover {
    background-color: #fac8b2;
    color: #ff6a00;
  }
  &:first-of-type {
    border-top: 1px solid #d0d2d8;
  }

  &:last-of-type {
    border-bottom: 1px solid #d0d2d8;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const StyleDatePicker = styled(DatePicker)(({ theme, customWidth }) => ({
  '& .MuiOutlinedInput-input': {
    backgroundColor: 'transparent',
    // border: '1px solid lightgray',
    // borderRadius: '8px',
    // border: '1px solid #D0D2D8',
    // borderRadius: 8,
    width: customWidth || '12ch',
    padding: theme.spacing(1),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputLabel-root': {
    // color: 'transparent', // Example: change label color
    // fontWeight: 'bold', // Example: make label text bold
  },
  '& .MuiInputLabel-root.Mui-focused': {
    // This targets the label when the input is focused
    // Custom styles for focused label
  },
}));
export const StyleDateTimePicker = styled(DateTimePicker)(
  ({ theme, customWidth }) => ({
    '& .MuiOutlinedInput-input': {
      backgroundColor: 'none',
      // borderRadius: 8,
      width: customWidth || '16ch',
      // border: '1px solid #D0D2D8',
      padding: theme.spacing(1),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputLabel-root': {
      // This targets the label
      // Add your custom label styles here
      color: 'transparent', // Example: change label color
      fontWeight: 'bold', // Example: make label text bold
    },
    '& .MuiInputLabel-root.Mui-focused': {
      // This targets the label when the input is focused
      // Custom styles for focused label
    },
  })
);

export const StyledButton = styled(Button)`
  width: 15ch;
  color: white;
  border-radius: 8px;
  background-color: #ff6a00;
  margin-left: 15px;

  &:hover {
    background-color: #fac8b2;
    color: #ff6a00;
  }
`;
export const StyledRunButton = styled(Button)`
  width: 15ch;
  height: 4ch;
  color: #ff6a00;
  border-radius: 8px;
  border: 2px solid #ff6a00;
  background-color: white;
  margin-left: 15px;

  &:hover {
    background-color: #fac8b2;
    color: #ff6a00;
  }
`;
export const StyledGroupButton = styled(Button)`
  width: 15ch;
  color: white;
  background-color: #ff6a00;
  border: 1px solid #ff6a00;

  //   &:hover {
  //     background-color: #FAC8B2;
  //     color: #FF6A00;
  //   }
  &:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`;
