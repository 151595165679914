import React from 'react';
import { CenteredGrid } from './CenteredComponent.styles';

/* eslint-disable no-undef */
interface Props {
  children: React.ReactNode;
}

const CenteredComponentView: React.FC<Props> = ({ children }) => {
  return <CenteredGrid>{children}</CenteredGrid>;
};

export default CenteredComponentView;
