import React from 'react';
import { FilterItemsContainer, RootContainer } from './KPIFilter.styles';
import { KPILeaderboardState } from '../_types';
import FilterDimensionValues from './filters/FilterDimensionValues';
import FilterTimeframe from './filters/FilterTimeframe';
import FilterDimensionType from './filters/FilterDimensionType';
import FilterTeams from './filters/FilterTeams';
// import FilterOrganization from './filters/FilterOrganization';

interface KPIFilterViewProps {
  kpiLeaderboardState: KPILeaderboardState;
  onChangeKpiLeaderboardState: (
    kpiLeaderboardState: KPILeaderboardState
  ) => void;
  resetTokens: () => void;
}

const KPIFilterView = ({
  kpiLeaderboardState,
  onChangeKpiLeaderboardState,
  resetTokens,
}: KPIFilterViewProps) => {
  return (
    <RootContainer>
      <div>
        <FilterItemsContainer>
          {/* <FilterOrganization
            kpiLeaderboardState={kpiLeaderboardState}
            onChangeKpiLeaderboardState={onChangeKpiLeaderboardState}
            resetTokens={resetTokens}
          /> */}
          <FilterTeams
            kpiLeaderboardState={kpiLeaderboardState}
            onChangeKpiLeaderboardState={onChangeKpiLeaderboardState}
            resetTokens={resetTokens}
          />
          <FilterDimensionType
            kpiLeaderboardState={kpiLeaderboardState}
            onChangeKpiLeaderboardState={onChangeKpiLeaderboardState}
            resetTokens={resetTokens}
          />
          <FilterTimeframe
            kpiLeaderboardState={kpiLeaderboardState}
            onChangeKpiLeaderboardState={onChangeKpiLeaderboardState}
            resetTokens={resetTokens}
          />
          <FilterDimensionValues
            kpiLeaderboardState={kpiLeaderboardState}
            onChangeKpiLeaderboardState={onChangeKpiLeaderboardState}
            resetTokens={resetTokens}
          />
        </FilterItemsContainer>
      </div>
    </RootContainer>
  );
};

export default KPIFilterView;
