import React, { useState } from 'react';
import { ViewContainer } from './Rules.styles';
import {
  InputBodyContainer,
  InputTitleContainer,
  InputTitleIcon,
  InputTitleText,
} from '../../NewGame.styles';
import ItemSelectInput from '../../custom-inputs/ItemSelectInput';
import CustomSelect from '../../custom-inputs/CustomSelect';
import CustomToggleSwitch from '../../custom-inputs/CustomToggleSwitch';
import NewGameState from '../../_types/NewGameState';
import CustomTextInput from '../../custom-inputs/CustomTextInput';
import { Box, Typography } from '@mui/material';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  kpis: { label: string; value: string; rule: any }[];
}

interface KPI {
  label: string;
  value: string;
  rule: any;
}

function NumberInput(props: any) {
  const [text, setText] = useState(props.value);
  return (
    <CustomTextInput
      label={props.label}
      onChange={(newValue) => {
        // Regex to check if newValue is a number or not (includes decimals, using parseFloat prevents reliable usage on isNaN for checking this)
        const isNumber = /^-?\d*\.?\d*$/.test(newValue);
        if (newValue === '' || isNumber) {
          setText(newValue);
          props.onChangeText(newValue);
        }
      }}
      value={text}
    />
  );
}

const renderMultipleKpiInputs = (
  newGameState: NewGameState,
  onChangeNewGameState: (newGameState: NewGameState) => void,
  kpis: { label: string; value: string; rule: any }[],
  normalizedKPIs: any
): React.JSX.Element => {
  const missionRules = newGameState.rules || [];

  const renderKPIList = missionRules.map((_missionRule, ruleNum) => (
    <Box key={ruleNum}>
      <CustomSelect
        label="KPI"
        value={_missionRule.id}
        onChange={(newValue: any) => {
          if (newGameState.useMultipleKPIs) {
            const _missionRule = {
              id: newValue,
              multiplier: 1.0,
              name: normalizedKPIs[newValue].label,
              role: 'contestant',
            };
            const _missionRules = missionRules || [];
            _missionRules.push(_missionRule);
            onChangeNewGameState({
              ...newGameState,
              rules: _missionRules,
              action: normalizedKPIs[newValue].rule.action,
              actionTypePlural: normalizedKPIs[newValue].rule.valueUnitPlural,
              actionPastTense: normalizedKPIs[newValue].rule.actionPastTense,
              actionType: normalizedKPIs[newValue].rule.valueUnit,
              KPIRuleID: newValue,
              KPIRuleName: normalizedKPIs[newValue].label,
            });

            // setFieldValue("missionRules", _missionRules);
            // setFieldValue("action", rule.action);
            // setFieldValue("actionTypePlural", rule.valueUnitPlural);
            // setFieldValue("actionPastTense", rule.actionPastTense);
            // setFieldValue("actionType", rule.valueUnit);
            // setFieldValue("KPIRuleID", value);
            // setFieldValue("KPIRuleName", name);
            // // setUpdate(update + 1);
          } else {
            // setFieldValue("KPIRuleID", value);
            // setFieldValue("KPIRuleName", name);
            onChangeNewGameState({
              ...newGameState,
              KPIRuleID: newValue,
              KPIRuleName: normalizedKPIs[newValue].label,
            });
          }
        }}
        items={kpis}
      />
      <Box
        onClick={() => removeKpiRule(ruleNum)}
        style={{ alignSelf: 'flex-end', marginTop: -20, marginBottom: 10 }}
      >
        <Typography
          style={{ color: 'red', fontSize: 12 }}
          sx={{ cursor: 'pointer' }}
        >
          Remove
        </Typography>
      </Box>

      <Box
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: 15,
          paddingRight: 10,
          justifyContent: 'space-between',
          marginTop: 10,
        }}
      >
        {/* <Typography style={{ fontSize: 12 }}>
          Points Per {_missionRule.name}{" "}
        </Typography>

        <NumberInput
          onChangeText={(text) => {
            let _missionRules = missionRules || [];
            _missionRules[ruleNum].multiplier = parseFloat(text);
            setFieldValue("missionRules", _missionRules);
          }}
          value={_missionRule.multiplier?.toString()}
          placeholder="Multiplier (default 1.0)"
        /> */}
        <NumberInput
          label={`Points Per ${_missionRule.name}${' '}`}
          value={_missionRule.multiplier?.toString()}
          onChangeText={(newValue: string) => {
            // console.log('newValue: ', newValue);

            const _missionRules = missionRules || [];
            _missionRules[ruleNum].multiplier = parseFloat(newValue);
            onChangeNewGameState({
              ...newGameState,
              rules: _missionRules,
            });
          }}
        />
      </Box>
      <Box
        style={{
          flex: 1,
          flexDirection: 'row',
          borderBottomWidth: 0.5,
          borderBottomColor: '#D9D9D9',
          alignItems: 'center',
          paddingLeft: 15,
          paddingBottom: 16,
          paddingRight: 10,
          justifyContent: 'space-between',
          marginTop: 10,
        }}
      >
        {/* <Typography style={{ fontSize: 12 }}>
          User's Role
        </Typography> */}
        <CustomSelect
          label="User's Role"
          value={_missionRule.role}
          onChange={(newValue) => {
            const _missionRules = missionRules || [];
            _missionRules[ruleNum].role = newValue;
            onChangeNewGameState({
              ...newGameState,
              rules: _missionRules,
            });
          }}
          items={[
            {
              label: 'Anyone',
              value: 'contestant',
            },
            {
              label: 'Setter',
              value: 'setter',
            },
            {
              label: 'Closer',
              value: 'closer',
            },
          ]}
        />
      </Box>
    </Box>
  ));

  const removeKpiRule = (index: number) => {
    const _missionRules = [...missionRules];
    _missionRules.splice(index, 1);
    onChangeNewGameState({
      ...newGameState,
      rules: _missionRules,
    });
    // setFieldValue("missionRules", _missionRules);
  };

  return (
    <>
      {renderKPIList}

      <CustomSelect
        label="KPI"
        value={newGameState.useMultipleKPIs ? '' : newGameState.KPIRuleID}
        onChange={(newValue: any) => {
          if (newGameState.useMultipleKPIs) {
            const _missionRule = {
              id: newValue,
              multiplier: 1.0,
              name: normalizedKPIs[newValue].label,
              role: 'contestant',
            };
            const _missionRules = missionRules || [];
            _missionRules.push(_missionRule);
            onChangeNewGameState({
              ...newGameState,
              rules: _missionRules,
              action: normalizedKPIs[newValue].rule.action,
              actionTypePlural: normalizedKPIs[newValue].rule.valueUnitPlural,
              actionPastTense: normalizedKPIs[newValue].rule.actionPastTense,
              actionType: normalizedKPIs[newValue].rule.valueUnit,
              KPIRuleID: newValue,
              KPIRuleName: normalizedKPIs[newValue].label,
            });

            // setFieldValue("missionRules", _missionRules);
            // setFieldValue("action", rule.action);
            // setFieldValue("actionTypePlural", rule.valueUnitPlural);
            // setFieldValue("actionPastTense", rule.actionPastTense);
            // setFieldValue("actionType", rule.valueUnit);
            // setFieldValue("KPIRuleID", value);
            // setFieldValue("KPIRuleName", name);
            // // setUpdate(update + 1);
          } else {
            // setFieldValue("KPIRuleID", value);
            // setFieldValue("KPIRuleName", name);
            onChangeNewGameState({
              ...newGameState,
              KPIRuleID: newValue,
              KPIRuleName: normalizedKPIs[newValue].label,
            });
          }
        }}
        items={kpis}
      />
    </>
  );
};

const RulesView: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
  kpis,
}) => {
  // Needed for accessing the rule on a KPI when selecting a rule.
  const normalizeKPIs = (kpis: KPI[]): Record<string, KPI> => {
    return kpis.reduce((acc, kpi) => {
      acc[kpi.value] = kpi;
      return acc;
    }, {} as Record<string, KPI>);
  };

  const normalizedKPIs = normalizeKPIs(kpis);

  // console.log('normalizedKPIs: ', normalizedKPIs);

  return (
    <ViewContainer>
      <InputTitleContainer>
        <InputTitleText>Rules</InputTitleText>
        <InputTitleIcon src="/images/trophy.svg" />
      </InputTitleContainer>
      <InputBodyContainer>
        <CustomToggleSwitch
          label="Use Multiple KPIs"
          value={newGameState.useMultipleKPIs}
          onChange={(newValue: any) => {
            // To match mobile logic: reset existing missionRules and alert user when turning on
            if (newValue) {
              onChangeNewGameState({
                ...newGameState,
                rules: [],
              });
              alert(
                "If your game has multiple KPI's, you will be required to define the point value of each KPI in the game."
              );
            }

            onChangeNewGameState({
              ...newGameState,
              useMultipleKPIs: newValue,
            });
          }}
        />
        {newGameState.useMultipleKPIs ? (
          renderMultipleKpiInputs(
            newGameState,
            onChangeNewGameState,
            kpis,
            normalizedKPIs
          )
        ) : (
          <CustomSelect
            label="KPI*"
            value={newGameState.KPIRuleID}
            onChange={(newValue: any) => {
              // console.log('newValue: ', newValue);
              onChangeNewGameState({ ...newGameState, KPIRuleID: newValue });
            }}
            items={kpis}
          />
        )}
        <CustomSelect
          label="Tie Breaker KPI"
          value={newGameState.tieKPIRuleID}
          onChange={(newValue: any) => {
            onChangeNewGameState({ ...newGameState, tieKPIRuleID: newValue });
          }}
          items={kpis}
        />
        <ItemSelectInput
          label="Goal"
          selectedItem={
            newGameState.isActionCountCustom
              ? 'custom'
              : newGameState.actionCount
          }
          onSelect={(newSelectedItem: { label: string; value: any }) => {
            if (newSelectedItem.value === 'custom') {
              onChangeNewGameState({
                ...newGameState,
                isActionCountCustom: true,
                actionCount: '',
              });
            } else {
              onChangeNewGameState({
                ...newGameState,
                isActionCountCustom: false,
                actionCount: newSelectedItem.value,
              });
            }
          }}
          items={[
            { label: '10', value: '10' },
            { label: '25', value: '25' },
            { label: '50', value: '50' },
            { label: 'Custom', value: 'custom' },
          ]}
          numColumns={4}
        />
        {newGameState.isActionCountCustom && (
          <CustomTextInput
            label="Custom Goal"
            placeholder="E.g. 15"
            value={newGameState.actionCount}
            onChange={(newValue) => {
              onChangeNewGameState({ ...newGameState, actionCount: newValue });
            }}
          />
        )}
      </InputBodyContainer>
    </ViewContainer>
  );
};

export default RulesView;
