import React, { useEffect, useState } from 'react';
import { View, Text } from '@aws-amplify/ui-react';
import {
  Box,
  Backdrop,
  Avatar,
  Checkbox,
  InputAdornment,
  TextField,
  CircularProgress,
} from '@mui/material';
import { StyledSelect, StyledMenuItem } from './StyledInputs';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ClearIcon from '@mui/icons-material/Clear';
import { API } from 'aws-amplify';
import getUserAvatar from '../../common/utils/getUserAvatar';
import getSentenceInitials from '../../common/utils/getSentenceInitials';
import gql from 'graphql-tag';
import './reports.css';
import { useNavigate } from 'react-router';
import { DateRangePicker } from './reportsUtils';

import ReportsCharts from './ReportsCharts';

export default function ReportsScreen() {
  const navigate = useNavigate();
  const orgID = global.me.orgID;
  const now = new Date();
  const reportTypes = [
    { value: 'org', title: 'Organization' },
    { value: 'team', title: 'Teams' },
    { value: 'user', title: 'Users' },
    { value: 'hourly', title: 'Hourly' },
  ];

  const [searchString, setSearchString] = useState('');
  const [showReportConfig, setShowReportConfig] = useState(true);
  const [gettingReports, setGettingReports] = useState(false);
  const [usersList, setUsersList] = useState(null);
  const [checkedUsers, setCheckedUsers] = useState({});
  const [teamsList, setTeamsList] = useState(null);
  const [checkedTeams, setCheckedTeams] = useState({});
  const [kpisList, setKpisList] = useState(null);
  const [checkedKpis, setCheckedKpis] = useState({});
  const [reportType, setReportType] = useState('org');
  const [modalType, setModalType] = useState(null);
  const [rangeCode, setRangeCode] = useState('T');
  const [canSubmit, setCanSubmit] = useState(false);
  const [fromTime, setFromTime] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate())
  );
  const [toTime, setToTime] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)
  );

  const modalConfiguration = {
    user: {
      type: 'users',
      fetchFunction: async (searchTerm) => {
        const searchUsersQuery = gql`
          query MyQuery(
            $orgID: ID!
            $searchString: String!
            $nextToken: String
          ) {
            searchUsers(
              filter: {
                name: { wildcard: $searchString }
                orgID: { eq: $orgID }
                status: { ne: "deleted" }
              }
              limit: 750
              sort: { direction: asc, field: name }
              nextToken: $nextToken
            ) {
              items {
                id
                birthdate
                email
                imageName
                imageType
                initials
                location
                name
                functionalRoles
                phoneNumber
                pronoun
                role
                status
                username
                title
                avatarID
                levelID
                createdAt
                orgs {
                  items {
                    id
                    status
                    memberType
                    orgID
                  }
                }
              }
              nextToken
              total
            }
          }
        `;
        const usersList = [];
        let nextToken = null;
        do {
          const searchResponse = await API.graphql({
            query: searchUsersQuery,
            variables: {
              orgID: global.me.orgID,
              searchString: searchTerm || '*',
              nextToken,
            },
          });
          usersList.push(...searchResponse.data.searchUsers.items);
          nextToken = searchResponse.data.searchUsers.nextToken;
        } while (nextToken);

        if (usersList?.length) {
          const usersWithAvatar = await Promise.all(
            usersList.map(async (original) => {
              const avatar = await getUserAvatar(
                original?.imageName,
                original?.imageType,
                true
              );
              const initials =
                original.initials || getSentenceInitials(original?.name);

              return {
                ...original,
                avatar,
                initials,
              };
            })
          );
          setUsersList(usersWithAvatar);
        }
      },
      optionsList: usersList,
      checkedList: checkedUsers,
      checkedListSetter: (id, name) => {
        const temp = { ...checkedUsers };
        if (temp[id]) {
          delete temp[id];
        } else {
          temp[id] = name;
        }
        setCheckedUsers(temp);
      },
      useAvatar: true,
      selectAll: () =>
        setCheckedUsers(
          usersList.reduce((acc, el) => {
            acc[el.id] = el.name;
            return acc;
          }, {})
        ),
      clearAll: () => setCheckedUsers({}),
      fetchOnStringChange: true,
    },
    team: {
      type: 'teams',
      fetchFunction: async (searchTerm) => {
        const myQuery = gql`
          query myQuery(
            $orgID: ID!
            $searchString: String!
            $nextToken: String
          ) {
            searchCircles(
              filter: {
                name: { wildcard: $searchString }
                orgID: { eq: $orgID }
                isDeleted: { eq: false }
              }
              limit: 750
              nextToken: $nextToken
              sort: { direction: asc, field: name }
            ) {
              nextToken
              items {
                approverType
                avatarImageName
                id
                imageName
                imageType
                inviterType
                isActive
                launchDate
                name
                status
                levelID
              }
              nextToken
              total
            }
          }
        `;
        const ourTeamsList = [];
        let nextToken = null;
        do {
          const res = await API.graphql({
            query: myQuery,
            variables: {
              orgID,
              searchString: searchTerm || '*',
              nextToken,
            },
          });

          ourTeamsList.push(...res.data.searchCircles.items);
          nextToken = res?.data?.searchCircles?.nextToken;
        } while (nextToken);

        for (const circle of ourTeamsList) {
          //  Fetching avatar image
          circle.avatar = await getUserAvatar(circle.imageName);
          circle.initials = getSentenceInitials(circle.name);
        }
        setTeamsList(ourTeamsList);
      },
      optionsList: teamsList,
      checkedList: checkedTeams,
      checkedListSetter: (id, name) => {
        const temp = { ...checkedTeams };
        if (temp[id]) {
          delete temp[id];
        } else {
          temp[id] = name;
        }
        setCheckedTeams(temp);
      },
      useAvatar: true,
      selectAll: () =>
        setCheckedTeams(
          teamsList.reduce((acc, el) => {
            acc[el.id] = el.name;
            return acc;
          }, {})
        ),
      clearAll: () => setCheckedTeams({}),
      fetchOnStringChange: true,
    },
    kpi: {
      type: 'KPIs',
      fetchFunction: async () => {
        const myQuery = gql`
          query MyQuery($orgID: ID!, $nextToken: String = null) {
            listKPIRules(
              filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
              limit: 10000
              nextToken: $nextToken
            ) {
              nextToken
              items {
                id
                title
                order
                action
                sourceType
                triggerID
                formula
                dimensions
                isHidden
                stage {
                  title
                }
                disposition {
                  title
                }
                goals {
                  items {
                    id
                  }
                }
              }
            }
          }
        `;
        let nextToken = null;
        const KPIRules = [];
        do {
          const listKPIRulesRet = await API.graphql({
            query: myQuery,
            variables: { orgID, nextToken },
          });
          KPIRules.push(...listKPIRulesRet.data.listKPIRules.items);
          nextToken = listKPIRulesRet.data.listKPIRules.nextToken;
        } while (nextToken !== null);

        KPIRules.forEach((el) => (el.name = el.title));

        const sortedKPIRules = KPIRules.sort((a, b) => {
          if (a.sourceType.toLowerCase() < b.sourceType.toLowerCase())
            return -1;
          if (a.sourceType.toLowerCase() > b.sourceType.toLowerCase()) return 1;
          return 0;
        });
        setKpisList(sortedKPIRules);
      },
      optionsList: kpisList,
      checkedList: checkedKpis,
      checkedListSetter: (id, name) => {
        const temp = { ...checkedKpis };
        if (temp[id]) {
          delete temp[id];
        } else {
          temp[id] = name;
        }
        setCheckedKpis(temp);
      },
      useAvatar: false,
      selectAll: () =>
        setCheckedKpis(
          kpisList.reduce((acc, el) => {
            acc[el.id] = el.name;
            return acc;
          }, {})
        ),
      clearAll: () => setCheckedKpis({}),
      fetchOnStringChange: false,
    },
  };
  const [myReports, setMyReports] = useState(null);

  const handleRunReport = async () => {
    if (fromTime >= toTime) {
      alert("Invalid Time Period - From date can't be later than to date");
      return;
    }
    setGettingReports(true);
    const ourPayload = { org_id: orgID, report_type: reportType, dates: {} };

    ourPayload.KPIs = Object.keys(checkedKpis);
    if (['user', 'hourly'].includes(reportType)) {
      ourPayload.users = Object.keys(checkedUsers);
    }
    if (reportType === 'team') {
      ourPayload.teams = Object.keys(checkedTeams);
    }

    const fromTimeUTC = new Date(
      fromTime.getTime() - fromTime.getTimezoneOffset() * 60000
    );
    const toTimeUTC = new Date(
      toTime.getTime() - toTime.getTimezoneOffset() * 60000
    );

    ourPayload.dates.from = fromTimeUTC.toISOString();
    ourPayload.dates.to = toTimeUTC.toISOString();
    // console.log('ourPayload');
    // console.log(ourPayload);
    try {
      const url = 'https://api.gogamify.com/report/kpireport';
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ourPayload),
      });
      const jsRes = await res.json();
      setMyReports(jsRes);
      setShowReportConfig(false);
    } catch (err) {
      console.log('Server Error');
      console.log(err);
    }
    setGettingReports(false);
  };

  const reportConfigString = () => {
    let temp = reportType[0].toUpperCase() + reportType.slice(1) + ' Report > ';
    if (reportType !== 'hourly') {
      const kpis = Object.values(checkedKpis);
      temp += kpis.slice(0, 3).join(', ');
      if (kpis.length >= 3) temp += '...';
      temp += ' > ';
    }
    if (reportType === 'user' || reportType === 'hourly') {
      const users = Object.values(checkedUsers);
      temp += users.slice(0, 3).join(', ');
      if (users.length >= 3) temp += '...';
      temp += ' > ';
    }
    if (reportType === 'team') {
      const teams = Object.values(checkedTeams);
      temp += teams.slice(0, 3).join(',');
      if (teams.length >= 3) temp += '...';
      temp += ' > ';
    }
    const newFrom = fromTime.toISOString().split('T')[0].split('-');
    temp += `${newFrom[1]}/${newFrom[2]}/${newFrom[0]} - `;
    const newTo = toTime.toISOString().split('T')[0].split('-');
    temp += `${newTo[1]}/${newTo[2]}/${newTo[0]}`;
    if (reportType === 'hourly') {
      temp += ` > ${fromTime.getHours()}:00 - ${toTime.getHours()}:00`;
    }

    return temp;
  };

  const exportFullReportCSV = () => {
    if (!myReports) return;
    let csvString = `,User Name,Date,Time,Pin,Address,Distance From Door\r\n`;
    myReports.full_hourly_report.forEach((dataset, i) => {
      const addressWithCommas = `"${dataset.address}"`;

      csvString += `${i + 1},${dataset.user_name},${dataset.pin_date},${
        dataset.pin_time
      },${dataset.pin},${addressWithCommas},${
        dataset.distance_from_door || 'Not Recorded'
      }\r\n`;
    });

    let exportFileName = ``;
    exportFileName += `${myReports.reports[0].report_info.report_name}`;
    exportFileName += `_${
      myReports.reports[myReports.reports.length - 1].report_info.report_name
    }`;
    exportFileName += `_pin_dropping_report_${myReports.org_id}`;

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', exportFileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    if (global.me.role !== 'admin' || !global.me.isAdmin) navigate(-1);
  }, []);

  useEffect(() => {
    if (Object.values(checkedKpis).length === 0 && reportType !== 'hourly') {
      setCanSubmit(false);
      return;
    }
    if (reportType === 'team' && Object.values(checkedTeams).length === 0) {
      setCanSubmit(false);
      return;
    }
    if (
      ['user', 'hourly'].includes(reportType) &&
      Object.values(checkedUsers).length === 0
    ) {
      setCanSubmit(false);
      return;
    }
    setCanSubmit(true);
  }, [checkedKpis, checkedTeams, checkedUsers, reportType, fromTime, toTime]);
  return (
    <React.Fragment>
      <View
        style={{
          minHeight: '100vh',
          paddingBottom: '50px',
          backgroundColor: '#E5E5E5',
        }}
      >
        {myReports && myReports.report_type === 'hourly' && (
          <Box
            className="generate-button export_full_report_button hov"
            name="my_box"
            style={{
              width: 'auto',
              bottom: 15,
              right: 15,
            }}
            onClick={exportFullReportCSV}
          >
            Merge and Export reports
          </Box>
        )}
        <Box className="report-generator-box">
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: '2rem',
            }}
            // onClick={() => console.log(myReports)}
          >
            Report Builder
          </Text>
          {showReportConfig ? (
            <Box style={{ marginTop: '25px' }}>
              <Box name="options-main-container">
                <Box>
                  <Box
                    name="line-one"
                    style={{
                      display: 'flex',
                      gap: '1rem',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Box>
                      <Text style={{ fontWeight: 'bold', marginBottom: '2px' }}>
                        Report Type
                      </Text>
                      <StyledSelect
                        value={reportType}
                        onChange={(e) => setReportType(e.target.value)}
                      >
                        {reportTypes.map((opt, i) => (
                          <StyledMenuItem value={opt.value} key={i}>
                            {opt.title}
                          </StyledMenuItem>
                        ))}
                      </StyledSelect>
                    </Box>
                    <DateRangePicker
                      fromTime={fromTime}
                      setFromTime={setFromTime}
                      toTime={toTime}
                      setToTime={setToTime}
                      reportType={reportType}
                      rangeCode={rangeCode}
                      setRange={(v) => setRangeCode(v)}
                    />
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Box
                      name="line-two"
                      style={{
                        marginTop: '1rem',
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'flex-end',
                        flexWrap: 'wrap',
                      }}
                    >
                      {reportType !== 'hourly' && (
                        <Box>
                          <Text
                            style={{ fontWeight: 'bold', marginBottom: '2px' }}
                          >
                            KPI Selection
                          </Text>
                          <Box
                            className="selection-box hov"
                            onClick={() => setModalType('kpi')}
                          >
                            {Object.keys(checkedKpis).length === 0 ? (
                              <Text>{"Click to select KPI's"}</Text>
                            ) : (
                              Object.values(checkedKpis).map((kpi, i) => (
                                <Box
                                  name={kpi}
                                  className="selected-item"
                                  key={i}
                                >
                                  {kpi}
                                </Box>
                              ))
                            )}
                          </Box>
                        </Box>
                      )}
                      {reportType === 'team' && (
                        <Box>
                          <Text
                            style={{ fontWeight: 'bold', marginBottom: '2px' }}
                          >
                            Team Selection
                          </Text>
                          <Box
                            className="selection-box hov"
                            onClick={() => setModalType('team')}
                          >
                            {Object.keys(checkedTeams).length === 0 ? (
                              <Text>Click to select Teams</Text>
                            ) : (
                              Object.values(checkedTeams).map((team, i) => (
                                <Box
                                  name={team}
                                  className="selected-item"
                                  key={i}
                                >
                                  {team}
                                </Box>
                              ))
                            )}
                          </Box>
                        </Box>
                      )}
                      {(reportType === 'user' || reportType === 'hourly') && (
                        <Box>
                          <Text
                            style={{ fontWeight: 'bold', marginBottom: '2px' }}
                            onClick={() => console.log(checkedUsers)}
                          >
                            User Selection
                          </Text>
                          <Box
                            className="selection-box hov"
                            onClick={() => setModalType('user')}
                          >
                            {Object.keys(checkedUsers).length === 0 ? (
                              <Text>Click to select Teams</Text>
                            ) : (
                              Object.values(checkedUsers).map((user, i) => (
                                <Box
                                  name={user}
                                  className="selected-item"
                                  key={i}
                                >
                                  {user}
                                </Box>
                              ))
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box
                      className={`generate-button ${canSubmit ? 'hov' : ''}`}
                      style={{
                        backgroundColor: canSubmit ? '#FF6A00' : 'lightgray',
                        color: canSubmit ? 'white' : 'gray',
                      }}
                      onClick={() => {
                        if (canSubmit) handleRunReport();
                      }}
                    >
                      {gettingReports ? <CircularProgress /> : 'Generate'}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Text
              style={{ fontWeight: 'bold', color: 'gray' }}
              onClick={() => setShowReportConfig(true)}
            >
              {reportConfigString()}
            </Text>
          )}
          {myReports && (
            <Box
              className="show-report-config-button hov"
              onClick={() => setShowReportConfig((p) => !p)}
            >
              {showReportConfig ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Box>
          )}
        </Box>

        {myReports && <ReportsCharts reports={myReports} />}
      </View>

      {modalType && (
        <SelectionModal
          config={modalConfiguration[modalType]}
          searchString={searchString}
          setSearchString={setSearchString}
          open={Boolean(modalType)}
          close={() => {
            setModalType(null);
            setSearchString('');
          }}
        />
      )}
    </React.Fragment>
  );
}

function SelectionModal({
  config,
  searchString,
  setSearchString,
  close,
  open,
}) {
  const {
    type,
    fetchFunction,
    optionsList,
    checkedList,
    checkedListSetter,
    useAvatar,
    selectAll,
    clearAll,
    fetchOnStringChange,
  } = config;

  const [localList, setLocalList] = useState(optionsList);

  const filterSearch = (el, term) => {
    return new RegExp(term, 'gi').test(el);
  };

  useEffect(() => {
    if (!optionsList) fetchFunction(searchString);
  }, []);

  useEffect(() => {
    setLocalList(optionsList);
  }, [optionsList]);

  useEffect(() => {
    if (!optionsList) return;
    if (fetchOnStringChange) {
      fetchFunction(searchString);
    } else {
      const newList = optionsList
        .filter((el) => filterSearch(el.title, searchString))
        .sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          } else if (a.title > b.title) {
            return 1;
          } else return 0;
        });
      setLocalList(newList);
    }
  }, [searchString]);

  return (
    <Backdrop
      open={open}
      onClick={close}
      sx={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box
        className="selection-modal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <Box className="selection-modal-header">{`Select ${type[0].toUpperCase()}${type.slice(
          1,
          type.length - 1
        )}(${type[type.length - 1]})`}</Box>
        <Box style={{ padding: '10px', height: '100%' }}>
          <Box position="relative">
            <TextField
              style={{ width: '100%', borderRadius: '5px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={`Search ${type}`}
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            {searchString !== '' && (
              <Box
                className="clear-search-icon hov"
                onClick={() => setSearchString('')}
              >
                <ClearIcon />
              </Box>
            )}
          </Box>
          <Box className="clear-select-all-container">
            <Box onClick={selectAll} className="clear-select-all hov">
              Select All
            </Box>
            {Object.values(checkedList).length > 0 ? (
              <Box className="clear-select-all hov" onClick={clearAll}>
                Clear All
              </Box>
            ) : null}
          </Box>
          <Box className="list-container">
            {localList ? (
              localList.map((option, index) => (
                <Box
                  key={index}
                  name={option.id}
                  className="hov menu-item"
                  onClick={() => checkedListSetter(option.id, option.name)}
                  style={{
                    backgroundColor: checkedList[option.id]
                      ? '#FAC8B2'
                      : 'white',
                    border: `1px solid ${
                      checkedList[option.id] ? '#FF6A00' : 'lightgray'
                    }`,
                  }}
                >
                  <Checkbox
                    sx={{
                      color: '#FF6A00',
                      '&.Mui-checked': {
                        color: '#FF6A00',
                      },
                    }}
                    checked={Boolean(checkedList[option.id]?.length) || false}
                  />
                  {useAvatar &&
                    (option.avatar ? (
                      <Avatar
                        src={option.avatar}
                        style={{ marginRight: '10px' }}
                      />
                    ) : (
                      <Avatar style={{ marginRight: '10px' }}>
                        {option.initials}
                      </Avatar>
                    ))}
                  <Text>{option.name}</Text>
                </Box>
              ))
            ) : (
              <Box
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
}
