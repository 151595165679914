import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import Utilities from '../common/Utilities';

export default class ScorecardRepository {
  static getDimension(type, inputNow) {
    // console.log('getDimension: ', type, inputNow);
    const now = new Date(Utilities.formatDate(inputNow, 'MM/DD/YY'));
    const curMonth = now.getUTCMonth() + 1;
    const curYear = now.getUTCFullYear();
    const curDayOfWeek = now.getUTCDay();
    // const curDay = now.getUTCDate();

    // console.log('type: ', type);
    // console.log('now: ', now);
    // console.log('curMonth: ', curMonth);
    // console.log('curYear: ', curYear);
    // console.log('curDayOfWeek: ', curDayOfWeek);
    // console.log('curDay: ', curDay);

    let monStr;
    if (curMonth < 10) {
      monStr = '0' + curMonth;
    } else {
      monStr = curMonth;
    }

    if (type === 'current') {
      return 'current:current';
    } else if (type === 'current_day' || type === 'select_day') {
      // setDateRange(Utilities.formatDate(now, "MM/DD/YY"));
      const nowDateStr = Utilities.formatDate(now, 'YYYY-MM-DD');
      return 'D:' + nowDateStr;
    } else if (type === 'last_day') {
      const nowDate = now;
      nowDate.setDate(now.getDate() - 1);
      // setDateRange(Utilities.formatDate(nowDate, "MM/DD/YY"));
      const nowDateStr = Utilities.formatDate(now, 'YYYY-MM-DD');
      return 'D:' + nowDateStr;
    } else if (
      type === 'current_week' ||
      type === 'select_week' ||
      type === 'last_week'
    ) {
      //
      //  The current week always starts on Monday
      //
      let weekStartDay = 0;
      if (global.appSettings.weekStartDay !== undefined) {
        //
        //  weekStartDay is the PYTHON week where 0=Monday, 6=Sunday
        //
        weekStartDay = global.appSettings.weekStartDay;
        //
        //  Convert to the Javascript week where 0=Sunday, 6=Saturday
        //
        weekStartDay += 1;
        if (weekStartDay > 6) {
          weekStartDay = 0;
        }
      }
      // console.log('weekStartDay: ', weekStartDay);

      const offset = (((curDayOfWeek - weekStartDay) % 7) + 7) % 7;
      const first = now.getDate() - offset;

      // console.log('+!+offset: ', offset);
      // console.log('first: ', first);
      // if ( curDayOfWeek === 0 ) {
      //   var first = now.getDate() -6; // First day is the day of the month - the day of the week
      // } else {
      //   var first = now.getDate() - now.getDay()+1; // First day is the day of the month - the day of the week
      // }
      // const last = first + 6; // last day is the first day + 6

      //
      //  if we're dealing with last week, subtract 7 from the first day and last day
      //
      let offsetDays = 0;
      if (type === 'last_week') {
        offsetDays = 7;
      }
      const firstday = new Date(now.setDate(first - offsetDays)).toUTCString();
      // const lastday = new Date(now.setDate(last - offsetDays)).toUTCString();

      // setDateRange(Utilities.formatDate(firstday, "MM/DD/YY") + " - " + Utilities.formatDate(lastday, "MM/DD/YY"));
      // console.log('firstday: ', firstday, lastday);
      // console.log('current week: ', 'W:' + firstday);
      const weekStart = Utilities.formatDate(firstday, 'YYYY-MM-DD');
      // console.log('weekStart: ', weekStart);
      return 'W:' + weekStart;
    } else if (type === 'best_all') {
      return 'A:BEST';
    } else if (type === 'best_year') {
      return 'Y:BEST';
    } else if (type === 'best_month') {
      return 'M:BEST';
    } else if (type === 'best_week') {
      return 'W:BEST';
    } else if (type === 'best_day') {
      return 'D:BEST';
    } else if (
      type === 'current_month' ||
      type === 'select_month' ||
      type === 'last_month'
    ) {
      let ourMonth = curMonth;
      let ourYear = curYear;
      if (type === 'last_month') {
        if (curMonth === 1) {
          ourMonth = 12;
          ourYear--;
        } else {
          ourMonth--;
        }
      }
      // const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      // setDateRange(MONTHS[ourMonth - 1] + " " + ourYear);

      let ourMonStr;
      if (ourMonth < 10) {
        ourMonStr = '0' + ourMonth;
      } else {
        ourMonStr = ourMonth;
      }
      return 'M:' + ourYear + '-' + ourMonStr;
    } else if (type === 'current_year' || type === 'select_year') {
      // setDateRange(curYear.toString());
      return 'Y:' + curYear;
    } else if (type === 'last_year') {
      // setDateRange((curYear - 1).toString());return ('Y:' + curYear - 1);
    } else if (type === 'current_pay_period' || type === 'select_pay_period') {
      //
      //  TODO: Calculate the current pay period start date
      //
      const diff = now.getDate() - curDayOfWeek + (curDayOfWeek === 0 ? -6 : 1); // adjust when day is sunday
      // console.log('diff: ', diff);

      const monday = new Date(now.setDate(diff)).getDate();
      // console.log('monday: ', monday);

      let dayStr;
      if (monday < 10) {
        dayStr = '0' + monday;
      } else {
        dayStr = monday;
      }
      const startDateString = curYear + '-' + monStr + '-' + dayStr;
      // const startDate = new Date(startDateString);
      // console.log('startDateString: ', startDateString);
      // console.log('startDate: ', startDate);
      // const endDate = new Date(startDate.setDate(startDate.getDate() + 7));
      // console.log('endDate: ', endDate);

      // setDateRange(Utilities.formatDate(startDateString, "MM/DD/YY") + " - " + Utilities.formatDate(endDate, "MM/DD/YY"));
      return 'P:' + startDateString;
    } else {
      // setDateRange(curYear.toString());
      return 'Y:' + curYear;
    }
  }

  static async fetchScorecard(userId, dimension) {
    console.log('fetchScorecard: ', userId, dimension);
    let stagesOnly = false;
    if (dimension.endsWith(':current:current')) {
      stagesOnly = true;
    }
    // const getPreferenceQuery = gql`
    //   query MyQuery($id: ID = "") {
    //     getPreferences(id: $id) {
    //       preferences
    //     }
    //   }`;

    const ourPreferences = [];
    // //
    // //  Get my preferences
    // //
    // const userPreferenceID = global.me.orgID + ":KPIBoard:" + "user" + ":" + global.me.id;
    // let preferenceRes = await API.graphql({query: getPreferenceQuery, variables: {id: userPreferenceID}});
    //
    // if (preferenceRes.data.getPreferences === null) {
    //     //
    //     //  No preferences for the user, so get the org preferences
    //     //
    //     const orgPreferenceID = global.me.orgID + ":KPIBoard:" + "org" + ":" + global.me.orgID;
    //     preferenceRes = await API.graphql({query: getPreferenceQuery, variables: {id: orgPreferenceID}});
    // }
    //
    // if (preferenceRes.data.getPreferences != null) {
    //     ourPreferences = JSON.parse(preferenceRes.data.getPreferences.preferences);
    // }

    // Get all of the possilbe KPI's
    //
    //  TODO:  Add an index here
    //
    const KPIQuery = gql`
      query MyQuery($orgID: ID!) {
        listKPIRules(
          filter: {
            orgID: { eq: $orgID }
            isDeleted: { eq: false }
            isHidden: { eq: false }
          }
          limit: 10000
        ) {
          items {
            id
            title
            sourceType
            action
            valueUnitPlural
            isDeleted
            triggerID
            format
            roundPrecision
          }
        }
      }
    `;
    const KPIRes = await API.graphql({
      query: KPIQuery,
      variables: { orgID: global.me.orgID },
    });
    const KPIList = {};
    for (const KPI of KPIRes.data.listKPIRules.items) {
      if (!(stagesOnly && KPI.sourceType !== 'stage')) {
        KPI.isFound = false;
        KPIList[KPI.id] = KPI;
      }
    }

    //
    //  Get all of the KPI's and trophies for this user
    //
    const queryListKPIs = gql`
      query MyQuery($userKey: ID!) {
        KPIValueAggsByUserKey(
          userKey: $userKey
          filter: { val: { ne: 0 } }
          limit: 10000
        ) {
          items {
            id
            userKey
            dimensionID
            dimension
            val
            updatedAt
            KPIRule {
              id
              categoryID
              title
              sourceType
              action
              valueUnitPlural
              isDeleted
              isHidden
              triggerID
              format
              roundPrecision
            }
          }
        }
      }
    `;
    const ourDimension = this.getDimension(
      dimension,
      Utilities.getLocalDateTime(new Date())
    );
    console.log('ourDimension: ', ourDimension);
    console.log('userKey: ', userId + ':' + ourDimension);

    const res = await API.graphql({
      query: queryListKPIs,
      variables: { userKey: userId + ':person:' + ourDimension },
    });
    const rawKPIValues = res.data.KPIValueAggsByUserKey.items;

    //
    // Update our KPIList with the KPI's where the user has a value
    //
    let KPIValues = [];
    for (const kpi of rawKPIValues) {
      if (!(kpi.KPIRule.isHidden || kpi.KPIRule.isDeleted)) {
        if (!(stagesOnly && kpi.KPIRule.sourceType !== 'stage')) {
          KPIValues[KPIValues.length] = kpi;
        }
      }
    }
    for (const kpi of KPIValues) {
      kpi.isHidden = false;
      if (Object.keys(KPIList).includes(kpi.KPIRule.id)) {
        KPIList[kpi.KPIRule.id].isFound = true;
      }
    }

    // For every KPI that does NOT have a value, append that KPI to the KPI's for the user, but with a 0 value

    for (const key of Object.keys(KPIList)) {
      if (!KPIList[key].isFound) {
        const fakeKPI = {
          dimensionID: KPIList[key].id,
          dimension: 'person:' + dimension,
          val: 0,
          updatedAt: '',
          KPIRule: {
            id: KPIList[key].id,
            title: KPIList[key].title,
            isDeleted: KPIList[key].isDeleted,
            sourceType: KPIList[key].sourceType,
            valueUnitPlural: KPIList[key].valueUnitPlural,
            action: KPIList[key].action,
            triggerID: KPIList[key].triggerID,
            format: KPIList[key].format,
            roundPrecision: KPIList[key].roundPrecision,
          },
        };

        KPIValues[KPIValues.length] = fakeKPI;
      }
    }

    const formattedKPIValues = [];
    for (const kpi of KPIValues) {
      console.log('!!!kpi.KPIRule.format: ', kpi.KPIRule.format);
      console.log(
        '!!!kpi.KPIRule.roundPrecision: ',
        kpi.KPIRule.roundPrecision
      );
      if (kpi.KPIRule.roundPrecision !== null) {
        kpi.val = kpi.val.toFixed(kpi.KPIRule.roundPrecision);
      }
      if (kpi.KPIRule.format === 'money') {
        kpi.val =
          '$ ' + kpi.val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        kpi.val = kpi.val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      formattedKPIValues[formattedKPIValues.length] = kpi;
    }
    let sortedKpis;
    if (ourPreferences.length === 0) {
      sortedKpis = formattedKPIValues.sort((a, b) => {
        if (a.KPIRule.title.toLowerCase() < b.KPIRule.title.toLowerCase()) {
          return -1;
        }
        if (a.KPIRule.title.toLowerCase() > b.KPIRule.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    } else {
      const ourNewKPIList = [];

      for (const kpi of formattedKPIValues) {
        const isRuleID = (element) => element.id === kpi.KPIRule.id;
        const index = ourPreferences.findIndex(isRuleID);
        if (index >= 0) {
          kpi.index = index;
          kpi.isHidden = ourPreferences[index].isHidden;
        } else {
          kpi.index = 1000;
          kpi.isHidden = false;
        }
        if (kpi.isHidden) {
          kpi.index = 9999;
        }
        console.log('!!!kpi.KPIRule.format: ', kpi.KPIRule.format);
        console.log('!!!kpi.KPIRule.precision: ', kpi.KPIRule.precision);

        ourNewKPIList[ourNewKPIList.length] = kpi;
      }
      KPIValues = ourNewKPIList;

      sortedKpis = KPIValues.sort((a, b) => {
        return a.index - b.index;
      });
    }
    return sortedKpis;
  }
}
