import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Application from './application/Application';
import reportWebVitals from './reportWebVitals';
import { AlertProvider } from 'react-alert-with-buttons';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AlertProvider containerStyle={{ borderRadius: 20, fontWeight: 500 }}>
      <Application />
    </AlertProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your application, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
