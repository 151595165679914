import gql from 'graphql-tag';
import { API } from 'aws-amplify';
import * as utils from '../../common/Utilities';
import Moment from 'moment';

async function fetchAllRewards(startBefore, endAfter) {
  try {
    // TODO:  get orgMember
    const orgMemberQuery = gql`
      query MyQuery($userID: ID!, $orgID: ID!) {
        OrgMemberByUser(userID: $userID, orgID: { eq: $orgID }) {
          items {
            orgPoints
            lifetimeOrgPoints
          }
        }
      }
    `;

    const orgMemberQueryRes = await API.graphql({
      query: orgMemberQuery,
      variables: {
        userID: global.me.id,
        orgID: global.me.orgID,
        limit: 100000,
      },
    });
    const orgPoints = orgMemberQueryRes.data.OrgMemberByUser.items[0].orgPoints;
    const lifeTimeOrgPoints =
      orgMemberQueryRes.data.OrgMemberByUser.items[0].lifeTimeOrgPoints;

    const newCircleMemberRewardQuery = gql`
      query ListCircleMembers(
        $userID: ID = "a8b8e888-a0cf-4a2d-9929-935428fb8010"
        $startBefore: String!
        $endAfter: String!
      ) {
        circleMemberByUserID(userID: $userID, limit: 10000) {
          items {
            circle {
              rewards(
                filter: {
                  launchDate: { le: $startBefore }
                  validTo: { ge: $endAfter }
                }
              ) {
                items {
                  isDeleted
                  approverType
                  cost
                  costType
                  description
                  id
                  imageName
                  imageType
                  isActive
                  launchDate
                  limitCount
                  location
                  name
                  ownerID
                  ownerType
                  personLimit
                  remainingCount
                  tags
                  rewardType
                  validFrom
                  validTo
                  value
                  valueType
                  createdBy
                  orgID
                  tangoAccountID
                }
              }
            }
          }
        }
      }
    `;

    // console.log('startBefore: ', startBefore);
    // console.log('endAfter: ', endAfter);
    const listCircleMembersRes = await API.graphql({
      query: newCircleMemberRewardQuery,
      variables: {
        userID: global.me.id,
        startBefore,
        endAfter,
      },
    });
    const circleMembers = listCircleMembersRes.data.circleMemberByUserID.items;

    const ourCircles = [];
    const ourRewards = [];
    let customRewardCount = 0;

    for (const circleMember of circleMembers) {
      const circle = circleMember.circle;
      if (circle == null) {
        continue;
      }
      const rewards = circle.rewards.items;
      // var ourRewards = [];

      for (const reward of rewards) {
        if (!reward.isDeleted) {
          const now = utils.getLocalDateTime();
          const validFrom = new Date(reward.validFrom);
          const gmtValidToDate = new Date(reward.validTo);
          const validTo = Moment(gmtValidToDate)
            .add(1, 'day')
            .subtract(1, 'second');

          reward.remaingDaysAvailable = utils.daysBetween(now, validTo);
          reward.daysBeforeAvailable = utils.daysBetween(now, validFrom);
          reward.secondsBeforeStart = utils.secondsBetween(now, validFrom);
          reward.remainingSecondsInMission = utils.secondsBetween(now, validTo);

          // TODO:  Use orgPoints
          reward.myCirclePoints = orgPoints;
          reward.purchaseCount = 0;
          reward.isVisible = true;
          if (reward.isVisible) {
            ourRewards[ourRewards.length] = reward;
          }
          if (reward.utid !== '') {
            customRewardCount += 1;
          }
        }
        ourCircles[0] = {
          name: '',
          // TODO:  Use orgPoints
          circlePoints: orgPoints,
          id: '',
          isActive: true,
          // TODO:  Use orgPoints
          lifetimeCirclePoints: lifeTimeOrgPoints,
          memberType: '',
          status: '',
          rewards: ourRewards,
        };
      }
    }

    // console.log('ourCircles', ourCircles);

    // setMyRewards(ourCircles);
    // setCustomRewardCount(customRewardCount);
    return [ourCircles, customRewardCount];
  } catch (err) {
    console.error('fetchAllRewards(): error fetching my Catalog', err.message);
  }
}

export default fetchAllRewards;
