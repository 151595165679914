import IndividualGameUser from '../_types/IndividualGameUser';
import TeamGameUser from '../_types/TeamGameUser';

const isIndividualGameUser = (
  gameUser: IndividualGameUser | TeamGameUser
): gameUser is IndividualGameUser => {
  return (gameUser as IndividualGameUser).user !== undefined;
};

export default isIndividualGameUser;
