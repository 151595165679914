import React from 'react';
import {
  HeaderBack,
  HeaderContainer,
  HeaderTitle,
  ViewContainer,
} from './Comments.styles';
import CommentType from '../_types/CommentType';
import Comment from '../Comment';
import AddComment from '../AddComment';
import AddCommentArgs from '../_types/AddCommentArgs';
import Me from '../../../_types/Me';
import MyPerson from '../_types/MyPerson';
import CenteredProgress from '../../../components/CenteredProgress';

interface Props {
  activityFeedID: string;
  title: string;
  comments: CommentType[];
  onAddComment: (args: AddCommentArgs) => Promise<void>;
  onNavigateBack: () => void;
  loading: boolean;
  onChangeSelectedComment: (comment: CommentType) => void;
  onDeleteComment: (deletedComment: CommentType) => void;
  me: Me;
  onChangeIsEditing: (newIsEditing: boolean) => void;
  myPersons: MyPerson[];
}

const CommentsView = ({
  activityFeedID,
  title,
  comments,
  onNavigateBack,
  onAddComment,
  loading,
  onDeleteComment,
  me,
  onChangeIsEditing,
  myPersons,
}: Props) => {
  return (
    <ViewContainer>
      <HeaderContainer>
        <HeaderBack onClick={onNavigateBack} src="/images/back.svg" />
        <HeaderTitle>{title}</HeaderTitle>
      </HeaderContainer>
      <AddComment
        onChangeIsEditing={onChangeIsEditing}
        activityFeedID={activityFeedID}
        onAddComment={onAddComment}
      />
      {loading && (
        <div style={{ margin: '20px 0' }}>
          <CenteredProgress />
        </div>
      )}
      {comments.map((comment) => {
        return (
          <Comment
            onChangeIsEditing={onChangeIsEditing}
            myPersons={myPersons}
            key={comment.id}
            commentId={comment.id}
            onDeleteComment={onDeleteComment}
            me={me}
          />
        );
      })}
    </ViewContainer>
  );
};

export default CommentsView;
