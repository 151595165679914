import React from 'react';
import RoundState from '../../../../_types/RoundState';
import RoundRewardsView from './RoundRewards.view';
import RewardType from '../../../../_types/RewardType';
import NewGameState from '../../../../_types/NewGameState';

interface Props {
  onChangeRoundState: (roundState: RoundState) => void;
  roundState: RoundState;
  rewards: RewardType[];
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  tabNo: number;
}

const RoundRewardsContainer: React.FC<Props> = ({
  onChangeRoundState,
  rewards,
  roundState,
  newGameState,
  onChangeNewGameState,
  tabNo,
}) => {
  return (
    <RoundRewardsView
      onChangeRoundState={onChangeRoundState}
      rewards={rewards}
      roundState={roundState}
      newGameState={newGameState}
      onChangeNewGameState={onChangeNewGameState}
      tabNo={tabNo}
    />
  );
};

export default RoundRewardsContainer;
