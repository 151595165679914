import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  padding: 125px 100px 40px 100px;
`;

export const LoadingContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorContainer = styled.div`
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 20;
  font-family: Manrope;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GamesDimensionTypeContainer = styled.div`
  padding: 6px;
  display: flex;
  gap: 4px;
  background-color: #f0f0f3;
  border-radius: 10px;
`;

export const GamesDimensionTypeTab = styled.div<{ isActive: boolean }>`
  font-family: Space Grotesk;
  color: ${(props) => (props.isActive ? '#FF6A00' : '#5F5F5F')};
  background-color: ${(props) => (props.isActive ? '#fff' : 'transparent')};
  text-align: center;
  padding: 10px 40px;
  border-radius: 10px;
  font-weight: 700;
  cursor: pointer;
`;

export const StatusFilterContainer = styled.div`
  display: flex;
  gap: 20px;
`;
// export const StatusFilterContainer = styled.div`
//   display: flex;
//   gap: 20px;
//   margin-top: 30px;
// `;

export const StatusFilterTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const StatusFilterTabText = styled.div<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? '#FF6A00' : '#5F5F5F')};
  font-weight: 700;
`;

export const StatusFilterTabIndicator = styled.div<{ isActive: boolean }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => (props.isActive ? '#FF6A00' : 'transparent')};
  border-radius: 100px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
// export const ButtonsContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   gap: 20px;
//   margin-top: 30px;
// `;

export const PreviousNextButton = styled.div<{ disabled: boolean }>`
  font-size: 38px;
  font-weight: 500;
  color: ${(props) => (props.disabled ? '#f0f0f3' : '#FF6A00')};
  cursor: pointer;
`;

export const GamesAddIconContainer = styled.div`
  position: relative;
  align-self: flex-end;
  margin-bottom: 20px;
`;

export const GamesAddIcon = styled.img`
  width: 30;
  height: 30;
  cursor: pointer;
`;

export const AddModalContainer = styled.div`
  position: absolute;
  min-width: 360px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  right: 0;

  /* @media (min-width: 768px) {
    min-width: 300px;
  } */
`;

export const AddModalTitle = styled.div`
  font-family: Space Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
`;

export const AddModalItem = styled.div`
  padding: 10px;
  background-color: #f0f0f3;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  justify-content: space-between;
`;

export const AddModalItemLeftSide = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const AddModalItemRightSide = styled.div`
  display: flex;
  align-self: center;
`;

export const AddModalIcon = styled.div`
  padding: 6px;
  background-color: #fff;
  display: flex;
  border-radius: 10px;
`;

export const AddModalItemTitle = styled.div<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? '#CCCCCC' : '#FF6A00')};
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

export const NoGameContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
`;

export const NoGameIcon = styled.img`
  width: 150px;
  height: 150px;
`;

export const NoGameText = styled.div`
  font-size: 20px;
  font-family: Space Grotesk;
  font-weight: 500;
`;

export const CreateNewButton = styled.div`
  background-color: #ff6a00;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-family: Manrope;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  color: #fff;

  @media (min-width: 600px) {
    width: 220px;
  }
`;
