import React from 'react';
import { Box, Typography } from '@mui/material';

export default function BetaIcon() {
  return (
    <Box
      style={{
        backgroundColor: '#060606',
        width: 'fit-content',
        height: 'fit-content',
        padding: '4px 8px 4px 8px',
        borderRadius: 2004,
      }}
    >
      <Typography
        style={{ color: '#FFFFFF', fontSize: '12px', fontWeight: 700 }}
      >
        Beta
      </Typography>
    </Box>
  );
}
