import Emoticon from '../_types/Emoticon';

const didUserReactWithParticularEmoticon = (args: {
  userId: string;
  emoticons: Emoticon[];
  type: string;
}): boolean => {
  const { emoticons, type, userId } = args;
  return emoticons.some(
    (item) => item.type === type && item.user.id === userId
  );
};

export default didUserReactWithParticularEmoticon;
