import React, { useRef, useState } from 'react';
import {
  CustomTextArea,
  DetailsContainer,
  FilePreviewContainer,
  FilePreviewCloseIcon,
  FilterPersonsList,
  FilterPersonsListItem,
  IconsContainer,
  InputAndActionsContainer,
  PostButton,
  ViewContainer,
} from './CreatePost.styles';
import MyPerson from '../_types/MyPerson';
import UserProfilePicture from '../../../components/UserProfilePicture';
import { Icon } from '../FeedItem/contents/Actions/Actions.styles';
import GifSearch from '../GifSearch';
import Me from '../../../_types/Me';
import CreatePostArgs from '../_types/CreatePostArgs';
import { LOG } from '../../../common/Utilities';
import { GamifyToast } from '../../../common/CustomToasts';
import { useAlert } from 'react-alert-with-buttons';
import CenteredProgress from '../../../components/CenteredProgress';

interface Props {
  post: CreatePostArgs;
  me: Me;
  myPersons: MyPerson[];
  filePreview: any;
  openGif: boolean;
  onChangePost: (post: CreatePostArgs) => void;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenCloseGif: () => void;
  onSavePost: () => Promise<void>;
  onRemoveFilePreview: () => void;
}

const CreatePostView = ({
  filePreview,
  me,
  myPersons,
  onChangePost,
  onOpenCloseGif,
  onSavePost,
  onUpload,
  openGif,
  post,
  onRemoveFilePreview,
}: Props) => {
  const uploadRef = useRef<any>();
  const [filteredMyPersons, setFilteredMyPersons] = useState<MyPerson[]>([]);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const handleTextChange = (value: string) => {
    onChangePost({ ...post, header: value });

    const lastWord = (value.split(' ').pop() || '').toLowerCase();

    if (lastWord.startsWith('@')) {
      setFilteredMyPersons(
        myPersons.filter((myPerson) =>
          myPerson.name.toLowerCase().includes(lastWord.slice(1))
        )
      );
    } else {
      setFilteredMyPersons([]);
    }
  };

  return (
    <>
      <ViewContainer>
        <UserProfilePicture imageName={me.imageName || ''} />
        <DetailsContainer>
          <CustomTextArea
            maxLength={255}
            value={post.header}
            onChange={(e) => handleTextChange(e.target.value)}
            placeholder={`What's happening?`}
          />
          {filePreview ? (
            <FilePreviewContainer>
              <FilePreviewCloseIcon
                onClick={(e) => {
                  e.stopPropagation();

                  alert.open({
                    message: 'Are you sure you wish to remove this attachment?',
                    buttons: [
                      {
                        label: 'Yes',
                        onClick: () => {
                          onRemoveFilePreview();
                          onChangePost({
                            ...post,
                            imageName: '',
                            imageType: '',
                          });
                          uploadRef.current.value = '';
                          alert.close();
                        },
                        style: {
                          borderRadius: 16,
                          backgroundColor: '#FF6A00',
                          marginRight: 20,
                          color: '#fff',
                          fontWeight: 700,
                        },
                      },
                      {
                        label: 'No',
                        onClick: () => alert.close(),
                        style: {
                          borderRadius: 16,
                          backgroundColor: '#FF5C77',
                          color: '#fff',
                          fontWeight: 700,
                        },
                      },
                    ],
                  });
                }}
                src="/images/close.svg"
              />
              {post.imageType === 'video' ? (
                <video width="320" height="240" controls>
                  <source src={filePreview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={filePreview} alt="Preview" width="200" />
              )}
            </FilePreviewContainer>
          ) : null}

          <InputAndActionsContainer>
            <IconsContainer>
              <input
                onChange={(e) => onUpload(e)}
                style={{ display: 'none' }}
                accept="image/*,video/*"
                type="file"
                ref={(ref) => (uploadRef.current = ref)}
              />
              <div style={{ position: 'relative' }}>
                <Icon
                  onClick={() => handleTextChange(post.header + '@')}
                  src="/images/icon-at.svg"
                />
                {filteredMyPersons.length > 0 ? (
                  <FilterPersonsList>
                    {filteredMyPersons.map((myPerson) => (
                      <FilterPersonsListItem
                        onClick={() => {
                          const currentText = post.header;
                          const newText = currentText.replace(
                            /@\w*$/,
                            `@${myPerson.name} `
                          );
                          handleTextChange(newText);
                        }}
                        key={myPerson.id}
                      >
                        <UserProfilePicture imageName={myPerson.imageName} />
                        <div style={{ fontWeight: 600 }}>{myPerson.name}</div>
                      </FilterPersonsListItem>
                    ))}
                  </FilterPersonsList>
                ) : null}
              </div>

              <Icon
                onClick={() => uploadRef.current.click()}
                src="/images/icon-image.svg"
              />
              <div style={{ position: 'relative' }}>
                <Icon onClick={onOpenCloseGif} src="/images/icon-gif.svg" />
                <GifSearch
                  open={openGif}
                  onOpenClose={onOpenCloseGif}
                  onSelect={(gif: any) => {
                    onChangePost({
                      ...post,
                      imageName: gif.url,
                      imageType: 'gif_url',
                    });
                  }}
                />
              </div>
            </IconsContainer>
            <PostButton
              onClick={async (e) => {
                e.preventDefault();
                if (post.header.length < 1 && !post.imageName) {
                  GamifyToast.error('Your post is empty');
                  return;
                }

                try {
                  setLoading(true);
                  await onSavePost();

                  setTimeout(() => {
                    setLoading(false);
                    GamifyToast.success('Content posted');
                  }, 4000);
                } catch (error) {
                  LOG.debug(error);
                }
              }}
            >
              Post
            </PostButton>
          </InputAndActionsContainer>
        </DetailsContainer>
      </ViewContainer>
      {loading && (
        <div style={{ margin: '20px 0' }}>
          <CenteredProgress />
        </div>
      )}
    </>
  );
};

export default CreatePostView;
