import { API } from 'aws-amplify';
import gql from 'graphql-tag';

async function fetchGiftCardAccounts() {
  const myQuery = gql`
    query MyQuery($userID: ID!) {
      listUserGiftCardAccountByUser(userID: $userID) {
        items {
          id
          name
        }
      }
    }
  `;

  const res = await API.graphql({
    query: myQuery,
    variables: { userID: global.me.id },
  });
  const accounts = res.data.listUserGiftCardAccountByUser.items;
  // console.log('accounts: ', accounts);

  const accountList = [];
  for (const account of accounts) {
    accountList[accountList.length] = {
      label: account.name,
      value: account.id,
    };
  }
  const sortedAccountList = accountList.sort((a, b) => {
    return b.label - a.label;
  });

  return sortedAccountList;
}

export default fetchGiftCardAccounts;
