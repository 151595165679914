import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import Utilities from '../common/Utilities';

export default class MissionListRepository {
  static async fetchMissions(missionFilterParm) {
    // console.log ("fetchMissions: ", missionFilterParm, missionSortParm);
    const now = Utilities.getLocalDateTime(new Date());

    // const now = new Date();
    console.log('###now: ', now);
    // const nowDate = graphqlDateFormat(now);
    const nowDate = now.toISOString();

    console.log('@@@@nowDate: ', nowDate);

    let startDateRange = [];
    let endDateRange = [];

    if (missionFilterParm === 0) {
      startDateRange = [Utilities.beginningOfTime, nowDate];
      endDateRange = [nowDate, Utilities.endOfTime];
    } else if (missionFilterParm === 1) {
      startDateRange = [nowDate, Utilities.endOfTime];
      endDateRange = [nowDate, Utilities.endOfTime];
    } else {
      startDateRange = [Utilities.beginningOfTime, Utilities.endOfTime];
      endDateRange = [Utilities.beginningOfTime, Utilities.endOfTime];
    }
    console.log(
      'filter: ' +
        missionFilterParm +
        ', startDateRange: ' +
        startDateRange +
        ', endDateRange: ' +
        endDateRange
    );
    const myQuery = gql`
      query MyQuery(
        $orgID: ID!
        $endDateRange: [String]!
        $startDateRange: [String]!
      ) {
        listMissionByEndDate(
          orgID: $orgID
          endDate: { between: $endDateRange }
          filter: {
            type: { ne: quest_mission }
            launchDate: { between: $startDateRange }
            isDeleted: { eq: false }
          }
          limit: 10000
        ) {
          items {
            id
            name
            startDate
            endDate
            isCircleOnly
          }
        }
      }
    `;

    const res = await API.graphql({
      query: myQuery,
      variables: {
        userID: global.me.id,
        startDateRange,
        endDateRange,
        orgID: global.me.orgID,
      },
    });
    const missions = res.data.listMissionByEndDate.items;
    console.log('missions: ', missions);
    for (const mission of missions) {
      mission.startDate = Utilities.formatDate(
        mission.startDate,
        'MM/DD/YYYY hh:mm:ss a'
      );
      mission.endDate = Utilities.formatDate(
        mission.endDate,
        'MM/DD/YYYY hh:mm:ss a'
      );
      mission.edit = 'Edit Campaign';
    }

    const sortedMissions = missions.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });
    return sortedMissions;
  }
}
