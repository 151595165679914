export type DimensionType = 'circle' | 'person';
export type DimensionTimeframe = 'D' | 'W' | 'M' | 'Y' | 'BEST';
export type SortColumn = string;
export type SortOrder = 'asc' | 'desc';

export enum DimensionTypeValues {
  Circle = 'circle',
  Person = 'person',
}

export enum DimensionTimeframeValues {
  Day = 'D',
  Week = 'W',
  Month = 'M',
  Year = 'Y',
  Best = 'BEST',
}
export interface KPI {
  [key: string]: string;
}

export interface Organization {
  id: string;
  name: string;
}

export interface Team {
  id: string;
  name: string;
  // users: {}[];
}

export interface Result {
  id: string;
  rank: number;
  name: string;
  kpis: KPI;
}
export interface KPIValues {
  totalResults: number;
  nextToken: string;
  kpiList: KPI;
  results: Result[];
}

export interface GetKPIValuesResponse {
  data: {
    getKPIValues: KPIValues;
  };
}

export interface GetOrganizationsResponse {
  data: {
    listCircleLevelByOrg: {
      items: Organization[];
    };
  };
}

export interface GetTeamsResponse {
  data: {
    searchCircles: {
      items: Team[];
    };
  };
}

export interface KPIList {
  [key: string]: string;
}

export interface GetKPIValuesArgs {
  dimensionType: DimensionType;
  dimensionTimeframe: DimensionTimeframe;
  dimensionValue: string;
  circleLevelID: string;
  circleID: string;
  nextToken: string;
  limit: number;
  sortColumn: SortColumn;
  sortOrder: SortOrder;
  currentPage: number;
  isIntervalRefresh: boolean;
}

export interface TableColumn {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: number) => string;
}

export interface FilterData {
  limit: number;
}

export interface KPILeaderboardState {
  loading: boolean;
  error: any;
  openFilter: boolean;
  dimensionType: DimensionType;
  dimensionTimeframe: DimensionTimeframe;
  dimensionValue: string;
  circleLevelID: string;
  circleID: string;
  tokens: string[];
  limit: number;
  sortColumn: SortColumn;
  sortOrder: SortOrder;
  kpiList: KPIList;
  results: Result[];
  currentPage: number;
  totalResults: number;
  filterChangesCount: number;
  organizations: Organization[];
  teams: Team[];
}

export interface TokensData {
  currentToken: string;
  nextToken: string;
  allTokens: string[];
}
