// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
//
// DisplayMedia
//
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Storage } from 'aws-amplify';
import CardMedia from '@mui/material/CardMedia';

export default function DisplayMedia(props) {
  // console.log('DisplayMedia: ', props.imageName + ',' + props.imageType);
  const [imageURL, setImageURL] = useState(null);

  async function getMedia(imageName, imageType) {
    // console.log('getMedia: ', imageName, imageType);

    if (
      imageType === 'gif_url' ||
      imageType === 'giftcard_url' ||
      imageType === 'trophy'
    ) {
      setImageURL(imageName);
      return;
    }
    try {
      if (imageName !== '') {
        const url = await Storage.get(imageName);
        // console.log('**url: ', url);
        setImageURL(url);
      }
    } catch (err) {
      alert("Can't get picture: ", imageName);
    }
  }

  useEffect(() => {
    // ("*** GamifyCamera useEffect****");
    getMedia(props.imageName, props.imageType);
  }, [props.imageName, props.imageType]);

  return (
    <Box>
      {/*
      <Image src={imageURL}
             width={500}
      />
      */}
      {props.imageType === 'video' ? (
        <CardMedia
          image={imageURL}
          component="video"
          controls
          sx={{
            height: props.height ? props.height : 200,
            width: props.width ? props.width : 300,
          }}
        />
      ) : (
        <CardMedia
          image={imageURL}
          component="img"
          sx={{
            borderRadius: props.borderRadius ? props.borderRadius : 3,
            height: props.height ? props.height : 200,
            width: props.width ? props.width : 300,
            marginLeft: props.marginLeft ? props.marginLeft : 0,
            objectFit: props.objectFit ? props.objectFit : '',
          }}
        />
      )}
    </Box>
  );
}
