import React, { useState } from 'react';
import CustomModal from '../../../custom-inputs/CustomModal';
import styled from 'styled-components';
import { CustomInputLabel } from '../../../custom-inputs/CustomInputs.styles';
import RewardType from '../../../_types/RewardType';
import DisplayMedia from '../../../../../../components/DisplayMedia';

interface Props {
  label: string;
  value: string;
  onSelect: (reward: any) => void;
  items: RewardType[];
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.div`
  font-family: Manrope;
  font-size: 14px;
`;

const RewardCardContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
`;

const RewardCardLightOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 12px;
`;

const RewardCardDetailsContainer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 10px;
  color: #fff;
  z-index: 2;
`;

const RewardCardLabel = styled.div`
  font-weight: 700;
  font-size: 18px;
  font-family: Manrope;
`;

const RewardCardSubLabel = styled.div`
  font-weight: 500;
  font-family: Space Grotesk;
  font-size: 14px;
`;

const NoRewardsText = styled.div`
  font-size: 20px;
  font-family: Space Grotesk;
  font-weight: 500;
  margin-top: 10px;
`;

const getPointsLabel = (numPoints: number): string => {
  if (numPoints === 0) {
    return '';
  }

  const label = numPoints === 1 ? 'pt' : 'pts';
  return `${numPoints} ${label}`;
};

const CustomSelectRewards: React.FC<Props> = ({
  items,
  label,
  onSelect,
  value,
}) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <CustomModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title="Select a reward"
      >
        {items.length > 0 ? (
          items.map((item, index: number) => {
            return (
              <RewardCardContainer
                onClick={() => {
                  onSelect(item);
                  setOpenModal(false);
                }}
                key={index}
              >
                <DisplayMedia
                  imageName={item.imageName}
                  imageType={item.imageType}
                  width={'100%'}
                  height={120}
                  borderRadius={10}
                  objectFit="contain"
                />
                <RewardCardLightOverlay />
                <RewardCardDetailsContainer>
                  <RewardCardLabel>{item.name}</RewardCardLabel>
                  <RewardCardSubLabel>
                    {getPointsLabel(item.cost)}
                  </RewardCardSubLabel>
                </RewardCardDetailsContainer>
              </RewardCardContainer>
            );
          })
        ) : (
          <NoRewardsText>No rewards yet</NoRewardsText>
        )}
      </CustomModal>
      <CustomInputLabel>{label}</CustomInputLabel>
      <Container onClick={() => setOpenModal(true)}>
        <Label>{value}</Label>
        <img src="/images/chevron-down.svg" width={16} height={16} />
      </Container>
    </>
  );
};

export default CustomSelectRewards;
