import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';

export default function ProfileStatsCardsSkeleton({ areStatsLoading }) {
  const numColumns = 3;
  const gap = 16;
  const columnWidth = `calc((100% - ${numColumns * gap}px) / ${numColumns})`; // 10 is gap between items
  const containerWidth = `calc(100% + ${gap}px)`;
  const filterWidth = `calc(100% - (${gap}px / 2))`;

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: areStatsLoading ? '2ch' : 0,
        }}
      >
        {areStatsLoading && (
          <Box
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <Box style={{ width: '33%', marginRight: 16 }}>
              <Typography>Date Range:</Typography>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: filterWidth,
                  padding: 12,
                  borderRadius: 8,
                  border: '1px solid #D0D2D8',
                }}
              >
                <Skeleton
                  variant={'rounded'}
                  sx={{ bgcolor: 'black' }}
                  width={50}
                  height={23}
                ></Skeleton>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box id="stats-subpage" style={{ width: containerWidth, gap }}>
        {['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''].map(
          (kpi, i) => {
            console.log(kpi);
            return (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: columnWidth,
                  paddingTop: 20,
                  paddingBottom: 28,

                  border: '1px solid #D0D2D8',
                  borderRadius: 8,
                }}
                key={i}
              >
                <Skeleton
                  variant={'rounded'}
                  sx={{ bgcolor: '#060606' }}
                  width={100}
                  height={18}
                ></Skeleton>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: 48,
                  }}
                >
                  <Skeleton
                    variant={'rounded'}
                    sx={{ bgcolor: '#060606' }}
                    width={22}
                    height={24}
                  ></Skeleton>
                </Box>
              </Box>
            );
          }
        )}
      </Box>
    </>
  );
}
