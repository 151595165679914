import React from 'react';
import {
  DimensionType,
  DimensionTypeValues,
  KPILeaderboardState,
} from '../../_types';
import {
  CustomSelect,
  CustomFilterContainer,
  CustomFilterTitle,
} from '../KPIFilter.styles';

interface FilterDimensionTypeProps {
  kpiLeaderboardState: KPILeaderboardState;
  onChangeKpiLeaderboardState: (
    kpiLeaderboardState: KPILeaderboardState
  ) => void;
  resetTokens: () => void;
}

const dimensionTypes = [
  { label: 'Team', value: DimensionTypeValues.Circle },
  { label: 'People', value: DimensionTypeValues.Person },
];

const FilterDimensionType = ({
  kpiLeaderboardState,
  onChangeKpiLeaderboardState,
  resetTokens,
}: FilterDimensionTypeProps) => {
  return (
    <CustomFilterContainer>
      <CustomFilterTitle>Team / People</CustomFilterTitle>
      <CustomSelect
        value={kpiLeaderboardState.dimensionType}
        onChange={(e) => {
          onChangeKpiLeaderboardState({
            ...kpiLeaderboardState,
            dimensionType: e.target.value as DimensionType,
            currentPage: 0,
          });
          resetTokens();
        }}
      >
        {dimensionTypes.map((dimensionType) => {
          return (
            <option key={dimensionType.value} value={dimensionType.value}>
              {dimensionType.label}
            </option>
          );
        })}
      </CustomSelect>
    </CustomFilterContainer>
  );
};

export default FilterDimensionType;
