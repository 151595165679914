// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
//
// MaintainMissionScreen
//
import React, { useState, useEffect } from 'react';
import { View } from '@aws-amplify/ui-react';
import {
  Alert,
  Button,
  Box,
  Switch,
  TextField,
  Grid,
  Typography,
  Paper,
} from '@mui/material';
import { API } from 'aws-amplify';
import { Form, Formik, useField, useFormikContext } from 'formik';
import * as yup from 'yup';
import { StyledInput } from '../../common/FormikHelpers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  SelectPerson,
  SelectCircle,
  SelectReward,
  SelectMission,
  SelectKPIRule,
  SelectMissionCategory,
  SelectFromList,
} from '../../common/PickerSearch';

import * as utils from '../../common/Utilities';
import {
  LOG,
  defaultDateTimeFormat,
  blankIfValue,
  formattedLocalDateTimeShort,
  formattedLocalDate,
} from '../../common/Utilities';
import { Trophy } from './TrophyCase';
import TrophyShop from './TrophyShop';
import gql from 'graphql-tag';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers';
import * as mutations from '../../../graphql/mutations';

const getMissionForUpdate = gql`
  query getMissionForUpdate($missionID: ID!) {
    getMission(id: $missionID) {
      id
      action
      actionCount
      actionPastTense
      actionType
      actionTypePlural
      autoAssign
      autoCreateMonths
      completePictureRequired
      createdAt
      createdBy
      description
      endDate
      imageName
      imageType
      isDeleted
      lastUpdatedBy
      launchDate
      location
      name
      orgID
      ownerID
      ownerType
      permissions
      questOrder
      questType
      rewardCount
      rewardID
      rewardName
      rewardType
      scope
      startDate
      tags
      trophiesAwarded
      type
      updatedAt
      KPIRuleID
      tieKPIRuleID
      actionCountType
      categoryID
      flexFields
      isCircleOnly
      rewardDisposition
      isPublic
      contestType
      bracket
      minGoal
      advanceType
      nextMissionID
      topNumberAdvance
      rewardLimit
      useAutoGoal
      autoGoalTimeframe
      autoGoalTimeframeCount
      autoGoalFactor
      minAutoGoal
      roundGoalPrecision
      useAutoCreate
      autoCreateTimeframe
      autoCreateTimeframeCount
      autoCreateStartDate
      autoCreateEndDate
      circle {
        name
      }
      user {
        name
      }
      trophies {
        items {
          id
          place
          imageName
          imageType
          description
          place
          title
        }
      }
      rewards {
        items {
          id
          place
          rewardID
          minActionCount
          points
          isTie
        }
      }
    }
  }
`;

export default function MaintainMissionScreen(props) {
  const newMission = {
    id: '',
    type: 'mission',
    name: '',
    description: '',
    rewardCount1: '',
    rewardCount2: '',
    rewardCount3: '',
    rewardCountP: '',
    rewardCount1t: '',
    rewardCount2t: '',
    rewardCount3t: '',
    search: '',
    imageName: '',
    imageType: '',
    createMission: false,
    pickAssignee: false,
    uploadloadingPicture: false,
    uploadPictureTitle: 'Upload Picture / Video',
    parentID: '',
    parentName: '',
    scope: 'circle',
    assigneeID: '',
    assigneeName: '',
    ownerID: '',
    ownerType: '',
    questType: 'notaquest',
    questOrder: 'notaquest',
    startDate: formattedLocalDateTimeShort(new Date()),
    launchDate: '',
    endDate: '',
    location: '',
    action: '',
    actionPastTense: '',
    actionTypePlural: '',
    actionType: '',
    actionCount: '',
    missionRewardID1: '',
    missionRewardID2: '',
    missionRewardID3: '',
    missionRewardIDp: '',
    missionRewardID1t: '',
    missionRewardID2t: '',
    missionRewardID3t: '',
    missionRewardIDpt: '',
    rewardID1: '',
    rewardName1: '',
    rewardID2: '',
    rewardName2: '',
    rewardID3: '',
    rewardName3: '',
    rewardIDp: '',
    rewardNameP: '',
    rewardID1t: '',
    rewardName1t: '',
    rewardID2t: '',
    rewardName2t: '',
    rewardID3t: '',
    rewardName3t: '',
    rewardIDpt: '',
    rewardNamePt: '',
    rewardType: 'mission_reward',
    tags: [],
    orgID: global.me.orgID,
    autoAssign: true,
    copiedMissionID: '',
    copiedMissionName: '',
    copyMission: false,
    KPIRuleID: '',
    KPIRuleName: '',
    tieKPIRuleID: '',
    tieKPIRuleName: '',
    trophies: [],
    isDeleted: false,
    useDealStage: true,
    actionCountTypeNum: 0,
    actionCountType: 'count',
    categoryID: '',
    flexFields: [],
    useStepCounter: false,
    assignToChildCircles: true,
    isCircleOnly: false,
    isPublic: false,
    rewardDisposition: 'winner',
    managerPlay: true,
    contestType: 'regular',
    bracket: [],
    rewardCurrency: 'reward',
    rewardWhen: 'mission',
    minGoal: '',
    rewardLimit: '',
    useAutoGoal: false,
    autoGoalTimeframe: 'W',
    autoGoalTimeframeCount: '4',
    autoGoalFactor: '100',
    minAutoGoal: '1.0',
    roundGoalPrecision: '0',
    useAutoCreate: false,
    autoCreateTimeframe: 'W',
    autoCreateTimeframeCount: '1',
    autoCreateStartDate: '',
    autoCreateEndDate: '',
    nextMissionID: '',
    advanceType: 'never',
    topNumberAdvance: 0,
  };
  const trophyCase = [];

  // LOG.debug ("global.appSettings: ", global.appSettings.defaultMissionTrophies);
  for (let i = 0; i < global.appSettings.defaultMissionTrophies.length; i++) {
    const trophy = global.appSettings.defaultMissionTrophies[i];
    // LOG.debug ("trophy: ", trophy);
    trophy.id = '';
    trophyCase[i] = trophy;
  }
  newMission.trophies = trophyCase;

  const [initialValues, setInitialValues] = useState(newMission);
  const [selectNewTrophy, setSelectNewTrophy] = useState(false);
  const [trophyIndex, setTrophyIndex] = useState(0);
  const [fetchDone, setFetchDone] = useState(false);
  const [assigneeIDs, setAssigneeIDs] = useState([]);
  const [assigneeNames, setAssigneeNames] = useState([]);
  const [lastAssigneeID, setLastAssigneeID] = useState('');
  const [update, setUpdate] = useState(0);
  const [viewAdvanced, setViewAdvanced] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  let missionID = queryParameters.get('missionID');
  console.log('MISSION ID: ', missionID);

  if (missionID === undefined || missionID === null) {
    missionID = '';
  }

  //  const missionID = "";

  //
  //  Setup the validation rules for the fields
  //
  const createMissionValidationSchema = yup.object().shape({
    rewardCount: yup
      .number()
      .positive()
      .integer()
      .min(0, 'Value must be at least 0')
      .max(100000, 'Value can be no larger than 100000')
      .label(global.appSettings.labels.reward.singular),
    name: yup
      .string()
      .label('Name')
      .required()
      .min(3, 'The name is too short.  Please enter at least 3 characters')
      .max(
        1024,
        'The name name is too long.  Are you trying to enter a description?'
      ),
    description: yup
      .string()
      .label('Description')
      .max(
        4096,
        'The description is too long.  Please use less than 4096 characters'
      ),
    actionCount: yup
      .number()
      .label('Action Count')
      .min(0, 'you must enter at least 0 or leave it blank')
      .max(1000000, 'Please enter a nubmer less than 1000000'),
    rewardCount1: yup
      .number()
      .positive()
      .integer()
      .label('Reward Count')
      .min(0, 'you must enter at least 0 or leave it blank')
      .max(1000000, 'Please enter a nubmer less than 1000000'),
    rewardCount2: yup
      .number()
      .positive()
      .integer()
      .label('Reward Count')
      .min(0, 'you must enter at least 0 or leave it blank')
      .max(1000000, 'Please enter a nubmer less than 1000000'),
    rewardCount3: yup
      .number()
      .positive()
      .integer()
      .label('Reward Count')
      .min(0, 'you must enter at least 0 or leave it blank')
      .max(1000000, 'Please enter a nubmer less than 1000000'),
    rewardCountP: yup
      .number()
      .positive()
      .integer()
      .label('Reward Count')
      .min(0, 'you must enter at least 0 or leave it blank')
      .max(1000000, 'Please enter a nubmer less than 1000000'),
    rewardCount1t: yup
      .number()
      .positive()
      .integer()
      .label('Reward Count')
      .min(0, 'you must enter at least 0 or leave it blank')
      .max(1000000, 'Please enter a nubmer less than 1000000'),
    rewardCount2t: yup
      .number()
      .positive()
      .integer()
      .label('Reward Count')
      .min(0, 'you must enter at least 0 or leave it blank')
      .max(1000000, 'Please enter a nubmer less than 1000000'),
    rewardCount3t: yup
      .number()
      .positive()
      .integer()
      .label('Reward Count')
      .min(0, 'you must enter at least 0 or leave it blank')
      .max(1000000, 'Please enter a nubmer less than 1000000'),
    minGoal: yup
      .number()
      .positive()
      .integer()
      .label('Minimum Goal')
      .min(0, 'you must enter at least 0 or leave it blank')
      .max(1000000, 'Please enter a nubmer less than 1000000'),
    startDate: yup
      .string()
      .label('Start Date')
      .matches(
        utils.dateTimeRegExp,
        'Please enter a valid date in the form ' +
          global.appSettings.dateTimeFormat
      ),
    endDate: yup
      .string()
      .label('End Date')
      .matches(
        utils.dateTimeRegExp,
        'Please enter a valid date in the form  ' +
          global.appSettings.dateTimeFormat
      ),
    launchDate: yup
      .string()
      .label('Launch Date')
      .matches(
        utils.dateTimeRegExp,
        'Please enter a valid date in the form  ' +
          global.appSettings.dateTimeFormat
      ),
  });

  /// //////////
  //
  //  Save mission
  //
  /// //////////

  async function saveOurMission(values, ourAssigneeIDs) {
    LOG.debug('saveOurMission: ', values);
    const now = new Date();
    LOG.debug('now: ', now);
    LOG.debug('tzOffset: ', now.getTimezoneOffset());
    try {
      values.assigneeIDs = ourAssigneeIDs;
      if (values.startDate.length > 10) {
        values.startDate += ' -' + now.getTimezoneOffset() / 60;
      }
      if (values.endDate.length > 10) {
        values.endDate += ' -' + now.getTimezoneOffset() / 60;
      }
      if (values.launchDate.length > 10) {
        values.launchDate += ' -' + now.getTimezoneOffset() / 60;
      }
      const saveMissionRes = await API.graphql({
        query: mutations.saveMission,
        variables: {
          mission: JSON.stringify(values),
          doFeedBlast: true,
        },
      });
      console.log('saveMissionRes: ', saveMissionRes);
      alert('Mission Saved');
      return saveMissionRes.data.saveMission;
    } catch (err) {
      alert('There was an error while saving.  Please try again.');
      LOG.error('Error saving Mission: ', err);
      return false;
    }
  }

  /// //////////
  //
  //  Get mission
  //
  /// //////////

  async function fetchMission(missionID) {
    // LOG.debug ("*****fetchMission: ", missionID);
    if (missionID === '' || missionID === undefined || missionID === null) {
      setFetchDone(true);
      return initialValues;
    }
    const missionData = await API.graphql({
      query: getMissionForUpdate,
      variables: { missionID },
    });
    // LOG.debug ("missionData:", missionData);
    if (!missionData.data.getMission) {
      // LOG.debug ("&&&&blank mission&&&&&");
      setFetchDone(true);
      return newMission;
    }
    const mission = missionData.data.getMission;
    LOG.debug('mission: ', mission);
    const ourData = {
      id: mission.id,
      type: mission.type,
      name: mission.name,
      description: mission.description,
      rewardCount: mission.rewardCount.toString(),
      search: mission.search,
      imageName: mission.imageName,
      imageType: mission.imageType,
      createMission: false,
      pickAssignee: false,
      uploadloadingPicture: false,
      uploadPictureTitle: 'Upload Picture / Video',
      parentName: '',
      scope: mission.scope,
      assigneeID: mission.ownerID,
      assigneeName: '',
      ownerID: mission.ownerID,
      questType: mission.questType,
      questOrder: mission.questOrder,
      startDate: defaultDateTimeFormat(
        blankIfValue(mission.startDate, utils.MIN_DATE)
      ),
      endDate: defaultDateTimeFormat(
        blankIfValue(mission.endDate, utils.MAX_DATE)
      ),
      launchDate: defaultDateTimeFormat(
        blankIfValue(mission.launchDate, utils.MIN_DATE)
      ),
      location: mission.location,
      action: mission.action,
      actionPastTense: mission.actionPastTense,
      actionCount: blankIfValue(mission.actionCount.toString(), '0'),
      actionType: mission.actionType,
      actionTypePlural: mission.actionTypePlural,
      tags: mission.tags,
      orgID: mission.orgID,
      nextExpireFeedBlast: mission.nextExpireFeedBlast,
      nextLaunchFeedBlast: mission.nextLaunchFeedBlast,
      rewardID: mission.rewardID,
      rewardName: mission.rewardName,
      autoAssign: mission.autoAssign,
      rewardType: mission.rewardType,
      actionCountType: mission.actionCountType,
      KPIRuleID: mission.KPIRuleID,
      KPIRuleName: '',
      tieKPIRuleID: mission.tieKPIRuleID,
      tieKPIRuleName: '',
      trophies: [],
      missionRewardID1: '',
      missionRewardID2: '',
      missionRewardID3: '',
      missionRewardIDp: '',
      rewardID1: '',
      rewardName1: '',
      rewardID2: '',
      rewardName2: '',
      rewardID3: '',
      rewardName3: '',
      rewardIDp: '',
      rewardNameP: '',
      missionRewardID1t: '',
      missionRewardID2t: '',
      missionRewardID3t: '',
      missionRewardIDpt: '',
      rewardID1t: '',
      rewardName1t: '',
      rewardID2t: '',
      rewardName2t: '',
      rewardID3t: '',
      rewardName3t: '',
      rewardIDpt: '',
      rewardNamePt: '',
      categoryID: mission.categoryID,
      flexFields: JSON.parse(mission.flexFields),
      useStepCounter: mission.useStepCounter,
      isCircleOnly: mission.isCircleOnly,
      assignToChildCircles: mission.assignToChildCircles,
      isPublic: mission.isPublic,
      rewardDisposition: mission.rewardDisposition,
      contestType: mission.contestType,
      bracket: 'bracket' in mission ? JSON.parse(mission.bracket) : [],
      minGoal: blankIfValue(mission.minGoal, '0').toString(),
      rewardLimit: blankIfValue(mission.rewardLimit, '0').toString(),
      useAutoGoal: mission.useAutoGoal,
      autoGoalTimeframe: mission.autoGoalTimeframe,
      autoGoalTimeframeCount: mission.autoGoalTimeframeCount,
      autoGoalFactor: mission.autoGoalFactor,
      minAutoGoal: mission.minAutoGoal,
      roundGoalPrecision: mission.roundGoalPrecision,
      useAutoCreate: mission.useAutoCreate,
      autoCreateTimeframe: mission.autoCreateTimeframe,
      autoCreateTimeframeCount: mission.autoCreateTimeframeCount,
      autoCreateStartDate: defaultDateTimeFormat(
        blankIfValue(mission.autoCreateStartDate, utils.MIN_DATE)
      ),
      autoCreateEndDate: defaultDateTimeFormat(
        blankIfValue(mission.autoCreateEndDate, utils.MAX_DATE)
      ),
      nextMissionID: mission.nextMissionID,
      advanceType: mission.advanceType,
      topNumberAdvance: mission.topNumberAdvance,
    };

    if (
      mission.rewardType === 'mission_points' ||
      mission.rewardType === 'mission_min_points' ||
      mission.rewardType === 'quest_mission_points' ||
      mission.rewardType === 'quest_points' ||
      mission.rewardType === 'action_points' ||
      mission.rewardType === 'goal_points'
    ) {
      ourData.rewardCurrency = 'points';
    } else {
      ourData.rewardCurrency = 'reward';
    }

    if (
      mission.rewardType === 'mission_points' ||
      mission.rewardType === 'mission_reward'
    ) {
      ourData.rewardWhen = 'mission';
    } else if (
      mission.rewardType === 'action_points' ||
      mission.rewardType === 'action_reward'
    ) {
      ourData.rewardWhen = 'action';
    } else {
      ourData.rewardWhen = 'goal';
    }
    LOG.debug('!!!ourData ', ourData);

    const trophyCase = [];
    for (let i = 0; i < 4; i++) {
      let title = '';
      if (i === 0) {
        title = '1st Place';
      } else if (i === 1) {
        title = '2nd Place';
      } else if (i === 2) {
        title = '3rd Place';
      } else {
        title = 'Completion';
      }

      trophyCase[i] = {
        id: '',
        place: i + 1,
        imageName: '',
        title,
        description: '',
      };
    }

    for (const trophy of mission.trophies.items) {
      trophyCase[trophy.place - 1] = {
        id: trophy.id,
        place: trophy.place,
        imageName: trophy.imageName,
        title: trophy.title,
        description: trophy.description,
      };
    }
    ourData.trophies = trophyCase.sort((a, b) => {
      if (a.place < b.place) return -1;
      if (a.place > b.place) return 1;
      return 0;
    });

    for (const reward of mission.rewards.items) {
      if (reward.isTie) {
        if (reward.place === 1) {
          ourData.missionRewardID1t = reward.id;
          ourData.rewardID1t = reward.rewardID;
          ourData.rewardCount1t = reward.points.toString();
        } else if (reward.place === 2) {
          ourData.missionRewardID2t = reward.id;
          ourData.rewardID2t = reward.rewardID;
          ourData.rewardCount2t = reward.points.toString();
        } else if (reward.place === 3) {
          ourData.missionRewardID3t = reward.id;
          ourData.rewardID3t = reward.rewardID;
          ourData.rewardCount3t = reward.points.toString();
        }
      } else {
        if (reward.place === 1) {
          ourData.missionRewardID1 = reward.id;
          ourData.rewardID1 = reward.rewardID;
          ourData.rewardCount1 = reward.points.toString();
        } else if (reward.place === 2) {
          ourData.missionRewardID2 = reward.id;
          ourData.rewardID2 = reward.rewardID;
          ourData.rewardCount2 = reward.points.toString();
        } else if (reward.place === 3) {
          ourData.missionRewardID3 = reward.id;
          ourData.rewardID3 = reward.rewardID;
          ourData.rewardCount3 = reward.points.toString();
        } else {
          ourData.missionRewardIDp = reward.id;
          ourData.rewardIDp = reward.rewardID;
          ourData.rewardCountP = reward.points.toString();
        }
      }
    }
    if (ourData.actionCountType === 'count') {
      ourData.actionCountTypeNum = 0;
    } else {
      ourData.actionCountTypeNum = 1;
    }

    ourData.useDealStage = ourData.KPIRuleID !== '';

    // LOG.debug ("misson.user: ", mission.user);
    if (mission.user !== null) {
      ourData.assigneeName = mission.user.name;
    }
    // LOG.debug ("misson.circle: ", mission.circle);
    if (mission.circle !== null) {
      ourData.assigneeName = mission.circle.name;
    }
    // console.log ("***ourData: ", ourData);
    setInitialValues(ourData);
    setFetchDone(true);
  }

  async function doSubmit(values, actions) {
    LOG.debug('createCampaign doSubmit: ', values, actions);

    await new Promise((resolve) => setTimeout(resolve, 500));
    // alert(JSON.stringify(values, null, 2));
    console.log(JSON.stringify(values, null, 2));

    if (values.scope === '') {
      alert(
        "Please select a value in the 'Who is this mission assigned to' field."
      );
      return false;
    }
    if (
      values.id === '' &&
      assigneeIDs.length === 0 &&
      Object.keys(values.bracket).length === 0
    ) {
      if (
        values.contestType === 'regular' ||
        values.contestType === 'throw_down' ||
        values.contestType === 'percent_to_goal'
      ) {
        alert(
          'Please select a ' +
            global.appSettings.labels.circle.singular +
            ' or ' +
            global.appSettings.labels.person.singular +
            ' for this ' +
            global.appSettings.labels.mission.singular
        );
      } else {
        alert('Please create a bracket before saving.');
      }
      return false;
    }
    if (values.KPIRuleID === '' && values.useDealStage) {
      alert(
        "Please select a valid KPI Rule using the 'Select a KPI Rule dropdown'"
      );
      return false;
    }
    if (values.categoryID === '' && !values.useDealStage) {
      alert("Please select a valid KPI using the 'Select category dropdown'");
      return false;
    }

    console.log('Done with checks -> Returning');
    // return true;
    const ret = await saveOurMission(values, assigneeIDs);
    // LOG.debug ("saveOurMission ret: ", ret);
    if (ret !== false) {
      // LOG.debug ("restoring mission data");

      actions.resetForm();
      window.location.href = '/campaigns';
      props.onExit();
    }
    actions.setSubmitting(false);
  }

  // async function copyMission (missionID) {
  //   const missionData = await API.graphql({ query: getMissionForUpdate, variables: { missionID } });
  //   if (!missionData.data.getMission) {
  //     setFetchDone(true);
  //     return (newMission);
  //   }
  //   const mission = missionData.data.getMission;
  //   const ourData = {
  //     id: '',
  //     type: mission.type,
  //     name: '',
  //     description: mission.description,
  //     rewardCount: mission.rewardCount.toString(),
  //     search: mission.search,
  //     imageName: mission.imageName,
  //     imageType: mission.imageType,
  //     createMission: false,
  //     pickAssignee: false,
  //     uploadloadingPicture: false,
  //     uploadPictureTitle: 'Upload Picture / Video',
  //     parentName: '',
  //     scope: mission.scope,
  //     assigneeID: mission.ownerID,
  //     assigneeName: '',
  //     ownerID: mission.ownerID,
  //     questType: mission.questType,
  //     questOrder: mission.questOrder,
  //     startDate: '',
  //     endDate: '',
  //     launchDate: '',
  //     location: mission.location,
  //     action: mission.action,
  //     actionPastTense: mission.actionPastTense,
  //     actionCount: blankIfValue(mission.actionCount.toString(), '0'),
  //     actionType: mission.actionType,
  //     actionTypePlural: mission.actionTypePlural,
  //     tags: mission.tags,
  //     orgID: mission.orgID,
  //     nextExpireFeedBlast: '',
  //     nextLaunchFeedBlast: '',
  //     rewardID: mission.rewardID,
  //     rewardName: mission.rewardName,
  //     autoAssign: mission.autoAssign,
  //     rewardType: mission.rewardType,
  //     KPIRuleID: mission.KPIRuleID,
  //     tieKPIRuleID: mission.tieKPIRuleID,
  //     actionCountType: mission.actionCountType,
  //     KPIRuleName: '',
  //     tieKPIRuleName: '',
  //     missionRewardID1: '',
  //     missionRewardID2: '',
  //     missionRewardID3: '',
  //     missionRewardIDp: '',
  //     missionRewardID1t: '',
  //     missionRewardID2t: '',
  //     missionRewardID3t: '',
  //     missionRewardIDpt: '',
  //     rewardID1: '',
  //     rewardName1: '',
  //     rewardID2: '',
  //     rewardName2: '',
  //     rewardID3: '',
  //     rewardName3: '',
  //     rewardIDp: '',
  //     rewardNameP: '',
  //     rewardID1t: '',
  //     rewardName1t: '',
  //     rewardID2t: '',
  //     rewardName2t: '',
  //     rewardID3t: '',
  //     rewardName3t: '',
  //     rewardIDpt: '',
  //     rewardNamePt: '',
  //     categoryID: mission.categoryID,
  //     flexFields: JSON.parse(mission.flexFields),
  //     useStepCounter: mission.useStepCounter,
  //     isCircleOnly: mission.isCircleOnly,
  //     assignToChildCircles: mission.assignToChildCircles,
  //     rewardDisposition: mission.rewardDisposition,
  //     isPublic: mission.isPublic,
  //     contestType: mission.contestType,
  //     bracket: 'bracket' in mission ? JSON.parse(mission.bracket) : [],
  //     minGoal: blankIfValue(mission.minGoal, '0').toString(),
  //     rewardLimit: blankIfValue(mission.rewardLimit, '0').toString(),
  //     useAutoGoal: mission.useAutoGoal,
  //     autoGoalTimeframe: mission.autoGoalTimeframe,
  //     autoGoalTimeframeCount: mission.autoGoalTimeframeCount.toString(),
  //     autoGoalFactor: mission.autoGoalFactor.toString(),
  //     minAutoGoal: mission.minAutoGoal.toString(),
  //     roundGoalPrecision: mission.roundGoalPrecision.toString(),
  //     useAutoCreate: mission.useAutoCreate,
  //     autoCreateTimeframe: mission.autoCreateTimeframe,
  //     autoCreateTimeframeCount: mission.autoCreateTimeframeCount.toString(),
  //     autoCreateStartDate: mission.autoCreateStartDate,
  //     autoCreateEndDate: mission.autoCreateEndDate,
  //     nextMissionID: mission.nextMissionID,
  //     advanceType: mission.advanceType,
  //     topNumberAdvance: mission.topNumberAdvance
  //   };
  //   if (mission.rewardType === 'mission_points' ||
  //     mission.rewardType === 'quest_mission_points' ||
  //     mission.rewardType === 'quest_points' ||
  //     mission.rewardType === 'mission_min_points' ||
  //     mission.rewardType === 'action_points' ||
  //     mission.rewardType === 'goal_points') {
  //     ourData.rewardCurrency = 'points';
  //   } else {
  //     ourData.rewardCurrency = 'reward';
  //   }
  //
  //   if (mission.rewardType === 'mission_points' || mission.rewardType === 'mission_reward') {
  //     ourData.rewardWhen = 'mission';
  //   } else if (mission.rewardType === 'action_points' || mission.rewardType === 'action_reward') {
  //     ourData.rewardWhen = 'action';
  //   } else if (mission.rewardType === 'mission_min_reward') {
  //     ourData.rewardWhen = 'goal';
  //   } else {
  //     ourData.rewardWhen = 'mission_min';
  //   }
  //
  //   const trophyCase = [];
  //
  //   for (let i = 0; i < 4; i++) {
  //     let title = '';
  //     if (i === 0) {
  //       title = '1st Place';
  //     } else if (i === 1) {
  //       title = '2nd Place';
  //     } else if (i === 2) {
  //       title = '3rd Place';
  //     } else {
  //       title = 'Completion';
  //     }
  //
  //     trophyCase[i] = {
  //       id: '',
  //       place: i + 1,
  //       imageName: '',
  //       title,
  //       description: ''
  //     };
  //   }
  //
  //   for (const trophy of mission.trophies.items) {
  //     trophyCase[trophy.place - 1] = {
  //       id: '',
  //       place: trophy.place,
  //       imageName: trophy.imageName,
  //       title: trophy.title,
  //       description: trophy.description
  //     };
  //   }
  //
  //   ourData.trophies = trophyCase.sort((a, b) => {
  //     if (a.place < b.place) return -1;
  //     if (a.place > b.place) return 1;
  //     return 0;
  //   });
  //
  //   for (const reward of mission.rewards.items) {
  //     if (reward.isTie) {
  //       if (reward.place === 1) {
  //         ourData.rewardID1t = reward.rewardID;
  //         ourData.rewardCount1t = reward.points.toString();
  //       } else if (reward.place === 2) {
  //         ourData.rewardID2t = reward.rewardID;
  //         ourData.rewardCount2t = reward.points.toString();
  //       } else if (reward.place === 3) {
  //         ourData.rewardID3t = reward.rewardID;
  //         ourData.rewardCount3t = reward.points.toString();
  //       }
  //     } else {
  //       if (reward.place === 1) {
  //         ourData.rewardID1 = reward.rewardID;
  //         ourData.rewardCount1 = reward.points.toString();
  //       } else if (reward.place === 2) {
  //         ourData.rewardID2 = reward.rewardID;
  //         ourData.rewardCount2 = reward.points.toString();
  //       } else if (reward.place === 3) {
  //         ourData.rewardID3 = reward.rewardID;
  //         ourData.rewardCount3 = reward.points.toString();
  //       } else {
  //         ourData.rewardIDp = reward.rewardID;
  //         ourData.rewardCountP = reward.points.toString();
  //       }
  //     }
  //   }
  //
  //   if (ourData.actionCountType === 'count') {
  //     ourData.actionCountTypeNum = 0;
  //   } else {
  //     ourData.actionCountTypeNum = 1;
  //   }
  //   ourData.useDealStage = ourData.KPIRuleID !== '';
  //
  //   // LOG.debug ("misson.user: ", mission.user);
  //   if (mission.user !== null) {
  //     ourData.assigneeName = mission.user.name;
  //   }
  //   // LOG.debug ("misson.circle: ", mission.circle);
  //   if (mission.circle !== null) {
  //     ourData.assigneeName = mission.circle.name;
  //   }
  //   // console.log ("!!!ourData: ", ourData);
  //   setInitialValues(ourData);
  //   setFetchDone(true);
  // }

  useEffect(() => {
    fetchMission(missionID);
  }, []);

  const displayLabels = missionID !== '';

  const MISSION_TYPES = [];

  if (global.appSettings.features.mission.types.includes('regular')) {
    MISSION_TYPES[MISSION_TYPES.length] = {
      label: 'Regular ' + global.appSettings.labels.mission.singular,
      value: 'regular',
    };
  }
  if (global.appSettings.features.mission.types.includes('percent_to_goal')) {
    MISSION_TYPES[MISSION_TYPES.length] = {
      label: 'Percent to Goal ' + global.appSettings.labels.mission.singular,
      value: 'percent_to_goal',
    };
  }
  if (global.appSettings.features.mission.types.includes('throw_down')) {
    MISSION_TYPES[MISSION_TYPES.length] = {
      label: 'Throw Down ' + global.appSettings.labels.mission.singular,
      value: 'throw_down',
    };
  }
  if (global.appSettings.features.mission.types.includes('bracket_manual')) {
    MISSION_TYPES[MISSION_TYPES.length] = {
      label: 'Bracket-Manual Seed',
      value: 'bracket_manual',
    };
  }
  if (global.appSettings.features.mission.types.includes('bracket_random')) {
    MISSION_TYPES[MISSION_TYPES.length] = {
      label: 'Bracket-Random Seed',
      value: 'bracket_random',
    };
  }
  if (global.appSettings.features.mission.types.includes('bracket_kpi')) {
    MISSION_TYPES[MISSION_TYPES.length] = {
      label: 'Bracket-KPI Seed',
      value: 'bracket_kpi',
    };
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#000000',
      },
      success: {
        main: '#827ffc',
      },
    },
    typography: {
      allVariants: {
        fontFamily: "'Manrope', sans-serif",
        color: 'white',
      },
      button: {
        textTransform: 'none',
      },
    },
    DatePickerField: {
      borderColor: 'black',
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {},
        },
      },
      MuiYearPicker: {
        styleOverrides: {
          root: {
            backgroundColor: '#f9f9f9',
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            color: 'black',
          },
        },
      },
      MuiClockPicker: {
        styleOverrides: {
          root: {
            backgroundColor: '#f9f9f9',
          },
        },
      },
    },
  });

  const DatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, formattedLocalDate(val));
        }}
      />
    );
  };

  const DateTimePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
      <DateTimePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, formattedLocalDateTimeShort(val));
        }}
      />
    );
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid
        className={'new-campaign-title-overlay'}
        container
        item
        justifyContent="center"
      >
        <Grid item>
          <Typography variant="h4">New Campaign</Typography>
        </Grid>
      </Grid>
      <Grid
        className={'form-overlay'}
        container
        item
        sx={{ mt: 4 }}
        justifyContent="center"
      >
        <Grid item xs={7}>
          <Box className={'formik-box'}>
            <View style={{ width: '100%' }}>
              {!fetchDone ? (
                <Typography color={'black'}>
                  Fetching ... please wait
                </Typography>
              ) : (
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={createMissionValidationSchema}
                  onSubmit={(values, actions) => doSubmit(values, actions)}
                >
                  {(formikProps) => (
                    <Form>
                      <Box>
                        <Box
                          style={{
                            flex: 1,
                            padding: 0,
                            marginLeft: 4,
                            marginRight: 4,
                          }}
                        >
                          {/* <View className={'copy-campaign-button'} style={{ marginBottom: 20, alignSelf: 'center' }}> */}
                          {/*  <ShadowButton */}
                          {/*    title={"Copy From a " + global.appSettings.labels.mission.proper} */}
                          {/*    onClick={() => formikProps.setFieldValue('copyMission', !formikProps.values.copyMission)} */}
                          {/*  /> */}
                          {/* </View> */}

                          {/* <View className={'copy-campaign-button-dropdown'} style={{ marginBottom: 20, alignSelf: 'center' }}> */}
                          {/*  {formikProps.values.copyMission && */}
                          {/*    <SelectMission */}
                          {/*      filter={formikProps.values.organizationID} */}
                          {/*      value={formikProps.values.copiedMissionID} */}
                          {/*      fieldSetter={(value, name) => { */}
                          {/*        if (value !== "") { */}
                          {/*          { */}
                          {/*            Alert.alert( */}
                          {/*              "Clone " + global.appSettings.labels.mission.proper, */}
                          {/*              "This will REPLACE the values in the current " + global.appSettings.labels.mission.singular + " with the values from the " + name + " " + global.appSettings.labels.mission.singular + ".  Are you sure you wish to continue?", */}
                          {/*              [ */}
                          {/*                { */}
                          {/*                  text: "Yes", */}
                          {/*                  onClick: () => { */}
                          {/*                    setFetchDone(false); */}
                          {/*                    copyMission(value); */}
                          {/*                    formikProps.setFieldValue('copyMission', false); */}
                          {/*                    formikProps.setFieldValue('copiedMissionID', ""); */}
                          {/*                  } */}
                          {/*                }, */}
                          {/*                { */}
                          {/*                  text: "No", */}
                          {/*                  style: "cancel", */}
                          {/*                  onClick: () => { */}
                          {/*                    formikProps.setFieldValue('copiedMissionID', value); */}
                          {/*                  } */}
                          {/*                }, */}
                          {/*                { */}
                          {/*                  text: "Cancel", */}
                          {/*                  style: "cancel", */}
                          {/*                  onClick: () => { */}
                          {/*                    formikProps.setFieldValue('copyMission', false); */}
                          {/*                    formikProps.setFieldValue('copiedMissionID', ""); */}
                          {/*                  } */}
                          {/*                } */}
                          {/*              ] */}
                          {/*            ) */}
                          {/*          } */}
                          {/*        } */}
                          {/*      }} */}
                          {/*    /> */}
                          {/*  } */}
                          {/* </View> */}

                          <Paper className={'paper-overlay'}>
                            <Grid
                              className={'title-overlay'}
                              container
                              item
                              justifyContent="center"
                            >
                              <Grid item>
                                <Typography color={'black'} variant="h6">
                                  Details
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid container item justifyContent="center">
                                <Grid item xs={10}>
                                  <Grid
                                    display={'block'}
                                    style={{
                                      marginLeft: '0%',
                                      marginTop: '3%',
                                    }}
                                  >
                                    <Box display={'block'}>
                                      <StyledInput
                                        hideLabels={!displayLabels}
                                        label="Name"
                                        value={formikProps.values.name}
                                        formikProps={formikProps}
                                        formikKey="name"
                                        placeholder="Name"
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid container item justifyContent="center">
                                <Grid item xs={10}>
                                  <Grid
                                    display={'block'}
                                    style={{
                                      marginLeft: '0%',
                                      marginTop: '5%',
                                    }}
                                  >
                                    <Box display={'block'}>
                                      <StyledInput
                                        hideLabels={!displayLabels}
                                        label="Description"
                                        value={formikProps.values.description}
                                        formikProps={formikProps}
                                        formikKey="description"
                                        placeholder="Description"
                                        maxLength={255}
                                        multiline={true}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid container item justifyContent="center">
                                <Grid item xs={10}>
                                  <Grid
                                    display={'inline-flex'}
                                    style={{
                                      marginLeft: '0%',
                                      marginTop: '5%',
                                    }}
                                  >
                                    <SelectFromList
                                      paddingRight={20}
                                      value={formikProps.values.contestType}
                                      fieldSetter={(itemValue) => {
                                        formikProps.setFieldValue(
                                          'contestType',
                                          itemValue
                                        );
                                        if (
                                          itemValue === 'regular' ||
                                          itemValue === 'throw_down' ||
                                          itemValue === 'percent_to_goal' ||
                                          itemValue === 'advance_on_goal_met'
                                        ) {
                                          formikProps.setFieldValue(
                                            'type',
                                            'mission'
                                          );
                                        } else {
                                          formikProps.setFieldValue(
                                            'type',
                                            'quest'
                                          );
                                        }
                                      }}
                                      items={MISSION_TYPES}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            {!formikProps.values.useDealStage && (
                              <Grid container spacing={2}>
                                <Grid container item justifyContent="center">
                                  <Grid item xs={10}>
                                    <Grid
                                      display={'inline-flex'}
                                      style={{
                                        marginLeft: '0%',
                                        marginTop: '5%',
                                      }}
                                    >
                                      <Box display={'block'}>
                                        {!formikProps.values.useDealStage && (
                                          <SelectMissionCategory
                                            filter={
                                              formikProps.values.organizationID
                                            }
                                            value={
                                              formikProps.values.categoryID
                                            }
                                            fieldSetter={(value) => {
                                              formikProps.setFieldValue(
                                                'categoryID',
                                                value
                                              );
                                            }}
                                          />
                                        )}
                                      </Box>
                                      <Box display={'block'}>
                                        {!formikProps.values.useDealStage && (
                                          <View
                                            style={{ flexDirection: 'row' }}
                                          >
                                            <Typography
                                              color={'black'}
                                              style={{
                                                marginRight: 10,
                                                paddingLeft: 4,
                                                paddingTop: 6,
                                                textShadowColor: 'white',
                                                textShadowRadius: 3,
                                              }}
                                            >
                                              Use Step Counter?
                                            </Typography>

                                            <Switch
                                              style={{
                                                transform: [
                                                  { scaleX: 0.8 },
                                                  { scaleY: 0.8 },
                                                ],
                                              }}
                                              onChange={() => {
                                                formikProps.setFieldValue(
                                                  'useStepCounter',
                                                  !formikProps.values
                                                    .useStepCounter
                                                );
                                              }}
                                              value={
                                                formikProps.values
                                                  .useStepCounter
                                              }
                                            />
                                          </View>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}

                            {formikProps.values.useDealStage && (
                              <Grid container spacing={2}>
                                <Grid container item justifyContent="center">
                                  <Grid item xs={10}>
                                    <Grid
                                      display={'inline-flex'}
                                      style={{
                                        marginLeft: '0%',
                                        marginTop: '5%',
                                      }}
                                    >
                                      <Box display={'block'}>
                                        <>
                                          <Typography color={'black'}>
                                            KPI Rule
                                          </Typography>
                                          <View style={{ marginTop: 0 }}>
                                            <SelectKPIRule
                                              name={'KPIRuleID'}
                                              paddingRight={20}
                                              filter={
                                                formikProps.values
                                                  .organizationID
                                              }
                                              value={
                                                formikProps.values.KPIRuleID
                                              }
                                              fieldSetter={(
                                                value,
                                                name,
                                                ruleAction,
                                                ruleValueUnitPlural,
                                                ruleActionPastTense,
                                                ruleValueUnit
                                              ) => {
                                                // LOG.debug ("!!!rule: ", rule);
                                                formikProps.setFieldValue(
                                                  'KPIRuleID',
                                                  value
                                                );
                                                formikProps.setFieldValue(
                                                  'KPIRuleName',
                                                  name
                                                );
                                                formikProps.setFieldValue(
                                                  'action',
                                                  ruleAction
                                                );
                                                formikProps.setFieldValue(
                                                  'actionTypePlural',
                                                  ruleValueUnitPlural
                                                );
                                                formikProps.setFieldValue(
                                                  'actionPastTense',
                                                  ruleActionPastTense
                                                );
                                                formikProps.setFieldValue(
                                                  'actionType',
                                                  ruleValueUnit
                                                );
                                              }}
                                            />
                                          </View>
                                          <Typography
                                            style={{ paddingTop: 20 }}
                                            color={'black'}
                                          >
                                            Tie Breaker KPI Rule
                                          </Typography>
                                          <View style={{ marginTop: 0 }}>
                                            <SelectKPIRule
                                              name={'tieKPIRuleID'}
                                              paddingRight={20}
                                              filter={
                                                formikProps.values
                                                  .organizationID
                                              }
                                              value={
                                                formikProps.values.tieKPIRuleID
                                              }
                                              fieldSetter={(value, name) => {
                                                // LOG.debug ("!!!rule: ", rule);
                                                formikProps.setFieldValue(
                                                  'tieKPIRuleID',
                                                  value
                                                );
                                                formikProps.setFieldValue(
                                                  'tieKPIRuleName',
                                                  name
                                                );
                                              }}
                                            />
                                          </View>
                                        </>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}

                            <Grid container spacing={2}>
                              <Grid container item justifyContent="center">
                                <Grid item xs={10}>
                                  <Grid
                                    display={'inline-flex'}
                                    style={{
                                      marginLeft: '0%',
                                      marginTop: '5%',
                                    }}
                                  >
                                    <Box display={'block'}>
                                      <StyledInput
                                        value={formikProps.values.actionCount}
                                        formikProps={formikProps}
                                        formikKey="actionCount"
                                        placeholder="Goal"
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>

                          <Paper className={'paper-overlay'}>
                            <Grid
                              className={'title-overlay'}
                              container
                              item
                              justifyContent="center"
                            >
                              <Grid item>
                                <Typography color={'black'} variant="h6">
                                  Reward
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid container item justifyContent="center">
                                <Grid item xs={10}>
                                  <Grid
                                    display={'inline-flex'}
                                    style={{ marginLeft: '0%' }}
                                  >
                                    <Box display={'block'}>
                                      <Typography
                                        style={{ paddingTop: 16 }}
                                        color={'black'}
                                      >
                                        Give{' '}
                                      </Typography>
                                    </Box>
                                    <Box
                                      display={'block'}
                                      style={{
                                        paddingTop: '4%',
                                        marginLeft: '10%',
                                      }}
                                    >
                                      <SelectFromList
                                        value={
                                          formikProps.values.rewardCurrency
                                        }
                                        fieldSetter={(itemValue) => {
                                          formikProps.setFieldValue(
                                            'rewardCurrency',
                                            itemValue
                                          );
                                          const rewardType =
                                            formikProps.values.rewardWhen +
                                            '_' +
                                            itemValue;
                                          formikProps.setFieldValue(
                                            'rewardType',
                                            rewardType
                                          );

                                          if (
                                            rewardType === 'action_points' ||
                                            rewardType === 'mission_points'
                                          ) {
                                            formikProps.setFieldValue(
                                              'rewardName1',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardName2',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardName3',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardNameP',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardName1t',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardName2t',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardName3t',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardNamePt',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                          } else {
                                            formikProps.setFieldValue(
                                              'rewardCount1',
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'rewardCount2',
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'rewardCount3',
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'rewardCountP',
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'rewardCount1t',
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'rewardCount2t',
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'rewardCount3t',
                                              ''
                                            );
                                          }
                                        }}
                                        placeholder="Reward Type"
                                        items={[
                                          {
                                            label: 'a Reward',
                                            value: 'reward',
                                          },
                                          {
                                            label:
                                              global.appSettings.labels.points
                                                .singular + '(s)',
                                            value: 'points',
                                          },
                                        ]}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid container item justifyContent="center">
                                <Grid item xs={10}>
                                  <Grid
                                    display={'inline-flex'}
                                    style={{
                                      marginLeft: '0%',
                                      marginTop: '5%',
                                    }}
                                  >
                                    <Box
                                      display={'block'}
                                      style={{ paddingTop: '2%' }}
                                    >
                                      <Typography color={'black'}>
                                        When{' '}
                                      </Typography>
                                    </Box>
                                    <Box
                                      display={'block'}
                                      style={{ marginLeft: '5%' }}
                                    >
                                      <SelectFromList
                                        value={formikProps.values.rewardWhen}
                                        fieldSetter={(itemValue) => {
                                          formikProps.setFieldValue(
                                            'rewardWhen',
                                            itemValue
                                          );
                                          const rewardType =
                                            itemValue +
                                            '_' +
                                            formikProps.values.rewardCurrency;
                                          formikProps.setFieldValue(
                                            'rewardType',
                                            rewardType
                                          );

                                          if (
                                            rewardType === 'action_points' ||
                                            rewardType === 'mission_points'
                                          ) {
                                            formikProps.setFieldValue(
                                              'rewardName1',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardName2',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardName3',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardNameP',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardName1t',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardName2t',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardName3t',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                            formikProps.setFieldValue(
                                              'rewardNamePt',
                                              global.appSettings.labels.points
                                                .plural
                                            );
                                          } else {
                                            formikProps.setFieldValue(
                                              'rewardCount1',
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'rewardCount2',
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'rewardCount3',
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'rewardCountP',
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'rewardCount1t',
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'rewardCount2t',
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'rewardCount3t',
                                              ''
                                            );
                                          }
                                        }}
                                        placeholder="When"
                                        items={[
                                          {
                                            label:
                                              'the ' +
                                              global.appSettings.labels.mission
                                                .proper +
                                              ' is complete',
                                            value: 'mission',
                                          },
                                          {
                                            label:
                                              'the ' +
                                              global.appSettings.labels.mission
                                                .proper +
                                              ' is complete w/minimum',
                                            value: 'mission_min',
                                          },
                                          {
                                            label: 'the Goal is met',
                                            value: 'goal',
                                          },
                                          {
                                            label: 'the action is complete',
                                            value: 'action',
                                          },
                                        ]}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            {(formikProps.values.rewardType ===
                              'action_reward' ||
                              formikProps.values.rewardType === 'goal_reward' ||
                              formikProps.values.rewardType ===
                                'action_points' ||
                              formikProps.values.rewardType ===
                                'goal_points') && (
                              <Grid container spacing={2}>
                                <Grid container item justifyContent="center">
                                  <Grid item xs={10}>
                                    <Grid
                                      display={'inline-flex'}
                                      style={{
                                        marginLeft: '0%',
                                        marginTop: '5%',
                                      }}
                                    >
                                      <Box display={'block'}>
                                        {(formikProps.values.rewardType ===
                                          'action_reward' ||
                                          formikProps.values.rewardType ===
                                            'goal_reward' ||
                                          formikProps.values.rewardType ===
                                            'action_points' ||
                                          formikProps.values.rewardType ===
                                            'goal_points') && (
                                          <View
                                            style={{ flexDirection: 'row' }}
                                          >
                                            <Typography
                                              color={'black'}
                                              style={{ paddingTop: 6 }}
                                            >
                                              Winner Limit
                                            </Typography>
                                            <View>
                                              <StyledInput
                                                value={
                                                  formikProps.values.rewardLimit
                                                }
                                                formikProps={formikProps}
                                                formikKey="rewardLimit"
                                                placeholder="blank = no limit"
                                              />
                                            </View>
                                          </View>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}

                            {(formikProps.values.rewardType ===
                              'action_points' ||
                              formikProps.values.rewardType ===
                                'goal_points') && (
                              <Grid container spacing={2}>
                                <Grid container item justifyContent="center">
                                  <Grid item xs={10}>
                                    <Grid
                                      display={'inline-flex'}
                                      style={{
                                        marginLeft: '0%',
                                        marginTop: '5%',
                                      }}
                                    >
                                      <Box display={'block'}>
                                        {(formikProps.values.rewardType ===
                                          'action_points' ||
                                          formikProps.values.rewardType ===
                                            'goal_points') && (
                                          <StyledInput
                                            value={
                                              formikProps.values.rewardCountP
                                            }
                                            formikProps={formikProps}
                                            formikKey="rewardCountP"
                                            placeholder={
                                              global.appSettings.labels.points
                                                .plural
                                            }
                                          />
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}

                            {(formikProps.values.rewardType ===
                              'mission_min_points' ||
                              formikProps.values.rewardType ===
                                'mission_min_reward') && (
                              <Grid container spacing={2}>
                                <Grid container item justifyContent="center">
                                  <Grid item xs={10}>
                                    <Grid
                                      display={'inline-flex'}
                                      style={{
                                        marginLeft: '0%',
                                        marginTop: '5%',
                                      }}
                                    >
                                      <Box display={'block'}>
                                        {(formikProps.values.rewardType ===
                                          'mission_min_points' ||
                                          formikProps.values.rewardType ===
                                            'mission_min_reward') && (
                                          <View
                                            style={{ flexDirection: 'row' }}
                                          >
                                            <Typography
                                              color={'black'}
                                              style={{ paddingTop: 6 }}
                                            >
                                              Minimum{' '}
                                            </Typography>
                                            <StyledInput
                                              hideLabels
                                              value={formikProps.values.minGoal}
                                              formikProps={formikProps}
                                              formikKey="minGoal"
                                              placeholder="Minimum Goal"
                                            />
                                          </View>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}

                            {(formikProps.values.rewardType ===
                              'mission_points' ||
                              formikProps.values.rewardType ===
                                'mission_min_points') && (
                              <Grid container spacing={2}>
                                <Grid container item justifyContent="center">
                                  <Grid item xs={10}>
                                    <Grid
                                      display={'inline-flex'}
                                      style={{
                                        marginLeft: '0%',
                                        marginTop: '5%',
                                      }}
                                    >
                                      <Box display={'block'}>
                                        {(formikProps.values.rewardType ===
                                          'mission_points' ||
                                          formikProps.values.rewardType ===
                                            'mission_min_points') && (
                                          <View>
                                            <View
                                              style={{
                                                flex: 1,
                                                alignSelf: 'stretch',
                                                flexDirection: 'row',
                                                paddingTop: 8,
                                              }}
                                            >
                                              <StyledInput
                                                label="1st Place"
                                                value={
                                                  formikProps.values
                                                    .rewardCount1
                                                }
                                                formikProps={formikProps}
                                                formikKey="rewardCount1"
                                                placeholder={
                                                  global.appSettings.labels
                                                    .points.plural
                                                }
                                              />
                                              <StyledInput
                                                label="2nd Place"
                                                value={
                                                  formikProps.values
                                                    .rewardCount2
                                                }
                                                formikProps={formikProps}
                                                formikKey="rewardCount2"
                                                placeholder={
                                                  global.appSettings.labels
                                                    .points.plural
                                                }
                                              />
                                              <StyledInput
                                                label="3rd Place"
                                                value={
                                                  formikProps.values
                                                    .rewardCount3
                                                }
                                                formikProps={formikProps}
                                                formikKey="rewardCount3"
                                                placeholder={
                                                  global.appSettings.labels
                                                    .points.plural
                                                }
                                              />
                                              <StyledInput
                                                label="Participant"
                                                value={
                                                  formikProps.values
                                                    .rewardCountP
                                                }
                                                formikProps={formikProps}
                                                formikKey="rewardCountP"
                                                placeholder={
                                                  global.appSettings.labels
                                                    .points.plural
                                                }
                                              />
                                            </View>
                                            <Typography
                                              color={'black'}
                                              style={{ paddingTop: '8%' }}
                                            >
                                              Tie Breaker: (multiple
                                              people/teams have the same result)
                                            </Typography>
                                            <View
                                              style={{
                                                flex: 1,
                                                alignSelf: 'stretch',
                                                flexDirection: 'row',
                                                paddingTop: 8,
                                              }}
                                            >
                                              <StyledInput
                                                label="1st Place"
                                                value={
                                                  formikProps.values
                                                    .rewardCount1t
                                                }
                                                formikProps={formikProps}
                                                formikKey="rewardCount1t"
                                                placeholder={
                                                  global.appSettings.labels
                                                    .points.plural
                                                }
                                              />
                                              <StyledInput
                                                label="2nd Place"
                                                value={
                                                  formikProps.values
                                                    .rewardCount2t
                                                }
                                                formikProps={formikProps}
                                                formikKey="rewardCount2t"
                                                placeholder={
                                                  global.appSettings.labels
                                                    .points.plural
                                                }
                                              />
                                              <StyledInput
                                                label="3rd Place"
                                                value={
                                                  formikProps.values
                                                    .rewardCount3t
                                                }
                                                formikProps={formikProps}
                                                formikKey="rewardCount3t"
                                                placeholder={
                                                  global.appSettings.labels
                                                    .points.plural
                                                }
                                              />
                                            </View>
                                          </View>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}

                            {(formikProps.values.rewardType ===
                              'action_reward' ||
                              formikProps.values.rewardType ===
                                'goal_reward') && (
                              <Grid container spacing={2}>
                                <Grid container item justifyContent="center">
                                  <Grid item xs={10}>
                                    <Grid
                                      display={'inline-flex'}
                                      style={{
                                        marginLeft: '0%',
                                        marginTop: '5%',
                                      }}
                                    >
                                      <Box display={'block'}>
                                        {(formikProps.values.rewardType ===
                                          'action_reward' ||
                                          formikProps.values.rewardType ===
                                            'goal_reward') && (
                                          <SelectReward
                                            paddingRight={100}
                                            filter={
                                              formikProps.values.organizationID
                                            }
                                            value={formikProps.values.rewardIDp}
                                            fieldSetter={(value, name) => {
                                              formikProps.setFieldValue(
                                                'rewardIDp',
                                                value
                                              );
                                              formikProps.setFieldValue(
                                                'rewardNameP',
                                                name
                                              );
                                            }}
                                          />
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}

                            {(formikProps.values.rewardType ===
                              'mission_reward' ||
                              formikProps.values.rewardType ===
                                'mission_min_reward') && (
                              <Grid container spacing={2}>
                                <Grid container item justifyContent="center">
                                  <Grid item xs={10}>
                                    <Grid
                                      style={{
                                        marginLeft: '0%',
                                        marginTop: '5%',
                                      }}
                                    >
                                      <Box display={'block'}>
                                        {(formikProps.values.rewardType ===
                                          'mission_reward' ||
                                          formikProps.values.rewardType ===
                                            'mission_min_reward') && (
                                          <View>
                                            <Typography color={'black'}>
                                              Not Tied: (only one person/team
                                              has the same result)
                                            </Typography>
                                            <Grid
                                              display={'inline-flex'}
                                              style={{ marginTop: '3%' }}
                                            >
                                              <Box display={'block'}>
                                                <Typography
                                                  color={'black'}
                                                  style={{
                                                    width: 90,
                                                    paddingTop: 16,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  1st Place
                                                </Typography>
                                              </Box>
                                              <Box
                                                display={'block'}
                                                style={{ paddingTop: '2%' }}
                                              >
                                                <SelectReward
                                                  paddingRight={100}
                                                  filter={
                                                    formikProps.values
                                                      .organizationID
                                                  }
                                                  value={
                                                    formikProps.values.rewardID1
                                                  }
                                                  fieldSetter={(
                                                    value,
                                                    name
                                                  ) => {
                                                    formikProps.setFieldValue(
                                                      'rewardID1',
                                                      value
                                                    );
                                                    formikProps.setFieldValue(
                                                      'rewardName1',
                                                      name
                                                    );
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid display={'inline-flex'}>
                                              <Box display={'block'}>
                                                <Typography
                                                  color={'black'}
                                                  style={{
                                                    width: 90,
                                                    paddingTop: 16,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  2nd Place
                                                </Typography>
                                              </Box>
                                              <Box
                                                display={'block'}
                                                style={{ paddingTop: '2%' }}
                                              >
                                                <SelectReward
                                                  paddingRight={100}
                                                  filter={
                                                    formikProps.values
                                                      .organizationID
                                                  }
                                                  value={
                                                    formikProps.values.rewardID2
                                                  }
                                                  fieldSetter={(
                                                    value,
                                                    name
                                                  ) => {
                                                    formikProps.setFieldValue(
                                                      'rewardID2',
                                                      value
                                                    );
                                                    formikProps.setFieldValue(
                                                      'rewardName2',
                                                      name
                                                    );
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid display={'inline-flex'}>
                                              <Box display={'block'}>
                                                <Typography
                                                  color={'black'}
                                                  style={{
                                                    width: 90,
                                                    paddingTop: 16,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  3rd Place
                                                </Typography>
                                              </Box>
                                              <Box
                                                display={'block'}
                                                style={{ paddingTop: '2%' }}
                                              >
                                                <SelectReward
                                                  paddingRight={100}
                                                  filter={
                                                    formikProps.values
                                                      .organizationID
                                                  }
                                                  value={
                                                    formikProps.values.rewardID3
                                                  }
                                                  fieldSetter={(
                                                    value,
                                                    name
                                                  ) => {
                                                    formikProps.setFieldValue(
                                                      'rewardID3',
                                                      value
                                                    );
                                                    formikProps.setFieldValue(
                                                      'rewardName3',
                                                      name
                                                    );
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid display={'inline-flex'}>
                                              <Box display={'block'}>
                                                <Typography
                                                  color={'black'}
                                                  style={{
                                                    width: 90,
                                                    paddingTop: 16,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  Participant
                                                </Typography>
                                              </Box>
                                              <Box
                                                display={'block'}
                                                style={{ paddingTop: '2%' }}
                                              >
                                                <SelectReward
                                                  paddingRight={100}
                                                  filter={
                                                    formikProps.values
                                                      .organizationID
                                                  }
                                                  value={
                                                    formikProps.values.rewardIDp
                                                  }
                                                  fieldSetter={(
                                                    value,
                                                    name
                                                  ) => {
                                                    formikProps.setFieldValue(
                                                      'rewardIDp',
                                                      value
                                                    );
                                                    formikProps.setFieldValue(
                                                      'rewardNameP',
                                                      name
                                                    );
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Typography
                                              color={'black'}
                                              style={{ marginTop: '5%' }}
                                            >
                                              Tied: (multiple people/teams have
                                              the same result)
                                            </Typography>
                                            <Grid
                                              display={'inline-flex'}
                                              style={{ marginTop: '3%' }}
                                            >
                                              <Box display={'block'}>
                                                <Typography
                                                  color={'black'}
                                                  style={{
                                                    width: 90,
                                                    paddingTop: 16,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  1st Place
                                                </Typography>
                                              </Box>
                                              <Box
                                                display={'block'}
                                                style={{ paddingTop: '2%' }}
                                              >
                                                <SelectReward
                                                  paddingRight={100}
                                                  filter={
                                                    formikProps.values
                                                      .organizationID
                                                  }
                                                  value={
                                                    formikProps.values
                                                      .rewardID1t
                                                  }
                                                  fieldSetter={(
                                                    value,
                                                    name
                                                  ) => {
                                                    formikProps.setFieldValue(
                                                      'rewardID1t',
                                                      value
                                                    );
                                                    formikProps.setFieldValue(
                                                      'rewardName1t',
                                                      name
                                                    );
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid display={'inline-flex'}>
                                              <Box display={'block'}>
                                                <Typography
                                                  color={'black'}
                                                  style={{
                                                    width: 90,
                                                    paddingTop: 16,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  2nd Place
                                                </Typography>
                                              </Box>
                                              <Box
                                                display={'block'}
                                                style={{ paddingTop: '2%' }}
                                              >
                                                <SelectReward
                                                  paddingRight={100}
                                                  filter={
                                                    formikProps.values
                                                      .organizationID
                                                  }
                                                  value={
                                                    formikProps.values
                                                      .rewardID2t
                                                  }
                                                  fieldSetter={(
                                                    value,
                                                    name
                                                  ) => {
                                                    formikProps.setFieldValue(
                                                      'rewardID2t',
                                                      value
                                                    );
                                                    formikProps.setFieldValue(
                                                      'rewardName2t',
                                                      name
                                                    );
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid display={'inline-flex'}>
                                              <Box display={'block'}>
                                                <Typography
                                                  color={'black'}
                                                  style={{
                                                    width: 90,
                                                    paddingTop: 16,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  3rd Place
                                                </Typography>
                                              </Box>
                                              <Box
                                                display={'block'}
                                                style={{ paddingTop: '2%' }}
                                              >
                                                <SelectReward
                                                  paddingRight={100}
                                                  filter={
                                                    formikProps.values
                                                      .organizationID
                                                  }
                                                  value={
                                                    formikProps.values
                                                      .rewardID3t
                                                  }
                                                  fieldSetter={(
                                                    value,
                                                    name
                                                  ) => {
                                                    formikProps.setFieldValue(
                                                      'rewardID3t',
                                                      value
                                                    );
                                                    formikProps.setFieldValue(
                                                      'rewardName3t',
                                                      name
                                                    );
                                                  }}
                                                />
                                              </Box>
                                            </Grid>
                                          </View>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Paper>

                          <Paper className={'paper-overlay'}>
                            <Grid
                              className={'title-overlay'}
                              container
                              item
                              justifyContent="center"
                            >
                              <Grid item>
                                <Typography color={'black'} variant="h6">
                                  Duration
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid container item justifyContent="center">
                                <Grid item xs={10}>
                                  <Grid style={{ marginLeft: '0%' }}>
                                    <Grid display={'inline-flex'}>
                                      <Box
                                        display={'block'}
                                        style={{ paddingTop: '3%' }}
                                      >
                                        <Typography
                                          className={'date-picker-description'}
                                          color={'black'}
                                        >
                                          Start Date (blank = now):
                                        </Typography>
                                      </Box>
                                      <Box display={'block'}>
                                        <Box className={'date-picker-overlay'}>
                                          <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                          >
                                            <ThemeProvider theme={theme}>
                                              <DateTimePickerField
                                                maxDate={
                                                  formikProps.values.endDate
                                                }
                                                name="startDate"
                                                formikProps={formikProps}
                                                // value={formikProps.values.startDate}
                                                value={
                                                  formikProps.values
                                                    .startDate !== ''
                                                    ? formikProps.values
                                                        .startDate
                                                    : formattedLocalDateTimeShort(
                                                        new Date()
                                                      )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    className={
                                                      'date-picker-text'
                                                    }
                                                    {...params}
                                                  />
                                                )}
                                              />
                                            </ThemeProvider>
                                          </LocalizationProvider>
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid display={'inline-flex'}>
                                      <Box
                                        display={'block'}
                                        style={{ paddingTop: '3%' }}
                                      >
                                        <Typography
                                          className={'date-picker-description'}
                                          color={'black'}
                                        >
                                          End Date (blank = forever):
                                        </Typography>
                                      </Box>
                                      <Box display={'block'}>
                                        <Box className={'date-picker-overlay'}>
                                          <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                          >
                                            <ThemeProvider theme={theme}>
                                              <DateTimePickerField
                                                minDate={
                                                  formikProps.values.startDate
                                                }
                                                name="endDate"
                                                formikProps={formikProps}
                                                value={
                                                  formikProps.values.endDate
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    className={
                                                      'date-picker-text'
                                                    }
                                                    {...params}
                                                  />
                                                )}
                                              />
                                            </ThemeProvider>
                                          </LocalizationProvider>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>

                          <Paper className={'paper-overlay'}>
                            <Grid
                              className={'title-overlay'}
                              container
                              item
                              justifyContent="center"
                            >
                              <Grid item>
                                <Typography color={'black'} variant="h6">
                                  Assignment
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid container item justifyContent="center">
                                <Grid item xs={10}>
                                  <Grid
                                    display={'inline-flex'}
                                    style={{
                                      marginLeft: '0%',
                                      marginTop: '3%',
                                    }}
                                  >
                                    <Box display={'block'}>
                                      {missionID !== '' && (
                                        <Typography color={'red'}>
                                          Use the previous screen to change
                                          assignments.
                                        </Typography>
                                      )}
                                      {/* {formikProps.values.type == 'mission' ? */}
                                      {/*  <Text style={{fontSize: 18, padding: 8, textAlign:'center'}}>This {global.appSettings.labels.mission.singular} is assigned to {formikProps.values.assigneeName}</Text> */}
                                      {/*  : */}
                                      {/*  <Text style={{fontSize: 18, padding: 8, textAlign:'center'}}>This {global.appSettings.labels.quest.singular} is assigned to {formikProps.values.assigneeName}</Text> */}
                                      {/* } */}
                                      {missionID === '' && (
                                        <React.Fragment>
                                          <SelectFromList
                                            name={'scope'}
                                            paddingRight={20}
                                            value={formikProps.values.scope}
                                            fieldSetter={(itemValue) => {
                                              formikProps.setFieldValue(
                                                'scope',
                                                itemValue
                                              );
                                              if (itemValue === 'me') {
                                                if (
                                                  !assigneeIDs.includes(
                                                    global.me.id
                                                  )
                                                ) {
                                                  setAssigneeIDs([
                                                    global.me.id,
                                                  ]);
                                                  setAssigneeNames([
                                                    global.me.name,
                                                  ]);
                                                  formikProps.setFieldValue(
                                                    'assigneeID',
                                                    global.me.id
                                                  );
                                                }
                                              }
                                              console.log(
                                                'ASSIGNEE NAMES: ',
                                                assigneeNames
                                              );
                                            }}
                                            placeholder="Scope"
                                            items={[
                                              {
                                                label:
                                                  'Assign ' +
                                                  global.appSettings.labels
                                                    .mission.singular +
                                                  '  to a ' +
                                                  global.appSettings.labels
                                                    .circle.singular,
                                                value: 'circle',
                                              },
                                              {
                                                label:
                                                  'Assign ' +
                                                  global.appSettings.labels
                                                    .mission.singular +
                                                  '  to a ' +
                                                  global.appSettings.labels
                                                    .person.singular,
                                                value: 'person',
                                              },
                                            ]}
                                          />
                                        </React.Fragment>
                                      )}

                                      {missionID === '' && (
                                        <React.Fragment>
                                          {formikProps.values.scope !== 'me' &&
                                            (formikProps.values.contestType ===
                                              'regular' ||
                                              formikProps.values.contestType ===
                                                'throw_down' ||
                                              formikProps.values.contestType ===
                                                'percent_to_goal') && (
                                              <View style={{ marginTop: '5%' }}>
                                                <Typography color={'black'}>
                                                  Assigned To:
                                                </Typography>
                                                {assigneeNames.length > 0 && (
                                                  <>
                                                    {assigneeNames.map(
                                                      (name, a) => (
                                                        <Box
                                                          key={a}
                                                          style={{
                                                            border:
                                                              '1px solid #c1c1c1',
                                                            borderRadius: 10,
                                                            marginTop: '3%',
                                                            width:
                                                              'fit-content',
                                                          }}
                                                        >
                                                          <View
                                                            style={{
                                                              flexDirection:
                                                                'row',
                                                              justifyContent:
                                                                'space-between',
                                                              paddingLeft: 5,
                                                              paddingRight: 5,
                                                            }}
                                                          >
                                                            <Button
                                                              style={{
                                                                paddingTop:
                                                                  '1%',
                                                              }}
                                                              onClick={() => {
                                                                console.log(
                                                                  '[ON CLICK] global.me.name: ',
                                                                  [
                                                                    global.me
                                                                      .name,
                                                                  ]
                                                                );
                                                                console.log(
                                                                  'ASSIGNEE NAMES: ',
                                                                  assigneeNames
                                                                );
                                                                const newIDs =
                                                                  [];
                                                                const newNames =
                                                                  [];
                                                                console.log(
                                                                  'ASSIGNEE IDs: ',
                                                                  assigneeIDs
                                                                );
                                                                for (const i in assigneeIDs) {
                                                                  if (i !== a) {
                                                                    newIDs[
                                                                      newIDs.length
                                                                    ] =
                                                                      assigneeIDs[
                                                                        i
                                                                      ];
                                                                    newNames[
                                                                      newNames.length
                                                                    ] =
                                                                      assigneeNames[
                                                                        i
                                                                      ];
                                                                  }
                                                                }
                                                                setAssigneeIDs(
                                                                  newIDs
                                                                );
                                                                setAssigneeNames(
                                                                  newNames
                                                                );
                                                                setUpdate(
                                                                  update + 1
                                                                );
                                                                console.log(
                                                                  'ASSIGNEE NAMES: ',
                                                                  assigneeNames
                                                                );
                                                              }}
                                                            >
                                                              <Typography
                                                                color={'black'}
                                                                style={{
                                                                  paddingTop: 8,
                                                                }}
                                                              >
                                                                {' '}
                                                                {name ||
                                                                  'no name'}{' '}
                                                              </Typography>
                                                            </Button>
                                                          </View>
                                                        </Box>
                                                      )
                                                    )}
                                                  </>
                                                )}
                                              </View>
                                            )}

                                          {formikProps.values.scope ===
                                            'person' &&
                                            (formikProps.values.contestType ===
                                              'regular' ||
                                              formikProps.values.contestType ===
                                                'throw_down' ||
                                              formikProps.values.contestType ===
                                                'percent_to_goal') && (
                                              <Box
                                                style={{
                                                  marginTop: '7%',
                                                  marginBottom: '3%',
                                                }}
                                              >
                                                <SelectPerson
                                                  name={'assigneeID'}
                                                  update={update}
                                                  hideAssigned={assigneeIDs}
                                                  paddingRight={20}
                                                  filter={
                                                    formikProps.values
                                                      .organizationID
                                                  }
                                                  value={''}
                                                  fieldSetter={(
                                                    value,
                                                    name
                                                  ) => {
                                                    if (
                                                      !assigneeIDs.includes(
                                                        value
                                                      ) &&
                                                      value !== ''
                                                    ) {
                                                      const ourAssigneeIDs =
                                                        assigneeIDs;
                                                      ourAssigneeIDs[
                                                        ourAssigneeIDs.length
                                                      ] = value;
                                                      setAssigneeIDs(
                                                        ourAssigneeIDs
                                                      );
                                                      const ourAssigneeNames =
                                                        assigneeNames;
                                                      ourAssigneeNames[
                                                        ourAssigneeNames.length
                                                      ] = name;
                                                      setAssigneeNames(
                                                        ourAssigneeNames
                                                      );
                                                      formikProps.setFieldValue(
                                                        'assigneeID',
                                                        value
                                                      );
                                                      setUpdate(update + 1);
                                                    }
                                                  }}
                                                />
                                              </Box>
                                            )}
                                          {formikProps.values.scope ===
                                            'circle' &&
                                            (formikProps.values.contestType ===
                                              'regular' ||
                                              formikProps.values.contestType ===
                                                'throw_down' ||
                                              formikProps.values.contestType ===
                                                'percent_to_goal') && (
                                              <React.Fragment>
                                                <Box
                                                  style={{ marginTop: '5%' }}
                                                >
                                                  <SelectCircle
                                                    name={'assigneeID'}
                                                    hideCircleLevels={
                                                      global.appSettings
                                                        .features.circle
                                                        .hideLevels
                                                    }
                                                    update={update}
                                                    checkCreatePermissions
                                                    hideAssigned={assigneeIDs}
                                                    paddingRight={20}
                                                    value={lastAssigneeID}
                                                    filter={
                                                      formikProps.values
                                                        .organizationID
                                                    }
                                                    noAutoSelect={true}
                                                    fieldSetter={(
                                                      value,
                                                      name
                                                    ) => {
                                                      LOG.debug(
                                                        '!!!selectCircle value: ',
                                                        value
                                                      );
                                                      if (
                                                        !assigneeIDs.includes(
                                                          value
                                                        ) &&
                                                        value !== '' &&
                                                        value !== lastAssigneeID
                                                      ) {
                                                        const ourAssigneeIDs =
                                                          assigneeIDs;
                                                        ourAssigneeIDs[
                                                          ourAssigneeIDs.length
                                                        ] = value;
                                                        setAssigneeIDs(
                                                          ourAssigneeIDs
                                                        );
                                                        const ourAssigneeNames =
                                                          assigneeNames;
                                                        ourAssigneeNames[
                                                          ourAssigneeNames.length
                                                        ] = name;
                                                        setAssigneeNames(
                                                          ourAssigneeNames
                                                        );
                                                        formikProps.setFieldValue(
                                                          'assigneeID',
                                                          value
                                                        );
                                                        setLastAssigneeID(
                                                          value
                                                        );
                                                        setUpdate(update + 1);
                                                      }
                                                    }}
                                                  />
                                                </Box>
                                                <Grid
                                                  display={'inline-flex'}
                                                  style={{ marginTop: '7%' }}
                                                >
                                                  <Box display={'block'}>
                                                    <Typography
                                                      color={'black'}
                                                      style={{
                                                        marginRight: 10,
                                                        paddingLeft: 4,
                                                        paddingTop: 6,
                                                        textShadowColor:
                                                          'white',
                                                        textShadowRadius: 3,
                                                      }}
                                                    >
                                                      Is this a{' '}
                                                      {
                                                        global.appSettings
                                                          .labels.circle
                                                          .singular
                                                      }{' '}
                                                      vs{' '}
                                                      {
                                                        global.appSettings
                                                          .labels.circle
                                                          .singular
                                                      }{' '}
                                                      {
                                                        global.appSettings
                                                          .labels.mission
                                                          .singular
                                                      }
                                                      ?
                                                    </Typography>
                                                  </Box>
                                                  <Box display={'block'}>
                                                    <Switch
                                                      style={{
                                                        transform: [
                                                          { scaleX: 0.8 },
                                                          { scaleY: 0.8 },
                                                        ],
                                                      }}
                                                      onChange={() => {
                                                        //
                                                        //  All circle only missions are ALWAYS assigned to all team members (even though they won't know it)
                                                        //
                                                        formikProps.setFieldValue(
                                                          'isCircleOnly',
                                                          !formikProps.values
                                                            .isCircleOnly
                                                        );
                                                        formikProps.setFieldValue(
                                                          'autoAssign',
                                                          true
                                                        );
                                                      }}
                                                      value={
                                                        formikProps.values
                                                          .isCircleOnly
                                                      }
                                                    />
                                                  </Box>
                                                </Grid>
                                                {formikProps.values
                                                  .isCircleOnly && (
                                                  <Box
                                                    style={{
                                                      marginBottom: '2%',
                                                    }}
                                                  >
                                                    <SelectFromList
                                                      name={'rewardDisposition'}
                                                      paddingRight={20}
                                                      value={
                                                        formikProps.values
                                                          .rewardDisposition
                                                      }
                                                      fieldSetter={(value) => {
                                                        formikProps.setFieldValue(
                                                          'rewardDisposition',
                                                          value
                                                        );
                                                      }}
                                                      placeholder="Reward Disposition"
                                                      items={[
                                                        {
                                                          label:
                                                            'Give ' +
                                                            global.appSettings
                                                              .labels.reward
                                                              .singular +
                                                            ' to each ' +
                                                            global.appSettings
                                                              .labels.circle
                                                              .singular +
                                                            ' member.',
                                                          value: 'winner',
                                                        },
                                                        {
                                                          label:
                                                            'Give ' +
                                                            global.appSettings
                                                              .labels.reward
                                                              .singular +
                                                            ' to the ' +
                                                            global.appSettings
                                                              .labels.circle
                                                              .singular +
                                                            ' manager.',
                                                          value: 'manager',
                                                        },
                                                      ]}
                                                    />
                                                  </Box>
                                                )}
                                                <Grid
                                                  display={'inline-flex'}
                                                  style={{ marginTop: '1%' }}
                                                >
                                                  <Box display={'block'}>
                                                    <Typography
                                                      color={'black'}
                                                      style={{
                                                        marginRight: 10,
                                                        paddingLeft: 4,
                                                        paddingTop: 6,
                                                        textShadowColor:
                                                          'white',
                                                        textShadowRadius: 3,
                                                      }}
                                                    >
                                                      Assign{' '}
                                                      {
                                                        global.appSettings
                                                          .labels.mission
                                                          .singular
                                                      }{' '}
                                                      to all child{' '}
                                                      {
                                                        global.appSettings
                                                          .labels.circle.plural
                                                      }
                                                      :
                                                    </Typography>
                                                  </Box>
                                                  <Box display={'block'}>
                                                    <Switch
                                                      onChange={() => {
                                                        formikProps.setFieldValue(
                                                          'assignToChildCircles',
                                                          !formikProps.values
                                                            .assignToChildCircles
                                                        );
                                                      }}
                                                      value={
                                                        formikProps.values
                                                          .assignToChildCircles
                                                      }
                                                      defaultChecked={true}
                                                    />
                                                  </Box>
                                                </Grid>
                                              </React.Fragment>
                                            )}
                                        </React.Fragment>
                                      )}
                                      <Grid
                                        display={'inline-flex'}
                                        style={{ marginTop: '1%' }}
                                      >
                                        <Box display={'block'}>
                                          <Typography
                                            color={'black'}
                                            style={{
                                              marginRight: 10,
                                              paddingLeft: 4,
                                              paddingTop: 8,
                                              textShadowColor: 'white',
                                              textShadowRadius: 3,
                                            }}
                                          >
                                            Is this a public{' '}
                                            {
                                              global.appSettings.labels.mission
                                                .singular
                                            }
                                            ?
                                          </Typography>
                                        </Box>
                                        <Box display={'block'}>
                                          <Switch
                                            onChange={() => {
                                              //
                                              //  All circle only missions are ALWAYS assigned to all team members (even though they won't know it)
                                              //
                                              formikProps.setFieldValue(
                                                'isPublic',
                                                !formikProps.values.isPublic
                                              );
                                            }}
                                            // value={formikProps.values.isPublic}
                                            defaultChecked={
                                              formikProps.values.isPublic
                                            }
                                          />
                                        </Box>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>

                          {global.appSettings.features.mission
                            .advancedOptions && (
                            <Paper className={'advanced-paper-overlay'}>
                              {global.appSettings.features.mission
                                .advancedOptions && (
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    backgroundColor: 'lightgray',
                                    width: '100%',
                                    borderRadius: 30,
                                  }}
                                  onClick={() => {
                                    setViewAdvanced(!viewAdvanced);
                                  }}
                                >
                                  <Grid
                                    className={'title-overlay'}
                                    container
                                    item
                                    justifyContent="center"
                                  >
                                    <Grid item>
                                      <Typography color={'black'} variant="h6">
                                        Advanced Options
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </View>
                              )}
                            </Paper>
                          )}

                          {viewAdvanced && (
                            <Paper className={'paper-overlay'}>
                              <Grid container spacing={2}>
                                <Grid container item justifyContent="center">
                                  <Grid item xs={10}>
                                    <Grid
                                      display={'inline-flex'}
                                      style={{
                                        marginLeft: '10%',
                                        marginTop: '5%',
                                      }}
                                    >
                                      <Box display={'block'}>
                                        {viewAdvanced && (
                                          <View>
                                            <View style={{ width: '100%' }}>
                                              <Typography
                                                color={'black'}
                                                style={{
                                                  marginRight: 10,
                                                  paddingLeft: 4,
                                                  paddingTop: 6,
                                                  textShadowColor: 'white',
                                                  textShadowRadius: 3,
                                                }}
                                              >
                                                Automatically Advance to the
                                                Next{' '}
                                                {
                                                  global.appSettings.labels
                                                    .mission.proper
                                                }
                                                :
                                              </Typography>
                                              <SelectFromList
                                                paddingRight={20}
                                                value={
                                                  formikProps.values.advanceType
                                                }
                                                fieldSetter={(value) => {
                                                  formikProps.setFieldValue(
                                                    'advanceType',
                                                    value
                                                  );
                                                }}
                                                placeholder={
                                                  'Advance to next ' +
                                                  global.appSettings.labels
                                                    .mission.proper
                                                }
                                                items={[
                                                  {
                                                    label: 'Never',
                                                    value: 'never',
                                                  },
                                                  {
                                                    label:
                                                      'Immediately when the goal is met',
                                                    value: 'goal_met',
                                                  },
                                                  {
                                                    label: 'All who meet goal',
                                                    value:
                                                      'mission_end_goal_met',
                                                  },
                                                  {
                                                    label: 'All At the end',
                                                    value: 'mission_end',
                                                  },
                                                  {
                                                    label: 'Top X Advance',
                                                    value: 'top',
                                                  },
                                                ]}
                                              />
                                            </View>

                                            {formikProps.values.advanceType !==
                                              'never' && (
                                              // hereboss
                                              <View
                                                style={{
                                                  marginLeft: 4,
                                                  marginTop: 10,
                                                }}
                                              >
                                                {formikProps.values
                                                  .advanceType === 'top' && (
                                                  <View
                                                    style={{
                                                      flexDirection: 'row',
                                                    }}
                                                  >
                                                    <Typography
                                                      color={'black'}
                                                      style={{ paddingLeft: 4 }}
                                                    >
                                                      Number to advance:{' '}
                                                    </Typography>
                                                    <View
                                                      style={{
                                                        marginTop: 0,
                                                        marginBottom: 5,
                                                      }}
                                                    >
                                                      <StyledInput
                                                        style={{
                                                          borderColor: 'gray',
                                                          borderWidth: 1,
                                                          alignSelf: 'center',
                                                        }}
                                                        value={
                                                          formikProps.values
                                                            .topNumberAdvance
                                                        }
                                                        formikProps={
                                                          formikProps
                                                        }
                                                        formikKey="topNumberAdvance"
                                                        placeholder="top"
                                                      />
                                                    </View>
                                                  </View>
                                                )}
                                                <Typography
                                                  color={'black'}
                                                  style={{
                                                    paddingLeft: 4,
                                                    paddingTop: 4,
                                                  }}
                                                >
                                                  Next{' '}
                                                  {
                                                    global.appSettings.labels
                                                      .mission.proper
                                                  }
                                                </Typography>

                                                <SelectMission
                                                  filter={
                                                    formikProps.values
                                                      .organizationID
                                                  }
                                                  value={
                                                    formikProps.values
                                                      .nextMissionID
                                                  }
                                                  paddingRight={20}
                                                  fieldSetter={(value) => {
                                                    if (value !== '') {
                                                      formikProps.setFieldValue(
                                                        'nextMissionID',
                                                        value
                                                      );
                                                    }
                                                  }}
                                                />
                                              </View>
                                            )}
                                            {global.appSettings.features.mission
                                              .autoGoal && (
                                              <View
                                                style={{
                                                  flexDirection: 'row',
                                                  paddingTop: 20,
                                                }}
                                              >
                                                <Typography
                                                  color={'black'}
                                                  style={{
                                                    marginRight: 10,
                                                    paddingLeft: 4,
                                                    paddingTop: 6,
                                                    textShadowColor: 'white',
                                                    textShadowRadius: 3,
                                                  }}
                                                >
                                                  Automatically Create Goals?{' '}
                                                </Typography>

                                                <Switch
                                                  style={{
                                                    transform: [
                                                      { scaleX: 0.8 },
                                                      { scaleY: 0.8 },
                                                    ],
                                                  }}
                                                  onChange={() => {
                                                    formikProps.setFieldValue(
                                                      'useAutoGoal',
                                                      !formikProps.values
                                                        .useAutoGoal
                                                    );
                                                  }}
                                                  value={
                                                    formikProps.values
                                                      .useAutoGoal
                                                  }
                                                />
                                              </View>
                                            )}
                                            {formikProps.values.useAutoGoal && (
                                              <View>
                                                <View
                                                  style={{
                                                    flexDirection: 'row',
                                                    marginLeft: 4,
                                                    marginTop: 0,
                                                  }}
                                                >
                                                  <Typography
                                                    color={'black'}
                                                    style={{ paddingTop: 14 }}
                                                  >
                                                    Set the goal to
                                                  </Typography>
                                                  <Box disply={'inline-flex'}>
                                                    <Box display={'block'}>
                                                      <StyledInput
                                                        style={{
                                                          borderColor: 'gray',
                                                          borderBottomWidth: 1,
                                                          alignSelf: 'center',
                                                        }}
                                                        value={
                                                          formikProps.values
                                                            .autoGoalFactor
                                                        }
                                                        formikProps={
                                                          formikProps
                                                        }
                                                        formikKey="autoGoalFactor"
                                                        placeholder="Factor"
                                                        width={25}
                                                      />
                                                    </Box>
                                                    <Box display={'block'}>
                                                      <Typography
                                                        color={'black'}
                                                      >
                                                        %
                                                      </Typography>
                                                    </Box>
                                                  </Box>
                                                </View>
                                                <Typography
                                                  color={'black'}
                                                  style={{
                                                    flex: 1,
                                                    paddingLeft: 4,
                                                  }}
                                                >
                                                  of the average of the past:
                                                </Typography>

                                                <Box display={'inline-flex'}>
                                                  <Box disply={'block'}>
                                                    <StyledInput
                                                      style={{
                                                        borderColor: 'gray',
                                                        borderBottomWidth: 1,
                                                        alignSelf: 'center',
                                                      }}
                                                      value={
                                                        formikProps.values
                                                          .autoGoalTimeframeCount
                                                      }
                                                      formikProps={formikProps}
                                                      formikKey="autoGoalTimeframeCount"
                                                      placeholder="Count"
                                                      width={25}
                                                    />
                                                  </Box>
                                                  <Box
                                                    disply={'block'}
                                                    style={{
                                                      marginLeft: 5,
                                                      marginTop: 5,
                                                    }}
                                                  >
                                                    <SelectFromList
                                                      paddingRight={200}
                                                      value={
                                                        formikProps.values
                                                          .autoGoalTimeframe
                                                      }
                                                      fieldSetter={(value) => {
                                                        formikProps.setFieldValue(
                                                          'autoGoalTimeframe',
                                                          value
                                                        );
                                                      }}
                                                      placeholder="What is the timeframe for the auto goal?"
                                                      items={[
                                                        {
                                                          label: 'Week(s)',
                                                          value: 'W',
                                                        },
                                                        {
                                                          label: 'Month(s)',
                                                          value: 'M',
                                                        },
                                                        {
                                                          label: 'Day(s)',
                                                          value: 'D',
                                                        },
                                                        {
                                                          label: 'Year(s)',
                                                          value: 'Y',
                                                        },
                                                      ]}
                                                    />
                                                  </Box>
                                                </Box>

                                                <View
                                                  style={{
                                                    flexDirection: 'row',
                                                  }}
                                                >
                                                  <Typography
                                                    color={'black'}
                                                    style={{
                                                      paddingLeft: 4,
                                                      marginTop: 10,
                                                    }}
                                                  >
                                                    The minimum goal will be:{' '}
                                                  </Typography>
                                                  <View
                                                    style={{ marginTop: 0 }}
                                                  >
                                                    <StyledInput
                                                      style={{
                                                        borderColor: 'gray',
                                                        borderBottomWidth: 1,
                                                        alignSelf: 'center',
                                                      }}
                                                      value={
                                                        formikProps.values
                                                          .minAutoGoal
                                                      }
                                                      formikProps={formikProps}
                                                      formikKey="minAutoGoal"
                                                      placeholder="Minimum"
                                                    />
                                                  </View>
                                                </View>
                                                <View
                                                  style={{
                                                    flexDirection: 'row',
                                                  }}
                                                >
                                                  <Typography
                                                    color={'black'}
                                                    style={{
                                                      paddingLeft: 4,
                                                      marginTop: 10,
                                                    }}
                                                  >
                                                    Round to:{' '}
                                                  </Typography>
                                                  <View
                                                    style={{ marginTop: 0 }}
                                                  >
                                                    <StyledInput
                                                      style={{
                                                        borderColor: 'gray',
                                                        borderBottomWidth: 1,
                                                        alignSelf: 'center',
                                                      }}
                                                      value={
                                                        formikProps.values
                                                          .roundGoalPrecision
                                                      }
                                                      formikProps={formikProps}
                                                      formikKey="roundGoalPrecision"
                                                      placeholder="Goal Round Precision"
                                                    />
                                                  </View>
                                                  <Typography
                                                    color={'black'}
                                                    style={{ paddingLeft: 4 }}
                                                  >
                                                    {' '}
                                                    Decimals
                                                  </Typography>
                                                </View>
                                              </View>
                                            )}
                                            {global.appSettings.features.mission
                                              .autoCreate && (
                                              <View
                                                style={{
                                                  flexDirection: 'row',
                                                  paddingTop: 20,
                                                }}
                                              >
                                                <Typography
                                                  color={'black'}
                                                  style={{
                                                    marginRight: 10,
                                                    paddingLeft: 4,
                                                    paddingTop: 6,
                                                    textShadowColor: 'white',
                                                    textShadowRadius: 3,
                                                  }}
                                                >
                                                  Create Recurring{' '}
                                                  {
                                                    global.appSettings.labels
                                                      .mission.proper
                                                  }
                                                  s?{' '}
                                                </Typography>

                                                <Switch
                                                  style={{
                                                    transform: [
                                                      { scaleX: 0.8 },
                                                      { scaleY: 0.8 },
                                                    ],
                                                  }}
                                                  onChange={() => {
                                                    formikProps.setFieldValue(
                                                      'useAutoCreate',
                                                      !formikProps.values
                                                        .useAutoCreate
                                                    );
                                                  }}
                                                  value={
                                                    formikProps.values
                                                      .useAutoCreate
                                                  }
                                                />
                                              </View>
                                            )}
                                            {formikProps.values
                                              .useAutoCreate && (
                                              <View>
                                                <View
                                                  style={{
                                                    flexDirection: 'row',
                                                    marginLeft: 4,
                                                    marginTop: -8,
                                                  }}
                                                >
                                                  <Typography
                                                    color={'black'}
                                                    style={{
                                                      color: 'black',
                                                      paddingLeft: 4,
                                                      paddingTop: 14,
                                                    }}
                                                  >
                                                    Repeat Every:
                                                  </Typography>
                                                  <StyledInput
                                                    style={{
                                                      borderColor: 'gray',
                                                      borderBottomWidth: 1,
                                                      alignSelf: 'center',
                                                    }}
                                                    value={
                                                      formikProps.values
                                                        .autoCreateTimeframeCount
                                                    }
                                                    formikProps={formikProps}
                                                    formikKey="autoCreateTimeframeCount"
                                                    placeholder="Count"
                                                    width={25}
                                                  />
                                                  <SelectFromList
                                                    paddingRight={200}
                                                    value={
                                                      formikProps.values
                                                        .autoCreateTimeframe
                                                    }
                                                    fieldSetter={(value) => {
                                                      formikProps.setFieldValue(
                                                        'autoCreateTimeframe',
                                                        value
                                                      );
                                                    }}
                                                    placeholder="Auto Goal Create Timeframe"
                                                    items={[
                                                      {
                                                        label: 'Week(s)',
                                                        value: 'W',
                                                      },
                                                      {
                                                        label: 'Month(s)',
                                                        value: 'M',
                                                      },
                                                      {
                                                        label: 'Day(s)',
                                                        value: 'D',
                                                      },
                                                      {
                                                        label: 'Year(s)',
                                                        value: 'Y',
                                                      },
                                                    ]}
                                                  />
                                                </View>

                                                <Box
                                                  className={
                                                    'date-picker-overlay'
                                                  }
                                                >
                                                  <LocalizationProvider
                                                    dateAdapter={AdapterMoment}
                                                  >
                                                    <ThemeProvider
                                                      theme={theme}
                                                    >
                                                      <DatePickerField
                                                        minDate={
                                                          formikProps.values
                                                            .autoCreateStartDate
                                                        }
                                                        label="Start Repeat"
                                                        name="autoCreateStartDate"
                                                        placeholder="Start Repeat"
                                                        formikProps={
                                                          formikProps
                                                        }
                                                        value={
                                                          formikProps.values
                                                            .autoCreateStartDate
                                                        }
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            className={
                                                              'date-picker-text'
                                                            }
                                                            {...params}
                                                          />
                                                        )}
                                                      />
                                                    </ThemeProvider>
                                                  </LocalizationProvider>
                                                </Box>

                                                <Box
                                                  className={
                                                    'date-picker-overlay'
                                                  }
                                                >
                                                  <LocalizationProvider
                                                    dateAdapter={AdapterMoment}
                                                  >
                                                    <ThemeProvider
                                                      theme={theme}
                                                    >
                                                      <DatePickerField
                                                        maxDate={
                                                          formikProps.values
                                                            .autoCreateEndDate
                                                        }
                                                        label="End Repeat"
                                                        name="autoCreateEndDate"
                                                        placeholder="End Repeat"
                                                        formikProps={
                                                          formikProps
                                                        }
                                                        value={
                                                          formikProps.values
                                                            .autoCreateEndDate
                                                        }
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            className={
                                                              'date-picker-text'
                                                            }
                                                            {...params}
                                                          />
                                                        )}
                                                      />
                                                    </ThemeProvider>
                                                  </LocalizationProvider>
                                                </Box>
                                              </View>
                                            )}

                                            <View>
                                              <Typography
                                                color={'black'}
                                                style={{
                                                  fontSize: 20,
                                                  textAlign: 'center',
                                                  textTransform: 'uppercase',
                                                  paddingTop: 20,
                                                  paddingBottom: 10,
                                                  paddingLeft: 4,
                                                }}
                                              >
                                                Click The Trophy To Replace
                                              </Typography>
                                              <View
                                                style={{
                                                  flex: 1,
                                                  alignSelf: 'stretch',
                                                  flexWrap: 'wrap',
                                                  flexDirection: 'row',
                                                  justifyContent:
                                                    'space-between',
                                                  padding: 5,
                                                  height: '100%',
                                                }}
                                              >
                                                <Box display={'inline-flex'}>
                                                  {formikProps.values.trophies.map(
                                                    (trophyData, t) => (
                                                      <Box
                                                        display={'block'}
                                                        key={t}
                                                      >
                                                        <View>
                                                          <View
                                                            onClick={() => {
                                                              Alert.alert(
                                                                'Replace Trophy',
                                                                'Are you sure you wish to replace the ' +
                                                                  trophyData.title +
                                                                  ' trophy?',
                                                                [
                                                                  {
                                                                    text: 'Yes',
                                                                    onClick:
                                                                      () => {
                                                                        setTrophyIndex(
                                                                          t
                                                                        );
                                                                        setSelectNewTrophy(
                                                                          !selectNewTrophy
                                                                        );
                                                                      },
                                                                  },
                                                                  {
                                                                    text: 'No',
                                                                    style:
                                                                      'cancel',
                                                                  },
                                                                ]
                                                              );
                                                            }}
                                                          >
                                                            <Trophy
                                                              id={trophyData.id}
                                                              imageName={
                                                                trophyData.imageName
                                                              }
                                                              title={
                                                                trophyData.title
                                                              }
                                                              description={
                                                                trophyData.description
                                                              }
                                                              createdAt=""
                                                            />
                                                          </View>
                                                        </View>
                                                      </Box>
                                                    )
                                                  )}
                                                </Box>
                                              </View>
                                              {selectNewTrophy && (
                                                <View>
                                                  <TrophyShop
                                                    addTrophy={(val) => {
                                                      console.log(
                                                        'AddTrophy: ',
                                                        val
                                                      );
                                                      const newTrophyList =
                                                        formikProps.values
                                                          .trophies;
                                                      // LOG.debug ("newTrophyList[trophyIndex]: ", newTrophyList[trophyIndex]);
                                                      newTrophyList[
                                                        trophyIndex
                                                      ] = {
                                                        id: newTrophyList[
                                                          trophyIndex
                                                        ].id,
                                                        place: trophyIndex + 1,
                                                        trophyID: val.trophyID,
                                                        imageName:
                                                          val.imageName,
                                                        title: val.title,
                                                        description:
                                                          val.description,
                                                      };
                                                      formikProps.setFieldValue(
                                                        'trophies',
                                                        newTrophyList
                                                      );
                                                    }}
                                                    hideTrophyShop={() =>
                                                      setSelectNewTrophy(false)
                                                    }
                                                  />
                                                </View>
                                              )}
                                            </View>
                                          </View>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          )}

                          <View
                            style={{
                              marginTop: 20,
                              marginBottom: 40,
                              paddingBottom: 20,
                            }}
                          >
                            {formikProps.isSubmitting ? (
                              <Box className={'save-button-overlay'}>
                                <Button className={'save-button'}>
                                  Publishing {formikProps.values.Type} ...
                                  please wait
                                </Button>
                              </Box>
                            ) : (
                              <View>
                                <Box className={'save-button-overlay'}>
                                  <Button
                                    type={'submit'}
                                    className={'save-button'}
                                  >
                                    Publish
                                  </Button>
                                </Box>
                              </View>
                            )}
                          </View>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )}
            </View>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
