import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const withUseSearchParams = (Component) => {
  const Wrapper = (props) => {
    const [searchParams] = useSearchParams();
    return <Component searchParams={searchParams} {...props} />;
  };

  return Wrapper;
};
