import { API } from 'aws-amplify';
import { LOG } from '../../../common/Utilities';
import CreatePostArgs from '../_types/CreatePostArgs';

export const CIRCLE_FEED_BLAST = /* GraphQL */ `
  mutation CircleFeedBlast(
    $circleID: String!
    $header: String!
    $details: String!
    $imageName: String!
    $imageType: String!
    $blastEvent: String!
    $userID: String!
    $chartType: String
    $chartData: String
    $alertMessage: String
    $alertDetails: String
    $createdBy: String!
    $ccUserID: String!
    $feedType: String
    $showAfterEpoch: Float
    $showUntilEpoch: Float
  ) {
    circleFeedBlast(
      circleID: $circleID
      header: $header
      details: $details
      imageName: $imageName
      imageType: $imageType
      blastEvent: $blastEvent
      userID: $userID
      chartType: $chartType
      chartData: $chartData
      alertMessage: $alertMessage
      alertDetails: $alertDetails
      createdBy: $createdBy
      ccUserID: $ccUserID
      feedType: $feedType
      showAfterEpoch: $showAfterEpoch
      showUntilEpoch: $showUntilEpoch
    )
  }
`;

const circleFeedBlast = async (
  args: CreatePostArgs & {
    circleID: string;
    header: string;
    details: string;
    imageName: string;
    imageType: string;
    blastEvent: any;
    mySub?: string;
    chartType?: string;
    chartData?: string;
    alertMessage: string;
    alertDetails: string;
    createdBy: string;
    ccUserID: string;
    feedType: string;
    showAfterEpoch: number;
    showUntilEpoch: number;
  }
) => {
  try {
    const circleFeedBlastVars = {
      ...args,
      blastEvent:
        args.blastEvent === undefined
          ? JSON.stringify({})
          : JSON.stringify(args.blastEvent),
      userID: args.mySub === undefined ? '' : args.mySub,
      chartType: args.chartType === undefined ? '' : args.chartType,
      chartData: args.chartData === undefined ? '' : args.chartData,
    };

    const results = await API.graphql({
      query: CIRCLE_FEED_BLAST,
      variables: circleFeedBlastVars,
    });

    console.log('results', results);

    return true;
  } catch (err) {
    LOG.error('circleFeedBlast Error: ' + err);
    return false;
  }
};

export default circleFeedBlast;
