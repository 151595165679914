import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Typography,
  TableContainer,
  Table as MUITable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  styled,
  FormControl,
} from '@mui/material';
import { Product } from './types';
import { useGetProducts } from './fetchProducts';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  SortingState,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table';
import { API, graphqlOperation } from 'aws-amplify';
import { deleteProduct } from '../../../../graphql/mutations';
import ConfirmationModal from '../../users/ConfirmationModal';
import { Search } from '@mui/icons-material';
import CreateProductsScreen from '../CreateProductsScreen/CreateProductsScreen.view';
import CenteredProgress from '../../../components/CenteredProgress';
import CenteredComponent from '../../../components/CenteredComponent';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GamifyIcon } from '../../../components/GamifyIcon';
import Fuse from 'fuse.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import BetaIcon from '../../../common/BetaIcon';

const ProductsTableCell = styled(TableCell)`
  color: #6d758f;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  padding: 12px 20px;
  background-color: white;

  &:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const ProductsTableRow = styled(TableRow)`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 8px;
    background-color: #f0f0f3;
    bottom: -8px;
  }

  &:last-child::after {
    display: none;
  }
`;

const StyledTableContainer = styled(TableContainer)`
  background-color: #f0f0f3;

  & .MuiTable-root {
    border-spacing: 0 8px;
    border-collapse: separate;
  }
`;

const HeaderCell = styled(TableCell)`
  color: #6b7280;
  font-weight: bold;
  padding: 12px 20px;
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;

  &:first-of-type {
    border-top-left-radius: 8px;
  }

  &:last-of-type {
    border-top-right-radius: 8px;
  }
`;

const SearchInput = styled('input')`
  padding: 8px 12px 8px 48px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  width: 240px;
  outline: none;
  height: 48px;

  &:focus {
    border-color: #ff6a00;
  }

  &::placeholder {
    color: #9ca3af;
  }
`;

const SearchWrapper = styled(Box)`
  position: relative;

  & svg {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
  }
`;

const ResultsText = styled(Typography)`
  color: #6b7280;
  font-size: 14px;
  margin-right: 16px;
`;

const NavigationButton = styled(Button)`
  text-transform: none;
  color: #6b7280;
  font-weight: 500;
  padding: 6px 12px;
  min-width: 0;

  &:disabled {
    color: #d1d5db;
  }

  &:hover:not(:disabled) {
    background-color: #f3f4f6;
  }
`;

export default function ProductsScreen() {
  const queryClient = useQueryClient();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] =
    useState<Partial<Product> | null>(null);
  const [searchValue, setSearchValue] = useState('');

  const [selectedProductFamily, setSelectedProductFamily] = useState('');

  // Replace the useEffect with useQuery
  const { data: products = [], isLoading, error } = useGetProducts();

  const productFamilies = Array.from(
    new Set(products.map((product) => product?.productFamily || ''))
  ).map((family) => ({
    label: family,
    value: family,
  }));

  // Filter products based on search value and product family
  const filteredProducts = useMemo(() => {
    let filtered = products;

    // First filter by product family if selected
    if (selectedProductFamily) {
      filtered = filtered.filter(
        (product) => product.productFamily === selectedProductFamily
      );
    }

    // Then apply fuzzy search if there's a search value
    if (searchValue) {
      const fuse = new Fuse(filtered, {
        keys: [
          'name',
          'productCode',
          'productFamily',
          'id',
          'units',
          'listPrice',
        ],
        threshold: 0.3,
        includeScore: true,
      });

      const searchResults = fuse.search(searchValue);
      filtered = searchResults.map((result) => result.item);
    }

    return filtered;
  }, [products, searchValue, selectedProductFamily]);

  // Add delete mutation
  const deleteMutation = useMutation({
    mutationFn: async (productId: string) => {
      return API.graphql(
        graphqlOperation(deleteProduct, { input: { id: productId } })
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['products'] });
      setIsDeleteModalOpen(false);
      setSelectedProduct(null);
    },
    onError: (error) => {
      console.error('Error deleting product:', error);
    },
  });

  const confirmDelete = async () => {
    if (selectedProduct) {
      deleteMutation.mutate(selectedProduct.id!);
    }
  };

  const columns = useMemo<ColumnDef<Product>[]>(
    () => [
      { header: 'Name', accessorKey: 'name' },
      { header: 'Product family', accessorKey: 'productFamily' },
      { header: 'Product Code', accessorKey: 'productCode' },
      { header: 'ID', accessorKey: 'id' },
      // { header: 'Created on', accessorKey: 'createdAt' },
      // { header: 'Last update', accessorKey: 'updatedAt' },
      { header: 'Units', accessorKey: 'units' },
      {
        header: 'Price',
        accessorKey: 'listPrice',
        cell: (info) => `$${Number(info.getValue()).toLocaleString()}`,
      },
      { header: 'Status', accessorKey: 'status' },
    ],
    []
  );

  const table = useReactTable({
    data: filteredProducts,
    // @ts-ignore
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 10 } },
  });

  // Remove onTriggerRefetch and replace with queryClient.invalidateQueries
  const refreshProducts = () => {
    queryClient.invalidateQueries({ queryKey: ['products'] });
  };

  const handleEdit = (product: Partial<Product>) => {
    setSelectedProduct(product);
    setIsCreateModalOpen(true);
  };

  const handleDelete = async (product: Partial<Product>) => {
    setSelectedProduct(product);
    setIsDeleteModalOpen(true);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const renderTopControls = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 3,
        mt: 5,
      }}
    >
      <Box style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            color: '#111827',
          }}
        >
          Products
        </Typography>
        <BetaIcon />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <SearchWrapper>
          <Search fontSize="small" />
          <SearchInput
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </SearchWrapper>

        <FormControl fullWidth margin="normal" style={{ marginTop: '-14px' }}>
          <Typography style={{ fontSize: 'small', fontWeight: 600 }}>
            Type
          </Typography>
          <Autocomplete
            value={selectedProductFamily}
            onChange={(event: any, newValue: string | null) => {
              setSelectedProductFamily(newValue || '');
            }}
            options={productFamilies.map((pf) => pf.value)}
            getOptionLabel={(option) =>
              productFamilies.find((pf) => pf.value === option)?.label || ''
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="All types"
                sx={{
                  backgroundColor: 'transparent',
                  '& .MuiOutlinedInput-root': {
                    height: '48px',
                    borderRadius: '8px',
                    border: '1px solid #E5E7EB',
                    '&:hover': {
                      borderColor: '#FF6A00',
                    },
                    '&.Mui-focused': {
                      borderColor: '#FF6A00',
                      boxShadow: 'none',
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '12px 14px',
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              />
            )}
            sx={{
              minWidth: 200,
              '& .MuiAutocomplete-endAdornment': {
                right: '8px',
                top: 'calc(50% - 14px)',
              },
              '& .MuiAutocomplete-input': {
                padding: '7.5px 4px 7.5px 6px !important',
              },
              '& .MuiInputBase-root': {
                padding: '0px 8px',
              },
            }}
          />
        </FormControl>
        <Button
          variant="contained"
          onClick={() => {
            setSelectedProduct(null);
            setIsCreateModalOpen(true);
          }}
          sx={{
            bgcolor: '#FF6A00',
            '&:hover': { bgcolor: '#FF8C00' },
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 600,
            padding: '8px 24px',
            borderRadius: '8px',
            minWidth: '180px',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: '20px',
              lineHeight: 1,
              marginRight: '4px',
            }}
          >
            +
          </Box>
          New product
        </Button>
      </Box>
    </Box>
  );

  const renderProductsTable = () => {
    if (isLoading) return <CenteredProgress />;
    if (error)
      return (
        <CenteredComponent>
          <Typography fontSize={24}>
            Something went wrong fetching the data. Please try again later.
          </Typography>
        </CenteredComponent>
      );
    if (!table.getRowModel().rows.length)
      return (
        <CenteredComponent>
          <Typography fontSize={24}>No available products.</Typography>
        </CenteredComponent>
      );

    return (
      <>
        <StyledTableContainer>
          <MUITable>
            <TableHead>
              <TableRow>
                {table.getHeaderGroups()[0].headers.map((header) => (
                  <HeaderCell
                    key={header.id}
                    onClick={() =>
                      header.column.getCanSort() &&
                      header.column.toggleSorting()
                    }
                    sx={{
                      cursor: header.column.getCanSort()
                        ? 'pointer'
                        : 'default',
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.getIsSorted() && (
                      <IconButton onClick={() => null}>
                        <GamifyIcon
                          icon="sort"
                          color="#6B7280"
                          height={18}
                          width={18}
                          backgroundColor={''}
                          /* size="small" */
                        />
                      </IconButton>
                    )}
                  </HeaderCell>
                ))}
                <HeaderCell>More options</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <ProductsTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <ProductsTableCell
                      key={cell.id}
                      sx={{
                        color:
                          cell.column.id === 'name'
                            ? '#323232'
                            : cell.column.id === 'listPrice'
                            ? '#FF6A00'
                            : '#6D758F',
                        fontWeight:
                          cell.column.id === 'name' ||
                          cell.column.id === 'listPrice'
                            ? '600'
                            : 'normal',
                      }}
                    >
                      <>
                        {cell.column.id === 'name' && (
                          <IconButton
                            onClick={() => null}
                            size="small"
                            style={{
                              marginRight: 16,
                              borderRadius: 20,
                              backgroundColor: '#F5F6F5',
                              padding: 8,
                            }}
                          >
                            <GamifyIcon
                              icon={'shoppingBag'}
                              height={24}
                              width={24}
                              color={'#29373B'}
                              backgroundColor={''}
                            />
                          </IconButton>
                        )}

                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </>
                    </ProductsTableCell>
                  ))}
                  <ProductsTableCell
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      gap: '8px',
                      padding: 2,
                    }}
                  >
                    <IconButton
                      onClick={() => handleDelete(row.original)}
                      size="small"
                    >
                      <GamifyIcon
                        icon={'trash'}
                        color="#D51C00"
                        backgroundColor={''}
                        width={24}
                        height={24}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => handleEdit(row.original)}
                      size="small"
                    >
                      <GamifyIcon
                        icon={'edit'}
                        color="#6B7280"
                        backgroundColor={''}
                        width={20}
                        height={20}
                      />
                    </IconButton>
                  </ProductsTableCell>
                </ProductsTableRow>
              ))}
            </TableBody>
          </MUITable>
        </StyledTableContainer>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mt: 2,
            gap: 1,
          }}
        >
          <ResultsText>
            Showing{' '}
            {table.getState().pagination.pageIndex *
              table.getState().pagination.pageSize +
              1}
            -
            {Math.min(
              (table.getState().pagination.pageIndex + 1) *
                table.getState().pagination.pageSize,
              table.getPrePaginationRowModel().rows.length
            )}{' '}
            of {table.getPrePaginationRowModel().rows.length} results
          </ResultsText>
          <NavigationButton
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </NavigationButton>
          <NavigationButton
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </NavigationButton>
        </Box>
      </>
    );
  };

  return (
    <Box sx={{ padding: '24px', backgroundColor: '#f0f0f3', height: '100vh' }}>
      <Typography variant="h4" sx={{ mb: 3, marginTop: 2 }}>
        Products
      </Typography>

      {renderTopControls()}

      {renderProductsTable()}
      <ConfirmationModal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Are you sure you want to delete this product?"
        message="This action cannot be undone."
        onConfirm={confirmDelete}
        confirmationButtonText="Delete Product"
        isLoading={false}
      />
      <CreateProductsScreen
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onProductCreated={refreshProducts}
        selectedProduct={selectedProduct}
        productFamilies={productFamilies}
      />
    </Box>
  );
}
