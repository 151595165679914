import { API } from 'aws-amplify';
import gql from 'graphql-tag';

const stageQuery = gql`
  query MyQuery($orgID: ID!, $nextToken: String) {
    listStageCategorys(
      filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
      limit: 10000
      nextToken: $nextToken
    ) {
      items {
        id
        title
        isDefault
        flexAttributes
        enablePindrop
        enableDeal
        dispositions(filter: { isDeleted: { eq: false } }) {
          items {
            id
            categoryID
            isSetupDeal
            order
            stageID
            formName
            iconName
            isConversation
            isNotSelectable
            isDefault
            deleteType
            requireDate
            noRecording
            title
            isDeleted
          }
          nextToken
        }
        stages(filter: { isDeleted: { eq: false } }) {
          items {
            id
            categoryID
            calendarAppointmentStatus
            changeToDispositionID
            description
            iconName
            isActive
            isDisplay
            isMapDefault
            isNoDealDefault
            requireCloser
            sendToCRM
            sequence
            userSelectable
            flexAttributes
            title
            isDeleted
          }
          nextToken
        }
        orgID
        isDeleted
      }
      nextToken
    }
  }
`;

export async function fetchStageCategories(orgID) {
  try {
    let nextToken = null;
    let categories = [];
    do {
      const categoriesRes = await API.graphql({
        query: stageQuery,
        variables: { orgID, nextToken },
      });
      const resItems = categoriesRes.data.listStageCategorys.items;
      // Fetch additional dispositions and stages if nextToken is present
      for (const category of resItems) {
        // Parse flexAttributes if it exists and is a valid JSON string
        if (category.flexAttributes) {
          try {
            category.flexAttributes = JSON.parse(category.flexAttributes);
          } catch (e) {
            console.log(
              `Error parsing flexAttributes for category ${category.id}:`,
              e
            );
          }
        }

        // Fetch additional dispositions
        if (category.dispositions.nextToken) {
          let allDispositions = category.dispositions.items;
          let dispToken = category.dispositions.nextToken;

          while (dispToken) {
            const dispResult = await API.graphql({
              query: gql`
                query GetDispositions($categoryID: ID!, $nextToken: String) {
                  listDispositions(
                    filter: {
                      categoryID: { eq: $categoryID }
                      isDeleted: { eq: false }
                    }
                    nextToken: $nextToken
                  ) {
                    items {
                      id
                      categoryID
                      isSetupDeal
                      order
                      stageID
                      formName
                      iconName
                      isConversation
                      isNotSelectable
                      isDefault
                      deleteType
                      requireDate
                      noRecording
                      title
                      isDeleted
                    }
                    nextToken
                  }
                }
              `,
              variables: { categoryID: category.id, nextToken: dispToken },
            });

            const { items: dispItems, nextToken: newDispToken } =
              dispResult.data.listDispositions;
            allDispositions = [...allDispositions, ...dispItems];
            dispToken = newDispToken;
          }

          category.dispositions = allDispositions.sort(
            (a, b) => a.order - b.order
          );
        } else {
          category.dispositions = category.dispositions.items
            .filter((d) => !d.isDeleted)
            .sort((a, b) => a.order - b.order);
        }

        // Fetch additional stages
        if (category.stages.nextToken) {
          let allStages = category.stages.items;
          let stageToken = category.stages.nextToken;

          while (stageToken) {
            const stageResult = await API.graphql({
              query: gql`
                query GetStages($categoryID: ID!, $nextToken: String) {
                  listStages(
                    filter: {
                      categoryID: { eq: $categoryID }
                      isDeleted: { eq: false }
                    }
                    nextToken: $nextToken
                  ) {
                    items {
                      id
                      categoryID
                      calendarAppointmentStatus
                      changeToDispositionID
                      description
                      iconName
                      isActive
                      isDisplay
                      isMapDefault
                      isNoDealDefault
                      requireCloser
                      sendToCRM
                      userSelectable
                      flexAttributes
                      title
                      isDeleted
                    }
                    nextToken
                  }
                }
              `,
              variables: { categoryID: category.id, nextToken: stageToken },
            });

            const { items: stageItems, nextToken: newStageToken } =
              stageResult.data.listStages;
            allStages = [...allStages, ...stageItems];
            stageToken = newStageToken;
          }

          category.stages = allStages;
        } else {
          category.stages = category.stages.items.filter((s) => !s.isDeleted);
        }
      }

      categories = categories.concat(resItems);
      nextToken = categoriesRes.data.listStageCategorys.nextToken;
    } while (nextToken);
    return categories;
  } catch (e) {
    console.log('ERROR FETCHING CATEGORIES', e);
    return [];
  }
}
