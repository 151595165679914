import React, { useEffect, useRef, useState } from 'react';
import NewGameState from '../../_types/NewGameState';
import {
  AddImageArea,
  AddImageAreaText,
  FilePreviewCloseIcon,
  FilePreviewContainer,
  ViewContainer,
} from './GameImage.styles';
import {
  InputBodyContainer,
  InputTitleContainer,
  InputTitleIcon,
  InputTitleText,
} from '../../NewGame.styles';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const GameImageView: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
}) => {
  const uploadRef = useRef<any>();
  const [filePreview, setFilePreview] = useState<any>(null);

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log('files', files);
    if (files && files.length > 0) {
      try {
        const selectedFile = files[0];
        const filePreview = URL.createObjectURL(selectedFile);

        onChangeNewGameState({
          ...newGameState,
          imageType: 'image',
          imageName: selectedFile.name,
          imageFile: selectedFile,
        });

        setFilePreview(filePreview);
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (newGameState.imageFile) {
      const filePreview = URL.createObjectURL(newGameState.imageFile);
      setFilePreview(filePreview);
    }
  }, []);

  return (
    <ViewContainer>
      <InputTitleContainer>
        <InputTitleText>Game Image</InputTitleText>
        <InputTitleIcon src="/images/thumbnail.svg" />
      </InputTitleContainer>
      <InputBodyContainer>
        <AddImageArea
          shouldAjustHeight={Boolean(filePreview)}
          shouldCenterItems={Boolean(filePreview)}
          onClick={() => uploadRef.current.click()}
        >
          {filePreview ? (
            <FilePreviewContainer>
              <FilePreviewCloseIcon
                onClick={(e) => {
                  e.stopPropagation();
                  uploadRef.current.value = '';
                  setFilePreview(null);
                }}
                src="/images/close.svg"
              />

              <img src={filePreview} alt="Preview" width="200" />
            </FilePreviewContainer>
          ) : null}
          <input
            onChange={(e) => onUpload(e)}
            style={{ display: 'none' }}
            accept="image/*"
            type="file"
            ref={(ref) => (uploadRef.current = ref)}
          />
          {!filePreview && (
            <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
              <img src="/images/game-select-image.svg" />
              <AddImageAreaText>Select Image</AddImageAreaText>
            </div>
          )}
        </AddImageArea>
      </InputBodyContainer>
    </ViewContainer>
  );
};

export default GameImageView;
