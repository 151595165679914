import React from 'react';
import { ViewContainer } from './Rewards.styles';
import {
  InputBodyContainer,
  InputTitleContainer,
  InputTitleIcon,
  InputTitleText,
} from '../../NewGame.styles';
import ItemSelectInput from '../../custom-inputs/ItemSelectInput';
import HitQuotaAndPlacedInputs from './rewards-inputs/HitQuotaAndPlacedInputs';
import CompanyActionInputs from './rewards-inputs/CompanyActionInputs';
import IfPlacedInputs from './rewards-inputs/IfPlacedInputs';
import NewGameState from '../../_types/NewGameState';
import RewardType from '../../_types/RewardType';
import featureFlag from '../../../../../common/featureFlag';

interface Props {
  rewards: RewardType[];
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const RewardsView: React.FC<Props> = ({
  rewards,
  newGameState,
  onChangeNewGameState,
}) => {
  return (
    <ViewContainer>
      <InputTitleContainer>
        <InputTitleText>Rewards</InputTitleText>
        <InputTitleIcon src="/images/trophy.svg" />
      </InputTitleContainer>
      <InputBodyContainer>
        <ItemSelectInput
          label="Reward Type"
          selectedItem={newGameState.rewardCurrency}
          onSelect={(newSelectedItem: { label: string; value: any }) => {
            onChangeNewGameState({
              ...newGameState,
              rewardCurrency: newSelectedItem.value,
            });
          }}
          items={[
            { label: 'Reward store item', value: 'reward' },
            { label: 'Coins', value: 'points' },
          ]}
          numColumns={2}
        />
        <ItemSelectInput
          label="Rules"
          selectedItem={newGameState.rewardWhen}
          onSelect={(newSelectedItem: { label: string; value: any }) => {
            onChangeNewGameState({
              ...newGameState,
              rewardWhen: newSelectedItem.value,
            });
          }}
          items={
            featureFlag('hit_quota_and_placed_rule') ||
            featureFlag('for_every_action_rule')
              ? [
                  {
                    label: 'If placed (ex 1st,2nd)',
                    value: 'mission',
                  },
                  { label: 'Hit Quota and Placed', value: 'mission_min' },
                  {
                    label: 'If KPI goal is met',
                    value: 'goal',
                  },
                  {
                    label: 'For every action (ex. $1/dial)',
                    value: 'action',
                  },
                ]
              : [
                  {
                    label: 'If placed (ex 1st,2nd)',
                    value: 'mission',
                  },
                  {
                    label: 'If KPI goal is met',
                    value: 'goal',
                  },
                ]
          }
          numColumns={2}
        />
        <IfPlacedInputs
          rewards={rewards}
          newGameState={newGameState}
          onChangeNewGameState={onChangeNewGameState}
        />
        <HitQuotaAndPlacedInputs
          rewards={rewards}
          newGameState={newGameState}
          onChangeNewGameState={onChangeNewGameState}
        />
        <CompanyActionInputs
          rewards={rewards}
          newGameState={newGameState}
          onChangeNewGameState={onChangeNewGameState}
        />
      </InputBodyContainer>
    </ViewContainer>
  );
};

export default RewardsView;
