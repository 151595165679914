import React, { useState } from 'react';
import { Grid, Typography, Box, Card } from '@mui/material';
import ScorecardRepository from '../../repository/ScorecardRepository';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import './Index.css';
import { SelectScorecardTimeframe } from '../../components/Select/SelectScorecardTimeframe';

// NOTE: This component has been replaced by by the stats subpage in the ProfileStatsPage

export default function Scorecard(props) {
  const navigate = useNavigate();
  let params = props;
  if (params === undefined) {
    params = useParams();
  }
  console.log('params: ', params);
  const [scorecard, setScorecard] = useState([]);
  const [lastUserId, setLastUserId] = useState('');
  const [lastTimeframe, setLastTimeframe] = useState('');
  const [searchParams] = useSearchParams();

  async function getData(userId, timeframe) {
    console.log('getData: ', userId, timeframe);
    ScorecardRepository.fetchScorecard(userId, timeframe).then((_scorecard) => {
      console.log('scorecard: ', _scorecard);
      if (!_scorecard || _scorecard.length !== 0) {
        setScorecard(_scorecard);
      }
    });
  }

  let timeframe = searchParams.get('timeframe');
  console.log('!!timeframe: ', timeframe);
  if (timeframe === '' || timeframe === null) {
    timeframe = 'current';
  }

  if (lastUserId !== params.userId || lastTimeframe !== timeframe) {
    getData(params.userId, timeframe);
    setLastUserId(params.userId);
    setLastTimeframe(timeframe);
  }

  return (
    <Grid className={'scorecard-box'} container>
      <Box className={'scorecard-dropdown-overlay'}>
        <SelectScorecardTimeframe
          className={'scorecard-dropdown'}
          value={lastTimeframe}
          fieldSetter={(id) => {
            navigate('/profile/' + params.userId + '?timeframe=' + id);
          }}
        />
      </Box>

      <Grid
        container
        columnSpacing={{ xs: 3, sm: 5, md: 7 }}
        rowSpacing={2}
        justifyContent="center"
      >
        {scorecard.map((kpi, i) => (
          <Grid item key={i}>
            <Card
              className={'scorecard-card-overlay'}
              onClick={() => {
                if (kpi.val > 0) {
                  navigate(
                    '/pipeline/?userId=' +
                      params.userId +
                      '&categoryId=' +
                      kpi.KPIRule.categoryID +
                      '&stageId=' +
                      kpi.KPIRule.triggerID
                  );
                } else {
                  alert(
                    "There are no leads with the '" +
                      kpi.KPIRule.title +
                      '" status.'
                  );
                }
              }}
            >
              <Box className={'scorecard-card'}>
                <Typography
                  className={'scorecard-kpi-value'}
                  variant={kpi.val.length > 5 ? 'h4' : 'h2'}
                >
                  {kpi.val}
                </Typography>
                <Typography className={'scorecard-kpi-title'}>
                  {kpi.KPIRule.title}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
// }
