import { API } from 'aws-amplify';

// const saveMission = `
//   mutation SaveMission($mission: AWSJSON!, $doFeedBlast: Boolean) {
//     saveMission(mission: $mission, doFeedBlast: $doFeedBlast)
//   }
// `;

// NOTE: The saveGame mutation currently does not have the newBrackets boolean
// const saveGameQuery = `
//   mutation SaveGame($mission: AWSJSON!, $doFeedBlast: Boolean, $newBrackets: Boolean) {
//     saveGame(mission: $mission, doFeedBlast: $doFeedBlast, newBrackets: $newBrackets)
//   }
// `;
const saveGameQuery = `
  mutation SaveGame($mission: AWSJSON!, $doFeedBlast: Boolean) {
    saveGame(mission: $mission, doFeedBlast: $doFeedBlast)
  }
`;

const saveGame = async (values: any) => {
  // const saveMissionRes = await API.graphql({
  //   query: saveMission,
  //   variables: {
  //     mission: JSON.stringify(values),
  //     doFeedBlast: true,
  //   },
  // });

  const saveGameRes = await API.graphql({
    query: saveGameQuery,
    variables: {
      mission: JSON.stringify(values),
      doFeedBlast: true,
      // newBrackets: true,
    },
  });

  console.log('saveGame saveGameRes: ', saveGameRes);

  // @ts-ignore
  return saveGameRes.data.maintainGame;
  // return saveMissionRes.data.saveMission;
};

export default saveGame;
