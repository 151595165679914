import React, { useState, useEffect } from 'react';
import CustomToggleSwitch from '../../../custom-inputs/CustomToggleSwitch';
import CustomSelect from '../../../custom-inputs/CustomSelect';
import CustomTextInput from '../../../custom-inputs/CustomTextInput';
import ItemSelectInput from '../../../custom-inputs/ItemSelectInput';
import RoundState from '../../../_types/RoundState';
import { RulesAndRewardsViewContainer, SectionTitle } from './Round.styles';
import NewGameState from '../../../_types/NewGameState';
import { Box, Typography } from '@mui/material';

interface Props {
  onChangeRoundState: (roundState: RoundState) => void;
  roundState: RoundState;
  kpis: { label: string; value: string; rule: any }[];
  newGameState: NewGameState;
  onChangeNewGameState: (roundState: NewGameState) => void;
  tabNo: number;
}

interface KPI {
  label: string;
  value: string;
  rule: any;
}

function NumberInput(props: any) {
  const [text, setText] = useState(props.value);
  return (
    <CustomTextInput
      label={props.label}
      onChange={(newValue) => {
        // Regex to check if newValue is a number or not (includes decimals, using parseFloat prevents reliable usage on isNaN for checking this)
        const isNumber = /^-?\d*\.?\d*$/.test(newValue);
        if (newValue === '' || isNumber) {
          setText(newValue);
          props.onChangeText(newValue);
        }
      }}
      value={text}
    />
  );
}

const renderMultipleKpiInputs = (
  newGameState: NewGameState,
  onChangeNewGameState: (newGameState: NewGameState) => void,
  roundState: RoundState,
  onChangeRoundState: (roundState: RoundState) => void,
  kpis: { label: string; value: string; rule: any }[],
  normalizedKPIs: any,
  tabNo: number
): React.JSX.Element => {
  const missionRules = roundState.rules || [];

  const renderKPIList = missionRules.map((_missionRule, ruleNum) => (
    <Box key={ruleNum}>
      <CustomSelect
        label="KPI"
        value={_missionRule.id}
        onChange={(newValue: any) => {
          if (roundState.useMultipleKPIs) {
            const _missionRule = {
              id: newValue,
              multiplier: 1.0,
              name: normalizedKPIs[newValue].label,
              role: 'contestant',
            };
            const _missionRules = missionRules || [];
            _missionRules.push(_missionRule);
            onChangeRoundState({
              ...roundState,
              missionRules: _missionRules,
              action: normalizedKPIs[newValue].rule.action,
              actionTypePlural: normalizedKPIs[newValue].rule.valueUnitPlural,
              actionPastTense: normalizedKPIs[newValue].rule.actionPastTense,
              actionType: normalizedKPIs[newValue].rule.valueUnit,
              KPIRuleID: newValue,
              KPIRuleName: normalizedKPIs[newValue].label,
            });

            // setFieldValue("missionRules", _missionRules);
            // setFieldValue("action", rule.action);
            // setFieldValue("actionTypePlural", rule.valueUnitPlural);
            // setFieldValue("actionPastTense", rule.actionPastTense);
            // setFieldValue("actionType", rule.valueUnit);
            // setFieldValue("KPIRuleID", value);
            // setFieldValue("KPIRuleName", name);
            // // setUpdate(update + 1);
          } else {
            // setFieldValue("KPIRuleID", value);
            // setFieldValue("KPIRuleName", name);
            onChangeRoundState({
              ...roundState,
              KPIRuleID: newValue,
              KPIRuleName: normalizedKPIs[newValue].label,
            });
          }
        }}
        items={kpis}
      />
      <Box
        onClick={() => removeKpiRule(ruleNum)}
        style={{ alignSelf: 'flex-end', marginTop: -20, marginBottom: 10 }}
      >
        <Typography
          style={{ color: 'red', fontSize: 12 }}
          sx={{ cursor: 'pointer' }}
        >
          Remove
        </Typography>
      </Box>

      <Box
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: 15,
          paddingRight: 10,
          justifyContent: 'space-between',
          marginTop: 10,
        }}
      >
        {/* <Typography style={{ fontSize: 12 }}>
          Points Per {_missionRule.name}{" "}
        </Typography>

        <NumberInput
          onChangeText={(text) => {
            let _missionRules = missionRules || [];
            _missionRules[ruleNum].multiplier = parseFloat(text);
            setFieldValue("missionRules", _missionRules);
          }}
          value={_missionRule.multiplier?.toString()}
          placeholder="Multiplier (default 1.0)"
        /> */}
        <NumberInput
          label={`Points Per ${_missionRule.name}${' '}`}
          value={_missionRule.multiplier?.toString()}
          onChangeText={(newValue: string) => {
            // console.log('newValue: ', newValue);

            const _missionRules = missionRules || [];
            _missionRules[ruleNum].multiplier = parseFloat(newValue);
            onChangeRoundState({
              ...roundState,
              missionRules: _missionRules,
            });
          }}
        />
      </Box>
      <Box
        style={{
          flex: 1,
          flexDirection: 'row',
          borderBottomWidth: 0.5,
          borderBottomColor: '#D9D9D9',
          alignItems: 'center',
          paddingLeft: 15,
          paddingBottom: 16,
          paddingRight: 10,
          justifyContent: 'space-between',
          marginTop: 10,
        }}
      >
        {/* <Typography style={{ fontSize: 12 }}>
          User's Role
        </Typography> */}
        <CustomSelect
          label="User's Role"
          value={_missionRule.role}
          onChange={(newValue) => {
            const _missionRules = missionRules || [];
            _missionRules[ruleNum].role = newValue;
            onChangeRoundState({
              ...roundState,
              missionRules: _missionRules,
            });
          }}
          items={[
            {
              label: 'Anyone',
              value: 'contestant',
            },
            {
              label: 'Setter',
              value: 'setter',
            },
            {
              label: 'Closer',
              value: 'closer',
            },
          ]}
        />
      </Box>
    </Box>
  ));

  const removeKpiRule = (index: number) => {
    const _missionRules = [...missionRules];
    _missionRules.splice(index, 1);
    onChangeRoundState({
      ...roundState,
      missionRules: _missionRules,
    });
    // setFieldValue("missionRules", _missionRules);
  };

  return (
    <>
      {renderKPIList}

      <CustomSelect
        label="KPI"
        value={roundState.useMultipleKPIs ? '' : roundState.KPIRuleID}
        onChange={(newValue: any) => {
          if (roundState.useMultipleKPIs) {
            const _missionRule = {
              id: newValue,
              multiplier: 1.0,
              name: normalizedKPIs[newValue].label,
              role: 'contestant',
            };
            const _missionRules = missionRules || [];
            _missionRules.push(_missionRule);
            onChangeRoundState({
              ...roundState,
              missionRules: _missionRules,
              action: normalizedKPIs[newValue].rule.action,
              actionTypePlural: normalizedKPIs[newValue].rule.valueUnitPlural,
              actionPastTense: normalizedKPIs[newValue].rule.actionPastTense,
              actionType: normalizedKPIs[newValue].rule.valueUnit,
              KPIRuleID: newValue,
              KPIRuleName: normalizedKPIs[newValue].label,
            });

            // setFieldValue("missionRules", _missionRules);
            // setFieldValue("action", rule.action);
            // setFieldValue("actionTypePlural", rule.valueUnitPlural);
            // setFieldValue("actionPastTense", rule.actionPastTense);
            // setFieldValue("actionType", rule.valueUnit);
            // setFieldValue("KPIRuleID", value);
            // setFieldValue("KPIRuleName", name);
            // // setUpdate(update + 1);

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].missionRules =
                _missionRules;
              newGameStateCopy.bracket.roundValues[tabNo - 1].action =
                normalizedKPIs[newValue].rule.action;
              newGameStateCopy.bracket.roundValues[tabNo - 1].actionTypePlural =
                normalizedKPIs[newValue].rule.valueUnitPlural;
              newGameStateCopy.bracket.roundValues[tabNo - 1].actionPastTense =
                normalizedKPIs[newValue].rule.actionPastTense;
              newGameStateCopy.bracket.roundValues[tabNo - 1].actionType =
                normalizedKPIs[newValue].rule.valueUnit;
              newGameStateCopy.bracket.roundValues[tabNo - 1].KPIRuleID =
                newValue;
              newGameStateCopy.bracket.roundValues[tabNo - 1].KPIRuleName =
                normalizedKPIs[newValue].label;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                missionRules: _missionRules,
                action: normalizedKPIs[newValue].rule.action,
                actionTypePlural: normalizedKPIs[newValue].rule.valueUnitPlural,
                actionPastTense: normalizedKPIs[newValue].rule.actionPastTense,
                actionType: normalizedKPIs[newValue].rule.valueUnit,
                KPIRuleID: newValue,
                KPIRuleName: normalizedKPIs[newValue].label,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          } else {
            // setFieldValue("KPIRuleID", value);
            // setFieldValue("KPIRuleName", name);
            onChangeRoundState({
              ...roundState,
              KPIRuleID: newValue,
              KPIRuleName: normalizedKPIs[newValue].label,
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].KPIRuleID =
                newValue;
              newGameStateCopy.bracket.roundValues[tabNo - 1].KPIRuleName =
                normalizedKPIs[newValue].label;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                KPIRuleID: newValue,
                KPIRuleName: normalizedKPIs[newValue].label,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          }
        }}
        items={kpis}
      />
    </>
  );
};

const RoundRules: React.FC<Props> = ({
  onChangeRoundState,
  roundState,
  kpis,
  newGameState,
  onChangeNewGameState,
  tabNo,
}) => {
  // Needed for accessing the rule on a KPI when selecting a rule.
  const normalizeKPIs = (kpis: KPI[]): Record<string, KPI> => {
    return kpis.reduce((acc, kpi) => {
      acc[kpi.value] = kpi;
      return acc;
    }, {} as Record<string, KPI>);
  };

  const normalizedKPIs = normalizeKPIs(kpis);

  // Need to update newGameState rules every time tabNo 1's rules change to match mobile logic:
  useEffect(() => {
    if (tabNo === 1) {
      onChangeNewGameState({
        ...newGameState,
        missionRules: roundState.rules,
      });
    }
  }, [roundState.rules]);

  return (
    <RulesAndRewardsViewContainer>
      <SectionTitle>Rules</SectionTitle>
      <CustomToggleSwitch
        label="Use Multiple KPIs"
        value={roundState.useMultipleKPIs}
        onChange={(newValue: any) => {
          onChangeRoundState({
            ...roundState,
            useMultipleKPIs: newValue,
          });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].useMultipleKPIs =
              newValue;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              useMultipleKPIs: newValue,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
      />
      {roundState.useMultipleKPIs ? (
        renderMultipleKpiInputs(
          newGameState,
          onChangeNewGameState,
          roundState,
          onChangeRoundState,
          kpis,
          normalizedKPIs,
          tabNo
        )
      ) : (
        <CustomSelect
          label="KPI*"
          value={roundState.KPIRuleID}
          onChange={(newValue: any) => {
            onChangeRoundState({
              ...roundState,
              KPIRuleID: newValue,
              KPIRuleName: normalizedKPIs[newValue].label,
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].KPIRuleID =
                newValue;
              newGameStateCopy.bracket.roundValues[tabNo - 1].KPIRuleName =
                normalizedKPIs[newValue].label;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                KPIRuleID: newValue,
                KPIRuleName: normalizedKPIs[newValue].label,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          }}
          items={kpis}
        />
      )}
      <CustomSelect
        label="Tie Breaker KPI"
        value={roundState.tieKPIRuleID}
        onChange={(newValue: any) => {
          onChangeRoundState({ ...roundState, tieKPIRuleID: newValue });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].tieKPIRuleID =
              newValue;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              tieKPIRuleID: newValue,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
        items={kpis}
      />
      <ItemSelectInput
        label="Goal"
        selectedItem={
          roundState.isActionCountCustom ? 'custom' : roundState.actionCount
        }
        onSelect={(newSelectedItem: { label: string; value: any }) => {
          if (newSelectedItem.value === 'custom') {
            onChangeRoundState({
              ...roundState,
              isActionCountCustom: true,
              actionCount: '',
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[
                tabNo - 1
              ].isActionCountCustom = true;
              newGameStateCopy.bracket.roundValues[tabNo - 1].actionCount = '';
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                isActionCountCustom: true,
                actionCount: '',
              });
            }
            onChangeNewGameState(newGameStateCopy);
          } else {
            onChangeRoundState({
              ...roundState,
              isActionCountCustom: false,
              actionCount: newSelectedItem.value,
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[
                tabNo - 1
              ].isActionCountCustom = false;
              newGameStateCopy.bracket.roundValues[tabNo - 1].actionCount =
                newSelectedItem.value;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                isActionCountCustom: false,
                actionCount: newSelectedItem.value,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          }
        }}
        items={[
          { label: '10', value: '10' },
          { label: '25', value: '25' },
          { label: '50', value: '50' },
          { label: 'Custom', value: 'custom' },
        ]}
        numColumns={4}
      />
      {roundState.isActionCountCustom && (
        <CustomTextInput
          label="Custom Goal"
          placeholder="E.g. 15"
          value={roundState.actionCount}
          onChange={(newValue) => {
            onChangeRoundState({ ...roundState, actionCount: newValue });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].actionCount =
                newValue;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                actionCount: newValue,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          }}
        />
      )}
    </RulesAndRewardsViewContainer>
  );
};

export default RoundRules;
