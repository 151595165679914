import React from 'react';
import {
  RulesAndRewardsContainer,
  RulesAndRewardsTitle,
  RulesAndRewardsAboutItemContainer,
  RulesAndRewardsAboutItemText,
} from '../NormalDetails.styles';
import { CalendarToday } from '@mui/icons-material';
import formatGameStartAndEndDate from '../../../games-helpers/formatGameStartAndEndDate';
import Game from '../../../_types/Game';

interface Props {
  game: Game;
}

const RulesAndRewards = ({ game }: Props) => {
  return (
    <RulesAndRewardsContainer>
      <RulesAndRewardsTitle>About</RulesAndRewardsTitle>
      <RulesAndRewardsAboutItemContainer>
        <div
          style={{
            padding: 6,
            borderRadius: 10,
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CalendarToday sx={{ fontSize: 18 }} />
        </div>
        <RulesAndRewardsAboutItemText>
          {formatGameStartAndEndDate({
            startDate: new Date(game.startDate),
            endDate: new Date(game.endDate),
          })}
        </RulesAndRewardsAboutItemText>
      </RulesAndRewardsAboutItemContainer>
      {game.description ? (
        <RulesAndRewardsAboutItemContainer>
          <img src="/images/rules.svg" width={30} height={30} />
          <RulesAndRewardsAboutItemText>
            {game.description}
          </RulesAndRewardsAboutItemText>
        </RulesAndRewardsAboutItemContainer>
      ) : null}
      {/* <RulesAndRewardsSectionTitle>Prize</RulesAndRewardsSectionTitle> */}
    </RulesAndRewardsContainer>
  );
};

export default RulesAndRewards;
