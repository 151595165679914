import { API } from 'aws-amplify';
import UpdatePostArgs from '../_types/UpdatePostArgs';

export const UPDATE_FEED_BLAST_MUTATION = /* GraphQL */ `
  mutation UpdateFeedBlast(
    $id: ID!
    $header: String!
    $details: String!
    $imageName: String!
    $imageType: String!
    $showAfterEpoch: Float
    $showUntilEpoch: Float
  ) {
    updateFeedBlast(
      id: $id
      header: $header
      details: $details
      imageName: $imageName
      imageType: $imageType
      showAfterEpoch: $showAfterEpoch
      showUntilEpoch: $showUntilEpoch
    )
  }
`;

const updatePost = async (args: UpdatePostArgs) => {
  await API.graphql({
    query: UPDATE_FEED_BLAST_MUTATION,
    variables: args,
  });
};

export default updatePost;
