import Emoticon from '../_types/Emoticon';

const removeDuplicateEmoticons = (emoticons: Emoticon[]): Emoticon[] => {
  const seen = new Set<string>();
  return emoticons.filter((emoticon) => {
    const uniqueKey = `${emoticon.type}-${emoticon.user.id}`;
    if (!seen.has(uniqueKey)) {
      seen.add(uniqueKey);
      return true;
    }
    return false;
  });
};

export default removeDuplicateEmoticons;
