import { API, graphqlOperation } from 'aws-amplify';

const CREATE_ACTIVITY_FEED_EMOTICON = /* GraphQL */ `
  mutation CreateActivityFeedEmoticon(
    $input: CreateActivityFeedEmoticonInput!
    $condition: ModelActivityFeedEmoticonConditionInput
  ) {
    createActivityFeedEmoticon(input: $input, condition: $condition) {
      id
      activityFeedID
      type
      userID
      orgID
      createdAt
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        functionalRoles
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        PersonDefaultSchedules {
          nextToken
        }
        PersonScheduleOverrides {
          nextToken
        }
        PersonAppointments {
          nextToken
        }
        SetterAppointments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      updatedAt
    }
  }
`;

const BOOST_ACTIVITY_FEED_ITEM_MUTATION = /* GraphQL */ `
  mutation BoostActivityFeedItem($request: AWSJSON!) {
    boostActivityFeedItem(request: $request)
  }
`;

const applyFeedEmoticon = async (args: {
  feedID: string;
  orgID: string;
  userID: string;
  type: string;
  isDeleted: boolean;
  userName: string;
}) => {
  const { feedID, isDeleted, orgID, type, userID, userName } = args;

  const emoticon = {
    activityFeedID: feedID,
    orgID,
    userID,
    type,
    isDeleted,
  };

  const newActivityFeedEmoticon = await API.graphql(
    graphqlOperation(CREATE_ACTIVITY_FEED_EMOTICON, { input: emoticon })
  );

  await API.graphql({
    query: BOOST_ACTIVITY_FEED_ITEM_MUTATION,
    variables: {
      request: JSON.stringify({
        type: 'activityFeedEmoticon',
        emoticonType: type,
        changedID:
          // @ts-ignore
          newActivityFeedEmoticon.data.createActivityFeedEmoticon.id,
        activityFeedID: feedID,
        userID,
        userName,
      }),
    },
  });
};

export default applyFeedEmoticon;
