import React from 'react';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { GamifyIcon, EllipsisIcon } from '../../../components/GamifyIcon';

export default function ProfileTeamCardsSkeleton() {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2ch',
        }}
      >
        {global.me.isAdmin && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <GamifyIcon
              icon={'add'}
              color={'#FF6A00'}
              backgroundColor={'white'}
            ></GamifyIcon>
            <Typography
              style={{ color: '#FF6A00', fontSize: '16px', fontWeight: 600 }}
            >
              Add to a new team
            </Typography>
          </Box>
        )}
      </Box>
      {['', '', '', '', '', ''].map((team, i) => {
        return <TeamCardSkeleton key={i} team={team}></TeamCardSkeleton>;
      })}
    </>
  );
}

function TeamCardSkeleton() {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: 15,
        border: '1px solid #D0D2D8',
        borderRadius: 8,
        marginBottom: 12,
      }}
    >
      <Box className={'teams-list-item-left-container'}>
        <Skeleton
          variant={'circular'}
          width={40}
          height={40}
          sx={{ bgcolor: '#868686', margin: '5px 12px 5px 5px' }}
        ></Skeleton>
        <Box className={'teams-list-item-left-text-container'}>
          <Skeleton
            variant={'rounded'}
            sx={{ bgcolor: '#222428', marginTop: '2px', marginBottom: '2px' }}
            width={115}
            height={20}
          ></Skeleton>
          <Skeleton
            variant={'rounded'}
            sx={{ bgcolor: '#868686', marginTop: '1px', marginBottom: '1px' }}
            width={55}
            height={19}
          ></Skeleton>
        </Box>
      </Box>
      <Box
        style={{
          position: 'relative',
          height: 45,
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Box
          style={{
            backgroundColor: '#C2FCEE',
            padding: '4px 8px 4px 8px',
            borderRadius: '1000px',
          }}
        >
          <Skeleton
            style={{ color: '#017E6A', marginTop: '1px', marginBottom: '1px' }}
            width={56}
            height={16}
          ></Skeleton>
        </Box>
        <Button className={'teams-option-button'} disableRipple={true}>
          <EllipsisIcon color={'#868686'}></EllipsisIcon>
        </Button>
      </Box>
    </Box>
  );
}
