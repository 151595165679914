import React, { useEffect, useState } from 'react';
import getComment from '../feed-helpers/getComment';
import CommentView from './Comment.view';
import CommentType from '../_types/CommentType';
import { Skeleton } from '@mui/material';
import Me from '../../../_types/Me';
import MyPerson from '../_types/MyPerson';
import EditComment from '../EditComment';

interface Props {
  commentId: string;
  onDeleteComment?: (deletedComment: CommentType) => void;
  me: Me;
  myPersons: MyPerson[];
  onChangeIsEditing: (newIsEditing: boolean) => void;
}

const CommentContainer = ({
  myPersons,
  commentId,
  onDeleteComment,
  me,
  onChangeIsEditing,
}: Props) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState<CommentType | null>(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const fetchComment = async () => {
    try {
      const comment = await getComment({ commentId });

      setComment(comment);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComment();
  }, [commentId]);

  useEffect(() => {
    const refetch = async () => {
      if (shouldRefetch) {
        await fetchComment();
        setShouldRefetch(false);
      }
    };

    refetch();
  }, [shouldRefetch]);

  if (loading || !comment || shouldRefetch) {
    return (
      <Skeleton
        variant="rectangular"
        width={760}
        height={100}
        style={{ marginBottom: 4 }}
      />
    );
  }

  if (isUpdating) {
    return (
      <EditComment
        isUpdating={isUpdating}
        onChangeIsUpdating={(newIsUpdating: boolean) => {
          setIsUpdating(newIsUpdating);
          onChangeIsEditing(true);
        }}
        myPersons={myPersons}
        me={me}
        comment={comment}
        refetchComment={() => setShouldRefetch(true)}
      />
    );
  }

  return (
    <CommentView
      myPersons={myPersons}
      comment={comment}
      onDeleteComment={onDeleteComment}
      me={me}
      onChangeIsUpdating={(newIsUpdating: boolean) => {
        setIsUpdating(newIsUpdating);
        onChangeIsEditing(true);
      }}
    />
  );
};

export default CommentContainer;
