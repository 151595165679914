import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import {
  Avatar,
  Backdrop,
  Box,
  CardMedia,
  Checkbox,
  Typography,
  InputAdornment,
  Button,
  CircularProgress,
  //   CircularProgress,
} from '@mui/material';
import { SearchInput } from './StyledComponent';
import { Search } from '@mui/icons-material';
import Fuse from 'fuse.js';
import { GamifyIcon } from '../../components/GamifyIcon';
import _ from 'lodash';
import './AddMemberLinkTeamModal.css';
import getUserAvatar from '../../common/utils/getUserAvatar';

export default function AddMemberLinkTeamModal(props) {
  const {
    title,
    team,
    teamLevelNumIdMap,
    teamLevelNumNameMap,
    noAutoSelect,
    pickCircles,
    isLinkTeams,
    pickUsers,
    filter,
    itemValue,
    onSubmit,
    hideInitialChecked,
    initialActiveMembers,
    setShowModal,
    showModal,
  } = props;

  // TO DO: Improvement idea --> Add Member/Link Team modal on web is split down middle, left has members that can be added, right has members that have been selected and are waiting to be added
  // OR: in addition to All (#), also have Selected (#)

  const [itemList, setItemList] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [selectedName, setSelectedName] = useState(''); // TO DO: figure out if needed
  let fetchPromise = null;
  const [selectedUser, setSelectedUser] = useState(null); // TO DO: figure out if needed
  const [searchData, setSearchData] = useState(itemList);
  const [modalSearchString, setModalSearchString] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teamLevelBelowName, setTeamLevelBelowName] = useState('');

  if (itemList.length === 1000) {
    // TO DO: use all of these props or remove the ones that stay unused
    console.log(
      'unused props: ',
      noAutoSelect,
      filter,
      itemValue,
      hideInitialChecked
    );

    // TO DO: props that were in lyferize but don't seem to get passed into the modal ever:
    // useCirclesList, useChildCirclesList, userID, allowCircle, checkCreatePermissions, skipCircleUserBelongsTo, fieldSetter

    // TO DO: use all of these state variables or remove the ones that stay unused
    console.log('unused state variables: ', selectedName, selectedUser);
  }

  async function fetchCircles(ourSearchString) {
    if (fetchPromise !== null) {
      //
      try {
        API.cancel(fetchPromise, 'New Request');
      } catch (err) {}
      fetchPromise = null;
    }

    let filteredAssigneeList = [];
    // Link Team:
    if (pickCircles) {
      // Use maps from fetchTeamLevels props to add team level id(s) for the level below current teams (if that level exists) to the filter below
      //   console.log('AMLT Link Team team: ', team);
      //   console.log('AMLT Link Team teamLevelNumIdMap: ', teamLevelNumIdMap);
      //   console.log('AMLT Link Team teamLevelNumNameMap: ', teamLevelNumNameMap);

      let teamLevelBelowId;

      // Only need to restrict teams to level below if linking teams, not if adding a single user to multiple teams
      if (isLinkTeams) {
        const teamLevelNum = team.level.levelNum;

        // Check if the (current team level num + 1) has a key in the map because there's no teams below the level of the lowest level teams
        if (teamLevelNumIdMap[teamLevelNum + 1]) {
          teamLevelBelowId = teamLevelNumIdMap[teamLevelNum + 1];
        } else {
          teamLevelBelowId = null;
        }

        // No need to query if teamLevelBelowId is null because that means it is the lowest level team
        if (!teamLevelBelowId) return;

        setTeamLevelBelowName(teamLevelNumNameMap[teamLevelNum + 1]);
      }

      let filter = '{';
      filter += 'name: {wildcard: $searchString},';
      filter += 'orgID: {eq: $orgID},';
      filter += ' isDeleted: {eq: false}';
      //   No longer have circleLevelID to hide, replaced w/ level id 1 level below current team level to maintain hierarchy
      //   if (props.hideCircleLevels !== undefined) {
      //     for (let circleLevelID of props.hideCircleLevels) {
      //       filter += ', levelID: {ne: "' + circleLevelID + '"}';
      //     }
      //   }

      if (isLinkTeams) {
        filter += ', levelID: {eq: "' + teamLevelBelowId + '"}';
      }

      //   NOTE: not sure if any of this is needed, useCirclesList and useChildCirclesList are never props on AddMemberModal in lyferize...
      if (
        !global.me.isAdmin &&
        (props.useCirclesList || props.useChildCirclesList)
      ) {
        //
        //  If the user is looking for all deals and they're NOT an admin, filter so that they see only the deals
        //  for the teams that they're in
        //
        filter += ', or: [';
        let first = true;

        if (props.useCirclesList) {
          for (const circleID of global.me.myCircles) {
            if (!first) {
              filter += ',';
            }
            filter += '{id: {eq: "' + circleID + '"}}';
            first = false;
          }
        }

        if (props.useChildCirclesList) {
          //
          //  Include all the circles under the user
          //
          for (const circleID of global.me.myChildCircles) {
            if (circleID !== global.me.leagueID) {
              //
              //  Allow the user to see all leads for any of their teams EXCEPT for their
              //  league.  This allows us to group people into leagues without allowing everyone
              //  in the same league to see everyone else's leads
              //
              if (!first) {
                filter += ',';
              }
              filter += '{id: {eq: "' + circleID + '"}}';
              first = false;
            }
          }
        }
        filter += ']';
      }
      filter += '}';

      //   console.log('AMLT Link Team filter: ', filter);
      //   console.log('AMLT Link Team teamLevelBelowName: ', teamLevelBelowName);

      try {
        const myQuery = gql`
              query MyQuery($orgID: ID!, $searchString: String!, $userID: ID!, $nextToken: String) {
                searchCircles(
                  filter: ${filter},
                  limit: 50,
                  sort: { direction: asc, field: name },
                  nextToken: $nextToken)
                  {
                  items {
                    id
                    name
                    imageName
                    imageType
                    users(filter: {id: {eq: $userID}}) {
                      items {
                        id
                        userID
                        status
                        memberType
                      }
                    }
                  }
                  nextToken
                }
              }`;
        let circlesNextToken = null;
        const circles = [];

        do {
          const mySearchString = ourSearchString + '*';

          fetchPromise = API.graphql({
            query: myQuery,
            variables: {
              orgID: global.me.orgID,
              searchString: mySearchString,
              userID: global.me.id,
              nextToken: circlesNextToken,
            },
          });
          const circleData = await fetchPromise;
          // console.log('AMLT Link Team circleData: ', circleData);

          // const circles = circleData.data.searchCircles.items;

          circles.push(...circleData.data.searchCircles.items);
          circlesNextToken = circleData.data.searchCircles.nextToken;
          // console.log('AMLT Link Team circlesNextToken: ', circlesNextToken);
        } while (circlesNextToken);

        // console.log('AMLT Link Team circles: ', circles);

        const assigneeList = [];
        let userID = global.me.id;
        if (props.userID !== undefined) {
          //
          userID = props.userID;
        }

        for (const circle of circles) {
          // console.log('AMLT circle: ', circle);
          if (circle.id === global.me.orgID && !props.allowAllCircle) {
            continue;
          }
          // const inheritedCircleAdmin = true;
          const inheritedCircleAdmin = global.me.myChildCircles.includes(
            circle.id
          );
          // console.log ("inheritedCircleAdmin: ", inheritedCircleAdmin?"True":"False");
          let userHasRecord = false;
          //
          //
          //  The user is approved if they're the admin, an inherited admin, or we're not checking permissions
          //
          let userApproved =
            global.me.isAdmin ||
            inheritedCircleAdmin ||
            !props.checkCreatePermissions;

          for (const user of circle.users.items) {
            //
            //  If the user ia attached to this circle, the user is an admin, or the user inherits admin to this cicle,
            //  by default we're approved
            //
            if (
              user.userID === userID ||
              global.me.isAdmin ||
              inheritedCircleAdmin
            ) {
              if (user.status === 'approved' || user.status === 'accepted') {
                userApproved = true;
                if (
                  props.checkCreatePermissions &&
                  user.memberType === 'member' &&
                  !global.me.isAdmin &&
                  !inheritedCircleAdmin
                ) {
                  //
                  // Members cannot create stuff using this circle, so do not approve them
                  //

                  userApproved = false;
                  break;
                }
              }
              if (user.userID === userID) {
                userHasRecord = true;
              }
            }
            if (props.showAll) {
              userApproved = true;
            }
            console.log(
              'props.checkCreatePermissions: ',
              props.checkCreatePermissions
            );
          }

          if (userApproved && props.skipCirclesUserBelongsTo === undefined) {
            const avatarFile = await getUserAvatar(
              circle.imageName,
              circle.imageType,
              true
            );
            assigneeList[assigneeList.length] = {
              name: circle.name,
              id: circle.id,
              value: circle.id,
              label: circle.name,
              type: 'circle',
              imageName: circle.imageName,
              imageType: circle.imageType,
              avatarFile,
            };
          }
          if (userApproved && props.skipCirclesUserBelongsTo !== undefined) {
            if (!userHasRecord) {
              const avatarFile = await getUserAvatar(
                circle.imageName,
                circle.imageType,
                true
              );
              assigneeList[assigneeList.length] = {
                name: circle.name,
                id: circle.id,
                value: circle.id,
                label: circle.name,
                type: 'circle',
                imageName: circle.imageName,
                imageType: circle.imageType,
                avatarFile,
              };
            }
          }
        }

        for (const assignee of assigneeList) {
          if (assignee.value === itemValue) {
            setSelectedName(assignee.label);
            setSelectedUser(assignee);
          }
        }

        filteredAssigneeList = assigneeList;
        if (props.hideAssigned !== undefined) {
          filteredAssigneeList = [];

          for (const item of assigneeList) {
            if (!props.hideAssigned.includes(item.id)) {
              filteredAssigneeList[filteredAssigneeList.length] = item;
            }
          }
        }

        for (const item of filteredAssigneeList) {
          if (item.id === props.value) {
            if (searchString !== item.name) {
              setSearchString(item.name);
            }
          }
          if (
            filteredAssigneeList.length === 1 &&
            searchString !== item.name &&
            !props.noAutoSelect
          ) {
            //
            //  If there is only one circle in our list, select it by default
            //
            setSearchString(item.name);
            props.fieldSetter(item.id, item.name, searchString);
          }
        }
      } catch (err) {
        console.error(err);
        console.log('AMLTModal error fetching teams');
      }
    }

    // Add Members:
    if (pickUsers) {
      try {
        const myPersonQuery = gql`
          query MyQuery(
            $orgID: ID!
            $searchString: String!
            $nextToken: String
          ) {
            searchUsers(
              filter: {
                name: { wildcard: $searchString }
                orgID: { eq: $orgID }
                status: { ne: "deleted" }
              }
              limit: 750
              sort: { direction: asc, field: name }
              nextToken: $nextToken
            ) {
              items {
                id
                name
                status
                imageName
                imageType
                initials
                title
              }
              nextToken
            }
          }
        `;
        const mySearchString = ourSearchString + '*';
        let usersNextToken = null;
        let users = [];

        do {
          fetchPromise = await API.graphql({
            query: myPersonQuery,
            variables: {
              orgID: global.me.orgID,
              searchString: mySearchString || '*',
              nextToken: usersNextToken,
            },
          });
          const userData = fetchPromise;

          users = [...users, ...(userData?.data?.searchUsers?.items || [])];

          usersNextToken = userData?.data?.searchUsers?.nextToken;
        } while (usersNextToken);

        // console.log('AMLTModal users: ', users);

        const personAssigneeList = [];
        for (const user of users) {
          if (user.status === 'active') {
            const avatarFile = await getUserAvatar(
              user.imageName,
              user.imageType,
              true
            );
            personAssigneeList[personAssigneeList.length] = {
              value: user.id,
              id: user.id,
              label: user.name,
              name: user.name,
              type: 'user',
              avatarFile,
              imageName: user.imageName,
              imageType: user.imageType,
              ...user,
            };
          }
        }

        //   console.log('AMLTModal personAssigneeList: ', personAssigneeList);

        // // TO DO: figure out what this is for... not used in mobile
        //   for (const assignee of personAssigneeList) {
        //     if (assignee.value === itemValue) {
        //       setSelectedName(assignee.label);
        //       setSelectedUser(assignee);
        //     }
        //   }

        // TO DO: figure out purpose of hideAssigned prop... not in mobile
        const hideAssigned = undefined;
        if (hideAssigned !== undefined) {
          for (const item of personAssigneeList) {
            if (!hideAssigned.includes(item.id)) {
              filteredAssigneeList[filteredAssigneeList.length] = item;
            }
          }
        } else {
          for (const item of personAssigneeList) {
            filteredAssigneeList[filteredAssigneeList.length] = item;
          }
        }
      } catch (err) {
        console.error(err);
        console.log('AMLTModal error fetching users');
      }
    }

    // filter out initial active members (or child teams if Link Teams) from filteredAssigneeList so they don't display in the modal list
    // TO DO: see if they should be filtered out or if they should remain for easier multi-user/team removal?
    filteredAssigneeList = filteredAssigneeList.filter(
      (item) =>
        // Check if initialActiveMember.user exists (when initialActiveMembers comes from TeamsScreen)
        // Then check if pickCircles and not Link Teams as the team id is in the circleID property, not id
        !initialActiveMembers.find((initialActiveMember) => {
          return initialActiveMember.user
            ? initialActiveMember.user.id === item.id
            : pickCircles && !isLinkTeams
            ? initialActiveMember.circleID === item.id
            : initialActiveMember.id === item.id;
        })
    );
    // console.log('AMLTModal filtered: ', filteredAssigneeList);

    // TO DO: switch back to let after commenting back in below if it is needed
    const sortedAssigneeList = filteredAssigneeList;
    // if (pickUsers && pickCircles) {
    //   //
    //   //  sort the combined list
    //   //
    //   sortedAssigneeList = filteredAssigneeList.sort((a, b) => {
    //     if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
    //     if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
    //     return 0;
    //   });
    // }
    // if (props.allOption) {
    //   sortedAssigneeList.unshift({
    //     label: "All " + global.appSettings.labels.circle.plural,
    //     value: "all",
    //     name: "All " + global.appSettings.labels.circle.plural,
    //     id: "all",
    //   });
    // }
    // if (props.allowNone) {
    //   sortedAssigneeList.unshift({
    //     label: "None",
    //     value: "",
    //     name: "None",
    //     id: "",
    //   });
    // }

    setItemList(sortedAssigneeList);
    // setGoodToGo(true);
  }

  const [checkedItems, setCheckedItems] = useState([]);
  //   console.log('AMLTModal checkedItems: ', checkedItems);

  useEffect(() => {
    // don't fetchCircles for Link Teams until teamLevelNumIdMap and teamLevelNumNameMap populate from TeamPage
    if (pickCircles) {
      // Check if Link Teams not add user to multiple teams because maps are only needed for linking teams
      if (isLinkTeams) {
        if (
          Object.keys(teamLevelNumIdMap).length === 0 ||
          Object.keys(teamLevelNumNameMap).length === 0
        )
          return;
      }
    }
    async function initialize() {
      if (isLoading) return;
      setIsLoading(true);
      await fetchCircles('');

      if (initialActiveMembers) {
        setCheckedItems(initialActiveMembers);
      }
      setIsLoading(false);
    }

    initialize();
  }, [initialActiveMembers, teamLevelNumIdMap, teamLevelNumNameMap]);

  // Handle Item Toggle:
  const handleItemToggle = (item) => {
    // console.log('AMLTModal item: ', item);
    setCheckedItems((currentCheckedItems) => {
      const isItemChecked = currentCheckedItems.some((i) => i.id === item.id);
      if (isItemChecked) {
        // Remove the item if it's already checked
        return currentCheckedItems.filter((i) => i.id !== item.id);
      } else {
        // Add the item if it's not checked
        return [...currentCheckedItems, item];
      }
    });
  };

  const data = itemList;

  // console.log('AMLTModal data: ', data);
  // console.log('AMLTModal initialActiveMembers: ', initialActiveMembers);
  //   console.log('AMLTModal checkedItems: ', checkedItems);
  // console.log('AMLTModal searchData: ', searchData);

  const options = {
    keys: ['name', 'title'],
    includeScore: true,
    threshold: 0.2,
  };

  const fuse = new Fuse(data, options);

  const handleClearSearch = () => {
    setModalSearchString('');
  };

  const cancelIconAdornment =
    modalSearchString.length > 0 ? (
      <Box
        sx={{ cursor: 'pointer' }}
        className={'clear-input-button'}
        onClick={handleClearSearch}
      >
        <InputAdornment position="end">
          <GamifyIcon
            icon={'delete'}
            color={'black'}
            // backgroundColor={'white'}
          ></GamifyIcon>
        </InputAdornment>
      </Box>
    ) : (
      <></>
    );

  return (
    <Backdrop sx={{ zIndex: 5 }} open={showModal}>
      <Box className={'am-lt-modal-container'}>
        <Box className={'am-lt-modal-top-container'}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 16,
            }}
          >
            <Typography
              style={{
                color: '#222428',
                fontSize: 18,
                fontWeight: 700,
                textAlign: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              {title}
            </Typography>
            <Box
              style={{
                cursor: 'pointer',
                maxWidth: 'fit-content',
                backgroundColor: '#F0F0F3',
                borderRadius: 1000,
                weight: 32,
                height: 32,
              }}
              onClick={() => {
                setShowModal(false);
                setIsSubmitting(false);
                setModalSearchString('');
                setSearchData();
              }}
              disableRipple={true}
            >
              {/* <BackIcon></BackIcon>
              <Typography
                style={{
                  color: '#0B0B0C',
                  fontWeight: 600,
                  fontSize: 16,
                  marginLeft: 16,
                }}
              >
                Cancel
              </Typography> */}
              <GamifyIcon icon={'newX'}></GamifyIcon>
            </Box>
          </Box>
          <SearchInput
            sx={{
              width: '100%',
              border: 'none',
              backgroundColor: '#F2F2F2',
              '& .MuiOutlinedInput-root': {
                width: '100%',
              },
              '& .MuiOutlinedInput-input': {
                position: 'relative',
                width: '100%',
                maxWidth: '627px',
              },
            }}
            id="search"
            name="new-search" // changed search to new-search to avoid Chrome autofill
            variant="outlined"
            placeholder="Search"
            value={modalSearchString}
            onChange={(e) => {
              //   console.log(
              //     'AMLTModal onChangeText e.target.value: ',
              //     e.target.value
              //   );
              const searchedData = fuse.search(e.target.value);
              //   console.log(
              //     'AMLTModal onChangeText searchedData: ',
              //     searchedData
              //   );

              const formattedData = searchedData.map((data) => ({
                ...data,
                ...data.item,
              }));

              setModalSearchString(e.target.value);
              setSearchData(formattedData);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    backgroundColor: 'transparent',
                    width: '10px',
                  }}
                >
                  <Search sx={{ color: '#868686' }} />
                </InputAdornment>
              ),
              endAdornment: cancelIconAdornment,
            }}
          />

          <Box style={{ marginVertical: 16 }}>
            <Typography
              style={{ fontSize: 14, fontWeight: 700, paddingBottom: 12 }}
            >
              {/* TO DO: decide if this is needed */}
              All (
              {modalSearchString.length > 0
                ? searchData.length
                : data.length > 0
                ? data.length
                : 0}
              )
            </Typography>
          </Box>
        </Box>

        <Box className={'am-lt-modal-middle-container'}>
          <Box
            style={{
              //   minHeight: 233,
              marginHorizontal: 16,
            }}
          >
            {/* use searchData when a modalSearchString is inputted, use data otherwise */}
            {isLoading ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // backgroundColor: '#f0f0f3',
                  border: '1px solid #E2E2E2',
                  width: '100%',
                  height: 80,
                  padding: 15,
                  borderRadius: 8,
                  marginBottom: 5,
                }}
              >
                <CircularProgress style={{ color: 'black' }}></CircularProgress>
              </Box>
            ) : modalSearchString.length > 0 ? (
              searchData.length > 0 ? (
                <>
                  {searchData.map((item, i) => {
                    const isChecked = checkedItems.find(
                      (checked) => checked.id === item.id
                    );
                    console.log('AMLT item: ', item);
                    return (
                      <Box
                        key={i}
                        className={'am-lt-list-item-button'}
                        style={{
                          width: '100%',
                          padding: 0,
                          borderRadius: 8,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleItemToggle(item)}
                        // disableRipple={true}
                      >
                        <Box className="am-lt-list-item">
                          <Box className={'am-lt-list-item-left-container'}>
                            {/* Display user/team avatar if there is one, otherwise display initials */}
                            {/* Account for image type to account for potential gifs */}
                            {item.avatarFile ? (
                              item.imageType === 'picture' ||
                              item.imageType.split('/')[0] === 'image' ? (
                                <Avatar
                                  src={item.avatarFile}
                                  sx={{ margin: '5px 12px 5px 5px' }}
                                />
                              ) : (
                                <CardMedia
                                  image={item.imageName}
                                  component="img"
                                  sx={{
                                    backgroundColor: 'black',
                                    borderRadius: 1000,
                                    height: 40,
                                    width: 40,
                                    objectFit: 'contain',
                                    margin: '5px 12px 5px 5px',
                                  }}
                                />
                              )
                            ) : (
                              <Avatar
                                sx={{
                                  backgroundColor: '#868686',
                                  margin: '5px 12px 5px 5px',
                                }}
                              >
                                {item.initials
                                  ? item.initials
                                  : item.name[0].toUpperCase()}
                              </Avatar>
                            )}
                            <Box
                              className={'am-lt-list-item-left-text-container'}
                            >
                              <Box style={{ display: 'flex' }}>
                                <Typography
                                  style={{
                                    color: '#222428',
                                    fontSize: 16,
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.name}
                                </Typography>
                                {item.status === 'disabled' && (
                                  <Typography
                                    style={{
                                      // color: '#FF5C77',
                                      color: '#E35050',
                                      fontSize: 16,
                                      fontWeight: 600,
                                      marginLeft: 4,
                                    }}
                                  >
                                    (Access{' '}
                                    {item.status[0].toUpperCase() +
                                      item.status.slice(1)}
                                    )
                                  </Typography>
                                )}
                              </Box>
                              <Typography
                                style={{
                                  color: '#868686',
                                  fontSize: 14,
                                  fontWeight: 500,
                                }}
                              >
                                {item.title}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className={'am-lt-list-item-right-container'}>
                            <Checkbox checked={isChecked}></Checkbox>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </>
              ) : (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 84,
                  }}
                >
                  <Typography>
                    {title === 'Add Members'
                      ? 'No members match this search, please try another'
                      : 'No teams match this search, please try another'}
                  </Typography>
                </Box>
              )
            ) : data.length > 0 ? (
              <>
                {data.map((item, i) => {
                  // If already checked items aren't filtered from the data, they start checked
                  const isChecked = checkedItems.some(
                    (checked) => checked.id === item.id
                  );
                  return (
                    <Box
                      key={i}
                      className={'am-lt-list-item-button'}
                      style={{
                        width: '100%',
                        padding: 0,
                        borderRadius: 8,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleItemToggle(item)}
                      // disableRipple={true}
                    >
                      <Box className="am-lt-list-item">
                        <Box className={'am-lt-list-item-left-container'}>
                          {/* Display user/team avatar if there is one, otherwise display initials */}
                          {/* Account for image type to account for potential gifs */}
                          {item.avatarFile ? (
                            item.imageType === 'picture' ||
                            item.imageType.split('/')[0] === 'image' ? (
                              <Avatar
                                src={item.avatarFile}
                                sx={{ margin: '5px 12px 5px 5px' }}
                              />
                            ) : (
                              <CardMedia
                                image={item.imageName}
                                component="img"
                                sx={{
                                  backgroundColor: 'black',
                                  borderRadius: 1000,
                                  height: 40,
                                  width: 40,
                                  objectFit: 'contain',
                                  margin: '5px 12px 5px 5px',
                                }}
                              />
                            )
                          ) : (
                            <Avatar
                              sx={{
                                backgroundColor: '#868686',
                                margin: '5px 12px 5px 5px',
                              }}
                            >
                              {item.initials
                                ? item.initials
                                : item.name[0].toUpperCase()}
                            </Avatar>
                          )}
                          <Box
                            className={'am-lt-list-item-left-text-container'}
                          >
                            <Box style={{ display: 'flex' }}>
                              <Typography
                                style={{
                                  color: '#222428',
                                  fontSize: 16,
                                  fontWeight: 600,
                                }}
                              >
                                {item.name}
                              </Typography>
                              {item.status === 'disabled' && (
                                <Typography
                                  style={{
                                    // color: '#FF5C77',
                                    color: '#E35050',
                                    fontSize: 16,
                                    fontWeight: 600,
                                    marginLeft: 4,
                                  }}
                                >
                                  (Access{' '}
                                  {item.status[0].toUpperCase() +
                                    item.status.slice(1)}
                                  )
                                </Typography>
                              )}
                            </Box>
                            <Typography
                              style={{
                                color: '#868686',
                                fontSize: 14,
                                fontWeight: 500,
                              }}
                            >
                              {item.title}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className={'am-lt-list-item-right-container'}>
                          <Checkbox
                            sx={{
                              '& .MuiCheckbox-root': {
                                '&.Mui-checked': {
                                  color: '#FF6A00', // for the outline color when checkbox is checked
                                },
                              },
                              '&.Mui-checked': {
                                '& .MuiSvgIcon-root': {
                                  position: 'relative',
                                  right: 2,
                                  top: 2,
                                  color: '#f0f0f3',
                                  backgroundColor: '#FF6A00', // for the checkmark color when checkbox is checked
                                  borderRadius: '4px',
                                  width: 20,
                                  height: 20,
                                },
                              },
                            }}
                            checked={isChecked}
                            disableRipple={true}
                          ></Checkbox>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 84,
                }}
              >
                <Typography>
                  {title === 'Add Members'
                    ? 'No active members left to add'
                    : teamLevelBelowName
                    ? `No ${teamLevelBelowName} level teams left to add`
                    : 'This team is at the lowest level, no teams can be linked'}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box className={'am-lt-modal-bottom-container'}>
          <Box className={'am-lt-modal-top-right-container'}>
            {/* render submit button only if there's a search string and more than 1 result or no search string and more than 1 result */}
            {modalSearchString.length > 0 ? (
              searchData.length > 0 ? (
                <Button
                  className={'top-am-lt-modal-submit-button'}
                  // NOTE: match width to am-let-modal-top-right-container
                  style={{ width: 110, height: 52, borderRadius: 8 }}
                  onClick={async () => {
                    setIsSubmitting(true);
                    const uniqueCheckedItems = _.uniqBy(checkedItems, 'id');
                    await onSubmit(uniqueCheckedItems);
                    setIsSubmitting(false);
                    setShowModal(false);
                  }}
                  disableRipple={true}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      style={{ color: 'white', width: 40, height: 40 }}
                    ></CircularProgress>
                  ) : title === 'Add Members' ? (
                    <Typography
                      style={{
                        color: '#FFFFFF',
                        fontSize: 16,
                        fontWeight: 700,
                      }}
                    >
                      Add
                    </Typography>
                  ) : (
                    <Typography
                      style={{
                        color: '#FFFFFF',
                        fontSize: 16,
                        fontWeight: 700,
                      }}
                    >
                      {isLinkTeams ? 'Link' : 'Add'}
                    </Typography>
                  )}
                </Button>
              ) : (
                <></>
              )
            ) : data.length > 0 ? (
              <Button
                className={'top-am-lt-modal-submit-button'}
                // NOTE: match width to am-let-modal-top-right-container
                style={{ width: 110, height: 52, borderRadius: 8 }}
                onClick={async () => {
                  setIsSubmitting(true);
                  const uniqueCheckedItems = _.uniqBy(checkedItems, 'id');
                  await onSubmit(uniqueCheckedItems);
                  setIsSubmitting(false);
                  setShowModal(false);
                }}
                disableRipple={true}
              >
                {isSubmitting ? (
                  <CircularProgress
                    style={{ color: 'white', width: 40, height: 40 }}
                  ></CircularProgress>
                ) : title === 'Add Members' ? (
                  <Typography
                    style={{ color: '#FFFFFF', fontSize: 16, fontWeight: 700 }}
                  >
                    Add
                  </Typography>
                ) : (
                  <Typography
                    style={{ color: '#FFFFFF', fontSize: 16, fontWeight: 700 }}
                  >
                    {isLinkTeams ? 'Link' : 'Add'}
                  </Typography>
                )}
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
}
