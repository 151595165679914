import React from 'react';
import { toast } from 'react-toastify';
import { /* CheckCircle, HighlightOff, */ Close } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import styled from '@emotion/styled';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToast.css';
import { GamifyIcon } from '../components/GamifyIcon';

// const SuccessToastContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   background-color: #58c8a0;
//   color: white;
//   padding: 8px;
//   font-family: Manrope, sans-serif;
//   font-size: 16px;
//   font-weight: 400;
//   line-height: 24px;
//   letter-spacing: -0.01em;
//   text-align: left;
//   border-radius: 8px;
// `;

// const ErrorToastContainer = styled(SuccessToastContainer)`
//   background-color: #fe7283;
// `;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

// const MessageContainer = styled.div`
//   flex: 1;
//   margin-left: 8px;
// `;

const CloseIconContainer = styled.div``;

// const SuccessToast = ({ title }) => (
//   <SuccessToastContainer>
//     <IconContainer>
//       <CheckCircle />
//     </IconContainer>
//     <MessageContainer>{title}</MessageContainer>
//     <CloseIconContainer>
//       <IconButton color="inherit" size="small">
//         <Close />
//       </IconButton>
//     </CloseIconContainer>
//   </SuccessToastContainer>
// );

const SuccessToast = ({ title, subtitle }) => (
  <Box
    sx={{
      '::after': {
        top: 0,
        left: 0,
        content: '""',
        width: '7px',
        height: '100%',
        position: 'absolute',
        display: 'inline-block',
        backgroundColor: '#01CEAC',
      },
    }}
    style={{
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      backgroundColor: '#3b3f46',
      color: 'white',
      minHeight: 64,
      padding: '16px',
      textAlign: 'left',
      borderRadius: '8px',
    }}
  >
    <IconContainer>
      <GamifyIcon icon={'check'}></GamifyIcon>
    </IconContainer>
    <Box
      style={{
        flex: 1,
        marginLeft: '8px',
      }}
    >
      <Typography style={{ fontSize: '15px', fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
        {subtitle}
      </Typography>
    </Box>
    <CloseIconContainer>
      <IconButton sx={{ padding: 0 }} color="inherit" size="small">
        <Close />
      </IconButton>
    </CloseIconContainer>
  </Box>
);

// const ErrorToast = ({ title }) => (
//   <ErrorToastContainer>
//     <IconContainer>
//       <HighlightOff />
//     </IconContainer>
//     <MessageContainer>{title}</MessageContainer>
//     <CloseIconContainer>
//       <IconButton color="inherit" size="small">
//         <Close />
//       </IconButton>
//     </CloseIconContainer>
//   </ErrorToastContainer>
// );

const ErrorToast = ({ title, subtitle }) => (
  <Box
    sx={{
      '::after': {
        top: 0,
        left: 0,
        content: '""',
        width: '7px',
        height: '100%',
        position: 'absolute',
        display: 'inline-block',
        backgroundColor: '#E35050',
      },
    }}
    style={{
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      backgroundColor: '#3b3f46',
      color: 'white',
      minHeight: 64,
      padding: '16px',
      textAlign: 'left',
      borderRadius: '8px',
    }}
  >
    <IconContainer>
      <GamifyIcon icon={'warning'} />
    </IconContainer>
    <Box
      style={{
        flex: 1,
        marginLeft: '8px',
      }}
    >
      <Typography style={{ fontSize: '15px', fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
        {subtitle}
      </Typography>
    </Box>
    <CloseIconContainer>
      <IconButton sx={{ padding: 0 }} color="inherit" size="small">
        <Close />
      </IconButton>
    </CloseIconContainer>
  </Box>
);

const defaultOptions = {
  autoClose: 1500,
  position: toast.POSITION.TOP_RIGHT,
};

// const GamifyToast = {
//   ...toast,
//   success: (title, options) => {
//     toast(<SuccessToast title={title} />, {
//       ...options,
//       ...defaultOptions,
//     });
//   },
//   error: (title, options) => {
//     toast(<ErrorToast title={title} />, {
//       ...options,
//       ...defaultOptions,
//     });
//   },
// };

const GamifyToast = {
  ...toast,
  success: (title, subtitle, options) => {
    toast(<SuccessToast title={title} subtitle={subtitle} />, {
      ...options,
      ...defaultOptions,
      closeButton: false,
      progressStyle: {
        background: '#01CEAC',
      },
    });
  },
  error: (title, subtitle, options) => {
    toast(<ErrorToast title={title} subtitle={subtitle} />, {
      ...options,
      ...defaultOptions,
      closeButton: false,
      progressStyle: {
        background: '#E35050',
      },
    });
  },
};

export { SuccessToast, ErrorToast, GamifyToast };
