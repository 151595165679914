import React from 'react';
import CustomSelectRewards from './CustomSelectRewards';
import RewardType from '../../../../../_types/RewardType';
import RoundState from '../../../../../_types/RoundState';
import NewGameState from '../../../../../_types/NewGameState';
import CustomTextInput from '../../../../../custom-inputs/CustomTextInput';

interface Props {
  rewards: RewardType[];
  roundState: RoundState;
  onChangeRoundState: (roundState: RoundState) => void;
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  tabNo: number;
}

const IfPlacedInputs: React.FC<Props> = ({
  rewards,
  roundState,
  onChangeRoundState,
  newGameState,
  onChangeNewGameState,
  tabNo,
}) => {
  if (roundState.rewardWhen !== 'mission') {
    return null;
  }

  if (roundState.rewardCurrency === 'points') {
    return (
      <>
        <CustomTextInput
          label="1st Place Coins"
          value={roundState.rewardCount1}
          onChange={(newValue) => {
            onChangeRoundState({ ...roundState, rewardCount1: newValue });
          }}
        />
        <CustomTextInput
          label="2nd Place Coins"
          value={roundState.rewardCount2}
          onChange={(newValue) => {
            onChangeRoundState({ ...roundState, rewardCount2: newValue });
          }}
        />
        <CustomTextInput
          label="3rd Place Coins"
          value={roundState.rewardCount3}
          onChange={(newValue) => {
            onChangeRoundState({ ...roundState, rewardCount3: newValue });
          }}
        />
        <CustomTextInput
          label="Participant Coins"
          value={roundState.rewardCountP}
          onChange={(newValue) => {
            onChangeRoundState({ ...roundState, rewardCountP: newValue });
          }}
        />
      </>
    );
  }

  return (
    <>
      <CustomSelectRewards
        label="1st Place Reward"
        value={roundState.rewardName1}
        onSelect={(reward: RewardType) => {
          console.log('reward', reward);
          onChangeRoundState({
            ...roundState,
            rewardID1: reward.id,
            rewardName1: reward.name,
            showCustomReward1: false,
          });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardID1 =
              reward.id;
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName1 =
              reward.name;
            newGameStateCopy.bracket.roundValues[tabNo - 1].showCustomReward1 =
              false;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              rewardID1: reward.id,
              rewardName1: reward.name,
              showCustomReward1: false,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
        items={rewards}
      />
      <CustomSelectRewards
        label="Participant Reward"
        value={roundState.rewardNameP}
        onSelect={(reward: RewardType) => {
          onChangeRoundState({
            ...roundState,
            rewardIDp: reward.id,
            rewardNameP: reward.name,
            showCustomReward1: false,
          });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardIDp =
              reward.id;
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardNameP =
              reward.name;
            newGameStateCopy.bracket.roundValues[tabNo - 1].showCustomReward1 =
              false;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              rewardIDp: reward.id,
              rewardNameP: reward.name,
              showCustomReward1: false,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
        items={rewards}
      />
      <CustomSelectRewards
        label="If Tied (Multiple people/teams have the same result)"
        value={roundState.rewardName1t}
        onSelect={(reward: RewardType) => {
          onChangeRoundState({
            ...roundState,
            rewardID1t: reward.id,
            rewardName1t: reward.name,
            showCustomReward1: false,
          });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardID1t =
              reward.id;
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName1t =
              reward.name;
            newGameStateCopy.bracket.roundValues[tabNo - 1].showCustomReward1 =
              false;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              rewardID1t: reward.id,
              rewardName1t: reward.name,
              showCustomReward1: false,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
        items={rewards}
      />
    </>
  );
};

export default IfPlacedInputs;
