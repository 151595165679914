import React from 'react';
import {
  CloseIcon,
  GifList,
  GifListItem,
  SearchInput,
  ViewContainer,
} from './GifSearch.styles';

interface Props {
  gifs: [];
  searchText: string;
  onChangeSearchText: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenClose: () => void;
  onSelect: (gif: any) => void;
}

const GifSearchView = ({
  gifs,
  onChangeSearchText,
  searchText,
  onOpenClose,
  onSelect,
}: Props) => {
  return (
    <ViewContainer>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CloseIcon src="/images/close-light.svg" onClick={onOpenClose} />
      </div>
      <SearchInput
        type="text"
        value={searchText}
        onChange={onChangeSearchText}
        placeholder="Search GIF"
      />
      <GifList>
        {gifs.map((gif: any) => {
          return (
            <GifListItem
              key={gif.id}
              onClick={(e) => {
                e.stopPropagation();
                onSelect(gif);
                onOpenClose();
              }}
              src={gif.url}
            />
          );
        })}
      </GifList>
    </ViewContainer>
  );
};

export default GifSearchView;
