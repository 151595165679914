import React from 'react';
import KPIFilter from './KPIFilter.view';
import { KPILeaderboardState } from '../_types';

interface KPIFilterContainerProps {
  kpiLeaderboardState: KPILeaderboardState;
  onChangeKpiLeaderboardState: (
    kpiLeaderboardState: KPILeaderboardState
  ) => void;
  resetTokens: () => void;
}

const KPIFilterContainer = ({
  kpiLeaderboardState,
  onChangeKpiLeaderboardState,
  resetTokens,
}: KPIFilterContainerProps) => {
  return (
    <KPIFilter
      kpiLeaderboardState={kpiLeaderboardState}
      onChangeKpiLeaderboardState={onChangeKpiLeaderboardState}
      resetTokens={resetTokens}
    />
  );
};

export default KPIFilterContainer;
