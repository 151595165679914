import { API } from 'aws-amplify';
import Contestant from '../_types/Contestant';
import { LOG } from '../../../common/Utilities';
import getUserAvatar from '../../../common/utils/getUserAvatar';

const SEARCH_CIRCLES_QUERY = `
query MyQuery($orgID: ID!, $userID: ID!) {
  searchCircles(
    filter: {
      name: { wildcard: "*" },
      orgID: { eq: $orgID },
      isDeleted: { eq: false }
    },
    limit: 10000,
    sort: { direction: asc, field: name }
  ) {
    items {
      id
      name
      imageName
      users(filter: {id: {eq: $userID}}) {
        items {
          id
          userID
          status
          memberType
          
        }
      }
    }
    nextToken
  }
}`;

type FetchContestantCirclesApiResponse = {
  data: {
    searchCircles: {
      items: {
        id: string;
        name: string;
        imageName: string;
        users: {
          items: {
            id: string;
            userID: string;
            status: string;
            memberType: string;
          };
        }[];
      }[];
    };
  };
};

const getContestantCircles = async ({
  userID,
  orgID,
}: {
  userID: string;
  orgID: string;
}): Promise<Contestant[]> => {
  const contestants: Contestant[] = [];

  try {
    const results = (await API.graphql({
      query: SEARCH_CIRCLES_QUERY,
      variables: {
        orgID,
        userID,
      },
    })) as FetchContestantCirclesApiResponse;

    const items = results.data.searchCircles.items;
    console.log('contestant circle', items);

    const filteredItems = items
      .filter((item) => item.id !== orgID)
      .map((item) => {
        return {
          label: item.name,
          value: item.id,
          imageName: item.imageName,
        };
      });

    const filteredItemsWithAvatar = [];

    for (let i = 0; i < filteredItems.length; ++i) {
      const item = filteredItems[i];
      const avatar = await getUserAvatar(item.imageName, '');
      filteredItemsWithAvatar.push({ ...item, avatar });
    }

    return filteredItemsWithAvatar;
  } catch (error) {
    LOG.error(error);
  }

  return contestants;
};

export default getContestantCircles;
