import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  margin-top: 20px;
  width: 500px;
`;

export const DateContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
`;
