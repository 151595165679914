import React, { useEffect, useState } from 'react';
import { View, Text } from '@aws-amplify/ui-react';
// import { API, Storage } from 'aws-amplify';
import {
  Box,
  // Backdrop,
  // Avatar,
  // Checkbox,
  // InputAdornment,
  // TextField,
  Button,
} from '@mui/material';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router';
import './dataImports.css';

export default function DataImports() {
  const navigate = useNavigate();
  // const [uploadType, setUploadType] = useState('');
  const [file, setFile] = useState(null);

  const onDrop = async (droppedFile) => {
    const [ourFile] = droppedFile;
    console.log(ourFile);
    setFile(ourFile);
  };

  const uploadFile = () => {
    console.log('Step 1');
    if (!file) {
      console.log('No file');
      return;
    }
    console.log('File is ready');
  };

  useEffect(() => {
    if (global.me.role !== 'admin' || !global.me.isAdmin) navigate(-1);
  }, []);
  return (
    <View
      style={{
        height: '100vh',
        width: '100vw',
        paddingTop: '90px',
      }}
    >
      <Box className="data-import-container">
        <Text style={{ fontWeight: 'bold', fontSize: '2rem' }}>
          Data Imports
        </Text>
        <Text style={{ fontSize: '1.2rem' }}>Import your data to Gamify</Text>
        <Text style={{ fontSize: '1.2rem' }}>Upload a CSV file to import</Text>
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <Box {...getRootProps()} className="data-imports-dropzone">
              <input {...getInputProps()} />
              <Box
                style={{
                  height: '400px',
                  width: '400px',
                  backgroundColor: 'red',
                }}
              />
              {/* <CloudUploadOutlinedIcon
                        style={{ fontSize: '10rem', color: '#323232' }}
                      />
                      <Text style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>
                        Drag and drop to upload
                      </Text>
                      <Box id="dropzone-button">
                        {file ? 'Change File' : 'Choose File'}
                      </Box>
                      {file && (
                        <input
                          type="text"
                          className="input-styling"
                          disabled={true}
                          value={lessonDetails.imageName}
                        />
                      )}
                      {submitted && validations.imageName && (
                        <Text
                          style={{
                            color: 'red',
                            fontSize: '0.9rem',
                            position: 'absolute',
                            bottom: '2rem',
                          }}
                        >
                          {validations.imageName}
                        </Text>
                      )} */}
            </Box>
          )}
        </Dropzone>
        {file && <Text>{file.name}</Text>}
        <Button onClick={uploadFile}>Get it!</Button>
      </Box>
    </View>
  );
}
