import React from 'react';
import CustomSelectRewards from './CustomSelectRewards';
import RewardType from '../../../../../_types/RewardType';
import RoundState from '../../../../../_types/RoundState';
import CustomTextInput from '../../../../../custom-inputs/CustomTextInput';
import NewGameState from '../../../../../_types/NewGameState';

interface Props {
  rewards: RewardType[];
  roundState: RoundState;
  onChangeRoundState: (roundState: RoundState) => void;
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  tabNo: number;
}

const CompanyActionInputs: React.FC<Props> = ({
  rewards,
  roundState,
  onChangeRoundState,
  newGameState,
  onChangeNewGameState,
  tabNo,
}) => {
  if (roundState.rewardWhen !== 'goal' && roundState.rewardWhen !== 'action') {
    return null;
  }

  if (roundState.rewardCurrency === 'points') {
    return (
      <CustomTextInput
        label="Coins"
        value={roundState.rewardCount1}
        onChange={(newValue) => {
          onChangeRoundState({ ...roundState, rewardCount1: newValue });
        }}
      />
    );
  }

  return (
    <>
      <CustomTextInput
        label="Winner Limit"
        placeholder="E.g. 30"
        value={roundState.rewardLimit}
        onChange={(newValue) => {
          onChangeRoundState({ ...roundState, rewardLimit: newValue });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardLimit =
              newValue;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              rewardLimit: newValue,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
      />
      <CustomSelectRewards
        label="Reward"
        value={roundState.rewardName1}
        onSelect={(reward: RewardType) => {
          onChangeRoundState({
            ...roundState,
            rewardID1: reward.id,
            rewardName1: reward.name,
            showCustomReward1: false,
          });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardLimit =
              reward.id;
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName1 =
              reward.name;
            newGameStateCopy.bracket.roundValues[tabNo - 1].showCustomReward1 =
              false;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              rewardID1: reward.id,
              rewardName1: reward.name,
              showCustomReward1: false,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
        items={rewards}
      />
    </>
  );
};

export default CompanyActionInputs;
