import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { API, Storage } from 'aws-amplify';
import { Text } from '@aws-amplify/ui-react';
import CloseIcon from '@mui/icons-material/Close';
import {
  createLessonDetail,
  updateLessonDetail,
} from '../../../graphql/mutations';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Button,
  Backdrop,
  Switch,
  CircularProgress,
  Tooltip,
  Slider,
} from '@mui/material';
import './Training.css';

export const SingleLessonCard = (props) => {
  const stylesObj = {
    grid: {
      main: 'single-lesson-card',
      img: 'lesson-card-image',
    },
    list: {
      main: 'single-lesson-list',
      img: 'lesson-list-image',
    },
  };
  const { lesson, display, refresh, snackbar } = props;
  const navigate = useNavigate();
  const [cardImageStyle, setCardImageStyle] = useState(null);
  const [descriptionShort, setDescriptionShort] = useState('');
  const [openEditor, setOpenEditor] = useState(false);

  useEffect(() => {
    const temp = {};
    if (lesson.posterImageName) {
      temp.backgroundImage = `url(${lesson.posterImageName})`;
    } else {
      temp.backgroundImage = 'linear-gradient(to top right, white, #FF6A00)';
    }
    setCardImageStyle(temp);

    if (lesson.description && lesson.description.length > 80) {
      setDescriptionShort(`${lesson.description.slice(0, 80)}...`);
    } else setDescriptionShort(lesson.description);
  }, [lesson]);

  if (!cardImageStyle) return null;
  return (
    <>
      <Box
        className={stylesObj[display].main}
        style={{ position: 'relative' }}
        onClick={() => navigate(`/training/${lesson.category}/${lesson.id}`)}
      >
        <Box style={cardImageStyle} className={stylesObj[display].img} />
        <Box
          style={{
            padding: '5px 35px 5px 15px',
            marginTop: '10px',
            position: 'relative',
          }}
        >
          <Text style={{ fontWeight: 'bold' }}>{lesson.name}</Text>
          <Text style={{ color: '#868686' }}>{descriptionShort}</Text>
        </Box>
        {global.me.isAdmin && (
          <Tooltip title="Edit">
            <Box
              className="card-edit-button"
              onClick={(e) => {
                e.stopPropagation();
                setOpenEditor(true);
              }}
            >
              <MoreHorizIcon
                style={{
                  color: display === 'grid' ? 'white' : 'black',
                  fontSize: '2rem',
                }}
                className="edit-dots"
              />
            </Box>
          </Tooltip>
        )}
      </Box>
      {openEditor && (
        <EditTrainigVideo
          open={openEditor}
          close={() => setOpenEditor(false)}
          lesson={lesson}
          refresh={refresh}
          snackbar={(a, b) => snackbar(a, b)}
        />
      )}
    </>
  );
};

export function EditTrainigVideo(props) {
  const { lesson, open, close, refresh, snackbar } = props;

  const [ogLesson, setOgLesson] = useState(null);
  const [changes, setChanges] = useState(false);
  const [lessonInfo, setLessonInfo] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageDisplay, setImageDisplay] = useState(null);
  const [savingChanges, setSavingChanges] = useState(false);
  const [validations, setValidations] = useState({ errors: false });
  const [createNewCategory, setCreateNewCategory] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const thumbnailChange = async (droppedFile) => {
    const formats = ['jpeg', 'jpg', 'png', 'gif'];
    const image = droppedFile[0];
    const imageName = image.name;
    if (!formats.includes(imageName.split('.').pop().toLowerCase())) {
      alert(
        'Thumbnail image format must be one of the following - png, gif, jpeg'
      );
      return;
    }
    setImageFile(image);
    setImageDisplay(URL.createObjectURL(image));
    setLessonInfo((p) => ({
      ...p,
      posterImageName: imageName.replace(/^.*[\\/]/, ''),
    }));
  };

  const validateLesson = (lesson) => {
    if (!lesson) return;
    const temp = { errors: false };
    if (!lesson.name.length) {
      temp.errors = true;
      temp.name = 'Training video must have a title';
    }
    if (!lesson.category.length) {
      temp.errors = true;
      temp.category = 'Please select category';
    }
    return temp;
  };

  const updateLesson = async () => {
    setSubmitted(true);
    if (validations.errors) return;
    setSavingChanges(true);
    // console.log(lessonInfo);
    const submitObj = {
      id: lessonInfo.id,
      category: newCategory.length ? newCategory : lessonInfo.category,
      description: lessonInfo.description,
      name: lessonInfo.name,
      order: lessonInfo.order,
    };

    if (lessonInfo.posterImageName !== ogLesson.posterImageName) {
      submitObj.posterImageName = `${
        global.me.orgID
      }_${lessonInfo.posterImageName.replace(/^.*[\\/]/, '')}`;

      await Storage.put(submitObj.posterImageName, imageFile, {
        contentType: imageFile.type,
      });
    }

    try {
      await API.graphql({
        query: updateLessonDetail,
        variables: { input: submitObj },
      });
    } catch (err) {
      console.log('ERROR');
      console.log(err);
      return false;
    }
    snackbar(`Updated Video Information - ${submitObj.name}`, 'info');
    refresh();
    close();
    // global.trainingVideos = null;

    // navigate(`/training/${submitObj.category}/${submitObj.id}`);
  };

  const deleteLesson = async () => {
    const submitObj = { id: lessonInfo.id, isDeleted: true };
    try {
      await API.graphql({
        query: updateLessonDetail,
        variables: { input: submitObj },
      });
    } catch (err) {
      console.log('ERROR');
      console.log(err);
      return false;
    }
    snackbar(`Video Deleted - ${lessonInfo.name}`, 'error');
    refresh();
    close();
  };

  useEffect(() => {
    setOgLesson(lesson);
    setLessonInfo(lesson);
  }, [lesson]);

  useEffect(() => {
    setValidations(validateLesson(lessonInfo));
    setChanges(JSON.stringify(ogLesson) !== JSON.stringify(lessonInfo));
  }, [lessonInfo]);
  if (!ogLesson || !global.trainingVideos) return;
  return (
    <Backdrop
      sx={{
        backgroundColor: 'rgba(0,0,0,0.6)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={(e) => {
        e.stopPropagation();
        if (!e.target.closest('#lesson-detail-form')) {
          close();
        }
      }}
    >
      <Box id="lesson-detail-form">
        {savingChanges && (
          <CircularProgress
            style={{
              height: '50px',
              width: '50px',
              position: 'absolute',
              top: '50%',
              right: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
        <Box
          style={{
            width: '100%',
            display: 'flex',
            padding: '15px',
            justifyContent: 'center',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
            position: 'relative',
            backgroundColor: '#D0D2D8',
          }}
        >
          <Box
            id="close-upload-form-button"
            className="hov"
            style={{ top: '10px' }}
            onClick={close}
          >
            <CloseIcon style={{ color: 'white' }} />
          </Box>
          <Text
            style={{ fontWeight: 'bold' }}
          >{`Edit Training Details - ${ogLesson.name}`}</Text>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '15px',
          }}
        >
          {imageDisplay || lessonInfo.posterImageName ? (
            <img
              src={imageDisplay || lessonInfo.posterImageName}
              style={{
                width: '25vw',
                aspectRatio: '16/9',
                marginBottom: '10px',
                borderRadius: '8px',
              }}
            />
          ) : (
            <Box
              style={{
                width: '25vw',
                aspectRatio: '16/9',
                marginBottom: '10px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage:
                  'linear-gradient(to top right, white, #FF6A00)',
              }}
            >
              <ImageNotSupportedIcon
                style={{ color: 'white', fontSize: '4rem' }}
              />
              <Text
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}
              >
                No Thumbnail Photo
              </Text>
            </Box>
          )}
          <Dropzone onDrop={thumbnailChange} disabled={savingChanges}>
            {({ getRootProps, getInputProps }) => (
              <Box
                {...getRootProps()}
                className={
                  savingChanges ? 'thumbnail-disabled' : 'thumbnail-button hov'
                }
              >
                <input {...getInputProps()} />

                <Text
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: 'white',
                  }}
                >
                  Change Thumbnail Photo
                </Text>
              </Box>
            )}
          </Dropzone>
        </Box>

        <Box style={{ width: '100%', padding: '10px 15px' }}>
          <Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Text style={{ margin: '2px 15px' }}>Title</Text>
              {submitted && validations.name && (
                <Text style={{ color: 'red', fontSize: '0.9rem' }}>
                  {validations.name}
                </Text>
              )}
            </Box>
            <input
              disabled={savingChanges}
              className="input-styling"
              placeholder="Training Video Title..."
              type="text"
              value={lessonInfo.name}
              style={{
                width: '100%',
              }}
              onChange={(e) =>
                setLessonInfo((p) => ({ ...p, name: e.target.value }))
              }
            />
          </Box>
          <Box>
            <Text style={{ margin: '2px 15px' }}>Description</Text>
            <textarea
              disabled={savingChanges}
              className="input-styling"
              placeholder="Training Video Description..."
              rows={4}
              style={{
                width: '100%',
                resize: 'none',
              }}
              value={lessonInfo.description}
              onChange={(e) =>
                setLessonInfo((p) => ({
                  ...p,
                  description: e.target.value,
                }))
              }
            />
          </Box>
          <Box style={{ display: 'flex', gap: 10 }}>
            <Box style={{ width: '85%', flexGrow: 1 }}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // backgroundColor: 'red',
                }}
              >
                <Text style={{ margin: '2px 15px' }}>Category</Text>
                {lessonInfo.category === newCategory &&
                  newCategory.length > 0 && (
                    <Text style={{ color: '#FF6A00', fontSize: '0.9rem' }}>
                      New Category
                    </Text>
                  )}
                {submitted && validations.category && (
                  <Text style={{ color: 'red', fontSize: '0.9rem' }}>
                    {validations.category}
                  </Text>
                )}
              </Box>
              {createNewCategory ? (
                <Box style={{ display: 'flex', gap: '1rem' }}>
                  <input
                    className="input-styling"
                    placeholder="New Category..."
                    type="text"
                    value={newCategory}
                    style={{
                      flexGrow: '1',
                    }}
                    onChange={(e) => setNewCategory(e.target.value)}
                  />
                  <Box
                    className={
                      !newCategory.length
                        ? 'thumbnail-disabled'
                        : 'thumbnail-button hov'
                    }
                    style={{ width: 'fit-content' }}
                    onClick={() => {
                      if (!newCategory.length) return;
                      setLessonInfo((p) => ({
                        ...p,
                        category: newCategory,
                      }));
                      setCreateNewCategory(false);
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        color: 'white',
                      }}
                    >
                      Create Category
                    </Text>
                  </Box>
                  <Box
                    className="thumbnail-button hov"
                    style={{ width: 'fit-content' }}
                    onClick={() => setCreateNewCategory(false)}
                  >
                    <Text
                      style={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        color: 'white',
                      }}
                    >
                      Cancel
                    </Text>
                  </Box>
                </Box>
              ) : (
                <select
                  disabled={savingChanges}
                  value={lessonInfo.category}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (val === '@add-new-category') {
                      setCreateNewCategory(true);
                    } else {
                      setLessonInfo((p) => ({
                        ...p,
                        category: val,
                      }));
                    }
                  }}
                  className="input-styling"
                  style={{
                    width: '100%',
                  }}
                >
                  <option value="">Select Category</option>
                  {Object.keys(global.trainingVideos).map((cat, i) => (
                    <option value={cat} key={i}>
                      {cat}
                    </option>
                  ))}
                  {newCategory.length && (
                    <option value={newCategory}>{newCategory}</option>
                  )}
                  <option value="@add-new-category">Add New Category +</option>
                </select>
              )}
            </Box>
            <Box
              style={{
                width: '15%',
                // backgroundColor: 'red',
                // alignItems: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text style={{ margin: '2px 10px' }}>Order</Text>
              <input
                // disabled={disableForm}
                className="input-styling"
                placeholder="order"
                type="number"
                value={lessonInfo.order}
                style={{
                  width: '100%',
                }}
                onChange={(e) =>
                  setLessonInfo((p) => ({
                    ...p,
                    order: e.target.value,
                  }))
                }
              />
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Box
            className={
              changes && !savingChanges
                ? 'thumbnail-button hov'
                : 'thumbnail-disabled'
            }
            onClick={() => {
              if (changes) {
                updateLesson();
              }
            }}
          >
            <Text style={{ color: 'white', fontWeight: 'bold' }}>
              Save Changes
            </Text>
          </Box>
          <Box
            onClick={() => setConfirmDelete(true)}
            className="thumbnail-button hov"
            style={{ backgroundColor: 'red' }}
          >
            <Text style={{ color: 'white', fontWeight: 'bold' }}>
              Delete Video
            </Text>
          </Box>
        </Box>
      </Box>
      <Backdrop open={confirmDelete}>
        <Box
          style={{
            borderRadius: '8px',
            backgroundColor: 'white',
            padding: '25px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
            Are you sure you want to delete this training video?
          </Text>
          <Box style={{ marginTop: '15px' }}>
            <Button
              style={{ marginRight: '15px' }}
              color="success"
              variant="contained"
              onClick={deleteLesson}
            >
              Yes (Delete video)
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => setConfirmDelete(false)}
            >
              No (Keep Video)
            </Button>
          </Box>
        </Box>
      </Backdrop>
    </Backdrop>
  );
}

export function UploadTrainingVideo(props) {
  const { open, close, refresh, category, snackbar } = props;
  const newTrainingObj = {
    name: '',
    description: '',
    youTubeURL: '',
    order: 0,
    imageName: '',
    imageType: 'video',
    posterImageName: '',
    category: category || '',
  };

  const [file, setFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [lessonDetails, setLessonDetails] = useState(newTrainingObj);
  const [uploadType, setUploadType] = useState('video');
  const [screenshots, setScreenshots] = useState([]);
  const [useYTimage, setUseYTimage] = useState(false);
  const [validations, setValidations] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const [openThumbnailPicker, setOpenThumbnailPicker] = useState(false);
  const [createNewCategory, setCreateNewCategory] = useState(false);
  const [newCategory, setNewCategory] = useState('');

  const [sliderVal, setSliderVal] = useState(0);

  const validateLesson = (lessonObj) => {
    const validationObj = { errors: false };
    if (!lessonObj.name || !lessonObj.name.length) {
      validationObj.errors = true;
      validationObj.name = 'Invalid Video Title';
    }
    if (!lessonObj.category || !lessonObj.category.length) {
      validationObj.errors = true;
      validationObj.category = 'Invalid Video Category';
    }
    if (uploadType === 'youtube') {
      if (!lessonObj.youTubeURL || !lessonObj.youTubeURL.length) {
        validationObj.errors = true;
        validationObj.youTubeURL = 'Please Provide YouTube Link';
      } else if (lessonObj.youTubeURL.split('watch?v=').length !== 2) {
        validationObj.errors = true;
        validationObj.youTubeURL = 'Invalid YouTube Link';
      }
    }
    if (uploadType === 'video' && !lessonObj.imageName.length) {
      validationObj.errors = true;
      validationObj.imageName = 'Pick a video file to upload';
    }
    return validationObj;
  };

  const resetComponenet = () => {
    setFile(null);
    setImageFile(null);
    setUploadType('video');
    setScreenshots([]);
    setLessonDetails(newTrainingObj);
    setValidations({});
    setUploadProgress(0);
    setSubmitted(false);
    setUploading(false);
    setUploadStatus(null);
    setDisableForm(false);
    setNewCategory('');
    setCreateNewCategory(false);
  };

  const saveScreenShot = () => {
    const dataURL = screenshots[sliderVal];

    const formatMatch = dataURL.match(/^data:image\/(jpeg|png);base64,/);
    if (!formatMatch) {
      console.error('Invalid data URL format');
    } else {
      const imageFormat = formatMatch[1]; // 'jpeg' or 'png'
      const base64Data = dataURL.split(',')[1];
      const binaryData = atob(base64Data);
      const imageName = `screenshot_${sliderVal}_${Date.now()}_${file.name
        .split('.')[0]
        .replace(/^.*[\\/]/, '')}.jpeg`;

      const blob = new Blob(
        [
          new Uint8Array(binaryData.length).map((_, i) =>
            binaryData.charCodeAt(i)
          ),
        ],
        {
          type: `image/${imageFormat}`,
        }
      );

      setImageFile(blob);
      setLessonDetails((p) => ({ ...p, posterImageName: imageName }));
      setSliderVal(0);
      setOpenThumbnailPicker(false);
    }
  };

  const onDrop = async (droppedFile) => {
    const formats = ['mov', 'avi', 'mp4', 'mpgeg', 'ogv', 'ts', '3gp', '3g2'];
    const videoFile = droppedFile[0];
    const videoName = videoFile.name;
    if (!formats.includes(videoName.split('.').pop().toLowerCase())) {
      alert(
        "Video format must be one of the following - 'mov', 'avi', 'mp4', 'mpgeg', 'ogv', 'ts', '3gp', '3g2'"
      );
      return;
    }
    setScreenshots([]);
    setFile(videoFile);
    setLessonDetails((p) => ({ ...p, imageName: videoName }));

    //  creating 20 screenshots from video for uploading progress
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.src = URL.createObjectURL(videoFile);

    video.onloadedmetadata = async () => {
      const numScreenshots = 100;
      const timestamps = [];
      const duration = video.duration;

      for (let i = 0; i < numScreenshots; i++) {
        const percent = (i / (numScreenshots - 1)) * 100;
        const time = (percent / 100) * duration;
        timestamps.push(time);
      }
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const capturedScreenshots = [];

      for (const timestamp of timestamps) {
        video.currentTime = timestamp;
        await new Promise((resolve) =>
          video.addEventListener('seeked', resolve, { once: true })
        );
        canvas.width = video.videoWidth * 0.5;
        canvas.height = video.videoHeight * 0.5;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        capturedScreenshots.push(canvas.toDataURL('image/jpeg'));
      }
      setScreenshots(capturedScreenshots);
    };
  };

  const onDropThumbnail = async (droppedFile) => {
    const formats = ['jpeg', 'jpg', 'png', 'gif'];
    const image = droppedFile[0];
    const imageName = image.name;
    if (!formats.includes(imageName.split('.').pop().toLowerCase())) {
      alert(
        'Thumbnail image format must be one of the following - png, gif, jpeg'
      );
      return;
    }
    setImageFile(image);
    setLessonDetails((p) => ({
      ...p,
      posterImageName: imageName.replace(/^.*[\\/]/, ''),
    }));
  };

  const uploadVideo = async () => {
    // console.log('UPLOAD STARTED!');
    setUploadStatus('Preparing files for upload');
    // console.log(lessonDetails);
    setSubmitted(true);
    if (validations.errors) return;
    setDisableForm(true);
    setUploading(true);
    const submitObj = {
      lessonID: 'NA',
      isDeleted: 'false',
      orgID: global.me.orgID,
      name: lessonDetails.name,
      category: lessonDetails.category,
      imageType: lessonDetails.imageType,
      imageName: '',
      youTubeURL: '',
      posterImageName: '',
      description: lessonDetails.description,
      order: lessonDetails.order,
      // order:
      //   newCategory === lessonDetails.category
      //     ? 1
      //     : global.trainingVideos[lessonDetails.category].length + 1,
    };
    if (uploadType === 'youtube') {
      setUploadStatus('Getting data from youtube!');
      submitObj.youTubeURL = lessonDetails.youTubeURL.split('watch?v=')[1];
    } else if (uploadType === 'video') {
      setUploadStatus('Uploading video...');

      submitObj.imageName = `${submitObj.orgID}_${file.name.replace(
        /^.*[\\/]/,
        ''
      )}`;
      //   console.log('UPLOADING THE VIDEO');
      try {
        await Storage.put(submitObj.imageName, file, {
          progressCallback(progress) {
            const percentage = (progress.loaded / progress.total) * 100;
            setUploadProgress(percentage);
          },
          contentType: file.type,
        });
      } catch (error) {
        console.error('Upload error:', error);
      }
    }
    if (!useYTimage && imageFile && lessonDetails.posterImageName.length) {
      //   console.log('UPLOADING THE THUMBNAIL');
      setUploadStatus('Saving thumbnail photo');
      submitObj.posterImageName = `${
        submitObj.orgID
      }_${lessonDetails.posterImageName.replace(/^.*[\\/]/, '')}`;
      await Storage.put(submitObj.posterImageName, imageFile, {
        contentType: imageFile.type,
      });
    }

    // console.log('UPLOADING LESSON DETAILS');
    setUploadStatus('Saving Training info');
    try {
      await API.graphql({
        query: createLessonDetail,
        variables: { input: submitObj },
      });
    } catch (err) {
      console.log('ERROR');
      console.log(err);
      return false;
    }
    // console.log('COMPLETED');
    snackbar(`Training Video Uploaded - ${submitObj.name}`, 'success');
    resetComponenet();
    refresh();
    close();
  };

  useEffect(() => {
    setValidations(validateLesson(lessonDetails));
  }, [lessonDetails]);

  useEffect(() => {
    setLessonDetails((p) => ({ ...p, posterImageName: '' }));
    if (uploadType === 'video') {
      setUseYTimage(false);
    }
  }, [uploadType]);

  if (!open) return null;
  return (
    <Backdrop open={open}>
      <Box
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          id="close-upload-form-button"
          className="hov"
          onClick={() => {
            if (disableForm) {
              alert("Please don't close the window until upload is done!");
            } else {
              resetComponenet();
              close();
            }
          }}
        >
          <CloseIcon style={{ color: 'white' }} />
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '15px',
          }}
        >
          <Box
            className="video-type-button hov"
            style={{
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
              color: uploadType === 'video' ? 'white' : 'gray',
              backgroundColor: uploadType === 'video' ? '#FF6A00' : 'lightgray',
            }}
            onClick={() => setUploadType('video')}
          >
            Upload Video
          </Box>
          <Box
            className="video-type-button hov"
            style={{
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px',
              color: uploadType === 'video' ? 'gray' : 'white',
              backgroundColor: uploadType === 'video' ? 'lightgray' : '#FF6A00',
            }}
            onClick={() => setUploadType('youtube')}
          >
            YouTube Video
          </Box>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box id="lesson-detail-form">
            <Box id="lesson-detail-form-top">
              <Text style={{ fontWeight: 700, color: '#222428' }}>
                General Info
              </Text>
            </Box>
            <Box style={{ padding: '10px 15px' }}>
              <Box>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Text style={{ margin: '2px 15px' }}>Title</Text>
                  {submitted && validations.name && (
                    <Text style={{ color: 'red', fontSize: '0.9rem' }}>
                      {validations.name}
                    </Text>
                  )}
                </Box>
                <input
                  disabled={disableForm}
                  className="input-styling"
                  placeholder="Training Video Title..."
                  type="text"
                  value={lessonDetails.name}
                  style={{
                    width: '100%',
                  }}
                  onChange={(e) =>
                    setLessonDetails((p) => ({ ...p, name: e.target.value }))
                  }
                />
              </Box>
              <Box>
                <Text style={{ margin: '2px 15px' }}>Description</Text>
                <textarea
                  disabled={disableForm}
                  className="input-styling"
                  placeholder="Training Video Description..."
                  rows={4}
                  style={{
                    width: '100%',
                    resize: 'none',
                  }}
                  value={lessonDetails.description}
                  onChange={(e) =>
                    setLessonDetails((p) => ({
                      ...p,
                      description: e.target.value,
                    }))
                  }
                />
              </Box>
              <Box>
                <Box style={{ display: 'flex', gap: 10 }}>
                  <Box style={{ width: '85%', flexGrow: 1 }}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <Text style={{ margin: '2px 15px' }}>Category</Text>
                      {lessonDetails.category === newCategory &&
                        newCategory.length > 0 && (
                          <Text
                            style={{ color: '#FF6A00', fontSize: '0.9rem' }}
                          >
                            New Category
                          </Text>
                        )}
                      {submitted && validations.category && (
                        <Text style={{ color: 'red', fontSize: '0.9rem' }}>
                          {validations.category}
                        </Text>
                      )}
                    </Box>
                    {createNewCategory ? (
                      <Box style={{ display: 'flex', gap: '1rem' }}>
                        <input
                          className="input-styling"
                          placeholder="New Category..."
                          type="text"
                          value={newCategory}
                          style={{
                            flexGrow: '1',
                          }}
                          onChange={(e) => setNewCategory(e.target.value)}
                        />
                        <Box
                          className={
                            !newCategory.length
                              ? 'thumbnail-disabled'
                              : 'thumbnail-button hov'
                          }
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            if (!newCategory.length) return;
                            setLessonDetails((p) => ({
                              ...p,
                              category: newCategory,
                            }));
                            // setNewCategory('');
                            setCreateNewCategory(false);
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: 'bold',
                              fontSize: '1rem',
                              color: 'white',
                            }}
                          >
                            Create Category
                          </Text>
                        </Box>
                        <Box
                          className="thumbnail-button hov"
                          style={{ width: 'fit-content' }}
                          onClick={() => setCreateNewCategory(false)}
                        >
                          <Text
                            style={{
                              fontWeight: 'bold',
                              fontSize: '1rem',
                              color: 'white',
                            }}
                          >
                            Cancel
                          </Text>
                        </Box>
                      </Box>
                    ) : (
                      <select
                        disabled={disableForm}
                        value={lessonDetails.category}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (val === '@add-new-category') {
                            setCreateNewCategory(true);
                          } else {
                            setLessonDetails((p) => ({
                              ...p,
                              category: val,
                            }));
                          }
                        }}
                        className="input-styling"
                        style={{
                          width: '100%',
                        }}
                      >
                        <option value="">Select Category</option>
                        {Object.keys(global.trainingVideos).map((cat, i) => (
                          <option value={cat} key={i}>
                            {cat}
                          </option>
                        ))}
                        {newCategory.length && (
                          <option value={newCategory}>{newCategory}</option>
                        )}
                        <option value="@add-new-category">
                          Add New Category +
                        </option>
                      </select>
                    )}
                  </Box>
                  <Box
                    style={{
                      width: '15%',
                      // backgroundColor: 'red',
                      // alignItems: 'flex-end',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text style={{ margin: '2px 10px' }}>Order</Text>
                    <input
                      disabled={disableForm}
                      className="input-styling"
                      placeholder="order"
                      type="number"
                      value={lessonDetails.order}
                      style={{
                        width: '100%',
                      }}
                      onChange={(e) =>
                        setLessonDetails((p) => ({
                          ...p,
                          order: e.target.value,
                        }))
                      }
                    />
                  </Box>
                </Box>
                <Text style={{ margin: '2px 15px' }}>Thumbnail Image</Text>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '15px',
                    marginBottom: '10px',
                  }}
                >
                  <input
                    className="input-styling"
                    placeholder="Upload Video Thumbnail"
                    type="text"
                    disabled={true}
                    value={lessonDetails.posterImageName}
                    style={{
                      flexGrow: 1,
                      margin: '0',
                    }}
                    onChange={(e) =>
                      setLessonDetails((p) => ({ ...p, name: e.target.value }))
                    }
                  />
                  <Dropzone onDrop={onDropThumbnail} disabled={useYTimage}>
                    {({ getRootProps, getInputProps }) => (
                      <Box
                        {...getRootProps()}
                        className={
                          useYTimage || disableForm
                            ? 'thumbnail-disabled'
                            : 'thumbnail-button hov'
                        }
                      >
                        <input {...getInputProps()} />

                        <Text
                          style={{
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            color: 'white',
                          }}
                        >
                          Choose Image
                        </Text>
                      </Box>
                    )}
                  </Dropzone>
                  {uploadType === 'youtube' ? (
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '3px 10px',
                        border: '1px solid #FF6A00',
                        borderRadius: '8px',
                      }}
                    >
                      <Text>Use Youtube Thumbnail</Text>
                      <Switch
                        disabled={disableForm}
                        checked={useYTimage}
                        onChange={() => setUseYTimage((p) => !p)}
                      />
                    </Box>
                  ) : (
                    <Box
                      onClick={() =>
                        disableForm || !file
                          ? null
                          : setOpenThumbnailPicker(true)
                      }
                      className={
                        disableForm || !file
                          ? 'thumbnail-disabled'
                          : 'thumbnail-button hov'
                      }
                    >
                      <Text
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1rem',
                          color: 'white',
                        }}
                      >
                        Image From Video
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box
                  className="submit-button-box hov"
                  onClick={() => {
                    if (disableForm) return;
                    uploadVideo();
                  }}
                >
                  UPLOAD
                </Box>
              </Box>
            </Box>
          </Box>
          <Box id="lesson-detail-dropzone">
            {uploadType === 'video' &&
              (!uploading ? (
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <Box {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <CloudUploadOutlinedIcon
                        style={{ fontSize: '10rem', color: '#323232' }}
                      />
                      <Text style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>
                        Drag and drop to upload
                      </Text>
                      <Box id="dropzone-button">
                        {file ? 'Change File' : 'Choose File'}
                      </Box>
                      {file && (
                        <input
                          type="text"
                          className="input-styling"
                          disabled={true}
                          value={lessonDetails.imageName}
                        />
                      )}
                      {submitted && validations.imageName && (
                        <Text
                          style={{
                            color: 'red',
                            fontSize: '0.9rem',
                            position: 'absolute',
                            bottom: '2rem',
                          }}
                        >
                          {validations.imageName}
                        </Text>
                      )}
                    </Box>
                  )}
                </Dropzone>
              ) : (
                <Box className="dropzone-on-upload">
                  {uploading && uploadStatus ? (
                    <Text style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                      {uploadStatus}
                    </Text>
                  ) : null}
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '1rem',
                    }}
                  >
                    {screenshots.length && uploadProgress < 100 ? (
                      <img
                        style={{
                          width: '400px',
                          maxHeight: '300px',
                          borderRadius: '8px',
                        }}
                        // src={screenshots[Math.floor(uploadProgress / 5)]}
                        src={screenshots[Math.floor(uploadProgress)]}
                      />
                    ) : (
                      <>
                        <CircularProgress />
                      </>
                    )}
                    <Box className="progress-bar">
                      <Box
                        className="progress-bar-fill"
                        style={{
                          width: `${uploadProgress}%`,
                          background: `linear-gradient(to right, lightgreen, green ${uploadProgress}%)`,
                        }}
                      />
                      <Text
                        style={{
                          position: 'absolute',
                          left: '50%',
                          top: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: 'white',
                        }}
                      >{`${Math.floor(uploadProgress)}%`}</Text>
                    </Box>
                  </Box>
                  <Box style={{ height: '1rem', width: '1rem' }} />
                </Box>
              ))}
            {uploadType === 'youtube' && (
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  position: 'relative',
                }}
              >
                {uploading && uploadStatus ? (
                  <Text style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                    {uploadStatus}
                  </Text>
                ) : null}
                <YouTubeIcon style={{ fontSize: '10rem', color: '#323232' }} />
                {uploading ? (
                  <CircularProgress />
                ) : (
                  <Text style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>
                    Paste YouTube Link Below
                  </Text>
                )}
                <input
                  disabled={disableForm}
                  className="input-styling"
                  placeholder="Paste YouTube Link Here!"
                  type="text"
                  value={lessonDetails.youTubeURL}
                  style={{
                    marginTop: '15px',
                    width: '100%',
                  }}
                  onChange={(e) =>
                    setLessonDetails((p) => ({
                      ...p,
                      youTubeURL: e.target.value,
                    }))
                  }
                />
                {submitted && validations.youTubeURL && (
                  <Text
                    style={{
                      color: 'red',
                      fontSize: '0.9rem',
                      position: 'absolute',
                      bottom: '-1.5rem',
                    }}
                  >
                    {validations.youTubeURL}
                  </Text>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Backdrop open={openThumbnailPicker}>
        <Box
          style={{
            minWidth: '30vw',

            backgroundColor: 'white',
            padding: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '15px',
          }}
        >
          {screenshots.length ? (
            <>
              <Text
                style={{
                  position: 'relative',
                  top: '-0.5rem',
                  marginBottom: '15px',
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}
              >
                Pick Thumbnail Image From Video
              </Text>
              {screenshots.length && (
                <img
                  style={{
                    width: '100%',
                    maxHeight: '300px',

                    borderRadius: '8px',
                  }}
                  src={screenshots[sliderVal]}
                />
              )}
              <Slider
                min={0}
                max={99}
                step={1}
                onChange={(e) => setSliderVal(e.target.value)}
              />
            </>
          ) : (
            <Box
              style={{
                padding: '25px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                style={{
                  marginBottom: '15px',
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                }}
              >
                Loading Screen Shots
              </Text>
              <CircularProgress />
            </Box>
          )}
          <Box>
            <Button
              style={{ marginRight: '2rem' }}
              disabled={!screenshots.length}
              color="success"
              variant="contained"
              onClick={saveScreenShot}
            >
              Save Image
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => setOpenThumbnailPicker(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Backdrop>
    </Backdrop>
  );
}

export function LoadingVideoRows() {
  const cards = [];
  for (let i = 0; i < 4; i++) {
    cards.push(
      <Box
        key={i}
        className="single-lesson-card"
        style={{
          animation: `bounceEffect 1.6s alternate infinite ${i * 200}ms`,
        }}
      >
        <Box
          className="lesson-card-image"
          style={{
            backgroundImage: 'linear-gradient(to top right, white, #FF6A00)',
            animation: `gradiantAnimation 1.2s alternate infinite ${i * 200}ms`,
          }}
        ></Box>
        <Box
          style={{
            padding: '5px 35px 5px 15px',
            marginTop: '10px',
            position: 'relative',
          }}
        >
          <Box
            style={{
              height: '1.2rem',
              width: `${Math.floor(Math.random() * 11) + 5}ch`,
              backgroundColor: 'lightgray',
              borderRadius: '2px',
              filter: `contrast(${Math.floor(Math.random() * 50) + 50}%)`,
            }}
          />
          <Box
            style={{
              marginTop: '0.5rem',
              height: '5ch',
              width: `100%`,
              backgroundColor: '#e3e3e3',
              borderRadius: '2px',
              filter: `contrast(${Math.floor(Math.random() * 50) + 50}%)`,
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box className="category-row-continaer">
      <Box className="category-row-top">
        <Box style={{ display: 'flex' }}>
          <Box
            style={{
              fontWeight: 700,
              height: '1.8rem',
              filter: 'blur(1px)',
              width: `${Math.floor(Math.random() * 11) + 15}ch`,
              backgroundColor: 'lightgray',
              borderRadius: '5px',
              marginBottom: '15px',
              animation: 'contrastAnimation 3s alternate infinite',
            }}
          />
        </Box>
      </Box>
      <Box className="category-row-lessons-container">{cards}</Box>
    </Box>
  );
}
