import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  FormInput,
  FormSelect,
  FormAutocomplete,
} from '../../../common/FormInputs';
import { GamifyToast } from '../../../common/CustomToasts';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Product } from '../ProductsScreen/types';
import { useMaintainProductMutation } from './useMaintainProductMutation';

interface NewProduct {
  id: string;
  categoryID: string;
  name: string;
  description: string;
  listPrice: number | null;
  units: string | null;
  productCode: string;
  productFamily: string;
  frequency?: string;
  status?: string;
}

const initialNewProduct: NewProduct = {
  id: '',
  categoryID: '',
  name: '',
  description: '',
  listPrice: null,
  units: null,
  productCode: '',
  productFamily: '',
  frequency: 'monthly',
  status: 'active',
};

const inputStyle = {
  backgroundColor: '#ecedec',
};

const validationSchema = Yup.object().shape({
  id: Yup.string().required('ProductID is required'),
  categoryID: Yup.string().required('CategoryID is required'),
  name: Yup.string().required('Name is required'),
  description: Yup.string(),
  listPrice: Yup.number().required('Price is required'),
  units: Yup.string().required('Units is required'),
  productCode: Yup.string().required('Product code is required'),
  productFamily: Yup.string().required('Product family is required'),
  frequency: Yup.string(),
  status: Yup.string(),
});

interface CreateProductsScreenProps {
  open: boolean;
  onClose: () => void;
  onProductCreated: () => void;
  selectedProduct?: Partial<Product> | null;
  productFamilies: { label: string; value: string }[];
}

export default function CreateProductsScreen({
  open,
  onClose,
  onProductCreated,
  selectedProduct,
  productFamilies,
}: CreateProductsScreenProps) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const productId = selectedProduct?.id;
  const isEditMode = !!productId;

  const maintainProductMutation = useMaintainProductMutation();

  const initialValues =
    isEditMode && selectedProduct
      ? {
          id: selectedProduct.id,
          categoryID: selectedProduct.categoryID,
          productCode: selectedProduct.productCode,
          productFamily: selectedProduct.productFamily,
          listPrice: selectedProduct.listPrice,
          name: selectedProduct.name,
          description: selectedProduct.description,
          units: selectedProduct.units,
          // categoryID: selectedProduct.categoryID,
          frequency: selectedProduct.frequency,
          status: selectedProduct.status || 'active',
        }
      : initialNewProduct;

  async function onCreateProduct(
    product: NewProduct,
    actions: FormikHelpers<NewProduct>
  ): Promise<string | undefined> {
    if (product.productFamily === null || product.productFamily === undefined) {
      GamifyToast.error('Product Family required');
      return;
    }
    if (product.id === null || product.id === undefined) {
      GamifyToast.error('Product ID required');
      return;
    }
    if (product.categoryID === null || product.categoryID === undefined) {
      GamifyToast.error('Category ID required');
      return;
    }
    if (product.name === null || product.name === undefined) {
      GamifyToast.error('Product Name required');
      return;
    }

    try {
      const result = await maintainProductMutation.mutateAsync(product);
      actions.resetForm();
      onProductCreated();
      onClose();
      return result.id;
    } catch (error) {
      GamifyToast.error('Error creating product');
      return '';
    }
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Create product
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Formik
        // @ts-ignore
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onCreateProduct}
      >
        {(formikProps) => (
          <>
            <DialogContent>
              <FormInput
                hasSubmitted={hasSubmitted}
                inputStyle={inputStyle}
                inputName="id"
                label="Product ID"
                placeholder="Product ID"
                formikProps={formikProps}
                required
                disabled={isEditMode}
              />
              <FormInput
                hasSubmitted={hasSubmitted}
                inputStyle={inputStyle}
                inputName="categoryID"
                label="Category ID"
                placeholder="Category ID"
                formikProps={formikProps}
                required
              />
              <FormInput
                hasSubmitted={hasSubmitted}
                inputStyle={inputStyle}
                inputName="name"
                label="Name"
                formikProps={formikProps}
                required
              />
              <FormInput
                hasSubmitted={hasSubmitted}
                inputStyle={inputStyle}
                inputName="description"
                label="Description (Optional)"
                formikProps={formikProps}
                multiline
                rows={3}
              />
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  <FormInput
                    hasSubmitted={hasSubmitted}
                    inputStyle={inputStyle}
                    inputName="listPrice"
                    label="List Price"
                    placeholder="$ per item"
                    formikProps={formikProps}
                    required
                    type="number"
                    startAdornment="$"
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <FormInput
                    hasSubmitted={hasSubmitted}
                    inputStyle={inputStyle}
                    inputName="units"
                    label="Units"
                    formikProps={formikProps}
                    required
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  <FormInput
                    hasSubmitted={hasSubmitted}
                    inputStyle={inputStyle}
                    inputName="productCode"
                    label="Product code"
                    formikProps={formikProps}
                    required
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <FormAutocomplete
                    value={formikProps.values.productFamily}
                    onChange={(event: any, value: string | null) => {
                      formikProps.setFieldValue('productFamily', value);
                    }}
                    onInputChange={(
                      event: any,
                      newInputValue: string | null
                    ) => {
                      formikProps.setFieldValue('productFamily', newInputValue);
                    }}
                    options={productFamilies.map((pf) => pf.value)}
                    getOptionLabel={(option: any) =>
                      productFamilies.find((pf) => pf.value === option)
                        ?.label || option
                    }
                    label="Product Family"
                    placeholder="Choose a product family"
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <FormSelect
                  selectStyle={inputStyle}
                  inputName="frequency"
                  title="Frequency"
                  formikProps={formikProps}
                  required
                  select
                  fieldSetter={(value: string) =>
                    formikProps.setFieldValue('frequency', value)
                  }
                  value={formikProps.values.frequency}
                  selectList={[
                    {
                      value: 'weekly',
                      label: 'weekly',
                    },
                    {
                      value: 'monthly',
                      label: 'monthly',
                    },
                    {
                      value: 'quarterly',
                      label: 'quarterly',
                    },
                    {
                      value: 'semi-annually',
                      label: 'semi-annually',
                    },
                    {
                      value: 'yearly',
                      label: 'yearly',
                    },
                  ]}
                />
                <FormSelect
                  selectStyle={inputStyle}
                  inputName="status"
                  title="Status"
                  formikProps={formikProps}
                  required
                  select
                  fieldSetter={(value: string) =>
                    formikProps.setFieldValue('status', value)
                  }
                  value={formikProps.values.status}
                  selectList={[
                    {
                      value: 'active',
                      label: 'active',
                    },
                    {
                      value: 'inactive',
                      label: 'inactive',
                    },
                  ]}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setHasSubmitted(true);
                  formikProps.handleSubmit();
                }}
                disabled={
                  formikProps.isSubmitting || maintainProductMutation.isPending
                }
                sx={{
                  bgcolor: '#FF6A00',
                  '&:hover': { bgcolor: '#FF8C00' },
                  textTransform: 'none',
                  fontSize: '14px',
                  fontWeight: 600,
                  padding: '24px 16px',
                  borderRadius: '8px',
                  minWidth: '140px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  color: 'white',
                }}
              >
                {formikProps.isSubmitting ||
                maintainProductMutation.isPending ? (
                  <CircularProgress size={24} />
                ) : (
                  'Save changes'
                )}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
