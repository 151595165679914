import { API } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import { listProducts } from '../../../../graphql/queries';
import { Product } from './types';

const fetchProducts = async (): Promise<Partial<Product>[]> => {
  const res = await API.graphql({
    query: listProducts,
    variables: {
      limit: 1000,
      filter: {
        isDeleted: { ne: true },
        // @ts-ignore
        orgID: { eq: global.me.orgID },
      },
    },
  });

  // @ts-ignore
  const unsortedProducts = res.data?.listProducts?.items || [];
  return unsortedProducts
    .slice()
    .sort((a: Product, b: Product) =>
      a.name && b.name ? a.name.localeCompare(b.name) : 0
    );
};

export const useGetProducts = () => {
  return useQuery({
    queryKey: ['products'],
    queryFn: fetchProducts,
  });
};
