// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
//
// rewardQueries
//

import gql from 'graphql-tag';

export const getRewardDetails = gql`
  query getRewardDetails($rewardID: ID!) {
    getReward(id: $rewardID) {
      id
      isDeleted
      approverType
      cost
      costType
      description
      imageName
      imageType
      isActive
      limitCount
      location
      name
      personLimit
      remainingCount
      rewardType
      validFrom
      validTo
      value
      valueType
      ownerType
      utid
      giftcardDisclaimer
      tangoAccountID
      missions {
        items {
          mission {
            name
            startDate
            endDate
          }
        }
      }
      users {
        items {
          id
          purchaseCount
          purchaseType
          status
          user {
            id
            imageName
            imageType
            name
            title
            initials
          }
          details {
            items {
              id
              purchaseCount
              purchaseType
              status
              createdAt
            }
          }
        }
      }
    }
  }
`;

export const orgMemberQuery = gql`
  query MyQuery($userID: ID!, $orgID: ID!) {
    OrgMemberByUser(userID: $userID, orgID: { eq: $orgID }) {
      items {
        orgPoints
        lifetimeOrgPoints
      }
    }
  }
`;
