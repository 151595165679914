import React from 'react';
import CustomTextInput from '../../../custom-inputs/CustomTextInput';
import ItemSelectInput from '../../../custom-inputs/ItemSelectInput';
import CustomSelectRewards from './CustomSelectRewards';
import NewGameState from '../../../_types/NewGameState';
import RewardType from '../../../_types/RewardType';

interface Props {
  rewards: RewardType[];
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const HitQuotaAndPlacedInputs: React.FC<Props> = ({
  rewards,
  newGameState,
  onChangeNewGameState,
}) => {
  if (newGameState.rewardWhen !== 'mission_min') {
    return null;
  }

  if (newGameState.rewardCurrency === 'points') {
    return (
      <>
        <CustomTextInput
          label="1st Place Coins"
          value={newGameState.rewardCount1}
          onChange={(newValue) => {
            onChangeNewGameState({ ...newGameState, rewardCount1: newValue });
          }}
        />
        <CustomTextInput
          label="2nd Place Coins"
          value={newGameState.rewardCount2}
          onChange={(newValue) => {
            onChangeNewGameState({ ...newGameState, rewardCount2: newValue });
          }}
        />
        <CustomTextInput
          label="3rd Place Coins"
          value={newGameState.rewardCount3}
          onChange={(newValue) => {
            onChangeNewGameState({ ...newGameState, rewardCount3: newValue });
          }}
        />
        <CustomTextInput
          label="Participant Coins"
          value={newGameState.rewardCountP}
          onChange={(newValue) => {
            onChangeNewGameState({ ...newGameState, rewardCountP: newValue });
          }}
        />
      </>
    );
  }

  return (
    <>
      <ItemSelectInput
        label="1st Place Reward (Coins)"
        selectedItem={
          newGameState.showCustomReward1 ? 'custom' : newGameState.rewardID1
        }
        onSelect={(newSelectedItem: { label: string; value: any }) => {
          if (newSelectedItem.value === 'custom') {
            onChangeNewGameState({
              ...newGameState,
              rewardID1: '',
              rewardName1: '',
              showCustomReward1: true,
            });
          } else {
            onChangeNewGameState({
              ...newGameState,
              rewardID1: newSelectedItem.value,
              rewardName1: newSelectedItem.label,
              showCustomReward1: false,
            });
          }
        }}
        items={[
          { label: '10', value: 10 },
          { label: '25', value: 25 },
          { label: '50', value: 50 },
          { label: 'Custom', value: 'custom' },
        ]}
        numColumns={4}
      />
      {newGameState.showCustomReward1 && (
        <CustomTextInput
          label="Custom Goal"
          placeholder="E.g. 15"
          value={newGameState.rewardID1}
          onChange={(newValue) => {
            onChangeNewGameState({
              ...newGameState,
              rewardID1: newValue,
              rewardName1: 'Custom Goal',
            });
          }}
        />
      )}
      <ItemSelectInput
        label="2nd Place Reward ( Coins)"
        selectedItem={
          newGameState.showCustomReward2 ? 'custom' : newGameState.rewardID2
        }
        onSelect={(newSelectedItem: { label: string; value: any }) => {
          if (newSelectedItem.value === 'custom') {
            onChangeNewGameState({
              ...newGameState,
              rewardID2: '',
              rewardName2: '',
              showCustomReward2: true,
            });
          } else {
            onChangeNewGameState({
              ...newGameState,
              rewardID2: newSelectedItem.value,
              rewardName2: newSelectedItem.label,
              showCustomReward2: false,
            });
          }
        }}
        items={[
          { label: '10', value: 10 },
          { label: '25', value: 25 },
          { label: '50', value: 50 },
          { label: 'Custom', value: 'custom' },
        ]}
        numColumns={4}
      />
      {newGameState.showCustomReward2 && (
        <CustomTextInput
          label="Custom Goal"
          placeholder="E.g. 15"
          value={newGameState.rewardID2}
          onChange={(newValue) => {
            onChangeNewGameState({
              ...newGameState,
              rewardID2: newValue,
              rewardName2: 'Custom Goal',
            });
          }}
        />
      )}
      <ItemSelectInput
        label="Participant Reward"
        selectedItem={
          newGameState.showCustomReward3 ? 'custom' : newGameState.rewardID3
        }
        onSelect={(newSelectedItem: { label: string; value: any }) => {
          if (newSelectedItem.value === 'custom') {
            onChangeNewGameState({
              ...newGameState,
              rewardID3: '',
              rewardName3: '',
              showCustomReward3: true,
            });
          } else {
            onChangeNewGameState({
              ...newGameState,
              rewardID3: newSelectedItem.value,
              rewardName3: newSelectedItem.label,
              showCustomReward3: false,
            });
          }
        }}
        items={[
          { label: '10', value: 10 },
          { label: '25', value: 25 },
          { label: '50', value: 50 },
          { label: 'Custom', value: 'custom' },
        ]}
        numColumns={4}
      />
      {newGameState.showCustomReward3 && (
        <CustomTextInput
          label="Custom Goal"
          placeholder="E.g. 15"
          value={newGameState.rewardID3}
          onChange={(newValue) => {
            onChangeNewGameState({
              ...newGameState,
              rewardID3: newValue,
              rewardName3: 'Custom Goal',
            });
          }}
        />
      )}
      <CustomSelectRewards
        label="If Tied (Multiple people/teams have the same result)"
        value={newGameState.rewardName1t}
        onSelect={(reward: RewardType) => {
          onChangeNewGameState({
            ...newGameState,
            rewardID1t: reward.id,
            rewardName1t: reward.name,
            showCustomReward1: false,
          });
        }}
        items={rewards}
      />
    </>
  );
};

export default HitQuotaAndPlacedInputs;
