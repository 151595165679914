import React from 'react';
import { ViewContainer } from './Stepper.styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

interface StepperViewProps {
  steps: string[];
  activeStep: number;
  onChangeActiveStep: (newActiveStep: number) => void;
}

const StepperView: React.FC<StepperViewProps> = ({
  steps,
  activeStep,
  onChangeActiveStep,
}) => {
  return (
    <ViewContainer>
      <Stepper
        sx={{
          '& .MuiStepIcon-root': {
            cursor: 'pointer',
            color: '#CCCCCC', // Default color
            '& .Mui-active': {
              color: '#FF6A00', // Active step color
            },
            '& .Mui-completed': {
              color: '#FF6A00 !important',
            },
          },
          '& .MuiStepIcon-root .Mui-completed': {
            color: 'red', // Active step color
            fill: 'none',
          },
          '& .MuiStepConnector-line': {
            borderWidth: 1,
            borderStyle: 'dashed',
            color: '#FF6A00',
          },
          '& .MuiStepLabel-label': {
            fontWeight: 700,
            fontFamily: 'Manrope',
            cursor: 'pointer',
          },
          '& .MuiStepLabel-root .Mui-active': {
            color: '#FF6A00', // Active step color
            fontWeight: 700,
          },
          '& .MuiStepLabel-root .Mui-completed': {
            color: '#FF6A00', // Active step color
          },
          '& .MuiStepIcon-text': {
            fontWeight: 700,
            fontSize: 14,
          },
        }}
        alternativeLabel
        activeStep={activeStep}
      >
        {steps.map((label, index) => (
          <Step onClick={() => onChangeActiveStep(index)} key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </ViewContainer>
  );
};

export default StepperView;
