import gql from 'graphql-tag';
import { API } from 'aws-amplify';

const fetchProfileRewards = async (userID, ourSearchStatus) => {
  let myQuery;

  if (userID === 'all') {
    myQuery = gql`
      query MyQuery($orgID: ID!) {
        listUserRewardsByOrgCreatedAt(orgID: $orgID, limit: 10000) {
          items {
            id
            purchaseCount
            purchaseType
            status
            createdAt
            reward {
              id
              imageName
              imageType
              limitCount
              personLimit
              remainingCount
              rewardType
              description
              name
              validFrom
              validTo
            }
            user {
              id
              initials
              imageName
              name
            }
            details {
              items {
                id
                purchaseCount
                purchaseType
                status
                createdAt
                errorMessages
                mission {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `;
  } else {
    myQuery = gql`
      query MyQuery($userID: ID!) {
        listUserRewardsByUserReward(userID: $userID, limit: 20000) {
          items {
            id
            purchaseCount
            purchaseType
            status
            createdAt
            reward {
              id
              imageName
              imageType
              limitCount
              personLimit
              remainingCount
              rewardType
              description
              name
              validFrom
              validTo
            }
            user {
              id
              initials
              imageName
              name
            }
            details {
              items {
                id
                purchaseCount
                purchaseType
                status
                createdAt
                errorMessages
                mission {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `;
  }

  try {
    const userRewardsRet = await API.graphql({
      query: myQuery,
      variables: { userID, orgID: global.me.orgID },
    });
    let ourRewards;
    if (userID === 'all') {
      ourRewards = userRewardsRet.data.listUserRewardsByOrgCreatedAt.items;
    } else {
      ourRewards = userRewardsRet.data.listUserRewardsByUserReward.items;
    }
    const filteredOurRewards = [];
    for (const reward of ourRewards) {
      // Make sure purchaseCount > 0 when filtering for rewards
      if (ourSearchStatus === 'all' && reward.purchaseCount > 0) {
        filteredOurRewards[filteredOurRewards.length] = reward;
      } else if (
        reward.status === ourSearchStatus &&
        reward.purchaseCount > 0
      ) {
        filteredOurRewards[filteredOurRewards.length] = reward;
      }
      reward.details.item = reward.details.items.sort((a, b) => {
        if (a.createdAt < b.createdAt) return -1;
        if (a.createdAt > b.createdAt) return 1;
        return 0;
      });
    }
    const sortedRewardList = filteredOurRewards.sort((a, b) => {
      if (
        a.createdAt + '|' + a.user.name.toLowerCase() <
        b.createdAt + '|' + b.user.name.toLowerCase()
      )
        return -1;
      if (
        a.createdAt + '|' + a.user.name.toLowerCase() >
        b.createdAt + '|' + b.user.name.toLowerCase()
      )
        return 1;
      return 0;
    });

    const ourRewardChecked = [];
    for (let i = 0; i < ourRewards.length; i++) {
      ourRewardChecked[ourRewardChecked.length] = false;
    }
    //   setRewards(sortedRewardList);
    //   setRewardChecked(ourRewardChecked);
    //   setSelectedAll(false);
    //   setRewardsFetched(true);
    return [sortedRewardList, ourRewardChecked];
  } catch (err) {
    console.error('fetchRewards error: ', err);
  }
};

export default fetchProfileRewards;
