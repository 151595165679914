import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  // Mobile
  background-color: #fff;
  padding: 20px;
  display: flex;
  gap: 20px;
  width: 500px;
  margin-bottom: 4px;

  @media (min-width: 600px) {
    width: 760px;
  }
`;
