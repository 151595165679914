import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

const ToggleMenuItem = ({ children, isActive }) => (
  <Box
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: isActive ? 'white' : '#F0F0F3',
      padding: 6,
      paddingHorizontal: 8,
      borderRadius: 6,
      minWidth: 120,
    }}
  >
    <Typography
      style={{
        color: isActive ? '#FF6A00' : '#5F5F5F',
        fontSize: 14,
        fontWeight: 600,
      }}
    >
      {children}
    </Typography>
  </Box>
);

const ToggleMenu = ({
  leftTitle = 'People',
  rightTitle = 'Team',
  onRightClick,
  onLeftClick,
  initialActive = 'left',
}) => {
  const [activeTab, setActiveTab] = useState(
    initialActive === 'left' ? leftTitle : rightTitle
  );

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: 3,
        backgroundColor: '#F0F0F3',
        borderRadius: 6,
      }}
    >
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          onLeftClick?.();
          setActiveTab(leftTitle);
        }}
        style={{ marginRight: 8 }}
      >
        <ToggleMenuItem isActive={activeTab === leftTitle}>
          {leftTitle}
        </ToggleMenuItem>
      </Box>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          onRightClick?.();
          setActiveTab(rightTitle);
        }}
      >
        <ToggleMenuItem isActive={activeTab === rightTitle}>
          {rightTitle}
        </ToggleMenuItem>
      </Box>
    </Box>
  );
};

export default ToggleMenu;
