import React from 'react';
import {
  CustomInputContainer,
  CustomSelectWrapper,
  CustomInputLabel,
} from './CustomInputs.styles';

interface Props {
  label: string;
  value: any;
  onChange: (newValue: any) => void;
  items: { label: string; value: any }[];
}

const CustomSelect: React.FC<Props> = ({ onChange, value, items, label }) => {
  return (
    <CustomInputContainer>
      <CustomInputLabel>{label}</CustomInputLabel>
      <CustomSelectWrapper
        placeholder="Select"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        <option value="" disabled>
          Select
        </option>
        {items.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </CustomSelectWrapper>
    </CustomInputContainer>
  );
};

export default CustomSelect;
