import React, { useEffect, useState } from 'react';
import CreatePostView from './CreatePost.view';
import { Skeleton } from '@mui/material';
import CreatePostArgs from '../_types/CreatePostArgs';
import MyPerson from '../_types/MyPerson';
import { LOG } from '../../../common/Utilities';
import getMyPersons from '../feed-helpers/getMyPersons';
import { GamifyToast } from '../../../common/CustomToasts';
import { Storage } from 'aws-amplify';
import createNewPost from '../feed-helpers/createNewPost';
import Me from '../../../_types/Me';
import AppSettings from '../../../_types/AppSettings';

interface Props {
  me: Me;
  appSettings: AppSettings;
  refreshFeed: () => void;
}

const CreatePostContainer = ({ me, appSettings, refreshFeed }: Props) => {
  const INITIAL_POST = {
    header: '',
    details: '',
    imageName: '',
    imageType: '',
    blastEvent: '',
    alertMessage: '',
    alertDetails: '',
    createdBy: '',
    ccUserID: '',
    feedType: '',
    showAfterEpoch: 0,
    showUntilEpoch: 0,
  };
  const [isLoadingGetCircles, setIsLoadingGetCircles] = useState(true);
  const [post, setPost] = useState<CreatePostArgs>(INITIAL_POST);
  const [filePreview, setFilePreview] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [openGif, setOpenGif] = useState(false);
  const [myPersons, setMyPersons] = useState<MyPerson[]>([]);

  const onOpenCloseGif = () => {
    setOpenGif(!openGif);
  };

  const onChangePost = (post: CreatePostArgs) => {
    if (post.imageType === 'gif_url') {
      setFilePreview(post.imageName);
    }

    setPost(post);
  };

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      try {
        const selectedFile = files[0];
        const filePreview = URL.createObjectURL(selectedFile);

        let imageType = '';
        const fileType = selectedFile.type.split('/')[0];
        if (fileType === 'video') {
          imageType = 'video';
        } else if (fileType === 'image') {
          imageType = 'picture';
        }

        setPost({ ...post, imageType, imageName: selectedFile.name });
        setSelectedFile(selectedFile);
        setFilePreview(filePreview);
      } catch (error) {}
    }
  };

  const onSavePost = async () => {
    try {
      const assigneeType =
        appSettings.features.feedBlast.disableDefaultPostToAll && !me.isAdmin
          ? ''
          : me.isAdmin
          ? 'circle'
          : '';

      const assigneeID =
        appSettings.features.feedBlast.disableDefaultPostToAll && !me.isAdmin
          ? ''
          : me.isAdmin
          ? me.orgID
          : '';

      const blastEvent = {
        enabled: true,
        background: '',
        imageName: post.imageName,
        imageType: post.imageType,
      };

      await createNewPost({
        header: post.header,
        imageName: post.imageName,
        imageType: post.imageType,
        assigneeType,
        assigneeID,
        alertMessage: post.header,
        alertDetails: post.alertDetails,
        createdBy: me.id,
        ccUserID: me.id,
        feedType: 'feed_and_inbox',
        details: post.details,
        blastEvent,
        showAfterEpoch: 0,
        showUntilEpoch: 0,
      });

      if (selectedFile) {
        await Storage.put(selectedFile.name, selectedFile, {
          contentType: selectedFile.type,
        });
      }

      setPost(INITIAL_POST);
      setFilePreview(null);

      refreshFeed();
    } catch (error) {
      GamifyToast.error('Something went wrong posting your content.');
      LOG.error(error);
    }
  };

  useEffect(() => {
    const fetchMyPersons = async () => {
      try {
        const myPersons = await getMyPersons({
          orgID: me.orgID,
        });

        setMyPersons(myPersons);
      } catch (error) {
        LOG.debug(error);
      } finally {
        setIsLoadingGetCircles(false);
      }
    };

    fetchMyPersons();
  }, []);

  if (isLoadingGetCircles) {
    return (
      <Skeleton
        variant="rectangular"
        width={700}
        height={100}
        style={{ marginBottom: 20 }}
      />
    );
  }

  return (
    <CreatePostView
      post={post}
      me={me}
      myPersons={myPersons}
      filePreview={filePreview}
      openGif={openGif}
      onOpenCloseGif={onOpenCloseGif}
      onChangePost={onChangePost}
      onUpload={onUpload}
      onSavePost={onSavePost}
      onRemoveFilePreview={() => setFilePreview(null)}
    />
  );
};

export default CreatePostContainer;
