import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  margin-top: 50px;
`;

export const MissionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border: 3px solid #f0f0f3;
  align-items: center;
`;

export const Team = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 20px;
  width: 100%;
`;
