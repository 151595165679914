import React, { useEffect, useState } from 'react';
import EmoticonsActionView from './EmoticonsAction.view';
import { useGetUserInformationQuery } from '../../../../../../store/api/GraphQlQuery';
import { Icon } from '../Actions.styles';
import countEmoticonsByType from '../../../../feed-helpers/countEmoticonsByType';
import didUserReactWithParticularEmoticon from '../../../../feed-helpers/didUserReactWithParticularEmoticon';
import deleteEmoticon from '../../../../feed-helpers/deleteEmoticon';
import applyFeedEmoticon from '../../../../feed-helpers/applyFeedEmoticon';
import Emoticon from '../../../../_types/Emoticon';
import applyCommentEmoticon from '../../../../feed-helpers/applyCommentEmoticon';
import removeDuplicateEmoticons from '../../../../feed-helpers/removeDuplicateEmoticons';
interface Props {
  activityFeedID: string;
  emoticonList: Emoticon[];
  commentID?: string;
}

const EmoticonsActionContainer = ({
  commentID,
  activityFeedID,
  emoticonList,
}: Props) => {
  const { data: userData, isLoading: isLoadingUserData } =
    useGetUserInformationQuery({});
  const [emoticons, setEmoticons] = useState(emoticonList);
  const [emoticonsCounts, setEmoticonsCounts] = useState(
    countEmoticonsByType(emoticons)
  );
  const [openReactions, setOpenReactions] = useState(false);

  const [shouldHeartBeFilled, setShouldHeartBeFilled] = useState(false);

  useEffect(() => {
    if (!userData || isLoadingUserData) return;

    setShouldHeartBeFilled(
      didUserReactWithParticularEmoticon({
        userId: userData.me.id,
        emoticons,
        type: 'love',
      })
    );
  }, [userData, isLoadingUserData]);

  if (isLoadingUserData || !userData) {
    return <Icon src="/images/heart-outline.svg" />;
  }

  const toggleHeartEmoji = (emoticonType: string) => {
    if (emoticonType === 'love') {
      setShouldHeartBeFilled(!shouldHeartBeFilled);
    }
  };

  const removeUserEmoticon = async (emoticonType: string) => {
    const newEmoticons = emoticons.filter(
      (emoticon) =>
        !(emoticon.type === emoticonType && emoticon.user.id === userData.me.id)
    );

    const emoticonToBeDeleted = emoticons.find(
      (emoticon) =>
        emoticon.type === emoticonType && emoticon.user.id === userData.me.id
    );

    setEmoticonsCounts({
      ...emoticonsCounts,
      [emoticonType]: emoticonsCounts[emoticonType] - 1,
    });

    setEmoticons(newEmoticons);

    await deleteEmoticon({
      emoticonID: emoticonToBeDeleted?.id as string,
    });
  };

  const addUserEmoticon = async (emoticonType: string) => {
    setEmoticonsCounts({
      ...emoticonsCounts,
      [emoticonType]: emoticonsCounts[emoticonType]
        ? emoticonsCounts[emoticonType] + 1
        : 1,
    });

    setEmoticons([
      ...emoticons,
      {
        createdAt: '',
        id: '',
        type: emoticonType,
        user: {
          id: userData.me.id,
          imageName: userData.me.imageName,
          name: userData.me.name,
          title: userData.me.title,
        },
      },
    ]);

    if (commentID) {
      await applyCommentEmoticon({
        activityFeedID,
        commentID,
        isDeleted: false,
        orgID: userData.me.orgID,
        type: emoticonType,
        userID: userData.me.id,
        userName: userData.me.username,
      });
    } else {
      await applyFeedEmoticon({
        feedID: activityFeedID,
        isDeleted: false,
        orgID: userData.me.orgID,
        type: emoticonType,
        userID: userData.me.id,
        userName: userData.me.username,
      });
    }
  };

  return (
    <EmoticonsActionView
      openReactions={openReactions}
      userId={userData.me.id}
      emoticonsCounts={emoticonsCounts}
      emoticons={emoticons}
      globalEmoticons={userData?.appSettings.emoticons}
      shouldHeartBeFilled={shouldHeartBeFilled}
      onOpenReactions={() => setOpenReactions(!openReactions)}
      onSelectEmoticon={async (emoticonType: string) => {
        toggleHeartEmoji(emoticonType);

        const uniqueEmoticons = removeDuplicateEmoticons(emoticons);

        const didUserReactToAParticularEmoticon =
          didUserReactWithParticularEmoticon({
            userId: userData.me.id,
            type: emoticonType,
            emoticons: uniqueEmoticons,
          });

        if (didUserReactToAParticularEmoticon) {
          await removeUserEmoticon(emoticonType);
        } else {
          await addUserEmoticon(emoticonType);
        }
      }}
    />
  );
};

export default EmoticonsActionContainer;
