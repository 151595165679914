import { API, graphqlOperation } from 'aws-amplify';

const CREATE_COMMENT_EMOTICON = `
  mutation CreateCommentEmoticon(
    $input: CreateCommentEmoticonInput!
    $condition: ModelCommentEmoticonConditionInput
  ) {
    createCommentEmoticon(input: $input, condition: $condition) {
      id
      commentID
      type
      userID
      orgID
      createdAt
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        functionalRoles
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        PersonDefaultSchedules {
          nextToken
        }
        PersonScheduleOverrides {
          nextToken
        }
        PersonAppointments {
          nextToken
        }
        SetterAppointments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      updatedAt
    }
  }
`;

export const BOOST_ACTIVITY_FEED_ITEM = /* GraphQL */ `
  mutation BoostActivityFeedItem($request: AWSJSON!) {
    boostActivityFeedItem(request: $request)
  }
`;
const applyCommentEmoticon = async (args: {
  commentID: string;
  orgID: string;
  userID: string;
  type: string;
  isDeleted: boolean;
  activityFeedID: string;
  userName: string;
}) => {
  const {
    userName,
    commentID,
    isDeleted,
    orgID,
    type,
    userID,
    activityFeedID,
  } = args;

  const emoticon = {
    commentID,
    orgID,
    userID,
    type,
    isDeleted,
  };

  const newCommentEmoticon = await API.graphql(
    graphqlOperation(CREATE_COMMENT_EMOTICON, { input: emoticon })
  );

  API.graphql({
    query: BOOST_ACTIVITY_FEED_ITEM,
    variables: {
      request: JSON.stringify({
        type: 'commentEmoticon',
        commentID,
        emoticonType: type,
        userID,
        userName,
        // @ts-ignore
        changedID: newCommentEmoticon.data.createCommentEmoticon.id,
        activityFeedID,
      }),
    },
  });
};

export default applyCommentEmoticon;
