import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  padding: 88px 100px 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f1f1; /* Your desired color */
  min-height: 100vh;
`;

export const HeaderContainer = styled.div`
  // Mobile
  background-color: #fff;
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  width: 500px;
  margin-bottom: 4px;

  @media (min-width: 600px) {
    width: 760px;
  }
`;

export const HeaderBack = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const HeaderTitle = styled.div`
  font-weight: 700;
`;
