import { format } from 'date-fns';

const formatGameStartAndEndDate = (args: {
  startDate: Date;
  endDate: Date;
}): string => {
  const { endDate, startDate } = args;

  const startFormat = format(startDate, 'MMMM d');
  const endFormat = format(endDate, 'MMMM d yyyy');

  return `${startFormat} - ${endFormat}`;
};

export default formatGameStartAndEndDate;
