import React, { useEffect, useState } from 'react';
import { LoadingContainer } from '../../../Games.styles';
import CenteredProgress from '../../../../../components/CenteredProgress';
import { API } from 'aws-amplify';
import getImageFromS3 from '../../../../../common/utils/getImageFromS3';
import {
  RewardCardContainer,
  RewardCardDarkOverlay,
  RewardCardDetailsContainer,
  RewardCardImage,
  RewardCardName,
  RewardCardPlace,
} from '../NormalDetails.styles';
import getOrdinalSuffixOf from '../../../games-helpers/getOrdinalSuffixOf';
import GET_REWARD_DETAILS from '../../../queries/GET_REWARD_DETAILS';

type RewardData = {
  imageName: string;
  [key: string]: any; // You can replace this with more specific fields if you know the structure of the reward.
};

type ApiResponse = {
  data: {
    getReward: RewardData;
  };
};

interface Props {
  rewardID: string;
  place: number;
}

const Reward = ({ rewardID, place }: Props) => {
  const [loading, setLoading] = useState(true);
  const [reward, setReward] = useState<any>({});

  useEffect(() => {
    const fetchReward = async () => {
      setLoading(true);
      try {
        const getRewardRes = (await API.graphql({
          query: GET_REWARD_DETAILS,
          variables: { rewardID },
        })) as ApiResponse;

        if (getRewardRes?.data?.getReward) {
          const rewardData = getRewardRes.data.getReward;
          const imageUrl = getImageFromS3(rewardData.imageName);
          setReward({ imageUrl, ...rewardData });
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchReward();
  }, [rewardID]);

  if (loading) {
    return (
      <LoadingContainer>
        <CenteredProgress />
      </LoadingContainer>
    );
  }

  return (
    <RewardCardContainer>
      <RewardCardImage
        src={
          reward.imageName.includes('http') ? reward.imageName : reward.imageUrl
        }
        alt="Reward"
      />
      <RewardCardDarkOverlay />
      <RewardCardDetailsContainer>
        <RewardCardPlace>{getOrdinalSuffixOf(place)} place</RewardCardPlace>
        <RewardCardName>{reward.name}</RewardCardName>
      </RewardCardDetailsContainer>
    </RewardCardContainer>
  );
};

export default Reward;
