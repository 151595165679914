import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  display: flex;
  gap: 30px;
  position: relative;
`;

export const EmoticonSymbolAndTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const EmoticonSymbol = styled.div<{ color: string }>`
  color: ${({ color }) => `#${color}`};
  font-size: 18px;
  cursor: pointer;
`;

export const EmoticonCountText = styled.div`
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
`;

export const ReactionsContainer = styled.div`
  position: relative;
  margin-top: 2px;
`;

export const GlobalEmoticonsContainer = styled.div<{
  position: 'left' | 'right';
}>`
  display: flex;
  background-color: #f5f5f5;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  top: -60px;
  right: ${({ position }) => (position === 'left' ? 0 : 1)};
  cursor: pointer;
`;

export const GlobalEmoticonsItem = styled.div`
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const GlobalEmoticonsItemSymbol = styled.div`
  font-size: 18px;
`;

export const GlobalEmoticonsItemIndicator = styled.div<{ isActive: boolean }>`
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: ${({ isActive }) => (isActive ? '#FF6A00' : 'transparent')};
`;

export const CloseIcon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const ReactorListIndicator = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#FF6A00' : 'transparent')};
  width: 8px;
  height: 8px;
  border-radius: 100%;
`;

export const ReactorListContainer = styled.div`
  top: 24px;
  z-index: 999;
  border-radius: 10px;
  position: absolute;
  background-color: #fff;
  padding: 20px;
  width: 400px;
  max-height: 500px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  overflow-y: auto;
`;

export const ReactorListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ReactorListHeaderLeftContent = styled.div`
  display: flex;
`;

export const ReactorListTab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  gap: 10px;
`;

export const ReactorListTabLabel = styled.div<{ isActive: boolean }>`
  font-weight: 600;
  color: ${({ isActive }) => (isActive ? '#FF6A00' : '#000')};
  font-size: 14px;
`;

export const ReactorListBody = styled.div`
  margin-top: 20px;
`;

export const ReactorListBodyItem = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  margin-bottom: 10px;
`;

export const ReactorBodyItemProfileContainer = styled.div`
  position: relative;
`;

export const ReactorListBodyItemEmoticon = styled.div`
  position: absolute;
  font-size: 26px;
  top: 30px;
  left: 40px;
`;

export const ReactorListBodyItemLabel = styled.div`
  font-weight: 700;
`;
