const LIST_CIRCLE_LEADERBOARD = `
  query MyQuery($orgID: ID!, $endBetween: [String]!, $startBetween: [String]!) {
    listMissionByEndDate(
      orgID: $orgID
      endDate: { between: $endBetween }
      limit: 10000
      filter: {
        startDate: { between: $startBetween }
        isCircleOnly: { eq: true }
        isDeleted: { eq: false }
        type: { ne: quest_mission }
      }
    ) {
      items {
        id
        action
        actionCount
        name
        description
        actionType
        actionPastTense
        actionTypePlural
        imageName
        imageType
        rewardType
        isPublic
        startDate
        endDate
        type
        contestType
        rewards {
          items {
            id
            place
            points
            isTie
            reward {
              id
              name
              imageName
              imageType
            }
          }
        }
        circles(filter: { isDeleted: { eq: false } }) {
          items {
            id
            circle {
              isDeleted
              name
              scope
              id
              imageType
              imageName
              children {
                items {
                  id
                }
              }
            }
            doneActionCount
            rewardCount
          }
        }
      }
    }
  }
`;

export default LIST_CIRCLE_LEADERBOARD;
