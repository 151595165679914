import React from 'react';
// import { useNavigate, useParams } from 'react-router';
import { Box, Button, Typography, Skeleton } from '@mui/material';
import { BackIcon } from '../../components/GamifyIcon';
import TeamFormAddImageSkeleton from './TeamFormAddImageSkeleton';

export default function TeamFormPageSkeleton(props) {
  const { teamId, setShowTeamFormModal, isModal } = props;
  // const { teamId } = useParams();
  // const navigate = useNavigate();

  const handleCancel = (e) => {
    e.preventDefault();
    // navigate('/teams');
    setShowTeamFormModal(false);
  };

  return (
    <Box
      className={
        isModal ? 'team-form-modal-container' : 'team-form-page-container'
      }
    >
      <Box
        className={
          isModal
            ? 'team-form-modal-top-container'
            : 'team-form-page-top-container'
        }
      >
        <Box
          className={
            isModal
              ? 'team-form-modal-cancel-container'
              : 'team-form-page-cancel-container'
          }
        >
          <Box className={'team-form-cancel-button-container'}>
            <Button
              className={
                isModal
                  ? 'team-form-modal-cancel-button'
                  : 'team-form-page-cancel-button'
              }
              onClick={(e) => handleCancel(e)}
              disableRipple={true}
            >
              <BackIcon></BackIcon>
              <Typography
                className={
                  isModal
                    ? 'deal-form-modal-cancel-button-text'
                    : 'deal-form-page-cancel-button-text'
                }
              >
                Cancel
              </Typography>
            </Button>
          </Box>
          <Box
            className={
              isModal
                ? 'team-form-modal-title-container'
                : 'team-form-page-title-container'
            }
          >
            <Typography className={'team-form-title-text'}>
              {teamId ? `Edit Team` : `New Team`}
            </Typography>
          </Box>
          <Box className={'team-form-place-holder-container'}></Box>
        </Box>
      </Box>
      <Box
        className={
          isModal ? 'modal-team-form-container' : 'page-team-form-container'
        }
      >
        <TeamFormAddImageSkeleton isModal={isModal}></TeamFormAddImageSkeleton>
        <Box
          style={{
            backgroundColor: '#F0F0F3',
            borderRadius: 8,
            paddingBottom: 0,
          }}
        >
          <Box
            style={{
              width: '100%',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              backgroundColor: '#D0D2D8',
              padding: 16,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Skeleton
              sx={{ bgcolor: '#222428' }}
              variant={'rounded'}
              width={50}
              height={27}
            ></Skeleton>
            <Skeleton
              sx={{ bgcolor: '#222428' }}
              variant={'circular'}
              width={24}
              height={24}
            ></Skeleton>
          </Box>
          <Box style={{ padding: '24px 16px 8px 16px' }}>
            {['', '', '', '', ''].map((input, i) => (
              <Box
                key={i}
                style={{
                  position: 'relative',
                  width: '100%',
                  marginBottom: 16,
                }}
              >
                <Skeleton
                  sx={{ bgcolor: '#0B0B0C', marginBottom: '8px' }}
                  variant={'rounded'}
                  width={75}
                  height={18}
                ></Skeleton>
                <Box
                  style={{
                    backgroundColor: 'white',
                    width: '100%',
                    height: 56,
                    padding: '12px 16px',
                    borderRadius: 8,
                  }}
                >
                  <Skeleton
                    sx={{ bgcolor: '#D0D2D8' }}
                    variant={'rounded'}
                    width={644}
                    height={32}
                  ></Skeleton>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className={'save-team-button-container'}>
          <Box className={'skeleton-save-team-button'}>
            <Skeleton
              sx={{ bgcolor: '#ffffff' }}
              variant={'rounded'}
              width={123}
              height={24}
            ></Skeleton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
