import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './api/GraphQlQuery';
import avatarUrlReducer from './data/AvatarUrl';
import usersReducer from './reducers/users';
import rewardsReducer from './reducers/rewards';
import dealsReducer from './reducers/deals';

const rootReducer = {
  [apiSlice.reducerPath]: apiSlice.reducer,
  avatar: avatarUrlReducer,
  users: usersReducer,
  rewards: rewardsReducer,
  deals: dealsReducer,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
