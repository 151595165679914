import React from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  Box,
  Button,
  Typography,
  Skeleton,
  CircularProgress,
} from '@mui/material';
import { BackIcon /* , GamifyIcon */ } from '../../../components/GamifyIcon';

export default function UserFormPageSkeleton() {
  const { userId } = useParams();
  const navigate = useNavigate();

  const handleCancel = (e) => {
    e.preventDefault();
    navigate('/people');
  };

  return (
    <Box className={'team-form-page-container'}>
      <Box className={'team-form-top-container'}>
        <Box className={'team-form-cancel-container'}>
          <Box className={'team-form-cancel-button-container'}>
            <Button
              className={'team-form-cancel-button'}
              onClick={(e) => handleCancel(e)}
              disableRipple={true}
            >
              <BackIcon></BackIcon>
              <Typography className={'team-form-cancel-button-text'}>
                Cancel
              </Typography>
            </Button>
          </Box>
          <Box className={'team-form-title-container'}>
            <Typography className={'team-form-title-text'}>
              {userId ? `Edit User` : `New User`}
            </Typography>
          </Box>
          <Box className={'team-form-place-holder-container'}></Box>
        </Box>
      </Box>
      <Box className={'team-form-container'}>
        <Box
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginBottom: 24,
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#BDBDBD',
              width: 240,
              height: 240,
              borderRadius: 1000,
            }}
            // backgroundColor={'#BDBDBD'}
            // width={360}
            // height={360}
            // borderRadius={1000}
          >
            <CircularProgress sx={{ color: '#868686' }}></CircularProgress>
          </Box>
          {/* <Box
            className={'team-form-file-upload-container'}
            style={{
              position: 'absolute',
              bottom: 16,
              right: 56,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              width: 40,
              minWidth: 40,
              height: 40,
              border: '1px solid #D0D2D8',
              borderRadius: 1000,
            }}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <GamifyIcon icon={'edit'}></GamifyIcon>
          </Box>
          <Box
            style={{
              position: 'absolute',
              bottom: 16,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              width: 40,
              minWidth: 40,
              height: 40,
              border: '1px solid #D0D2D8',
              borderRadius: 1000,
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <GamifyIcon icon={'trash'}></GamifyIcon>
          </Box> */}
        </Box>
        <Box
          style={{
            backgroundColor: '#F0F0F3',
            borderRadius: 8,
            paddingBottom: 0,
          }}
        >
          <Box
            style={{
              width: '100%',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              backgroundColor: '#D0D2D8',
              padding: 16,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Skeleton
              sx={{ bgcolor: '#222428' }}
              variant={'rounded'}
              width={50}
              height={27}
            ></Skeleton>
            <Skeleton
              sx={{ bgcolor: '#222428' }}
              variant={'circular'}
              width={24}
              height={24}
            ></Skeleton>
          </Box>
          <Box style={{ padding: '24px 16px 8px 16px' }}>
            {['', '', '', '', ''].map((input, i) => (
              <Box
                key={i}
                style={{
                  position: 'relative',
                  width: '100%',
                  marginBottom: 16,
                }}
              >
                <Skeleton
                  sx={{ bgcolor: '#0B0B0C', marginBottom: '8px' }}
                  variant={'rounded'}
                  width={75}
                  height={18}
                ></Skeleton>
                <Box
                  style={{
                    backgroundColor: 'white',
                    width: '100%',
                    height: 56,
                    padding: '12px 16px',
                    borderRadius: 8,
                  }}
                >
                  <Skeleton
                    sx={{ bgcolor: '#D0D2D8' }}
                    variant={'rounded'}
                    width={644}
                    height={32}
                  ></Skeleton>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className={'save-team-button-container'}>
          <Box className={'skeleton-save-team-button'}>
            <Skeleton
              sx={{ bgcolor: '#ffffff' }}
              variant={'rounded'}
              width={123}
              height={24}
            ></Skeleton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
