import React from 'react';
import { StickyTableHeadCell, TableHeadCell } from './KPITable.styles';
import { Header, Table, flexRender } from '@tanstack/react-table';
import { Box } from '@mui/material';

const TableHeader = ({
  table,
  onSort,
}: {
  table: Table<any>;
  onSort: (header: Header<any, any>) => void;
}) => {
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => {
            return (
              <>
                {console.log('TableHeader header: ', header)}
                {/* First 2 (Rank, Name) column headers */}
                {[0, 1].includes(index) ? (
                  <StickyTableHeadCell
                    left={index === 0 ? 0 : 98}
                    key={header.id}
                  >
                    {header.isPlaceholder ? null : (
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          width: index === 1 ? 300 : '100%',
                        }}
                        onClick={() => {
                          if (index === 0) return;
                          onSort(header);
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Box>
                    )}
                  </StickyTableHeadCell>
                ) : header.column.id === 'space' ? (
                  <></>
                ) : (
                  <TableHeadCell key={header.id}>
                    {header.isPlaceholder ? null : (
                      <Box
                        onClick={() => onSort(header)}
                        style={{ width: header.id === 'Time (s)' ? 75 : 65 }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Box>
                    )}
                  </TableHeadCell>
                )}
              </>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
