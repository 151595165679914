import React, { useState } from 'react';
import { /* Backdrop, Button, */ Box, Typography } from '@mui/material';
import { CustomMenu, CustomMenuItem } from '../../common/StyledComponents';
// import { GamifyToast } from '../../common/CustomToasts';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import './AppointmentOptionsMenu.css';

export default function TeamOptionsMenu(props) {
  const {
    // setAppointmentOptionsOpen,
    anchorEl,
    setAnchorEl,
    appointment,
    user,
    markAppointment,
  } = props;
  //   console.log('TeamOptionsMenu team, user: ', team, user);
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmCancelApptModal, setShowConfirmCancelApptModal] =
    useState(false);
  const [showConfirmCompleteApptModal, setShowConfirmCompleteApptModal] =
    useState(false);

  const handleCancelApptClick = () => {
    setShowConfirmCancelApptModal(true);
  };

  const handleConfirmCancelApptClick = async (appointment) => {
    // console.log(
    //   'handleConfirmCancelApptClick appointment: ',
    //   appointment
    // );
    setIsSaving(true);

    try {
      await markAppointment(appointment.id, 'cancelled', '', () => {
        setIsSaving(false);
      });
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }

    setShowConfirmCancelApptModal(false);
    // setAppointmentOptionsOpen(false);
    setAnchorEl(null);
  };

  const handleConfirmCompleteApptClick = async (appointment) => {
    // console.log(
    //   'handleConfirmCompleteApptClick appointment: ',
    //   appointment
    // );
    setIsSaving(true);

    try {
      await markAppointment(appointment.id, 'completed', '', () => {
        setIsSaving(false);
      });
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }

    setShowConfirmCompleteApptModal(false);
    // setAppointmentOptionsOpen(false);
    setAnchorEl(null);
  };

  //   console.log('AppointmentOptionsMenu isSaving: ', isSaving);

  return (
    <>
      {/* NOTE: DynamicConfirmModal has replaced the custom Backdrop component commented out below */}
      <DynamicConfirmModal
        showDynamicConfirmModal={showConfirmCancelApptModal}
        setShowDynamicConfirmModal={setShowConfirmCancelApptModal}
        zIndex={100002}
        title={'Cancel Appointment'}
        subtitle={'Do you want to cancel this appointment?'}
        // subtitleColor={dynamicModalObj?.subtitleColor}
        // icon={dynamicModalObj?.icon}
        // iconBackground={dynamicModalObj?.iconBackground}
        // confirmButtonColor={'#fe7283'}
        confirmButtonColor={'#E35050'}
        isSaving={isSaving}
        onConfirm={() => {
          handleConfirmCancelApptClick(appointment);
        }}
      ></DynamicConfirmModal>
      <DynamicConfirmModal
        showDynamicConfirmModal={showConfirmCompleteApptModal}
        setShowDynamicConfirmModal={setShowConfirmCompleteApptModal}
        zIndex={100002}
        title={'Complete Appointment'}
        subtitle={'Do you want to mark this appointment as complete (sat)?'}
        // subtitleColor={dynamicModalObj?.subtitleColor}
        // icon={dynamicModalObj?.icon}
        // iconBackground={dynamicModalObj?.iconBackground}
        // confirmButtonColor={dynamicModalObj?.confirmButtonColor}
        isSaving={isSaving}
        onConfirm={() => {
          handleConfirmCompleteApptClick(appointment, user);
        }}
      ></DynamicConfirmModal>

      {/* NOTE: CustomMenu and CustomMenuItem components have replaced the custom options menu commented out below */}
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <CustomMenuItem
          sx={{
            background: 'white',
            color: 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowConfirmCompleteApptModal(true);
            setAnchorEl(null);
          }}
          disableRipple={true}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography>Sat</Typography>
            {/* <GamifyIcon icon={'role'} color={'black'}></GamifyIcon> */}
          </Box>
        </CustomMenuItem>
        <CustomMenuItem
          sx={{
            background: 'white',
            color: 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            console.log('Reschedule clicked');
            // setAnchorEl(null);
          }}
          disableRipple={true}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography>Reschedule</Typography>
            {/* <GamifyIcon
              icon={
                user.status === 'active' ? 'deactivateUser' : 'activateUser'
              }
              color={user.status === 'active' ? '' : 'black'}
            ></GamifyIcon> */}
          </Box>
        </CustomMenuItem>
        <CustomMenuItem
          sx={{
            background: 'white',
            color: 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleCancelApptClick();
            setAnchorEl(null);
          }}
          disableRipple={true}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              style={{
                color: '#e35050',
              }}
            >
              Cancel Appointment
            </Typography>
            {/* <GamifyIcon
              icon={
                user.status === 'active' ? 'deactivateUser' : 'activateUser'
              }
              color={user.status === 'active' ? '' : 'black'}
            ></GamifyIcon> */}
          </Box>
        </CustomMenuItem>
      </CustomMenu>
      {/* <Box className={'option-menu-container'}>
        <Box className={'option-menu-sat-container'}>
          <Button
            disableRipple={true}
            className={'option-menu-top-button'}
            onClick={(e) => {
              e.stopPropagation();
              setShowConfirmCompleteApptModal(true);
            }}
          >
            <Typography className={'option-menu-text'}>Sat</Typography>
          </Button>
        </Box>
        <Box className={'option-menu-reschedule-container'}>
          <Button
            disableRipple={true}
            className={'option-menu-button'}
            onClick={(e) => {
              e.stopPropagation();
              console.log('Reschedule clicked');
            }}
          >
            <Typography className={'option-menu-text'}>Reschedule</Typography>
          </Button>
        </Box>
        <Box className={'option-menu-cancel-container'}>
          <Button
            disableRipple={true}
            className={'option-menu-bottom-button'}
            onClick={(e) => {
              e.stopPropagation();
              handleCancelApptClick();
            }}
          >
            <Typography className={'option-menu-cancel-text'}>
              Cancel Appointment
            </Typography>
          </Button>
        </Box>
      </Box> */}
    </>
  );
}
