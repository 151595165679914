import React, { useEffect, useState } from 'react';
import { View, Text } from '@aws-amplify/ui-react';
import {
  Box,
  Button,
  Switch,
  Typography,
  CircularProgress,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { fetchStageCategories } from './fetchStageCategories';
import { CustomTextField, CustomButton } from './styledComponents';
import './CRMConfigV2.css'; // Ensure this path is correct
import { hasUnsavedChanges } from '../utils/hasUnsavedChanges';
import { newCRMTemp, flexAttributesTemp } from '../utils/tempsUtils';
import DealStagesEditor from './DealStagesEditor';
import DealFormEditor from './DealFormEditor';
import {
  createStageCategory,
  updateStageCategory,
} from '../../../../graphql/mutations';
import CustomButtonWithInput from '../utils/CustomButtonWithInput';
import StageDispositionsEditor from './StageDispositionsEditor';
const { v4: uuidv4 } = require('uuid');

const CRMConfigV2 = ({ orgID }) => {
  const [stageCategories, setStageCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [copySelectedCategory, setCopySelectedCategory] = useState(null);
  const [configPage, setConfigPage] = useState('stages'); // enum "stages" | "forms" | "dispositions" (coming soon)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [defaultCategoryName, setDefaultCategoryName] = useState('');

  useEffect(() => {
    if (isLoading) return;
    const fetchData = async () => {
      setIsLoading(true);
      const categories = await fetchStageCategories(orgID);
      setStageCategories(
        categories.reduce((acc, el) => {
          acc[el.id] = el;
          return acc;
        }, {})
      );
      if (categories.length) {
        setSelectedCategory(categories[0]);
        setCopySelectedCategory(categories[0]);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [orgID]);

  useEffect(() => {
    const x = hasUnsavedChanges(copySelectedCategory, selectedCategory);
    setUnsavedChanges(x);
  }, [selectedCategory, copySelectedCategory]);

  const handleFormUpdates = async (forms) => {
    const newFlexAttributes = {
      ...selectedCategory.flexAttributes,
      config: forms,
    };
    const CRMUpdateObject = {
      ...selectedCategory,
      flexAttributes: newFlexAttributes,
    };
    const submitObject = { ...CRMUpdateObject };
    delete submitObject.dispositions;
    delete submitObject.stages;
    submitObject.flexAttributes = JSON.stringify(submitObject.flexAttributes);
    try {
      await API.graphql(
        graphqlOperation(updateStageCategory, { input: submitObject })
      );
      setStageCategories((p) => ({
        ...p,
        [CRMUpdateObject.id]: CRMUpdateObject,
      }));
      setCopySelectedCategory(CRMUpdateObject);
      setSelectedCategory(CRMUpdateObject);
    } catch (err) {
      console.log('ERROR updating CRM', err);
    }
  };

  const handleCRMUpdates = async () => {
    const CRMSubmitObj = {
      id: selectedCategory.id,
      isDefault: selectedCategory.isDefault,
      title: selectedCategory.title,
      enableDeal: selectedCategory.enableDeal,
      enablePindrop: selectedCategory.enablePindrop,
    };
    try {
      await API.graphql(
        graphqlOperation(updateStageCategory, { input: CRMSubmitObj })
      );
      setStageCategories((p) => ({
        ...p,
        [selectedCategory.id]: selectedCategory,
      }));
      setCopySelectedCategory(selectedCategory);
    } catch (err) {
      console.log('ERROR updating CRM', err);
    }
  };

  const handleUpdateStages = (stages) => {
    const updatedCRM = { ...selectedCategory };
    updatedCRM.stages = stages;
    setStageCategories((p) => ({
      ...p,
      [updatedCRM.id]: updatedCRM,
    }));
    setCopySelectedCategory(updatedCRM);
    setSelectedCategory(updatedCRM);
  };
  const handleDispositionUpdates = (dispositions) => {
    const updatedCRM = { ...selectedCategory };
    updatedCRM.dispositions = dispositions;
    setStageCategories((p) => ({
      ...p,
      [updatedCRM.id]: updatedCRM,
    }));
    setCopySelectedCategory(updatedCRM);
    setSelectedCategory(updatedCRM);
  };
  const handleCreateNewCRM = async (title) => {
    const newID = uuidv4();
    const newCRM = {
      ...newCRMTemp(orgID, flexAttributesTemp),
      title,
      id: newID,
    };
    try {
      await API.graphql(
        graphqlOperation(createStageCategory, { input: newCRM })
      );
      newCRM.flexAttributes = flexAttributesTemp;
      newCRM.stages = [];
      newCRM.dispositions = [];

      setStageCategories((p) => ({ ...p, [newID]: newCRM }));
      setSelectedCategory(newCRM);
    } catch (err) {
      console.log('Error creating new stgage Category (CRM): ', err);
    }
  };
  const handleSwitchChange = (field, value) => {
    if (field === 'isDefault' && value) {
      const currentDefault = getDefaultCategory();
      if (currentDefault && currentDefault.title !== selectedCategory.title) {
        setDefaultCategoryName(currentDefault.title);
        setDialogOpen(true);
        return;
      }
    }
    setSelectedCategory((prev) => ({ ...prev, [field]: value }));
  };

  const getDefaultCategory = () => {
    return Object.values(stageCategories).find(
      (category) => category.isDefault
    );
  };

  if (isLoading || !stageCategories) {
    return (
      <View>
        <CircularProgress />
      </View>
    );
  }

  const noDefaultCategory = !Object.values(stageCategories).some(
    (category) => category.isDefault
  );

  return (
    <View>
      <View className="categories_selection_container">
        {Object.values(stageCategories).map((category) => (
          <CustomButton
            style={{
              backgroundColor:
                category.id === selectedCategory.id ? '#FF6A00' : 'white',
            }}
            key={category.id}
            onClick={() => {
              setSelectedCategory(category);
              setCopySelectedCategory(category);
            }}
          >
            <Text
              className="category_selection_button_text"
              style={{
                fontSize: 'large',
                color: category.id === selectedCategory.id ? 'white' : 'black',
              }}
            >
              {category.title}
            </Text>
          </CustomButton>
        ))}
        <CustomButtonWithInput
          takenNames={Object.values(stageCategories).map((cat) => cat.title)}
          onSubmit={handleCreateNewCRM}
          style={{ marginLeft: 'auto' }}
        />
      </View>
      {selectedCategory && (
        <React.Fragment>
          <View className="category_general_info_container">
            <View>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Text
                    style={{ fontSize: 'large', fontWeight: 500 }}
                    onClick={() => console.log(selectedCategory)}
                  >
                    CRM Title
                  </Text>
                  <CustomTextField
                    value={selectedCategory?.title}
                    onChange={(e) =>
                      setSelectedCategory((p) => ({
                        ...p,
                        title: e.target.value,
                      }))
                    }
                  />
                </Box>
                {unsavedChanges && (
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ height: '45px' }}
                    onClick={handleCRMUpdates}
                  >
                    Save Changes
                  </Button>
                )}
              </Box>
              {noDefaultCategory && (
                <Typography color="error" variant="body2">
                  No default category selected.
                </Typography>
              )}
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedCategory.enableDeal || false}
                      onChange={(e) =>
                        handleSwitchChange('enableDeal', e.target.checked)
                      }
                    />
                  }
                  label="Enable Deal"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedCategory.enablePindrop || false}
                      onChange={(e) =>
                        handleSwitchChange('enablePindrop', e.target.checked)
                      }
                    />
                  }
                  label="Enable Pindrop"
                />
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius={4}
                  p={2}
                  mt={2}
                >
                  <Typography variant="body2" color="textSecondary">
                    Only one category can be set as default. Changing the
                    default category will require setting the current default to
                    non-default first.
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={selectedCategory.isDefault || false}
                        onChange={(e) =>
                          handleSwitchChange('isDefault', e.target.checked)
                        }
                      />
                    }
                    label="Set as Default"
                  />
                </Box>
              </Box>
            </View>
          </View>
          <Box style={{ flexDirection: 'row', display: 'flex' }}>
            <Box
              onClick={() => setConfigPage('stages')}
              className="stage_form_selector"
              style={{
                backgroundColor: configPage === 'stages' ? '#f0f0f0' : 'white',
              }}
            >
              <Typography
                style={{
                  color: configPage === 'stages' ? '#000' : '#888',
                  fontWeight: configPage === 'stages' ? 'bold' : 'normal',
                }}
              >
                Deal Stages
              </Typography>
            </Box>
            <Box
              onClick={() => setConfigPage('forms')}
              className="stage_form_selector"
              style={{
                backgroundColor: configPage === 'forms' ? '#f0f0f0' : 'white',
              }}
            >
              <Typography
                style={{
                  color: configPage === 'forms' ? '#000' : '#888',
                  fontWeight: configPage === 'forms' ? 'bold' : 'normal',
                }}
              >
                Forms
              </Typography>
            </Box>
            <Box
              onClick={() => setConfigPage('dispositions')}
              className="stage_form_selector"
              style={{
                backgroundColor:
                  configPage === 'dispositions' ? '#f0f0f0' : 'white',
              }}
            >
              <Typography
                style={{
                  color: configPage === 'dispositions' ? '#000' : '#888',
                  fontWeight: configPage === 'dispositions' ? 'bold' : 'normal',
                }}
              >
                Dispositions
              </Typography>
            </Box>
          </Box>
          {configPage === 'stages' && (
            <DealStagesEditor
              dealStages={selectedCategory.stages}
              stageDispositions={selectedCategory.dispositions}
              updateStages={handleUpdateStages}
              orgID={orgID}
              categoryID={selectedCategory.id}
            />
          )}
          {configPage === 'forms' && (
            <DealFormEditor
              dealForms={selectedCategory.flexAttributes.config}
              stages={selectedCategory.stages}
              stageDispositions={selectedCategory.dispositions}
              updateForms={handleFormUpdates}
            />
          )}
          {configPage === 'dispositions' && (
            <StageDispositionsEditor
              dispositions={selectedCategory.dispositions}
              stageForms={Object.keys(selectedCategory.flexAttributes.config)}
              stages={selectedCategory.stages}
              updateDispositions={handleDispositionUpdates}
              orgID={orgID}
              categoryID={selectedCategory.id}
            />
          )}
        </React.Fragment>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Change Default Category'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`The category "${defaultCategoryName}" is currently set as the
            default. Please set it to non-default before setting another
            category as the default.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </View>
  );
};

export default CRMConfigV2;
