export const LIST_QUEST_MISSION_BY_QUEST = `
      query MyQuery($questID: ID = "7474c7d3-f8a5-47a0-9446-532a9acc5d00") {
        listQuestMissionByQuest(questID: $questID) {
          items {
            sequence
            mission {
              bracketWinner
              circles {
                items {
                  doneActionCount
                  circle {
                    id
                    name
                    imageName
                  }
                }
              }
            }
          }
        }
      }
    `;
