import React, { useRef } from 'react';
import { View, Text } from '@aws-amplify/ui-react';
import { Icon, Button } from '@mui/material';
import { Storage } from 'aws-amplify';
import { useFormikContext } from 'formik';
import Dropzone from 'react-dropzone';
import { LOG } from '../../common/Utilities';

const UploadMediaNewReward = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const uploadRef = useRef();

  async function pickImage(values, setFieldValue, photo) {
    const quality = 0.2;
    const result = {
      name: photo[0].name,
      mediaTypes: photo[0].type,
      quality,
    };

    if (!photo) {
      setFieldValue('uploadingPicture', false);
    } else {
      setFieldValue('uploadingPicture', true);
      try {
        const blob = photo[0];
        // LOG.debug ("result.uri: ", result.uri);
        const name = result.name.replace(/^.*[\\/]/, '');
        console.log('resulting NAME: ', name);
        // LOG.debug ("mimeType: ", mimeType);
        await Storage.put(name, blob, {
          contentType: result.mediaTypes,
        })
          .then((result) => LOG.debug(result))
          .catch((err) => LOG.debug(err));
        const VIDEO_EXTENSIONS = [
          'mov',
          'avi',
          'mp4',
          'mpgeg',
          'ogv',
          'ts',
          '3gp',
          '3g2',
        ];

        if (VIDEO_EXTENSIONS.includes(result.mediaTypes)) {
          setFieldValue('imageType', 'video');
        } else {
          setFieldValue('imageType', 'picture');
        }
        setFieldValue('imageName', result.name);
        setFieldValue('uploadingPicture', false);

        console.log('Image Type: ', result.mediaTypes);
        console.log('Image Name: ', result.name);
      } catch (err) {
        LOG.error(err);
      }
    }
  }
  let buttonLabel = '';
  if (global.appSettings.displayIconLabels) {
    buttonLabel = 'Upload Picture/Video';
  }
  if (props.uploadLabel) {
    buttonLabel = props.uploadLabel;
  }

  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles) => {
          pickImage(values, setFieldValue, acceptedFiles);
        }}
        onClick={() => uploadRef.current.click()}
      >
        {({ getRootProps, getInputProps }) => (
          <section
            style={{
              width: 500,
              height: 300,
              backgroundColor: '#FAC8B2',
              borderRadius: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <div
              {...getRootProps()}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 8,
              }}
            >
              <input
                ref={(ref) => (uploadRef.current = ref)}
                {...getInputProps()}
              />
              <img src="/images/image-icon.svg" height={20} width={20} />
              <Text fontWeight={600} color={'#FF6A00'} fontFamily={'Manrope'}>
                Add Image
              </Text>
            </div>
          </section>
        )}
      </Dropzone>

      {values.uploadingPicture ? (
        <Text marginBottom={20}>Uploading ...</Text>
      ) : (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Button
            icon={
              <Icon
                name="image"
                size={25}
                marginRight={2}
                color="black"
                type="font-awesome-5"
              />
            }
            title={buttonLabel}
            type="clear"
            onClick={() => {
              pickImage(values, setFieldValue, props.allowEditing);
            }}
          />
        </View>
      )}
    </>
  );
};

export default UploadMediaNewReward;
