import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  position: absolute;
  background-color: #fff;
  padding: 20px;
  height: 500px;
  width: 400px;
  background-color: #fff;
  border-radius: 16px;
  overflow-y: scroll;
  z-index: 999;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;

export const CloseIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const SearchInput = styled.input`
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 20px;
  border: 1px solid #e5e5e5;
  width: 100%;
  outline: none;
`;

export const GifList = styled.div`
  display: grid;
  grid-template-columns: repeat(
    3,
    1fr
  ); /* This will create three columns of equal width */
  gap: 20px;
`;

export const GifListItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;
