import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import React from 'react';
import './CustomThemeProvider.css';

const assembleCustomTheme = () => {
  return responsiveFontSizes(
    createTheme({
      palette: {
        background: {
          paper: '#FFFFFF',
          default: '#FFFFFF',
        },
        main: {
          main: 'var(--component-background)',
          contrastText: '#FFFFFF',
        },
        application: {
          main: 'var(--application-color-active)',
        },
      },
      components: {
        MuiPopover: {
          styleOverrides: {
            paper: {
              backgroundColor: 'var(--component-background)',
              padding: '5px',
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              backgroundColor: 'white',
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: 'var(--application-color-label-color)',
            },
          },
        },
      },
      typography: {
        allVariants: {
          fontFamily: "'Manrope', sans-serif",
        },
        button: {
          textTransform: 'none',
        },
      },
    })
  );
};

export default class CustomThemeProvider extends React.Component {
  render() {
    return (
      <ThemeProvider theme={assembleCustomTheme()}>
        {this.props.children}
      </ThemeProvider>
    );
  }
}
