import React from 'react';
import CustomSelectRewards from './CustomSelectRewards';
import NewGameState from '../../../_types/NewGameState';
import RewardType from '../../../_types/RewardType';
import CustomTextInput from '../../../custom-inputs/CustomTextInput';

interface Props {
  rewards: RewardType[];
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const IfPlacedInputs: React.FC<Props> = ({
  rewards,
  newGameState,
  onChangeNewGameState,
}) => {
  if (newGameState.rewardWhen !== 'mission') {
    return null;
  }

  if (newGameState.rewardCurrency === 'points') {
    return (
      <>
        <CustomTextInput
          label="1st Place Coins"
          value={newGameState.rewardCount1}
          onChange={(newValue) => {
            onChangeNewGameState({ ...newGameState, rewardCount1: newValue });
          }}
        />
        <CustomTextInput
          label="2nd Place Coins"
          value={newGameState.rewardCount2}
          onChange={(newValue) => {
            onChangeNewGameState({ ...newGameState, rewardCount2: newValue });
          }}
        />
        <CustomTextInput
          label="3rd Place Coins"
          value={newGameState.rewardCount3}
          onChange={(newValue) => {
            onChangeNewGameState({ ...newGameState, rewardCount3: newValue });
          }}
        />
        <CustomTextInput
          label="Participant Coins"
          value={newGameState.rewardCountP}
          onChange={(newValue) => {
            onChangeNewGameState({ ...newGameState, rewardCountP: newValue });
          }}
        />
      </>
    );
  }

  return (
    <>
      <CustomSelectRewards
        label="1st Place Reward"
        value={newGameState.rewardName1}
        onSelect={(reward: RewardType) => {
          console.log('reward', reward);
          onChangeNewGameState({
            ...newGameState,
            rewardID1: reward.id,
            rewardName1: reward.name,
            showCustomReward1: false,
          });
        }}
        items={rewards}
      />
      <CustomSelectRewards
        label="Participant Reward"
        value={newGameState.rewardNameP}
        onSelect={(reward: RewardType) => {
          onChangeNewGameState({
            ...newGameState,
            rewardIDp: reward.id,
            rewardNameP: reward.name,
            showCustomReward1: false,
          });
        }}
        items={rewards}
      />
      <CustomSelectRewards
        label="If Tied (Multiple people/teams have the same result)"
        value={newGameState.rewardName1t}
        onSelect={(reward: RewardType) => {
          onChangeNewGameState({
            ...newGameState,
            rewardID1t: reward.id,
            rewardName1t: reward.name,
            showCustomReward1: false,
          });
        }}
        items={rewards}
      />
    </>
  );
};

export default IfPlacedInputs;
