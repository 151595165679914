import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import getUserAvatar from '../../common/utils/getUserAvatar';

const fetchProfile = async (userID) => {
  try {
    const getUserNameQuery = gql`
      query getUser($userID: ID = "f3104592-39d1-42f3-ab02-430c4200b9b8") {
        getUser(id: $userID) {
          id
          extID
          name
          nameVisibility
          initials
          birthdate
          birthdateVisibility
          createdAt
          createdBy
          lastUpdatedBy
          imageName
          imageType
          title
          username
          functionalRoles
          status
          email
          emailVisibility
          location
          locationVisibility
          phoneNumber
          phoneNumberVisibility
          pictureVisibility
          pronoun
          role
          orgID
          levelID
          avatarID
          orgs {
            items {
              id
              status
              memberType
              orgID
              orgPoints
            }
          }
          circles {
            items {
              circleID
              circle {
                name
                isLeague
              }
            }
          }
          circleLevel {
            name
          }
        }
      }
    `;

    // let user;
    const getUserRet = await API.graphql({
      query: getUserNameQuery,
      variables: { userID },
    });
    const user = getUserRet.data.getUser;

    user.avatarFile = await getUserAvatar(user.imageName, user.imageType, true);
    user.league = '';
    user.points = user.orgs.items[0].orgPoints;

    for (const myOrg of user.orgs.items) {
      if (myOrg.orgID === global.me.orgID) {
        user.memberType = myOrg.memberType;
        user.orgMemberID = myOrg.id;
      }
    }

    const functionalRolesArr = user.functionalRoles;

    // Format the functional role
    let userFunctionalRole = '';
    if (functionalRolesArr) {
      for (const role of functionalRolesArr) {
        // Skip if blank role
        if (role === '') continue;

        // Put & for multiple roles
        if (userFunctionalRole !== '') {
          userFunctionalRole += ' & ';
        }
        userFunctionalRole += role[0].toUpperCase() + role.slice(1);
      }
    }

    user.functionalRole = userFunctionalRole;

    const memberType = user.orgs?.items[0]?.memberType;
    if (memberType) user.memberType = memberType;

    for (const circle of user.circles.items) {
      if (circle.circle != null && circle.circle.isLeague) {
        user.league = circle.circle.name;
      }
    }
    delete user.circles;
    return user;
  } catch (err) {
    console.error('fetchProfile error: ', err);
  }
};

export default fetchProfile;
