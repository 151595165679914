import React, { useEffect, useState } from 'react';
import AddCommentView from './AddComment.view';
import { useGetUserInformationQuery } from '../../../store/api/GraphQlQuery';
import { Skeleton } from '@mui/material';
import AddCommentArgs from '../_types/AddCommentArgs';
import { Storage } from 'aws-amplify';
import { LOG } from '../../../common/Utilities';
import { GamifyToast } from '../../../common/CustomToasts';
import getMyPersons from '../feed-helpers/getMyPersons';
import MyPerson from '../_types/MyPerson';

interface Props {
  onAddComment: (args: AddCommentArgs) => Promise<void>;
  activityFeedID: string;
  onChangeIsEditing: (newIsEditing: boolean) => void;
}

const AddCommentContainer = ({
  onAddComment,
  activityFeedID,
  onChangeIsEditing,
}: Props) => {
  const { data, isLoading: isLoadingGetUserInformation } =
    useGetUserInformationQuery({});
  const [isLoadingGetCircles, setIsLoadingGetCircles] = useState(true);
  const INITIAL_COMMENT = {
    commentActivityFeedId: activityFeedID,
    content: '',
    imageName: '',
    imageType: '',
    isDeleted: false,
    orgID: data?.me.orgID || '',
    userID: data?.me.id || '',
    userName: data?.me.username || '',
  };
  const [comment, setComment] = useState<AddCommentArgs>(INITIAL_COMMENT);
  const [filePreview, setFilePreview] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [openGif, setOpenGif] = useState(false);
  const [myPersons, setMyPersons] = useState<MyPerson[]>([]);

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      try {
        const selectedFile = files[0];
        const filePreview = URL.createObjectURL(selectedFile);

        let imageType = '';
        const fileType = selectedFile.type.split('/')[0];
        if (fileType === 'video') {
          imageType = 'video';
        } else if (fileType === 'image') {
          imageType = 'picture';
        }

        setComment({ ...comment, imageType, imageName: selectedFile.name });
        setSelectedFile(selectedFile);
        setFilePreview(filePreview);
        onChangeIsEditing(true);
      } catch (error) {}
    }
  };

  const onSaveComment = async () => {
    try {
      if (comment.content.length < 1) {
        GamifyToast.error(
          'You must enter at least 2 characters for a comment.'
        );
        return;
      }

      await onAddComment({ ...comment });

      if (selectedFile) {
        await Storage.put(selectedFile.name, selectedFile, {
          contentType: selectedFile.type,
        });
      }
      setComment(INITIAL_COMMENT);
      setFilePreview(null);
      onChangeIsEditing(false);
    } catch (error) {
      GamifyToast.error('Something went wrong posting your comment.');
      LOG.error(error);
    }
  };

  const onChangeComment = (comment: AddCommentArgs) => {
    if (comment.imageType === 'gif_url') {
      setFilePreview(comment.imageName);
    }

    setComment(comment);
  };

  const onOpenCloseGif = () => {
    setOpenGif(!openGif);
  };

  useEffect(() => {
    const fetchMyPersons = async () => {
      if (isLoadingGetUserInformation || !data) {
        return;
      }

      try {
        const myPersons = await getMyPersons({
          orgID: data.me.orgID,
        });

        setMyPersons(myPersons);
      } catch (error) {
        LOG.debug(error);
      } finally {
        setIsLoadingGetCircles(false);
      }
    };

    fetchMyPersons();
  }, [isLoadingGetUserInformation, data]);

  if (isLoadingGetUserInformation || isLoadingGetCircles || !data) {
    return (
      <Skeleton
        variant="rectangular"
        width={760}
        height={100}
        style={{ marginBottom: 4 }}
      />
    );
  }

  return (
    <AddCommentView
      openGif={openGif}
      onOpenCloseGif={onOpenCloseGif}
      filePreview={filePreview}
      comment={comment}
      me={data?.me}
      onUpload={onUpload}
      onChangeComment={onChangeComment}
      onSaveComment={onSaveComment}
      myPersons={myPersons}
      onRemoveFilePreview={() => {
        setFilePreview(null);
        onChangeIsEditing(false);
      }}
      onChangeIsEditing={onChangeIsEditing}
    />
  );
};

export default AddCommentContainer;
