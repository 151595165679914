import React, { useEffect, useState } from 'react';
import { View, Text } from '@aws-amplify/ui-react';
import {
  Box,
  List,
  CircularProgress,
  ListItem,
  Snackbar,
  Button,
} from '@mui/material';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import './kpis.css';
import CreateGoalDefinition from './CreateGoalDefinition';

export default function KpisConfig(props) {
  const { orgID } = props;
  const [KPIRules, setKPIRules] = useState([]);
  const [rulesProp, setRulesProp] = useState(null);
  const [snackbarObj, setSnackbarObj] = useState({ open: false, message: '' });
  const [currentRuleID, setCurrentRuleID] = useState(null);
  const [openEditor, setOpenEditor] = useState(false);

  async function fetchKPIGoals() {
    const myQuery = gql`
      query MyQuery($orgID: ID!, $nextToken: String = null) {
        listKPIRules(
          filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
          limit: 10000
          nextToken: $nextToken
        ) {
          nextToken
          items {
            id
            title
            order
            action
            sourceType
            triggerID
            formula
            dimensions
            isHidden
            stage {
              title
            }
            disposition {
              title
            }
            goals {
              items {
                id
              }
            }
          }
        }
      }
    `;
    let nextToken = null;
    const KPIRules = [];
    do {
      const listKPIRulesRet = await API.graphql({
        query: myQuery,
        variables: { orgID, nextToken },
      });
      KPIRules.push(...listKPIRulesRet.data.listKPIRules.items);
      nextToken = listKPIRulesRet.data.listKPIRules.nextToken;
    } while (nextToken !== null);

    const sortedKPIRules = KPIRules.sort((a, b) => {
      if (a.sourceType.toLowerCase() < b.sourceType.toLowerCase()) return -1;
      if (a.sourceType.toLowerCase() > b.sourceType.toLowerCase()) return 1;
      return 0;
    });
    setRulesProp(() => sortedKPIRules);
    let lastSourceType = '';
    const groupedKPIRules = [];
    for (const KPIRule of sortedKPIRules) {
      if (KPIRule.sourceType !== lastSourceType) {
        let groupTitle;
        if (KPIRule.sourceType === 'contact_type') {
          groupTitle = 'Contact Type';
        } else if (KPIRule.sourceType === 'disposition') {
          groupTitle = 'Disposition';
        } else if (KPIRule.sourceType === 'stage') {
          groupTitle = 'Deal Stage';
        } else if (KPIRule.sourceType === 'flexfield') {
          groupTitle = 'Fields';
        } else if (KPIRule.sourceType === 'manual') {
          groupTitle = 'Manual';
        } else if (KPIRule.sourceType === 'rule') {
          groupTitle = 'Rule';
        }
        groupedKPIRules[groupedKPIRules.length] = {
          title: groupTitle,
          count: KPIRule.goals.items.length,
          rules: [],
        };
        lastSourceType = KPIRule.sourceType;
      }
      groupedKPIRules[groupedKPIRules.length - 1].rules[
        groupedKPIRules[groupedKPIRules.length - 1].rules.length
      ] = KPIRule;
    }
    setKPIRules(groupedKPIRules);
  }

  const editRule = (e) => {
    e.stopPropagation();
    setCurrentRuleID(e.currentTarget.getAttribute('name'));
    setOpenEditor(true);
  };
  const createNew = () => {
    setCurrentRuleID(() => null);
    setOpenEditor(true);
  };

  const snackbarMessage = (message) => {
    setSnackbarObj(() => ({
      open: true,
      message,
    }));
    setTimeout(() => {
      setSnackbarObj(() => ({
        open: false,
        message: '',
      }));
    }, 3000);
  };

  useEffect(() => {
    fetchKPIGoals();
  }, []);

  if (!KPIRules.length) return <CircularProgress color="inherit" />;

  return (
    <View>
      {openEditor && (
        <CreateGoalDefinition
          open={openEditor}
          closer={setOpenEditor}
          orgID={orgID}
          ruleID={currentRuleID}
          reloadKPIs={fetchKPIGoals}
          snackbarMessage={snackbarMessage}
          rulesProp={rulesProp}
        />
      )}
      <Box id="title-box">
        <Text
          style={{
            fontWeight: 'bold',
            padding: '0px',
            fontSize: '2rem',
          }}
        >{`KPI's Configuration`}</Text>
        <Button variant="contained" onClick={createNew}>
          Create
        </Button>
      </Box>
      <List id="kpi-list">
        {KPIRules.map((el, i) => (
          <Box key={i}>
            <ListItem
              key={`${el.title}-${i}`}
              style={
                i === 0
                  ? {
                      borderTopLeftRadius: '15px',
                      borderTopRightRadius: '15px',
                    }
                  : {}
              }
              className="label-list-item"
            >
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                  width: '100%',
                  margin: '0',
                }}
              >
                {el.title}
              </Text>
            </ListItem>
            {el.rules.map((rule, r) => (
              <ListItem
                key={rule.id}
                name={rule.id}
                // style={{ borderBottom: '1px solid lightgray' }}
                className="rule-list-item"
                onClick={editRule}
              >
                <Box>
                  <Box display="flex" alignItems="center">
                    <Text
                      style={{
                        // color: 'white',
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        marginBottom: '3px',
                        marginRight: '8px',
                      }}
                    >
                      {rule.title}
                    </Text>

                    {rule.order ? (
                      <Text color="gray">{`order: ${rule.order}`}</Text>
                    ) : (
                      <Text color="lightgray">order not set</Text>
                    )}
                  </Box>
                  {rule.sourceType === 'stage' && (
                    <React.Fragment>
                      {rule.stage !== null ? (
                        <Text
                          style={{
                            color: 'gray',
                            fontSize: '1.1rem',
                            margin: '2px 0',
                          }}
                        >
                          stage: {rule.stage.title}
                        </Text>
                      ) : (
                        <Text
                          style={{
                            color: 'gray',
                            fontSize: '1.1rem',
                            margin: '2px 0',
                          }}
                        >
                          stage: {rule.title}
                        </Text>
                      )}
                    </React.Fragment>
                  )}
                  {rule.sourceType === 'disposition' &&
                    rule.disposition !== null && (
                      <Text
                        style={{
                          color: 'gray',
                          fontSize: '1.1rem',
                          margin: '2px 0',
                        }}
                      >
                        disposition: {rule.disposition.title}
                      </Text>
                    )}
                  {rule.sourceType === 'contact_type' && (
                    <Text
                      style={{
                        color: 'gray',
                        fontSize: '1.1rem',
                        margin: '2px 0',
                      }}
                    >
                      contact: {rule.title}
                    </Text>
                  )}
                  {rule.sourceType === 'flexfield' && (
                    <Text
                      style={{
                        color: 'gray',
                        fontSize: '1.1rem',
                        margin: '2px 0',
                      }}
                    >
                      Field: {rule.title}
                    </Text>
                  )}
                  {rule.sourceType === 'manual' && (
                    <Text
                      style={{
                        color: 'lightGray',
                        fontSize: '1.1rem',
                        margin: '2px 0',
                      }}
                    >
                      {rule.title}
                    </Text>
                  )}
                  <Text style={{ color: 'gray', margin: '2px 0' }}>
                    {rule.formula}
                  </Text>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: 5,
                    }}
                  >
                    <Text style={{ color: 'gray', paddingRight: 4 }}>[</Text>
                    {rule.dimensions.map((dimension, d) => (
                      <Text key={d} style={{ color: 'gray', paddingRight: 4 }}>
                        {r > 0 ? ',' : ''}
                        {dimension}
                      </Text>
                    ))}
                    <Text style={{ color: 'gray' }}>]</Text>
                  </Box>
                </Box>
              </ListItem>
            ))}
          </Box>
        ))}
      </List>
      <Snackbar
        open={snackbarObj.open}
        message={snackbarObj.message}
        severity="success"
      />
    </View>
  );
}
