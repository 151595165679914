import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  margin-top: 20px;
  width: 500px;
`;

export const AddContestantArea = styled.div<{ shouldAjustHeight: boolean }>`
  border-radius: 10px;
  width: 100%;
  padding: 16px;
  background-color: #fff;
  display: flex;
  gap: 10px;
  height: ${({ shouldAjustHeight }) =>
    shouldAjustHeight ? 'max-content' : '100px'};
  cursor: pointer;
  flex-wrap: wrap;
`;

export const CustomItemButton = styled.div<{ backgroundColor: string }>`
  cursor: pointer;
  border-radius: 10px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => `${backgroundColor}`};
  text-align: center;
  gap: 6px;
  width: fit-content;
  height: fit-content;
`;

export const CustomIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const CustomText = styled.div`
  font-family: Manrope;
  font-size: 14px;
`;

export const ContestantItem = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? '#F0F0F3' : 'transparent'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 6px;
`;

export const ContestantItemText = styled.div`
  font-size: 14px;
`;

export const ContestantItemCheckIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const TypeList = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 30px 10px 20px 10px;
`;

export const TypeListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TypeItemIndicator = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#FF6A00' : 'transparent')};
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-top: 6px;
`;

export const TypeListItemText = styled.div<{ isActive: boolean }>`
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  color: ${({ isActive }) => (isActive ? '#FF6A00' : '#737373')};
  cursor: pointer;
  font-size: 14px;
`;
