import React, { useState } from 'react';
import {
  InputBodyContainer,
  InputTitleContainer,
  InputTitleIcon,
  InputTitleText,
} from '../../NewGame.styles';
import {
  CustomItemButton,
  CustomIcon,
  CustomText,
  AddContestantArea,
  ContestantItem,
  ContestantItemCheckIcon,
  ContestantItemText,
  ViewContainer,
  TypeItemIndicator,
  TypeList,
  TypeListItem,
  TypeListItemText,
} from './AddContestants.styles';
import { CustomInputLabel } from '../../custom-inputs/CustomInputs.styles';
import CustomModal from '../../custom-inputs/CustomModal';
import NewGameState from '../../_types/NewGameState';
import Contestant from '../../../_types/Contestant';
import Scope from '../../_types/Scope';
import { GamifyIcon } from '../../../../../components/GamifyIcon';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  contestants: Contestant[];
}

const types = [
  { label: 'Person', value: 'person' },
  { label: 'Team', value: 'circle' },
];

const AddContestantsView: React.FC<Props> = ({
  contestants,
  newGameState,
  onChangeNewGameState,
}) => {
  const filteredContestants = contestants.filter(
    (contestant) => !newGameState.assigneeIDs.includes(contestant.value)
  );

  const [openModal, setOpenModal] = useState(false);
  const [initialSelectedContestants, setInitialSelectedContestants] = useState<
    Contestant[]
  >([]);

  const checkIsInitialContestantSelected = (
    contestant: Contestant
  ): boolean => {
    return initialSelectedContestants.some(
      (initialContestant) =>
        initialContestant.label === contestant.label &&
        initialContestant.value === contestant.value
    );
  };

  const onRemoveContestant = (contestant: Contestant) => {
    const newAssigneeIds = newGameState.assigneeIDs.filter(
      (id) => id !== contestant.value
    );
    onChangeNewGameState({ ...newGameState, assigneeIDs: newAssigneeIds });
  };

  const onSelectContestant = (selectedContestant: Contestant) => {
    // If already selected
    if (checkIsInitialContestantSelected(selectedContestant)) {
      setInitialSelectedContestants((prev) => {
        return prev.filter(
          (contestant) => contestant.value !== selectedContestant.value
        );
      });
      return;
    }

    setInitialSelectedContestants((prev) => [...prev, selectedContestant]);
  };

  const onAddContestants = () => {
    const newAssigneeIds = initialSelectedContestants.map(
      (contestant) => contestant.value
    );
    onChangeNewGameState({
      ...newGameState,
      assigneeIDs: [...newGameState.assigneeIDs, ...newAssigneeIds],
    });

    setOpenModal(false);
    setInitialSelectedContestants([]);
  };

  const onChangeType = (newType: any) => {
    onChangeNewGameState({
      ...newGameState,
      scope: newType as Scope,
      isCircleOnly: (newType.value as Scope) === 'circle',
      assigneeIDs: [],
      rewardDisposition: 'winner',
    });
  };
  console.log('newGameState.assigneeIDs', newGameState.assigneeIDs);
  console.log('contestants', contestants);
  return (
    <ViewContainer>
      <CustomModal
        isOpen={openModal}
        title="Add Contestants"
        onClose={() => setOpenModal(false)}
      >
        <TypeList>
          {types.map((type) => {
            const isActive = newGameState.scope === type.value;

            return (
              <TypeListItem
                key={type.value}
                onClick={() => {
                  onChangeType(type.value);
                }}
              >
                <TypeListItemText isActive={isActive}>
                  {type.label}
                </TypeListItemText>
                <TypeItemIndicator isActive={isActive} />
              </TypeListItem>
            );
          })}
        </TypeList>
        <div
          onClick={onAddContestants}
          style={{
            textAlign: 'right',
            fontWeight: 500,
            fontFamily: 'Space Grotesk',
            color: '#FF6A00',
            cursor: 'pointer',
            marginBottom: 10,
          }}
        >
          Add {`(${initialSelectedContestants.length})`}
        </div>
        {filteredContestants.length > 0 ? (
          filteredContestants.map((contestant, index) => {
            return (
              <ContestantItem
                key={index}
                onClick={() => {
                  onSelectContestant(contestant);
                }}
                isSelected={checkIsInitialContestantSelected(contestant)}
              >
                <ContestantItemText>{contestant.label}</ContestantItemText>
                {checkIsInitialContestantSelected(contestant) && (
                  <ContestantItemCheckIcon src="/images/purple-check.svg" />
                )}
              </ContestantItem>
            );
          })
        ) : (
          <div>No contestant available</div>
        )}
      </CustomModal>
      <InputTitleContainer>
        <InputTitleText>Contestants</InputTitleText>
        <InputTitleIcon src="/images/about.svg" />
      </InputTitleContainer>
      <InputBodyContainer>
        <CustomInputLabel>Add Contestants</CustomInputLabel>
        <AddContestantArea
          shouldAjustHeight={Boolean(newGameState.assigneeIDs)}
        >
          <CustomItemButton
            backgroundColor="#FAC8B2"
            onClick={() => setOpenModal(true)}
          >
            {/* <CustomIcon src="/images/plus-purple.svg" /> */}
            <GamifyIcon
              icon={'add'}
              color={'#FF6A00'}
              backgroundColor={'transparent'}
              height={24}
              width={24}
            ></GamifyIcon>
            <CustomText>Add</CustomText>
          </CustomItemButton>
          {contestants.map((contestant, index) => {
            if (!newGameState.assigneeIDs.includes(contestant.value))
              return null;

            return (
              <CustomItemButton
                key={index}
                backgroundColor="#F0F0F3"
                onClick={() => onRemoveContestant(contestant)}
              >
                <CustomText>{contestant.label.split(' ')[0]}</CustomText>
                <CustomIcon src="/images/close-gray.svg" />
              </CustomItemButton>
            );
          })}
        </AddContestantArea>
      </InputBodyContainer>
    </ViewContainer>
  );
};

export default AddContestantsView;
