import React from 'react';
import { Box } from '@mui/material';
import {
  Bracket,
  IRenderSeedProps,
  Seed,
  SeedItem,
  SeedTeam,
} from 'react-brackets';
import {
  SeedValue,
  SeedValueImage,
  SeedValueText,
  // ViewContainer,
} from './DisplayBracket.styles';
import { NavigateFunction, useNavigate } from 'react-router';

const CustomSeed = ({
  seed,
  breakpoint,
  gameScope,
  navigate,
}: IRenderSeedProps & { navigate: NavigateFunction; gameScope: string }) => {
  // breakpoint passed to Bracket component
  // to check if mobile view is triggered or not
  // mobileBreakpoint is required to be passed down to a seed
  console.log('seed: ', seed);
  return (
    <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 14 }}>
      <SeedItem style={{ backgroundColor: '#fff' }}>
        <div>
          <SeedTeam
            style={{
              color: '#000',
              backgroundColor: '#fff',
              borderBottom: '2px solid #f0f0f3',
              fontWeight: 600,
            }}
          >
            {/* NOTE: Need to account for users vs teams page navigation... need seed to have a boolean for team or user */}
            <SeedValue
              onClick={() =>
                gameScope === 'person'
                  ? navigate(`/people/stats/${seed.teams[0].id}`, {
                      state: { games: true },
                    })
                  : navigate(`/teams/${seed.teams[0].id}`, {
                      state: { games: true },
                    })
              }
            >
              <SeedValueImage src={seed.teams[0]?.avatar} />
              <SeedValueText>{seed.teams[0]?.name || '-----'}</SeedValueText>
            </SeedValue>
          </SeedTeam>
          <SeedTeam
            style={{
              fontWeight: 600,
              color: '#000',
              backgroundColor: '#fff',
              boxShadow: 'none',
            }}
          >
            <SeedValue
              onClick={() =>
                gameScope === 'person'
                  ? navigate(`/people/stats/${seed.teams[1].id}`, {
                      state: { games: true },
                    })
                  : navigate(`/teams/${seed.teams[0].id}`, {
                      state: { games: true },
                    })
              }
            >
              <SeedValueImage src={seed.teams[1]?.avatar} />
              <SeedValueText style={{ fontWeight: 500 }}>
                {seed.teams[1]?.name || '-----'}
              </SeedValueText>
            </SeedValue>
          </SeedTeam>
        </div>
      </SeedItem>
    </Seed>
  );
};

interface Props {
  brackets: any;
  gameScope: string;
}

const DisplayBracketView: React.FC<Props> = ({ brackets, gameScope }) => {
  const navigate = useNavigate();

  return (
    // <ViewContainer>
    <Box
      style={{
        marginTop: '60px',
      }}
    >
      <Bracket
        rounds={brackets}
        renderSeedComponent={(props) => (
          <CustomSeed {...props} gameScope={gameScope} navigate={navigate} />
        )}
      />
    </Box>
    // </ViewContainer>
  );
};

export default DisplayBracketView;
