import React, { useState } from 'react';
import { Box, ButtonGroup, Button, TextField } from '@mui/material';
import DisplayCharts from '../charts/DisplayCharts';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './Index.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    success: {
      main: '#827ffc',
    },
  },
  typography: {
    allVariants: {
      fontFamily: "'Manrope', sans-serif",
      color: 'white',
    },
    button: {
      textTransform: 'none',
    },
  },
});

export default function SelectChart(props) {
  console.log('SELECT CHART PROPS: ', props);
  const [startDate, setStartDate] = useState('2022-07-20');
  const [endDate, setEndDate] = useState('2022-12-31');
  const [graphInterval, setGraphInterval] = useState('week');
  const [chartData, setChartData] = useState({
    startDate: '2022-07-20',
    endDate: '2022-12-31',
    interval: 'week',
  });

  return (
    <Box className={'settings-and-chart-box'}>
      <Box className={'chart-settings-overlay'}>
        <Box className={'button-group-overlay'}>
          <ThemeProvider theme={theme}>
            <ButtonGroup
              className={'button-group button-group-date'}
              variant="contained"
              aria-label="outlined primary button group"
            >
              <Button
                className={'button-in-group button-in-group-date'}
                color={graphInterval === 'day' ? 'success' : 'primary'}
                onClick={() => {
                  setGraphInterval('day');
                }}
              >
                Day
              </Button>
              <Button
                className={'button-in-group button-in-group-date'}
                color={graphInterval === 'week' ? 'success' : 'primary'}
                onClick={() => {
                  setGraphInterval('week');
                }}
              >
                Week
              </Button>
              <Button
                className={'button-in-group button-in-group-date'}
                color={graphInterval === 'month' ? 'success' : 'primary'}
                onClick={() => {
                  setGraphInterval('month');
                }}
              >
                Month
              </Button>
              <Button
                className={'button-in-group button-in-group-date'}
                color={graphInterval === 'year' ? 'success' : 'primary'}
                onClick={() => {
                  setGraphInterval('year');
                }}
              >
                Year
              </Button>
            </ButtonGroup>
          </ThemeProvider>

          <Box className={'date-picker-overlay'}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ThemeProvider theme={theme}>
                <DatePicker
                  className="react-datepicker"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField className={'date-picker-text'} {...params} />
                  )}
                />
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <DatePicker
                  className="react-datepicker"
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField className={'date-picker-text'} {...params} />
                  )}
                />
              </ThemeProvider>
            </LocalizationProvider>
          </Box>
        </Box>
        <Button
          className={'refresh-charts-button'}
          onClick={() => {
            setChartData({
              interval: graphInterval,
              startDate,
              endDate,
            });
          }}
        >
          Refresh Charts
        </Button>
      </Box>

      <>
        <Box>
          <DisplayCharts
            userId={props.userId}
            startDate={chartData.startDate}
            endDate={chartData.endDate}
            interval={chartData.interval}
          />
        </Box>
      </>
    </Box>
  );
}
