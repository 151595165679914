import { API } from 'aws-amplify';
import { getCircle } from '../../../graphql/queries';
import getUserAvatar from '../../common/utils/getUserAvatar';

const fetchTeam = async (teamId) => {
  try {
    const res = await API.graphql({
      query: getCircle,
      variables: { id: teamId },
    });
    const teamRes = res.data.getCircle;

    // team members are set in fetchTeamMembers

    teamRes.avatarFile = await getUserAvatar(
      teamRes.imageName,
      teamRes.imageType,
      true
    );

    // // this prevents team image updates when navigating to new team page from team tree
    // if (myTeam) {
    //   teamRes.imageFile = myTeam.imageFile;
    // } else {
    //   teamRes.imageFile = await getUserAvatar(
    //     teamRes.imageName,
    //     teamRes.imageType,
    //     true
    //   );
    // }

    if (teamRes.children.items.length > 0) {
      for (const childTeam of teamRes.children.items) {
        childTeam.avatarFile = await getUserAvatar(
          childTeam.imageName,
          childTeam.imageType,
          true
        );
      }
    }
    return teamRes;
  } catch (err) {
    console.error('fetchTeam error: ', err);
  }
};

export default fetchTeam;
