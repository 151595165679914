import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';

export default class SummarizeKPIForAllRepository {
  static async fetchKPIs(
    startDate,
    endDate,
    interval,
    userId,
    kpiRuleId,
    title
  ) {
    console.log('fetchKPIs UserID: ', userId);
    console.log('fetch KPIs Start Date: ', startDate);
    console.log('fetch KPIs End Date: ', endDate);
    console.log('fetch KPIs interval: ', interval);
    console.log('fetch KPIs kpiRuleId: ', kpiRuleId);
    console.log('fetch KPIs Title: ', title);
    console.log('fetch KPIs OrgID: ', global.me.orgID);
    if (userId === null) {
      console.log('WE MAKE IT HERE');
      const res = await API.graphql({
        query: queries.summarizeKPIForAll,
        variables: {
          orgID: global.me.orgID,
          interval,
          startDate,
          endDate,
          KPIRuleID: kpiRuleId,
        },
      });
      console.log("WE DON'T MAKE IT HERE");
      const data = JSON.parse(res.data.summarizeKPIForAll);
      data.title = title;
      console.log('&&&data: ', data);
      return data;
    }
    console.log('SAME ISSUE IF WE HAVE A DEFINED USER ID');
    const res = await API.graphql({
      query: queries.summarizeKPIForUser,
      variables: {
        orgID: global.me.orgID,
        interval,
        startDate,
        endDate,
        KPIRuleID: kpiRuleId,
        userID: userId,
      },
    });
    const data = JSON.parse(res.data.summarizeKPIForUser);
    data.title = title;
    console.log('&&&data: ', data);
    return data;
  }
}
