const getImageFromS3 = (imageName: string) => {
  try {
    const imageData =
      'https://d22xeeaijfewl2.cloudfront.net/public/' +
      encodeURIComponent(imageName);

    if (imageData) {
      return imageData;
    }
    return '';
  } catch (err) {
    console.error(err);
    return '';
  }
};

export default getImageFromS3;
