import React from 'react';
import Rules from '../new-game-inputs/Rules';
import Rewards from '../new-game-inputs/Rewards';
import NewGameState from '../_types/NewGameState';
import Bracket from '../new-game-inputs/Bracket';
import Rounds from '../new-game-inputs/Rounds';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  setShouldDisableNextOrFinishButton: any;
}

const Step2: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
  setShouldDisableNextOrFinishButton,
}) => {
  if (newGameState.contestType === 'bracket_manual') {
    return (
      <>
        <Bracket
          newGameState={newGameState}
          onChangeNewGameState={onChangeNewGameState}
        />
        <Rounds
          newGameState={newGameState}
          onChangeNewGameState={onChangeNewGameState}
          setShouldDisableNextOrFinishButton={
            setShouldDisableNextOrFinishButton
          }
        />
      </>
    );
  }

  return (
    <>
      <Rules
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
      />
      <Rewards
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
      />
    </>
  );
};

export default Step2;
