import React from 'react';
import {
  Box,
  Button,
  Card,
  Typography,
  Grid,
  ListItem,
  ListItemText,
  Skeleton,
} from '@mui/material';
import { PreviewIcon, SortIcon } from '../../../components/GamifyIcon';
import DealCardSkeleton from '../DealCardSkeleton';

export default function DealsListSkeleton({ pipelineTitle }) {
  return (
    <Box className={'deal-list-container'}>
      <Box className={'deal-list-overlay-left'}>
        <Box className={'deal-title-and-add-deal-section'}>
          <Typography className={'deal-title'}>{`${
            pipelineTitle || ''
          } Pipeline`}</Typography>
          {/* <Button disableRipple={true} className={'add-deal-button'}>
            <AddIcon></AddIcon>
            New deal
          </Button> */}
          <Box className={'add-deal-button-skeleton'}>
            <Skeleton
              sx={{ bgcolor: '#ffffff' }}
              variant={'circular'}
              width={18}
              height={18}
            ></Skeleton>
            <Skeleton
              sx={{ bgcolor: '#ffffff', position: 'relative', left: 8 }}
              variant={'rounded'}
              width={83}
              height={18}
            ></Skeleton>
          </Box>
        </Box>
        <Box
          className={'lead-search-overlay'}
          container
          item
          justifyContent="center"
        >
          {/* <SearchInput
            id="leadSearch"
            name="leadSearch"
            // label="Search"
            placeholder="Search"
            type="text"
            fullWidth
            size="small"
          /> */}
          <Box className="leadSearch-skeleton-container">
            <Skeleton variant={'rounded'} width={'100%'} height={23}></Skeleton>
          </Box>
        </Box>

        <Box
          className={'deal-list-settings-overlay'}
          gridTemplateColumns={'repeat(2, minmax(0, 1fr))'}
        >
          <Box className={'deal-list-settings-container'}>
            <Typography className={'deal-settings-title-text'}>
              Stage
            </Typography>
            <Box className={'deal-settings-dropdown-skeleton-container'}>
              <Skeleton
                variant={'rounded'}
                width={'100%'}
                height={23}
              ></Skeleton>
            </Box>
          </Box>
          <Box className={'deal-list-settings-container'}>
            <Typography className={'deal-settings-title-text'}>
              Owner
            </Typography>
            <Box className={'deal-settings-dropdown-skeleton-container'}>
              <Skeleton
                variant={'rounded'}
                width={'100%'}
                height={23}
              ></Skeleton>
            </Box>
          </Box>
        </Box>

        <Box className={'deal-count-and-sort-section-container'}>
          <Skeleton variant={'rounded'} width={85} height={24}></Skeleton>
          <Button disableRipple={true} className={'sort-deals-button'}>
            <SortIcon></SortIcon>
          </Button>
        </Box>

        <Grid container className={'deal-list-column-container'} width="100%">
          <Box className={'deal-list-column'}>
            {['', '', '', '', ''].map((deal, i) => (
              <Card className={'deal-list-card'} key={i}>
                <Box className={'deal-list-inside-box'}>
                  <ListItem className={'deal-list-text-overlay'} key={i}>
                    <ListItemText
                      className={'deal-list-text'}
                      primary={
                        <DealCardSkeleton isOnKanban={false}></DealCardSkeleton>
                      }
                    />
                  </ListItem>
                </Box>
              </Card>
            ))}
          </Box>
        </Grid>
      </Box>
      <Box className={'deal-list-overlay-right'}>
        <Box className={'select-to-see-preview-container'}>
          <Box className={'preview-icon-container'}>
            <PreviewIcon></PreviewIcon>
          </Box>
          <Typography className={'preview-text'}>
            Select a deal to preview
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
