import React from 'react';
import AboutView from './About.view';
import NewGameState from '../../_types/NewGameState';
import ErrorMessagesState from '../../_types/ErrorMessagesState';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  errorMessages: ErrorMessagesState;
}

const AboutContainer: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
  errorMessages,
}) => {
  return (
    <AboutView
      errorMessages={errorMessages}
      newGameState={newGameState}
      onChangeNewGameState={onChangeNewGameState}
    />
  );
};

export default AboutContainer;
