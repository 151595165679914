import React, { useState } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
// import { StyledSelect, StyledMenuItem } from '../teams/StyledComponent';
import { StyledSelect, StyledMenuItem } from '../../common/StyledComponents';
import { Title, ViewContainer } from './KPILeaderboard.styles';
import KPITable from './KPITable';
import { GetKPIValuesArgs, KPILeaderboardState, TokensData } from './_types';
import KPIFilter from './KPIFilter';
import featureFlag from '../../common/featureFlag';

interface KPILeaderboardViewProps {
  kpiLeaderboardState: KPILeaderboardState;
  onChangeKpiLeaderboardState: (
    kpiLeaderboardState: KPILeaderboardState
  ) => void;
  columns: any;
  data: any;
  tokens: TokensData;
  resetTokens: () => void;
  fetchKpiLeaderboardData: (fetchDataArgs: GetKPIValuesArgs) => void;
}

const KPILeaderboardView: React.FC<KPILeaderboardViewProps> = ({
  columns,
  data,
  kpiLeaderboardState,
  onChangeKpiLeaderboardState,
  tokens,
  resetTokens,
  fetchKpiLeaderboardData,
}) => {
  const [refreshIntervalAmount, setRefreshIntervalAmount] =
    useState<number>(10000);

  const intervalList = [
    {
      label: '10 sec',
      value: 10000,
    },
    {
      label: '15 sec',
      value: 15000,
    },
    {
      label: '20 sec',
      value: 20000,
    },
    {
      label: '30 sec',
      value: 30000,
    },
    {
      label: '60 sec',
      value: 60000,
    },
  ] as const;

  return (
    <React.Fragment>
      {/* <img
        width="100%"
        style={{ position: 'absolute', zIndex: -1 }}
        src="/images/kpi-background.svg"
      /> */}
      <ViewContainer>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Title>Leaderboard</Title>
          {featureFlag('leaderboardRefreshInterval') && (
            <Box>
              <Typography style={{ fontSize: '15px', fontWeight: 700 }}>
                Refresh Interval
              </Typography>
              <StyledSelect
                style={{ width: '100%', borderRadius: 8 }}
                // placeholder="HELLO!!"
                onChange={(e: SelectChangeEvent<unknown>) =>
                  setRefreshIntervalAmount(parseInt(e.target.value as string))
                }
                value={refreshIntervalAmount}
                name="status"
                MenuProps={{
                  style: { zIndex: 100002 },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                    },
                  },
                }}
              >
                {intervalList.map((opt, i) => (
                  <StyledMenuItem value={opt.value} key={i}>
                    {opt.label}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
          )}
        </Box>
        <KPIFilter
          kpiLeaderboardState={kpiLeaderboardState}
          onChangeKpiLeaderboardState={onChangeKpiLeaderboardState}
          resetTokens={resetTokens}
        />
        <KPITable
          data={data}
          columns={columns}
          kpiLeaderboardState={kpiLeaderboardState}
          tokens={tokens}
          resetTokens={resetTokens}
          fetchKpiLeaderboardData={fetchKpiLeaderboardData}
          onChangeKpiLeaderboardState={onChangeKpiLeaderboardState}
          refreshIntervalAmount={refreshIntervalAmount}
        />
      </ViewContainer>
    </React.Fragment>
  );
};

export default KPILeaderboardView;
