import React, { useEffect, useState } from 'react';
import { jsPDF as JsPDF } from 'jspdf';
import { defaults, Chart, registerables } from 'chart.js/auto';
import { Line, Bar } from 'react-chartjs-2';
import { View, Text } from '@aws-amplify/ui-react';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Menu } from '@mui/material';
import './reports.css';
import { StyledMenuItem } from './StyledInputs';

Chart.register(...registerables);
const verticalLinePlugin = {
  id: 'verticalLine',
  afterDraw: (chart, args, options) => {
    const { ctx, scales, chartArea } = chart;
    if (options.lineAt) {
      const x = scales.x.getPixelForValue(options.lineAt);
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, chartArea.top);
      ctx.lineTo(x, chartArea.bottom);
      ctx.lineWidth = options.lineWidth || 1;
      ctx.strokeStyle = options.lineColor || 'black';
      ctx.stroke();
      ctx.restore();
    }
  },
};

// Register the plugin
Chart.register(verticalLinePlugin);
defaults.maintainAspectRatio = false;
defaults.responsive = true;

defaults.plugins.title.display = true;
defaults.plugins.title.align = 'center';
defaults.plugins.title.font.size = 25;
defaults.plugins.title.color = 'black';

const colorArray = [
  '#551AC9',
  '#00C572',
  '#EF3D5D',
  '#438EF7',
  '#FFCA54',
  '#703DDD',
  '#3AC6E5',
  '#B41632',
  '#0A157B',
  '#FF9F43',
  '#17D870',
  '#D42645',
  '#01A579',
  '#350A7B',
  '#F98A27',
  '#13DCA6',
  '#173FA9',
  '#9B0620',
  '#2E6EEA',
  '#A267EE',
  '#00D2D3',
  '#9b59b6',
  '#3498db',
  '#2ecc71',
  '#f1c40f',
  '#e74c3c',
  '#34495e',
  '#95a5a6',
  '#d35400',
  '#16a085',
];

const optionsObject = (title, label) => ({
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: function (val) {
          if (val % 1 === 0) return val;
        },
      },
    },
  },
  plugins: {
    title: {
      text: title,
    },
    verticalLine: {
      lineAt: label,
      lineColor: 'red',
      lineWidth: 2,
    },
  },
  elements: {
    line: {
      tension: 0.2,
    },
  },
  spanGaps: true,
});

const menuAlignObj = { vertical: '15px', horizontal: 'right' };

export default function ReportsCharts(props) {
  const { reports } = props;

  const [reportsList, setReportsList] = useState(null);
  // const [currentReportType, setCurrentReportType] = useState(null);
  const [exportMenu, setExportMenu] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    // console.log('reports', reports);
    if (reports) {
      setReportsList(Object.values(reports.reports));
      // setCurrentReportType(reports.report_type);
    }
  }, [reports]);

  // useEffect(() => {
  //   console.log('REPORT TYPE');
  //   console.log(currentReportType);
  // }, [currentReportType]);

  const createExportName = (report, format) => {
    let fileName = report.report_info.title.split(' ').join('_') + '_';
    if (reports.report_type !== 'hourly') {
      fileName += `${report.labels[0].split(' ')[1]}-${
        report.labels[report.labels.length - 1].split(' ')[1]
      }_`;
    }
    fileName += `report_${reports.org_id}.${format}`;
    return fileName;
  };

  const downloadChartAsPDF = (report) => {
    const chart = document.getElementById(report.report_info.id);
    const chartDataURL = chart.toDataURL('image/png');
    const pdf = new JsPDF();

    const pageWidth = pdf.internal.pageSize.getWidth();
    const imgWidth = 180;
    const xCentered = (pageWidth - imgWidth) / 2;

    pdf.addImage(chartDataURL, 'PNG', xCentered, 20, imgWidth, 100);
    pdf.save(createExportName(report, 'pdf'));
  };

  const downloadSingleCSV = (report) => {
    let csvString = '';
    // console.log(reports);
    let entityLabel;
    switch (reports.report_type) {
      case 'user':
      case 'hourly':
        entityLabel = 'User Name';
        break;
      case 'team':
        entityLabel = 'Team Name';
        break;
      case 'org':
        entityLabel = 'KPI';
        break;
      default:
        entityLabel = 'Entity name';
        break;
    }

    // csvString += `,${report.report_type[0]. Entity Name},`;
    csvString += `,${entityLabel},`;

    if (reports.report_type === 'hourly') {
      csvString += 'First Pin Dropped,Last Pin Dropped,Total Daily Pins,';
    }
    csvString += `${report.labels.join(',')}\r\n`;

    report.data_sets.forEach((dataset, i) => {
      csvString += `${i + 1},${dataset.label},`;
      if (reports.report_type === 'hourly') {
        csvString += `${dataset.stats.first_pin},${dataset.stats.last_pin},${dataset.stats.num_daily_pins},`;
      }
      csvString += `${dataset.data.map((d) => d || 0).join(',')}\r\n`;
    });

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', createExportName(report, 'csv'));
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const MyChartComponent = ({ report }) => {
    const chartData = {
      labels: report.labels,
      datasets: report.data_sets
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((ds, i) => ({
          ...ds,
          backgroundColor: colorArray[i],
          borderColor: colorArray[i],
        })),
    };

    const chartOptions = optionsObject(`${report.report_info.title} Report`);

    const ChartComponent = report.labels.length > 1 ? Line : Bar;

    return (
      <ChartComponent
        id={report.report_info.id}
        data={chartData}
        options={chartOptions}
      />
    );
  };

  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
      setExportMenu(event.currentTarget.getAttribute('data-report-id'));
    } else {
      setAnchorEl(null);
      setExportMenu('');
    }
  };
  if (reportsList && reportsList.length === 0)
    return (
      <View style={{ width: '100%' }}>
        <Box
          style={{
            width: '100%',
            height: '30dvh',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '5rem',
          }}
        >
          <Text style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
            {'No data is available for this report'}
          </Text>
        </Box>
      </View>
    );
  return (
    <View style={{ width: '100%' }}>
      <Box className="reports-container">
        {reportsList &&
          reportsList.map((report, i) => {
            return (
              <Box key={i} className="single-chart-container">
                <Box
                  onClick={handleClick}
                  data-report-id={report.report_info.id}
                  className="download-report-button hov"
                >
                  <DownloadIcon />
                  <Menu
                    open={exportMenu === report.report_info.id}
                    anchorEl={anchorEl}
                    anchorOrigin={menuAlignObj}
                    transformOrigin={menuAlignObj}
                  >
                    <StyledMenuItem onClick={() => downloadSingleCSV(report)}>
                      Export as CSV
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => downloadChartAsPDF(report)}>
                      Export as PDF
                    </StyledMenuItem>
                  </Menu>
                </Box>
                <MyChartComponent report={report} />
              </Box>
            );
          })}
      </Box>
    </View>
  );
}
