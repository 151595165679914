import React from 'react';
import GameDetailsView from './GameDetails.view';
import Game from '../_types/Game';
interface Props {
  game: Game;
}

const GameDetailsContainer = ({ game }: Props) => {
  return <GameDetailsView game={game} />;
};

export default GameDetailsContainer;
