import { API } from 'aws-amplify';
import { LOG } from '../../../common/Utilities';

const GET_ACTIVITY_FEED_QUERY = `
query MyQuery($messageID: ID!) {
  getActivityFeed(id: $messageID) {
    id
    header
    details
    showAfterEpoch
    showUntilEpoch
    type
    imageName
    imageType
  }
}
`;

const getPost = async (id: string) => {
  try {
    const res = await API.graphql({
      query: GET_ACTIVITY_FEED_QUERY,
      variables: { messageID: id },
    });
    // @ts-ignore
    return res.data.getActivityFeed;
  } catch (error) {
    LOG.error(error);
  }
};

export default getPost;
