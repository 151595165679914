import React, { useState } from 'react';
import Emoticon from '../../../../_types/Emoticon';
import {
  CloseIcon,
  ReactorBodyItemProfileContainer,
  ReactorListBody,
  ReactorListBodyItem,
  ReactorListBodyItemEmoticon,
  ReactorListBodyItemLabel,
  ReactorListContainer,
  ReactorListHeader,
  ReactorListHeaderLeftContent,
  ReactorListIndicator,
  ReactorListTab,
  ReactorListTabLabel,
} from './EmoticonsAction.styles';
import UserProfilePicture from '../../../../../../components/UserProfilePicture';
import removeDuplicateEmoticons from '../../../../feed-helpers/removeDuplicateEmoticons';
import { useNavigate } from 'react-router';

interface Props {
  show: boolean;
  emoticons: Emoticon[];
  emoticonsCounts: Record<string, number>;
  globalEmoticons: {
    color: string;
    key: string;
    symbol: string;
  }[];
  onClose: () => void;
}

const ReactorsList = ({
  emoticons,
  show,
  emoticonsCounts,
  globalEmoticons,
  onClose,
}: Props) => {
  if (!show) return null;

  const navigate = useNavigate();
  const uniqueEmoticons = removeDuplicateEmoticons(emoticons);
  const [currentTab, setCurrentTab] = useState('All');
  const tabs = globalEmoticons.map((globalEmoticon) => {
    if (
      !(globalEmoticon.key in emoticonsCounts) ||
      emoticonsCounts[globalEmoticon.key] === 0
    )
      return null;

    return {
      label: globalEmoticon.key,
      value: `${globalEmoticon.symbol} ${emoticonsCounts[globalEmoticon.key]}`,
    };
  });
  tabs.unshift({
    label: 'All',
    value: `All ${Object.values(emoticonsCounts).reduce(
      (acc, curr) => acc + curr,
      0
    )}`,
  });
  const filteredEmoticons = uniqueEmoticons.filter(
    (emoticon) => currentTab === 'All' || currentTab === emoticon.type
  );

  console.log('filteredEmoticons', filteredEmoticons);

  return (
    <ReactorListContainer>
      <ReactorListHeader>
        <ReactorListHeaderLeftContent>
          {tabs.map((tab) => {
            if (!tab) return null;
            if (tab.label === 'All') {
              return (
                <ReactorListTab
                  key={'All'}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentTab('All');
                  }}
                  style={{
                    marginRight: 50,
                  }}
                >
                  <ReactorListTabLabel isActive={currentTab === 'All'}>
                    {tab.value}
                  </ReactorListTabLabel>
                  <ReactorListIndicator isActive={currentTab === 'All'} />
                </ReactorListTab>
              );
            }

            return (
              <ReactorListTab
                key={tab.label}
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentTab(tab.label);
                }}
                style={{
                  marginRight: 30,
                }}
              >
                <ReactorListTabLabel isActive={currentTab === tab.label}>
                  {tab.value}
                </ReactorListTabLabel>
                <ReactorListIndicator isActive={tab.label === currentTab} />
              </ReactorListTab>
            );
          })}
        </ReactorListHeaderLeftContent>
        <CloseIcon
          src="/images/close-light.svg"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        />
      </ReactorListHeader>
      <ReactorListBody>
        {filteredEmoticons.map((emoticon, index) => {
          return (
            <ReactorListBodyItem key={index}>
              <ReactorBodyItemProfileContainer>
                <ReactorListBodyItemEmoticon>
                  {getSymbol({
                    globalEmoticons,
                    emoticonType: emoticon?.type as string,
                  })}
                </ReactorListBodyItemEmoticon>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/profile/${emoticon.user.id}`);
                  }}
                >
                  <UserProfilePicture
                    imageName={emoticon.user.imageName as string}
                  />
                </div>
              </ReactorBodyItemProfileContainer>
              <ReactorListBodyItemLabel>
                {emoticon?.user.name}
              </ReactorListBodyItemLabel>
            </ReactorListBodyItem>
          );
        })}
      </ReactorListBody>
    </ReactorListContainer>
  );
};

const getSymbol = ({
  emoticonType,
  globalEmoticons,
}: {
  emoticonType: string;
  globalEmoticons: {
    color: string;
    key: string;
    symbol: string;
  }[];
}) => {
  return globalEmoticons.find((emoticon) => emoticon.key === emoticonType)
    ?.symbol;
};

export default ReactorsList;
