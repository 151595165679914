import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Text, View } from '@aws-amplify/ui-react';
import { API, Storage } from 'aws-amplify';
import { Alert, Box, Snackbar } from '@mui/material';
import gql from 'graphql-tag';
import './Training.css';
import {
  UploadTrainingVideo,
  SingleLessonCard,
  LoadingVideoRows,
} from './TrainingComponents';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';

export const getLessons = async (orgID, API, callbackSetter) => {
  let myNextToken = null;
  const lessons = [];

  const myQuery = gql`
    query MyQuery($orgID: ID!, $nextToken: String) {
      listLessonDetails(
        filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
        limit: 200
        nextToken: $nextToken
      ) {
        nextToken
        items {
          id
          name
          description
          youTubeURL
          order
          imageName
          imageType
          posterImageName
          category
          createdAt
        }
      }
    }
  `;
  let res;
  try {
    do {
      console.log('Running');
      console.log('nextToken ', myNextToken);
      res = await API.graphql({
        query: myQuery,
        variables: { orgID, nextToken: myNextToken },
      });
      console.log('res ', res);
      const data = res.data.listLessonDetails.items;
      lessons.push(...data);
      myNextToken = res.data.listLessonDetails.nextToken;
    } while (myNextToken);
  } catch (error) {
    console.log('We Got a Problem --- update later  ', error);
    // alert("Couldn't Get Lessons!");
    return false;
  }

  const lessonsObject = {};
  const allLessons = [];
  // for (const lesson of res.data.listLessonDetails.items) {
  for (const lesson of lessons) {
    if (lesson.posterImageName === '') lesson.posterImageName = null;
    if (lesson.youTubeURL === '') lesson.youTubeURL = null;
    if (lesson.youTubeURL) {
      if (lesson.youTubeURL.includes('watch?v='))
        lesson.youTubeURL = lesson.youTubeURL.split('watch?v=')[1];
      if (!lesson.posterImageName) {
        lesson.posterImageName = `https://img.youtube.com/vi/${
          lesson.youTubeURL.split('&')[0]
        }/hqdefault.jpg`;
      } else {
        const picture = await Storage.get(lesson.posterImageName);
        lesson.posterImageName = picture;
      }
      lesson.videoURL = `https://www.youtube.com/watch?v=${lesson.youTubeURL}`;
    } else {
      lesson.videoURL =
        'https://d22xeeaijfewl2.cloudfront.net/public/' +
        encodeURIComponent(lesson.imageName);
      if (lesson.posterImageName) {
        const picture = await Storage.get(lesson.posterImageName);
        lesson.posterImageName = picture;
        console.log('pitcure!! -- ', picture);
      }
    }
    if (!lessonsObject[lesson.category]) lessonsObject[lesson.category] = [];
    lessonsObject[lesson.category].push(lesson);
    allLessons.push(lesson);
  }
  Object.keys(lessonsObject).forEach((category) => {
    lessonsObject[category] = lessonsObject[category].sort(
      (a, b) => a.order - b.order
    );
  });

  callbackSetter(lessonsObject);
  global.trainingVideos = lessonsObject;
};

export default function TrainingHomePage() {
  const orgID = global.me.orgID;
  const navigate = useNavigate();

  const [allLessons, setAllLessons] = useState({});
  const [recentUploads, setRecentUploads] = useState([]);
  const [uploadVid, setUploadVid] = useState(false);
  const [videoLimitReached, setVideoLimitReached] = useState(false);
  const [snackbarObj, setSnackbarObj] = useState(null);

  const recentUploadsReducer = (lessonsObj) =>
    Object.values(lessonsObj)
      .reduce((acc, el) => [...acc, ...el], [])
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  useEffect(() => {
    if (global.trainingVideos) {
      setAllLessons(global.trainingVideos);
      setRecentUploads(recentUploadsReducer(global.trainingVideos));
    } else {
      getLessons(orgID, API, (res) => {
        setAllLessons(res);
        setRecentUploads(recentUploadsReducer(res));
      });
    }
  }, [global.trainingVideos]);

  let maxLessons = 0;
  for (const entitlement of global.appSettings.entitlements) {
    if (entitlement.startsWith('lms:max_lessons')) {
      maxLessons = parseInt(entitlement.split('=')[1].trim());
    }
  }

  return (
    <View
      style={{
        paddingTop: '40px',
      }}
    >
      <DynamicConfirmModal
        showDynamicConfirmModal={videoLimitReached}
        setShowDynamicConfirmModal={setVideoLimitReached}
        zIndex={100002}
        leftAlignText={true}
        title={`You have uploaded ${recentUploads.length} videos.`}
        subtitle={`Please upgrade to create more than ${maxLessons} videos.`}
        noCancelButton={true}
        confirmButtonText={'OK'}
        onConfirm={() => {
          setVideoLimitReached(false);
        }}
      ></DynamicConfirmModal>
      <Box id="training-home-container">
        <Box id="training-home-topbar">
          <Box id="page-title-box">
            <Text
              style={{
                fontWeight: '700',
                fontSize: '1.7rem',
                marginRight: '0.7vw',
              }}
              className="hov"
            >
              Training
            </Text>
            {global.me.isAdmin ? (
              <Text
                style={{
                  color: '#FF6A00',
                  fontWeight: '700',
                  transform: 'translateY(-3px)',
                }}
                className="hov"
                onClick={() => {
                  if (recentUploads.length >= maxLessons) {
                    setVideoLimitReached(true);
                  } else {
                    setUploadVid(true);
                  }
                }}
              >
                + Add Video
              </Text>
            ) : null}
          </Box>
          {/* <Box id="type-selection-box">
            <Box className="training-type-option">
              <Text
                style={{
                  color: '#FF6A00',
                  fontSize: '1rem',
                  fontWeight: '700',
                }}
              >
                Videos
              </Text>
              <Box className="dot" style={{ backgroundColor: '#FF6A00' }} />
            </Box>
            <Box className="training-type-option">
              <Text
                style={{
                  color: '#868686',
                  fontSize: '1rem',
                  fontWeight: '700',
                }}
              >
                Documents
              </Text>
            </Box>
          </Box> */}
        </Box>
        {allLessons ? (
          <>
            <Box className="category-row-continaer">
              <Box className="category-row-top">
                <Text style={{ fontWeight: 700, fontSize: '1.3rem' }}>
                  Recently Uploaded
                </Text>
              </Box>
              <Box className="category-row-lessons-container">
                {recentUploads.slice(0, 4).map((lesson, i) => (
                  <SingleLessonCard
                    lesson={lesson}
                    refresh={() => {
                      getLessons(orgID, API, (res) => {
                        setAllLessons(res);
                        setRecentUploads(recentUploadsReducer(res));
                      });
                    }}
                    snackbar={(message, severity) =>
                      setSnackbarObj({ message, severity })
                    }
                    key={i}
                    display="grid"
                  />
                ))}
              </Box>
            </Box>
            {Object.keys(allLessons).map((category, i) => (
              <Box key={i} className="category-row-continaer">
                <Box className="category-row-top">
                  <Text style={{ fontWeight: 700, fontSize: '1.3rem' }}>
                    {category}
                  </Text>
                  <Text
                    style={{ fontWeight: 'bold', color: '#FF6A00' }}
                    onClick={() => navigate(`/training/${category}`)}
                    className="hov"
                  >
                    View All
                  </Text>
                </Box>
                <Box className="category-row-lessons-container">
                  {allLessons[category].map((lesson, i) => (
                    <SingleLessonCard
                      lesson={lesson}
                      refresh={() => {
                        getLessons(orgID, API, (res) => {
                          setAllLessons(res);
                          setRecentUploads(recentUploadsReducer(res));
                        });
                      }}
                      key={i}
                      display="grid"
                      snackbar={(message, severity) =>
                        setSnackbarObj({ message, severity })
                      }
                    />
                  ))}
                </Box>
              </Box>
            ))}
          </>
        ) : (
          <>
            <LoadingVideoRows />
            <LoadingVideoRows />
          </>
        )}
      </Box>
      <UploadTrainingVideo
        open={uploadVid}
        close={() => setUploadVid(false)}
        refresh={() => {
          getLessons(orgID, API, (res) => {
            setAllLessons(res);
            setRecentUploads(recentUploadsReducer(res));
          });
        }}
        snackbar={(message, severity) => setSnackbarObj({ message, severity })}
      />
      {/* <EditTrainigVideo open={editLesson} close={() => setEditLesson(null)} /> */}
      {/* <Snackbar open={true} message="hello" severity="info" /> */}
      {snackbarObj && (
        <Snackbar
          autoHideDuration={6000}
          onClose={() => setSnackbarObj(null)}
          open={Boolean(snackbarObj)}
        >
          <Alert severity={snackbarObj.severity}>{snackbarObj.message}</Alert>
        </Snackbar>
      )}
    </View>
  );
}
