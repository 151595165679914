import React from 'react';
import { Box } from '@mui/material';

export default function Payments() {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        paddingTop: 90,
      }}
    >
      <iframe
        width={'100%'}
        height={'100%'}
        src={'https://merchants.payengine.dev/signin'}
      ></iframe>
    </Box>
  );
}
