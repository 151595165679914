import styled from 'styled-components';

export const ViewContainer = styled.div`
  margin-top: 20px;
  width: 500px;
`;

export const AddImageArea = styled.div<{ shouldAjustHeight?: boolean }>`
  border-radius: 10px;
  width: 100%;
  padding: 16px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ shouldAjustHeight }) =>
    shouldAjustHeight ? 'max-content' : '100px'};
  cursor: pointer;
`;

export const AddImageAreaText = styled.div`
  color: #ff6a00;
  font-family: Space Grotesk;
  font-weight: 500;
  text-align: center;
`;

export const FilePreviewContainer = styled.div`
  position: relative;
  width: fit-content;
`;
export const FilePreviewCloseIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: #f5f5f5;
  position: absolute;
  border-radius: 100%;
  right: -20px;
  top: -10px;
`;
