export const removeEmptyFields = (obj, log = false) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      // Using key to avoid eslint error for unused variable
      if (value === '' && log) {
        console.log(`Removing empty field: ${key}`);
      }
      return value !== '';
    })
  );
};
