import React from 'react';
import { Box } from '@mui/material';
import './Index.css';
import { View } from '@aws-amplify/ui-react';
import MaintainMissionScreen from '../../routes/campaigns/MaintainMissionScreen';

export default function Home() {
  const missionID = '';

  return (
    <Box className={'meta-testing-box'}>
      <Box className={'new-attempt'}>
        <View>
          <MaintainMissionScreen missionID={missionID} />
        </View>
      </Box>
    </Box>
  );
}
