import React, { useRef, useState } from 'react';
import {
  CustomTextArea,
  DetailsContainer,
  FilePreviewContainer,
  FilePreviewCloseIcon,
  FilterPersonsList,
  FilterPersonsListItem,
  IconsContainer,
  InputAndActionsContainer,
  PostButton,
  ViewContainer,
} from './AddComment.styles';
import UserProfilePicture from '../../../components/UserProfilePicture';
import Me from '../../../_types/Me';
import { Icon } from '../FeedItem/contents/Actions/Actions.styles';
import AddCommentArgs from '../_types/AddCommentArgs';
import GifSearch from '../GifSearch';
import MyPerson from '../_types/MyPerson';
import { useAlert } from 'react-alert-with-buttons';

interface Props {
  filePreview: any;
  me: Me;
  openGif: boolean;
  onSaveComment: () => void;
  comment: AddCommentArgs;
  onChangeComment: (comment: AddCommentArgs) => void;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenCloseGif: () => void;
  myPersons: MyPerson[];
  onRemoveFilePreview: () => void;
  onChangeIsEditing: (newIsEditing: boolean) => void;
}

const AddCommentView = ({
  me,
  onSaveComment,
  comment,
  onChangeComment,
  filePreview,
  onUpload,
  openGif,
  onOpenCloseGif,
  myPersons,
  onRemoveFilePreview,
  onChangeIsEditing,
}: Props) => {
  const uploadRef = useRef<any>();
  const [filteredMyPersons, setFilteredMyPersons] = useState<MyPerson[]>([]);
  const alert = useAlert();

  const handleTextChange = (value: string) => {
    onChangeIsEditing(!!value);
    onChangeComment({ ...comment, content: value });

    const lastWord = (value.split(' ').pop() || '').toLowerCase();

    if (lastWord.startsWith('@')) {
      setFilteredMyPersons(
        myPersons.filter((myPerson) =>
          myPerson.name.toLowerCase().includes(lastWord.slice(1))
        )
      );
    } else {
      setFilteredMyPersons([]);
    }
  };

  return (
    <ViewContainer>
      <UserProfilePicture imageName={me.imageName || ''} />
      <DetailsContainer>
        <CustomTextArea
          maxLength={255}
          value={comment.content}
          onChange={(e) => handleTextChange(e.target.value)}
          placeholder={`What's happening?`}
        />
        {filePreview ? (
          <FilePreviewContainer>
            <FilePreviewCloseIcon
              onClick={(e) => {
                e.stopPropagation();
                alert.open({
                  message: 'Are you sure you wish to remove this attachment?',
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: () => {
                        onRemoveFilePreview();
                        uploadRef.current.value = '';
                        alert.close();
                      },
                      style: {
                        borderRadius: 16,
                        backgroundColor: '#FF6A00',
                        marginRight: 20,
                        color: '#fff',
                        fontWeight: 700,
                      },
                    },
                    {
                      label: 'No',
                      onClick: () => alert.close(),
                      style: {
                        borderRadius: 16,
                        backgroundColor: '#FF5C77',
                        color: '#fff',
                        fontWeight: 700,
                      },
                    },
                  ],
                });
              }}
              src="/images/close.svg"
            />
            {comment.imageType === 'video' ? (
              <video width="320" height="240" controls>
                <source src={filePreview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={filePreview} alt="Preview" width="200" />
            )}
          </FilePreviewContainer>
        ) : null}

        <InputAndActionsContainer>
          <IconsContainer>
            <input
              onChange={(e) => onUpload(e)}
              style={{ display: 'none' }}
              accept="image/*,video/*"
              type="file"
              ref={(ref) => (uploadRef.current = ref)}
            />
            <div style={{ position: 'relative' }}>
              <Icon
                onClick={() => handleTextChange('@')}
                src="/images/icon-at.svg"
              />
              {filteredMyPersons.length > 0 ? (
                <FilterPersonsList>
                  {filteredMyPersons.map((myPerson) => (
                    <FilterPersonsListItem
                      onClick={() => {
                        const contentWithoutAt = comment.content.split('@')[0];
                        handleTextChange(
                          `${contentWithoutAt}@${myPerson.name}`
                        );
                        setFilteredMyPersons([]);
                      }}
                      key={myPerson.id}
                    >
                      <UserProfilePicture imageName={myPerson.imageName} />
                      <div style={{ fontWeight: 600 }}>{myPerson.name}</div>
                    </FilterPersonsListItem>
                  ))}
                </FilterPersonsList>
              ) : null}
            </div>

            <Icon
              onClick={() => uploadRef.current.click()}
              src="/images/icon-image.svg"
            />
            <div style={{ position: 'relative' }}>
              <Icon onClick={onOpenCloseGif} src="/images/icon-gif.svg" />
              <GifSearch
                open={openGif}
                onOpenClose={onOpenCloseGif}
                onSelect={(gif: any) => {
                  onChangeComment({
                    ...comment,
                    imageName: gif.url,
                    imageType: 'gif_url',
                  });
                }}
              />
            </div>
          </IconsContainer>
          <PostButton
            onClick={(e) => {
              e.preventDefault();
              onSaveComment();
            }}
          >
            Post
          </PostButton>
        </InputAndActionsContainer>
      </DetailsContainer>
    </ViewContainer>
  );
};

export default AddCommentView;
