import React, { useState } from 'react';
import { Text } from '@aws-amplify/ui-react';
import { StyledSelect, StyledMenuItem, StyleDatePicker } from './StyledInputs';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './reports.css';

// export function convertDateRange(rangeValue) {
//   const now = new Date();
//   let startDate, endDate;

//   switch (rangeValue) {
//     // case 'A': // All Dates
//     //   startDate = new Date(0);
//     //   endDate = new Date(now.setHours(23, 59, 59, 999));
//     //   break;

//     case 'T': // Today
//       startDate = new Date(now.setHours(0, 0, 0, 0));
//       endDate = new Date(now.setHours(23, 59, 59, 999));
//       break;

//     case 'Y': // Yesterday
//       startDate = new Date(now.setDate(now.getDate() - 1)).setHours(0, 0, 0, 0);
//       endDate = new Date(now.setDate(now.getDate() - 1)).setHours(
//         23,
//         59,
//         59,
//         999
//       );
//       break;

//     case 'TW': // This Week
//       const dayOfWeek = now.getDay();
//       startDate = new Date(now.setDate(now.getDate() - dayOfWeek)).setHours(
//         0,
//         0,
//         0,
//         0
//       );
//       endDate = new Date(now.setDate(now.getDate() - dayOfWeek + 6)).setHours(
//         23,
//         59,
//         59,
//         999
//       );
//       break;

//     case 'LW': // Last Week
//       const previousWeek = new Date(
//         now.setDate(now.getDate() - now.getDay() - 7)
//       );
//       startDate = new Date(
//         previousWeek.setDate(previousWeek.getDate() - previousWeek.getDay())
//       ).setHours(0, 0, 0, 0);
//       endDate = new Date(
//         previousWeek.setDate(previousWeek.getDate() - previousWeek.getDay() + 6)
//       ).setHours(23, 59, 59, 999);
//       break;

//     case 'TM': // This Month
//       startDate = new Date(now.getFullYear(), now.getMonth(), 1);
//       endDate = new Date(
//         now.getFullYear(),
//         now.getMonth() + 1,
//         0,
//         23,
//         59,
//         59,
//         999
//       );
//       break;

//     case 'LM': // Last Month
//       startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
//       endDate = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999);
//       break;

//     case 'TY': // This Year
//       startDate = new Date(now.getFullYear(), 0, 1);
//       endDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
//       break;

//     case 'LY': // Last Year
//       startDate = new Date(now.getFullYear() - 1, 0, 1);
//       endDate = new Date(now.getFullYear() - 1, 11, 31, 23, 59, 59, 999);
//       break;

//     default:
//       return null;
//   }

//   //   return [new Date(startDate), new Date(endDate)];
//   return [startDate.toISOString(), endDate.toISOString()];
// }
export function convertDateRange(rangeValue) {
  const now = new Date();
  let startDate, endDate;

  switch (rangeValue) {
    case 'T': // Today
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      endDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        23,
        59,
        59,
        999
      );
      break;

    case 'Y': // Yesterday
      startDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 1
      );
      endDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 1,
        23,
        59,
        59,
        999
      );
      break;

    case 'TW': // This Week
      const dayOfWeek = now.getDay();
      startDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - dayOfWeek
      );
      endDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - dayOfWeek + 6,
        23,
        59,
        59,
        999
      );
      break;

    case 'LW': // Last Week
      const startLastWeek = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - now.getDay() - 7
      );
      startDate = new Date(
        startLastWeek.getFullYear(),
        startLastWeek.getMonth(),
        startLastWeek.getDate() - startLastWeek.getDay()
      );
      endDate = new Date(
        startLastWeek.getFullYear(),
        startLastWeek.getMonth(),
        startLastWeek.getDate() - startLastWeek.getDay() + 6,
        23,
        59,
        59,
        999
      );
      break;

    case 'TM': // This Month
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      endDate = new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        0,
        23,
        59,
        59,
        999
      );
      break;

    case 'LM': // Last Month
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      endDate = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999);
      break;

    case 'TY': // This Year
      startDate = new Date(now.getFullYear(), 0, 1);
      endDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999);
      break;

    case 'LY': // Last Year
      startDate = new Date(now.getFullYear() - 1, 0, 1);
      endDate = new Date(now.getFullYear() - 1, 11, 31, 23, 59, 59, 999);
      break;

    default:
      return null;
  }

  return [startDate, endDate];
  //   return [startDate.toISOString(), endDate.toISOString()];
}

export function DateRangePicker(props) {
  const { rangeCode, fromTime, setFromTime, reportType, toTime, setToTime } =
    props;

  const ranges = [
    ['Custom', 'C'],
    ['Today', 'T'],
    ['Yesterday', 'Y'],
    ['This Week', 'TW'],
    ['Last Week', 'LW'],
    ['This Month', 'TM'],
    ['Last Month', 'LM'],
    ['This Year', 'TY'],
    ['Last Year', 'LY'],
  ];

  return (
    <Box
      style={{
        display: 'flex',
        gap: '15px',
      }}
    >
      <Box>
        <Text style={{ fontWeight: 'bold', marginBottom: '2px' }}>
          Time period
        </Text>
        <StyledSelect
          value={rangeCode}
          onChange={(e) => {
            const rangeCode = e.target.value;
            props.setRange(rangeCode);
            if (rangeCode === 'C') return;

            const [newFrom, newTo] = convertDateRange(e.target.value);
            setFromTime(newFrom);
            setToTime(newTo);
          }}
        >
          {ranges.map((range, i) => (
            <StyledMenuItem key={i} value={range[1]}>
              {range[0]}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </Box>
      <DateAndTime
        reportType={reportType}
        from={fromTime}
        fromSetter={(val) => setFromTime(val)}
        to={toTime}
        toSetter={(val) => setToTime(val)}
        disable={!(rangeCode === 'C')}
      />
    </Box>
  );
}

export function DateAndTime(props) {
  const { from, fromSetter, to, toSetter, disable, reportType } = props;
  const [hoursSelected, setHoursSelected] = useState({
    from: '08:00',
    to: '18:00',
  });
  const hours = [
    ['12:00 AM', '00:00'],
    ['01:00 AM', '01:00'],
    ['02:00 AM', '02:00'],
    ['03:00 AM', '03:00'],
    ['04:00 AM', '04:00'],
    ['05:00 AM', '05:00'],
    ['06:00 AM', '06:00'],
    ['07:00 AM', '07:00'],
    ['08:00 AM', '08:00'],
    ['09:00 AM', '09:00'],
    ['10:00 AM', '10:00'],
    ['11:00 AM', '11:00'],
    ['12:00 PM', '12:00'],
    ['01:00 PM', '13:00'],
    ['02:00 PM', '14:00'],
    ['03:00 PM', '15:00'],
    ['04:00 PM', '16:00'],
    ['05:00 PM', '17:00'],
    ['06:00 PM', '18:00'],
    ['07:00 PM', '19:00'],
    ['08:00 PM', '20:00'],
    ['09:00 PM', '21:00'],
    ['10:00 PM', '22:00'],
    ['11:00 PM', '23:00'],
  ];

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const hour = parseInt(value.split(':')[0], 10);

    let newFromDate = new Date(from);
    let newToDate = new Date(to);

    if (name === 'from') {
      newFromDate.setHours(hour, 0, 0);

      if (newToDate < newFromDate) {
        newToDate = new Date(newFromDate);
        setHoursSelected({ from: value, to: value });
      } else {
        setHoursSelected((p) => ({ ...p, from: value }));
      }
    } else if (name === 'to') {
      newToDate.setHours(hour, 0, 0);

      if (newToDate < newFromDate) {
        newFromDate = new Date(newToDate);
        setHoursSelected({ from: value, to: value });
      } else {
        setHoursSelected((p) => ({ ...p, to: value }));
      }
    }
    fromSetter(newFromDate);
    toSetter(newToDate);
  };

  return (
    <Box style={{ display: 'flex', gap: '15px' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box>
          <Text style={{ fontWeight: 'bold', marginBottom: '2px' }}>From</Text>
          <Box
            style={{
              border: '1px solid #D0D2D8',
              borderRadius: '8px',
              padding: '0 2px',
            }}
          >
            <StyleDatePicker
              disabled={disable}
              value={from}
              onChange={(e) => fromSetter(e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </Box>
        <Box>
          <Text style={{ fontWeight: 'bold', marginBottom: '2px' }}>To</Text>
          <Box
            style={{
              border: '1px solid #D0D2D8',
              borderRadius: '8px',
              padding: '0 2px',
            }}
          >
            <StyleDatePicker
              disabled={disable}
              value={to}
              onChange={(e) => toSetter(e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </Box>
        {reportType === 'hourly' && (
          <>
            <Box>
              <Text style={{ fontWeight: 'bold', marginBottom: '2px' }}>
                From Hour
              </Text>
              <StyledSelect
                onChange={handleChange}
                name="from"
                value={hoursSelected.from}
                customWidth="8ch"
              >
                {hours.map((el, i) => (
                  <StyledMenuItem key={i} value={el[1]}>
                    {el[0]}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
            <Box>
              <Text style={{ fontWeight: 'bold', marginBottom: '2px' }}>
                To Hour
              </Text>
              <StyledSelect
                onChange={handleChange}
                name="to"
                value={hoursSelected.to}
                customWidth="8ch"
              >
                {hours
                  .filter((h) => h[1] >= hoursSelected.from)
                  .map((el, i) => (
                    <StyledMenuItem key={i} value={el[1]}>
                      {el[0]}
                    </StyledMenuItem>
                  ))}
              </StyledSelect>
            </Box>
          </>
        )}
      </LocalizationProvider>
    </Box>
  );
  // }
}
