import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import Utilities from '../common/Utilities';

export default class FeedRepository {
  // console.log ("MyFeedScreen");
  static isFetchingFeed = false;
  static lastFeedType = null;

  static async fetchFeed(
    myFeed,
    ourFeedFilter,
    feedType,
    showDeletedParm,
    now,
    ourNextToken,
    resetList
  ) {
    console.log(
      '!!!fetchFeed: ',
      ourFeedFilter,
      feedType,
      showDeletedParm,
      now,
      ourNextToken,
      resetList
    );

    const topOfFeedQuery = gql`
      query feedByDateNew(
        $userID: ID!
        $showDeleted: Boolean = false
        $nextToken: String = null
        $nowEpoch: Float
      ) {
        feedByUpdatedAt(
          userID: $userID
          limit: 100
          sortDirection: DESC
          nextToken: $nextToken
          filter: {
            type: { ne: inbox }
            showUntilEpoch: { gt: $nowEpoch }
            or: [
              { showAfterEpoch: { lt: $nowEpoch } }
              { showAfterEpoch: { eq: 0 } }
            ]
            and: {
              or: [
                { isDeleted: { eq: false } }
                { isDeleted: { eq: $showDeleted } }
              ]
            }
          }
        ) {
          nextToken
          items {
            activityFeed {
              comments(sortDirection: ASC) {
                items {
                  content
                  createdAt
                  id
                  imageType
                  userID
                  imageName
                  updatedAt
                  isDeleted
                  user {
                    name
                    imageName
                    initials
                    title
                  }
                  parentComment {
                    id
                  }
                }
              }
              emoticons {
                items {
                  id
                  createdAt
                  type
                  user {
                    id
                    imageName
                    name
                    title
                  }
                }
              }
              type
              showAfter
              createdAt
              details
              event
              header
              id
              imageType
              imageName
              updatedAt
              chartType
              chartData
              createdBy
              isDeleted
              changedComment {
                content
                user {
                  id
                  imageName
                  name
                  title
                }
              }
              changedEmoticon {
                type
                createdAt
                user {
                  id
                  imageName
                  name
                  title
                }
              }
              changedCommentEmoticon {
                type
                createdAt
                user {
                  id
                  imageName
                  name
                  title
                }
              }
              createdByUser {
                id
                initials
                imageName
                name
                title
              }
            }
            type
            showAfter
            showAfterEpoch
            showUntilEpoch
            createdAt
            id
            userID
            isRead
            updatedAt
            isDeleted
            user {
              name
              imageName
              initials
              title
            }
          }
        }
      }
    `;

    const feedQuery = gql`
      query feedByDateNew(
        $userID: ID!
        $showDeleted: Boolean = false
        $nextToken: String = null
        $nowEpoch: Float
      ) {
        feedByUpdatedAt(
          userID: $userID
          limit: 100
          sortDirection: DESC
          nextToken: $nextToken
          filter: {
            type: { ne: inbox }
            showUntilEpoch: { eq: 0 }
            or: [
              { showAfterEpoch: { lt: $nowEpoch } }
              { showAfterEpoch: { eq: 0 } }
            ]
            and: {
              or: [
                { isDeleted: { eq: false } }
                { isDeleted: { eq: $showDeleted } }
              ]
            }
          }
        ) {
          nextToken
          items {
            activityFeed {
              comments(sortDirection: ASC) {
                items {
                  content
                  createdAt
                  id
                  imageType
                  userID
                  imageName
                  updatedAt
                  isDeleted
                  user {
                    name
                    imageName
                    initials
                    title
                  }
                  parentComment {
                    id
                  }
                }
              }
              emoticons {
                items {
                  id
                  createdAt
                  type
                  user {
                    id
                    imageName
                    name
                    title
                  }
                }
              }
              type
              showAfter
              createdAt
              details
              event
              header
              id
              imageType
              imageName
              updatedAt
              chartType
              chartData
              createdBy
              isDeleted
              changedComment {
                content
                user {
                  id
                  imageName
                  name
                  title
                }
              }
              changedEmoticon {
                type
                createdAt
                user {
                  id
                  imageName
                  name
                  title
                }
              }
              changedCommentEmoticon {
                type
                createdAt
                user {
                  id
                  imageName
                  name
                  title
                }
              }
              createdByUser {
                id
                initials
                imageName
                name
                title
              }
            }
            type
            showAfter
            showAfterEpoch
            showUntilEpoch
            createdAt
            id
            userID
            isRead
            updatedAt
            isDeleted
            user {
              name
              imageName
              initials
              title
            }
          }
        }
      }
    `;

    const inboxQueryNoDeleted = gql`
      query feedByShowAfter(
        $userID: ID!
        $now: String!
        $nextToken: String = null
        $nowEpoch: Float
      ) {
        feedByShowAfter(
          userID: $userID
          showAfter: { le: $now }
          limit: 100
          sortDirection: DESC
          nextToken: $nextToken
          filter: {
            type: { ne: feed }
            isDeleted: { eq: false }
            or: [
              { showAfterEpoch: { lt: $nowEpoch } }
              { showAfterEpoch: { eq: 0 } }
            ]
            or: [
              { showUntilEpoch: { gt: $nowEpoch } }
              { showUntilEpoch: { eq: 0 } }
            ]
          }
        ) {
          nextToken
          items {
            activityFeed {
              comments(sortDirection: DESC) {
                items {
                  content
                  createdAt
                  id
                  imageType
                  userID
                  imageName
                  updatedAt
                  isDeleted
                  user {
                    name
                    imageName
                    initials
                    title
                  }
                  parentComment {
                    id
                  }
                }
              }
              emoticons {
                items {
                  id
                  createdAt
                  type
                  user {
                    id
                    imageName
                    name
                    title
                  }
                }
              }
              changedComment {
                content
                user {
                  id
                  imageName
                  name
                  title
                }
              }
              changedEmoticon {
                type
                createdAt
                user {
                  id
                  imageName
                  name
                  title
                }
              }
              changedCommentEmoticon {
                type
                createdAt
                user {
                  id
                  imageName
                  name
                  title
                }
              }
              showAfter
              type
              createdAt
              details
              event
              header
              id
              imageType
              imageName
              updatedAt
              chartType
              chartData
              createdBy
              changedID
              isDeleted
              createdByUser {
                id
                initials
                imageName
                name
                title
              }
            }
            showAfter
            showAfterEpoch
            showUntilEpoch
            type
            createdAt
            id
            userID
            isRead
            updatedAt
            user {
              name
              imageName
              initials
              title
            }
          }
        }
      }
    `;

    const inboxQueryAll = gql`
      query feedByShowAfter(
        $userID: ID!
        $now: String!
        $nextToken: String = null
        $nowEpoch: Float
      ) {
        feedByShowAfter(
          userID: $userID
          showAfter: { le: $now }
          nextToken: $nextToken
          limit: 100
          sortDirection: DESC
          filter: {
            type: { ne: feed }
            or: [
              { showAfterEpoch: { lt: $nowEpoch } }
              { showAfterEpoch: { eq: 0 } }
            ]
            or: [
              { showUntilEpoch: { gt: $nowEpoch } }
              { showUntilEpoch: { eq: 0 } }
            ]
          }
        ) {
          nextToken
          items {
            activityFeed {
              comments(sortDirection: DESC) {
                items {
                  content
                  createdAt
                  id
                  imageType
                  userID
                  imageName
                  updatedAt
                  isDeleted
                  user {
                    name
                    imageName
                    initials
                    title
                  }
                  parentComment {
                    id
                  }
                }
              }
              emoticons {
                items {
                  id
                  createdAt
                  type
                  user {
                    id
                    imageName
                    name
                    title
                  }
                }
              }
              changedComment {
                content
                user {
                  id
                  imageName
                  name
                  title
                }
              }
              changedEmoticon {
                type
                createdAt
                user {
                  id
                  imageName
                  name
                  title
                }
              }
              changedCommentEmoticon {
                type
                createdAt
                user {
                  id
                  imageName
                  name
                  title
                }
              }
              showAfter
              type
              createdAt
              details
              event
              header
              id
              imageType
              imageName
              updatedAt
              chartType
              chartData
              createdBy
              isDeleted
              createdByUser {
                id
                initials
                imageName
                name
                title
              }
            }
            showAfter
            showAfterEpoch
            showUntilEpoch
            type
            createdAt
            id
            userID
            isRead
            updatedAt
            user {
              name
              imageName
              initials
              title
            }
          }
        }
      }
    `;
    let filteredFeed = [];

    if (this.isFetchingFeed) {
      console.log('!!isFetchingFeed is true');
      return;
    }
    this.isFetchingFeed = true;

    if (!resetList && ourNextToken == null) {
      console.log('!!!reset list issue!!!');
      this.isFetchingFeed = false;
      return [null, null];
    }
    let myNextToken = ourNextToken;
    if (resetList || feedType !== this.lastFeedType) {
      myNextToken = null;
    }
    if (myNextToken === '' && !resetList) {
      console.log('***End of list***');
      this.isFetchingFeed = false;
      return [null, null];
    }

    console.log('**myNextToken: ', myNextToken);
    const nowEpoch = new Date().getTime();

    try {
      let myFeedData = [];
      // console.log ("***localToISODateFormat now: ", endOfToday(now));
      // const now = getCurrentUTCDate();
      if (feedType === 'feed') {
        const topOfFeedData = await API.graphql({
          query: topOfFeedQuery,
          variables: {
            userID: global.me.id,
            feedType,
            nextToken: myNextToken,
            nowEpoch,
          },
          limit: 1,
        });
        console.log('topOfFeedData:', topOfFeedData);
        myFeedData = myFeedData.concat(
          topOfFeedData.data.feedByUpdatedAt.items
        );
        console.log('query: ', global.me.id, feedType, myNextToken, nowEpoch);
        const feedData = await API.graphql({
          query: feedQuery,
          variables: {
            userID: global.me.id,
            feedType,
            nextToken: myNextToken,
            nowEpoch,
          },
          limit: 1,
        });
        console.log('feedData:', feedData);
        for (const item of feedData.data.feedByUpdatedAt.items) {
          myFeedData[myFeedData.length] = item;
        }
        // myFeedData = feedData.data.feedByUpdatedAt.items;
        myNextToken = feedData.data.feedByUpdatedAt.nextToken;
      } else {
        if (showDeletedParm) {
          const feedData = await API.graphql({
            query: inboxQueryAll,
            variables: {
              userID: global.me.id,
              now: Utilities.endOfToday(now),
              nextToken: myNextToken,
              nowEpoch,
            },
            limit: 1,
          });
          myFeedData = myFeedData.concat(feedData.data.feedByShowAfter.items);
          myNextToken = feedData.data.feedByShowAfter.nextToken;
        } else {
          const feedData = await API.graphql({
            query: inboxQueryNoDeleted,
            variables: {
              userID: global.me.id,
              now: Utilities.endOfToday(now),
              nextToken: myNextToken,
              nowEpoch,
            },
            limit: 1,
          });
          myFeedData = myFeedData.concat(feedData.data.feedByShowAfter.items);
          myNextToken = feedData.data.feedByShowAfter.nextToken;
        }
      }
      console.log('myFeedData: ', myFeedData);

      if (!resetList && this.lastFeedType === feedType) {
        filteredFeed = myFeed;
      }

      if (ourFeedFilter === 2) {
        // Show everything
        for (const feedItem of myFeedData) {
          console.log('createdByUser: ', feedItem.activityFeed.createdByUser);
          console.log(
            'feedItem.activityFeed.isDeleted: ',
            feedItem.activityFeed.isDeleted
          );
          if (feedItem.activityFeed.isDeleted) {
            continue;
          }
          if (feedItem.activityFeed.createdByUser != null) {
            // feedItem.avatarFile = await getUserAvatar(feedItem.activityFeed.createdByUser.id, feedItem.activityFeed.createdByUser.imageName);
            feedItem.initials = feedItem.activityFeed.createdByUser.initials;
            feedItem.name = feedItem.activityFeed.createdByUser.name;
            // console.log ("&&&&&title: ", feedItem.activityFeed.createdByUser.title);
            if (feedItem.activityFeed.createdByUser.title != null) {
              feedItem.userTitle = feedItem.activityFeed.createdByUser.title;
            } else {
              feedItem.userTitle = '';
            }
          } else {
            // feedItem.avatarFile = '/images/default-avatar.png';
            feedItem.initials = 'PAI';
            feedItem.name = 'PaivBot';
            feedItem.userTitle = '';
          }
          filteredFeed[filteredFeed.length] = feedItem;
        }
      } else {
        //
        for (const feedItem of myFeedData) {
          // console.log ("####feedItem: ", feedItem.activityFeed.createdByUser);
          console.log(
            'feedItem.activityFeed.isDeleted: ',
            feedItem.activityFeed.isDeleted
          );
          if (feedItem.activityFeed.isDeleted) {
            continue;
          }
          if (feedItem.isRead === (ourFeedFilter === 1)) {
            if (
              feedItem.activityFeed !== null &&
              feedItem.activityFeed.createdByUser !== null
            ) {
              // feedItem.avatarFile = await getUserAvatar(feedItem.activityFeed.createdByUser.id, feedItem.activityFeed.createdByUser.imageName);
              feedItem.initials = feedItem.activityFeed.createdByUser.initials;
              feedItem.name = feedItem.activityFeed.createdByUser.name;
              // console.log ("&&&&&title: ", feedItem.activityFeed.createdByUser.title);
              if (feedItem.activityFeed.createdByUser.title != null) {
                feedItem.userTitle = feedItem.activityFeed.createdByUser.title;
              } else {
                feedItem.userTitle = '';
              }
            } else {
              // console.log ("###default feeditem");
              // feedItem.avatarFile = '/images/default-avatar.png';
              feedItem.initials = 'PAI';
              feedItem.name = 'PaivBot';
              feedItem.userTitle = '';
            }
            filteredFeed[filteredFeed.length] = feedItem;
          }
        }
      }

      const missionIDs = [];
      for (const feedItem of filteredFeed) {
        console.log('feedItem: ', feedItem);
        if (feedItem.activityFeed.changedEmoticon != null) {
          feedItem.activityFeed.changedEmoticon.color = 'gray';
          feedItem.activityFeed.changedEmoticon.symbol = '?';
          for (const emoticon of global.appSettings.emoticons) {
            if (emoticon.key === feedItem.activityFeed.changedEmoticon.type) {
              feedItem.activityFeed.changedEmoticon.color = emoticon.color;
              feedItem.activityFeed.changedEmoticon.symbol = emoticon.symbol;
              break;
            }
          }
          // feedItem.activityFeed.changedEmoticon.avatarFile = await getUserAvatar(feedItem.activityFeed.changedEmoticon.user.id, feedItem.activityFeed.changedEmoticon.user.imageName);
        }
        if (feedItem.activityFeed.changedCommentEmoticon != null) {
          feedItem.activityFeed.changedCommentEmoticon.color = 'gray';
          feedItem.activityFeed.changedCommentEmoticon.symbol = '?';
          for (const emoticon of global.appSettings.emoticons) {
            if (
              emoticon.key === feedItem.activityFeed.changedCommentEmoticon.type
            ) {
              feedItem.activityFeed.changedCommentEmoticon.color =
                emoticon.color;
              feedItem.activityFeed.changedCommentEmoticon.symbol =
                emoticon.symbol;
              break;
            }
          }
          // feedItem.activityFeed.changedCommentEmoticon.avatarFile = await getUserAvatar(feedItem.activityFeed.changedCommentEmoticon.user.id, feedItem.activityFeed.changedCommentEmoticon.user.imageName);
        }
        // if (feedItem.activityFeed.changedComment != null) {
        //   feedItem.activityFeed.changedComment.avatarFile = await getUserAvatar(feedItem.activityFeed.changedComment.user.id, feedItem.activityFeed.changedComment.user.imageName);
        // }

        //
        //  Filter out the child comments (so we only see the parents in the first level of the feed
        //
        const filteredComments = [];

        for (const comment of feedItem.activityFeed.comments.items) {
          console.log('***comment: ', comment);

          if (comment.parentComment == null && !comment.isDeleted) {
            filteredComments[filteredComments.length] = comment;
          }
        }
        feedItem.activityFeed.comments.items = filteredComments;

        if (feedItem.activityFeed.chartType === 'missionLeader') {
          const event = JSON.parse(feedItem.activityFeed.event);
          // console.log ("event: ", event);
          if (missionIDs.includes(event.missionID)) {
            // console.log ("!!blanking dup");
            feedItem.activityFeed.skipDetails = true;
          } else {
            feedItem.activityFeed.skipDetails = false;
            missionIDs[missionIDs.length] = event.missionID;
          }
        }
      }

      // setMyFeed(filteredFeed);
      // setNextToken(myNextToken);
      // setLoadingFeed(false);
      // setLastFeedType(feedType);
      console.log('**loop**');
      if (feedType === 'inbox' || feedType === 'feed_and_inbox') {
        const count = filteredFeed.length;
        if (global.notificationCount !== count) {
          global.notificationCount = count;
          for (const handler of global.updateNotificationHandler) {
            console.log('updating handler count');
            handler(count);
          }
          for (const handler of global.refreshOnNotificationHandler) {
            console.log('refreshOnNotificationHandler');
            handler(count);
          }
        }
      }
    } catch (err) {
      console.error('fetchFeed(): error fetching feed', err);
    }
    this.isFetchingFeed = false;
    this.lastFeedType = feedType;
    return [myNextToken, filteredFeed];
  }
}
