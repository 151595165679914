import React from 'react';
import {
  DimensionTimeframe,
  DimensionTimeframeValues,
  KPILeaderboardState,
} from '../../_types';
import {
  CustomSelect,
  CustomFilterContainer,
  CustomFilterTitle,
} from '../KPIFilter.styles';
import { getDimensionValue } from '../../_kpiLeaderboardHelpers';

interface FilterTimeframeProps {
  kpiLeaderboardState: KPILeaderboardState;
  onChangeKpiLeaderboardState: (
    kpiLeaderboardState: KPILeaderboardState
  ) => void;
  resetTokens: () => void;
}

const timeframes = [
  { label: 'Yearly', value: DimensionTimeframeValues.Year },
  { label: 'Monthly', value: DimensionTimeframeValues.Month },
  { label: 'Weekly', value: DimensionTimeframeValues.Week },
  { label: 'Daily', value: DimensionTimeframeValues.Day },
];

const FilterTimeframe = ({
  kpiLeaderboardState,
  onChangeKpiLeaderboardState,
  resetTokens,
}: FilterTimeframeProps) => {
  return (
    <CustomFilterContainer>
      <CustomFilterTitle>Timeframe</CustomFilterTitle>
      <CustomSelect
        value={kpiLeaderboardState.dimensionTimeframe}
        onChange={(e) => {
          onChangeKpiLeaderboardState({
            ...kpiLeaderboardState,
            dimensionTimeframe: e.target.value as DimensionTimeframe,
            dimensionValue: getDimensionValue(
              e.target.value as DimensionTimeframe
            ),
            currentPage: 0,
          });
          resetTokens();
        }}
      >
        {timeframes.map((timeframeTab) => {
          return (
            <option key={timeframeTab.value} value={timeframeTab.value}>
              {timeframeTab.label}
            </option>
          );
        })}
      </CustomSelect>
    </CustomFilterContainer>
  );
};

export default FilterTimeframe;
