import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const ApplicationButton = styled(Button)(() => ({
  ':hover': {
    backgroundColor: 'white',
    color: '#2C2C2C',
  },
}));

export default class StyledButton extends React.Component {
  render() {
    return (
      <ApplicationButton
        {...this.props}
        centerRipple={true}
        color="main"
        variant="contained"
      >
        {this.props.children}
      </ApplicationButton>
    );
  }
}
