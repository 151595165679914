import { format, isValid } from 'date-fns';

const localToISODateFormat = (date: Date): string => {
  if (!date || !isValid(date)) {
    return '';
  }

  const dateVal = format(date, 'yyyy-MM-dd');
  const timeVal = format(date, 'HH:mm:ss');

  return `${dateVal}T${timeVal}.000Z`;
};

export default localToISODateFormat;
