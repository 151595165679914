import React from 'react';
import NewGameState from '../../../_types/NewGameState';
import CustomTextInput from '../../../custom-inputs/CustomTextInput';
import { InputBodyContainer } from '../../../NewGame.styles';
import { DateContainer } from '../../About/About.styles';
import CustomDatePicker from '../../../custom-inputs/CustomDatePicker';
import { /* format, */ formatISO, sub } from 'date-fns';
import RoundRules from './RoundRules';
import RoundState from '../../../_types/RoundState';
import RewardType from '../../../_types/RewardType';
import RoundRewards from './RoundRewards';
import {
  ButtonContainer,
  NextRoundButton,
  NextRoundIcon,
} from './Round.styles';
import RoundErrorMessagesState from '../../../_types/RoundErrorMessagesState';
import moment from 'moment';

interface Props {
  newGameState: NewGameState;
  onChangeRoundState: (roundState: RoundState) => void;
  roundState: RoundState;
  kpis: { label: string; value: string; rule: any }[];
  onChangeNewGameState: (newGameState: NewGameState) => void;
  shouldDisableNextButton: boolean;
  rewards: RewardType[];
  onNextRound: () => void;
  shouldShowNextRoundButton: boolean;
  tabNo: number;
  errorMessages: RoundErrorMessagesState;
}

const RoundView: React.FC<Props> = ({
  roundState,
  onChangeRoundState,
  kpis,
  rewards,
  onNextRound,
  shouldShowNextRoundButton,
  onChangeNewGameState,
  newGameState,
  tabNo,
  errorMessages,
  shouldDisableNextButton,
}) => {
  const previousRoundValues = newGameState.bracket.roundValues[tabNo - 2];
  const disabledBeforeDateForStartDatePicker =
    previousRoundValues && previousRoundValues.endDate
      ? formatISO(sub(new Date(previousRoundValues.endDate), { days: 1 }))
      : roundState.startDate
      ? new Date(roundState.startDate).toISOString()
      : '';

  const disabledBeforeDateForEndDatePicker = roundState.startDate
    ? formatISO(sub(new Date(roundState.startDate), { days: 1 }))
    : '';

  return (
    <InputBodyContainer>
      <div
        style={{
          fontWeight: 600,
          paddingBottom: 10,
          marginBottom: 16,
          borderBottom: '1px solid #D0D2D8',
        }}
      >
        Round {tabNo}
      </div>
      <CustomTextInput
        label="Round Name"
        placeholder="E.g. Knocking door challenge"
        value={roundState.bracketName}
        onChange={(newValue) => {
          onChangeRoundState({ ...roundState, bracketName: newValue });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].bracketName =
              newValue;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              bracketName: newValue,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
      />
      <DateContainer>
        <CustomDatePicker
          type="Date"
          label="Start date"
          onChange={(newValue: string) => {
            onChangeRoundState({
              ...roundState,
              // startDate: format(new Date(newValue), 'MM/dd/yyyy HH:mm'),
              startDate: moment(newValue).startOf('day').toDate().toISOString(),
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].startDate =
                // format(new Date(newValue), 'MM/dd/yyyy HH:mm');
                moment(newValue).startOf('day').toDate().toISOString();
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                // startDate: format(new Date(newValue), 'MM/dd/yyyy HH:mm'),
                startDate: moment(newValue)
                  .startOf('day')
                  .toDate()
                  .toISOString(),
              });
            }

            // NOTE: Need to set newGameState's start date to start of 1st round
            if (tabNo === 1) {
              newGameStateCopy.startDate = moment(newValue)
                .startOf('day')
                .toDate()
                .toISOString();
            }

            onChangeNewGameState(newGameStateCopy);
          }}
          value={
            roundState.startDate
              ? // ? format(new Date(roundState.startDate), 'MM/dd/yyyy HH:mm')
                moment(roundState.startDate)
                  .startOf('day')
                  .toDate()
                  .toISOString()
              : ''
          }
          disableBeforeDate={disabledBeforeDateForStartDatePicker}
          errorMessage={errorMessages.startDate}
        />
        <CustomDatePicker
          type="Date"
          label="End date"
          onChange={(newValue: string) => {
            onChangeRoundState({
              ...roundState,
              // endDate: format(new Date(newValue), 'MM/dd/yyyy HH:mm'),
              endDate: moment(newValue).startOf('day').toDate().toISOString(),
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].endDate =
                // format(
                //   new Date(newValue),
                //   'MM/dd/yyyy HH:mm'
                // );
                moment(newValue).startOf('day').toDate().toISOString();
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                // endDate: format(new Date(newValue), 'MM/dd/yyyy HH:mm'),
                endDate: moment(newValue).startOf('day').toDate().toISOString(),
              });
            }

            // NOTE: Need to set newGameState's end date to end of last round (when next round button is disabled)
            if (!shouldShowNextRoundButton) {
              newGameStateCopy.endDate = moment(newValue)
                .startOf('day')
                .toDate()
                .toISOString();
            }

            onChangeNewGameState(newGameStateCopy);
          }}
          value={
            roundState.endDate
              ? // ? format(new Date(roundState.endDate), 'MM/dd/yyyy HH:mm')
                moment(roundState.endDate).startOf('day').toDate().toISOString()
              : ''
          }
          disableBeforeDate={disabledBeforeDateForEndDatePicker}
          errorMessage={errorMessages.endDate}
        />
      </DateContainer>

      <RoundRules
        kpis={kpis}
        onChangeRoundState={onChangeRoundState}
        roundState={roundState}
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
        tabNo={tabNo}
      />
      <RoundRewards
        rewards={rewards}
        onChangeRoundState={onChangeRoundState}
        roundState={roundState}
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
        tabNo={tabNo}
      />
      {shouldShowNextRoundButton && (
        <ButtonContainer>
          <NextRoundButton
            disabled={shouldDisableNextButton}
            onClick={onNextRound}
          >
            <div>Next Round</div>
            {!shouldDisableNextButton && (
              <NextRoundIcon src="/images/purple-right-arrow.svg" />
            )}
          </NextRoundButton>
        </ButtonContainer>
      )}
    </InputBodyContainer>
  );
};

export default RoundView;
