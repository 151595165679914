import React, { useState } from 'react';
import Item from '../_types/Item';
import Me from '../../../_types/Me';
import MyPerson from '../_types/MyPerson';
import EditPostView from './EditPost.view';
import UpdatePostArgs from '../_types/UpdatePostArgs';
import { LOG } from '../../../common/Utilities';
import updatePost from '../feed-helpers/updatePost';
import { GamifyToast } from '../../../common/CustomToasts';
import { Storage } from 'aws-amplify';
import getPost from '../feed-helpers/getPost';

interface Props {
  feedItem: Item;
  me: Me;
  myPersons: MyPerson[];
  isEditing: boolean;
  onChangeIsEditing: (newIsEditing: boolean) => void;
  onSave: (newPost: Item) => void;
}

const EditPostContainer = ({
  feedItem,
  me,
  myPersons,
  onChangeIsEditing,
  onSave,
}: Props) => {
  const INITIAL_POST: UpdatePostArgs = {
    id: feedItem.activityFeed.id,
    header: feedItem.activityFeed.header,
    details: feedItem.activityFeed.details,
    imageName: feedItem.activityFeed.imageName,
    imageType: feedItem.activityFeed.imageType,
    showAfterEpoch: 0,
    showUntilEpoch: 0,
  };
  const [post, setPost] = useState<UpdatePostArgs>(INITIAL_POST);
  const [filePreview, setFilePreview] = useState<any>(post.imageName);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [openGif, setOpenGif] = useState(false);

  const onOpenCloseGif = () => {
    setOpenGif(!openGif);
  };

  const onChangePost = (post: UpdatePostArgs) => {
    if (post.imageType === 'gif_url') {
      setFilePreview(post.imageName);
    }

    setPost(post);
  };

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log('files', files);
    if (files && files.length > 0) {
      try {
        const selectedFile = files[0];
        const filePreview = URL.createObjectURL(selectedFile);

        let imageType = '';
        const fileType = selectedFile.type.split('/')[0];
        if (fileType === 'video') {
          imageType = 'video';
        } else if (fileType === 'image') {
          imageType = 'picture';
        }

        setPost({ ...post, imageType, imageName: selectedFile.name });
        setSelectedFile(selectedFile);
        setFilePreview(filePreview);
      } catch (error) {
        LOG.error(error);
      }
    }
  };

  const onUpdatepost = async () => {
    try {
      if (post.header.length < 1) {
        GamifyToast.error(
          'You must enter at least 2 characters for a comment.'
        );
        return;
      }

      GamifyToast.success('Updating content');
      await updatePost(post);
      const newPost = await getPost(post.id);
      onSave(newPost);

      if (selectedFile) {
        await Storage.put(selectedFile.name, selectedFile, {
          contentType: selectedFile.type,
        });
      }

      GamifyToast.success('Content updated');
      onChangeIsEditing(false);
    } catch (error) {
      GamifyToast.error('Something went wrong updating the post.');
      LOG.debug(error);
    }
  };

  return (
    <EditPostView
      feedItem={feedItem}
      post={post}
      me={me}
      myPersons={myPersons}
      filePreview={filePreview}
      openGif={openGif}
      onOpenCloseGif={onOpenCloseGif}
      onChangePost={onChangePost}
      onUpload={onUpload}
      onUpdatepost={onUpdatepost}
      onRemoveFilePreview={() => setFilePreview(null)}
      onChangeIsEditing={onChangeIsEditing}
    />
  );
};

export default EditPostContainer;
