// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
//
// TrophyCase
//

import React, { useState, useEffect } from 'react';
import { Text, View, Image } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import { LOG, formatDate } from '../../common/Utilities';
import DisplayMedia from '../../components/DisplayMedia';

export function FeedTrophy(props) {
  console.log('++++++Trophy: ', props);
  const trophyData = JSON.parse(props.trophyData);
  const imageName = trophyData.trophyImageName;
  const title = trophyData.trophyTitle;
  const description = trophyData.missionName;
  const createdAt = trophyData.createdAt;

  return (
    <View style={{ alignItems: 'center' }}>
      {imageName !== '' ? (
        <Image
          source={{ uri: imageName }}
          style={{ height: 150, width: 150 }}
        />
      ) : (
        <View style={{ height: 150, width: 150, backgroundColor: 'gray' }} />
      )}
      <Text textAlign="center">{title}</Text>
      {description.length > 0 && <Text textAlign="center">{description}</Text>}
      <Text textAlign="center">{formatDate(createdAt, 'MMM YYYY')}</Text>
    </View>
  );
}

export function Trophy(props) {
  // console.log  ("++++++Trophy: ", props);
  const imageName = props.imageName;
  const title = props.title;
  const description = props.description;
  const createdAt = props.createdAt;

  useEffect(() => {
    console.log("Can't be empty");
  }, []);

  return (
    <View
      style={{
        width: 110,
        height: 170,
        padding: 5,
        borderRadius: 8,
        borderBottomWidth: 0,
        borderBottomColor: 'gray',
        alignItems: 'center',
      }}
    >
      {imageName !== '' ? (
        <View>
          <DisplayMedia
            width={75}
            height={75}
            imageName={imageName}
            imageType={'trophy'}
          />
        </View>
      ) : (
        <View style={{ height: 75, width: 75, backgroundColor: 'gray' }} />
      )}
      <Text textAlign="center" style={{ paddingRight: '25%' }}>
        {title}
      </Text>
      {description.length > 0 && (
        <Text
          style={{ flex: 1, flexDirection: 'row', paddingRight: '25%' }}
          textAlign="center"
        >
          {description}
        </Text>
      )}
      {createdAt !== '' && (
        <Text textAlign="center" style={{ paddingRight: '25%' }}>
          {formatDate(createdAt, 'MMM YYYY')}
        </Text>
      )}
    </View>
  );
}

export default function TrophyCase(props) {
  LOG.debug('TrophyCase: ', props);

  // Code for retrieval of trophies for the provided user goes here
  //
  //
  const [trophyData, setTrophyData] = useState(false);
  const [lastPersonDimension, setLastPersonDimension] = useState('');

  // Below trophy data will be provided by the backend for each user as provided in function params
  async function getData(userID, dimension) {
    LOG.debug('***getData: ', userID);

    // Get all of the possilbe KPI's
    //
    //  TODO:  Refactor to use an index
    //
    const KPIQuery = gql`
      query MyQuery($orgID: ID!) {
        listKPIRules(
          filter: {
            orgID: { eq: $orgID }
            isDeleted: { eq: false }
            isHidden: { eq: false }
          }
        ) {
          items {
            id
            title
            sourceType
            action
            valueUnitPlural
            isDeleted
          }
        }
      }
    `;
    const KPIRes = await API.graphql({
      query: KPIQuery,
      variables: { orgID: global.me.orgID },
    });
    LOG.debug('KPIRes: ', KPIRes);

    const KPIList = {};
    for (const KPI of KPIRes.data.listKPIRules.items) {
      LOG.debug('KPI: ', KPI);
      KPI.isFound = false;
      KPIList[KPI.id] = KPI;
    }

    LOG.debug('KPIList: ', KPIList);
    //
    //  Get all of the KPI's and trophies for this user
    //
    const listKPIsAndTrophies = gql`
      query listKPIsAndTrophies($userID: ID!, $dimension: String!) {
        getUser(id: $userID) {
          kpis(filter: { dimension: { beginsWith: $dimension } }) {
            items {
              dimensionID
              dimension
              val
              updatedAt
              KPIRule {
                id
                title
                sourceType
                action
                valueUnitPlural
                isDeleted
              }
            }
          }
          trophies {
            items {
              id
              createdAt
              description
              place
              title
              imageName
              imageType
            }
          }
        }
      }
    `;
    const res = await API.graphql({
      query: listKPIsAndTrophies,
      variables: { userID, dimension },
    });
    LOG.debug('trophy res: ', res);
    const user = res.data.getUser;

    //
    // Update our KPIList with the KPI's where the user has a value
    //
    for (const kpi of user.kpis.items) {
      LOG.debug('kpi: ', kpi);
      if (kpi.KPIRule.id in KPIList) {
        KPIList[kpi.KPIRule.id].isFound = true;
      } else {
        LOG.warn('Missing KPI Rule: ', kpi.KPIRule.id);
      }
    }

    // For every KPI that does NOT have a value, append that KPI to the KPI's for the user, but with a 0 value

    for (const key of Object.keys(KPIList)) {
      if (!KPIList[key].isFound) {
        const fakeKPI = {
          dimensionID: KPIList[key].id,
          dimension: 'person:' + props.dimension,
          val: 0,
          updatedAt: '',
          KPIRule: {
            id: KPIList[key].id,
            title: KPIList[key].title,
            isDeleted: KPIList[key].isDeleted,
            sourceType: KPIList[key].sourceType,
            valueUnitPlural: KPIList[key].valueUnitPlural,
            action: KPIList[key].action,
          },
        };

        LOG.debug('fakeKPI: ', fakeKPI);
        user.kpis.items[user.kpis.items.length] = fakeKPI;
      }
    }

    const sortedKPIs = user.kpis.items.sort((a, b) => {
      if (a.KPIRule.title.toLowerCase() < b.KPIRule.title.toLowerCase()) {
        return -1;
      }
      if (a.KPIRule.title.toLowerCase() > b.KPIRule.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    user.kpis.items = sortedKPIs;
    LOG.debug('!!sortedKPIs: ', sortedKPIs);
    setTrophyData(user);
  }

  if (lastPersonDimension !== props.userID + props.dimension) {
    getData(props.userID, 'person:' + props.dimension);
    setLastPersonDimension(props.userID + props.dimension);
  }

  // LOG.debug ("****screenWidth: ", screenWidth);
  // LOG.debug ("****Dimensions.get('window').width: ", Dimensions.get('window').width);

  let sortedTrophies = [];
  if (trophyData) {
    const unsortedTrophies = trophyData.trophies.items;

    sortedTrophies = unsortedTrophies.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }

  return (
    <View
      styles={{ flex: 1, padding: 0, width: '100%' }}
      nestedScrollEnabled={true}
      keyboardShouldPersistTaps="always"
    >
      {trophyData && (
        <React.Fragment>
          <Text
            style={{
              fontSize: 20,
              textAlign: 'center',
              textTransform: 'uppercase',
              paddingTop: 10,
              paddingBottom: 1,
              paddingLeft: 4,
            }}
          >
            Trophy Case
          </Text>
          <View
            style={{
              flex: 1,
              alignSelf: 'stretch',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 5,
            }}
          >
            {sortedTrophies.map((rowData, i) => (
              <View key={i} style={{ height: 200 }}>
                <Trophy
                  id={rowData.id}
                  imageName={rowData.imageName}
                  title={rowData.title}
                  description={rowData.description}
                  createdAt={rowData.createdAt}
                />
              </View>
            ))}
          </View>
        </React.Fragment>
      )}
    </View>
  );
}
