import React, { useState } from 'react';
import { Icon } from '../Actions.styles';
import {
  EmoticonCountText,
  EmoticonSymbol,
  EmoticonSymbolAndTextContainer,
  ReactionsContainer,
  GlobalEmoticonsContainer,
  ViewContainer,
  GlobalEmoticonsItem,
  GlobalEmoticonsItemSymbol,
  GlobalEmoticonsItemIndicator,
} from './EmoticonsAction.styles';
import didUserReactWithParticularEmoticon from '../../../../feed-helpers/didUserReactWithParticularEmoticon';
import Emoticon from '../../../../_types/Emoticon';
import ReactorsList from './ReactorsList';

interface Props {
  emoticonsCounts: Record<string, number>;
  globalEmoticons: {
    color: string;
    key: string;
    symbol: string;
  }[];
  shouldHeartBeFilled: boolean;
  onSelectEmoticon: (emoticonType: string) => void;
  userId: string;
  emoticons: Emoticon[];
  openReactions: boolean;
  onOpenReactions: () => void;
}

const EmoticonsActionView = ({
  emoticonsCounts,
  globalEmoticons,
  shouldHeartBeFilled,
  onSelectEmoticon,
  userId,
  emoticons,
  openReactions,
  onOpenReactions,
}: Props) => {
  const [showReactorsList, setShowReactorsList] = useState(false);

  return (
    <ViewContainer>
      <ReactorsList
        show={showReactorsList}
        onClose={() => setShowReactorsList(false)}
        emoticons={emoticons}
        globalEmoticons={globalEmoticons}
        emoticonsCounts={emoticonsCounts}
      />
      <EmoticonSymbolAndTextContainer>
        <Icon
          onClick={(e) => {
            // disregard to onclick of the parent element
            e.stopPropagation();

            onSelectEmoticon('love');
          }}
          src={`/images/heart-${
            shouldHeartBeFilled ? 'filled' : 'outline'
          }.svg`}
        />
        {emoticonsCounts.love ? (
          <EmoticonCountText
            onClick={(e) => {
              e.stopPropagation();
              setShowReactorsList(!showReactorsList);
            }}
          >
            {emoticonsCounts.love}
          </EmoticonCountText>
        ) : null}
      </EmoticonSymbolAndTextContainer>
      {globalEmoticons.map((emoticon, index: number) => {
        // if a global emoticon is not in the emoticons, and emoticon is love, or count is 0, return null
        if (
          !(emoticon.key in emoticonsCounts) ||
          emoticon.key === 'love' ||
          !emoticonsCounts[emoticon.key]
        )
          return null;

        return (
          <EmoticonSymbolAndTextContainer key={index}>
            <EmoticonSymbol
              onClick={(e) => {
                e.stopPropagation();
                onSelectEmoticon(emoticon.key);
                onOpenReactions();
              }}
              color={emoticon.color}
            >
              {emoticon.symbol}
            </EmoticonSymbol>
            <EmoticonCountText
              onClick={(e) => {
                e.stopPropagation();
                setShowReactorsList(!showReactorsList);
              }}
            >
              {emoticonsCounts[emoticon.key]}
            </EmoticonCountText>
          </EmoticonSymbolAndTextContainer>
        );
      })}
      <ReactionsContainer>
        <Icon
          onClick={(e) => {
            e.stopPropagation();
            onOpenReactions();
          }}
          src={`/images/emoji-outline.svg`}
        />
        {openReactions ? (
          <GlobalEmoticonsContainer
            position={emoticons.length >= 4 ? 'left' : 'right'}
          >
            {globalEmoticons.map((emoticon) => {
              if (emoticon.key === 'love') return null;

              return (
                <GlobalEmoticonsItem
                  key={emoticon.key}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectEmoticon(emoticon.key);
                    onOpenReactions();
                  }}
                >
                  <GlobalEmoticonsItemSymbol>
                    {emoticon.symbol}
                  </GlobalEmoticonsItemSymbol>
                  <GlobalEmoticonsItemIndicator
                    isActive={didUserReactWithParticularEmoticon({
                      userId,
                      emoticons,
                      type: emoticon.key,
                    })}
                  />
                </GlobalEmoticonsItem>
              );
            })}
          </GlobalEmoticonsContainer>
        ) : null}
      </ReactionsContainer>
    </ViewContainer>
  );
};

export default EmoticonsActionView;
