import GameDimensionType from '../_types/GameDimensionType';
import GameDimensionTypeValues from '../_types/GameDimensionTypeValues';
import getLeaderboards from './getLeaderboards';
import getListCircleLeaderboard from './getListCircleLeaderboard';

const getGamesLeaderboards = async (args: {
  gameDimensionType: GameDimensionType;
  orgID: string;
  showCompleted: boolean;
}) => {
  const { gameDimensionType, orgID, showCompleted } = args;

  switch (gameDimensionType) {
    case GameDimensionTypeValues.INDIVIDUAL: {
      const result = await getLeaderboards({
        showCompleted,
      });

      return result;
    }
    case GameDimensionTypeValues.TEAM: {
      const result = await getListCircleLeaderboard({
        showCompleted,
        orgID,
      });

      return result;
    }

    default:
      console.error('Invalid dimension type');
  }
};

export default getGamesLeaderboards;
