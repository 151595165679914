import React from 'react';
import { useParams } from 'react-router-dom';

export const withUseParams = (Component) => {
  const Wrapper = (props) => {
    const params = useParams();

    return <Component params={params} {...props} />;
  };

  return Wrapper;
};
