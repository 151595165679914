import React from 'react';
import {
  RewardsContainer,
  RewardsItemsContainer,
  RewardsTitle,
} from '../NormalDetails.styles';
import RewardCard from './RewardCard';
import Game from '../../../_types/Game';

interface Props {
  game: Game;
}

const Rewards = ({ game }: Props) => {
  const rewards = game.rewards.items;
  const sortedRewards =
    rewards.length > 0 ? rewards.sort((a, b) => a.place - b.place) : [];
  const shouldShowRewards = rewards.every(
    (item) => Object.keys(item.reward).length > 0
  );

  if (!shouldShowRewards) {
    return null;
  }

  return (
    <RewardsContainer>
      <RewardsTitle>Rewards</RewardsTitle>
      <RewardsItemsContainer>
        {rewards.length > 0
          ? sortedRewards.map((item, index) => (
              <RewardCard
                key={item.id}
                place={index + 1}
                rewardID={item.reward.id}
              />
            ))
          : null}
      </RewardsItemsContainer>
    </RewardsContainer>
  );
};

export default Rewards;
