import React from 'react';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';

// TO DO: update to include images/selects/inputs?
export default function DynamicConfirmModal(props) {
  const {
    // isVisible,
    showDynamicConfirmModal,
    setShowDynamicConfirmModal,
    zIndex = 10,
    // icon = "question",
    // iconColor,
    // iconBackground = "#FAC8B2",
    title,
    titleColor = '#222428',
    subtitle,
    subtitleColor = '#868686',
    leftAlignText = false,
    noConfirmButton = false,
    confirmButtonColor = '#FF6A00',
    confirmButtonText = 'Confirm',
    confirmButtonTextColor = 'white',
    confirmButtonBorder = 0,
    noCancelButton = false,
    cancelButtonColor = 'white',
    cancelButtonText = 'Cancel',
    canceButtonTextColor = '#323232',
    cancelButtonBorder = '1px solid #cfcfcf',
    isSaving,
    onConfirm,
    onCancel,
  } = props;

  const handleCloseClick = () => {
    setShowDynamicConfirmModal(false);
  };

  // console.log('DynamicConfirmModal leftAlignText: ', leftAlignText);
  // console.log('DynamicConfirmModal isSaving: ', isSaving);

  return (
    <Backdrop
      sx={{ zIndex }}
      open={showDynamicConfirmModal}
      onClick={(e) => {
        e.stopPropagation();
        if (!e.target.closest('.confirm-button')) {
          handleCloseClick();
        }
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          width: 624,
          maxHeight: '65vh',
          padding: '24px 40px 32px 24px',
          borderRadius: 8,
          overflowY: 'auto',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: leftAlignText ? 'flex-start' : 'center',
          }}
        >
          <Typography
            style={{
              color: titleColor,
              fontSize: 28,
              fontWeight: 700,
              textAlign: leftAlignText ? 'left' : 'center',
              lineHeight: '48px',
              letterSpacing: '-0.02em',
              marginBottom: 16,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: leftAlignText ? 'flex-start' : 'center',
          }}
        >
          <Typography
            style={{
              color: subtitleColor,
              fontSize: 16,
              fontWeight: 500,
              textAlign: leftAlignText ? 'flex-start' : 'center',
              marginBottom: 24,
            }}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent:
              noCancelButton || noConfirmButton ? 'center' : 'space-between',
          }}
        >
          {!noCancelButton && (
            <Box
              onClick={(e) => {
                e.stopPropagation();
                if (onCancel) {
                  onCancel();
                } else {
                  handleCloseClick();
                }
              }}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: cancelButtonColor,
                width: 262,
                padding: 12,
                border: cancelButtonBorder,
                borderRadius: 8,
              }}
            >
              <Typography
                style={{
                  color: canceButtonTextColor,
                  fontSize: 16,
                  fontWeight: 700,
                }}
              >
                {cancelButtonText}
              </Typography>
            </Box>
          )}
          {!noConfirmButton && (
            <Box
              className={'confirm-button'}
              onClick={(e) => {
                e.stopPropagation();
                onConfirm();
              }}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: confirmButtonColor,
                width: 262,
                padding: 12,
                border: confirmButtonBorder,
                borderRadius: 8,
              }}
            >
              {isSaving ? (
                <CircularProgress
                  style={{
                    color: confirmButtonTextColor,
                    width: 24,
                    height: 24,
                  }}
                ></CircularProgress>
              ) : (
                <Typography
                  style={{
                    color: confirmButtonTextColor,
                    fontSize: 16,
                    fontWeight: 700,
                  }}
                >
                  {confirmButtonText}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Backdrop>
  );
}
