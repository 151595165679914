import { API } from 'aws-amplify';
import Contestant from '../_types/Contestant';
import { LOG } from '../../../common/Utilities';
import getUserAvatar from '../../../common/utils/getUserAvatar';

export const SEARCH_USERS_QUERY = `
query MyQuery($orgID: ID!) {
  searchUsers(
    filter: { name: { wildcard: "*" }, orgID: { eq: $orgID } }
    limit: 999
    sort: { direction: asc, field: name }
  ) {
    items {
      id
      name
      imageName
      status
    }
    nextToken
  }
}
`;

type FetchContestantPersonsApiResponse = {
  data: {
    searchUsers: {
      items: {
        id: string;
        name: string;
        status: string;
        imageName: string;
      }[];
    };
  };
};

const getContestantPersons = async ({
  orgID,
}: {
  orgID: string;
}): Promise<Contestant[]> => {
  const contestants: Contestant[] = [];

  try {
    const results = (await API.graphql({
      query: SEARCH_USERS_QUERY,
      variables: { orgID },
    })) as FetchContestantPersonsApiResponse;

    const items = results.data.searchUsers.items;

    const filteredAndSortedItems = items
      // Get only active items
      .filter((item) => item.status === 'active')
      // Get only the needed data
      .map((item) => {
        return {
          label: item.name,
          value: item.id,
          imageName: item.imageName,
        };
      });

    const filteredItemsWithAvatar = [];

    for (let i = 0; i < filteredAndSortedItems.length; ++i) {
      const item = filteredAndSortedItems[i];
      const avatar = await getUserAvatar(item.imageName, '');
      filteredItemsWithAvatar.push({ ...item, avatar });
    }

    return filteredItemsWithAvatar;

    return filteredItemsWithAvatar;
  } catch (error) {
    LOG.error(error);
  }

  return contestants;
};

export default getContestantPersons;
