import React, { useState } from 'react';
import { CustomMenu, CustomMenuItem } from '../../common/StyledComponents';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { GamifyToast } from '../../common/CustomToasts';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
// import DealFormModal from './DealFormModal';

export default function DealNoteOptionsMenu({ props }) {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // console.log('deal options menu props: ', props);
  const {
    anchorEl,
    setAnchorEl,
    setEditNoteId,
    onTriggerSelectedDealRefetch,
    dealNoteId,
  } = props;

  //   console.log(`deal options ${dealNoteId}: `, props);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = (e) => {
    // console.log('deal note options handleEditClick dealNoteId: ', dealNoteId);
    e.stopPropagation();
    setEditNoteId(dealNoteId);
    setAnchorEl(null);
  };

  const handleDeleteClick = (e) => {
    // console.log('deal note options handleDeleteClick dealNoteId: ', dealNoteId);
    e.stopPropagation();
    setShowConfirmDelete(true);
    setAnchorEl(null);
  };

  const handleConfirmDeleteClick = async (dealNoteId) => {
    // console.log(
    //   'deal note options handleConfirmDeleteClick dealNoteId: ',
    //   dealNoteId
    // );
    setIsSaving(true);
    try {
      //
      //  Delete the deal note
      //
      await API.graphql(
        graphqlOperation(mutations.updateDealComments, {
          input: { id: dealNoteId, isDeleted: true },
        })
      );
      //   NOTE: There is no deleteDealNoteObject mutation, use updateDealComments instead
      //   await API.graphql(
      //     graphqlOperation(mutations.deleteDealObject, {
      //       id: dealID,
      //     })
      //   );

      setShowConfirmDelete(false);
      setIsSaving(false);
      GamifyToast.success(`Deal note deleted successfully`);

      setTimeout(() => {
        onTriggerSelectedDealRefetch();
      }, 250);
    } catch (err) {
      setShowConfirmDelete(false);
      setIsSaving(false);
      GamifyToast.error(`There was an error deleting your deal note`);
      console.error('deal options handleConfirmDeleteClick error: ', err);
    }
  };

  return (
    <>
      <DynamicConfirmModal
        showDynamicConfirmModal={showConfirmDelete}
        setShowDynamicConfirmModal={setShowConfirmDelete}
        zIndex={100002}
        title={`Are you sure you want to delete this note?`}
        subtitle={`This action cannot be undone.`}
        leftAlignText={true}
        confirmButtonColor={'#E35050'}
        isSaving={isSaving}
        onCancel={() => {
          setShowConfirmDelete(false);
        }}
        onConfirm={() => {
          // console.log('onConfirm dealID: ', dealID);
          handleConfirmDeleteClick(dealNoteId);
        }}
      ></DynamicConfirmModal>

      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => {
          e.stopPropagation();
          handleMenuClose();
        }}
      >
        <CustomMenuItem
          sx={{
            background: 'white',
            color: 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            console.log('notesCheck clicked Edit');
            handleEditClick(e);
          }}
          disableRipple={true}
        >
          Edit
        </CustomMenuItem>
        <CustomMenuItem
          sx={{
            background: 'white',
            color: 'red',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(e);
          }}
          disableRipple={true}
        >
          Delete
        </CustomMenuItem>
      </CustomMenu>
    </>
  );
}
