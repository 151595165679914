import React from 'react';
import DisplayMedia from '../../../../components/DisplayMedia';

interface Props {
  imageName: string;
  imageType: string;
}

const Media = ({ imageName, imageType }: Props) => {
  if (!imageName) {
    return null;
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DisplayMedia
        imageName={imageName}
        imageType={imageType}
        width={300}
        height={'100%'}
        borderRadius={0}
      />
    </div>
  );
};

export default Media;
