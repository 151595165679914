import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Storage } from 'aws-amplify';
import {
  Box,
  Button,
  CircularProgress,
  Input,
  InputAdornment,
  ImageList,
  ImageListItem,
  Switch,
  Typography,
} from '@mui/material';
import {
  GamifyIcon,
  PhotoIcon,
  RightArrowIcon,
  XIcon,
  DeleteIcon,
} from '../../../components/GamifyIcon';
import { generateRandomImageFileNameString } from '../../../common/Utilities';
import PowerSupplierForm from './PowerSupplierForm';
import { GamifyToast } from '../../../common/CustomToasts';
import { StyledSelect, StyledMenuItem } from '../../../common/StyledComponents';
// import { StyledSelect, StyledMenuItem } from '../users/StyledInputs';
import DisplayMedia from '../../../components/DisplayMedia';
import ImageCarousel from '../../../common/ImageCarousel';

export default function FlexFields(props) {
  const { dealTypeId, dealTypesConfigMap, dealId } = props;

  if (!dealTypeId) return;

  // NOTE: Need to create a mutable copy of the dealTypesConfig.flexFields for setupFlexFields because non-required lists need to have a 'No Selection' option added. Now that dealTypesConfigMap comes from redux it is immutable and needs this copy created other wise there will be an error for attempting to edit a 'read only' field
  const [mutableFlexFields, setMutableFlexFields] = useState([]);

  useEffect(() => {
    const tempMutableFlexFields = [];
    if (dealTypesConfigMap[dealTypeId]) {
      for (const field of dealTypesConfigMap[dealTypeId].flexFields) {
        const mutableField = { ...field };
        tempMutableFlexFields.push(mutableField);
      }
    }
    setMutableFlexFields(tempMutableFlexFields);
  }, [dealTypeId]);

  const [flexFields, setFlexFields] = useState([]);
  const [flexData, setFlexData] = useState(new Map());
  const [loadFlexFields, setLoadFlexFields] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  //   const [saveAttempted, setSaveAttempted] = useState(false); // Set to true (replaced w/ hasSubmitted prop)
  const [headersAndFields, setHeadersAndFields] = useState({});
  if (flexFields === 'test') {
    console.log('FlexFields flexFields: ', flexFields);
  }
  // console.log('FlexFields props.values: ', props.values);

  const capitalizeFirstLetter = (word) => {
    const n = word.length;

    if (n < 1) return word;

    if (word === 'kws') return 'KWs';

    return `${word.slice(0, 1).toUpperCase()}${word.slice(1, n)}`;
  };

  const iconsMapper = {
    about: 'about',
    area: 'area',
    kws: 'kws',
    files: 'files',
    power: 'power',
    info: 'about',
    images: 'files',
  };

  function setupFlexFields(fields, values) {
    // console.log('setupFlexFields fields: ', fields);
    // console.log('setupFlexFields values: ', values);
    let ourValues = {};
    // How ourValues are set in lyferize
    if (values != null && 'flexAttributes' in values) {
      ourValues = JSON.parse(values.flexAttributes);
    }
    // Added to account for already parsed flexFieldData on web
    if (values != null && 'flexFieldData' in values) {
      ourValues = values.flexFieldData;
    }
    if (ourValues == null) {
      console.error("Shouldn't have null ourValues");
    }
    const ourFlexFields = [];
    const ourFlexData = new Map();

    // console.log('setupFlexFields ourValues: ', ourValues);

    for (const field of fields) {
      // console.log('setupFlexFields field: ', field);
      // console.log(
      //   'setupFlexFields ourValues[field.name]: ',
      //   ourValues[field.name]
      // );
      if (field.type === 'Read Only') {
        continue;
      }
      if (!field.displayField) {
        continue;
      }
      let value = field.default;
      let listIndex = 0;
      let listIdVal;
      if (field.type === 'List') {
        value = field.label;
        // console.log('TESTList value: ', value);

        // Get listIndex using listIdVal if dealId because it is an existing deal with a value
        if (dealId) {
          listIdVal = values.flexFieldData[value];
          // console.log('TESTList field: ', field);

          // Add the 'No Selection' option to non-required lists (empty string for an id is 'No Selection', need to only do it once so specified that with && otherwise it will add { id: 'No Selection' } twice)
          if (!field.required && field.values[0].id !== 'No Selection') {
            field.values = [{ id: 'No Selection' }, ...field.values];
          }

          // Use listIdVal to get the listIndex for value setting later (iterate over field's values (1 until field.values.length b/c 'No Selection' added in at 0 index) until the id matches the listIdVal)
          for (let i = 1; i < field.values.length; i++) {
            // console.log('List option: ', field.values[i]);
            // console.log('List listIdVal: ', listIdVal);
            if (listIdVal === field.values[i].id) {
              // console.log('List Match! listIndex is now i: ', i);
              listIndex = i;
            }
          }

          // // Remove the { id: '' } if it's there to avoid messing up the actual Select's options later
          // if (field.values[0].id === '') field.values.shift();
        }
      }
      if (ourValues != null) {
        if (field.name in ourValues) {
          value = ourValues[field.name];
        }
      }
      if (field.type === 'List') {
        // ourFlexData.set(field.name, {
        //   type: field.type,
        //   val: value,
        //   id: value,
        //   required: field.required !== undefined ? field.required : false,
        // });
        // no val/id set if non-required list, default to 1st val/id if required list
        if (field.required) {
          ourFlexData.set(field.name, {
            type: field.type,
            val: dealId ? field.values[listIndex].label : field.values[0].label,
            id: dealId ? field.values[listIndex].id : field.values[0].id,
            required: field.required !== undefined ? field.required : false,
          });
        } else {
          // NOTE: Need to check if dealId to see if it's a deal edit or not, also need to check if listIndex isn't 0 to avoid setting to 'No Selection'
          ourFlexData.set(field.name, {
            type: field.type,
            val: dealId && listIndex !== 0 ? field.values[listIndex].label : '',
            id: dealId && listIndex !== 0 ? field.values[listIndex].id : '',
            required: field.required !== undefined ? field.required : false,
          });
          // console.log('ourFlexData: ', ourFlexData);
        }
      } else if (field.type === 'ImageList') {
        ourFlexData.set(field.name, {
          type: field.type,
          val:
            values.flexFieldData && Object.keys(values.flexFieldData).length > 0
              ? values.flexFieldData[field.name]
              : value,
          id: 'imageList',
          required: field.required !== undefined ? field.required : false,
        });
      } else if (field.type === 'Boolean') {
        ourFlexData.set(field.name, {
          type: field.type,
          val: values.flexFieldData ? values.flexFieldData[field.name] : false,
          id: field.name,
          required: field.required !== undefined ? field.required : false,
        });
      } else {
        ourFlexData.set(field.name, {
          type: field.type,
          val: values.flexFieldData ? values.flexFieldData[field.name] : value,
          id: field.name,
          required: field.required !== undefined ? field.required : false,
        });
      }
      ourFlexFields[ourFlexFields.length] = field;
    }

    // console.log('setupFlexFields ourFlexFields: ', ourFlexFields);
    // console.log('setupFlexFields ourFlexData: ', ourFlexData);

    const headersFlexFields = ourFlexFields.reduce(
      (acc, el) => {
        if (!el.heading || el.heading === '') {
          acc.noHeaders.push(el);
          return acc;
        }
        if (acc[el.heading]) {
          acc[el.heading].push(el);
        } else {
          acc[el.heading] = [el];
        }
        return acc;
      },
      { noHeaders: [] }
    );

    // console.log('setupFlexFields headersFlexFields: ', headersFlexFields);

    setHeadersAndFields(headersFlexFields);
    setFlexData(ourFlexData);
    setFlexFields(ourFlexFields);
    // console.log('setupFlexFields ourFlexData: ', ourFlexData);
    // console.log('setupFlexFields ourFlexFields: ', ourFlexFields);
    return ourFlexData;
  }

  function validateAlFields(ourFlexData, argErrorMessages = {}) {
    // console.log('validateAlFields: ', ourFlexData);
    // console.log('validateAlFields argErrorMessages: ', argErrorMessages);
    const flexJSON = {};
    for (const key of ourFlexData.keys()) {
      const val = ourFlexData.get(key).val;
      const id = ourFlexData.get(key).id;
      const type = ourFlexData.get(key).type;

      if (type === 'List') {
        flexJSON[key] = id;
      } else {
        flexJSON[key] = val;
      }
    }

    // TO DO: Find a way to set, this makes formikProps.dirty always true even when no edits on the form have been done...
    props.fieldSetter(flexJSON);
    // console.log('!!props.canSave: ', props.canSave);
    if (props.canSave !== undefined) {
      //
      //  Validate that all of the required fields have been entered
      //
      let ourCanSave = true;
      //   const allErrorMessages = {};
      const allErrorMessages = argErrorMessages;
      for (const key of ourFlexData.keys()) {
        // console.log('validateAlFields field: ', ourFlexData.get(key));
        // console.log('validateAlFields flexJSON[key]: ', flexJSON[key]);
        if (
          ourFlexData.get(key).required &&
          (flexJSON[key] === undefined || flexJSON[key] === '')
        ) {
          ourCanSave = false;
          //   console.log(
          //     'validateAlFields ourCanSave is now false because of this key BEFORE: ',
          //     key
          //   );
          //   console.log(
          //     'validateAlFields props.validateFields and errorMessages: ',
          //     props.validateFields,
          //     errorMessages
          //   );
          if (props.validateFields && !errorMessages[key]) {
            //
            //  Only display error messages if we're validating every field (which is usually done on save)
            //
            errorMessages[key] = 'This field is required';
          }
          allErrorMessages[key] = 'This field is required';
        } else {
          if (
            // // Removed props.validateFields && here... resulted in needing to submit once in order to verify required fields were filled
            // props.validateFields &&
            errorMessages[key] === 'This field is required'
          ) {
            // console.log('validateAlFields ITS VALIDATED');
            errorMessages[key] = false;
          }
        }
      }

      if (ourCanSave) {
        //
        //  Verify that all of the entered fields passed validation
        //
        for (const key of Object.keys(errorMessages)) {
          if (errorMessages[key] !== false) {
            // console.log(
            //   'validateAlFields ourCanSave is now false because of this key AFTER: ',
            //   key
            // );
            ourCanSave = false;
          }
        }
      }

      //   console.log('validateAlFields ourCanSave: ', ourCanSave);
      //   console.log('validateAlFields allErrorMessages: ', allErrorMessages);
      //   console.log('validateAlFields what errorMessages should set to: ', {
      //     ...errorMessages,
      //     ...allErrorMessages,
      //   });
      //   console.log('validateAlFields errorMessages BEFORE set: ', errorMessages);
      props.setCanSave(ourCanSave);
      setErrorMessages({ ...errorMessages, ...allErrorMessages });
      //   console.log('validateAlFields errorMessages AFTER set: ', errorMessages);
    }
  }

  useEffect(() => {
    setLoadFlexFields(true);

    return () => {
      setLoadFlexFields(true);
    };
  }, []);

  if (loadFlexFields) {
    validateAlFields(setupFlexFields(mutableFlexFields, props.values));
    setLoadFlexFields(false);
  }

  // replaced below w/ hasSubmitted from DealFormPage
  if (props.validateFields && !props.hasSubmitted) {
    validateAlFields(flexData);
    props.setHasSubmitted(true);
  }

  //   if (props.validateFields && !saveAttempted) {
  //     validateAlFields(flexData);
  //     setSaveAttempted(true);
  //   }

  //   console.log('FlexFields headersAndFields: ', headersAndFields);

  return (
    <Box>
      {Object.keys(headersAndFields).length > 0 &&
        [
          ...Object.entries(headersAndFields).filter(
            (el) => el[0] !== 'noHeaders'
          ),
          [' ', headersAndFields.noHeaders],
        ].map(([heading, fields], i) => {
          //   console.log('FlexFields inside map: ', heading, fields);
          if (!fields.length) return <></>;
          return (
            <Box
              key={i}
              style={
                i === 0
                  ? {
                      backgroundColor: '#F0F0F3',
                      borderRadius: 8,
                      paddingBottom: 0,
                    }
                  : {
                      backgroundColor: '#F0F0F3',
                      borderRadius: 8,
                      marginTop: 15,
                      paddingBottom: 0,
                    }
              }
            >
              <Box
                style={{
                  width: '100%',
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  backgroundColor: '#D0D2D8',
                  padding: 16,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  style={{ fontSize: 18, color: '#222428', fontWeight: 600 }}
                >
                  {capitalizeFirstLetter(heading)}
                </Typography>
                <GamifyIcon
                  icon={iconsMapper[heading.toLowerCase()]}
                  color="#323232"
                />
              </Box>
              <Box style={{ padding: '24px 16px 8px 16px' }}>
                {fields.map((el, i) => (
                  <FlexFieldInput
                    key={i}
                    dealId={dealId}
                    flexField={el}
                    flexData={flexData}
                    setFlexData={setFlexData}
                    validateAlFields={validateAlFields}
                    errorMessages={errorMessages}
                    // setErrorMessages={setErrorMessages}
                    hasSubmitted={props.hasSubmitted}
                    canSave={props.canSave}
                  />
                ))}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}

function FlexFieldInput(props) {
  const {
    dealId,
    flexField,
    flexData,
    setFlexData,
    validateAlFields,
    errorMessages,
    // setErrorMessages,
    hasSubmitted,
    canSave,
  } = props;
  //   const { flexField, flexData, setFlexData } = props;
  // console.log('FlexFieldInput flexField: ', flexField);
  // console.log('FlexFieldInput flexData: ', flexData);
  // console.log(
  //   'FlexFieldInput flexData[flexField.name]: ',
  //   flexData.get(flexField.name)
  // );
  //   const [errorMessages, setErrorMessages] = useState({
  //     ...fieldErrorMessages,
  //   });
  //     const [showModal, setShowModal] = useState(false);
  const [showPowerSupplierForm, setShowPowerSupplierForm] = useState(false);
  const [flexInputValue, setFlexInputValue] = useState(
    flexData.get(flexField.name)?.type === 'List'
      ? flexData.get(flexField.name)?.id
      : flexData.get(flexField.name)?.val
      ? flexData.get(flexField.name)?.val
      : flexField.name === 'pictures'
      ? []
      : ''
  );
  const [flexInputSelectIndex, setFlexInputSelectIndex] = useState(
    flexInputValue === ''
      ? 0
      : flexField.values.findIndex((option) => option.id === flexInputValue)
  );
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [imageIndex, setImageIndex] = useState(null);
  const [showImageCarousel, setShowImageCarousel] = useState(false);

  //   console.log('FlexFieldInput errorMessages: ', errorMessages);
  // if (flexData.get(flexField.name)?.type === 'List') {
  //   console.log(
  //     'flexData.get(flexField.name)',
  //     flexData.get(flexField.name)
  //   );
  //   console.log(
  //     'flexInputValue: ',
  //     flexField.values.findIndex((option) => option.id === flexInputValue),
  //     flexInputValue,
  //     flexInputSelectIndex
  //   );
  // }
  // console.log('flexField.values List: ', flexField.values);

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  const handlePowerSupplierClick = () => {
    setShowPowerSupplierForm(true);
  };

  const handleImageClick = (index) => {
    // console.log('handleImageClick placeholder');
    setImageIndex(index);
    setTimeout(() => {
      setShowImageCarousel(true);
    }, 250);
  };

  const handleDeleteImageClick = (fieldName, fieldType, i) => {
    // NOTE: Because there is no query done, the image deletion is nearly instantaneous, so no animation shows unless a timeout is set on setIsImageUploading(false)...
    setIsImageUploading(true);
    const ourFlexData = flexData;
    const tempUploadedImages = ourFlexData.get(fieldName);

    const updatedUploadedImages = [...tempUploadedImages.val];
    updatedUploadedImages.splice(i, 1);

    setFlexInputValue(updatedUploadedImages);

    setFlexFieldAttribute(fieldName, updatedUploadedImages, '', fieldType);

    setIsImageUploading(false);
  };

  const onDropDealImage = async (droppedFile, fieldName, fieldType) => {
    // console.log('onDropDealImage fieldName, fieldType: ', fieldName, fieldType);
    const formats = ['jpeg', 'jpg', 'png', 'gif'];
    const image = droppedFile[0]; // the imageFile
    // console.log('onDropDealImage image: ', image);
    const imageName = image.name;
    if (!formats.includes(imageName.split('.').pop().toLowerCase())) {
      GamifyToast.error(
        'Deal image format must be one of the following - png, gif, jpeg'
      );
      return;
    }

    setIsImageUploading(true);

    // generate random string and take image format to create new file name to match file names on deals in lyferize
    const imageNameFormat = imageName.split('.').pop().toLowerCase();
    const randomString = generateRandomImageFileNameString();
    const newImageName = `${randomString}.${imageNameFormat}`;

    const uploadedImage = await Storage.put(newImageName, image, {
      contentType: image.type,
    });

    setFlexInputValue([...flexInputValue, uploadedImage.key]);

    // set flexData w/ randomly generated uploaded image names
    setFlexFieldAttribute(
      fieldName,
      [...flexInputValue, uploadedImage.key],
      '',
      fieldType
    );
    setIsImageUploading(false);
  };

  function setFlexFieldAttribute(fieldName, inVal, id, fieldType) {
    // console.log(
    //   'FlexFieldInput setFlexFieldAttribute inputs: ',
    //   fieldName,
    //   inVal,
    //   id,
    //   fieldType
    // );
    const ourFlexData = flexData;
    let val = inVal;

    // console.log('FlexFieldInput val: ', val);

    const tempErrorMessages = {};

    if (fieldType === 'Phone') {
      if (
        !String(inVal)
          .toLowerCase()
          .match(/^\+?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im) &&
        String(inVal) !== ''
      ) {
        tempErrorMessages[fieldName] = 'Please enter a valid phone number';
      } else {
        val = formatPhoneNumber(inVal);
        tempErrorMessages[fieldName] = false;
      }
    } else if (fieldType === 'Number') {
      if (isNaN(inVal) && String(inVal) !== '') {
        val = inVal.substring(0, inVal.length - 1);
        tempErrorMessages[fieldName] = 'Please enter only numbers';
      } else {
        tempErrorMessages[fieldName] = false;
      }
    } else if (fieldType === 'Date') {
      if (
        !String(inVal)
          .toLowerCase()
          .match(
            /^(0[1-9]|1[0-2]|[1-9])\/(0[1-9]|1\d|2\d|3[01]|[1-9])\/(19|20)\d{2}$/
          ) &&
        String(inVal) !== ''
      ) {
        tempErrorMessages[fieldName] = 'Please enter a date (ex: 1/2/2022).';
      } else {
        tempErrorMessages[fieldName] = false;
      }
    } else if (fieldType === 'Money') {
      if (
        !String(inVal)
          .toLowerCase()
          .match(/^(?=.*?\d)\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/) &&
        String(inVal) !== ''
      ) {
        tempErrorMessages[fieldName] = 'Please enter a valid dollar amount.';
      } else {
        tempErrorMessages[fieldName] = false;
      }
    } else if (fieldType === 'Email') {
      if (
        !String(inVal)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
        String(inVal) !== ''
      ) {
        tempErrorMessages[fieldName] = 'Please enter a valid email address';
      } else {
        tempErrorMessages[fieldName] = false;
      }
    }
    // console.log('FlexFieldInput fieldName: ', fieldName);
    // console.log(
    //   'FlexFieldInput ourFlexData(fieldName): ',
    //   ourFlexData.get(fieldName)
    // );
    // console.log('FlexFieldInput ourFlexData BEFORE set: ', ourFlexData);

    ourFlexData.set(fieldName, {
      val,
      id,
      type: ourFlexData.get(fieldName).type,
      required: ourFlexData.get(fieldName).required,
    });

    // console.log('FlexFieldInput ourFlexData AFTER set: ', ourFlexData);
    // console.log('FlexFieldInput errorMessages tempErrors: ', tempErrorMessages);
    // console.log('FlexFieldInput errorMessages END: ', errorMessages);
    setFlexData(ourFlexData);
    // Don't set errors in FlexFieldInput, just pass tempErrorMessages to validateAlFields as default parameter
    // setErrorMessages({ ...errorMessages, ...tempErrorMessages });
    validateAlFields(ourFlexData, tempErrorMessages);
  }

  const getMaxLength = (ff) => {
    switch (ff.type) {
      case 'String':
        return ff.maxLength || 80;
      case 'Notes':
        return ff.maxLength || 4096;
      case 'Email':
      case 'Date':
        return 255;
      case 'Number':
      case 'Money':
      case 'Phone':
        return 20;
      default:
        return 4096;
    }
  };

  const handleClearInput = () => {
    setFlexInputValue('');
    setFlexFieldAttribute(flexField.name, '', '', flexField.type);
  };

  const cancelIconAdornment =
    flexInputValue.length > 0 ? (
      <Box
        className={'clear-input-button'}
        style={{
          position: 'relative',
          right: 8,
        }}
        sx={{ cursor: 'pointer' }}
        onClick={handleClearInput}
      >
        <InputAdornment position="end">
          <DeleteIcon></DeleteIcon>
        </InputAdornment>
      </Box>
    ) : (
      <></>
    );

  //     const getKeyboardConfig = (fieldType) => {
  //       switch (fieldType) {
  //         case "Email":
  //           return "email-address";
  //         case "Number":
  //         case "Money":
  //         case "Phone":
  //           return "decimal-pad";
  //         default:
  //           return "default";
  //       }
  //     };
  return (
    <>
      {[
        'String',
        'Notes',
        'Email',
        'Number',
        'Money',
        'Date',
        'Phone',
      ].includes(flexField.type) && (
        <Box
          style={{
            position: 'relative',
            width: '100%',
            marginBottom: 16,
          }}
        >
          <Typography
            onClick={() =>
              console.log('THIS FIELD----->', flexData.get(flexField.name).val)
            }
            style={{
              fontSize: 12,
              fontWeight: 700,
              color: '#0B0B0C',
              marginBottom: 8,
            }}
          >
            {flexField.required ? `*${flexField.label}` : flexField.label}
          </Typography>
          <Input
            className={
              errorMessages[flexField.name] && hasSubmitted && !canSave
                ? 'error-flex-field-input'
                : 'flex-field-input'
            }
            style={{
              width: '100%',
              padding: '12px 16px 12px 16px',
              border: 0,
              borderRadius: 8,
            }}
            // autofocus
            placeholder={flexField.label}
            // placeholderTextColor="#868686"
            //   testID={"ReactNative Appium"}
            //   keyboardType={getKeyboardConfig(flexField.type)}
            //   accessibilityLabel={flexField.name.replace(/ /g, '') + 'Field'}
            //   style={[flexFieldStyles.textInputStyle]}
            //   textBreakStrategy={'highQuality'}
            //   maxLength={getMaxLength(flexField)}
            inputProps={{ maxLength: getMaxLength(flexField) }}
            multiline={flexField.type === 'Notes'}
            // value={flexData.get(flexField.name).val}
            value={flexInputValue}
            onChange={(e) => {
              //   console.log('ON CHANGE EVENT --->', e);
              //   console.log('ON CHANGE EVENT TARGET VALUE --->', e.target.value);
              setFlexInputValue(e.target.value);
              setFlexFieldAttribute(
                flexField.name,
                e.target.value,
                // flexData.get(flexField.name).val,
                '',
                flexField.type
              );
              //   console.log('ON CHANGE flexData --> ', flexData);
              //   console.log(
              //     'ON CHANGE flexData.get... --> should be input value: ',
              //     flexData.get(flexField.name).val
              //   );
              //   console.log(
              //     'ON CHANGE flexInputValues --> new state variable to be input value: ',
              //     flexInputValue
              //   );
            }}
            endAdornment={cancelIconAdornment}
          />
        </Box>
      )}
      {flexField.type === 'Boolean' && (
        <>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 16,
            }}
          >
            {!flexField.hideLabel && (
              <Typography
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 12,
                  fontWeight: 700,
                  color: '#0B0B0C',
                }}
              >
                {flexField.required ? `*${flexField.label}` : flexField.label}
              </Typography>
            )}
            <Switch
              style={{
                transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }],
              }}
              sx={{
                '& .Mui-checked': {
                  color: 'white !important',
                  opacity: 1,
                },
                '& .Mui-checked+.MuiSwitch-track': {
                  backgroundColor: '#FF6A00 !important',
                  opacity: '1 !important',
                },
                '& .MuiSwitch-track': {
                  position: 'relative',
                  bottom: 4,
                  right: 4,
                  //   backgroundColor: '#D0D2D8 !important',
                  //   opacity: 1,
                  height: 22,
                  minWidth: 42,
                  borderRadius: 1000,
                },
              }}
              onChange={() => {
                // console.log('FlexField switch press');
                setFlexFieldAttribute(
                  flexField.name,
                  !flexData.get(flexField.name).val,
                  '',
                  flexField.type
                );
              }}
              value={flexData.get(flexField.name).val}
            />
          </Box>
        </>
      )}
      {flexField.type === 'ImageList' && (
        <>
          <ImageCarousel
            showImageCarousel={showImageCarousel}
            setShowImageCarousel={setShowImageCarousel}
            imagesArr={flexInputValue}
            imageIndex={imageIndex}
            setImageIndex={setImageIndex}
            zIndex={100002}
          ></ImageCarousel>
          <Dropzone
            onDrop={(droppedFile) =>
              onDropDealImage(droppedFile, flexField.name, flexField.type)
            }
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                className={'flex-field-file-upload-container'}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: 8,
                  padding: '8px 12px 8px',
                  marginBottom: 16,
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#FAC8B2',
                    borderRadius: 1000,
                    height: 40,
                    width: 40,
                    marginRight: 16,
                  }}
                >
                  <PhotoIcon></PhotoIcon>
                </Box>
                <Typography
                  style={{
                    color: '#FF6A00',
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  Add files
                </Typography>
              </Box>
            )}
          </Dropzone>
          {isImageUploading ? (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: 80,
                margin: '16px 0px 16px',
              }}
            >
              <CircularProgress
                style={{ color: 'black', width: 48, height: 48 }}
              ></CircularProgress>
            </Box>
          ) : (
            flexInputValue.length > 0 && (
              <Box className={'flex-field-images-section'}>
                <ImageList
                  cols={8}
                  gap={8}
                  sx={{
                    width: '100%',
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                >
                  {flexInputValue.map((image, i) => (
                    <ImageListItem
                      key={i}
                      sx={{
                        width: 'fit-content',
                      }}
                    >
                      <Button
                        className={'image-modal-button'}
                        onClick={() => handleImageClick(i)}
                        disableRipple={true}
                      >
                        <DisplayMedia
                          imageName={image}
                          imageType={'img'}
                          height={'80px'}
                          width={'80px'}
                          objectFit={'cover'}
                          borderRadius={'8px'}
                        ></DisplayMedia>
                        {/* <Box
                        component={'img'}
                        sx={{
                          height: 80,
                          width: 80,
                          objectFit: 'cover',
                          borderRadius: '8px',
                        }}
                        alt={image.name}
                        src={URL.createObjectURL(image)}
                      ></Box> */}
                      </Button>
                      <Box
                        // className={'delete-image-modal-button'}
                        style={{
                          backgroundColor: '#060606',
                          display: 'flex',
                          position: 'absolute',
                          zIndex: 1,
                          left: '60px',
                          bottom: '60px',
                          boxSizing: 'border-box',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '16px',
                          width: '16px',
                          minWidth: '16px',
                          padding: 0,
                          borderRadius: '2000px',
                        }}
                        sx={{
                          '&:hover': {
                            border: 'white 1px solid',
                          },
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          handleDeleteImageClick(
                            flexField.name,
                            flexField.type,
                            i
                          )
                        }
                        // disableRipple={true}
                      >
                        <XIcon></XIcon>
                      </Box>
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            )
          )}
        </>
      )}
      {flexField.type === 'List' && (
        <Box
          style={{
            position: 'relative',
            width: '100%',
            marginBottom: 16,
          }}
        >
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 700,
              color: '#0B0B0C',
              marginBottom: 8,
            }}
          >
            {flexField.required ? `*${flexField.label}` : flexField.label}
          </Typography>
          {/* {console.log(
            'FlexField Select flexData.get(flexField.name): ',
            flexData.get(flexField.name)
          )}
          {console.log('FlexField Select flexField.values', flexField.values)} */}
          <StyledSelect
            className={'flex-field-select'}
            style={{ width: '100%', border: 'none' }}
            onChange={(e) => {
              // console.log('FlexField Select flexField: ', flexField);
              // console.log('FlexField Select e.target.value: ', e.target.value);
              // console.log(
              //   'FlexField Select flexField.values[e.target.value]: ',
              //   flexField.values[e.target.value]
              // );
              // console.log(
              //   'FlexField Select flexInputSelectIndex: ',
              //   flexInputSelectIndex
              // );
              setFlexInputSelectIndex(e.target.value);
              if (flexField.required) {
                setFlexInputValue(flexField.values[e.target.value].id);
                setFlexFieldAttribute(
                  flexField.name,
                  flexField.values[e.target.value].label,
                  // flexData.get(flexField.name).val,
                  flexField.values[e.target.value].id,
                  // '',
                  flexField.type
                );
              } else {
                if (e.target.value !== 0) {
                  // indices off by 1 for non-required lists, -1 to e.target.value to account for that
                  setFlexInputValue(flexField.values[e.target.value].id);
                  setFlexFieldAttribute(
                    flexField.name,
                    flexField.values[e.target.value].label,
                    // flexData.get(flexField.name).val,
                    flexField.values[e.target.value].id,
                    // '',
                    flexField.type
                  );
                } else {
                  // index 0 is reserved for no selection if field isn't required
                  setFlexInputValue('');
                  setFlexFieldAttribute(
                    flexField.name,
                    '',
                    // flexData.get(flexField.name).val,
                    '',
                    // '',
                    flexField.type
                  );
                }
              }
            }}
            value={flexInputSelectIndex}
            name={'flexFieldSelect'}
            // MenuProps={{
            //   MenuListProps: {
            //     sx: {
            //       boxShadow: '0px 4px 24px 0px #ababab5e;',
            //       borderRadius: '4px',
            //       backgroundColor: 'white',
            //       paddingTop: '0px',
            //       marginTop: '8px',
            //     },
            //   },
            // }}
            MenuProps={{
              style: { zIndex: 100002 },
              MenuListProps: {
                sx: {
                  maxHeight: 300,
                  overflowY: 'auto',
                  paddingTop: 0,
                  paddingBottom: 0,
                  borderRadius: '8px',
                  border: '1px solid #D0D2D8',
                  boxShadow: '0px 4px 24px 0px #ababab5e;',
                },
              },
            }}
          >
            {/* NOTE: On the non-required list (!flexField.required) of a new deal (!dealId), { id: 'No Selection' } needs to be inserted as the first element as it doesn't get inserted by setupFlexFields */}
            {!dealId && !flexField.required
              ? [{ id: 'No Selection' }, ...flexField.values].map((el, i) => (
                  <StyledMenuItem
                    className={'utility-info-option'}
                    value={i}
                    key={i}
                  >
                    {el.id}
                    {/* {el.label} */}
                  </StyledMenuItem>
                ))
              : flexField.values.map((el, i) => (
                  <StyledMenuItem
                    className={'utility-info-option'}
                    value={i}
                    key={i}
                  >
                    {el.id}
                    {/* {el.label} */}
                  </StyledMenuItem>
                ))}
          </StyledSelect>
          {/* <SearchableDropdown
                  onItemSelect={(item) => {
                    setFlexFieldAttribute(
                      flexField.name,
                      item.name,
                      item.id,
                      flexField.type
                    );
                  }}
                  itemStyle={{
                    padding: 5,
                    marginTop: 2,
                    backgroundColor: "white",
                    borderColor: "#bbb",
                    borderWidth: 1,
                    borderRadius: 8,
                  }}
                  itemTextStyle={{ color: "#222" }}
                  itemsContainerStyle={{ maxHeight: 140 }}
                  items={flexField.values.map((el) => ({
                    ...el,
                    name: el.label,
                  }))}
                  defaultIndex={0}
                  resetValue={false}
                  textInputProps={{
                    placeholder: flexData.get(flexField.name).val,
                    placeholderTextColor: "#868686",
                    underlineColorAndroid: "transparent",
                    style: {
                      fontSize: 16,
                      width: "100%",
                      padding: 5,

                      borderRadius: 8,
                    },
                  }}
                  containerStyle={{ backgroundColor: "white" }}
                  listProps={{
                    nestedScrollEnabled: true,
                  }}
                /> */}
        </Box>
      )}
      {flexField.type === 'Power' && (
        <Button
          style={{
            width: '100%',
            borderRadius: 8,
            border:
              errorMessages[flexField.name] && hasSubmitted && !canSave
                ? // ? '1px #FF5C77 solid'
                  '1px #E35050 solid'
                : 'none',
            backgroundColor: 'white',
            padding: 12,
            marginBottom: 16,
          }}
          onClick={handlePowerSupplierClick}
          disableRipple={true}
        >
          <Box
            style={{
              backgroundColor: 'white',
              width: '100%',
              paddingHorizontal: 12,
              paddingVertical: 8,
              borderRadius: 8,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: '#0B0B0C',
                  marginBottom: 2,
                }}
              >
                {flexField.required ? `*${flexField.label}` : flexField.label}
              </Typography>
              <Typography
                style={{ fontSize: 14, fontWeight: 400, color: '#868686' }}
              >
                {flexData.get(flexField.name)?.val?.name || 'Select'}
              </Typography>
            </Box>
            <RightArrowIcon></RightArrowIcon>
          </Box>
        </Button>
      )}
      {/* TO DO: look into flexField.next and flexField.back for how this is implemented */}
      {/* {(flexField.next || flexField.back) && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {flexField.back >= 0 && (
                  <Button
                    buttonStyle={styles.button}
                    icon={
                      <Icon
                        name="backward"
                        size={20}
                        marginRight={2}
                        color="black"
                        type="font-awesome-5"
                      />
                    }
                    titleStyle={{ color: "gray" }}
                    type="clear"
                    onPress={() => {}}
                  />
                )}
                {flexField.next && flexField.next > 0 && (
                  <Button
                    buttonStyle={styles.button}
                    icon={
                      <Icon
                        name="forward"
                        size={20}
                        marginRight={2}
                        color="black"
                        type="font-awesome-5"
                      />
                    }
                    titleStyle={{ color: "gray" }}
                    type="clear"
                    onPress={() => {}}
                  />
                )}
              </View>
            )}
                */}
      {errorMessages[flexField.name] && hasSubmitted && !canSave && (
        <Typography
          color="red"
          weight="400"
          style={{
            fontSize: 12,
            marginTop: -12,
            color: 'red',
            paddingBottom: 10,
          }}
        >
          {errorMessages[flexField.name]}
        </Typography>
      )}
      {flexField.type === 'Power' && (
        <PowerSupplierForm
          showPowerSupplierForm={showPowerSupplierForm}
          setShowPowerSupplierForm={setShowPowerSupplierForm}
          flexField={flexData.get(flexField.name).val}
          onSave={(val) => {
            // console.log('OUTSIDE THE MODAL');
            // console.log(val);
            setFlexFieldAttribute(flexField.name, val, '', flexField.type);
          }}
        ></PowerSupplierForm>
      )}
    </>
  );
}
