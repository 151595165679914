import { API } from 'aws-amplify';
import gql from 'graphql-tag';

const fetchTeamLevels = async () => {
  const myQuery = gql`
    query MyQuery($orgID: ID!) {
      listCircleLevelByOrg(
        orgID: $orgID
        filter: { isDeleted: { eq: false } }
        limit: 10000
      ) {
        items {
          id
          name
          levelNum
        }
      }
    }
  `;

  const res = await API.graphql({
    query: myQuery,
    variables: { orgID: global.me.orgID },
  });
  const teamLevels = res.data.listCircleLevelByOrg.items;

  const teamLevelList = [];
  for (const teamLevel of teamLevels) {
    teamLevelList[teamLevelList.length] = {
      label: teamLevel.name,
      value: teamLevel.id,
      levelNum: teamLevel.levelNum,
    };
  }
  const sortedTeamLevelList = teamLevelList.sort((a, b) => {
    return b.levelNum - a.levelNum;
  });

  return sortedTeamLevelList;
};

export default fetchTeamLevels;
