import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { CustomTextField } from './styledComponents';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { hasUnsavedChanges } from '../utils/hasUnsavedChanges';
import FlexFieldEditor from './FlexFieldEditor';
import './CRMConfigV2.css';
import { newFormTemplate, newFieldTemp } from '../utils/tempsUtils';

const DealFormEditor = ({
  dealForms,
  stages,
  stageDispositions,
  updateForms,
}) => {
  const [workingDealForms, setWorkingDealForms] = useState({});
  const [changedForms, setChangedForms] = useState([]);
  const [expandedForm, setExpandedForm] = useState(false);
  const [expandedField, setExpandedField] = useState(null);
  const [newFormName, setNewFormName] = useState('');
  const [formNameError, setFormNameError] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [formToDelete, setFormToDelete] = useState('');

  useEffect(() => {
    setWorkingDealForms(dealForms);
  }, [dealForms]);

  useEffect(() => {
    const updatedChangedForms = Object.keys(workingDealForms).filter((key) =>
      hasUnsavedChanges(dealForms[key], workingDealForms[key])
    );
    setChangedForms(updatedChangedForms);
  }, [dealForms, workingDealForms]);

  const handleFormChange = (formName, field, value) => {
    const updatedDealForms = {
      ...workingDealForms,
      [formName]: { ...workingDealForms[formName], [field]: value },
    };
    setWorkingDealForms(updatedDealForms);
  };

  const handleFieldChange = (formName, fieldIndex, field, value) => {
    const updatedFields = [...workingDealForms[formName].flexFields];
    updatedFields[fieldIndex] = {
      ...updatedFields[fieldIndex],
      [field]: value,
    };

    handleFormChange(formName, 'flexFields', updatedFields);
  };

  const deleteField = (formName, fieldIndex) => {
    const updatedFields = [...workingDealForms[formName].flexFields];
    updatedFields.splice(fieldIndex, 1);
    handleFormChange(formName, 'flexFields', updatedFields);
    setExpandedField(null);
  };

  const saveChanges = (formName) => {
    const updatedForm = { ...workingDealForms[formName] };

    const displayFields = updatedForm.flexFields
      .filter((f) => f.displayField)
      .map((f) => f.name);

    const pickerDisplayFields = updatedForm.flexFields
      .filter((f) => f.pickerDisplayField)
      .map((f) => f.name);

    updatedForm.displayFields = displayFields;
    updatedForm.pickerDisplayFields = pickerDisplayFields;

    const allForms = { ...dealForms, [formName]: updatedForm };

    updateForms(allForms);
    setChangedForms(changedForms.filter((name) => name !== formName));
  };

  const resetChanges = (formName, e) => {
    e.stopPropagation();
    const originalForm = dealForms[formName];
    const updatedDealForms = {
      ...workingDealForms,
      [formName]: { ...originalForm },
    };
    setWorkingDealForms(updatedDealForms);
  };

  const handleFormAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedForm(isExpanded ? panel : false);
  };

  const handleFieldAccordionChange = (fieldIndex) => (event, isExpanded) => {
    setExpandedField(isExpanded ? fieldIndex : null);
  };

  const handleDragStart = (e, formName, index) => {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', JSON.stringify({ formName, index }));
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, formName, dropIndex) => {
    e.preventDefault();
    const { formName: draggedFormName, index: draggedIndex } = JSON.parse(
      e.dataTransfer.getData('text')
    );

    if (draggedFormName === formName && draggedIndex !== dropIndex) {
      const updatedFields = [...workingDealForms[formName].flexFields];
      const [draggedItem] = updatedFields.splice(draggedIndex, 1);
      updatedFields.splice(dropIndex, 0, draggedItem);

      handleFormChange(formName, 'flexFields', updatedFields);
    }
  };

  const handleFormNameChange = (formName, value) => {
    if (value === 'deal') return; // Prevent setting the form name to 'deal'
    const updatedDealForms = { ...workingDealForms };
    updatedDealForms[value] = { ...updatedDealForms[formName] };
    delete updatedDealForms[formName];
    setWorkingDealForms(updatedDealForms);
    setExpandedForm(value);
  };

  const confirmDeleteForm = (formName) => {
    setFormToDelete(formName);
    setOpenDialog(true);
  };

  const handleDeleteForm = () => {
    if (formToDelete === 'deal') return; // Prevent deleting the form with name 'deal'
    const updatedDealForms = { ...workingDealForms };
    delete updatedDealForms[formToDelete];
    setWorkingDealForms(updatedDealForms);
    setOpenDialog(false);
    setFormToDelete('');
    updateForms(updatedDealForms);
  };

  const addNewField = (formName) => {
    const updatedFields = [
      newFieldTemp,
      ...workingDealForms[formName].flexFields,
    ];
    handleFormChange(formName, 'flexFields', updatedFields);
    setExpandedField(0);
  };

  const createNewForm = () => {
    if (newFormName.trim() === '' || workingDealForms[newFormName]) {
      setFormNameError(true);
      return;
    }

    const newForm = {
      ...newFormTemplate,
      flexFields: [],
    };

    setWorkingDealForms({
      ...workingDealForms,
      [newFormName]: newForm,
    });

    setNewFormName('');
    setFormNameError(false);
    setExpandedForm(newFormName);
    setShowCreateForm(false);
  };

  const handleNewFormNameChange = (e) => {
    const { value } = e.target;
    setNewFormName(value);
    setFormNameError(!!workingDealForms[value] && value !== '');
  };

  const renderFieldAccordionSummary = (field, fieldIndex) => (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`field${fieldIndex}-content`}
      id={`field${fieldIndex}-header`}
      style={{
        backgroundColor: expandedField === fieldIndex ? '#f0f0f0' : 'inherit',
        padding: '10px 35px',
      }}
    >
      <Box display="flex" alignItems="center" gap={5}>
        <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
          {field.label}
        </Typography>
        <Typography
          style={{
            fontSize: 'medium',
            color: 'gray',
            marginLeft: '8px',
            fontWeight: 'bold',
          }}
        >
          Type:
          <Typography
            component="span"
            style={{
              fontSize: 'medium',
              color: 'gray',
              marginLeft: '4px',
              fontWeight: 'normal',
            }}
          >
            {field.type}
          </Typography>
        </Typography>
        <Typography
          style={{
            fontSize: 'medium',
            color: 'gray',
            marginLeft: '8px',
            fontWeight: 'bold',
          }}
        >
          Heading:
          <Typography
            component="span"
            style={{
              fontSize: 'medium',
              color: 'gray',
              marginLeft: '4px',
              fontWeight: 'normal',
            }}
          >
            {field.heading}
          </Typography>
        </Typography>
      </Box>
    </AccordionSummary>
  );

  return (
    <Box border={'1px solid gray'}>
      {Object.keys(workingDealForms).map((formName, index) => (
        <Accordion
          key={formName}
          expanded={expandedForm === formName}
          onChange={handleFormAccordionChange(formName)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            style={{
              backgroundColor:
                expandedForm === formName ? '#e0cfe8' : 'inherit',
              padding: '10px 35px',
            }}
          >
            <Typography style={{ fontSize: 'large', fontWeight: 700 }}>
              {formName}{' '}
              {changedForms.includes(formName) && (
                <>
                  <span style={{ color: 'red', fontWeight: 400 }}>
                    (Unsaved Changes)
                  </span>
                  <Typography
                    component="span"
                    style={{
                      color: 'gray',
                      cursor: 'pointer',
                      marginLeft: '10px',
                    }}
                    onClick={(e) => resetChanges(formName, e)}
                  >
                    reset
                  </Typography>
                </>
              )}
            </Typography>
            {changedForms.includes(formName) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => saveChanges(formName)}
                style={{ marginLeft: 'auto', marginRight: '15px' }}
              >
                Save Changes
              </Button>
            )}
          </AccordionSummary>
          <AccordionDetails style={{ padding: '10px 0px' }}>
            <Box style={{ padding: '5px 35px' }}>
              <Box mb={2}>
                <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
                  Form Name
                </Typography>
                <CustomTextField
                  value={formName}
                  onChange={(e) =>
                    handleFormNameChange(formName, e.target.value)
                  }
                  disabled={true}
                  fullWidth
                  margin="normal"
                />
              </Box>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Box width="48%">
                  <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
                    Availability Disposition
                  </Typography>
                  <FormControl fullWidth margin="normal">
                    <Select
                      className="square-select"
                      value={
                        workingDealForms[formName].availabiltyDisposition || ''
                      }
                      onChange={(e) =>
                        handleFormChange(
                          formName,
                          'availabiltyDisposition',
                          e.target.value
                        )
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      <MenuItem
                        value=""
                        style={{
                          backgroundColor: 'white',
                          border: '1px solid lightgray',
                          borderTop: 'none',
                          fontStyle: 'italic',
                          color: 'gray',
                        }}
                      >
                        Select Availability Disposition...
                      </MenuItem>
                      {stageDispositions.map((disposition) => (
                        <MenuItem
                          key={disposition.id}
                          value={disposition.id}
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid lightgray',
                            borderTop: 'none',
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = 'lightgray')
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = 'white')
                          }
                        >
                          {disposition.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box width="48%">
                  <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
                    Availability Stage
                  </Typography>
                  <FormControl fullWidth margin="normal">
                    <Select
                      className="square-select"
                      value={workingDealForms[formName].availabiltyStage || ''}
                      onChange={(e) =>
                        handleFormChange(
                          formName,
                          'availabiltyStage',
                          e.target.value
                        )
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      <MenuItem
                        value=""
                        style={{
                          backgroundColor: 'white',
                          border: '1px solid lightgray',
                          borderTop: 'none',
                          fontStyle: 'italic',
                          color: 'gray',
                        }}
                      >
                        Select Availability Stage...
                      </MenuItem>
                      {stages.map((stage) => (
                        <MenuItem
                          key={stage.id}
                          value={stage.id}
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid lightgray',
                            borderTop: 'none',
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = 'lightgray')
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = 'white')
                          }
                        >
                          {stage.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => confirmDeleteForm(formName)}
                  disabled={formName === 'deal'}
                  style={{ marginLeft: 'auto' }}
                >
                  Delete Form
                </Button>
              </Box>
            </Box>
            <Box style={{ margin: '5px 35px', border: '1px solid lightgray' }}>
              <Box
                style={{
                  backgroundColor: '#99c2d9',
                  padding: '15px 35px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
                  Form Fields
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => addNewField(formName)}
                  style={{ marginLeft: 'auto' }}
                >
                  Add New Field
                </Button>
              </Box>
              <Box style={{ minHeight: '60px' }}>
                {workingDealForms[formName].flexFields?.length === 0 && (
                  <Box mb={2} display="flex">
                    <Typography m="auto" pt="15px" color="gray">
                      No fields
                    </Typography>
                  </Box>
                )}
                {workingDealForms[formName].flexFields?.map(
                  (field, fieldIndex) => (
                    <Accordion
                      key={fieldIndex}
                      expanded={expandedField === fieldIndex}
                      onChange={handleFieldAccordionChange(fieldIndex)}
                      draggable={expandedField !== fieldIndex}
                      onDragStart={(e) =>
                        handleDragStart(e, formName, fieldIndex)
                      }
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, formName, fieldIndex)}
                    >
                      {renderFieldAccordionSummary(field, fieldIndex)}
                      <FlexFieldEditor
                        formName={formName}
                        field={field}
                        fieldIndex={fieldIndex}
                        handleFieldChange={handleFieldChange}
                        deleteField={deleteField}
                        changedForms={changedForms}
                      />
                    </Accordion>
                  )
                )}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
      <Box className="create_form_container">
        {!showCreateForm && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowCreateForm(true)}
          >
            Create New Form
          </Button>
        )}
        {showCreateForm && (
          <Box display="flex" alignItems="center" gap={2}>
            <CustomTextField
              value={newFormName}
              onChange={handleNewFormNameChange}
              placeholder="New Form Name"
              error={formNameError}
              helperText={
                formNameError ? 'Form name already exists or is invalid' : ''
              }
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={createNewForm}>
              Create
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowCreateForm(false)}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleted forms cannot be restored. Are you sure you want to delete
            this form?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteForm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DealFormEditor;
