// Modal.tsx
import React from 'react';
import styled from 'styled-components';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  width?: number;
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalWrapper = styled.div<{ width: number }>`
  background-color: #fff;
  padding: 20px;
  width: ${({ width }) => `${width}px`};
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-height: 65vh;
  height: 65vh;
  overflow: auto;
`;

const Title = styled.div`
  font-size: 24px;
  font-family: Space Grotesk;
  font-weight: 700;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CloseButton = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const CustomModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  width = 500,
}) => {
  if (!isOpen) return null;

  return (
    <Overlay
      onClick={() => {
        onClose();
      }}
    >
      <ModalWrapper width={width} onClick={(e) => e.stopPropagation()}>
        <Header>
          <Title>{title}</Title>
          <CloseButton
            src="/images/close-light.svg"
            onClick={() => {
              onClose();
            }}
          />
        </Header>
        {children}
      </ModalWrapper>
    </Overlay>
  );
};

export default CustomModal;
