import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Switch,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { hasUnsavedChanges } from '../utils/hasUnsavedChanges';
import { CustomTextField } from './styledComponents';
import {
  calendarAppointmentStatusOptions,
  newDealStageTemp,
} from '../utils/tempsUtils';
import { updateStage, createStage } from '../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { removeEmptyFields } from '../utils/removeEmptyFields';
const { v4: uuidv4 } = require('uuid');

const DealStageForm = ({
  stage,
  stageDispositions,
  onChange,
  onToggleChange,
  onCalendarStatusChange,
}) => (
  <>
    <Box display="flex" justifyContent="space-between" mb={2}>
      <Box width="80%" mr={2}>
        <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
          Title
        </Typography>
        <CustomTextField
          value={stage.title}
          onChange={(e) => onChange('title', e.target.value)}
          fullWidth
          margin="normal"
        />
      </Box>
      <Box width="20%">
        <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
          Sequence
        </Typography>
        <CustomTextField
          type="number"
          value={stage.sequence}
          onChange={(e) => onChange('sequence', e.target.value)}
          fullWidth
          margin="normal"
        />
      </Box>
    </Box>
    <Box mb={2}>
      <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
        Description
      </Typography>
      <CustomTextField
        value={stage.description}
        onChange={(e) => onChange('description', e.target.value)}
        fullWidth
        margin="normal"
      />
    </Box>
    <Box display="flex" justifyContent="space-between" mb={2}>
      <Box width="48%" display="flex" flexDirection="column">
        <Box border="1px solid lightgray" padding="16px" mb={2}>
          <Typography style={{ fontSize: 'small', fontWeight: 500 }}>
            Only one deal stage in the list can be the default. Changing the
            default will remove the current default.
          </Typography>
          <Box display="flex" alignItems="center" mt={2}>
            <Switch
              checked={stage.isMapDefault}
              onChange={(e) => onToggleChange('isMapDefault', e.target.checked)}
              color="primary"
            />
            <Typography style={{ marginLeft: 8 }}>
              Default deal stage
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Switch
              checked={stage.isNoDealDefault}
              onChange={(e) =>
                onToggleChange('isNoDealDefault', e.target.checked)
              }
              color="primary"
            />
            <Typography style={{ marginLeft: 8 }}>
              Default no deal stage
            </Typography>
          </Box>
        </Box>
        {/* Other switches */}
        <Box display="flex" alignItems="center">
          <Switch
            checked={stage.isActive}
            onChange={(e) => onToggleChange('isActive', e.target.checked)}
            color="primary"
          />
          <Typography style={{ marginLeft: 8 }}>Show as active</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Switch
            checked={stage.isDisplay}
            onChange={(e) => onToggleChange('isDisplay', e.target.checked)}
            color="primary"
          />
          <Typography style={{ marginLeft: 8 }}>Show in Deal screen</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Switch
            checked={stage.requireCloser}
            onChange={(e) => onToggleChange('requireCloser', e.target.checked)}
            color="primary"
          />
          <Typography style={{ marginLeft: 8 }}>Require closer</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Switch
            checked={stage.sendToCRM}
            onChange={(e) => onToggleChange('sendToCRM', e.target.checked)}
            color="primary"
          />
          <Typography style={{ marginLeft: 8 }}>Send to CRM</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Switch
            checked={stage.userSelectable ?? true}
            onChange={(e) => onToggleChange('userSelectable', e.target.checked)}
            color="primary"
          />
          <Typography style={{ marginLeft: 8 }}>User selectable</Typography>
        </Box>
      </Box>
      <Box width="48%" p={2} mb={2}>
        <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
          Change to disposition
        </Typography>
        <FormControl fullWidth margin="normal">
          <Select
            className="square-select"
            value={stage.changeToDispositionID || ''}
            onChange={(e) => onChange('changeToDispositionID', e.target.value)}
            displayEmpty
          >
            <MenuItem
              value=""
              style={{
                backgroundColor: '#F0F0F0',
                border: '1px solid lightgray',
              }}
            >
              <em>Select a disposition</em>
            </MenuItem>
            {stageDispositions.map((disposition) => (
              <MenuItem
                key={disposition.id}
                value={disposition.id}
                style={{
                  backgroundColor: 'white',
                  border: '1px solid lightgray',
                  borderTop: 'none',
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = 'lightgray')
                }
                onMouseLeave={(e) => (e.target.style.backgroundColor = 'white')}
              >
                {disposition.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography
          style={{
            fontSize: 'medium',
            fontWeight: 600,
            marginTop: '16px',
          }}
        >
          Calendar appointment status
        </Typography>
        {calendarAppointmentStatusOptions.map((status) => (
          <FormControlLabel
            key={status}
            control={
              <Switch
                checked={(
                  stage.calendarAppointmentStatus?.split(', ') || []
                ).includes(status)}
                onChange={(e) =>
                  onCalendarStatusChange(status, e.target.checked)
                }
                color="primary"
              />
            }
            label={status.charAt(0).toUpperCase() + status.slice(1)}
          />
        ))}
      </Box>
    </Box>
  </>
);

const DealStagesEditor = ({
  dealStages,
  stageDispositions,
  updateStages,
  orgID,
  categoryID,
}) => {
  const [workingDealStages, setWorkingDealStages] = useState([]);
  const [changedStages, setChangedStages] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [defaultStageChange, setDefaultStageChange] = useState(null);
  const [stageToDelete, setStageToDelete] = useState(null);
  const [newStage, setNewStage] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    setWorkingDealStages(dealStages);
  }, [dealStages]);

  useEffect(() => {
    const updatedChangedStages = workingDealStages
      .filter((stage, index) => hasUnsavedChanges(dealStages[index], stage))
      .map((stage) => stage.id);
    setChangedStages(updatedChangedStages);
  }, [dealStages, workingDealStages]);

  const handleChange = (index, field, value) => {
    const updatedDealStages = [...workingDealStages];
    updatedDealStages[index] = { ...updatedDealStages[index], [field]: value };
    setWorkingDealStages(updatedDealStages);
  };

  const handleToggleChange = (index, field, checked) => {
    const updatedDealStages = [...workingDealStages];

    if (field === 'isMapDefault' && checked) {
      const currentDefaultStage = updatedDealStages.find(
        (stage) =>
          stage.isMapDefault && stage.id !== updatedDealStages[index].id
      );
      if (currentDefaultStage) {
        setConfirmDialogMessage(
          `The current default stage is "${currentDefaultStage.title}". Do you want to set "${updatedDealStages[index].title}" as the new default stage?`
        );
        setDefaultStageChange({ index, field, checked });
        setOpenConfirmDialog(true);
        return;
      }
    } else if (field === 'isNoDealDefault' && checked) {
      const currentDefaultNoDealStage = updatedDealStages.find(
        (stage) =>
          stage.isNoDealDefault && stage.id !== updatedDealStages[index].id
      );
      if (currentDefaultNoDealStage) {
        setConfirmDialogMessage(
          `The current no-deal default stage is "${currentDefaultNoDealStage.title}". Do you want to set "${updatedDealStages[index].title}" as the new no-deal default stage?`
        );
        setDefaultStageChange({ index, field, checked });
        setOpenConfirmDialog(true);
        return;
      }
    }

    updatedDealStages[index] = {
      ...updatedDealStages[index],
      [field]: checked,
    };
    setWorkingDealStages(updatedDealStages);
  };

  const confirmDefaultChange = () => {
    const { index, field, checked } = defaultStageChange;
    const updatedDealStages = [...workingDealStages];

    if (field === 'isMapDefault') {
      updatedDealStages.forEach((stage, idx) => {
        if (stage.isMapDefault && idx !== index) {
          updatedDealStages[idx] = { ...stage, isMapDefault: false };
        }
      });
    } else if (field === 'isNoDealDefault') {
      updatedDealStages.forEach((stage, idx) => {
        if (stage.isNoDealDefault && idx !== index) {
          updatedDealStages[idx] = { ...stage, isNoDealDefault: false };
        }
      });
    }

    updatedDealStages[index] = {
      ...updatedDealStages[index],
      [field]: checked,
    };

    setWorkingDealStages(updatedDealStages);
    setOpenConfirmDialog(false);
    setDefaultStageChange(null);
  };

  const handleCalendarStatusChange = (index, status, checked) => {
    let calendarStatusArray =
      workingDealStages[index].calendarAppointmentStatus?.length > 0
        ? workingDealStages[index].calendarAppointmentStatus.split(', ')
        : [];

    if (checked) {
      calendarStatusArray.push(status);
    } else {
      calendarStatusArray = calendarStatusArray.filter((s) => s !== status);
    }

    handleChange(
      index,
      'calendarAppointmentStatus',
      calendarStatusArray.join(', ')
    );
  };

  const saveChanges = async () => {
    let updatedWorkingStages = [...workingDealStages];
    const errors = [];

    // Validate new stage if it exists
    if (newStage) {
      const validationErrors = validateStage(newStage);
      if (Object.keys(validationErrors).length > 0) {
        errors.push({ id: newStage.id, errors: validationErrors });
      }
    }

    // Validate updated stages
    const updatedChangedStages = updatedWorkingStages.filter((stage) => {
      const originalStage = dealStages.find((ds) => ds.id === stage.id);
      return originalStage && hasUnsavedChanges(originalStage, stage);
    });

    updatedChangedStages.forEach((stage) => {
      const validationErrors = validateStage(stage);
      if (Object.keys(validationErrors).length > 0) {
        errors.push({ id: stage.id, errors: validationErrors });
      }
    });

    // If there are errors, set error messages and return
    if (errors.length > 0) {
      setErrorMessages(errors);
      return;
    }

    // Clear error messages if no errors found
    setErrorMessages([]);

    // Create the new stage if it exists
    if (newStage) {
      const submitNewStageObj = { ...newStage };
      try {
        const result = await API.graphql(
          graphqlOperation(createStage, {
            input: removeEmptyFields(submitNewStageObj),
          })
        );
        const createdStage = result.data.createStage;
        console.log('Created Stage:', createdStage);
        updatedWorkingStages = [submitNewStageObj, ...updatedWorkingStages];
      } catch (error) {
        console.error('Error creating new stage:', error);
        return;
      }

      setNewStage(null);
    }

    // Update the changed stages
    for (const stage of updatedChangedStages) {
      const submitDealObj = { ...stage };
      try {
        await API.graphql(
          graphqlOperation(updateStage, {
            input: removeEmptyFields(submitDealObj),
          })
        );
      } catch (error) {
        console.error(`Error updating stage ${stage.id}:`, error);
        return; // Exit the function if there's an error updating any stage
      }
    }

    // Sort the stages by their sequence
    const sortedDealStages = updatedWorkingStages.sort(
      (a, b) => a.sequence - b.sequence
    );

    updateStages(sortedDealStages);
    setChangedStages([]);
  };

  const validateStage = (stage) => {
    const errors = {};

    // Check if the title is missing
    if (!stage.title || stage.title.trim() === '') {
      errors.title = 'Title is required.';
    }

    // Check if the title is unique
    const isTitleUnique = workingDealStages.every(
      (s) => s.id === stage.id || s.title.trim() !== stage.title.trim()
    );

    if (!isTitleUnique) {
      errors.title = 'Title must be unique.';
    }

    return errors;
  };

  const resetChanges = (index, e) => {
    e.stopPropagation();
    const originalStage = dealStages[index];
    const updatedDealStages = [...workingDealStages];
    updatedDealStages[index] = { ...originalStage };
    setWorkingDealStages(updatedDealStages);
  };

  const confirmDeleteStage = (id, index) => {
    setStageToDelete({ id, index });
    setOpenDialog(true);
  };

  const deleteStage = async () => {
    console.log(
      `Stage deleted: ${stageToDelete.id} from index: ${stageToDelete.index}`
    );
    const deleteStageIndex = stageToDelete.index;
    const updatedStage = {
      ...workingDealStages[deleteStageIndex],
      isDeleted: true,
    };

    // Update the stage using GraphQL mutation
    try {
      await API.graphql(
        graphqlOperation(updateStage, {
          input: removeEmptyFields(updatedStage),
        })
      );
    } catch (error) {
      console.error(`Error deleting stage ${updatedStage.id}:`, error);
      return; // Exit the function if there's an error updating the stage
    }

    // Create a new stages list by splicing out the stage to delete
    const newStages = [...dealStages];
    newStages.splice(deleteStageIndex, 1);

    updateStages(newStages);

    setOpenDialog(false);
    setStageToDelete(null);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNewStageChange = (field, value) => {
    setNewStage((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Box border={'1px solid gray'}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="10px 35px"
        borderBottom="1px solid gray"
      >
        {(changedStages.length > 0 ||
          (newStage && newStage?.title.length > 0)) && (
          <Button variant="contained" color="primary" onClick={saveChanges}>
            Save Changes
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            if (!newStage) {
              const newID = uuidv4();
              setNewStage({
                ...newDealStageTemp(orgID, categoryID, newID),
              });
            } else {
              setNewStage(null);
            }
          }}
        >
          {!newStage ? 'Create New Stage' : 'Remove New Stage'}
        </Button>
      </Box>
      {errorMessages.length > 0 && (
        <Box my={2}>
          {errorMessages.map((error, index) => (
            <Alert severity="error" key={index}>
              {error.errors.title
                ? `Stage ${error.id}: ${error.errors.title}`
                : null}
            </Alert>
          ))}
        </Box>
      )}
      {newStage && (
        <Accordion expanded={expanded === newStage.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-new-content`}
            id={`panel-new-header`}
            style={{
              backgroundColor: '#e0cfe8',
              padding: '10px 35px',
            }}
            onClick={() => setExpanded(newStage.id)}
          >
            <Typography style={{ fontSize: 'large', fontWeight: 700 }}>
              {(!newStage.title.length ? 'New Stage' : newStage.title) +
                ' (Unsaved)'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '10px 35px' }}>
            <DealStageForm
              stage={newStage}
              stageDispositions={stageDispositions}
              onChange={(field, value) => handleNewStageChange(field, value)}
              onToggleChange={(field, checked) =>
                handleNewStageChange(field, checked)
              }
              onCalendarStatusChange={(status, checked) =>
                handleNewStageChange(
                  'calendarAppointmentStatus',
                  checked
                    ? [
                        ...(newStage.calendarAppointmentStatus?.split(', ') ||
                          []),
                        status,
                      ].join(', ')
                    : (newStage.calendarAppointmentStatus?.split(', ') || [])
                        .filter((s) => s !== status)
                        .join(', ')
                )
              }
            />
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button
                variant="contained"
                color="primary"
                onClick={saveChanges}
                style={{ marginTop: '16px' }}
              >
                Save New Stage
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setNewStage(null)}
                style={{ marginTop: '16px', marginLeft: 'auto' }}
              >
                Cancel
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {workingDealStages.map((stage, index) => (
        <Accordion
          key={stage.id}
          expanded={expanded === stage.id}
          onChange={handleAccordionChange(stage.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            style={{
              backgroundColor: expanded === stage.id ? '#e0cfe8' : 'inherit',
              padding: '10px 35px',
            }}
            px={26}
          >
            <Typography style={{ fontSize: 'large', fontWeight: 700 }}>
              {dealStages.find((ds) => ds.id === stage.id)?.title}{' '}
              {changedStages.includes(stage.id) && (
                <>
                  <span style={{ color: 'red' }}>(Unsaved Changes)</span>
                  <Typography
                    component="span"
                    style={{
                      color: 'gray',
                      cursor: 'pointer',
                      marginLeft: '10px',
                    }}
                    onClick={(e) => resetChanges(index, e)}
                  >
                    reset
                  </Typography>
                </>
              )}
            </Typography>
            <Typography
              style={{ marginLeft: 'auto', fontSize: 'large', fontWeight: 500 }}
            >
              Sequence: {stage.sequence}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '10px 35px' }}>
            <DealStageForm
              stage={stage}
              stageDispositions={stageDispositions}
              onChange={(field, value) => handleChange(index, field, value)}
              onToggleChange={(field, checked) =>
                handleToggleChange(index, field, checked)
              }
              onCalendarStatusChange={(status, checked) =>
                handleCalendarStatusChange(index, status, checked)
              }
            />
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => confirmDeleteStage(stage.id, index)}
                style={{ marginTop: '16px', marginLeft: 'auto' }}
              >
                Delete Stage
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Stage'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this stage? This action cannot be
            undone.
            {changedStages.length > 0 && (
              <Typography
                color="error"
                variant="body2"
                style={{ marginTop: '16px' }}
              >
                There are unsaved changes in other stages. Deleting this stage
                will discard those changes. Would you like to save changes &
                delete, delete and discard changes, or cancel?
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteStage} color="secondary" autoFocus>
            {'Delete' + (changedStages.length > 0 ? ' & Discard Changes' : '')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          Confirm Default Stage Change
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {confirmDialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDefaultChange} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DealStagesEditor;
