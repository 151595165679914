import React from 'react';
import CustomTextInput from '../../../custom-inputs/CustomTextInput';
import CustomSelectRewards from './CustomSelectRewards';
import NewGameState from '../../../_types/NewGameState';
import RewardType from '../../../_types/RewardType';

interface Props {
  rewards: RewardType[];
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const CompanyActionInputs: React.FC<Props> = ({
  rewards,
  newGameState,
  onChangeNewGameState,
}) => {
  if (
    newGameState.rewardWhen !== 'goal' &&
    newGameState.rewardWhen !== 'action'
  ) {
    return null;
  }

  if (newGameState.rewardCurrency === 'points') {
    return (
      <CustomTextInput
        label="Coins"
        value={newGameState.rewardCount1}
        onChange={(newValue) => {
          onChangeNewGameState({ ...newGameState, rewardCount1: newValue });
        }}
      />
    );
  }

  return (
    <>
      <CustomTextInput
        label="Winner Limit"
        placeholder="E.g. 30"
        value={newGameState.rewardLimit}
        onChange={(newValue) => {
          onChangeNewGameState({ ...newGameState, rewardLimit: newValue });
        }}
      />
      <CustomSelectRewards
        label="Reward"
        value={newGameState.rewardName1}
        onSelect={(reward: RewardType) => {
          onChangeNewGameState({
            ...newGameState,
            rewardID1: reward.id,
            rewardName1: reward.name,
            showCustomReward1: false,
          });
        }}
        items={rewards}
      />
    </>
  );
};

export default CompanyActionInputs;
