import { API } from 'aws-amplify';
import CommentType from '../_types/CommentType';

const GET_COMMENT_QUERY = `
  query MyQuery($id: ID!) {
    getComment(id: $id) {
      id
      content
      createdAt
      imageName
      imageType
      userID
      updatedAt
      isDeleted
      activityFeed {
        id
      }
      emoticons {
        items {
          id
          createdAt
          type
          user {
            id
            imageName
            name
            title
          }
        }
      }
      comments(filter: { isDeleted: { eq: false } }) {
        items {
          id
        }
      }
      user {
        id
        name
        title
        imageName
        imageType
      }
    }
  }
`;

interface GetCommentQueryApiResponse {
  data: {
    getComment: CommentType;
  };
}

const getComment = async ({
  commentId,
}: {
  commentId: string;
}): Promise<CommentType> => {
  const comment = (await API.graphql({
    query: GET_COMMENT_QUERY,
    variables: { id: commentId },
  })) as GetCommentQueryApiResponse;

  return comment.data.getComment as CommentType;
};

export default getComment;
