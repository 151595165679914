import React, { useEffect, useState } from 'react';
import DisplayBracketSeedView from './DisplayBracketSeed.view';
import { API } from 'aws-amplify';
import { LIST_QUEST_MISSION_BY_QUEST } from './_query';
import Game from '../../_types/Game';
import QuestMission from '../../_types/QuestMission';

type ListQuestMissionByQuestApiResponse = {
  data: {
    listQuestMissionByQuest: {
      items: QuestMission[];
    };
  };
};

interface Props {
  game: Game;
}

const DisplayBracketSeedContainer = ({ game }: Props) => {
  const [brackets, setBrackets] = useState<QuestMission[]>([]);

  useEffect(() => {
    const getListQuestMissionByQuest = async () => {
      const results = (await API.graphql({
        query: LIST_QUEST_MISSION_BY_QUEST,
        variables: {
          questID: game.id,
        },
      })) as ListQuestMissionByQuestApiResponse;

      const sortedNewBrackets = results.data.listQuestMissionByQuest.items.sort(
        (a, b) => a.sequence - b.sequence
      );

      setBrackets(sortedNewBrackets);
    };
    getListQuestMissionByQuest();
  }, [game.id]);

  return <DisplayBracketSeedView brackets={brackets} />;
};

export default DisplayBracketSeedContainer;
