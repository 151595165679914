import { Storage } from 'aws-amplify';

export default async function getUserAvatar(
  imageName,
  imageType,
  dontShowDefaultAvatar
) {
  // console.log('getUserAvatar: ', imageName);
  if (imageName !== '') {
    const url = await Storage.get(imageName);
    // console.log('!!url: ', url);
    return url;
  }

  if (dontShowDefaultAvatar) {
    return '';
  }

  return '/images/default-avatar.png';
}
