import { createSlice } from '@reduxjs/toolkit';

const avatarUrlSlice = createSlice({
  name: 'profile',
  initialState: {
    value: null,
  },
  reducers: {
    avatarUrl: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { avatarUrl } = avatarUrlSlice.actions;
export default avatarUrlSlice.reducer;
