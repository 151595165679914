import React from 'react';
import { Box, Skeleton } from '@mui/material';

export default function ProfileAboutCardSkeleton() {
  return (
    <Box id="about-subpage">
      {['', '', '', '', '', ''].map((skeleton, i) => (
        <Box
          key={skeleton + i}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 16,
          }}
        >
          <Skeleton
            sx={{ bgcolor: '#222428' }}
            variant={'rounded'}
            width={80}
            height={24}
          ></Skeleton>
          <Skeleton
            sx={{ bgcolor: '#868686' }}
            variant={'rounded'}
            width={100}
            height={24}
          ></Skeleton>
        </Box>
      ))}
      {/* <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <Skeleton
          sx={{ bgcolor: '#222428' }}
          variant={'rounded'}
          width={80}
          height={24}
        ></Skeleton>
        <Skeleton
          sx={{ bgcolor: '#868686' }}
          variant={'rounded'}
          width={100}
          height={24}
        ></Skeleton>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <Skeleton
          sx={{ bgcolor: '#222428' }}
          variant={'rounded'}
          width={80}
          height={24}
        ></Skeleton>
        <Skeleton
          sx={{ bgcolor: '#868686' }}
          variant={'rounded'}
          width={100}
          height={24}
        ></Skeleton>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <Skeleton
          sx={{ bgcolor: '#222428' }}
          variant={'rounded'}
          width={80}
          height={24}
        ></Skeleton>
        <Skeleton
          sx={{ bgcolor: '#868686' }}
          variant={'rounded'}
          width={100}
          height={24}
        ></Skeleton>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <Skeleton
          sx={{ bgcolor: '#222428' }}
          variant={'rounded'}
          width={80}
          height={24}
        ></Skeleton>
        <Skeleton
          sx={{ bgcolor: '#868686' }}
          variant={'rounded'}
          width={100}
          height={24}
        ></Skeleton>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <Skeleton
          sx={{ bgcolor: '#222428' }}
          variant={'rounded'}
          width={80}
          height={24}
        ></Skeleton>
        <Skeleton
          sx={{ bgcolor: '#868686' }}
          variant={'rounded'}
          width={100}
          height={24}
        ></Skeleton>
      </Box>

      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton
          sx={{ bgcolor: '#222428' }}
          variant={'rounded'}
          width={80}
          height={24}
        ></Skeleton>
        <Skeleton
          sx={{ bgcolor: '#868686' }}
          variant={'rounded'}
          width={100}
          height={24}
        ></Skeleton>
      </Box> */}
    </Box>
  );
}
