import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import {
  DealStage,
  DealType,
  DealTypeConfig,
} from '../../routes/deals/kanban/DealsKanban.types';

interface DealsState {
  dealTypeId: string | null;
  dealTypes: {
    [id: string]: DealType;
  };
  dealTypesConfigMap: {
    [id: string]: DealTypeConfig;
  };
  dealTypesStagesMap: {
    [id: string]: DealStage[];
  };
}

const initialState: DealsState = {
  dealTypeId: null,
  dealTypes: {},
  dealTypesConfigMap: {},
  dealTypesStagesMap: {},
};

const usersSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    loadDealTypeIdAction: (state, action: PayloadAction<string>) => {
      state.dealTypeId = action.payload;
    },
    loadDealTypesAction: (
      state,
      action: PayloadAction<{
        [id: string]: DealType;
      }>
    ) => {
      state.dealTypes = action.payload;
    },
    loadDealTypesConfigMapAction: (
      state,
      action: PayloadAction<{
        [id: string]: DealTypeConfig;
      }>
    ) => {
      state.dealTypesConfigMap = action.payload;
    },
    loadDealTypesStagesMapAction: (
      state,
      action: PayloadAction<{
        [id: string]: DealStage[];
      }>
    ) => {
      state.dealTypesStagesMap = action.payload;
    },
    resetDealsStateAction: () => initialState,
  },
});

export const {
  loadDealTypeIdAction,
  loadDealTypesAction,
  loadDealTypesConfigMapAction,
  loadDealTypesStagesMapAction,
  resetDealsStateAction,
} = usersSlice.actions;

export const selectDealTypeId = (state: RootState) => state.deals.dealTypeId;
export const selectDealTypes = (state: RootState) => state.deals.dealTypes;
export const selectDealTypesConfigMap = (state: RootState) =>
  state.deals.dealTypesConfigMap;
export const selectDealTypesStagesMap = (state: RootState) =>
  state.deals.dealTypesStagesMap;

export default usersSlice.reducer;
