import { useFormikContext } from 'formik';
import React from 'react';
import { ImagePreviewCloseIcon } from './Rewards.styles';

const ImageCloseButton = () => {
  const { setFieldValue } = useFormikContext();

  return (
    <ImagePreviewCloseIcon
      onClick={() => {
        setFieldValue('imageName', '');
        setFieldValue('imageType', '');
      }}
      src="/images/close.svg"
    />
  );
};

export default ImageCloseButton;
