import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { CustomTextField } from '../CRMConfigV2/styledComponents';

const ExpandableButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid lightgray',
  padding: '8px 10px',
  borderRadius: '6px',
  backgroundColor: 'white',
  minWidth: '150px',
  transition: 'all 0.3s ease',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CustomButtonWithInput = ({ takenNames, onSubmit }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const handleButtonClick = () => {
    setIsExpanded(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    if (inputValue.trim() === '') {
      setError('Category name cannot be empty');
      return;
    }
    if (takenNames.includes(inputValue.trim())) {
      setError('Category name is already taken');
      return;
    }
    setError('');
    onSubmit(inputValue.trim());
    setIsExpanded(false);
    setInputValue('');
  };

  const handleCancel = () => {
    setIsExpanded(false);
    setInputValue('');
    setError('');
  };

  return (
    <ExpandableButtonContainer style={{ marginLeft: 'auto', gap: '15px' }}>
      {!isExpanded ? (
        <Button onClick={handleButtonClick}>
          <Typography style={{ fontSize: 'large' }}>
            Create new CRM +
          </Typography>
        </Button>
      ) : (
        <>
          <CustomTextField
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter CRM name"
            error={!!error}
            helperText={error}
          />
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
        </>
      )}
    </ExpandableButtonContainer>
  );
};

export default CustomButtonWithInput;
