import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  display: flex;
  gap: 30px;
  /* margin-top: 20px; */
  margin: 20px 0;
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
