import { API } from 'aws-amplify';

export const DELETE_FEED_ITEM_MUTATION = /* GraphQL */ `
  mutation DeleteFeedItem($request: AWSJSON) {
    deleteFeedItem(request: $request)
  }
`;

const remoteFeedItem = async ({ feedItemID }: { feedItemID: string }) => {
  const request = {
    id: feedItemID,
  };
  await API.graphql({
    query: DELETE_FEED_ITEM_MUTATION,
    variables: {
      request: JSON.stringify(request),
    },
  });
};

export default remoteFeedItem;
