import React, { useState, useEffect } from 'react';
import {
  Box,
  Input,
  InputAdornment,
  Typography,
  FormControl,
  TextField,
} from '@mui/material';
// import { StyledSelect, StyledMenuItem } from '../routes/teams/StyledComponent';
import { StyledSelect, StyledMenuItem } from './StyledComponents';
import { GamifyIcon } from '../components/GamifyIcon';
import { GamifyToast } from './CustomToasts';
import DynamicConfirmModal from './DynamicConfirmModal';
// import Moment from 'moment';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Autocomplete from '@mui/material/Autocomplete';

// Add these styles outside the components
const autocompleteStyles = {
  textField: {
    '& .MuiOutlinedInput-root': {
      height: '52px',
      borderRadius: '8px',
      border: '1px solid #D0D2D8',
      backgroundColor: '#ecedec',
      '& .MuiOutlinedInput-input': {
        padding: '12px 14px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  autocomplete: {
    backgroundColor: 'transparent',
    '& .MuiAutocomplete-endAdornment': {
      right: '8px',
      top: 'calc(50% - 14px)',
    },
    '& .MuiAutocomplete-input': {
      padding: '7.5px 4px 7.5px 6px !important',
    },
    '& .MuiInputBase-root': {
      padding: '0px 8px',
    },
  },
};

export function FormInput(props) {
  const {
    inputName,
    formikProps,
    hasSubmitted,
    required,
    focusCounter,
    setFocusCounter,
    placeholder,
    label,
    disabled,
  } = props;
  const inputValue = formikProps.values[inputName] || '';
  const errorMsg = formikProps.errors[inputName] || null;
  const [showExtIDAlertModal, setShowExtIDAlertModal] = useState(false);

  // console.log(`FormInput ${inputName} inputValue: `, inputValue);
  // console.log(`FormInput ${inputName} errorMsg: `, errorMsg);
  // console.log(`FormInput ${inputName} hasSubmitted: `, hasSubmitted);
  // console.log(`FormInput ${inputName} formikProps: `, formikProps);

  function addSpaceBeforeCapitalLetters(str) {
    let result = '';
    for (let i = 0; i < str.length; i++) {
      // Check if the character is a capital letter
      if (str[i] === str[i].toUpperCase() && str[i] !== ' ') {
        // Add a space before the capital letter
        result += ' ' + str[i];
      } else {
        // Otherwise, just add the character as it is
        result += str[i];
      }
    }
    return result;
  }

  // NOTE: Manually account for extID since it converts to 'Ext I D' and not 'Ext ID' with the addSapceBeforeCapitalLetters function
  const inputNameWithSpaces =
    inputName === 'extID' ? 'Ext ID' : addSpaceBeforeCapitalLetters(inputName);

  const handleClearInput = () => {
    formikProps.handleChange(inputName)('');
  };

  const cancelIconAdornment =
    inputValue.length > 0 && !disabled ? (
      <Box
        className={'clear-input-button'}
        style={{
          position: 'relative',
          right: 8,
        }}
        sx={{ cursor: 'pointer' }}
        onClick={handleClearInput}
      >
        <InputAdornment position="end">
          <GamifyIcon icon={'delete'}></GamifyIcon>
        </InputAdornment>
      </Box>
    ) : (
      <></>
    );

  const renderTitle = () => {
    if (label) {
      return label;
    } else if (required) {
      return `*${
        inputNameWithSpaces[0].toUpperCase() + inputNameWithSpaces.slice(1)
      }`;
    } else {
      return (
        inputNameWithSpaces[0].toUpperCase() + inputNameWithSpaces.slice(1)
      );
    }
  };

  return (
    <>
      <DynamicConfirmModal
        showDynamicConfirmModal={showExtIDAlertModal}
        setShowDynamicConfirmModal={setShowExtIDAlertModal}
        zIndex={100008}
        leftAlignText={true}
        title={
          "WARNING: If another user's extID is used, this user's data will be put on that other user's accont."
        }
        subtitle={'If the extID entered matches no users, it will fail.'}
        confirmButtonText={'OK'}
        onConfirm={() => {
          setShowExtIDAlertModal(false);
        }}
        noCancelButton={true}
        // isSaving={isSaving}
      ></DynamicConfirmModal>
      <Box
        style={{
          position: 'relative',
          width: '100%',
          marginBottom: 16,
        }}
      >
        <Typography
          onClick={() => console.log('THIS FIELD----->', inputName)}
          style={{
            fontSize: 12,
            fontWeight: 700,
            color: '#0B0B0C',
            marginBottom: 8,
          }}
        >
          {renderTitle()}
        </Typography>
        <Input
          // NOTE: Users must be alerted of the impacts of editing a user's extID before doing so.
          onFocus={() => {
            if (inputName === 'extID') {
              // alert(
              //   "WARNING: If another user's extID is used, this user's data will be put on that other user's accont. If the extID entered matches no users, it will fail."
              // );
              if (focusCounter === 0) {
                setShowExtIDAlertModal(true);
                // Increment focusCounter so it doesn't open the modal beyond the first focus
                setFocusCounter(focusCounter + 1);
              }
            }
          }}
          className={
            errorMsg && hasSubmitted
              ? 'error-team-form-input'
              : 'team-form-input'
          }
          style={{
            width: '100%',
            padding: '12px 16px 12px 16px',
            border: 0,
            borderRadius: 8,
            ...(props.inputStyle || {}),
          }}
          placeholder={
            placeholder ||
            inputNameWithSpaces[0].toUpperCase() + inputNameWithSpaces.slice(1)
          }
          // inputProps={{ maxLength: getMaxLength(flexField) }}
          value={formikProps.values[inputName]}
          onChange={(e) => {
            //   console.log('ON CHANGE EVENT --->', e);
            //   console.log('ON CHANGE EVENT TARGET VALUE --->', e.target.value);
            formikProps.handleChange(inputName)(e.target.value);
          }}
          endAdornment={cancelIconAdornment}
          disabled={disabled}
        />
      </Box>
      {errorMsg && hasSubmitted && (
        <Typography
          color="red"
          weight="400"
          style={{
            fontSize: 12,
            marginTop: -12,
            color: 'red',
            paddingBottom: 10,
          }}
        >
          {errorMsg}
        </Typography>
      )}
    </>
  );
}

export function FormSelect(props) {
  const {
    value,
    fieldSetter,
    selectList,
    title,
    selectStyle,
    inputName,
    containerStyle,
  } = props;
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '100%',
          marginBottom: 16,
          ...(containerStyle || {}),
        }}
      >
        <Typography
          onClick={() => console.log('THIS FIELD----->', title)}
          style={{
            fontSize: 12,
            fontWeight: 700,
            color: '#0B0B0C',
            marginBottom: 8,
          }}
        >
          {title}
        </Typography>
        <StyledSelect
          style={{ width: '100%', borderRadius: 8, ...(selectStyle || {}) }}
          onChange={(e) => {
            //   console.log('style select onChange val: ', e);
            fieldSetter(e.target.value);
          }}
          value={value}
          name={inputName}
          MenuProps={{
            MenuListProps: {
              sx: {
                maxHeight: 300,
                paddingTop: 0,
                paddingBottom: 0,
                overflowY: 'auto',
                borderRadius: '8px',
                border: '1px solid #D0D2D8',
              },
            },
          }}
        >
          {selectList.map((opt, i) => (
            <StyledMenuItem value={opt.value} key={i}>
              {opt.label[0].toUpperCase() + opt.label.slice(1)}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </Box>
    </>
  );
}

export function FormSelectPerson(props) {
  const { value, fieldSetter, people, title } = props;

  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '100%',
          marginBottom: 16,
        }}
      >
        <Typography
          onClick={() => console.log('THIS FIELD----->', title)}
          style={{
            fontSize: 12,
            fontWeight: 700,
            color: '#0B0B0C',
            marginBottom: 8,
          }}
        >
          {title}
        </Typography>
        <StyledSelect
          style={{ width: '100%', borderRadius: 8 }}
          onChange={(e) => {
            //   console.log('style select onChange val: ', e);
            e.stopPropagation();
            fieldSetter(e.target.value);
          }}
          value={value}
          name="owner"
          MenuProps={{
            MenuListProps: {
              sx: {
                maxHeight: 300,
                paddingTop: 0,
                paddingBottom: 0,
                overflowY: 'auto',
                borderRadius: '8px',
                border: '1px solid #D0D2D8',
              },
            },
            sx: {
              zIndex: 100001,
              '& .MuiPaper-root': {
                zIndex: 100001,
              },
            },
            PaperProps: {
              sx: {
                zIndex: 100001,
              },
            },
          }}
        >
          {people.map((opt, i) => (
            <StyledMenuItem value={opt.value} key={i}>
              {opt.label[0].toUpperCase() + opt.label.slice(1)}
            </StyledMenuItem>
          ))}
          {/* NOTE: defaults to admin as owner, but if no default then use this */}
          {/* {[{ label: 'Select', value: '' }, ...people].map(
              (opt, i) => (
                <StyledMenuItem value={opt.value} key={i}>
                  {opt.label[0].toUpperCase() + opt.label.slice(1)}
                </StyledMenuItem>
              )
            )} */}
        </StyledSelect>
      </Box>
    </>
  );
}

export function FormLevelsInput(props) {
  const {
    value,
    fieldSetter,
    hasSubmitted,
    linkedTeams,
    teamLevels,
    setLevelNum,
    title,
    required,
  } = props;
  // console.log('FormLevelsInput teamLevels: ', teamLevels);

  if (!teamLevels.length) return <></>;

  const firstRowButtons = teamLevels.map((level) => {
    return {
      ...level,
      onClick: () => {
        if (linkedTeams.length > 0) {
          GamifyToast.error(
            'Cannot change Team Level while there are Linked Teams. Remove all Linked Teams and try again'
          );
          return;
        }
        fieldSetter(level.value);
        if (setLevelNum) {
          setLevelNum(level.levelNum); // set so form knows current levelNum for Link Teams Modal when creating a team
        }
      },
    };
  });

  return (
    <>
      <FormButtonSelection
        value={value}
        fieldSetter={fieldSetter}
        hasSubmitted={hasSubmitted}
        title={title}
        firstRowButtons={firstRowButtons}
        required={required}
      ></FormButtonSelection>
      {required && value === '' && hasSubmitted && (
        <Typography
          color="red"
          weight="400"
          style={{
            fontSize: 12,
            marginTop: -12,
            color: 'red',
            paddingBottom: 10,
          }}
        >
          Team Level is a required field
        </Typography>
      )}
    </>
  );
}

export function FormScopeInput(props) {
  const { value, fieldSetter, hasSubmitted, title } = props;

  const firstRowButtons = [
    {
      label: 'Anyone',
      value: 'public',
      onClick: () => {
        fieldSetter('public');
      },
    },
    {
      label: 'Invited People',
      value: 'private',
      onClick: () => {
        fieldSetter('private');
      },
    },
  ];

  return (
    <FormButtonSelection
      value={value}
      fieldSetter={fieldSetter}
      hasSubmitted={hasSubmitted}
      title={title}
      firstRowButtons={firstRowButtons}
    ></FormButtonSelection>
  );
}

export function FormWhoCanInviteInput(props) {
  const { value, fieldSetter, hasSubmitted, title } = props;

  const firstRowButtons = [
    {
      label: 'Moderators',
      value: 'moderator',
      onClick: () => {
        fieldSetter('moderator');
      },
    },
    {
      label: 'Only members',
      value: 'member',
      onClick: () => {
        fieldSetter('member');
      },
    },
    {
      label: 'Anyone',
      value: 'anyone',
      onClick: () => {
        fieldSetter('anyone');
      },
    },
    {
      label: 'Only team owners',
      value: 'owner',
      onClick: () => {
        fieldSetter('owner');
      },
    },
  ];
  // const secondRowButtons = [
  //   {
  //     label: 'Anyone',
  //     value: 'anyone',
  //     onClick: () => {
  //       fieldSetter('anyone');
  //     },
  //   },
  //   {
  //     label: 'Only team owners',
  //     value: 'owner',
  //     onClick: () => {
  //       fieldSetter('owner');
  //     },
  //   },
  // ];

  return (
    <FormButtonSelection
      value={value}
      fieldSetter={fieldSetter}
      hasSubmitted={hasSubmitted}
      title={title}
      firstRowButtons={firstRowButtons}
      // secondRowButtons={secondRowButtons}
    ></FormButtonSelection>
  );
}

export function FormWhoCanApproveInput(props) {
  const { value, fieldSetter, hasSubmitted, title } = props;

  const firstRowButtons = [
    {
      label: 'Automatically',
      value: 'auto',
      onClick: () => {
        fieldSetter('auto');
      },
    },
    {
      label: 'Only members',
      value: 'member',
      onClick: () => {
        fieldSetter('member');
      },
    },
    {
      label: 'Anyone',
      value: 'moderator',
      onClick: () => {
        fieldSetter('moderator');
      },
    },
    {
      label: 'Only team owners',
      value: 'owner',
      onClick: () => {
        fieldSetter('owner');
      },
    },
  ];
  // const secondRowButtons = [
  //   {
  //     label: 'Anyone',
  //     value: 'moderator',
  //     onClick: () => {
  //       fieldSetter('moderator');
  //     },
  //   },
  //   {
  //     label: 'Only team owners',
  //     value: 'owner',
  //     onClick: () => {
  //       fieldSetter('owner');
  //     },
  //   },
  // ];

  return (
    <FormButtonSelection
      value={value}
      fieldSetter={fieldSetter}
      hasSubmitted={hasSubmitted}
      title={title}
      firstRowButtons={firstRowButtons}
      // secondRowButtons={secondRowButtons}
    ></FormButtonSelection>
  );
}

export function FormButtonSelection(props) {
  const {
    value,
    // fieldSetter,
    title,
    firstRowButtons = [],
    secondRowButtons = [],
    required = false,
    hasSubmitted,
  } = props;
  // console.log(
  //   'ButtonSelection props: ',
  //   value,
  //   fieldSetter,
  //   title,
  //   firstRowButtons,
  //   secondRowButtons,
  //   required
  // );

  const [activeButton, setActiveButton] = useState(firstRowButtons?.[0]?.label);
  const [activeValue, setActiveValue] = useState(value);

  useEffect(() => {
    const buttons = [...firstRowButtons, ...secondRowButtons];

    const initiallySelectedButton = buttons.find(
      (button) => button.value === value
    );

    setActiveButton(initiallySelectedButton?.label || '');
    setActiveValue(initiallySelectedButton?.value || value);
  }, [activeValue, activeButton, value]);

  const handleClick = (newValue, buttonLabel, onClick) => {
    setActiveValue(newValue);
    setActiveButton(buttonLabel);
    onClick?.();
  };

  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '100%',
          marginBottom: 16,
        }}
      >
        <Typography
          onClick={() => console.log('THIS FIELD----->', title)}
          style={{
            fontSize: 12,
            fontWeight: 700,
            color: '#0B0B0C',
            marginBottom: 8,
          }}
        >
          {title}
        </Typography>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between',
          }}
        >
          <Box style={{ display: 'flex' }}>
            {firstRowButtons.map((button, i, arr) => (
              <FormSelectionItem
                key={i}
                isFirst={i === 0}
                isLast={i === arr.length - 1}
                label={button.label}
                isActive={activeButton === button.label}
                onClick={() =>
                  handleClick(button.value, button.label, button.onClick)
                }
                hasSubmitted={hasSubmitted}
                required={required}
                value={value}
              ></FormSelectionItem>
            ))}
          </Box>
          {secondRowButtons.length > 0 && (
            <Box style={{ display: 'flex', marginTop: 8 }}>
              {secondRowButtons.map((button, i, arr) => (
                <FormSelectionItem
                  key={i}
                  isFirst={i === 0}
                  isLast={i === arr.length - 1}
                  label={button.label}
                  isActive={activeButton === button.label}
                  onClick={() =>
                    handleClick(button.value, button.label, button.onClick)
                  }
                  hasSubmitted={hasSubmitted}
                  required={required}
                  value={value}
                ></FormSelectionItem>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export function FormSelectionItem(props) {
  const {
    isFirst,
    isLast,
    label,
    isActive,
    onClick,
    hasSubmitted,
    required,
    value,
  } = props;

  // console.log('FormSelectionItem required, value: ', required, value);

  return (
    <Box
      onClick={onClick}
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        backgroundColor: isActive ? '#FAC8B2' : 'white',
        borderRadius: 8,
        border:
          //   required && hasSubmitted && value === '' ? '1px solid #ff5c77' : 0,
          required && hasSubmitted && value === '' ? '1px solid #E35050' : 0,
        padding: '16px 24px 16px 24px',
        marginLeft: !isFirst ? 4 : 0,
        marginRight: !isLast ? 4 : 0,
      }}
    >
      <Typography
        style={{
          color: isActive ? '#FF6A00' : '#222428',
          fontSize: 12,
          fontWeight: 700,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}

export function FormSelectGiftcardAccount(props) {
  const { value, fieldSetter, giftCardAccounts, title } = props;
  const [itemValue, setItemValue] = useState('');

  if (itemValue !== props.value) {
    setItemValue(props.value);
  }

  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '100%',
          marginBottom: 16,
        }}
      >
        <Typography
          onClick={() => console.log('THIS FIELD----->', title)}
          style={{
            fontSize: 12,
            fontWeight: 700,
            color: '#0B0B0C',
            marginBottom: 8,
          }}
        >
          {title}
        </Typography>
        <StyledSelect
          style={{ width: '100%', borderRadius: 8 }}
          name="tangoAccountID"
          onChange={(e) => {
            //   console.log('style select onChange val: ', e);
            e.stopPropagation();
            // Select value needs to be 'Select' to display the placeholder since Select doesn't have an actual placeholder, make sure actual value is set back to ''
            if (e.target.value === 'Select') {
              fieldSetter('');
              // formikProps.setFieldValue('tangoAccountID', '');
            } else {
              fieldSetter(e.target.value);
              // formikProps.setFieldValue('tangoAccountID', e.target.value);
            }
          }}
          value={value === '' || value === 'NA' ? 'Select' : value}
          MenuProps={{
            MenuListProps: {
              sx: {
                maxHeight: 300,
                overflowY: 'auto',
                paddingTop: 0,
                paddingBottom: 0,
                borderRadius: '8px',
                border: '1px solid #D0D2D8',
              },
            },
            sx: {
              zIndex: 100001,
              '& .MuiPaper-root': {
                zIndex: 100001,
              },
            },
            PaperProps: {
              sx: {
                zIndex: 100001,
              },
            },
          }}
        >
          {[{ label: 'Select', value: 'Select' }, ...giftCardAccounts].map(
            (opt, i) => (
              <StyledMenuItem value={opt.value} key={i}>
                {opt.label}
              </StyledMenuItem>
            )
          )}
        </StyledSelect>
      </Box>
    </>
  );
}

export function FormModalInput(props) {
  const { onClick, gamifyIcon, label, hasItems } = props;

  return (
    <Box
      onClick={onClick}
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: 16,
        marginBottom: hasItems ? 8 : 16,
        borderRadius: 8,
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#FAC8B2',
          width: 40,
          height: 40,
          borderRadius: 2000,
        }}
      >
        <GamifyIcon icon={gamifyIcon}></GamifyIcon>
      </Box>
      <Typography
        style={{
          color: '#FF6A00',
          fontSize: 16,
          fontWeight: 700,
          marginLeft: 16,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}

// NOTE: FormDatePicker not needed for TeamFormPage, Start and End Date only used in game creation for now.

// Inside AdvancedOptionsForm
// const { startDate } = formikProps.values;

// let endMinimumDate = new Date();

// if (startDate && startDate instanceof Date) {
//   endMinimumDate = startDate;
// }

// Inside FormDatePicker
// const DEFAULT_DATE = new Date();

// function FormDatePicker(props) {
//   const {
//     title,
//     value = DEFAULT_DATE,
//     fieldSetter,
//     mode = 'date',
//     dateInputProps,
//     hideTime = false,
//     isStartDate = false,
//     isEndDate = false,
//   } = props;
//   // console.log('FormDatePicker value: ', value);
//   // console.log(
//   //   'FormDatePicker Moment(value).isValid(): ',
//   //   Moment(value).isValid()
//   // );
//   const [selectedDateTime, setSelectedDateTime] = useState(
//     value && Moment(value).isValid() ? value : DEFAULT_DATE
//   );
//   // const [tempDate, setTempDate] = useState(value || DEFAULT_DATE);
//   // const [showDatePicker, setShowDatePicker] = useState(false);
//   // const [showTimePicker, setShowTimePicker] = useState(false);

//   // TO DO: Verify tempDate, showDatePicker, showTimePicker, handleTempDateChange, and handleTimeChange are needed

//   useEffect(() => {
//     if (!value) {
//       setSelectedDateTime(DEFAULT_DATE);
//       // setTempDate(DEFAULT_DATE);
//     } else if (Moment(value, 'MM/DD/YYYY', true).isValid()) {
//       // Handling string in "MM/DD/YYYY" format
//       const parsedDate = Moment(value, 'MM/DD/YYYY').toDate();
//       setSelectedDateTime(parsedDate);
//       // setTempDate(parsedDate);
//     } else if (Moment(value).isValid()) {
//       // Handling Date object
//       setSelectedDateTime(value);
//       // setTempDate(value);
//     }
//   }, [value]);

//   // const handleTempDateChange = (selectedDate) => {
//   //   if (selectedDate && Moment(selectedDate).isValid()) {
//   //     setTempDate(selectedDate);
//   //   } else {
//   //     setTempDate(DEFAULT_DATE);
//   //   }
//   // };

//   const handleDateSelection = (newDate) => {
//     console.log('handleDateSelection newDate: ', newDate);
//     if (Moment(newDate).isValid()) {
//       let updatedDateTime;
//       if (mode === 'date') {
//         if (isStartDate) {
//           updatedDateTime = Moment(newDate).startOf('day').toDate();
//         } else if (isEndDate) {
//           updatedDateTime = Moment(newDate).endOf('day').toDate();
//         } else {
//           updatedDateTime = newDate;
//         }
//       } else if (mode === 'datetime') {
//         updatedDateTime = Moment(newDate).toDate();
//       } else {
//         // mode is "time"
//         updatedDateTime = Moment(selectedDateTime)
//           .hour(Moment(newDate).hour())
//           .minute(Moment(newDate).minute())
//           .second(hideTime ? 59 : 0)
//           .millisecond(hideTime ? 999 : 0)
//           .toDate();
//       }

//       setSelectedDateTime(updatedDateTime);
//       if (fieldSetter && Moment(updatedDateTime).isValid()) {
//         fieldSetter(updatedDateTime.format('MM-DD-YYYY'));
//       }
//       // setShowDatePicker(false);
//       // setShowTimePicker(false);
//     }
//   };

//   // const handleTimeChange = (event, selectedTime) => {
//   //   if (selectedTime && Moment(selectedTime).isValid()) {
//   //     const updatedDateTime = Moment(selectedDateTime)
//   //       .hour(Moment(selectedTime).hour())
//   //       .minute(Moment(selectedTime).minute())
//   //       .second(isEndDate ? 59 : 0)
//   //       .millisecond(isEndDate ? 999 : 0)
//   //       .toDate();
//   //     if (Moment(updatedDateTime).isValid()) {
//   //       setSelectedDateTime(updatedDateTime);
//   //     }

//   //     if (fieldSetter && moment(updatedDateTime).isValid()) {
//   //       fieldSetter(updatedDateTime);
//   //     }
//   //   }
//   // };

//   return (
//     <>
//       <Box
//         style={{
//           position: 'relative',
//           width: '100%',
//           marginBottom: 16,
//         }}
//       >
//         <Typography
//           onClick={() => console.log('THIS FIELD----->', title)}
//           style={{
//             fontSize: 12,
//             fontWeight: 700,
//             color: '#0B0B0C',
//             marginBottom: 8,
//           }}
//         >
//           {title}
//         </Typography>
//         <LocalizationProvider dateAdapter={AdapterMoment}>
//           <DatePicker
//             value={selectedDateTime}
//             onChange={(newDateTime) => handleDateSelection(newDateTime)}
//             minDate={dateInputProps.minimumDate}
//             renderInput={(inputProps) => {
//               console.log('FormDatePicker inputProps: ', inputProps);
//               return (
//                 <TextField
//                   {...inputProps}
//                   id={'form-date-picker'}
//                   sx={{
//                     width: '100%',
//                     '& .MuiInputBase-root': {
//                       borderRadius: '8px',
//                       padding: '12px 16px',
//                       width: '100%',
//                     },
//                     '& .MuiInputBase-input': {
//                       width: '100%',
//                     },
//                   }}
//                   variant="standard"
//                   InputProps={{
//                     ...inputProps.InputProps,
//                     disableUnderline: true,
//                   }}
//                 />
//               );
//             }}
//             PopperProps={{
//               style: { borderRadius: '8px' },
//             }}
//             PaperProps={{
//               style: { border: '1px solid #D0D2D8', borderRadius: '8px' },
//             }}
//             // NOTE: slotProps below replaces renderInput in MUI v6
//             // slotProps={{ textField: { variant: 'outlined' } }}
//           ></DatePicker>
//         </LocalizationProvider>
//       </Box>
//     </>
//   );
// }

export function FormAutocomplete({
  value,
  onChange,
  onInputChange,
  options,
  getOptionLabel,
  placeholder,
  label,
  freeSolo = true,
}) {
  return (
    <FormControl fullWidth>
      {label && (
        <Typography
          style={{
            fontSize: 'small',
            fontWeight: 600,
            marginBottom: '8px',
          }}
        >
          {label}
        </Typography>
      )}
      <Autocomplete
        value={value}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        getOptionLabel={getOptionLabel}
        freeSolo={freeSolo}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            sx={autocompleteStyles.textField}
          />
        )}
        sx={autocompleteStyles.autocomplete}
      />
    </FormControl>
  );
}
