import gql from 'graphql-tag';
import { API } from 'aws-amplify';

const fetchTeamLevelMaps = async (orgID) => {
  // Get org team level IDs
  try {
    const teamLevelsQuery = gql`
      query MyQuery($orgID: ID!) {
        listCircleLevelByOrg(
          orgID: $orgID
          filter: { isDeleted: { eq: false } }
        ) {
          items {
            id
            name
            levelNum
          }
        }
      }
    `;

    const res = await API.graphql({
      query: teamLevelsQuery,
      variables: { orgID },
    });

    // console.log('fetchTeamLevels res: ', res);
    const teamLevelsRes = res.data.listCircleLevelByOrg.items;

    const teamLevelNumIdMap = {};
    const teamLevelNumNameMap = {};

    for (const level of teamLevelsRes) {
      // console.log('fetchTeamLevels level: ', level);
      // console.log('fetchTeamLevels teamLevelIdMap: ', teamLevelIdMap);

      // Populate the levelNumId and levelNumName maps

      teamLevelNumIdMap[level.levelNum] = level.id;
      teamLevelNumNameMap[level.levelNum] = level.name;
    }

    // console.log('fetchTeamLevels teamLevelNumIdMap: ', teamLevelNumIdMap);
    // console.log('fetchTeamLevels teamLevelNumNameMap: ', teamLevelNumNameMap);
    return [teamLevelNumIdMap, teamLevelNumNameMap];
  } catch (err) {
    console.error(
      'fetchTeamLevels(): error fetching Team Members: ',
      err.message
    );
  }
};

export default fetchTeamLevelMaps;
