import React from 'react';
import MyPerson from '../../_types/MyPerson';
interface Props {
  details: string;
  myPersons: MyPerson[];
}

const Details = ({ details, myPersons }: Props) => {
  let newDetails = details;

  // const words = details.split(/\s+/);
  // words.forEach((word) => {
  //   if (word.startsWith('#')) {
  //     console.log(word)
  //     newDetails = newDetails.replace(
  //       `${word}`,
  //       `<span style="color: #FF6A00">${word}</span>`
  //     );
  //   }
  // });

  newDetails = newDetails.replace(/#.*?[^ ](?=\s|$)/g, (match) => {
    return `<span style="color: #FF6A00">${match}</span>`;
  });

  myPersons.forEach((person) => {
    newDetails = newDetails.replace(
      `@${person.name}`,
      `<span style="color: #FF6A00">@${person.name}</span>`
    );
  });

  newDetails = newDetails.replace(
    '@All gamify',
    `<span style="color: #FF6A00">@All gamify</span>`
  );

  return (
    <div
      style={{ whiteSpace: 'pre-line', margin: '10px 0' }}
      dangerouslySetInnerHTML={{ __html: newDetails }}
    />
  );
};

export default Details;
