import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Store';

// NOTE: Based on the User model in schema.graphql
export interface User {
  id: string;
  extID: string;
  username: string;
  email: string;
  phoneNumber?: string;
  name: string;
  birthdate?: string;
  imageName?: string;
  imageType?: string;
  location?: string;
  nameVisibility: string;
  phoneNumberVisibility: string;
  emailVisibility: string;
  birthdateVisibility: string;
  pictureVisibility: string;
  locationVisibility: string;
  initials?: string;
  pronoun?: string;
  role: string;
  functionalRoles: string[];
  status: string;
  expoToken?: string;
  orgID: string;
  title?: string;
  avatarID?: string;
  levelID?: string;
  circleLevel?: object;
  agreedToTerms: boolean;
  agreedToTermsAt?: string;
  tags?: string;
  colorCode?: string;
  hasWanderMode?: boolean;
  hasRoundRobin?: boolean;
  flexAttributes?: string;
  addresses?: any[];
  circles?: any[];
  rewards?: any[];
  orgs?: any[];
  kpis?: any[];
  trophies?: any[];
  lessonAssignments?: any[];
  PersonDefaultSchedules?: any[];
  PersonScheduleOverrides?: any[];
  PersonAppointments?: any[];
  SetterAppointments?: any[];
  createdBy?: string;
  lastUpdatedBy?: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
}

interface Profile {
  user: User;
  subpage?: {
    about?: string;
    stats?: string;
  };
  teams?: {
    [id: string]: object;
  };
  stats?: any[];
  dimension?: string;
  dimensionType?: string;
  selectedDateTime?: string;
  rewards?: any[];
  searchStatus?: string;
  searchAssigneeID?: string;
  appointments?: {
    [id: string]: object;
  };
  scheduleType?: string;
}

// TO DO: Make Team, Reward, and Appointment interfaces for use in the profiles key
interface UsersState {
  users: {
    [id: string]: User;
  };
  profiles: {
    [id: string]: Profile;
  };
  searchString: string;
  filters: {
    access: string;
    status: string;
  };
  currentPage: number;
  totalPages: number;
  usersPerPage: number;
}

const initialState: UsersState = {
  users: {},
  profiles: {},
  searchString: '',
  filters: {
    access: 'all',
    status: 'all',
  },
  currentPage: 1,
  totalPages: 1,
  usersPerPage: 50,
};

// NOTE: addUserAction and updatedUserAction are the same, they are just separated to make the code they're used in more clear as they have different purposes. Add is for entirely new users, update is for already existing users
// NOTE: Same as above for addProfileAction and updateProfileAction
const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loadUsersAction: (state, action: PayloadAction<User[]>) => {
      action.payload.forEach((user) => {
        state.users[user.id] = user;
      });
    },
    addUserAction: (state, action: PayloadAction<User>) => {
      state.users[action.payload.id] = action.payload;
    },
    removeUserAction: (state, action: PayloadAction<string>) => {
      delete state.users[action.payload];
    },
    removeUsersAction: (state, action: PayloadAction<User[]>) => {
      action.payload.forEach((user) => {
        delete state.users[user.id];
      });
    },
    updateUserAction: (state, action: PayloadAction<User>) => {
      state.users[action.payload.id] = action.payload;
    },
    loadProfileAction: (state, action: PayloadAction<Profile>) => {
      state.profiles[action.payload.user.id] = action.payload;
    },
    updateProfileAction: (state, action: PayloadAction<Profile>) => {
      state.profiles[action.payload.user.id] = action.payload;
    },
    setSearchStringAction: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setFiltersAction: (
      state,
      action: PayloadAction<{ access: string; status: string }>
    ) => {
      state.filters = action.payload;
    },
    setCurrentPageAction: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setTotalPagesAction: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setUsersPerPageAction: (state, action: PayloadAction<number>) => {
      state.usersPerPage = action.payload;
    },
    resetUsersStateAction: () => initialState,
  },
});

export const {
  loadUsersAction,
  addUserAction,
  removeUserAction,
  removeUsersAction,
  updateUserAction,
  loadProfileAction,
  updateProfileAction,
  setSearchStringAction,
  setFiltersAction,
  setCurrentPageAction,
  setTotalPagesAction,
  setUsersPerPageAction,
  resetUsersStateAction,
} = usersSlice.actions;

export const selectAllUsers = (state: RootState) => state.users.users;
export const selectAllProfiles = (state: RootState) => state.users.profiles;
export const selectSearchString = (state: RootState) =>
  state.users.searchString;
export const selectFilters = (state: RootState) => state.users.filters;
export const selectCurrentPage = (state: RootState) => state.users.currentPage;
export const selectTotalPages = (state: RootState) => state.users.totalPages;
export const selectUsersPerPage = (state: RootState) =>
  state.users.usersPerPage;

export default usersSlice.reducer;
