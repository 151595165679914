import { API } from 'aws-amplify';
import { LOG } from '../../../common/Utilities';
import EditCommentArgs from '../_types/EditCommentArgs';

const UPDATE_COMMENT_MUTATION = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      content
      userID
      imageName
      imageType
      orgID
      createdBy
      lastUpdatedBy
      createdAt
      updatedAt
      activityFeed {
        id
        event
        header
        details
        imageName
        imageType
        chartType
        chartData
        orgID
        type
        showAfterEpoch
        showUntilEpoch
        showAfter
        extID
        createdAt
        createdBy
        createdByUser {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          functionalRoles
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        people {
          nextToken
        }
        emoticons {
          nextToken
        }
        changedID
        changedComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        changedEmoticon {
          id
          activityFeedID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        changedCommentEmoticon {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        updatedAt
        lastUpdatedBy
        isDeleted
      }
      parentComment {
        id
        content
        userID
        imageName
        imageType
        orgID
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        activityFeed {
          id
          event
          header
          details
          imageName
          imageType
          chartType
          chartData
          orgID
          type
          showAfterEpoch
          showUntilEpoch
          showAfter
          extID
          createdAt
          createdBy
          changedID
          updatedAt
          lastUpdatedBy
          isDeleted
        }
        parentComment {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        comments {
          nextToken
        }
        emoticons {
          nextToken
        }
        user {
          id
          extID
          username
          email
          phoneNumber
          name
          birthdate
          imageName
          imageType
          location
          nameVisibility
          phoneNumberVisibility
          emailVisibility
          birthdateVisibility
          pictureVisibility
          locationVisibility
          initials
          pronoun
          role
          functionalRoles
          status
          expoToken
          orgID
          title
          avatarID
          levelID
          agreedToTerms
          agreedToTermsAt
          tags
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        isDeleted
      }
      comments {
        items {
          id
          content
          userID
          imageName
          imageType
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        nextToken
      }
      emoticons {
        items {
          id
          commentID
          type
          userID
          orgID
          createdAt
          isDeleted
          updatedAt
        }
        nextToken
      }
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        functionalRoles
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        PersonDefaultSchedules {
          nextToken
        }
        PersonScheduleOverrides {
          nextToken
        }
        PersonAppointments {
          nextToken
        }
        SetterAppointments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
    }
  }
`;

const updateComment = async (args: EditCommentArgs) => {
  try {
    await API.graphql({
      query: UPDATE_COMMENT_MUTATION,
      variables: { input: args },
    });
  } catch (error) {
    LOG.error(error);
  }
};

export default updateComment;
