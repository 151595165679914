import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Store';

// TO DO: Make Reward interface
interface RewardsState {
  allRewards: object;
  people: any[];
}

const initialState: RewardsState = {
  allRewards: {},
  people: [],
};

// TO DO: Create add, update, remove actions once they're needed
const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    loadAllRewardsAction: (state, action: PayloadAction<object>) => {
      state.allRewards = action.payload;
    },
    loadPeopleAction: (state, action: PayloadAction<object[]>) => {
      state.people = action.payload;
    },
    resetRewardsStateAction: () => initialState,
  },
});

export const {
  loadAllRewardsAction,
  loadPeopleAction,
  resetRewardsStateAction,
} = rewardsSlice.actions;

export const selectAllRewards = (state: RootState) => state.rewards.allRewards;
export const selectPeople = (state: RootState) => state.rewards.people;

export default rewardsSlice.reducer;
