import React from 'react';
import CenteredComponentView from './CenteredComponent.view';
/* eslint-disable no-undef */
interface CenteredComponentContainerProps {
  children: React.ReactNode;
}

const CenteredComponentContainer: React.FC<CenteredComponentContainerProps> = (
  props
) => {
  return <CenteredComponentView {...props} />;
};

export default CenteredComponentContainer;
