import { API } from 'aws-amplify';

const DELETE_ACTIVITY_FEED_EMOTICON_MUTATION = /* GraphQL */ `
  mutation DeleteActivityFeedEmoticon(
    $input: DeleteActivityFeedEmoticonInput!
    $condition: ModelActivityFeedEmoticonConditionInput
  ) {
    deleteActivityFeedEmoticon(input: $input, condition: $condition) {
      id
      activityFeedID
      type
      userID
      orgID
      createdAt
      user {
        id
        extID
        username
        email
        phoneNumber
        name
        birthdate
        imageName
        imageType
        location
        nameVisibility
        phoneNumberVisibility
        emailVisibility
        birthdateVisibility
        pictureVisibility
        locationVisibility
        initials
        pronoun
        role
        functionalRoles
        status
        expoToken
        orgID
        title
        avatarID
        levelID
        circleLevel {
          id
          name
          description
          levelNum
          orgID
          createdBy
          lastUpdatedBy
          createdAt
          updatedAt
          isDeleted
        }
        agreedToTerms
        agreedToTermsAt
        tags
        addresses {
          nextToken
        }
        circles {
          nextToken
        }
        rewards {
          nextToken
        }
        orgs {
          nextToken
        }
        kpis {
          nextToken
        }
        trophies {
          nextToken
        }
        lessonAssignments {
          nextToken
        }
        PersonDefaultSchedules {
          nextToken
        }
        PersonScheduleOverrides {
          nextToken
        }
        PersonAppointments {
          nextToken
        }
        SetterAppointments {
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdAt
        updatedAt
        isDeleted
      }
      isDeleted
      updatedAt
    }
  }
`;

const deleteEmoticon = async ({ emoticonID }: { emoticonID: string }) => {
  await API.graphql({
    query: DELETE_ACTIVITY_FEED_EMOTICON_MUTATION,
    variables: { input: { id: emoticonID } },
  });
};

export default deleteEmoticon;
