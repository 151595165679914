const GET_LIST_KPI_RULES_BY_ORG = `
  query MyQuery($orgID: ID!) {
    listKPIRulesByOrg(
      orgID: $orgID
      filter: { isDeleted: { eq: false } }
      limit: 10000
    ) {
      items {
        id
        title
        action
        valueUnit
        valueUnitPlural
        actionPastTense
        entitlement
      }
    }
  }
`;

export default GET_LIST_KPI_RULES_BY_ORG;
