import React from 'react';
import { Icon } from '../Actions.styles';
import { ViewContainer } from './CommentsAction.styles';

interface Props {
  totalComment: number;
  onNavigateToComments: () => void;
}

const CommentsActionView = ({ totalComment, onNavigateToComments }: Props) => {
  return (
    <ViewContainer onClick={onNavigateToComments}>
      <Icon
        src={`/images/comment-${totalComment ? 'filled' : 'outline'}.svg`}
      />
      <div style={{ fontWeight: 600, fontSize: 12 }}>
        {totalComment || null}
      </div>
    </ViewContainer>
  );
};

export default CommentsActionView;
