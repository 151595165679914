// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
//
// MyMissionsScreen
//
import React, { useState, useEffect } from 'react';
import { Text, View } from '@aws-amplify/ui-react';
import { Button, Box, ButtonGroup, Grid } from '@mui/material';
import { API } from 'aws-amplify';
import { MissionCard } from './missionUtils';
import {
  LOG,
  getLocalDateTime,
  formattedMonthDay,
  beginningOfTime,
  endOfTime,
} from '../../common/Utilities';
import gql from 'graphql-tag';

// const { innerWidth: width } = window;
// const screenWidth = width;

export default function MyMissionsScreen({ navigation }) {
  // LOG.debug ("MyMissionsScreen: ");

  const [missions, setMissions] = useState([]);
  // const [refreshing, setRefreshing] = React.useState(false);
  const initialMissionFilter = 0;
  const missionSort = 1;
  const missionSortOrder = 0;
  const [missionFilter, setMissionFilter] = useState(initialMissionFilter);
  const [filterButtons, setFilterButtons] = useState(false);
  const [displayMission, setDisplayMission] = useState('');
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    fetchMissions(missionFilter);
  }, []);

  // const onRefresh = React.useCallback(() => {
  //   // LOG.debug ("onRefresh: ");
  //
  //   setRefreshing(true);
  //   fetchMissions(missionFilter);
  //   setRefreshing(false);
  // }, []);

  /// ////////
  //
  //  Get our missions
  //
  /// ////////

  async function fetchMissions(missionFilterParm) {
    // LOG.debug ("fetchMissions: ", missionFilterParm, missionSortParm);
    const now = getLocalDateTime();

    // const now = new Date();
    LOG.debug('###now: ', now);
    // const nowDate = graphqlDateFormat(now);
    const nowDate = now.toISOString();

    LOG.debug('@@@@nowDate: ', nowDate);

    try {
      let startDateRange;
      let endDateRange;

      if (missionFilterParm === 0) {
        startDateRange = [beginningOfTime, nowDate];
        endDateRange = [nowDate, endOfTime];
      } else if (missionFilterParm === 1) {
        startDateRange = [nowDate, endOfTime];
        endDateRange = [nowDate, endOfTime];
      } else {
        startDateRange = [beginningOfTime, endOfTime];
        endDateRange = [beginningOfTime, endOfTime];
      }
      LOG.info(
        'filter: ' +
          missionFilterParm +
          ', startDateRange: ' +
          startDateRange +
          ', endDateRange: ' +
          endDateRange
      );
      const myQuery = gql`
        query MyQuery(
          $orgID: ID!
          $endDateRange: [String]!
          $startDateRange: [String]!
        ) {
          listMissionByEndDate(
            orgID: $orgID
            endDate: { between: $endDateRange }
            filter: {
              type: { ne: quest_mission }
              launchDate: { between: $startDateRange }
              isDeleted: { eq: false }
            }
            limit: 10000
          ) {
            items {
              id
              name
              startDate
              endDate
              isCircleOnly
            }
          }
        }
      `;

      const res = await API.graphql({
        query: myQuery,
        variables: {
          userID: global.me.id,
          startDateRange,
          endDateRange,
          orgID: global.me.orgID,
        },
      });
      const missions = res.data.listMissionByEndDate.items;
      console.log('missions: ', missions);

      const sortedMissions = missions.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });

      setMissions(sortedMissions);
      // LOG.debug ("myMissions: ", myMissions);
    } catch (err) {
      LOG.error('fetchMissions(): error fetching missions' + err);
    }
  }

  function addMission() {
    window.location.href = '/campaigns/new';
    // // LOG.debug ("addMission: ");
    // const ourNavigationState = navigation.getState();
    // // LOG.debug ("ourNavigationState: ",ourNavigationState);
    // if (ourNavigationState.routes[0].name === 'More') {
    //   navigation.navigate('Create Mission', {
    //     missionID: '',
    //     onExit:
    //       () => {
    //         setTimeout(() => fetchMissions(missionFilter), 1000);
    //       }
    //   });
    // } else {
    //   navigation.navigate('Create', {
    //     missionID: '',
    //     onExit:
    //       () => {
    //         setTimeout(() => fetchMissions(missionFilter), 1000);
    //       }
    //   });
    // }
  }

  // Beginning of screen content
  return (
    <View
      style={{
        flex: 1,
        padding: 0,
        justifyContent: 'center',
        backgroundColor: 'white',
        width: '70%',
        marginLeft: '15%',
        borderRadius: 30,
      }}
    >
      <View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button
            style={{ marginTop: 5 }}
            // icon={<Icon name="filter" size={25} marginRight={2} color="black" type="font-awesome-5"/>}
            // titleStyle={{ color: 'gray' }}
            // type="clear"
            onClick={() => setFilterButtons(!filterButtons)}
          >
            {global.appSettings.displayIconLabels ? 'Filter' : 'Filter'}
          </Button>
          {global.me.canCreateMissions && (
            <Button
              style={{ marginTop: 5 }}
              // icon={<Icon name="plus-circle" size={25} marginRight={2} color="black" type="font-awesome-5"/>}
              // titleStyle={{ color: 'gray' }}
              // type="clear"
              onClick={() => addMission()}
            >
              {global.appSettings.displayIconLabels ? 'Create' : 'Create'}
            </Button>
          )}
        </View>
        {filterButtons && (
          <ButtonGroup
            onClick={(val) => {
              // LOG.debug("filter: ", val);
              setMissionFilter(val);
              // setRefreshing(true);
              fetchMissions(val, missionSort, missionSortOrder);
              // setRefreshing(false);
            }}
            selectedIndex={missionFilter}
            buttons={['Current', 'Future', 'All']}
            style={{ backgroundColor: 'black' }}
            textStyle={{ fontWeight: 'bold' }}
            containerStyle={{ height: 30 }}
          />
        )}
        {missions.length === 0 && (
          <Text style={{ textAlign: 'center' }}>
            You have no {global.appSettings.labels.mission.plural}
          </Text>
        )}
        {missions.map((mission) => (
          <View
            key={mission.id}
            onClick={() => {
              setDisplayMission(mission.id);
            }}
          >
            <View style={{ flexDirection: 'row', marginLeft: 4 }}>
              {/* <View style={{ width: 30 }}> */}
              {/*  {mission.isCircleOnly */}
              {/*    ? <Icon name="users" size={20} marginRight={2} color="black" type="font-awesome-5"/> */}
              {/*    : <Icon name="user" size={20} marginRight={2} color="black" type="font-awesome-5"/> */}
              {/*  } */}
              {/* </View> */}
              <Grid
                display={'inline-flex'}
                style={{ marginLeft: '2%', width: '100%', padding: '1%' }}
              >
                <Box
                  display={'block'}
                  style={{ width: 300, marginRight: '5%' }}
                >
                  <Text>{mission.name}</Text>
                </Box>
                <Box
                  display={'block'}
                  style={{ width: 250, marginRight: '5%' }}
                >
                  <Text>
                    {formattedMonthDay(mission.startDate)} -{' '}
                    {formattedMonthDay(mission.endDate)}
                  </Text>
                </Box>
                <Box display={'block'} style={{ padding: 0 }}>
                  <Button
                    style={{ paddingTop: 0 }}
                    onClick={() => {
                      setDisplayMission(mission.id);
                      setShowMenu(true);
                    }}
                  >
                    {' '}
                    Edit Campaign
                  </Button>
                </Box>
              </Grid>
            </View>
          </View>
        ))}

        {displayMission !== '' && showMenu && (
          <Box style={{ width: '100%', height: '100%', padding: 0 }}>
            <View
              style={{
                flex: 0,
                width: '100%',
                height: '100%',
                backgroundColor:
                  global.appSettings.colors.globalBackground.backgroundColor,
              }}
            >
              <View
                style={{
                  backgroundColor: 'white',
                  height: '100%',
                  paddingLeft: '4%',
                  paddingRight: '4%',
                }}
              >
                <MissionCard
                  hideTheMenu={() => {
                    setShowMenu(false);
                  }}
                  showTheMenu={() => {
                    setShowMenu(true);
                  }}
                  missionID={displayMission}
                  navigation={navigation}
                  fetch={() => {
                    setDisplayMission('');
                    fetchMissions(missionFilter);
                  }}
                />
              </View>
            </View>
          </Box>
        )}
      </View>
    </View>
  );
}
