import React from 'react';
import CommentType from '../_types/CommentType';
import { ViewContainer } from './Comment.styles';
import UserProfilePicture from '../../../components/UserProfilePicture';
import Header from '../FeedItem/contents/Header';
import Details from '../FeedItem/contents/Details';
import Media from '../FeedItem/contents/Media';
import Actions from '../FeedItem/contents/Actions';
import { useNavigate } from 'react-router';
import Me from '../../../_types/Me';
import EditDeleteEllipseComment from './EditDeleteEllipseComment';
import MyPerson from '../_types/MyPerson';

interface Props {
  comment: CommentType;
  onDeleteComment?: (deletedComment: CommentType) => void;
  me?: Me;
  myPersons: MyPerson[];
  onChangeIsUpdating: (newIsUpdating: boolean) => void;
}

const CommentView = ({
  comment,
  onDeleteComment,
  me,
  myPersons,
  onChangeIsUpdating,
}: Props) => {
  const navigate = useNavigate();
  return (
    <ViewContainer>
      <div
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/profile/${comment.userID}`);
        }}
      >
        <UserProfilePicture imageName={comment.user.imageName} />
      </div>
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/profile/${comment.userID}`);
            }}
          >
            <Header
              shortName={comment.user.name.split(' ')[0]}
              fullName={comment.user.name}
              createdAt={comment.createdAt}
            />
          </div>

          {!!me && !!onDeleteComment ? (
            <EditDeleteEllipseComment
              onDeleteComment={onDeleteComment}
              me={me}
              comment={comment}
              onChangeIsUpdating={onChangeIsUpdating}
            />
          ) : null}
        </div>
        <Details myPersons={myPersons} details={comment.content} />
        <Media imageName={comment.imageName} imageType={comment.imageType} />
        <Actions
          totalComment={comment.comments.items.length}
          onNavigateToComments={() => {
            navigate('/feed/comments', {
              state: {
                activityFeedID: comment.activityFeed.id,
                comments: comment.comments.items,
                title: `Replies on ${comment.user.name}'s comment`,
                selectedComment: comment,
              },
            });
          }}
          activityFeedID={comment.activityFeed.id}
          emoticonList={comment.emoticons.items}
          commentID={comment.id}
        />
        {/* {!feedItem.activityFeed.details.includes('[object Object]') &&
          feedItem.activityFeed.details !== '' && (
            <div style={{ marginTop: 10 }}>{feedItem.activityFeed.details}</div>
          )} */}
      </div>
    </ViewContainer>
  );
};

export default CommentView;
