import { API } from 'aws-amplify';
import gql from 'graphql-tag';

let fetchPromise = null;

export default async function fetchCirclesOrPeople(
  ourSearchString,
  allOption,
  pickUsers,
  pickCircles,
  useCirclesList,
  useChildCirclesList,
  hideCircleLevels,
  userID,
  allowAllCircle,
  checkCreatePermissions,
  showAll,
  skipCirclesUserBelongsTo,
  hideAssigned,
  //   value,
  //   noAutoSelect,
  //   fieldSetter,
  allowNone
  //   itemValue,
  //   searchString,
) {
  //   console.log('fetchCircles: ', ourSearchString);
  if (fetchPromise !== null) {
    // console.log("Cancelling Request");
    try {
      API.cancel(fetchPromise, 'New Request');
    } catch (err) {
      console.warn('Cancel Error: ', err);
    }
    fetchPromise = null;
  }

  let filteredAssigneeList = [];
  if (pickCircles) {
    let filter = '{';
    filter += 'name: {wildcard: $searchString},';
    filter += 'orgID: {eq: $orgID},';
    filter += ' isDeleted: {eq: false}';
    if (hideCircleLevels !== undefined) {
      for (const circleLevelID of hideCircleLevels) {
        filter += ', levelID: {ne: "' + circleLevelID + '"}';
      }
    }

    if (!global.me.isAdmin && (useCirclesList || useChildCirclesList)) {
      //
      //  If the user is looking for all deals and they're NOT an admin, filter so that they see only the deals
      //  for the teams that they're in
      //
      filter += ', or: [';
      let first = true;

      if (useCirclesList) {
        for (const circleID of global.me.myCircles) {
          if (!first) {
            filter += ',';
          }
          filter += '{id: {eq: "' + circleID + '"}}';
          first = false;
        }
      }

      if (useChildCirclesList) {
        //
        //  Include all the circles under the user
        //
        for (const circleID of global.me.myChildCircles) {
          if (circleID !== global.me.leagueID) {
            //
            //  Allow the user to see all leads for any of their teams EXCEPT for their
            //  league.  This allows us to group people into leagues without allowing everyone
            //  in the same league to see everyone else's leads
            //
            if (!first) {
              filter += ',';
            }
            filter += '{id: {eq: "' + circleID + '"}}';
            first = false;
          }
        }
      }
      filter += ']';
    }
    filter += '}';

    let dealOwnersNextToken = null;

    // console.log('SelectCircle filter:', filter);
    const myQuery = gql`
        query MyQuery($orgID: ID!, $searchString: String!, $userID: ID!, $nextToken: String) {
          searchCircles(
            filter: ${filter},
            limit: 50,
            sort: { direction: asc, field: name },
            nextToken: $nextToken)
            {
            items {
              id
              name
              users(filter: {id: {eq: $userID}}) {
                items {
                  id
                  userID
                  status
                  memberType
                }
              }
            }
            nextToken
          }
        }`;

    do {
      const mySearchString = ourSearchString + '*';

      fetchPromise = API.graphql({
        query: myQuery,
        variables: {
          orgID: global.me.orgID,
          searchString: mySearchString,
          userID: global.me.id,
          nextToken: dealOwnersNextToken,
        },
      });
      const circleData = await fetchPromise;

      // console.log('---circleData: ', circleData);
      const circles = circleData.data.searchCircles.items;
      // console.log('---circles: ', circles);

      if (circleData.data.searchCircles.nextToken !== null) {
        // console.log(
        //   'pickCircles token check dealOwnersNextToken before assignment: ',
        //   circleData.data.searchCircles.nextToken
        // );
        dealOwnersNextToken =
          circleData.data.searchCircles.nextToken.toString();
      } else {
        dealOwnersNextToken = null;
      }

      const assigneeList = [];

      if (userID === undefined) {
        // console.log ("setting userID to props");
        userID = global.me.id;
      }

      for (const circle of circles) {
        // console.log ("circle.name", circle.name);
        //   console.log('circle.id: ', circle.id);
        if (circle.id === global.me.orgID && !allowAllCircle) {
          continue;
        }
        // const inheritedCircleAdmin = true;
        const inheritedCircleAdmin = global.me.myChildCircles.includes(
          circle.id
        );
        // console.log ("inheritedCircleAdmin: ", inheritedCircleAdmin?"True":"False");
        let userHasRecord = false;
        // console.log ("global.me.myChildCircles: ", global.me.myChildCircles);
        //
        //  The user is approved if they're the admin, an inherited admin, or we're not checking permissions
        //
        let userApproved =
          global.me.isAdmin || inheritedCircleAdmin || !checkCreatePermissions;

        for (const user of circle.users.items) {
          //
          //  If the user ia attached to this circle, the user is an admin, or the user inherits admin to this cicle,
          //  by default we're approved
          //
          if (
            user.userID === userID ||
            global.me.isAdmin ||
            inheritedCircleAdmin
          ) {
            if (user.status === 'approved' || user.status === 'accepted') {
              userApproved = true;
              if (
                checkCreatePermissions &&
                user.memberType === 'member' &&
                !global.me.isAdmin &&
                !inheritedCircleAdmin
              ) {
                //
                // Members cannot create stuff using this circle, so do not approve them
                //
                console.log('User rejected');
                userApproved = false;
                break;
              }
            }
            if (user.userID === userID) {
              userHasRecord = true;
            }
          }
          if (showAll) {
            userApproved = true;
          }
          // console.log('checkCreatePermissions: ', checkCreatePermissions);
          // console.log('user.memberType: ', user.memberType);
        }
        //   console.log('user approved:', userApproved);
        //   console.log('user has record:', userHasRecord);
        if (userApproved && skipCirclesUserBelongsTo === undefined) {
          console.log('case 1');
          assigneeList[assigneeList.length] = {
            name: circle.name,
            id: circle.id,
            value: circle.id,
            label: circle.name,
            type: 'circle',
          };
        }
        if (userApproved && skipCirclesUserBelongsTo !== undefined) {
          console.log('case 2');
          if (!userHasRecord) {
            assigneeList[assigneeList.length] = {
              name: circle.name,
              id: circle.id,
              value: circle.id,
              label: circle.name,
              type: 'circle',
            };
          }
        }
      }
      // console.log('!!!assigneeList: ', assigneeList);
      // console.log('!!!hideAssigned: ', hideAssigned);
      // for (const assignee of assigneeList) {
      //   console.log('!!assignee: ', assignee, itemValue);
      //   if (assignee.value === itemValue) {
      //     setSelectedName(assignee.label);
      //   }
      // }

      filteredAssigneeList = [...filteredAssigneeList, ...assigneeList];
      // filteredAssigneeList = assigneeList;
      if (hideAssigned !== undefined) {
        filteredAssigneeList = [];

        for (const item of assigneeList) {
          if (!hideAssigned.includes(item.id)) {
            filteredAssigneeList[filteredAssigneeList.length] = item;
          }
        }
      }
      // console.log('!!!filteredAssigneeList: ', filteredAssigneeList);

      // for (let item of filteredAssigneeList) {
      //   console.log('**item ', item);
      //   if (item.id === value) {
      //     if (searchString !== item.name) {
      //       console.log('setting name');
      //       setSearchString(item.name);
      //     }
      //   }
      //   if (
      //     filteredAssigneeList.length === 1 &&
      //     searchString !== item.name &&
      //     !noAutoSelect
      //   ) {
      //     //
      //     //  If there is only one circle in our list, select it by default
      //     //
      //     setSearchString(item.name);
      //     // fieldSetter(item.id, item.name, searchString);
      //   }
      // }
    } while (dealOwnersNextToken);
  }

  if (pickUsers) {
    // console.log('!!!pickUsers!!!!');

    let dealOwnersNextToken = null;

    const myPersonQuery = gql`
      query MyQuery($orgID: ID!, $searchString: String!, $nextToken: String) {
        searchUsers(
          filter: { name: { wildcard: $searchString }, orgID: { eq: $orgID } }
          limit: 999
          sort: { direction: asc, field: name }
          nextToken: $nextToken
        ) {
          items {
            id
            name
            status
          }
          nextToken
        }
      }
    `;
    do {
      const mySearchString = ourSearchString + '*';
      fetchPromise = await API.graphql({
        query: myPersonQuery,
        variables: {
          orgID: global.me.orgID,
          searchString: mySearchString,
          nextToken: dealOwnersNextToken,
        },
      });
      const userData = await fetchPromise;

      // console.log('userData: ', userData);
      const users = userData.data.searchUsers.items;

      if (userData.data.searchUsers.nextToken !== null) {
        // console.log(
        //   'pickUsers token check dealOwnersNextToken before assignment: ',
        //   userData.data.searchUsers.nextToken
        // );
        dealOwnersNextToken = userData.data.searchUsers.nextToken.toString();
      } else {
        dealOwnersNextToken = null;
      }

      const personAssigneeList = [];
      for (const user of users) {
        //   console.log('!!!user: ', user);
        if (user.status === 'active') {
          personAssigneeList[personAssigneeList.length] = {
            value: user.id,
            id: user.id,
            label: user.name,
            name: user.name,
            type: 'user',
          };
        }
      }
      // console.log('***personAssigneeList: ', personAssigneeList);
      // console.log('***hideAssigned: ', hideAssigned);
      // for (const assignee of personAssigneeList) {
      //   console.log('!!assignee: ', assignee, itemValue);
      //   if (assignee.value === itemValue) {
      //     console.log('!!!found: ', assignee.label);
      //     setSelectedName(assignee.label);
      //   }
      // }
      if (hideAssigned !== undefined) {
        for (const item of personAssigneeList) {
          if (!hideAssigned.includes(item.id)) {
            filteredAssigneeList[filteredAssigneeList.length] = item;
          }
        }
      } else {
        for (const item of personAssigneeList) {
          filteredAssigneeList[filteredAssigneeList.length] = item;
        }
      }
    } while (dealOwnersNextToken);
  }
  //   console.log('***filteredAssigneeList: ', filteredAssigneeList);

  let sortedAssigneeList = filteredAssigneeList;
  if (pickUsers && pickCircles) {
    //
    //  sort the combined list
    //
    sortedAssigneeList = filteredAssigneeList.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      return 0;
    });
  }
  if (allOption) {
    sortedAssigneeList.unshift({
      label: 'All ' + global.appSettings.labels.circle.plural,
      value: 'all',
      name: 'All ' + global.appSettings.labels.circle.plural,
      id: 'all',
    });
  }
  if (allowNone) {
    sortedAssigneeList.unshift({
      label: 'None',
      value: '',
      name: 'None',
      id: '',
    });
  }

  //   setItemList(sortedAssigneeList);
  //   setGoodToGo(true);
  return sortedAssigneeList;
}
