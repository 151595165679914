import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
// import StyledTable from '../../components/StyledTable';
// import StyledButton from '../../components/StyledButton';
// import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import './Index.css';
import MissionListRepository from '../../repository/MissionListRepository';
import MyMissionsScreen from './MyMissionsScreen';

// const columns = [
//   { field: 'name', headerName: 'Name', width: 500 },
//   { field: 'startDate', headerName: 'Start Date', width: 200, type: 'date' },
//   { field: 'endDate', headerName: 'End Date', width: 200, type: 'date' },
//   { field: 'edit', headerName: '', width: 140 }
// ];

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      missionList: [],
    };
  }

  componentDidMount() {
    MissionListRepository.fetchMissions(0).then((response) => {
      this.setState({ missionList: response });
    });
  }

  render() {
    return (
      <Box>
        <Grid container justifyContent="center">
          <Grid
            className={'campaign-title-overlay'}
            container
            justifyContent="center"
          >
            <Typography
              className={'title-text'}
              variant="h4"
              style={{ marginBottom: '10%' }}
            >
              Campaigns
            </Typography>
          </Grid>
          {/* <Grid container sx={{ mt: 4 }} justifyContent="center"> */}
          {/*  <StyledTable style={{ borderRadius: 30 }} columns={columns} rows={this.state.missionList} onRowClick={(e) => { */}
          {/*    window.location.href = `/campaigns/new?missionID=${e.id}`; */}
          {/*  }} */}
          {/*               topRight={<StyledButton onClick={() => { window.location.href = '/campaigns/new'; }} startIcon={<AddBoxOutlinedIcon/>} >New</StyledButton>}/> */}
          {/* </Grid> */}
        </Grid>
        <MyMissionsScreen />
      </Box>
    );
  }
}
