import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';

export const CustomTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    backgroundColor: '#f3f3f3',
    borderRadius: '0px', // Ensure no rounded borders
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      padding: '0px',
      borderColor: '#FF6A00',
      borderRadius: '0px !important', // Ensure no rounded borders
    },
    '&:hover fieldset': {
      borderColor: '#FF6A00',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FF6A00',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#FF6A00',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#FF6A00',
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  border: '1px solid lightgray',
  padding: '8px 10px',
  minWidth: '150px',
  borderRadius: '6px',
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const CustomSelect = styled(Select)(() => ({
  '& .MuiSelect-select': {
    backgroundColor: '#f3f3f3',
    borderRadius: '0px !important', // Ensure no rounded borders
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FF6A00 !important',
      borderRadius: '0px !important', // Ensure no rounded borders
    },
    '&:hover fieldset': {
      borderColor: '#FF6A00 !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FF6A00 !important',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#FF6A00 !important',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#FF6A00 !important',
  },
}));
