import React from 'react';
import ActionsView from './Actions.view';
import Emoticon from '../../../_types/Emoticon';

interface Props {
  activityFeedID: string;
  emoticonList: Emoticon[];
  totalComment: number;
  onNavigateToComments: () => void;
  commentID?: string;
}

const ActionsContainer = ({
  emoticonList,
  activityFeedID,
  totalComment,
  onNavigateToComments,
  commentID,
}: Props) => {
  return (
    <ActionsView
      onNavigateToComments={onNavigateToComments}
      totalComment={totalComment}
      emoticonList={emoticonList}
      activityFeedID={activityFeedID}
      commentID={commentID}
    />
  );
};

export default ActionsContainer;
