import React, { useEffect, useState } from 'react';
import AddContestantsView from './AddContestants.view';
import NewGameState from '../../_types/NewGameState';
import { useGetUserInformationQuery } from '../../../../../store/api/GraphQlQuery';
import getContestants from '../../../games-helpers/getContestants';
import { LOG } from '../../../../../common/Utilities';
import Contestant from '../../../_types/Contestant';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const AddContestantsContainer: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
}) => {
  const { data: userData, isLoading } = useGetUserInformationQuery({});
  const [contestants, setContestants] = useState<Contestant[]>([]);

  useEffect(() => {
    const fetchContestants = async () => {
      if (isLoading || !userData) {
        return;
      }

      try {
        const contestants = await getContestants({
          newGameState,
          me: userData.me,
        });

        setContestants(contestants);
      } catch (error) {
        LOG.error(error);
      }
    };

    fetchContestants();
  }, [newGameState.scope, isLoading]);

  if (isLoading || !userData) {
    return null;
  }

  return (
    <AddContestantsView
      contestants={contestants}
      newGameState={newGameState}
      onChangeNewGameState={onChangeNewGameState}
    />
  );
};

export default AddContestantsContainer;
