import React from 'react';
import CommentsActionView from './CommentsAction.view';
interface Props {
  totalComment: number;
  onNavigateToComments: () => void;
}

const CommentsActionContainer = ({
  totalComment,
  onNavigateToComments,
}: Props) => {
  return (
    <CommentsActionView
      totalComment={totalComment}
      onNavigateToComments={onNavigateToComments}
    />
  );
};

export default CommentsActionContainer;
