import React from 'react';
import CustomSelectRewards from './CustomSelectRewards';
import RewardType from '../../../../../_types/RewardType';
import RoundState from '../../../../../_types/RoundState';
import ItemSelectInput from '../../../../../custom-inputs/ItemSelectInput';
import CustomTextInput from '../../../../../custom-inputs/CustomTextInput';
import NewGameState from '../../../../../_types/NewGameState';

interface Props {
  rewards: RewardType[];
  roundState: RoundState;
  onChangeRoundState: (roundState: RoundState) => void;
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  tabNo: number;
}

const HitQuotaAndPlacedInputs: React.FC<Props> = ({
  rewards,
  roundState,
  onChangeRoundState,
  newGameState,
  onChangeNewGameState,
  tabNo,
}) => {
  if (roundState.rewardWhen !== 'mission_min') {
    return null;
  }

  if (roundState.rewardCurrency === 'points') {
    return (
      <>
        <CustomTextInput
          label="1st Place Coins"
          value={roundState.rewardCount1}
          onChange={(newValue) => {
            onChangeRoundState({ ...roundState, rewardCount1: newValue });
          }}
        />
        <CustomTextInput
          label="2nd Place Coins"
          value={roundState.rewardCount2}
          onChange={(newValue) => {
            onChangeRoundState({ ...roundState, rewardCount2: newValue });
          }}
        />
        <CustomTextInput
          label="3rd Place Coins"
          value={roundState.rewardCount3}
          onChange={(newValue) => {
            onChangeRoundState({ ...roundState, rewardCount3: newValue });
          }}
        />
        <CustomTextInput
          label="Participant Coins"
          value={roundState.rewardCountP}
          onChange={(newValue) => {
            onChangeRoundState({ ...roundState, rewardCountP: newValue });
          }}
        />
      </>
    );
  }

  return (
    <>
      <ItemSelectInput
        label="1st Place Reward (Coins)"
        selectedItem={
          roundState.showCustomReward1 ? 'custom' : roundState.rewardID1
        }
        onSelect={(newSelectedItem: { label: string; value: any }) => {
          if (newSelectedItem.value === 'custom') {
            onChangeRoundState({
              ...roundState,
              rewardID1: '',
              rewardName1: '',
              showCustomReward1: true,
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardID1 = '';
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName1 = '';
              newGameStateCopy.bracket.roundValues[
                tabNo - 1
              ].showCustomReward1 = true;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                rewardID1: '',
                rewardName1: '',
                showCustomReward1: true,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          } else {
            onChangeRoundState({
              ...roundState,
              rewardID1: newSelectedItem.value,
              rewardName1: newSelectedItem.label,
              showCustomReward1: false,
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardID1 =
                newSelectedItem.value;
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName1 =
                newSelectedItem.label;
              newGameStateCopy.bracket.roundValues[
                tabNo - 1
              ].showCustomReward1 = false;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                rewardID1: newSelectedItem.value,
                rewardName1: newSelectedItem.label,
                showCustomReward1: false,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          }
        }}
        items={[
          { label: '10', value: 10 },
          { label: '25', value: 25 },
          { label: '50', value: 50 },
          { label: 'Custom', value: 'custom' },
        ]}
        numColumns={4}
      />
      {roundState.showCustomReward1 && (
        <CustomTextInput
          label="Custom Goal"
          placeholder="E.g. 15"
          value={roundState.rewardID1}
          onChange={(newValue) => {
            onChangeRoundState({
              ...roundState,
              rewardID1: newValue,
              rewardName1: 'Custom Goal',
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardID1 =
                newValue;
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName1 =
                'Custom Goal';
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                rewardID1: newValue,
                rewardName1: 'Custom Goal',
              });
            }
            onChangeNewGameState(newGameStateCopy);
          }}
        />
      )}
      <ItemSelectInput
        label="2nd Place Reward ( Coins)"
        selectedItem={
          roundState.showCustomReward2 ? 'custom' : roundState.rewardID2
        }
        onSelect={(newSelectedItem: { label: string; value: any }) => {
          if (newSelectedItem.value === 'custom') {
            onChangeRoundState({
              ...roundState,
              rewardID2: '',
              rewardName2: '',
              showCustomReward2: true,
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardID2 = '';
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName2 = '';
              newGameStateCopy.bracket.roundValues[
                tabNo - 1
              ].showCustomReward2 = true;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                rewardID2: '',
                rewardName2: '',
                showCustomReward2: true,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          } else {
            onChangeRoundState({
              ...roundState,
              rewardID2: newSelectedItem.value,
              rewardName2: newSelectedItem.label,
              showCustomReward2: false,
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardID2 =
                newSelectedItem.value;
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName2 =
                newSelectedItem.label;
              newGameStateCopy.bracket.roundValues[
                tabNo - 1
              ].showCustomReward2 = false;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                rewardID2: newSelectedItem.value,
                rewardName2: newSelectedItem.label,
                showCustomReward2: false,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          }
        }}
        items={[
          { label: '10', value: 10 },
          { label: '25', value: 25 },
          { label: '50', value: 50 },
          { label: 'Custom', value: 'custom' },
        ]}
        numColumns={4}
      />
      {roundState.showCustomReward2 && (
        <CustomTextInput
          label="Custom Goal"
          placeholder="E.g. 15"
          value={roundState.rewardID2}
          onChange={(newValue) => {
            onChangeRoundState({
              ...roundState,
              rewardID2: newValue,
              rewardName2: 'Custom Goal',
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardID2 =
                newValue;
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName2 =
                'Custom Goal';
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                rewardID2: newValue,
                rewardName2: 'Custom Goal',
              });
            }
            onChangeNewGameState(newGameStateCopy);
          }}
        />
      )}
      <ItemSelectInput
        label="Participant Reward"
        selectedItem={
          roundState.showCustomReward3 ? 'custom' : roundState.rewardID3
        }
        onSelect={(newSelectedItem: { label: string; value: any }) => {
          if (newSelectedItem.value === 'custom') {
            onChangeRoundState({
              ...roundState,
              rewardID3: '',
              rewardName3: '',
              showCustomReward3: true,
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardID3 = '';
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName3 = '';
              newGameStateCopy.bracket.roundValues[
                tabNo - 1
              ].showCustomReward3 = true;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                rewardID3: '',
                rewardName3: '',
                showCustomReward3: true,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          } else {
            onChangeRoundState({
              ...roundState,
              rewardID3: newSelectedItem.value,
              rewardName3: newSelectedItem.label,
              showCustomReward3: false,
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardID3 =
                newSelectedItem.value;
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName3 =
                newSelectedItem.label;
              newGameStateCopy.bracket.roundValues[
                tabNo - 1
              ].showCustomReward3 = false;
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                rewardID3: newSelectedItem.value,
                rewardName3: newSelectedItem.label,
                showCustomReward3: false,
              });
            }
            onChangeNewGameState(newGameStateCopy);
          }
        }}
        items={[
          { label: '10', value: 10 },
          { label: '25', value: 25 },
          { label: '50', value: 50 },
          { label: 'Custom', value: 'custom' },
        ]}
        numColumns={4}
      />
      {roundState.showCustomReward3 && (
        <CustomTextInput
          label="Custom Goal"
          placeholder="E.g. 15"
          value={roundState.rewardID3}
          onChange={(newValue) => {
            onChangeRoundState({
              ...roundState,
              rewardID3: newValue,
              rewardName3: 'Custom Goal',
            });

            const newGameStateCopy = { ...newGameState };
            if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardID3 =
                newValue;
              newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName3 =
                'Custom Goal';
            } else {
              newGameStateCopy.bracket.roundValues.push({
                ...roundState,
                rewardID3: newValue,
                rewardName3: 'Custom Goal',
              });
            }
            onChangeNewGameState(newGameStateCopy);
          }}
        />
      )}
      <CustomSelectRewards
        label="If Tied (Multiple people/teams have the same result)"
        value={roundState.rewardName1t}
        onSelect={(reward: RewardType) => {
          onChangeRoundState({
            ...roundState,
            rewardID1t: reward.id,
            rewardName1t: reward.name,
            showCustomReward1: false,
          });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardID1t =
              reward.id;
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardName1t =
              reward.name;
            newGameStateCopy.bracket.roundValues[tabNo - 1].showCustomReward1 =
              false;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              rewardID1t: reward.id,
              rewardName1t: reward.name,
              showCustomReward1: false,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
        items={rewards}
      />
    </>
  );
};

export default HitQuotaAndPlacedInputs;
