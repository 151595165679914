import { Amplify, Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';

const awsCloudLogicCustom = (endpoint) => {
  return [
    {
      name: 'lyferizeAPI',
      endpoint,
      region: 'us-west-2',
    },
  ];
};

const common = {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  aws_appsync_region: 'us-west-2',
  aws_user_files_s3_bucket_region: 'us-west-2',
  graphql_headers: async () => {
    try {
      const token = (await Auth.currentSession()).idToken.jwtToken;
      return { Authorization: token };
    } catch (error) {
      console.error(error);
      return {};
    }
  },
};

// const environmentStaging = {
//   aws_cognito_identity_pool_id: 'us-west-2:90acc7cf-4271-41b8-bcdd-00881ff7c761',
//   aws_user_pools_id: 'us-west-2_3pue2cknE',
//   aws_user_pools_web_client_id: '53gul4ip3ogak6ouehh9p9khbf',
//   oauth: {},
//   aws_appsync_graphqlEndpoint: 'https://3clxavzi4zeapojxjy64smctfq.appsync-api.us-west-2.amazonaws.com/graphql',
//   aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
//   aws_cloud_logic_custom: awsCloudLogicCustom('https://v6p3nsdju4.execute-api.us-west-2.amazonaws.com/staging'),
//   aws_user_files_s3_bucket: 'lyferize-user-data43325-staging'
// };

// eslint-disable-next-line no-unused-vars
const environmentProduction = {
  aws_cognito_identity_pool_id:
    'us-west-2:fa3d6ea6-e9fc-4662-ad6b-1bcc41856934',
  aws_user_pools_id: 'us-west-2_bWd9R4gG4',
  aws_user_pools_web_client_id: '2kaf7o6d3jhthc54g0dk1r3res',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://iuuaq3xqwbagpd6ey3qkxeda5a.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: awsCloudLogicCustom(
    'https://6whfuuursb.execute-api.us-west-2.amazonaws.com/prod'
  ),
  aws_user_files_s3_bucket: 'lyferize-user-data213532-prod',
};

const environment = Object.assign(common, environmentProduction);
Amplify.configure(environment);
Auth.configure();
