// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
//
// TrophyShop
//

import React, { useState, useEffect } from 'react';
import { ScrollView, Text, View } from '@aws-amplify/ui-react';
import { Alert, Button } from '@mui/material';
import { API } from 'aws-amplify';
import { Trophy } from './TrophyCase';
import gql from 'graphql-tag';

export default function TrophyShop(props) {
  // Code for retrieval of trophies for the provided user goes here
  //
  //
  const [trophyData, setTrophyData] = useState(false);

  // Below trophy data will be provided by the backend for each user as provided in function params
  async function getData() {
    const listStoreTrophies = gql`
      query MyQuery($orgID: ID!) {
        listTrophys(filter: { orgID: { eq: $orgID } }, limit: 10000) {
          items {
            id
            description
            imageName
            imageType
            place
            title
          }
        }
      }
    `;

    const res = await API.graphql({
      query: listStoreTrophies,
      variables: { orgID: global.me.orgID },
    });
    // LOG.debug ("!!!res: ", res);
    const trophies = res.data.listTrophys;
    // LOG.debug ("!!!trophies: ", trophies);
    let sortedTrophies = [];
    if (trophies) {
      sortedTrophies = trophies.items.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
    }
    setTrophyData(sortedTrophies);
  }

  useEffect(() => {
    getData();
  }, []);

  // LOG.debug ("****screenWidth: ", screenWidth);
  // LOG.debug ("****Dimensions.get('window').width: ", Dimensions.get('window').width);

  return (
    <ScrollView nestedScrollEnabled={true} keyboardShouldPersistTaps="always">
      <View styles={{ flex: 1, padding: 0 }}>
        {trophyData && (
          <React.Fragment>
            <Text
              style={{
                fontSize: 20,
                textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 4,
              }}
            >
              Touch the trophy to add
            </Text>
            <View
              style={{
                flex: 1,
                alignSelf: 'stretch',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 5,
              }}
            >
              {trophyData.map((rowData) => (
                <React.Fragment key={rowData.id}>
                  <Button
                    onPress={() => {
                      Alert.alert(
                        'Add Trophy',
                        'Are you sure you wish to add the ' +
                          trophyData.name +
                          ' trophy to this ' +
                          global.appSettings.labels.mission.singular +
                          '?',
                        [
                          {
                            text: 'Yes',
                            onPress: () => {
                              props.addTrophy(rowData);
                              props.hideTrophyShop();
                            },
                          },
                          {
                            text: 'No',
                            style: 'cancel',
                          },
                        ]
                      );
                    }}
                  >
                    <Trophy
                      id={rowData.id}
                      imageName={rowData.imageName}
                      title={rowData.title}
                      description={rowData.description}
                      createdAt=""
                    />
                  </Button>
                </React.Fragment>
              ))}
            </View>
          </React.Fragment>
        )}
      </View>
    </ScrollView>
  );
}
