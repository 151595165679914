import React, { useState } from 'react';
import { /* Backdrop, Button, */ Box, Typography } from '@mui/material';
import { CustomMenu, CustomMenuItem } from '../../common/StyledComponents';
// import { API } from 'aws-amplify';
// import * as mutations from '../../../graphql/mutations';
import { GamifyToast } from '../../common/CustomToasts';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import './LinkedTeamOptionsMenu.css';

export default function LinkedTeamOptionsMenu(props) {
  const {
    // setLinkedTeamOptionsOpen,
    anchorEl,
    setAnchorEl,
    parentTeamId,
    linkedTeam,
    setIsLinkedTeamListLoading,
    // onTriggerRefetch,
    onRemoveTeamFromState,
    onRemoveTeam,
  } = props;
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // console.log('LinkedTeamOptionMenu linkedTeam: ', linkedTeam);

  const handleRemoveClick = () => {
    setShowConfirmRemove(true);
  };

  const handleConfirmRemoveClick = async (teamId, linkedTeam) => {
    // console.log(
    //   'handleConfirmRemoveClick teamId, linkedTeam: ',
    //   teamId,
    //   linkedTeam
    // );
    setIsSaving(true);
    setIsLinkedTeamListLoading(true);

    setTimeout(() => {
      setShowConfirmRemove(false);
      // setLinkedTeamOptionsOpen(false);
      setAnchorEl(null);
    }, 500);

    // onRemoveTeam returns true if successfull, false if not
    const success = await onRemoveTeam(linkedTeam);

    // End function if onRemoveTeam fails
    if (!success) return;

    // await onTriggerRefetch();

    setTimeout(() => {
      GamifyToast.success(`${linkedTeam.name} unlinked successfully`);
      setIsLinkedTeamListLoading(false);
    }, 500);
  };

  // For removing members while creating a team before the team has an id:
  const handleConfirmRemoveClickNoTeamId = (linkedTeam) => {
    // console.log('handleConfirmRemoveClickNoTeamId linkedTeam: ', linkedTeam);
    setIsLinkedTeamListLoading(true);
    onRemoveTeamFromState(linkedTeam.id);
    GamifyToast.success(
      `${linkedTeam.name} unlinked successfully. Save changes below to ensure ${linkedTeam.name} is unlinked`
    );
    setIsLinkedTeamListLoading(false);
  };

  // const handleCancelRemoveClick = () => {
  //   setShowConfirmRemove(false);
  // };

  return (
    <>
      {/* NOTE: DynamicConfirmModal has replaced the custom Backdrop component commented out below */}
      <DynamicConfirmModal
        showDynamicConfirmModal={showConfirmRemove}
        setShowDynamicConfirmModal={setShowConfirmRemove}
        zIndex={100002}
        leftAlignText={true}
        title={`Are you sure you want to unlink the ${linkedTeam.name} team?`}
        // subtitle={`You can link this team back later.`}
        isSaving={isSaving}
        // onCancel={() => {
        // }}
        confirmButtonText={'Unlink'}
        confirmButtonColor={'#e35050'}
        onConfirm={() => {
          parentTeamId
            ? handleConfirmRemoveClick(parentTeamId, linkedTeam)
            : handleConfirmRemoveClickNoTeamId(linkedTeam);
        }}
      ></DynamicConfirmModal>
      {/* <Backdrop sx={{ zIndex: 100001 }} open={showConfirmRemove}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#ffffff',
            width: 624,
            height: 286,
            padding: '40px 40px 32px 40px',
            borderRadius: 8,
          }}
        >
          <Box className="unlink-team-confirmation-top-container">
            <Typography
              style={{
                color: '#222428',
                fontSize: 28,
                fontWeight: 700,
                lineHeight: '48px',
                letterSpacing: '-0.02em',
                marginBottom: 16,
              }}
            >
              Are you sure you want to unlink this team?
            </Typography>
            <Typography
              style={{
                color: '#868686',
                fontSize: 16,
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '0.01em',
              }}
            >
              {`Team to unlink: ${linkedTeam.name}`}
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              className={'cancel-unlink-team-button'}
              onClick={() => handleCancelRemoveClick()}
              disableRipple={true}
            >
              <Typography
                style={{
                  color: '#323232',
                  fontWeight: 700,
                }}
              >
                Cancel
              </Typography>
            </Button>
            <Button
              className={'confirm-unlink-team-button'}
              onClick={() => {
                parentTeamId
                  ? handleConfirmRemoveClick(parentTeamId, linkedTeam)
                  : handleConfirmRemoveClickNoTeamId(linkedTeam);
              }}
              disableRipple={true}
            >
              <Typography
                style={{
                  color: 'white',
                  fontWeight: 700,
                }}
              >
                Remove
              </Typography>
            </Button>
          </Box>
        </Box>
      </Backdrop> */}

      {/* NOTE: CustomMenu and CustomMenuItem components have replaced the custom options menu commented out below */}
      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => {
          e.stopPropagation();
          setAnchorEl(null);
        }}
      >
        <CustomMenuItem
          sx={{
            background: 'white',
            color: 'black',
            paddingTop: '9px',
            paddingBottom: '9px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveClick();
            setAnchorEl(null);
          }}
          disableRipple={true}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              style={{
                color: '#e35050',
              }}
            >
              Remove
            </Typography>
            {/* <GamifyIcon
              icon={
                user.status === 'active' ? 'deactivateUser' : 'activateUser'
              }
              color={user.status === 'active' ? '' : 'black'}
            ></GamifyIcon> */}
          </Box>
        </CustomMenuItem>
      </CustomMenu>
      {/* <Box
        style={{
          position: 'absolute',
          zIndex: 10001,
          right: 0,
          top: 40,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: 228,
          backgroundColor: 'white',
          borderRadius: 12,
          boxShadow: '0px 4px 24px 0px #ababab5e',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button
            disableRipple={true}
            className={'option-menu-only-button'}
            onClick={() => handleRemoveClick()}
          >
            <Typography style={{ color: '#E35050' }}>
              Unlink
            </Typography>
          </Button>
        </Box>
      </Box> */}
    </>
  );
}
