import React, { useState } from 'react';
import CommentType from '../_types/CommentType';
import Me from '../../../_types/Me';

interface Props {
  me: Me;
  comment: CommentType;
  onDeleteComment: (deleteComment: CommentType) => void;
  onChangeIsUpdating: (newIsEditing: boolean) => void;
}

const EditDeleteEllipseComment = ({
  me,
  comment,
  onDeleteComment,
  onChangeIsUpdating,
}: Props) => {
  const [isEllipseClick, setIsEllipseClick] = useState(false);

  if (!me.isAdmin || comment.userID !== me.id) {
    return null;
  }

  return (
    <div style={{ position: 'relative' }}>
      <img
        onClick={(e) => {
          e.stopPropagation();

          setIsEllipseClick(!isEllipseClick);
        }}
        src="/images/three-dots.svg"
        width={30}
        height={30}
        style={{ cursor: 'pointer' }}
      />
      {isEllipseClick ? (
        <div
          style={{
            position: 'absolute',
            backgroundColor: '#f5f5f5',
            padding: '10px 20px',
            borderRadius: 10,
            right: 0,
            top: 28,
          }}
        >
          <div
            style={{
              fontSize: 14,
              fontWeight: 500,
              cursor: 'pointer',
              marginBottom: 10,
            }}
            onClick={(e) => {
              e.stopPropagation();
              onChangeIsUpdating(true);
            }}
          >
            Edit
          </div>
          <div
            style={{
              fontSize: 14,
              color: '#FF5C77',
              fontWeight: 700,
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              onDeleteComment(comment);
              setIsEllipseClick(!isEllipseClick);
            }}
          >
            Delete
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EditDeleteEllipseComment;
