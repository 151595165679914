import {
  Header,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect } from 'react';
import KPITableView from './KPITable.view';
import { GetKPIValuesArgs, KPILeaderboardState, TokensData } from '../_types';
import featureFlag from '../../../common/featureFlag';

interface KPITableContainerProps {
  columns: any;
  data: any;
  kpiLeaderboardState: KPILeaderboardState;
  tokens: TokensData;
  fetchKpiLeaderboardData: (fetchDataArgs: GetKPIValuesArgs) => void;
  onChangeKpiLeaderboardState: (
    kpiLeaderboardState: KPILeaderboardState
  ) => void;
  resetTokens: () => void;
  refreshIntervalAmount: number;
}

const KPITableContainer = ({
  columns,
  data,
  kpiLeaderboardState,
  fetchKpiLeaderboardData,
  onChangeKpiLeaderboardState,
  tokens,
  resetTokens,
  refreshIntervalAmount,
}: KPITableContainerProps) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    table.setPageSize(kpiLeaderboardState.limit);
  }, [kpiLeaderboardState.limit]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (featureFlag('leaderboardRefreshInterval')) {
        // console.log('INTERVAL CHECK: ', refreshIntervalAmount);
        // console.log('INTERVAL CHECK isLoading: ', kpiLeaderboardState.loading);
        fetchKpiLeaderboardData({
          ...kpiLeaderboardState,
          currentPage: kpiLeaderboardState.currentPage,
          nextToken: tokens.allTokens[kpiLeaderboardState.currentPage],
          isIntervalRefresh: true,
        });
      }
    }, refreshIntervalAmount);

    return () => clearInterval(refreshInterval);
  }, [refreshIntervalAmount]);

  return (
    <KPITableView
      kpiLeaderboardState={kpiLeaderboardState}
      table={table}
      totalPages={tokens.allTokens.length}
      currentPage={kpiLeaderboardState.currentPage}
      disabledNextPage={
        kpiLeaderboardState.currentPage === tokens.allTokens.length - 1
      }
      disabledPrevPage={kpiLeaderboardState.currentPage === 0}
      onPreviousPage={() => {
        fetchKpiLeaderboardData({
          ...kpiLeaderboardState,
          currentPage: kpiLeaderboardState.currentPage - 1,
          nextToken: tokens.allTokens[kpiLeaderboardState.currentPage - 1],
          isIntervalRefresh: false,
        });
      }}
      onNextPage={() => {
        fetchKpiLeaderboardData({
          ...kpiLeaderboardState,
          currentPage: kpiLeaderboardState.currentPage + 1,
          nextToken: tokens.allTokens[kpiLeaderboardState.currentPage + 1],
          isIntervalRefresh: false,
        });
      }}
      onSelectPage={(page) => {
        if (page === kpiLeaderboardState.currentPage) {
          return;
        }

        fetchKpiLeaderboardData({
          ...kpiLeaderboardState,
          currentPage: page,
          nextToken: tokens.allTokens[page],
          isIntervalRefresh: false,
        });
      }}
      onSort={(header: Header<any, any>) => {
        onChangeKpiLeaderboardState({
          ...kpiLeaderboardState,
          sortColumn: header.getContext().header.id,
          sortOrder: kpiLeaderboardState.sortOrder === 'asc' ? 'desc' : 'asc',
          currentPage: 0,
        });
        resetTokens();
      }}
    />
  );
};

export default KPITableContainer;
