import React from 'react';
import {
  DimensionTimeframeValues,
  DimensionTypeValues,
  KPILeaderboardState,
} from './_types';
import KPILeaderboardView from './KPILeaderboard.view';
import useGetKpiValues from './useGetKpiValues';
import { getColumns, getData } from './_kpiLeaderboardHelpers';

const KPILeaderboardContainer = () => {
  const {
    kpiLeaderboardState,
    setKpiLeaderboardState,
    tokens,
    resetTokens,
    fetchKpiLeaderboardData,
  } = useGetKpiValues({
    loading: true,
    error: null,
    openFilter: false,
    dimensionType: DimensionTypeValues.Person,
    dimensionTimeframe: DimensionTimeframeValues.Month,
    dimensionValue: '2023-07',
    circleLevelID: '',
    circleID: '',
    tokens: [],
    limit: 25,
    sortColumn: '--first--',
    sortOrder: 'desc',
    kpiList: {},
    results: [],
    currentPage: 0,
    totalResults: 0,
    filterChangesCount: 0,
    organizations: [],
    teams: [],
  });

  const columns = getColumns({
    kpiList: kpiLeaderboardState.kpiList,
    currentSortColumn: kpiLeaderboardState.sortColumn,
    currentSortOrder: kpiLeaderboardState.sortOrder,
  });
  const data = getData({
    results: kpiLeaderboardState.results,
    kpiList: kpiLeaderboardState.kpiList,
  });

  return (
    <KPILeaderboardView
      columns={columns}
      data={data}
      tokens={tokens}
      resetTokens={resetTokens}
      fetchKpiLeaderboardData={fetchKpiLeaderboardData}
      kpiLeaderboardState={kpiLeaderboardState}
      onChangeKpiLeaderboardState={(kpiLeaderboardState: KPILeaderboardState) =>
        setKpiLeaderboardState(kpiLeaderboardState)
      }
    />
  );
};

export default KPILeaderboardContainer;
