import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Button } from '@mui/material';
import { Text, View } from '@aws-amplify/ui-react';
import { Storage } from 'aws-amplify';
import './DevComp.css';

const FileUploader = (props) => {
  const { setter, file, parsedSetter } = props;

  const onDrop = (droppedFile) => {
    const [f] = droppedFile;
    if (f.type !== 'text/csv') {
      console.log('Bad file!');
      return false;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const contents = reader.result;
      //   console.log('CSV file contents:');
      //   console.log(contents);
      const parsedTemp = contents
        .split('\n')
        .map((el) => el.slice(0, -2))
        .map((el) => el.split(','));

      console.log(parsedTemp[0]);
      console.log(parsedTemp[1]);
      console.log(parsedTemp[2]);
      parsedSetter(parsedTemp);

      const columnNames = contents.split('\n')[0].split(',');
      console.log('Column names:', columnNames);
      // parseCSV(contents);
    };
    reader.readAsText(f);

    console.log(f);
    setter(f);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  console.log('--->>', getInputProps);

  return (
    <Box
      {...getRootProps()}
      id="drag_drop_box"
      className={isDragActive ? 'drag-active' : 'non-active-drag'}
    >
      {file ? (
        <Text>{file.name}</Text>
      ) : (
        <Text>click or drag and drop CSV file here</Text>
      )}
      {isDragActive ? <Text>Drag it!</Text> : null}
    </Box>
  );
};

export default function DevComp() {
  const [file, setFile] = useState(null);
  const [parsedFile, setParsedFile] = useState(null);
  const [displayName, setDisplayName] = useState('');

  const shortFileName = (name) => {
    const arr = name.split('.');
    const format = arr.pop();
    const cleanName = arr.join('.');
    const ans = `${name.slice(0, 10)} ... ${cleanName.slice(
      cleanName.length - 5
    )}.${format}`;
    console.log(ans);
    return ans;
  };

  useEffect(() => {
    if (!file) return;
    let name = file.name.split('.');
    name.pop();
    name = name.join('.');
    if (name.length > 10) setDisplayName(shortFileName(file.name));
    else setDisplayName(file.name);
  }, [file]);

  const uploadIt = async () => {
    const result = {
      name: `user_uploads_csvs/${global.menubar.orgID}/${file.name}`,
      mediaTypes: file.type,
      quality: 0.2,
    };
    try {
      console.log(result);
      await Storage.put(result.name, file, {
        contentType: result.mediaTypes,
      }).catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <View style={{ marginTop: '100px', padding: '15px' }}>
      <Box id="main-section">
        <Text style={{ fontSize: '1.4rem' }}>User Upload Tool</Text>
        <FileUploader
          setter={(val) => setFile(val)}
          parsedSetter={(val) => setParsedFile(val)}
          file={file}
        />
        {displayName ? <Text>{displayName}</Text> : null}
        <Button variant="outlined" onClick={() => console.log(file)}>
          {' '}
          Click to print
        </Button>
        <Button disabled={!file} onClick={uploadIt} variant="contained">
          UPLOAD
        </Button>
        <Button variant="contained" onClick={() => console.log(parsedFile)}>
          Parsed File
        </Button>
      </Box>
      <Box>Hello</Box>
    </View>
  );
}
