import React from 'react';
import { useTranslation } from 'react-i18next';
import CenteredComponent from '../components/CenteredComponent';
import { Typography } from '@mui/material';

export default function NotFound() {
  const { t: translation } = useTranslation('', { keyPrefix: 'notFound' });
  return (
    <CenteredComponent>
      <Typography style={{ marginTop: '100px' }} variant="h4">
        {translation('pageNotFound')}
      </Typography>
    </CenteredComponent>
  );
}
