// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
// ShadowButton

import React from 'react';
import { Button } from '@aws-amplify/ui-react';
import { Box } from '@mui/material';

//
// props:

export default function ShadowButton(props) {
  return (
    <Box>
      <Box
        style={{
          shadowColor: 'black',
          shadowOffset: { width: 4, height: 4 },
          shadowOpacity: 0.25,
          shadowRadius: 4,
        }}
      >
        <Button
          className={'shadow-button'}
          width={props.width ? props.width : null}
          marginRight={props.marginRight ? props.marginRight : null}
          onClick={props.onClick}
        >
          {props.title}
        </Button>
      </Box>
    </Box>
  );
}
