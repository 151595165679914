import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Typography,
} from '@mui/material';
import { EllipsisIcon } from '../../components/GamifyIcon';
// import MemberOptionsMenu from './MemberOptionsMenu';
import LinkedTeamOptionsMenu from './LinkedTeamOptionMenu';
import fetchTeamMembers from './fetchTeamMembers';
import LinkedTeamMembersModal from './LinkedTeamMembersModal';

export default function LinkedTeamCard({
  parentTeamId,
  linkedTeam,
  setIsLinkedTeamListLoading,
  onTriggerRefetch,
  onRemoveTeamFromState,
  onRemoveTeam,
}) {
  // const [linkedTeamOptionsOpen, setLinkedTeamOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkedTeamMembers, setLinkedTeamMembers] = useState([]);
  const [hasFetchedTeamMembers, setHasFetchedTeamMembers] = useState(false);
  const [showLinkedTeamMembersModal, setShowLinkedTeamMembersModal] =
    useState(false);

  // const handleClickOffLinkedTeamOptionMenu = () => {
  //   setLinkedTeamOptionsOpen(false);
  // };

  // TO DO: get users in initial query in AMLT Modal
  useEffect(() => {
    const prepMembers = async () => {
      const teamMembers = await fetchTeamMembers(linkedTeam.id);
      setLinkedTeamMembers(teamMembers);
      setHasFetchedTeamMembers(true);
    };
    prepMembers();
  }, []);

  return (
    <>
      {/* NOTE: This background was only needed for custom built options menu, CustomMenu and CustomMenuItem components do not need this */}
      {/* {linkedTeamOptionsOpen && (
        <Box
          onClick={handleClickOffLinkedTeamOptionMenu}
          className="option-menu-container-background"
        ></Box>
      )} */}
      <LinkedTeamMembersModal
        teamMembers={linkedTeamMembers}
        teamName={linkedTeam?.name}
        setShowModal={setShowLinkedTeamMembersModal}
        showModal={showLinkedTeamMembersModal}
      ></LinkedTeamMembersModal>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'white',
          padding: 15,
          borderRadius: 8,
          marginBottom: 8,
          cursor: 'pointer',
        }}
        onClick={() => {
          setShowLinkedTeamMembersModal(true);
        }}
      >
        <Box className={'linked-team-list-item-left-container'}>
          {/* Display linked team avatar if there is one, otherwise display initials */}
          {/* Account for image type to account for potential gifs */}
          {linkedTeam.avatarFile ? (
            linkedTeam.imageType === 'picture' ||
            linkedTeam.imageType.split('/')[0] === 'image' ? (
              <Avatar
                src={linkedTeam.avatarFile}
                sx={{ margin: '5px 12px 5px 5px' }}
              />
            ) : (
              <CardMedia
                image={linkedTeam.imageName}
                component="img"
                sx={{
                  backgroundColor: 'black',
                  borderRadius: 1000,
                  height: 40,
                  width: 40,
                  objectFit: 'contain',
                  margin: '5px 12px 5px 5px',
                }}
              />
            )
          ) : (
            <Avatar
              sx={{ backgroundColor: '#868686', margin: '5px 12px 5px 5px' }}
            >
              {linkedTeam.name[0]}
            </Avatar>
          )}
          <Box className={'linked-team-list-item-left-text-container'}>
            <Box style={{ display: 'flex' }}>
              <Typography
                style={{ color: '#222428', fontSize: 16, fontWeight: 600 }}
              >
                {linkedTeam.name}
              </Typography>
            </Box>
            {hasFetchedTeamMembers ? (
              <Typography
                style={{ color: '#868686', fontSize: 14, fontWeight: 500 }}
              >
                {`${linkedTeamMembers.length} Members`}
              </Typography>
            ) : (
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress
                  style={{ color: '#868686', width: 12, height: 12 }}
                ></CircularProgress>
                <Typography
                  style={{
                    color: '#868686',
                    fontSize: 14,
                    fontWeight: 500,
                    marginLeft: 4,
                  }}
                >
                  {`Members`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          style={{
            position: 'relative',
            height: 45,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            className={'linked-team-option-button'}
            onClick={(e) => {
              e.stopPropagation();
              // setLinkedTeamOptionsOpen(!linkedTeamOptionsOpen);
              setAnchorEl(e.currentTarget);
            }}
            disableRipple={true}
          >
            <EllipsisIcon color={'#868686'}></EllipsisIcon>
          </Button>
          {/* TO DO: limit access to LinkedTeamOptionsMenu to admin/moderator/manager? */}
          <LinkedTeamOptionsMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            parentTeamId={parentTeamId}
            linkedTeam={linkedTeam}
            setIsLinkedTeamListLoading={setIsLinkedTeamListLoading}
            onTriggerRefetch={onTriggerRefetch}
            onRemoveTeamFromState={onRemoveTeamFromState}
            onRemoveTeam={onRemoveTeam}
          ></LinkedTeamOptionsMenu>
          {/* {linkedTeamOptionsOpen && (
            <LinkedTeamOptionsMenu
              setLinkedTeamOptionsOpen={setLinkedTeamOptionsOpen}
              parentTeamId={parentTeamId}
              linkedTeam={linkedTeam}
              setIsLinkedTeamListLoading={setIsLinkedTeamListLoading}
              onTriggerRefetch={onTriggerRefetch}
              onRemoveTeamFromState={onRemoveTeamFromState}
              onRemoveTeam={onRemoveTeam}
            ></LinkedTeamOptionsMenu>
          )} */}
        </Box>
      </Box>
    </>
  );
}
