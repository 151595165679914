import React from 'react';
import useGames from './hooks/useGames';
import GamesView from './Games.view';
import { useGetUserInformationQuery } from '../../store/api/GraphQlQuery';

const GamesContainer = () => {
  const { data, isLoading: isLoadingGetUserInformation } =
    useGetUserInformationQuery({});
  const { gamesState, onChangeGamesState } = useGames({
    error: null,
    loading: true,
    gameDimensionType: 'Individual',
    games: [],
    showCompleted: false,
    currentGameIndex: 0,
  });
  const showAdd = Boolean(
    !isLoadingGetUserInformation && data && data.me.canCreateMissions
  );

  return (
    <GamesView
      showAdd={showAdd}
      gamesState={gamesState}
      onChangeGamesState={onChangeGamesState}
    />
  );
};

export default GamesContainer;
