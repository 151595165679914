import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router';
import { Box, Typography } from '@mui/material';

export default function PipelineScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>('/pipeline/board');

  // Need to navigate to leaderboard on initial click on the 'Performance' tab
  useEffect(() => {
    if (location.pathname === '/pipeline') {
      navigate('/pipeline/board');
      setActiveTab('/pipeline/board');
    }

    if (
      location.pathname === '/pipeline/list' &&
      activeTab !== '/pipeline/list'
    ) {
      setActiveTab('/pipeline/list');
    }
  }, [location]);

  const pipelineTabs = [
    {
      label: 'Board',
      path: '/pipeline/board',
      val: 'board',
    },
    {
      label: 'List',
      path: '/pipeline/list',
      val: 'list',
    },
  ];

  const handleNavigate = (path: string) => {
    setActiveTab(path);
    navigate(path);
  };

  return (
    <>
      <Box
        style={{
          width: '100%',
          height: 90,
          maxHeight: 90,
          backgroundColor: 'white',
          position: 'fixed',
          zIndex: 100,
          top: 90,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          borderBottom: '1px solid #F0F0F3',
        }}
      >
        <Box
          style={{
            display: 'flex',
            border: '1px solid #F0F0F3',
            borderRadius: 8,
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          {pipelineTabs.map((pipelineTab, i) => (
            <Box
              key={i}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'fit-content',
                padding: 12,
                borderRadius:
                  i === 0
                    ? '8px 0px 0px 8px'
                    : i === pipelineTabs.length - 1
                    ? '0px 8px 8px 0px'
                    : 0,
                backgroundColor: activeTab.includes(pipelineTab.val)
                  ? '#FF6A00'
                  : 'white',
              }}
              onClick={() => handleNavigate(pipelineTab.path)}
            >
              <Typography
                style={{
                  fontSize: 16,
                  color: activeTab.includes(pipelineTab.val)
                    ? 'white'
                    : '#7C7C7C',
                  fontWeight: activeTab.includes(pipelineTab.val) ? 600 : 400,
                }}
              >
                {pipelineTab.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box style={{ overflowY: 'auto' }}>
        <Outlet />
      </Box>
    </>
  );
}
