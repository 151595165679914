import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import * as utilities from '../../common/Utilities';
import {
  // Autocomplete,
  Backdrop,
  Box,
  // CircularProgress,
  Typography,
  // TextField,
} from '@mui/material';
// import { StyledSelect, StyledMenuItem } from '../teams/StyledComponent';
import { StyledSelect, StyledMenuItem } from '../../common/StyledComponents';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import { GamifyToast } from '../../common/CustomToasts';

export default function OwnerDealStageModal(props) {
  // NOTE: props that may be needed until SelectStage is redesigned: showAll, activeOption, allOption, minSequence, ignoreNoDealStages
  // NOTE: props that may be needed until NewSelectCircleOrPerson is redesigned: showAll, pickUsers, pickCircles, hideAssigned, allOption, allowNone
  const {
    deal,
    isOnKanban,
    dealStages,
    dealPeople,
    showOwnerDealStageModal,
    setShowOwnerDealStageModal,
    fetchDeals,
    handleDealStageChange,
    handleDealStageChangeRevert,
    handleRefetchDeals,
    triggerSelectedDealRefetch,
    onTriggerRefetch,
  } = props;

  // Deal stages used in this modal MUST be user selectable
  const filteredDealStages = dealStages.filter(
    (dealStage) =>
      dealStage.userSelectable !== false &&
      dealStage.label !== '- Active Only' &&
      dealStage.label !== '- All'
  );

  const filteredDealPeople = dealPeople.filter(
    (dealPerson) => dealPerson.value !== 'all'
  );

  const [isSaving, setIsSaving] = useState(false);

  const normalizedDealStages = dealStages.reduce((acc, stage) => {
    acc[stage.id] = stage;
    return acc;
  }, {});

  const [dealOwners, setDealOwners] = useState({
    dealID: deal.id,
    setterID: deal.setter.id,
    closerID: deal.closer?.id || '',
    currentStageID: deal.stageId,
    originalSetterID: deal.setter.id,
    originalCloserID: deal.closer?.id || '',
    originalStageID: deal.stageId,
  });
  // NOTE: Disposition only used with pins... not on web for now
  // const [currentDispositionID, setCurrentDispositionID] = useState(null);
  const [dynamicModalObj, setDynamicModalObj] = useState(null);
  const [showDynamicConfirmModal, setShowDynamicConfirmModal] = useState(false);
  const [showCloserRequiredModal, setShowCloserRequiredModal] = useState(false);
  const [stage, setStage] = useState({});

  const modalsConfig = {
    warning: {
      title: `Are you sure you want to change the stage of this deal?`,
      subtitle: `WARNING: The "${stage.label}" deal stage is a predecessor to the current stage.\nIf you change to this stage, KPIs and games may be impacted.`,
    },
    inform: {
      title: `Are you sure you want to change the stage of this deal?`,
      subtitle: `If you change this deal stage to "${stage.label}" you cannot change back to a previous stage.`,
    },
    noStageChange: {
      title: `You haven't changed the stage of this deal yet`,
      subtitle: `Are you sure you want to change the setter or closer without changing the stage?`,
    },
  };

  useEffect(() => {
    setDealOwners({
      dealID: deal.id,
      setterID: deal.setter.id,
      closerID: deal.closer?.id || '',
      currentStageID: deal.stageId,
      originalSetterID: deal.setter.id,
      originalCloserID: deal.closer?.id || '',
      originalStageID: deal.stageId,
    });
  }, [deal, triggerSelectedDealRefetch]);

  const handleCancelChangeClick = () => {
    // console.log('CANCEL CHANGE');
    setShowOwnerDealStageModal(false);
  };

  const createFeedItem = async (dealID, stageID, userID, onSave) => {
    // console.log('createFeedItem: ', dealID, stageID, userID);
    // global.analytics.track("CreateManualKPI");
    try {
      // const now = new Date();
      const completeMissionInput = {
        dealID,
        stageID,
        userID,
        tmst: utilities.newLocalToISODateFormat(utilities.getLocalDateTime()),
      };
      // console.log('completeMissionInput: ', completeMissionInput);
      const completeMissionRes = await API.graphql({
        query: mutations.completeMission,
        variables: {
          missionInfo: JSON.stringify(completeMissionInput),
        },
      });
      const completeMissionStatus = JSON.parse(
        completeMissionRes.data.completeMission
      );

      // console.log('completeMissionStatus: ', completeMissionStatus);
      if (completeMissionStatus.status === 'failed') {
        GamifyToast.error(
          global.appSettings.labels.mission.proper + ' save failed'
        );
        onSave(false);
      } else if (completeMissionStatus.status === 'available') {
        alert(global.appSettings.labels.mission.proper + ' Abandoned');
        console.warning(
          global.appSettings.labels.mission.proper + ' Abandoned'
        );
        // props.fetch(true);
        onSave(true);

        // NOTE: Disposition only used with pins... not on web for now
        // if (currentDispositionID !== null && currentDispositionID !== '') {
        //   props.setCurrentDispositionID(currentDispositionID);
        // }
      } else if (completeMissionStatus.status === 'success') {
        onSave(true);

        // NOTE: Disposition only used with pins... not on web for now
        // if (currentDispositionID !== null && currentDispositionID !== '') {
        //   props.setCurrentDispositionID(currentDispositionID);
        // }
      } else if (completeMissionStatus.status.startsWith('WARNING:')) {
        GamifyToast.error(completeMissionRes.data.completeMission);
        console.error(completeMissionRes.data.completeMission);
        onSave(false);
      } else {
        GamifyToast.error(
          completeMissionRes.data.completeMission +
            '.   Gamify support has been notified.'
        );
        console.error(
          'error in completeMission(): ' +
            completeMissionRes.data.completeMission
        );
        onSave(false);
      }
    } catch (err) {
      console.error('error in completeMission(): ' + err.message);
      GamifyToast.error(err);
    }
  };

  const saveDealOwners = async (dealOwners, onComplete) => {
    const ourDealOwners = JSON.parse(JSON.stringify(dealOwners));
    ourDealOwners.updatedAt = utilities.getCurrentUTCDate();
    // console.log('!!!ourDealOwners: ', ourDealOwners);

    // If this is on the kanban, there will be a handleDealStageChange function that optimistically moves the deal to the next stage
    // If maintainDealOwners has an error, need the original deal arrays for original stage and current stage to revert
    const dealId = dealOwners.dealID;
    const originalStageId = dealOwners.originalStageID;
    const currentStageId = dealOwners.currentStageID;

    let originalStageDeals = [];
    let currentStageDeals = [];
    // let originalDealsListDeals = {
    //   deals: [],
    //   currentOffset: 0,
    //   canLoadMore: false,
    //   isLoading: true,
    // };
    let success = true;
    if (isOnKanban) {
      [originalStageDeals, currentStageDeals, success] = handleDealStageChange(
        dealId,
        originalStageId,
        currentStageId
      );
    }
    // else {
    //   [originalDealsListDeals, success] = handleDealStageChange(
    //     dealId,
    //     currentStageId
    //   );
    // }

    // If dealId not found in originalStageDeals, then show an error that the deal stage/owner change was cancelled and return early
    if (!success) {
      GamifyToast.error(
        'There was an error updating your deal. Please try again.'
      );
      onComplete(false);
      return;
    }

    // NOTE: This updates deal in the DB, but will still need to do completeMission (in createFeedItem below) to ensure outbound integrations fire
    try {
      await API.graphql({
        query: mutations.maintainDealOwners,
        variables: {
          request: JSON.stringify(ourDealOwners),
        },
      });
    } catch (err) {
      console.error(err);
      GamifyToast.error(
        'There was an error updating your deal, please try again'
      );
      onComplete(false);

      // If on the kanban, revert to original deals array for original stage and current stage
      if (isOnKanban) {
        handleDealStageChangeRevert(
          originalStageId,
          originalStageDeals,
          currentStageId,
          currentStageDeals
        );
      }
      // else {
      //   handleDealStageChangeRevert(originalDealsListDeals);
      // }
      return;
    }

    // If on the kanban, fetch deals for originalStageId and currentStageId with showLoading set to false so dealOwners update eventually too
    // If on the list, fetch deals for page 0 with showLoading set to false so updatedAt eventually updates
    if (isOnKanban) {
      // Delay by 1 s to give DB time to update, kanban seemed to be loading faster than DB update after reroute
      setTimeout(async () => {
        await fetchDeals([originalStageId, currentStageId], false);
      }, 1000);
    } else {
      setTimeout(() => {
        handleRefetchDeals();
      }, 1000);
    }
    // NOTE: If can figure out deals changing w/ optimistic loading and background refetch then switch back to this
    // else {
    //   // Delay by 1 s to give DB time to update, list seemed to be loading faster than DB update after reroute
    //   setTimeout(async () => {
    //     await fetchDeals(false);
    //   }, 1000);
    // }

    // NOTE: createFeedItem used on mobile, is done by completeMission function in the backend
    // // let currentUserID =
    // //   _dealOwners.closerID !== "" ? _dealOwners.closerID : _dealOwners.setterID;

    const currentUserID = global.me.id;

    await createFeedItem(
      deal.id,
      dealOwners.currentStageID,
      currentUserID,
      (status) => {
        onComplete(status);
      }
    );
  };

  return (
    <>
      <Backdrop
        sx={{ zIndex: 100002 }}
        open={showOwnerDealStageModal}
        onClick={(e) => {
          if (
            !e.target.closest('.save-owner-deal-stage-button') &&
            !e.target.closest('#menu-dealStage') &&
            !e.target.closest('#menu-setter') &&
            !e.target.closest('#menu-closer') &&
            !e.target.closest('.MuiSelect-select') &&
            !e.target.closest('.MuiMenuItem-root') &&
            !e.target.closest('.MuiAutocomplete-root') // NOTE: not needed until Autocomplete implemented... need to figure out how Autcomplete works and how to style it and get rid of global styling on it first...
          ) {
            handleCancelChangeClick();
          }
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            width: 624,
            maxHeight: '70vh',
            padding: '24px 40px 32px 24px',
            borderRadius: 8,
            overflowY: 'auto',
          }}
        >
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              style={{
                color: '#222428',
                fontSize: '28px',
                fontWeight: 700,
                lineHeight: '48px',
                letterSpacing: '-0.02em',
                marginBottom: '16px',
              }}
            >
              Change deal stage
            </Typography>
          </Box>
          <Box
            style={{
              position: 'relative',
              width: '100%',
              marginBottom: 16,
            }}
          >
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: '#0B0B0C',
                marginBottom: 8,
              }}
            >
              Deal stage
            </Typography>
            {/* TO DO: Update SelectStage in PickerSearch and replace this with that */}
            <StyledSelect
              style={{ width: '100%' }}
              // placeholder="HELLO!!"
              onChange={(e) => {
                // console.log('SelectDealStage dealOwners: ', dealOwners);
                // console.log('SelectDealStage: ', dealStages);
                // console.log('SelectDealStage: ', e.target.value);

                // Set current stage for DynamicConfirmModal
                const tempStage = normalizedDealStages[e.target.value];
                setStage(tempStage);
                setDealOwners((p) => ({
                  ...p,
                  currentStageID: e.target.value,
                }));
                // NOTE: Disposition only used with pins... not on web for now
                // if (
                //   tempStage.changeToDispositionID !== null &&
                //   tempStage.changeToDispositionID !== ''
                // ) {
                //   setCurrentDispositionID(tempStage.changeToDispositionID);
                // }
              }}
              value={dealOwners.currentStageID}
              name="dealStage"
              MenuProps={{
                style: { zIndex: 100002 },
                MenuListProps: {
                  sx: {
                    maxHeight: 300,
                    overflowY: 'auto',
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderRadius: '8px',
                    border: '1px solid #D0D2D8',
                  },
                },
              }}
            >
              {filteredDealStages.map((opt, i) => (
                <StyledMenuItem value={opt.value} key={i}>
                  {opt.label[0].toUpperCase() + opt.label.slice(1)}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </Box>
          <Box
            style={{
              position: 'relative',
              width: '100%',
              marginBottom: 16,
            }}
          >
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: '#0B0B0C',
                marginBottom: 8,
              }}
            >
              Setter
            </Typography>
            {/* TO DO: Replace with a styled Autocomplete or redesigned NewSelectCircleOrPeople... figure out what is putting styling rules onto Autocomplete... see if it can be removed */}
            <StyledSelect
              style={{ width: '100%' }}
              // placeholder="HELLO!!"
              onChange={(e) => {
                // console.log(e.target.value);
                setDealOwners((p) => ({
                  ...p,
                  setterID: e.target.value,
                }));
              }}
              value={dealOwners.setterID}
              name="setter"
              MenuProps={{
                style: { zIndex: 100002 },
                MenuListProps: {
                  sx: {
                    maxHeight: 300,
                    overflowY: 'auto',
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderRadius: '8px',
                    border: '1px solid #D0D2D8',
                  },
                },
              }}
            >
              {filteredDealPeople.map((opt, i) => (
                <StyledMenuItem value={opt.value} key={i}>
                  {opt.label[0].toUpperCase() + opt.label.slice(1)}
                </StyledMenuItem>
              ))}
            </StyledSelect>
            {/* <Autocomplete
              style={{ width: '100%' }}
              value={dealOwners.setterID}
              onChange={(event, newValue) => {
                setDealOwners((prevState) => ({
                  ...prevState,
                  setterID: newValue,
                }));
              }}
              options={dealPeople}
              // getOptionLabel={(option) => {
              //   console.log('option: ', option);
              //   option.label;
              // }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Select Setter"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              renderOption={(props, option) => (
                <StyledMenuItem {...props}>
                  {option.label[0].toUpperCase() + option.label.slice(1)}
                </StyledMenuItem>
              )}
              MenuProps={{
                style: { zIndex: 100003 },
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    borderRadius: '8px',
                    border: '1px solid #D0D2D8',
                  },
                },
              }}
            /> */}
          </Box>
          <Box
            style={{
              position: 'relative',
              width: '100%',
              marginBottom: 16,
            }}
          >
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: '#0B0B0C',
                marginBottom: 8,
              }}
            >
              Closer
            </Typography>
            <StyledSelect
              style={{ width: '100%' }}
              // placeholder="HELLO!!"
              onChange={(e) => {
                // console.log(e.target.value);
                setDealOwners((p) => ({
                  ...p,
                  closerID: e.target.value,
                }));
              }}
              value={dealOwners.closerID}
              name="closer"
              MenuProps={{
                style: { zIndex: 100002 },
                MenuListProps: {
                  sx: {
                    maxHeight: 300,
                    overflowY: 'auto',
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderRadius: '8px',
                    border: '1px solid #D0D2D8',
                  },
                },
              }}
            >
              {filteredDealPeople.map((opt, i) => (
                <StyledMenuItem value={opt.value} key={i}>
                  {opt.label}
                  {/* {opt[0].toUpperCase() + opt.slice(1)} */}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              onClick={() => handleCancelChangeClick()}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 262,
                padding: 12,
                border: '1px solid #cfcfcf',
                borderRadius: 8,
              }}
            >
              <Typography style={{ color: '#323232', fontWeight: 700 }}>
                Cancel
              </Typography>
            </Box>
            <Box
              className={'save-owner-deal-stage-button'}
              onClick={() => {
                const {
                  currentStageID,
                  originalStageID,
                  originalCloserID,
                  closerID,
                  originalSetterID,
                  setterID,
                } = dealOwners;

                // Inform the user a closer is required if they don't have one selected and it is required for the stage
                if (
                  normalizedDealStages[currentStageID].requireCloser &&
                  dealOwners.closerID === ''
                ) {
                  setShowCloserRequiredModal(true);
                  return;
                }

                // Don't open the DynamicConfirmModal or do anything if nothing was changed
                if (
                  currentStageID === originalStageID &&
                  closerID === originalCloserID &&
                  setterID === originalSetterID
                ) {
                  setShowOwnerDealStageModal(false);
                  return;
                }

                if (currentStageID === originalStageID) {
                  // There is not a change in stage, but setter and/or closer changed
                  setDynamicModalObj(modalsConfig.noStageChange);
                } else {
                  // There is a change in stage and/or setter and/or closer
                  // Set dynamicModalObj to either warning if setting to an earlier sequence or inform to say cannot change back to a previous stage
                  if (
                    normalizedDealStages[currentStageID].sequence <
                    normalizedDealStages[originalStageID].sequence
                  ) {
                    // Warning about KPI and game impact
                    setDynamicModalObj(modalsConfig.warning);
                  } else {
                    // Inform user they cannot change back to a previous stage
                    setDynamicModalObj(modalsConfig.inform);
                  }
                }

                // Close the OwnerDealStageModal, open the DynamicConfirmModal
                setShowOwnerDealStageModal(false);
                setShowDynamicConfirmModal(true);
              }}
              sx={{ cursor: 'pointer' }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FF6A00',
                width: 262,
                padding: 12,
                borderRadius: 8,
              }}
            >
              {/* {isSaving ? (
                <CircularProgress
                  style={{ color: 'white', width: 24, height: 24 }}
                ></CircularProgress>
              ) : ( */}
              <Typography style={{ color: 'white', fontWeight: 700 }}>
                Save
              </Typography>
              {/* )} */}
            </Box>
          </Box>
        </Box>
      </Backdrop>
      <DynamicConfirmModal
        showDynamicConfirmModal={showDynamicConfirmModal}
        setShowDynamicConfirmModal={setShowDynamicConfirmModal}
        zIndex={100004}
        leftAlignText={true}
        title={dynamicModalObj?.title}
        subtitle={dynamicModalObj?.subtitle}
        onConfirm={async () => {
          setIsSaving(true);
          await saveDealOwners(dealOwners, (successful) => {
            if (successful) {
              setIsSaving(false);
              setShowDynamicConfirmModal(false);
              // setDealOptionsOpen(false);
              // setAnchorEl(null);

              // Update deal owners' original values with newly updated values
              setDealOwners({
                ...dealOwners,
                originalStageID: dealOwners.currentStageID,
                originalSetterID: dealOwners.setterID,
                originalCloserID: dealOwners.closerID,
              });
            }
          });

          // Refetch only triggers for the selected deal on the DealsKanban, handleDealStageChange (in saveDealOwners) handles refetching for the kanban itself
          setTimeout(() => {
            onTriggerRefetch();
          }, 750);

          GamifyToast.success('Deal updated successfully');
        }}
        onCancel={() => {
          // Will want the OwnerDealStageModal to open again after cancelling from DynamicConfirmModal
          setShowDynamicConfirmModal(false);
          setShowOwnerDealStageModal(true);
        }}
        isSaving={isSaving}
      ></DynamicConfirmModal>
      <DynamicConfirmModal
        showDynamicConfirmModal={showCloserRequiredModal}
        setShowDynamicConfirmModal={setShowCloserRequiredModal}
        zIndex={100008}
        leftAlignText={true}
        title={`A closer is required.`}
        subtitle={'Please select a closer and try again.'}
        confirmButtonText={'OK'}
        onConfirm={() => {
          // Close the modal after confirmation to get back to the OwnerDealStageModal
          setShowCloserRequiredModal(false);
        }}
        noCancelButton={true}
        isSaving={isSaving}
      ></DynamicConfirmModal>
    </>
  );
}
