import React, { useEffect, useState } from 'react';
import GifSearchView from './GifSearch.view';
import { LOG } from '../../../common/Utilities';
import { debounce } from '@mui/material';

interface Props {
  open: boolean;
  onOpenClose: () => void;
  onSelect: (gif: any) => void;
}

const GifSearchContainer = ({ onOpenClose, onSelect, open }: Props) => {
  const abortController = new AbortController();
  const [gifs, setGifs] = useState<any>([]);
  const [searchText, setSearchText] = useState('');

  const getGifs = debounce(async (searchText: string) => {
    let url;

    if (searchText) {
      url = `https://api.giphy.com/v1/gifs/search?api_key=L16mtR950lYlqWmf4FWfFWONhLI3JSkj&q=${searchText}`;
    } else {
      url =
        'https://api.giphy.com/v1/gifs/trending?api_key=L16mtR950lYlqWmf4FWfFWONhLI3JSkj';
    }

    try {
      const response = await fetch(url, {
        method: 'GET',
        signal: abortController.signal,
      });

      if (response.ok) {
        const responseJson = await response.json();
        const downSampledGifs = responseJson.data.map(
          (item: any) => item.images.fixed_height_downsampled
        );
        setGifs(downSampledGifs);
      }
    } catch (error) {
      LOG.error(error);
    }
  }, 500);

  useEffect(() => {
    if (open) {
      getGifs(searchText);
    }
  }, [open, searchText]);

  if (!open) {
    return null;
  }

  return (
    <GifSearchView
      gifs={gifs}
      searchText={searchText}
      onChangeSearchText={(event: React.ChangeEvent<HTMLInputElement>) =>
        setSearchText(event.target.value)
      }
      onOpenClose={onOpenClose}
      onSelect={onSelect}
    />
  );
};

export default GifSearchContainer;
