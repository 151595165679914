import Emoticon from '../_types/Emoticon';

const countEmoticonsByType = (
  emoticons: Emoticon[]
): Record<string, number> => {
  const seen = new Set<string>();

  return emoticons.reduce((accumulator, currentItem) => {
    const uniqueKey = `${currentItem.type}-${currentItem.user.id}`;

    // If we have not seen this combination before, count it.
    if (!seen.has(uniqueKey)) {
      seen.add(uniqueKey);
      accumulator[currentItem.type] = (accumulator[currentItem.type] || 0) + 1;
    }

    return accumulator;
  }, {} as Record<string, number>);
};

export default countEmoticonsByType;
