// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
//
// missionUtils
//
import React, { useState, useEffect } from 'react';
import { Text, View } from '@aws-amplify/ui-react';
import { Button, Box, ListItem, Grid } from '@mui/material';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import gql from 'graphql-tag';
import DisplayMedia from '../../components/DisplayMedia';
import { StyledInput } from '../../common/FormikHelpers';
import * as utils from '../../common/Utilities';
import {
  LOG,
  numberWithCommas,
  daysBetween,
  formattedLocalShortDate,
} from '../../common/Utilities';
import getUserAvatar from '../../common/utils/getUserAvatar';
// import UserAvatar from '../../common/UserAvatar';
import MaintainMissionScreen from './MaintainMissionScreen';
// import CountDown from 'react-native-countdown-component';
import RoundButtons from '../../common/RoundButtons';
import { SelectCircle, SelectPerson } from '../../common/PickerSearch';
import { FieldArray, Formik } from 'formik';
import ShadowButton from '../../common/ShadowButton';
import { useAlert } from 'react-alert-with-buttons';
import StyledButton from '../../components/StyledButton';
// import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

export function MissionAssignedUsers(props) {
  // LOG.debug ("MissionAssignedUsers: ", props);
  const [missionUsers, setMissionUsers] = useState([]);

  /// ///////////
  //
  //  Modify the mission goals
  //
  /// ///////////

  async function modifyMissionUserGoals(missionUserGoals) {
    // LOG.debug ("modifyMissionUserGoals: ", missionUserGoals);
    const alert = useAlert();
    try {
      for (const missionUserGoal of missionUserGoals) {
        await API.graphql({
          query: mutations.updateUserMission,
          variables: { input: missionUserGoal },
        });
        // LOG.debug ("updateUserMissionRes: ", updateUserMissionRes);
      }
    } catch (err) {
      alert.open({
        message:
          'There was an error modifing ' +
          global.appSettings.labels.mission.singular +
          ' goals.',
      });
      LOG.error('modifyMissionUserGoals: ' + err);
      return false;
    }
    alert.open({
      message: global.appSettings.labels.mission.proper + ' goals updated.',
    });
    return true;
  }

  async function doSubmit(values) {
    const missionUserGoals = [];

    for (const user of values.users) {
      missionUserGoals[missionUserGoals.length] = {
        id: user.id,
        goalActionCount: parseInt(user.goalActionCount),
      };
    }
    return modifyMissionUserGoals(missionUserGoals);
  }

  async function fetchUsersForMission(missionID) {
    const myQuery = gql`
      query getMissionUsers($missionID: ID!) {
        getMission(id: $missionID) {
          users(
            filter: { playMission: { eq: true }, isDeleted: { eq: false } }
          ) {
            items {
              circleID
              doneActionCount
              goalActionCount
              id
              imageName
              imageType
              isCompleted
              isPaid
              isVerified
              status
              userID
              user {
                name
                imageName
                initials
                status
              }
            }
          }
        }
      }
    `;

    const getMissionRet = await API.graphql({
      query: myQuery,
      variables: { missionID },
    });
    LOG.debug('getMissionRet: ', getMissionRet);

    const ourUsers = [];

    for (const user of getMissionRet.data.getMission.users.items) {
      if (user.user !== null && user.user.status === 'active') {
        user.profilePicture = await getUserAvatar(
          user.userID,
          user.user.imageName
        );
        if (user.goalActionCount === '' || user.goalActionCount === null) {
          user.goalActionCount = 0;
        }
        if (user.doneActionCount === '' || user.doneActionCount === null) {
          user.doneActionCount = 0;
        }
        ourUsers[ourUsers.length] = user;
      }
    }
    setMissionUsers(ourUsers);
  }

  useEffect(() => {
    fetchUsersForMission(props.missionID);
  }, []);

  return (
    <View>
      <Formik
        enableReinitialize={true}
        initialValues={{ users: missionUsers }}
        onSubmit={(values, actions) => doSubmit(values, actions)}
      >
        {(formikProps) => (
          <View
            style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'column' }}
          >
            <Text>
              {global.appSettings.labels.person.properPlural} Assigned To This{' '}
              {global.appSettings.labels.mission.proper}
            </Text>
            <View>
              <View style={{ flex: 0, flexDirection: 'row' }}>
                <Text style={{ width: 65 }} />
                <Text style={{ width: 165 }}>Name</Text>
                <Text style={{ width: 80 }}>Actual</Text>
                <Text style={{ width: 80 }}>Goal</Text>
              </View>
            </View>
            <FieldArray name="users">
              <React.Fragment>
                {missionUsers.map((user, i) => (
                  <ListItem
                    key={user.userID}
                    style={{ width: '100%', height: 50 }}
                  >
                    {/* <UserAvatar */}
                    {/*  profileType="person" */}
                    {/*  size="small" */}
                    {/*  initials={user.user.initials} */}
                    {/*  userID={user.userID} */}
                    {/*  profilePicture={user.profilePicture} */}
                    {/* /> */}
                    <Text style={{ width: 150 }}>{user.user.name}</Text>
                    <Text style={{ width: 50 }}>{user.doneActionCount}</Text>
                    <StyledInput
                      style={{
                        height: 25,
                        width: 80,
                        marginTop: 15,
                        borderWidth: 1,
                        borderColor: 'gray',
                        borderRadius: 8,
                        padding: 4,
                        marginBottom: 3,
                      }}
                      value={
                        formikProps.values.users[i] !== undefined
                          ? formikProps.values.users[
                              i
                            ].goalActionCount.toString()
                          : '0'
                      }
                      formikProps={formikProps}
                      formikKey={`users.${i}.goalActionCount`}
                      placeholder="0"
                    />
                  </ListItem>
                ))}
              </React.Fragment>
            </FieldArray>
            {formikProps.dirty && (
              <View
                style={{
                  marginTop: 20,
                  marginLeft: 5,
                  marginRight: 5,
                  marginBottom: 20,
                  alignSelf: 'center',
                }}
              >
                <ShadowButton
                  width={100}
                  title="Save"
                  onClick={formikProps.handleSubmit}
                />
              </View>
            )}
          </View>
        )}
      </Formik>
    </View>
  );
}

export function MissionAssignedCircles(props) {
  LOG.debug('MissionAssignedCircles: ', props);
  const [missionCircles, setMissionCircles] = useState([]);

  /// ///////////
  //
  //  Modify the circle goals
  //
  /// ///////////

  const alert = useAlert();

  async function modifyMissionCircleGoals(missionCircleGoals) {
    // LOG.debug ("modifyMissionCircleGoals: ", missionCircleGoals);

    try {
      for (const missionCircleGoal of missionCircleGoals) {
        LOG.debug('missionCircleGoal: ', missionCircleGoal);
        const updateCircleMissionRes = await API.graphql({
          query: mutations.updateCircleMission,
          variables: { input: missionCircleGoal },
        });
        LOG.debug('updateCircleMissionRes: ', updateCircleMissionRes);
      }
    } catch (err) {
      alert.open({
        message:
          'There was an error modifing ' +
          global.appSettings.labels.circle.singular +
          ' goals.',
      });
      LOG.error('modifyMissionCircleGoals: ', err);
      return false;
    }
    alert.open({
      message: global.appSettings.labels.circle.proper + ' goals updated.',
    });
    return true;
  }

  async function doSubmit(values) {
    const missionCircleGoals = [];

    for (const circle of values.circles) {
      missionCircleGoals[missionCircleGoals.length] = {
        id: circle.id,
        goalActionCount: parseInt(circle.goalActionCount),
      };
    }
    return modifyMissionCircleGoals(missionCircleGoals);
  }

  async function fetchCirclesForMission(missionID) {
    const myQuery = gql`
      query getmissionCircles($missionID: ID!) {
        getMission(id: $missionID) {
          circles(filter: { isDeleted: { eq: false } }) {
            items {
              doneActionCount
              goalActionCount
              id
              circle {
                name
                imageName
                status
              }
            }
          }
        }
      }
    `;

    const getCircleRet = await API.graphql({
      query: myQuery,
      variables: { missionID },
    });
    LOG.debug('getCircleRet: ', getCircleRet);

    for (const circle of getCircleRet.data.getMission.circles.items) {
      // user.profilePicture = await getUserAvatar(user.userID, user.user.imageName);
      if (circle.goalActionCount === '' || circle.goalActionCount === null) {
        circle.goalActionCount = 0;
      }
      if (circle.doneActionCount === '' || circle.doneActionCount === null) {
        circle.doneActionCount = 0;
      }
    }
    setMissionCircles(getCircleRet.data.getMission.circles.items);
  }

  useEffect(() => {
    fetchCirclesForMission(props.missionID);
  }, []);

  return (
    <View>
      <Formik
        enableReinitialize={true}
        initialValues={{ circles: missionCircles }}
        onSubmit={(values, actions) => doSubmit(values, actions)}
      >
        {(formikProps) => (
          <View
            style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'column' }}
          >
            <Text>
              {global.appSettings.labels.circle.properPlural} Assigned To This{' '}
              {global.appSettings.labels.mission.proper}
            </Text>
            <View>
              <View style={{ flex: 0, flexDirection: 'row' }}>
                <Text style={{ width: 65 }} />
                <Text style={{ width: 165 }}>Name</Text>
                <Text style={{ width: 80 }}>Actual</Text>
                <Text style={{ width: 80 }}>Goal</Text>
              </View>
            </View>
            <FieldArray name="circles">
              <React.Fragment>
                {missionCircles.map((circle, i) => (
                  <ListItem
                    key={circle.id}
                    style={{ width: '100%', height: 50 }}
                  >
                    <Text style={{ width: 150 }}>{circle.circle.name}</Text>
                    <Text style={{ width: 50 }}>{circle.doneActionCount}</Text>
                    <StyledInput
                      style={{
                        height: 25,
                        width: 80,
                        marginTop: 15,
                        borderWidth: 1,
                        borderColor: 'gray',
                        borderRadius: 8,
                        padding: 4,
                        marginBottom: 3,
                      }}
                      value={
                        formikProps.values.circles[i] !== undefined
                          ? formikProps.values.circles[
                              i
                            ].goalActionCount.toString()
                          : '0'
                      }
                      formikProps={formikProps}
                      formikKey={`circles.${i}.goalActionCount`}
                      placeholder="0"
                    />
                  </ListItem>
                ))}
              </React.Fragment>
            </FieldArray>
            {formikProps.dirty && (
              <View
                style={{
                  marginTop: 20,
                  marginLeft: 5,
                  marginRight: 5,
                  marginBottom: 20,
                  alignSelf: 'center',
                }}
              >
                <ShadowButton
                  width={100}
                  title="Save"
                  onClick={formikProps.handleSubmit}
                />
              </View>
            )}
          </View>
        )}
      </Formik>
    </View>
  );
}

/// ///////////////////////////////////////////////////////////////////////////
//
//  Display a card for a mission that has already been retrieved
//
// props:
// fetch: callback after succesful change
// mission: mission object
//      mission.mission
// navigation: navigation object from the screen
//
/// ///////////////////////////////////////////////////////////////////////////

export const MissionCard = (props) => {
  LOG.debug('MissionCard: ', props);
  const [editMission, setEditMission] = useState(false);
  const [editGoals, setEditGoals] = useState(false);
  const [mission, setMission] = useState(false);
  const [addCircleToMission, setAddCircleToMission] = useState(false);
  const [addPersonToMission, setAddPersonToMission] = useState(false);
  const [assigneeIDs, setAssigneeIDs] = useState([]);

  /// //////////
  //
  //  Delete mission
  //
  /// //////////

  async function deleteMission(mission) {
    // LOG.debug ("deleteMission: ", mission);
    try {
      await API.graphql({
        query: mutations.deleteMissionFunction,
        variables: {
          missionID: mission.id,
        },
      });
      // console.log ("saveMissionRes: ", saveMissionRes);
      alert.close();
      window.location.href = '/campaigns';
      alert.open({
        message: 'Mission Deleted',
      });
      return true;
    } catch (err) {
      alert.open({
        message: 'There was an error while deleting.  Please try again.',
      });
      LOG.error('Error Deleting Mission: ', err);
      return false;
    }
  }

  async function doAddCircleToMission(missionID, circleID, onSuccess) {
    LOG.debug('doAddCircleToMission: ', missionID, circleID);
    try {
      const request = {
        missionID,
        circleID,
      };
      await API.graphql({
        query: mutations.assignMissionToCircle,
        variables: {
          request: JSON.stringify(request),
        },
      });
      // console.log ("saveMissionRes: ", saveMissionRes);
      onSuccess(true);
      return true;
    } catch (err) {
      LOG.error('Error adding circle to mission: ', err);
      onSuccess(false);
      return false;
    }
  }

  async function doAddPersonToMission(missionID, userID, onSuccess) {
    LOG.debug('doAddCircleToMission: ', missionID, userID);
    try {
      const request = {
        missionID,
        userID,
      };
      await API.graphql({
        query: mutations.assignMissionToPerson,
        variables: {
          request: JSON.stringify(request),
        },
      });
      // console.log ("saveMissionRes: ", saveMissionRes);
      onSuccess(true);
      return true;
    } catch (err) {
      LOG.error('Error adding person to mission: ', err);
      onSuccess(false);
      return false;
    }
  }

  async function doRemoveCircleFromMission(missionID, circleID, onSuccess) {
    LOG.debug('doRemoveCircleFromMission: ', missionID, circleID);
    try {
      const request = {
        missionID,
        circleID,
      };
      const res = await API.graphql({
        query: mutations.unAssignMissionFromCircle,
        variables: {
          request: JSON.stringify(request),
        },
      });
      LOG.debug('***res: ', res);
      onSuccess(true);
      return true;
    } catch (err) {
      LOG.error('Error removing circle from mission: ', err);
      onSuccess(false);
      return false;
    }
  }

  async function doRemoveUserFromMission(missionID, userID, onSuccess) {
    LOG.debug('doRemoveCircleFromMission: ', missionID, userID);
    try {
      const request = {
        missionID,
        userID,
      };
      const res = await API.graphql({
        query: mutations.unAssignMissionFromPerson,
        variables: {
          request: JSON.stringify(request),
        },
      });
      LOG.debug('***res: ', res);
      onSuccess(true);
      return true;
    } catch (err) {
      LOG.error('Error removing user from mission: ', err);
      onSuccess(false);
      return false;
    }
  }

  /// ///////////
  //
  //  Modify the mission
  //
  /// ///////////

  async function fetchMission(id) {
    LOG.debug('fetchMission: ', id);
    try {
      const myQuery = gql`
        query MyQuery($missionID: ID!) {
          getMission(id: $missionID) {
            id
            action
            actionCount
            actionCountType
            actionPastTense
            actionType
            actionTypePlural
            autoAssign
            description
            startDate
            endDate
            imageName
            imageType
            isCircleOnly
            isDeleted
            launchDate
            location
            ownerID
            ownerType
            rewardCount
            rewardType
            name
            KPIRule {
              title
            }
            rewards(filter: { isDeleted: { eq: false } }) {
              items {
                id
                place
                reward {
                  id
                  name
                }
              }
            }
            circle {
              id
              isDeleted
              name
            }
            user {
              id
              isDeleted
              name
            }
            circles(filter: { isDeleted: { eq: false } }) {
              items {
                circle {
                  id
                  isDeleted
                  name
                }
              }
            }
            users(filter: { isDeleted: { eq: false } }) {
              items {
                user {
                  id
                  isDeleted
                  name
                }
              }
            }
          }
        }
      `;

      const getMissionRet = await API.graphql({
        query: myQuery,
        variables: { missionID: id },
      });
      LOG.debug('getMissionRet: ', getMissionRet);

      const now = new Date();
      // const now = getLocalDateTime();
      LOG.debug('now: ', now);
      const ourMission = getMissionRet.data.getMission;

      if (ourMission !== null) {
        LOG.debug('ourMission: ', ourMission);
        const startDate = new Date(ourMission.startDate);
        // const gmtEndDate = new Date(ourMission.endDate);
        //  Add one day to the end date so that the day ends at the END of the day, not the beginning of the day
        // const endDate = moment(gmtEndDate).add(1, 'day').subtract(1, 'second');
        const endDate = new Date(ourMission.endDate);

        ourMission.remainingDaysInMission = daysBetween(now, endDate);
        ourMission.daysBeforeStart = daysBetween(now, startDate);
        ourMission.secondsBeforeStart = utils.secondsBetween(now, startDate);
        // LOG.debug ("===seconds before start", ourMission.secondsBeforeStart);
        ourMission.remainingSecondsInMission = utils.secondsBetween(
          now,
          endDate
        );
        // LOG.debug ("===remainingSecondsInMission: ", ourMission.remainingSecondsInMission);
        // LOG.debug ("!!!ourMission.daysBeforeStart = ", ourMission.daysBeforeStart)
        if (ourMission.rewards.items.length > 0) {
          ourMission.rewards.items = ourMission.rewards.items.sort((b, a) => {
            return b.place - a.place;
          });
        }
        const assigneeIDList = [];
        for (const circle of ourMission.circles.items) {
          assigneeIDList[assigneeIDList.length] = circle.circle.id;
        }
        LOG.debug('**assigneeIDList: ', assigneeIDList);
        setAssigneeIDs(assigneeIDList);
        setMission(ourMission);
      }
    } catch (err) {
      LOG.error('!!!fetchMission error: ', id, err);
      setMission(false);
    }
  }

  useEffect(() => {
    LOG.debug('about to fetchMission: ', props.missionID);
    fetchMission(props.missionID);
  }, []);

  /// /////////////////////////////////////////////////////////////////////////////////
  //
  //  MAIN screen
  //
  /// /////////////////////////////////////////////////////////////////////////////////

  const alert = useAlert();
  LOG.debug('!!mission: ', mission);
  return (
    <View>
      {mission && (
        <React.Fragment>
          <View style={{ paddingBottom: 0 }}>
            {mission.isDeleted ? (
              <Text
                style={{
                  color: 'red',
                  fontSize: 32,
                  textAlign: 'center',
                }}
              >
                This {global.appSettings.labels.mission.proper} has been DELETED
              </Text>
            ) : (
              <React.Fragment>
                {(global.me.isAdmin || global.me.canCreateMissions) && (
                  <RoundButtons
                    onClick={(val) => {
                      // LOG.debug ("Selected dimension: ", val);
                      // LOG.debug ("dimensionID: " + global.me.orgID);
                      if (val === 'close') {
                        props.hideTheMenu();
                      } else if (val === 'edit') {
                        // setEditMission(!editMission);
                        window.location.href = `/campaigns/new?missionID=${mission.id}`;
                      } else if (val === 'goals') {
                        setEditGoals(!editGoals);
                      } else if (val === 'delete') {
                        alert.open({
                          message:
                            'Delete ' +
                            global.appSettings.labels.mission.proper +
                            '? Are you sure you wish to permanently delete the ' +
                            mission.name +
                            ' ' +
                            global.appSettings.labels.mission.proper +
                            '?',
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                deleteMission(mission);
                                setTimeout(() => {
                                  props.fetch(true);
                                  alert.close(); // Remeber that customly created button does not close the alert by default!
                                }, 1000);
                              },
                              style: {
                                backgroundColor: 'green',
                                borderRadius: 15,
                                color: 'white',
                                margin: 5,
                              },
                            },
                            {
                              label: 'No',
                              onClick: () => {
                                // implement your function here for the button2 click
                                alert.close(); // Remeber that customly created button does not close the alert by default!
                                // You must add the close function manually!
                              },
                              style: {
                                backgroundColor: 'red',
                                borderRadius: 15,
                                color: 'white',
                                margin: 5,
                              },
                            },
                          ],
                        });
                      }
                    }}
                    buttons={[
                      {
                        key: 'close',
                        iconName: 'bullseye',
                        iconType: 'font-awesome-5',
                        title: 'Close',
                      },
                      {
                        key: 'goals',
                        iconName: 'bullseye',
                        iconType: 'font-awesome-5',
                        title: 'Goals',
                      },
                      {
                        key: 'edit',
                        iconName: 'edit',
                        iconType: 'font-awesome-5',
                        title: 'Edit',
                      },
                      {
                        key: 'delete',
                        iconName: 'trash-alt',
                        iconType: 'font-awesome-5',
                        title: 'Delete',
                      },
                    ]}
                  />
                )}
              </React.Fragment>
            )}

            <View
              style={{
                width: '100%',
                paddingBottom: 10,
                paddingTop: 20,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text
                style={{
                  fontSize: 22,
                  textTransform: 'uppercase',
                  textAlign: 'center',
                }}
              >
                {mission.name}
              </Text>
            </View>

            {false &&
              mission.secondsBeforeStart > 0 &&
              mission.daysBeforeStart <= 7 && (
                <React.Fragment>
                  <Text>
                    {global.appSettings.labels.mission.proper} Will Start in:
                  </Text>
                  {/* <CountDown until={mission.secondsBeforeStart}/> */}
                </React.Fragment>
              )}
            {false &&
              mission.remainingDaysInMission < 10 &&
              mission.remainingDaysInMission >= 0 && (
                <React.Fragment>
                  <Text>
                    {global.appSettings.labels.mission.proper} Will End in:
                  </Text>
                  {/* <CountDown until={mission.remainingSecondsInMission}/> */}
                </React.Fragment>
              )}
            {mission.remainingDaysInMission < 0 && (
              <Text
                style={{
                  paddingLeft: 4,
                  color: 'red',
                  fontSize: 24,
                  textAlign: 'center',
                }}
              >
                This {global.appSettings.labels.mission.singular} is no longer
                available.
              </Text>
            )}

            {mission.imageName !== '' && (
              <DisplayMedia
                imageName={mission.imageName}
                imageType={mission.imageType}
              />
            )}
            <Text
              style={{
                paddingLeft: 4,
                paddingTop: 5,
                paddingBottom: 10,
              }}
            >
              {mission.description}
            </Text>

            {mission.action !== '' && (
              <View style={{ flexDirection: 'row', paddingLeft: 15 }}>
                <Text>Goal: </Text>
                <Text>{mission.action}</Text>
                <Text> {mission.actionCount} </Text>
                <Text>
                  {mission.actionCount > 1
                    ? mission.actionTypePlural
                    : mission.actionType}
                </Text>
              </View>
            )}

            {mission.rewardType === 'mission_points' && (
              <Grid
                display={'inline-flex'}
                style={{ width: '100%', paddingLeft: '1%' }}
              >
                <Text style={{ marginRight: '1%', paddingLeft: '1%' }}>
                  {global.appSettings.labels.reward.proper}:
                </Text>
                <Text>
                  {' '}
                  {mission.rewardCount}{' '}
                  {mission.rewardCount === 1
                    ? global.appSettings.labels.points.singular
                    : global.appSettings.labels.points.plural}
                  /{mission.actionType}.
                </Text>
              </Grid>
            )}
            {mission.rewardType === 'action_points' && (
              <Grid
                display={'inline-flex'}
                style={{ width: '100%', paddingLeft: '1%' }}
              >
                <Text style={{ marginRight: '1%' }}>
                  {global.appSettings.labels.reward.proper}:
                </Text>
                <Text>
                  {' '}
                  {mission.rewardCount}{' '}
                  {mission.rewardCount === 1
                    ? global.appSettings.labels.points.singular
                    : global.appSettings.labels.points.plural}
                  /{mission.actionType}.
                </Text>
              </Grid>
            )}
            {mission.rewardType === 'action_reward' && (
              <Grid
                display={'inline-flex'}
                style={{ width: '100%', paddingLeft: '1%' }}
              >
                <Text style={{ marginRight: '1%' }}>
                  {global.appSettings.labels.reward.proper}:
                </Text>
                <Text>
                  {' '}
                  1 {mission.rewardName}/{mission.action}.
                </Text>
              </Grid>
            )}
            {mission.rewardType === 'mission_reward' && (
              <Grid
                display={'inline-flex'}
                style={{ width: '100%', paddingLeft: '1%' }}
              >
                <Text style={{ marginRight: '1%' }}>
                  {global.appSettings.labels.reward.proper}:
                </Text>
                <Text> {mission.rewardName} when mission complete.</Text>
              </Grid>
            )}

            {mission.rewards.items.length > 0 && (
              <View>
                {mission.rewards.items.map((reward, r) => (
                  <React.Fragment key={r}>
                    {(mission.rewardType === 'action_reward' ||
                      mission.rewardType === 'mission_reward') && (
                      <View style={{ flexDirection: 'row' }}>
                        {reward.place === 1 && (
                          <Text style={{ width: '30%', marginLeft: 25 }}>
                            1st Place
                          </Text>
                        )}
                        {reward.place === 2 && (
                          <Text style={{ width: '30%', marginLeft: 25 }}>
                            2nd Place
                          </Text>
                        )}
                        {reward.place === 3 && (
                          <Text style={{ width: '30%', marginLeft: 25 }}>
                            3rd Place
                          </Text>
                        )}
                        {reward.place === 4 && (
                          <Text style={{ width: '30%', marginLeft: 25 }}>
                            Completion
                          </Text>
                        )}
                        {reward.reward !== null && (
                          <Text>{reward.reward.name}</Text>
                        )}
                      </View>
                    )}

                    {reward.points > 0 &&
                      (mission.rewardType === 'action_points' ||
                        mission.rewardType === 'mission_points') && (
                        <View style={{ flexDirection: 'row' }}>
                          {reward.place === 1 && (
                            <React.Fragment>
                              {mission.rewardType === 'mission_points' && (
                                <Text style={{ paddingLeft: 15 }}>
                                  {global.appSettings.labels.reward.proper}:
                                </Text>
                              )}
                              <Text style={{ width: '30%', marginLeft: 6 }}>
                                1st Place
                              </Text>
                            </React.Fragment>
                          )}
                          {reward.place === 2 && (
                            <Text style={{ width: '30%', marginLeft: 80 }}>
                              2nd Place
                            </Text>
                          )}
                          {reward.place === 3 && (
                            <Text style={{ width: '30%', marginLeft: 80 }}>
                              3rd Place
                            </Text>
                          )}
                          {reward.place === 4 && (
                            <Text style={{ width: '30%', marginLeft: 80 }}>
                              Completion
                            </Text>
                          )}
                          <Text>{numberWithCommas(reward.points)}</Text>
                          <Text style={{ width: '30%' }}>
                            {' '}
                            {reward.points === 1
                              ? global.appSettings.labels.points.singular
                              : global.appSettings.labels.points.plural}
                          </Text>
                        </View>
                      )}
                  </React.Fragment>
                ))}
              </View>
            )}
            <Text style={{ paddingLeft: 15 }}>
              <Text>Available from:</Text>{' '}
              {formattedLocalShortDate(mission.startDate)} to{' '}
              {formattedLocalShortDate(mission.endDate)}
            </Text>
            {mission.isCircleOnly ? (
              <Text style={{ paddingLeft: 15 }}>
                <Text>{global.appSettings.labels.mission.proper} Type: </Text>
                {global.appSettings.labels.circle.proper}
              </Text>
            ) : (
              <Text style={{ paddingLeft: 15 }}>
                <Text>{global.appSettings.labels.mission.proper} Type: </Text>
                {global.appSettings.labels.person.proper}
              </Text>
            )}

            {(global.me.isAdmin || global.me.canCreateMissions) &&
              mission.ownerType === 'circle' &&
              !mission.isDeleted && (
                <View
                  style={{
                    flexDirection: 'row',
                    alignSelf: 'center',
                    paddingTop: 10,
                  }}
                >
                  <Button
                    // icon={<Icon name="plus-circle" size={25} marginRight={2} color="gray" type="font-awesome-5"/>}
                    // type="clear"
                    onClick={() => {
                      setAddCircleToMission(!addCircleToMission);
                    }}
                  />
                  <Text style={{ paddingTop: 4 }}>
                    {' '}
                    {global.appSettings.labels.circle.proper}
                  </Text>
                </View>
              )}
            {(global.me.isAdmin || global.me.canCreateMissions) &&
              mission.ownerType === 'person' &&
              !mission.isDeleted && (
                <View
                  style={{
                    flexDirection: 'row',
                    alignSelf: 'center',
                    paddingTop: 10,
                  }}
                >
                  <Button
                    // icon={<Icon name="plus-circle" size={25} marginRight={2} color="gray" type="font-awesome-5"/>}
                    // type="clear"
                    onClick={() => {
                      setAddPersonToMission(!addPersonToMission);
                    }}
                  />
                  <Text style={{ paddingTop: 4 }}>
                    {' '}
                    {global.appSettings.labels.person.proper}
                  </Text>
                </View>
              )}
          </View>

          {addCircleToMission && (
            <View>
              <SelectCircle
                hideCircleLevels={global.appSettings.features.circle.hideLevels}
                noAutoSelect={true}
                hideAssigned={assigneeIDs}
                paddingRight={20}
                filter={global.me.orgID}
                value=""
                fieldSetter={(value, name) => {
                  if (value !== '') {
                    alert.open({
                      message:
                        'Add ' +
                        global.appSettings.labels.circle.singular +
                        ' to ' +
                        mission.name +
                        '? Are you sure you wish to add ' +
                        name +
                        ' to ' +
                        mission.name +
                        ' ' +
                        global.appSettings.labels.mission.singular +
                        '?',
                      buttons: [
                        {
                          label: 'Yes',
                          onClick: () => {
                            doAddCircleToMission(
                              mission.id,
                              value,
                              (success) => {
                                if (success) {
                                  alert.open({
                                    message:
                                      name + ' added to ' + mission.name + '.',
                                  });
                                  const newAssigneeIDs = assigneeIDs;
                                  newAssigneeIDs[newAssigneeIDs.length] =
                                    mission.id;
                                  setAssigneeIDs(newAssigneeIDs);
                                  setAddCircleToMission(false);
                                  setTimeout(() => {
                                    fetchMission(props.missionID);
                                    alert.close(); // Remeber that customly created button does not close the alert by default!
                                  }, 1000);
                                } else {
                                  alert.open({
                                    message:
                                      'Error adding ' +
                                      name +
                                      ' to ' +
                                      mission.name +
                                      '.  Please Try again.',
                                  });
                                }
                              }
                            );
                          },
                          style: {
                            backgroundColor: 'green',
                            borderRadius: 15,
                            color: 'white',
                            margin: 5,
                          },
                        },
                        {
                          label: 'No',
                          onClick: () => {
                            // implement your function here for the button2 click
                            alert.close(); // Remeber that customly created button does not close the alert by default!
                            // You must add the close function manually!
                          },
                          style: {
                            backgroundColor: 'red',
                            borderRadius: 15,
                            color: 'white',
                            margin: 5,
                          },
                        },
                      ],
                    });
                  }
                }}
              />
            </View>
          )}
          {addPersonToMission && (
            <View>
              <SelectPerson
                filter={global.me.orgID}
                paddingRight={20}
                value=""
                fieldSetter={(value, name) => {
                  if (value !== '') {
                    alert.open({
                      message:
                        'Add ' +
                        global.appSettings.labels.person.singular +
                        ' to ' +
                        mission.name +
                        '? Are you sure you wish to add ' +
                        name +
                        ' to ' +
                        mission.name +
                        ' ' +
                        global.appSettings.labels.mission.singular +
                        '?',
                      buttons: [
                        {
                          label: 'Yes',
                          onClick: () => {
                            doAddPersonToMission(
                              mission.id,
                              value,
                              (success) => {
                                if (success) {
                                  alert.open({
                                    message:
                                      name + ' added to ' + mission.name + '.',
                                  });
                                  const newAssigneeIDs = assigneeIDs;
                                  newAssigneeIDs[newAssigneeIDs.length] =
                                    mission.id;
                                  setAssigneeIDs(newAssigneeIDs);
                                  setAddPersonToMission(false);
                                  setTimeout(() => {
                                    fetchMission(props.missionID);
                                    alert.close();
                                  }, 1000);
                                } else {
                                  alert.open({
                                    message:
                                      'Error adding ' +
                                      name +
                                      ' to ' +
                                      mission.name +
                                      '.  Please Try again.',
                                  });
                                }
                              }
                            );
                          },
                          style: {
                            backgroundColor: 'green',
                            borderRadius: 15,
                            color: 'white',
                            margin: 5,
                          },
                        },
                        {
                          label: 'No',
                          onClick: () => {
                            // implement your function here for the button2 click
                            alert.close(); // Remeber that customly created button does not close the alert by default!
                            // You must add the close function manually!
                          },
                          style: {
                            backgroundColor: 'red',
                            borderRadius: 15,
                            color: 'white',
                            margin: 5,
                          },
                        },
                      ],
                    });
                  }
                }}
              />
            </View>
          )}

          {mission.users.items.length > 0 && mission.ownerType === 'person' && (
            <View>
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  paddingTop: 10,
                  textTransform: 'uppercase',
                }}
              >
                {global.appSettings.labels.person.plural}
              </Text>
              {mission.users.items.map((user, c) => (
                <React.Fragment key={c}>
                  {user.user && !user.user.isDeleted && (
                    <ListItem>
                      <Text style={{ width: '90%' }}>{user.user.name}</Text>
                      {(global.me.isAdmin || global.me.canCreateMissions) &&
                        !mission.isDeleted && (
                          <StyledButton
                            startIcon={<DeleteIcon />}
                            // startIcon={<Icon name="times" size={25} marginRight={2} color="gray" type="font-awesome-5"/>}
                            // type="clear"
                            onClick={() => {
                              alert.open({
                                message:
                                  'Remove ' +
                                  global.appSettings.labels.person.singular +
                                  ' from ' +
                                  global.appSettings.labels.mission.singular +
                                  '? Are you sure you wish to permanently remove the ' +
                                  user.user.name +
                                  ' from the ' +
                                  mission.name +
                                  ' ' +
                                  global.appSettings.labels.mission.proper +
                                  '?',
                                buttons: [
                                  {
                                    label: 'Yes',
                                    onClick: () => {
                                      doRemoveUserFromMission(
                                        mission.id,
                                        user.user.id,
                                        (success) => {
                                          if (success) {
                                            alert.close();
                                            const newAssigneeIDs = [];
                                            LOG.debug(
                                              '**newAssigneeIDs: ',
                                              newAssigneeIDs
                                            );
                                            for (const assigneeID of assigneeIDs) {
                                              if (assigneeID !== user.user.id) {
                                                newAssigneeIDs[
                                                  newAssigneeIDs.length
                                                ] = assigneeID;
                                              }
                                              LOG.debug(
                                                'assigneeID: ',
                                                assigneeID
                                              );
                                            }
                                            setAssigneeIDs(newAssigneeIDs);
                                            alert.open({
                                              message:
                                                user.user.name +
                                                ' removed from ' +
                                                mission.name +
                                                '.',
                                            });
                                            setTimeout(() => {
                                              fetchMission(props.missionID);
                                            }, 1500);
                                          } else {
                                            alert.close();
                                            alert.open({
                                              message:
                                                'Error removing ' +
                                                user.user.name +
                                                ' from ' +
                                                mission.name +
                                                '.  Please Try again.',
                                            });
                                          }
                                        }
                                      );
                                      alert.close();
                                    },
                                    style: {
                                      backgroundColor: 'green',
                                      borderRadius: 15,
                                      color: 'white',
                                      margin: 5,
                                    },
                                  },
                                  {
                                    label: 'No',
                                    onClick: () => {
                                      // implement your function here for the button2 click
                                      alert.close(); // Remeber that customly created button does not close the alert by default!
                                      // You must add the close function manually!
                                    },
                                    style: {
                                      backgroundColor: 'red',
                                      borderRadius: 15,
                                      color: 'white',
                                      margin: 5,
                                    },
                                  },
                                ],
                              });
                            }}
                          >
                            Delete
                          </StyledButton>
                        )}
                    </ListItem>
                  )}
                </React.Fragment>
              ))}
            </View>
          )}

          {mission.circles.items.length > 0 &&
            mission.ownerType === 'circle' && (
              <View>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 20,
                    paddingTop: 10,
                    textTransform: 'uppercase',
                  }}
                >
                  {global.appSettings.labels.circle.plural}
                </Text>
                {mission.circles.items.map((circle, c) => (
                  <React.Fragment key={c}>
                    {circle.circle && !circle.circle.isDeleted && (
                      <ListItem>
                        <Text style={{ width: '90%' }}>
                          {circle.circle.name}
                        </Text>
                        {(global.me.isAdmin || global.me.canCreateMissions) &&
                          !mission.isDeleted && (
                            <StyledButton
                              startIcon={<DeleteIcon />}
                              // icon={<Icon name="times" size={25} marginRight={2} color="gray" type="font-awesome-5"/>}
                              // type="clear"
                              onClick={() => {
                                alert.open({
                                  message:
                                    'Remove ' +
                                    global.appSettings.labels.circle.singular +
                                    ' from ' +
                                    global.appSettings.labels.mission.singular +
                                    '? Are you sure you wish to permanently remove the ' +
                                    circle.circle.name +
                                    ' from the ' +
                                    mission.name +
                                    ' ' +
                                    global.appSettings.labels.mission.proper +
                                    '?',
                                  buttons: [
                                    {
                                      label: 'Yes',
                                      onClick: () => {
                                        doRemoveCircleFromMission(
                                          mission.id,
                                          circle.circle.id,
                                          (success) => {
                                            if (success) {
                                              const newAssigneeIDs = [];
                                              LOG.debug(
                                                '**newAssigneeIDs: ',
                                                newAssigneeIDs
                                              );
                                              for (const assigneeID of assigneeIDs) {
                                                if (
                                                  assigneeID !==
                                                  circle.circle.id
                                                ) {
                                                  newAssigneeIDs[
                                                    newAssigneeIDs.length
                                                  ] = assigneeID;
                                                }
                                                LOG.debug(
                                                  'assigneeID: ',
                                                  assigneeID
                                                );
                                              }
                                              setAssigneeIDs(newAssigneeIDs);
                                              alert.open({
                                                message:
                                                  circle.circle.name +
                                                  ' removed from ' +
                                                  mission.name +
                                                  '.',
                                              });
                                              setTimeout(
                                                () =>
                                                  fetchMission(props.missionID),
                                                1500
                                              );
                                            } else {
                                              alert.open({
                                                message:
                                                  'Error removing ' +
                                                  circle.circle.name +
                                                  ' from ' +
                                                  mission.name +
                                                  '.  Please Try again.',
                                              });
                                            }
                                          }
                                        );
                                      },
                                      style: {
                                        backgroundColor: 'green',
                                        borderRadius: 15,
                                        color: 'white',
                                        margin: 5,
                                      },
                                    },
                                    {
                                      label: 'No',
                                      onClick: () => {
                                        // implement your function here for the button2 click
                                        alert.close(); // Remeber that customly created button does not close the alert by default!
                                        // You must add the close function manually!
                                      },
                                      style: {
                                        backgroundColor: 'red',
                                        borderRadius: 15,
                                        color: 'white',
                                        margin: 5,
                                      },
                                    },
                                  ],
                                });
                              }}
                            >
                              Delete
                            </StyledButton>
                          )}
                      </ListItem>
                    )}
                  </React.Fragment>
                ))}
              </View>
            )}

          {editMission && (
            <Box style={{ flex: 0, width: '100%', height: '100%', padding: 0 }}>
              <View
                style={{
                  flex: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor:
                    global.appSettings.colors.globalBackground.backgroundColor,
                }}
              >
                <View style={{ backgroundColor: 'white', height: '100%' }}>
                  <View>
                    <MaintainMissionScreen
                      missionID={mission.id}
                      onExit={() => {
                        setEditMission(false);
                        fetchMission(props.missionID);
                      }}
                    />
                  </View>
                </View>
              </View>
            </Box>
          )}

          {editGoals && (
            <Box style={{ width: '100%', height: '100%', padding: 0 }}>
              <View
                style={{
                  flex: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor:
                    global.appSettings.colors.globalBackground.backgroundColor,
                }}
              >
                <View style={{ backgroundColor: 'white', height: '100%' }}>
                  <View>
                    {mission.isCircleOnly ? (
                      <MissionAssignedCircles missionID={mission.id} />
                    ) : (
                      <MissionAssignedUsers missionID={mission.id} />
                    )}
                  </View>
                </View>
              </View>
            </Box>
          )}
        </React.Fragment>
      )}
    </View>
  );
};
