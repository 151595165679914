import React, { useState } from 'react';
import EditCommentView from './EditComment.view';
import Me from '../../../_types/Me';
import MyPerson from '../_types/MyPerson';
import CommentType from '../_types/CommentType';
import EditCommentArgs from '../_types/EditCommentArgs';
import { GamifyToast } from '../../../common/CustomToasts';
import updateComment from '../feed-helpers/updateComment';
import { Storage } from 'aws-amplify';
import { LOG } from '../../../common/Utilities';

interface Props {
  comment: CommentType;
  me: Me;
  myPersons: MyPerson[];
  isUpdating: boolean;
  onChangeIsUpdating: (newIsUpdating: boolean) => void;
  refetchComment: () => void;
}

const EditCommentContainer = ({
  comment: originalComment,
  me,
  myPersons,
  onChangeIsUpdating,
  refetchComment,
}: Props) => {
  const INITIAL_COMMENT = {
    id: originalComment.id,
    commentActivityFeedId: originalComment.activityFeed.id,
    content: originalComment.content,
    imageName: originalComment.imageName,
    imageType: originalComment.imageType,
    isDeleted: false,
    orgID: me.orgID,
    userID: me.id,
    createdBy: me.id,
    lastUpdatedBy: me.id,
  };
  const [comment, setComment] = useState<EditCommentArgs>(INITIAL_COMMENT);
  const [filePreview, setFilePreview] = useState<any>(comment.imageName);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [openGif, setOpenGif] = useState(false);

  const onOpenCloseGif = () => {
    setOpenGif(!openGif);
  };

  const onChangeComment = (comment: EditCommentArgs) => {
    if (comment.imageType === 'gif_url') {
      setFilePreview(comment.imageName);
    }

    setComment(comment);
  };

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log('files', files);
    if (files && files.length > 0) {
      try {
        const selectedFile = files[0];
        const filePreview = URL.createObjectURL(selectedFile);

        let imageType = '';
        const fileType = selectedFile.type.split('/')[0];
        if (fileType === 'video') {
          imageType = 'video';
        } else if (fileType === 'image') {
          imageType = 'picture';
        }

        setComment({ ...comment, imageType, imageName: selectedFile.name });
        setSelectedFile(selectedFile);
        setFilePreview(filePreview);
      } catch (error) {
        LOG.error(error);
      }
    }
  };

  const onUpdateComment = async () => {
    try {
      if (comment.content.length < 1) {
        GamifyToast.error(
          'You must enter at least 2 characters for a comment.'
        );
        return;
      }

      GamifyToast.success('Updating content');
      await updateComment(comment);

      if (selectedFile) {
        await Storage.put(selectedFile.name, selectedFile, {
          contentType: selectedFile.type,
        });
      }

      GamifyToast.success('Content updated');
      refetchComment();
      onChangeIsUpdating(false);
    } catch (error) {
      GamifyToast.error('Something went wrong updating the comment.');
      LOG.error(error);
    }
  };

  return (
    <EditCommentView
      comment={comment}
      filePreview={filePreview}
      me={me}
      myPersons={myPersons}
      onChangeComment={onChangeComment}
      onChangeIsUpdating={onChangeIsUpdating}
      openGif={openGif}
      onOpenCloseGif={onOpenCloseGif}
      onRemoveFilePreview={() => setFilePreview(null)}
      onUpdateComment={onUpdateComment}
      onUpload={onUpload}
    />
  );
};

export default EditCommentContainer;
