import React from 'react';
import {
  CustomInputContainer,
  TextInputCustom,
  CustomInputLabel,
  ErrorMessage,
  CustomTextArea,
} from './CustomInputs.styles';

interface Props {
  label: string;
  placeholder?: string;
  value: string;
  onChange: (newValue: string) => void;
  errorMessage?: string;
  rightComponent?: React.ReactNode;
  type?: 'TextInput' | 'TextArea';
}

const CustomTextInput: React.FC<Props> = ({
  label,
  onChange,
  placeholder,
  value,
  errorMessage,
  rightComponent,
  type = 'TextInput',
}) => {
  return (
    <CustomInputContainer>
      <CustomInputLabel>{label}</CustomInputLabel>
      {type === 'TextInput' ? (
        <TextInputCustom
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      ) : (
        <CustomTextArea
          maxLength={255}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
        />
      )}
      {rightComponent && (
        <div style={{ position: 'absolute', right: 12, top: 40 }}>
          {rightComponent}
        </div>
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </CustomInputContainer>
  );
};

export default CustomTextInput;
