import React from 'react';
import { VictoryBar, VictoryChart, VictoryTheme, VictoryAxis } from 'victory';
import { Box, Grid, Typography } from '@mui/material';
import './Index.css';
import CenteredProgress from '../../components/CenteredProgress';
import SummarizeKPIForAllRepository from '../../repository/SummarizeKPIForAllRepository';
import CenteredComponent from '../../components/CenteredComponent';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../components/withRouter';
import { withUseParams } from '../../components/withUseParams';
import { withUseSearchParams } from '../../components/useSearchParams';

import moment from 'moment';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';

class DisplayCharts extends React.Component {
  constructor(props) {
    console.log('****constructor****', props);
    super(props);
    this.state = {
      charts: [],
      isLoading: true,
      startDate: props.startDate,
      endDate: props.endDate,
      interval: props.interval,
      userId: props.userId,
      searchParams: props.searchParams,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('!!nextProps: ', nextProps);
    if (
      nextProps.startDate !== prevState.startDate ||
      nextProps.endDate !== prevState.endDate ||
      nextProps.interval !== prevState.interval ||
      nextProps.userId !== prevState.userId
    ) {
      return {
        charts: [],
        isLoading: true,
        startDate: nextProps.startDate,
        endDate: nextProps.endDate,
        interval: nextProps.interval,
        userId: nextProps.userId,
        searchParams: nextProps.searchParams,
      };
    }
    return null;
  }

  loadData(data) {
    console.log('**loadData: ', data);
    const searchParams = data.searchParams;
    const startDate = data.startDate
      ? data.startDate
      : searchParams.get('startDate');
    const endDate = data.endDate ? data.endDate : searchParams.get('endDate');
    const interval = data.interval
      ? data.interval
      : searchParams.get('interval');
    const userId = data.userId ? data.userId : searchParams.get('userId');

    const myQuery = gql`
      query MyQuery($orgID: ID!) {
        listKPIRulesByOrg(orgID: $orgID) {
          items {
            title
            id
            valueUnit
            valueUnitPlural
          }
        }
      }
    `;
    API.graphql({
      query: myQuery,
      variables: {
        orgID: global.me.orgID,
      },
    }).then((res) => {
      console.log('res: ', res);
      const promisedRules = res.data.listKPIRulesByOrg.items.map((kpiRule) =>
        SummarizeKPIForAllRepository.fetchKPIs(
          startDate,
          endDate,
          interval,
          userId,
          kpiRule.id,
          kpiRule.title
        )
      );
      Promise.all(promisedRules).then((response) => {
        console.log('response: ', response);
        this.setState({
          charts: response,
          isLoading: false,
        });
      });
    });
  }

  DisplayChart(props) {
    const chart = props.chart.KPIRuleID;
    console.log('!!chart: ', chart);
    if (chart.buckets.length === 0) {
      console.log('0 length chart');
      return <></>;
    }
    const data = [];
    for (const interval of chart.buckets[0].actionsByInterval.buckets) {
      data[data.length] = {
        date: moment(new Date(interval.key)).format('M/D'),
        value: interval.val.value,
      };
    }
    console.log('data: ', data);
    return (
      <>
        <Grid
          item
          width={props.width}
          className={'chart-grid'}
          onClick={props.onClick}
        >
          <Typography className={'chart-title'} align={'center'}>
            {props.chart.title}
          </Typography>
          <VictoryChart theme={VictoryTheme.material} domainPadding={17}>
            <VictoryAxis
              style={{
                tickLabels: { fill: 'white' },
                grid: { strokeWidth: 0.3, strokeOpacity: 0.3 },
              }}
            />
            <VictoryAxis
              dependentAxis
              style={{
                tickLabels: { fill: 'white' },
                grid: { strokeWidth: 0.3, strokeOpacity: 0.3 },
              }}
            />
            <VictoryBar
              className={'victory-bar'}
              style={{ data: { fill: '#827ffc' } }}
              alignment="middle"
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
              barRatio={0.75}
              data={data}
              x="date"
              y="value"
            />
          </VictoryChart>
        </Grid>
      </>
    );
  }

  render() {
    if (this.state.isLoading) {
      this.loadData(this.state);
    }
    const translation = this.props.t;
    if (this.state.isLoading) {
      return <CenteredProgress />;
    }
    if (this.state.charts.length === 0) {
      return (
        <CenteredComponent>
          <Typography variant="h5">{translation('noLeaderboards')}</Typography>
        </CenteredComponent>
      );
    }
    return (
      <Box>
        {this.state.zoomChart ? (
          <Grid container className={'chart-overlay'}>
            <this.DisplayChart
              chart={this.state.zoomChart}
              width={800}
              onClick={() => {
                this.setState({
                  isLoading: false,
                  charts: this.state.charts,
                  zoomChart: null,
                });
              }}
            />
          </Grid>
        ) : (
          <Box className={'chart-overlay'}>
            <Box>
              <Grid
                container
                className={'chart-grid-overlay'}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {this.state.charts.map((chart, i) => (
                  <this.DisplayChart
                    chart={chart}
                    key={i}
                    width={'30%'}
                    onClick={() => {
                      this.setState({
                        isLoading: false,
                        charts: this.state.charts,
                        zoomChart: chart,
                      });
                    }}
                  />
                ))}
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
}

export default withTranslation('', {
  keyPrefix: 'routerComponents.home.index',
})(withRouter(withUseParams(withUseSearchParams(DisplayCharts))));
