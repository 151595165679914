import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import getUserAvatar from '../../common/utils/getUserAvatar';

const fetchPeople = async (ourSearchString, allOption, hideAssigned) => {
  //   console.log('fetchPeople: ', ourSearchString);

  const mySearchString = ourSearchString + '*';

  try {
    const myQuery = gql`
      query MyQuery($orgID: ID!, $searchString: String!, $nextToken: String) {
        searchUsers(
          filter: {
            name: { wildcard: $searchString }
            orgID: { eq: $orgID }
            isDeleted: { eq: false }
          }
          limit: 999
          sort: { direction: asc, field: name }
          nextToken: $nextToken
        ) {
          items {
            id
            name
            imageName
            imageType
            status
          }
          nextToken
        }
      }
    `;
    let usersList = [];
    let usersNextToken = null;
    do {
      const userData = await API.graphql({
        query: myQuery,
        variables: {
          orgID: global.me.orgID,
          searchString: mySearchString,
          nextToken: usersNextToken,
        },
      });

      // console.log('userData: ', userData);
      usersList = [...usersList, ...(userData.data?.searchUsers?.items || [])];
      usersNextToken = userData.data?.searchUsers?.nextToken;
    } while (usersNextToken);

    const assigneeList = [];
    if (allOption) {
      assigneeList[0] = {
        label: 'All ' + global.appSettings.labels.person.plural,
        value: 'all',
      };
    }

    for (const user of usersList) {
      //   console.log('!!!user: ', user);
      if (user.status === 'active') {
        const avatarFile = await getUserAvatar(
          user.imageName,
          user.imageType,
          true
        );

        assigneeList[assigneeList.length] = {
          value: user.id,
          label: user.name,
          avatarFile,
          imageName: user.imageName,
          imageType: user.imageType,
        };
      }
    }

    // console.log('!!!hideAssigned: ', hideAssigned);
    if (hideAssigned !== undefined) {
      // console.log('!!!filtering!!!!');
      for (const item of assigneeList) {
        if (hideAssigned.includes(item.value)) {
          // console.log('!!item.value: ', item.value);
          item.selectable = false;
          item.disabled = true;
          item.labelStyle = { color: 'lightgray' };
        }
      }
    }

    return assigneeList;
  } catch (err) {
    console.error('fetchPeople(): error fetching People in org: ', err.message);
  }
};

export default fetchPeople;
