import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  margin-top: 60px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

export const BracketTitle = styled.div`
  // Mobile
  background-color: #202124;
  color: #fff;
  padding: 20px;
  margin: 0 20px;
  display: inline-block;
  width: 260px;

  @media (min-width: 768px) {
    width: 300px;
  }
`;

export const BracketReward = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

export const SeedValue = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const SeedValueImage = styled.img`
  border-radius: 100%;
  width: 36px;
  height: 36px;
  object-fit: cover;
`;

export const SeedValueText = styled.div`
  font-weight: 500;
`;
