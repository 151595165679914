import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Typography } from '@mui/material';

import {
  AddModalContainer,
  AddModalIcon,
  AddModalItem,
  AddModalItemLeftSide,
  AddModalItemRightSide,
  AddModalItemTitle,
  AddModalTitle,
  ButtonsContainer,
  CreateNewButton,
  ErrorContainer,
  GamesAddIcon,
  GamesAddIconContainer,
  GamesDimensionTypeContainer,
  GamesDimensionTypeTab,
  HeaderContainer,
  LoadingContainer,
  NoGameContainer,
  NoGameIcon,
  NoGameText,
  PreviousNextButton,
  StatusFilterContainer,
  StatusFilterTab,
  StatusFilterTabIndicator,
  StatusFilterTabText,
  ViewContainer,
} from './Games.styles';
import CenteredProgress from '../../components/CenteredProgress';
import GameDetails from './GameDetails';
import GameDimensionTypeValues from './_types/GameDimensionTypeValues';
import GamesState from './_types/GamesState';

interface Props {
  showAdd: boolean;
  gamesState: GamesState;
  onChangeGamesState: (gamesState: GamesState) => void;
}

const GamesView = ({ showAdd, gamesState, onChangeGamesState }: Props) => {
  const navigate = useNavigate();
  const [openAddModal, setOpenAddModal] = useState(false);
  const addModalChoices = [
    {
      label: 'New Game',
      icon: '/images/new-game.svg',
      onClick: () => navigate('/performance/games/new-game'),
      disabled: false,
    },
    {
      label: 'New Incentive',
      icon: '/images/new-incentive.svg',
      onClick: () => navigate('/performance/games/new-incentive'),
      disabled: true,
    },
  ];

  return (
    <React.Fragment>
      {/* <img
        width="100%"
        style={{ position: 'absolute', zIndex: -1 }}
        src="/images/kpi-background.svg"
      /> */}
      <ViewContainer>
        <HeaderContainer>
          <GamesAddIconContainer>
            {showAdd && (
              <GamesAddIcon
                src="/images/games-add.svg"
                onClick={() => setOpenAddModal(!openAddModal)}
              />
            )}
            {openAddModal && (
              <AddModalContainer>
                <AddModalTitle>Create</AddModalTitle>
                {addModalChoices.map((choice) => {
                  return (
                    <AddModalItem
                      key={choice.label}
                      onClick={() => {
                        if (choice.disabled) return;

                        choice.onClick();
                      }}
                    >
                      <AddModalItemLeftSide>
                        <AddModalIcon>
                          <img
                            width={16}
                            src={choice.icon}
                            alt={choice.label}
                          />
                        </AddModalIcon>
                        <AddModalItemTitle disabled={choice.disabled}>
                          {choice.label}
                        </AddModalItemTitle>
                      </AddModalItemLeftSide>
                      <AddModalItemRightSide>
                        {choice.disabled && (
                          <div
                            style={{
                              color: '#FF3535',
                              fontWeight: 600,
                              fontSize: 12,
                            }}
                          >
                            Coming soon!
                          </div>
                        )}
                        {!choice.disabled && (
                          <img
                            width={22}
                            src="/images/chevron-right.svg"
                            alt="Chevron right"
                          />
                        )}
                      </AddModalItemRightSide>
                    </AddModalItem>
                  );
                })}
              </AddModalContainer>
            )}
          </GamesAddIconContainer>

          <Box
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-evenly',
            }}
          >
            <GamesDimensionTypeContainer>
              <GamesDimensionTypeTab
                onClick={() => {
                  onChangeGamesState({
                    ...gamesState,
                    gameDimensionType: GameDimensionTypeValues.INDIVIDUAL,
                    currentGameIndex: 0,
                  });
                }}
                isActive={
                  gamesState.gameDimensionType ===
                  GameDimensionTypeValues.INDIVIDUAL
                }
              >
                Individual
              </GamesDimensionTypeTab>
              <GamesDimensionTypeTab
                onClick={() => {
                  onChangeGamesState({
                    ...gamesState,
                    gameDimensionType: GameDimensionTypeValues.TEAM,
                    currentGameIndex: 0,
                  });
                }}
                isActive={
                  gamesState.gameDimensionType === GameDimensionTypeValues.TEAM
                }
              >
                Team
              </GamesDimensionTypeTab>
            </GamesDimensionTypeContainer>

            {gamesState.games.length > 0 ? (
              // Do not show if both are disabled
              gamesState.currentGameIndex === 0 &&
              gamesState.currentGameIndex ===
                gamesState.games.length - 1 ? null : (
                <ButtonsContainer>
                  <PreviousNextButton
                    disabled={gamesState.currentGameIndex === 0}
                    onClick={() => {
                      if (gamesState.currentGameIndex === 0) {
                        return;
                      }

                      onChangeGamesState({
                        ...gamesState,
                        currentGameIndex: gamesState.currentGameIndex - 1,
                      });
                    }}
                  >
                    {'<'}
                  </PreviousNextButton>
                  <PreviousNextButton
                    disabled={
                      gamesState.currentGameIndex ===
                      gamesState.games.length - 1
                    }
                    onClick={() => {
                      if (
                        gamesState.currentGameIndex ===
                        gamesState.games.length - 1
                      ) {
                        return;
                      }

                      onChangeGamesState({
                        ...gamesState,
                        currentGameIndex: gamesState.currentGameIndex + 1,
                      });
                    }}
                  >
                    {'>'}
                  </PreviousNextButton>
                </ButtonsContainer>
              )
            ) : (
              <></>
            )}
            <Box
              style={{
                display: 'flex',
                width: 291.2,
                justifyContent: 'center',
              }}
            >
              <StatusFilterContainer>
                <StatusFilterTab>
                  <StatusFilterTabText
                    onClick={() => {
                      onChangeGamesState({
                        ...gamesState,
                        showCompleted: false,
                        currentGameIndex: 0,
                      });
                    }}
                    isActive={gamesState.showCompleted === false}
                  >
                    Current
                  </StatusFilterTabText>
                  <StatusFilterTabIndicator
                    isActive={gamesState.showCompleted === false}
                  />
                </StatusFilterTab>
                <StatusFilterTab>
                  <StatusFilterTabText
                    onClick={() => {
                      onChangeGamesState({
                        ...gamesState,
                        showCompleted: true,
                        currentGameIndex: 0,
                      });
                    }}
                    isActive={gamesState.showCompleted === true}
                  >
                    Completed
                  </StatusFilterTabText>
                  <StatusFilterTabIndicator
                    isActive={gamesState.showCompleted === true}
                  />
                </StatusFilterTab>
              </StatusFilterContainer>
            </Box>
          </Box>
        </HeaderContainer>
        {gamesState.loading ? (
          <LoadingContainer>
            <CenteredProgress />
          </LoadingContainer>
        ) : gamesState.error ? (
          <ErrorContainer>
            <Typography fontSize={24}>
              Something went wrong fetching the data. Please try again later.
            </Typography>
          </ErrorContainer>
        ) : gamesState.games.length > 0 ? (
          <>
            <GameDetails game={gamesState.games[gamesState.currentGameIndex]} />
          </>
        ) : (
          <NoGameContainer>
            <NoGameIcon src="/images/no-games.svg" />
            <NoGameText>No games created yet</NoGameText>
            <CreateNewButton
              onClick={() => {
                navigate('/performance/games/new-game');
              }}
            >
              Create new
            </CreateNewButton>
          </NoGameContainer>
        )}
      </ViewContainer>
    </React.Fragment>
  );
};

export default GamesView;
