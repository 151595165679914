import gql from 'graphql-tag';
import { API } from 'aws-amplify';

// NOTE: to be used in scheduling
const fetchAppointments = async (type, id, setAppointmentsExist) => {
  const appointmentListByUserQuery = gql`
    query MyQuery($userID: ID!) {
      listAppointmentByUser(
        userID: $userID
        filter: { status: { eq: scheduled } }
        sortDirection: ASC
      ) {
        nextToken
        items {
          id
          appointmentDate
          appointmentStartTime
          appointmentEndTime
          notes
          isDeleted
          dealID
          deal {
            id
            name
            categoryID
            currentStageID
            isDeleted
          }
          user {
            id
            name
          }
          setter {
            id
            name
          }
        }
      }
    }
  `;

  const appointmentListBySetterQuery = gql`
    query MyQuery($setterID: ID!) {
      listAppointmentBySetter(
        setterID: $setterID
        filter: { status: { eq: scheduled } }
        sortDirection: ASC
      ) {
        nextToken
        items {
          id
          appointmentDate
          appointmentStartTime
          appointmentEndTime
          notes
          isDeleted
          dealID
          deal {
            id
            name
            categoryID
            currentStageID
            isDeleted
          }
          user {
            id
            name
          }
        }
      }
    }
  `;
  const appointmentListByDealQuery = gql`
    query MyQuery($dealID: ID!) {
      listAppointmentByDeal(dealID: $dealID, sortDirection: ASC) {
        nextToken
        items {
          id
          appointmentDate
          appointmentStartTime
          appointmentEndTime
          notes
          isDeleted
          dealID
          status
          deal {
            id
            name
            categoryID
            currentStageID
            isDeleted
          }
          user {
            id
            name
          }
          setter {
            id
            name
          }
        }
      }
    }
  `;

  try {
    // console.log('!!!functionalRoles: ', global.me.functionalRoles);
    let appointments;
    //
    //  Default to the setter schedule and don't show the closer schedule
    //
    const showCloser =
      global.me.isAdmin ||
      global.me.functionalRoles?.includes('closer') ||
      global.me.functionalRoles?.includes('manager');

    let viewSchedule = 'setter';
    if (type === 'mine' && showCloser) {
      viewSchedule = 'closer';
    }

    if (type === 'deal') {
      //
      //  We're looking for closer appointments
      //
      const res = await API.graphql({
        query: appointmentListByDealQuery,
        variables: { dealID: id },
      });
      console.log(
        '!!!appointments deal: ',
        res.data.listAppointmentByDeal.items
      );
      if (setAppointmentsExist) {
        //
        //  Set the appointmentExist state in the caller
        //
        let appointmentsScheduled = false;
        for (const appointment of res.data.listAppointmentByDeal.items) {
          if (appointment.status === 'scheduled') {
            appointmentsScheduled = true;
          }
        }
        setAppointmentsExist(appointmentsScheduled);
      }
      appointments = res.data.listAppointmentByDeal.items;
    } else if (viewSchedule === 'setter') {
      //
      //  We're looking for setter appointments
      //
      const res = await API.graphql({
        query: appointmentListBySetterQuery,
        variables: { setterID: id },
      });
      console.log(
        '!!!appointments setter: ',
        res.data.listAppointmentBySetter.items
      );
      appointments = res.data.listAppointmentBySetter.items;
    } else if (viewSchedule === 'closer') {
      //
      //  We're looking for closer appointments
      //
      const res = await API.graphql({
        query: appointmentListByUserQuery,
        variables: { userID: id },
      });
      console.log(
        '!!!appointments mine: ',
        res.data.listAppointmentByUser.items
      );
      appointments = res.data.listAppointmentByUser.items;
    }

    // // Filter out deleted appointments with deleted deals? Will just not redirect on rewardCard click if deal is deleted for now...
    // const filteredAppointments = appointments.filter((appointment) => {
    //   console.log('filter appointment: ', appointment);
    //   console.log(
    //     'filter !appointment.deal.isDeleted: ',
    //     !appointment.deal.isDeleted
    //   );
    //   return !appointment.deal.isDeleted;
    // });
    // return filteredAppointments;
    return appointments;
  } catch (err) {
    console.error('fetchAppointments error: ', err);
  }
};

export default fetchAppointments;
