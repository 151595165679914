import React from 'react';
import {
  MissionContainer,
  Team,
  ViewContainer,
} from './DisplayBracketSeed.styles';
import QuestMission from '../../_types/QuestMission';
import { useNavigate } from 'react-router';

interface Props {
  brackets: QuestMission[];
}

const DisplayBracketSeedView = ({ brackets }: Props) => {
  const navigate = useNavigate();

  return (
    <ViewContainer>
      {brackets.map((bracket, index: number) => {
        const firstTeam = bracket.mission.circles.items[0];
        const secondTeam = bracket.mission.circles.items[1];

        return (
          <MissionContainer key={index}>
            <Team>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/profile/${firstTeam?.circle.id}`)}
              >
                {firstTeam?.circle.name}
              </div>
              <div>{firstTeam?.doneActionCount}</div>
            </Team>
            <div>|</div>
            <Team>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/profile/${secondTeam?.circle.id}`)}
              >
                {secondTeam?.circle.name}
              </div>
              <div>{secondTeam?.doneActionCount}</div>
            </Team>
          </MissionContainer>
        );
      })}
    </ViewContainer>
  );
};

export default DisplayBracketSeedView;
