import React, { useRef, useState } from 'react';
import {
  InputBodyContainer,
  InputTitleContainer,
  InputTitleIcon,
  InputTitleText,
} from '../../NewGame.styles';
import {
  AddImageArea,
  AddImageAreaText,
  FilePreviewCloseIcon,
  FilePreviewContainer,
  ViewContainer,
} from './ImageThumbnail.styles';
import NewGameState from '../../_types/NewGameState';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const ImageThumbnailView: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
}) => {
  const uploadRef = useRef<any>();
  const [filePreview, setFilePreview] = useState<any>(
    newGameState.imageFile ? URL.createObjectURL(newGameState.imageFile) : null
  );

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      try {
        const selectedFile = files[0];
        const filePreview = URL.createObjectURL(selectedFile);

        onChangeNewGameState({
          ...newGameState,
          imageType: 'image',
          imageName: selectedFile.name,
          imageFile: selectedFile,
        });

        setFilePreview(filePreview);
      } catch (error) {}
    }
  };

  return (
    <ViewContainer>
      <InputTitleContainer>
        <InputTitleText>Image/Thumbnail</InputTitleText>
        <InputTitleIcon src="/images/thumbnail.svg" />
      </InputTitleContainer>
      <InputBodyContainer>
        <AddImageArea
          shouldAjustHeight={Boolean(filePreview)}
          onClick={() => uploadRef.current.click()}
        >
          {filePreview ? (
            <FilePreviewContainer>
              <FilePreviewCloseIcon
                onClick={(e) => {
                  e.stopPropagation();
                  uploadRef.current.value = '';
                  setFilePreview(null);
                }}
                src="/images/close.svg"
              />

              <img src={filePreview} alt="Preview" width="200" />
            </FilePreviewContainer>
          ) : null}
          <input
            onChange={(e) => onUpload(e)}
            style={{ display: 'none' }}
            accept="image/*"
            type="file"
            ref={(ref) => (uploadRef.current = ref)}
          />
          {!filePreview && <AddImageAreaText>Add Image</AddImageAreaText>}
        </AddImageArea>
      </InputBodyContainer>
    </ViewContainer>
  );
};

export default ImageThumbnailView;
