import React from 'react';
import {
  CustomInputContainer,
  CustomInputLabel,
  Item,
  Items,
} from './CustomInputs.styles';

interface Props {
  label: string;
  selectedItem: any;
  items: { label: string; value: any }[];
  onSelect: (newSelectedItem: { label: string; value: any }) => void;
  numColumns: number;
}

const ItemSelectInput: React.FC<Props> = ({
  label,
  items,
  onSelect,
  selectedItem,
  numColumns,
}) => {
  return (
    <CustomInputContainer>
      <CustomInputLabel>{label}</CustomInputLabel>
      <Items numColumns={numColumns}>
        {items.map((item, index) => {
          return (
            <Item
              key={index}
              onClick={() => onSelect(item)}
              isSelected={selectedItem === item.value}
            >
              {item.label}
            </Item>
          );
        })}
      </Items>
    </CustomInputContainer>
  );
};

export default ItemSelectInput;
