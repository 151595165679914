import { useEffect, useState } from 'react';
import NewGameState from '../_types/NewGameState';
import { useGetUserInformationQuery } from '../../../../store/api/GraphQlQuery';
import saveGame from '../../games-helpers/saveGame';
import { Storage } from 'aws-amplify';
import { GamifyToast } from '../../../../common/CustomToasts';
import { isBefore } from 'date-fns';

const INITIAL_STATE: NewGameState = {
  id: '',
  type: 'mission',
  name: '',
  description: '',
  contestType: 'regular',
  scope: 'person',
  isCircleOnly: false,
  isPublic: false,
  startDate: '',
  endDate: '',
  useMultipleKPIs: false,
  KPIRuleID: '',
  KPIRuleName: '',
  tieKPIRuleID: '',
  tieKPIRuleName: '',
  actionCount: '10',
  isActionCountCustom: false,
  assigneeIDs: [],
  rewardDisposition: 'winner',
  rewardWhen: 'mission',
  rewardType: '',
  rewardCurrency: 'reward',
  rewardID1: '',
  rewardName1: '',
  rewardID2: '',
  rewardName2: '',
  rewardID3: '',
  rewardName3: '',
  rewardIDp: '',
  rewardNameP: '',
  rewardID1t: '',
  rewardName1t: '',
  rewardID2t: '',
  rewardName2t: '',
  rewardID3t: '',
  rewardName3t: '',
  rewardIDpt: '',
  rewardNamePt: '',
  showCustomReward1: false,
  showCustomReward2: false,
  showCustomReward3: false,
  rewardLimit: '',
  imageName: '',
  imageType: '',
  imageFile: null,
  advanceType: true,
  automaticallyCreateGoals: true,
  createRecurringCampaigns: true,

  tags: [],
  action: '',
  actionCountType: 'count',
  actionCountTypeNum: 0,
  actionPastTense: '',
  actionType: '',
  actionTypePlural: '',
  assignToChildCircles: true,
  autoAssign: true,
  autoCreateEndDate: '',
  autoCreateStartDate: '',
  autoCreateTimeframe: 'W',
  autoCreateTimeframeCount: '1',
  autoGoalFactor: '100',
  autoGoalTimeframe: 'W',
  autoGoalTimeframeCount: '4',
  bracket: {
    roundValues: [],
    bracket: [],
    totalCompetitors: 2,
    roundSpecificRules: true,
    roundOver: 'after_time',
    nextRoundStarts: 'immediately',
    daysBeforeNextRound: 0,
    daysInRound: 0,
  },
  bracketName: '',
  categoryID: '',
  competitors: [],
  copiedMissionID: '',
  copiedMissionName: '',
  copyMission: false,
  createMission: false,
  flexFields: [],
  isDeleted: false,
  launchDate: '',
  location: '',
  managerPlay: true,
  minAutoGoal: '1.0',
  minGoal: '',
  missionRewardID1: '',
  missionRewardID1t: '',
  missionRewardID2: '',
  missionRewardID2t: '',
  missionRewardID3: '',
  missionRewardID3t: '',
  missionRewardIDp: '',
  missionRewardIDpt: '',
  nextMissionID: '',
  orgID: '',
  ownerID: '',
  ownerType: '',
  parentID: '',
  parentName: '',
  pickAssignee: false,
  questOrder: '',
  questType: '',
  rewardCount1: '',
  rewardCount1t: '',
  rewardCount2: '',
  rewardCount2t: '',
  rewardCount3: '',
  rewardCount3t: '',
  rewardCountP: '',
  roundGoalPrecision: '',
  rules: [],
  missionRules: [],
  search: '',
  topNumberAdvance: 0,
  trophies: [],
  tzOffset: -8,
  useAutoCreate: false,
  useAutoGoal: false,
  useDealStage: true,
  useStepCounter: false,
  totalRounds: 0,
  currentRound: 0,
};
const useNewGame = () => {
  const { data: userData, isLoading: loadingGetUserInformation } =
    useGetUserInformationQuery({});
  const [newGameState, setNewGameState] = useState<NewGameState>(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [errorMessages, setErrorMessages] = useState({
    name: '',
    startDate: '',
    endDate: '',
  });

  const isErrorPresent = Object.values(errorMessages).some(
    (message) => message !== ''
  );
  const [shouldDisableNextOrFinishButton, setShouldDisableNextOrFinishButton] =
    useState(isErrorPresent);

  const onChangeNewGameState = (newState: NewGameState) => {
    setNewGameState(newState);
  };

  const onSaveGame = async () => {
    console.log('onSaveGame newGameState: ', newGameState);
    try {
      setLoading(true);
      if (newGameState.imageFile) {
        await Storage.put(newGameState.imageFile.name, newGameState.imageFile, {
          contentType: newGameState.imageFile.type,
        });
      }

      const newGame = await saveGame(newGameState);
      GamifyToast.success('Successfully saved game');
      return newGame;
    } catch (error) {
      console.error(error);
      setError(error);
      GamifyToast.error('Something went wrong saving the game game');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeState = () => {
      if (loadingGetUserInformation || !userData) {
        return;
      }

      setNewGameState((prevState) => ({
        ...prevState,
        rewardCurrency: userData?.appSettings.entitlements.includes(
          'mission:reward:reward'
        )
          ? 'reward'
          : 'points',
      }));
    };

    initializeState();
  }, [loadingGetUserInformation]);

  useEffect(() => {
    const errors = validate();
    const isErrorPresent = Object.values(errors).some(
      (message) => message !== ''
    );

    setShouldDisableNextOrFinishButton(
      loading || !newGameState.name || isErrorPresent
    );
    setErrorMessages(errors);
  }, [
    loading,
    newGameState.name,
    newGameState.startDate,
    newGameState.endDate,
  ]);

  const validate = () => {
    const errors = { ...errorMessages };

    // Name validation
    if (newGameState.name.length < 3 && newGameState.name.length !== 0) {
      errors.name = 'Name should be at least 3 characters long';
    } else {
      errors.name = '';
    }

    // Start Date validation
    if (
      newGameState.startDate &&
      isBefore(new Date(newGameState.startDate), new Date())
    ) {
      errors.startDate = 'Start Date cannot be earlier than the current time';
    } else {
      errors.startDate = '';
    }

    // End Date validation
    if (
      newGameState.endDate &&
      newGameState.startDate &&
      isBefore(new Date(newGameState.endDate), new Date(newGameState.startDate))
    ) {
      errors.endDate = 'End Date should not be earlier than Start Date';
    } else {
      errors.endDate = '';
    }

    return errors;
  };

  return {
    newGameState,
    loading,
    error,
    errorMessages,
    onChangeNewGameState,
    onSaveGame,
    shouldDisableNextOrFinishButton,
    setShouldDisableNextOrFinishButton,
  };
};

export default useNewGame;
