import React from 'react';
import { Backdrop, Box, Typography } from '@mui/material';
import DisplayMedia from '../components/DisplayMedia';

export default function ImageCarousel(props) {
  const {
    showImageCarousel,
    setShowImageCarousel,
    imagesArr,
    imageIndex,
    setImageIndex,
    zIndex,
  } = props;

  return (
    <Backdrop
      sx={{ zIndex }}
      open={showImageCarousel}
      onClick={(e) => {
        e.stopPropagation();
        if (!e.target.closest('.navigation-button')) {
          setShowImageCarousel(false);
        }
      }}
    >
      <Box style={{ display: 'flex', height: '75vh' }}>
        {imagesArr.length > 0 && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              marginRight: 16,
            }}
          >
            <Box
              className={'navigation-button'}
              style={{
                backgroundColor: 'white',
                width: 30,
                height: 30,
                borderRadius: 1000,
              }}
              sx={{ cursor: imageIndex !== 0 ? 'pointer' : 'default' }}
              onClick={() => {
                if (imageIndex !== 0) setImageIndex(imageIndex - 1);
              }}
            >
              <img
                src={
                  imageIndex > 0
                    ? '/images/previous-page.svg'
                    : '/images/previous-page-disabled.svg'
                }
                style={{ height: '30px' }}
              />
            </Box>
          </Box>
        )}
        <Box
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: 'black',
            borderRadius: '8px',
          }}
        >
          <DisplayMedia
            imageName={imagesArr[imageIndex]}
            imageType={'img'}
            height={'100%'}
            width={'450px'}
            objectFit={'cover'}
            borderRadius={'0px'}
          ></DisplayMedia>
          <Box
            style={{
              position: 'absolute',
              top: '16px',
              right: '16px',
              backgroundColor: 'black',
              padding: '12px 16px 12px 16px',
              borderRadius: 1000,
            }}
          >
            <Typography style={{ color: 'white' }}>
              {`${imageIndex + 1} of ${imagesArr.length}`}
            </Typography>
          </Box>
        </Box>
        {imagesArr.length > 0 && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              marginLeft: 16,
            }}
          >
            <Box
              className={'navigation-button'}
              style={{
                backgroundColor: 'white',
                width: 30,
                height: 30,
                borderRadius: 1000,
              }}
              sx={{
                cursor:
                  imageIndex !== imagesArr.length - 1 ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (imageIndex !== imagesArr.length - 1)
                  setImageIndex(imageIndex + 1);
              }}
            >
              <img
                src={
                  imageIndex < imagesArr.length - 1
                    ? '/images/next-page.svg'
                    : '/images/next-page-disabled.svg'
                }
                style={{ height: '30px' }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Backdrop>
  );
}
