// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
//
// FormikHelpers
//

import React, { useState } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { defaults } from './Utilities';
import { NewSelectCircleOrPerson } from './PickerSearch';

// import { Formik, Field, Form, useField, useFormikContext } from 'formik';

//
// Helpers for making fancy fields -- move somewhere
//
export const FieldWrapper = ({
  children,
  label,
  formikProps,
  formikKey,
  hideLabels,
}) => (
  <Box>
    {defaults.displayLabels && label !== '' && !hideLabels && (
      <Typography color={'black'}>{label}</Typography>
    )}
    {children}
    <Typography style={{ color: 'red' }}>
      {formikProps.touched[formikKey] && formikProps.errors[formikKey]}
    </Typography>
  </Box>
);
export const StyledInput = ({
  label,
  formikProps,
  formikKey,
  onAtMention,
  onAtMentionID,
  value,
  pickUsers,
  pickCircles,
  hideLabels,
  ...rest
}) => {
  // LOG.debug("StyledInput: ", pickUsers, pickCircles);
  const [selectCircleOrPerson, setSelectCircleOrPerson] = useState(false);
  const [currValue, setCurrValue] = useState(value);
  const [currDisplayValue, setCurrDisplayValue] = useState([value]);
  const [atMentionPos, setAtMentionPos] = useState([]);

  // function fireDropdown () {
  //   // LOG.debug ("fireDropdown: ", len);
  //   // LOG.debug ("currValue.length = ", currValue.length);
  //   setSelectCircleOrPerson(true);
  // }

  // function handleChangeText (text, formikProps, formikKey) {
  //   // console.log("text: ", text);
  //   if (onAtMention && text.substring(text.length - 1) === '@') {
  //     fireDropdown(text.length);
  //   } else {
  //     // LOG.debug("atMentionPos: ", atMentionPos);
  //     if (atMentionPos.length > 0 && text.length === atMentionPos[atMentionPos.length - 1].end - 1) {
  //       // //LOG.debug ("@@@@backed over end");
  //       // LOG.debug ("setting currValue=", currValue.substring(0,atMentionPos[atMentionPos.length-1].start-1));
  //       setCurrValue(currValue.substring(0, atMentionPos[atMentionPos.length - 1].start - 1));
  //       // //LOG.debug ("currValue: ", currValue.substring(0,atMentionPos[atMentionPos.length-1].start-1));
  //       const ourCurrDisplayValue = currDisplayValue;
  //       // //LOG.debug ("ourCurrDisplayValue b4:  ", ourCurrDisplayValue);
  //       const popValue = ourCurrDisplayValue.pop();
  //       // //LOG.debug ("popValue: ", popValue);
  //       if (typeof (popValue) !== 'object') {
  //         ourCurrDisplayValue.pop();
  //         // //LOG.debug ("newPopValue: ", newPopValue);
  //       }
  //       // //LOG.debug ("ourCurrDisplayValue: ", ourCurrDisplayValue);
  //       setCurrDisplayValue(ourCurrDisplayValue);
  //       const ourAtMentionPos = atMentionPos;
  //       ourAtMentionPos.pop();
  //       // //LOG.debug ("ourAtMentionPos: ", ourAtMentionPos);
  //       setAtMentionPos(ourAtMentionPos);
  //     } else {
  //       // LOG.debug("!!!setting curValue = ", text);
  //       setCurrValue(text);
  //       const ourDisplayArr = [];
  //       // //LOG.debug ("@@@length: ", currDisplayValue.length);
  //       for (const e of currDisplayValue) {
  //         // LOG.debug("@@@type e: ", typeof (e));
  //         ourDisplayArr[ourDisplayArr.length] = e;
  //       }
  //       // //LOG.debug ("@@@type: ", typeof (ourDisplayArr[ourDisplayArr.length-1]));
  //       let curTextPos = 0;
  //       if (atMentionPos.length > 0) {
  //         curTextPos = atMentionPos[atMentionPos.length - 1].end;
  //       }
  //       if (ourDisplayArr.length === 0) {
  //         ourDisplayArr[ourDisplayArr.length] = text.substring(curTextPos);
  //       } else if (typeof (ourDisplayArr[ourDisplayArr.length - 1]) === 'object') {
  //         ourDisplayArr[ourDisplayArr.length] = text.substring(curTextPos);
  //       } else {
  //         ourDisplayArr[ourDisplayArr.length - 1] = text.substring(curTextPos);
  //       }
  //       // LOG.debug("ourDisplayArr: ", ourDisplayArr);
  //       // LOG.debug("text: ", text);
  //       setCurrDisplayValue(ourDisplayArr);
  //       formikProps.setFieldValue(formikKey, text);
  //     }
  //   }
  // }

  function addAtMentionName(name) {
    const ourDisplayArr = [];
    for (const e of currDisplayValue) {
      // //LOG.debug ("!!!type e: ", typeof (e));
      ourDisplayArr[ourDisplayArr.length] = e;
    }
    ourDisplayArr[ourDisplayArr.length] = (
      <Typography key={currValue.length} style={{ color: 'red' }}>
        {name}
      </Typography>
    );
    setCurrDisplayValue(ourDisplayArr);
    setAtMentionPos(
      atMentionPos.concat({
        start: currValue.length,
        end: currValue.length + name.length,
      })
    );
  }

  // if (formikProps.touched[formikKey] && formikProps.errors[formikKey]) {
  //   styles.inputStyles.borderColor = 'red';
  // }

  let currDisp;
  if (onAtMention) {
    currDisp = currDisplayValue;
  } else {
    currDisp = value;
  }

  const inputProps = {
    className: 'campaign-styled-bubble',
  };

  return (
    <FieldWrapper
      label={label}
      formikKey={formikKey}
      formikProps={formikProps}
      hideLabels={hideLabels}
    >
      {/* <TextField */}
      {/*  placeholderTextColor="gray" */}
      {/*  onChangeText={(text) => { */}
      {/*    if (onAtMention) { */}
      {/*      handleChangeText(text, formikProps, formikKey); */}
      {/*    } else { */}
      {/*      formikProps.setFieldValue(formikKey, text); */}
      {/*    } */}
      {/*  }} */}
      {/*  onBlur={formikProps.handleBlur(formikKey)} */}
      {/*  {...rest} */}
      {/* > */}
      {/*  {currDisp} */}
      {/* </TextField> */}
      {/*:*/}
      <TextField
        inputProps={inputProps}
        onChange={(event) => {
          const value = event.target.value;
          // LOG.info("value: ", value)
          formikProps.setFieldValue(formikKey, value);
        }}
        onBlur={formikProps.handleBlur(formikKey)}
        value={currDisp}
        fullWidth={true}
        {...rest}
      />

      {selectCircleOrPerson && (
        <>
          {pickCircles && !pickUsers && (
            <NewSelectCircleOrPerson
              pickUsers={pickUsers}
              pickCircles={pickCircles}
              open={true}
              autoFocus={true}
              hideCircleLevels={global.appSettings.features.circle.hideLevels}
              width={300}
              filter={global.me.orgID}
              value={''}
              fieldSetter={(pickerValue, name) => {
                // LOG.debug("!!pickerValue: ", pickerValue + ", pickerName: " + name + ", id:" + id);
                if (name !== '') {
                  onAtMentionID(pickerValue, name, 'circle');
                  formikProps.setFieldValue(formikKey, name);
                  addAtMentionName('@' + name);
                  setCurrValue(currValue + name);
                  formikProps.setFieldValue(
                    formikKey,
                    formikProps.values[formikKey] + '@' + name
                  );
                }
                setSelectCircleOrPerson(false);
              }}
            />
          )}
          {pickUsers && !pickCircles && (
            <NewSelectCircleOrPerson
              pickUsers={pickUsers}
              pickCircles={pickCircles}
              open={true}
              autoFocus={true}
              width={300}
              filter={global.me.orgID}
              value={''}
              fieldSetter={(pickerValue, name) => {
                // LOG.debug("!!pickerValue: ", pickerValue + ", pickerName: " + name + ", id:" + id);
                if (name !== '') {
                  onAtMentionID(pickerValue, name, 'person');
                  formikProps.setFieldValue(formikKey, name);
                  addAtMentionName('@' + name);
                  setCurrValue(currValue + name);
                  formikProps.setFieldValue(
                    formikKey,
                    formikProps.values[formikKey] + '@' + name
                  );
                }
                setSelectCircleOrPerson(false);
              }}
            />
          )}
          {pickUsers && pickCircles && (
            <NewSelectCircleOrPerson
              open={true}
              autoFocus={true}
              pickUsers={pickUsers}
              pickCircles={pickCircles}
              width={300}
              filter={global.me.orgID}
              value={''}
              fieldSetter={(pickerValue, name, type) => {
                // LOG.debug("!!pickerValue: ", pickerValue + ", pickerName: " + name + ", type:" + type);
                if (name !== '') {
                  onAtMentionID(pickerValue, name, type);
                  formikProps.setFieldValue(formikKey, name);
                  addAtMentionName('@' + name);
                  setCurrValue(currValue + name);
                  formikProps.setFieldValue(
                    formikKey,
                    formikProps.values[formikKey] + '@' + name
                  );
                }
                setSelectCircleOrPerson(false);
              }}
            />
          )}
        </>
      )}
    </FieldWrapper>
  );
};
