import { styled } from '@mui/material/styles';
import {
  TextField,
  // MenuItem,
  // Select,
  InputLabel,
  Button,
  OutlinedInput,
  // Autocomplete,
} from '@mui/material';
export const StyledTextField = styled(OutlinedInput)`
  .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  gap: 10px;
  // width: 189px;
  height: 30px;
  background: rgb(238, 238, 238, 0.5);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: black;
  //styleName: Title semibold 16px;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  cursor: pointer;

  :hover {
    background-color: rgb(238, 238, 238, 0.5);
  }
`;

export const SubmitButton = styled(StyledButton)`
  background-color: #635ed1;
  color: white;
  :hover {
    background-color: rgb(99, 94, 209, 0.7);
  }
  font-size: 16px;
`;

export const Title = styled('h1')`
  font-family: Manrope;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.01em;
  text-align: left;
  color: black;
  margin: 0;
`;

export const Subtitle = styled('p')`
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: 0;
  color: #868686;
`;

export const StyledInputLabel = styled(InputLabel)({
  fontFamily: 'Source Sans Pro',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '-0.01em',
  textAlign: 'left',
  marginBottom: '-10px',
});

export const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    borderRadius: 8,
    border: '1px solid #D5D5D5',
    padding: theme.spacing(1.5),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

// NOTE: Commented out components moved to common folder's StyledComponents

// export const StyledSelect = styled(Select)(({ theme }) => ({
//   '& .MuiOutlinedInput-input': {
//     borderRadius: 8,
//     border: '1px solid #D0D2D8',
//     padding: theme.spacing(1.5),
//   },
//   '& .MuiOutlinedInput-notchedOutline': {
//     border: 'none',
//   },
//   '&:hover .MuiOutlinedInput-notchedOutline': {
//     border: 'none',
//   },
//   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//     border: 'none',
//   },
// }));

// export const StyledMenuItem = styled(MenuItem)(() => ({
//   fontFamily: 'Source Sans Pro',
//   fontSize: '16px',
//   fontWeight: 400,
//   lineHeight: '24px',
//   letterSpacing: '-0.01em',
//   color: 'black',
//   backgroundColor: 'white',
//   '&:hover': {
//     backgroundColor: '#FAC8B2',
//   },
//   '&.Mui-selected': {
//     backgroundColor: '#F0F0F3',
//     '&.Mui-focusVisible': { background: '#F0F0F3' },
//   },
//   '&.Mui-selected:hover': {
//     backgroundColor: '#F0F0F3',
//   },
//   '&:first-of-type': {
//     borderTopLeftRadius: '5px',
//     borderTopRightRadius: '5px',
//   },
//   '&:last-of-type': {
//     borderBottomLeftRadius: '5px',
//     borderBottomRightRadius: '5px',
//   },
// }));

// export const StyledAutocomplete = styled(Autocomplete)(() => ({
//   '& .MuiOutlinedInput-root': {
//     borderRadius: 5,
//     borderColor: 'grey',
//     '& fieldset': {
//       borderColor: 'grey',
//     },
//     '&:hover fieldset': {
//       borderColor: 'grey',
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: 'grey',
//     },
//   },
//   '& .MuiOutlinedInput-input': {
//     padding: '9.5px 4px',
//   },
//   '& .MuiAutocomplete-clearIndicator': {
//     display: 'none',
//   },
// }));

export const StyledSelectLabel = styled(StyledInputLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  transform: 'none',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '-0.01em',
  textAlign: 'left',
}));

export const SubsectionTitle = styled('p')`
  font-family: Manrope;
  font-size: 16px;
  color: #868686;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.09em;
  text-align: left;
  margin: 0;
`;

export const LoadMoreButton = styled(Button)(() => ({
  color: 'white',
  backgroundColor: 'transparent',
  border: '2px solid white',
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'white',
  },
  marginRight: '16px',
  paddingTop: '10px',
  paddingBottom: '10px',

  fontFamily: 'Manrope',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '-0.01em',
  textAlign: 'left',
  width: '150px',
}));

export const AddNewButton = styled(StyledButton)`
  color: white;
  background: #ff6a00;

  :hover {
    background-color: #5f17cd;
  }
`;
export const SearchInput = styled(TextField)(({ theme }) => ({
  width: '312px',
  height: '48px',
  border: '1px solid #D0D2D8',
  borderRadius: 8,
  marginBottom: '11px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1.5),

  '& .MuiOutlinedInput-root': {
    // background: '#222428',
    backgroundColor: 'transparent',
  },

  '& .MuiOutlinedInput-input': {
    padding: theme.spacing(1.5),
    color: '#222428',
    fontFamily: 'Source Sans Pro',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    textAlign: 'left',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-input::placeholder': {
    color: '#0B0B0C',
  },
}));

export const PageButton = styled(Button)`
  color: ${({ isSelected }) => (isSelected ? '#FF6A00' : 'gray')};

  margin: 0 10px;
  width: 20px;
  height: 60px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 2px;
  border: 2px solid ${({ isSelected }) => (isSelected ? '#FF6A00' : 'gray')};

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? '#FF6A00' : 'transparent'};
  }
`;
// export const PageButton = styled(Button)`
//   color: ${({ isSelected }) => (isSelected ? '#FF6A00' : 'gray')};
//   font-weight: bold;
//   font-size: 16px;
//   border-radius: 2px;
//   background-color: ${({ isSelected }) =>
//     isSelected ? '#FF6A00' : 'transparent'};
//   &:hover {
//     background-color: ${({ isSelected }) =>
//       isSelected ? '#FF6A00' : 'transparent'};
//   }
// `;

export const NavigationButton = styled(Button)`
  &:disabled {
    color: #898989;
  }
`;
