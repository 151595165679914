import React from 'react';
import About from '../new-game-inputs/About';
import NewGameState from '../_types/NewGameState';
import ErrorMessagesState from '../_types/ErrorMessagesState';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  errorMessages: ErrorMessagesState;
}

const Step1: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
  errorMessages,
}) => {
  return (
    <>
      <About
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
        errorMessages={errorMessages}
      />
    </>
  );
};

export default Step1;
