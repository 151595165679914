import React from 'react';
import { CircularProgress } from '@mui/material';
import CenteredComponent from './CenteredComponent';

export default class CenteredProgress extends React.Component {
  render() {
    return (
      <CenteredComponent>
        <CircularProgress color="application" size={60} />
      </CenteredComponent>
    );
  }
}
