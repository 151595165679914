import { styled } from '@mui/material/styles';
import { Autocomplete, Select, MenuItem, Menu } from '@mui/material';

export const CustomMenu = styled(Menu)(() => ({
  zIndex: 1000000, // Needs to be higher than form modals zIndex (100000)
  '& .MuiPaper-root': {
    padding: 0,
    minWidth: 200,
    backgroundColor: 'white',
    border: '1px solid #D0D2D8',
    borderRadius: '8px',
    boxShadow: '0px 4px 24px 0px #ABABAB5E !important',
  },
  '& .MuiList-root': {
    padding: 0,
  },
}));
export const CustomMenuItem = styled(MenuItem)(() => ({
  fontFamily: 'Manrope, sans-serif',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '-0.01em',
  borderBottom: '0.5px solid #11111140',
  color: 'black',
  '&:hover': {
    backgroundColor: '#FAC8B2',
  },
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    border: 0,
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    borderRadius: 8,
    border: '1px solid #D0D2D8',
    padding: theme.spacing(1.5),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '-0.01em',
  color: 'black',
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: '#FAC8B2',
  },
  '&.Mui-selected': {
    backgroundColor: '#F0F0F3',
    '&.Mui-focusVisible': { background: '#F0F0F3' },
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#F0F0F3',
  },
  '&:first-of-type': {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
}));

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 5,
    borderColor: 'grey',
    '& fieldset': {
      borderColor: 'grey',
    },
    '&:hover fieldset': {
      borderColor: 'grey',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'grey',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '9.5px 4px',
  },
  '& .MuiAutocomplete-clearIndicator': {
    display: 'none',
  },
}));
