import React from 'react';
import NewGameState from '../../_types/NewGameState';
import BracketView from './Bracket.view';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const Bracket: React.FC<Props> = ({ newGameState, onChangeNewGameState }) => {
  return (
    <BracketView
      newGameState={newGameState}
      onChangeNewGameState={onChangeNewGameState}
    />
  );
};

export default Bracket;
