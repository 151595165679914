import { API } from 'aws-amplify';
import gql from 'graphql-tag';

export default async function fetchDealTypeConfigs(dealTypes) {
  const getFlexAttributes = gql`
    query MyQuery($categoryID: ID!) {
      getStageCategory(id: $categoryID) {
        flexAttributes
      }
    }
  `;

  const mapDealTypeToConfig = {};

  // console.log('dealTypeNamesAndIds: ', dealTypeNamesAndIds);
  for (const dealType of dealTypes) {
    const dealTypeId = dealType.id;
    // const dealTypeTitle = dealType.title;

    const dealTypesFlexAttributesRes = await API.graphql({
      query: getFlexAttributes,
      variables: { categoryID: dealTypeId },
    });

    const dealTypeFlexAttributes = JSON.parse(
      dealTypesFlexAttributesRes.data.getStageCategory.flexAttributes
    );

    // NOTE: Eventually need to switch this to the id for a better map... will need DealList and SelectedDealDisplay updated to work with this change too
    mapDealTypeToConfig[dealTypeId] = dealTypeFlexAttributes.config.deal;
  }

  return mapDealTypeToConfig;
}
