export default function featureFlag(feature) {
  const featureFlags = global?.appSettings?.featureFlags;
  const isFeatureEnabled = global?.appSettings?.featureFlags?.[feature] || '';

  if (!featureFlags) {
    //
    //  IF the customer has no feature flags defined, then the answer is always false
    //
    return false;
  }

  if (isFeatureEnabled === undefined) {
    return false;
  }
  //
  //  If everyone has the feature, then the we have the feature
  //
  if (isFeatureEnabled.includes('everyone')) {
    return true;
  }
  //
  //  If not everyone has the feature, then we have to be in the "whitelist" to get it
  //
  return isFeatureEnabled.includes(global?.me?.email);
}
