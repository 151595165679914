import { API } from 'aws-amplify';
import MyPerson from '../_types/MyPerson';

interface GetMyPersonsResponse {
  data: {
    searchUsers: {
      items: MyPerson[];
    };
  };
}

export const SEARCH_USERS_QUERY = `
query MyQuery($orgID: ID!) {
  searchUsers(
    filter: { name: { wildcard: "*" }, orgID: { eq: $orgID } }
    limit: 999
    sort: { direction: asc, field: name }
  ) {
    items {
      id
      name
      imageName
      imageType
      initials
      title
      status
    }
    nextToken
  }
}
`;

const getMyPersons = async (args: { orgID: string }) => {
  const { orgID } = args;

  const result = (await API.graphql({
    query: SEARCH_USERS_QUERY,
    variables: {
      orgID,
    },
  })) as GetMyPersonsResponse;

  const myPersons = result.data.searchUsers.items.filter(
    (item) => item.status === 'active'
  );

  return myPersons;
};

export default getMyPersons;
