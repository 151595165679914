// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
//
// SelectFromList
//

import React from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import '../../routes/scorecard/Index.css';

export function SelectScorecardTimeframe(props) {
  const itemList = [
    { label: 'Current Stages', id: 'current' },
    { label: 'Today', id: 'current_day' },
    { label: 'Yesterday', id: 'last_day' },
    { label: 'This Week', id: 'current_week' },
    { label: 'Last Week', id: 'last_week' },
    { label: 'This Month', id: 'current_month' },
    { label: 'Last Month', id: 'last_month' },
    { label: 'This Year', id: 'current_year' },
    // {label: "Last Year", id: 'last_year'},
    // {label: "Select Day", id: 'select_day'},
    // {label: "Select Week", id: 'select_week'},
    // {label: "Select Month", id: 'select_month'},
    // {label: "Select Year", id: 'select_year'},
    // {label: "Personal Best", id: 'best_all'},
    // {label: "Best Day", id: 'best_day'},
    // {label: "Best Week", id: 'best_week'},
    // {label: "Best Month", id: 'best_month'},
    // {label: "Best Year", id: 'best_year'}
  ];

  let itemLabel = '';
  for (const item of itemList) {
    if (item.id === props.value) {
      itemLabel = item.label;
      break;
    }
  }
  return (
    <Box>
      <>
        {itemList.length > 0 && (
          <Autocomplete
            classes={{ inputRoot: 'scorecard-dropdown-box' }}
            sx={{ width: 270 }}
            onChange={(event, val) => {
              props.fieldSetter(val.id, val.label);
            }}
            options={itemList}
            defaultValue={itemLabel}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </>
    </Box>
  );
}
