import React from 'react';
import { GameDate, GameTitle, ViewContainer } from './GameDetails.styles';
import DisplayBracketSeed from './DisplayBracketSeed';
import DisplayBracket from './DisplayBracket';
import NormalDetails from './NormalDetails';
import formatGameStartAndEndDate from '../games-helpers/formatGameStartAndEndDate';
import Game from '../_types/Game';

interface Props {
  game: Game;
}

const GameDetailsView = ({ game }: Props) => {
  return (
    <ViewContainer>
      <GameTitle>{game.name}</GameTitle>
      <GameDate>
        {formatGameStartAndEndDate({
          startDate: new Date(game.startDate),
          endDate: new Date(game.endDate),
        })}
      </GameDate>
      {game.type === 'quest' ? (
        game.contestType === 'bracket_seed' ? (
          <DisplayBracketSeed game={game} />
        ) : (
          <DisplayBracket game={game} />
        )
      ) : (
        <NormalDetails game={game} />
      )}
    </ViewContainer>
  );
};

export default GameDetailsView;
