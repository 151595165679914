// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
// RoundButtons

import React from 'react';
import { View } from '@aws-amplify/ui-react';
import { Box } from '@mui/material';
import ShadowButton from './ShadowButton';

//
// props:
// * backgroundColor
// * shadowColor
// * selectedShadowColor
// * iconColor
// * selected
// * onSelect
// * buttons: [
//    iconName
//    iconType
//    title
//    key

export default function RoundButtons(props) {
  console.log('RoundButtons: ', props);
  // LOG.debug ("===RoundButtons: ", props);
  // var backgroundColor = props.backgroundColor;
  // if (backgroundColor === undefined) {
  //   backgroundColor = "black"
  // }

  // var selectedShadowColor = props.selectedShadowColor;
  // if (selectedShadowColor === undefined) {
  //   selectedShadowColor = "red"
  // }

  return (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingTop: 10,
      }}
    >
      {props.buttons.map((button, i) => (
        <Box key={i} display={'inline-flex'}>
          <React.Fragment key={i}>
            {(button.enabled === undefined || button.enabled) && (
              <View
                key={button.key}
                style={{ flexDirection: 'row' }}
                onClick={() => {
                  props.onClick(button.key);
                }}
              >
                <View>
                  {props.selected === button.key ? (
                    <ShadowButton
                      title={button.title}
                      width={85}
                      marginRight={10}
                      onClick={() => {
                        props.onClick(button.key);
                      }}
                    />
                  ) : (
                    <ShadowButton
                      title={button.title}
                      width={85}
                      marginRight={10}
                      onClick={() => {
                        props.onClick(button.key);
                      }}
                    />
                  )}
                </View>
              </View>
            )}
          </React.Fragment>
        </Box>
      ))}
    </View>
  );
}
