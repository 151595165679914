import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const customTextStyles = {
  fontFamily: 'Manrope',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '-0.01em',
  textAlign: 'left',
};

const CancelButton = styled(Button)(() => ({
  color: '#FF6A00',
  backgroundColor: 'white',
  border: '2px solid #FF6A00',
  '&:hover': {
    backgroundColor: '#FF6A00',
    color: 'white',
  },
  width: '50%',
  marginRight: '16px',
  paddingTop: '10px',
  paddingBottom: '10px',
  ...customTextStyles,
}));

const SaveButton = styled(Button)(() => ({
  color: 'white',
  backgroundColor: '#FF6A00',
  border: '2px solid #FF6A00',
  '&:hover': {
    backgroundColor: '#FF6A00',
    color: 'white',
  },
  width: '50%',
  paddingTop: '10px',
  paddingBottom: '10px',
  ...customTextStyles,
}));

const ConfirmationModal = ({
  open,
  onClose,
  message,
  onConfirm,
  title = 'Save',
  confirmationButtonText,
  isLoading,
}) => {
  const titleStyle = {
    fontFamily: 'Manrope',
    fontSize: '28px',
    fontWeight: '600px',
    lineHeight: '34px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#222428',
    marginBottom: '0px',
  };

  const subtitleStyle = {
    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    textAlign: 'left',
    color: '#868686',
  };

  const modalContainerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 624,
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
    padding: '40px',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '16px',
    right: '16px',
    color: '#222428',
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box style={modalContainerStyle}>
        <IconButton style={closeButtonStyle} onClick={onClose}>
          <Close />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <h1 style={titleStyle}>{title || 'Are you sure?'}</h1>
        </Box>
        <p style={subtitleStyle}>{message}</p>
        <Box sx={{ display: 'flex', marginTop: 5 }}>
          <CancelButton variant="contained" onClick={onClose}>
            Cancel
          </CancelButton>
          <SaveButton variant="contained" color="error" onClick={onConfirm}>
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              confirmationButtonText
            )}
          </SaveButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
