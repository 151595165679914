import React, { useEffect, useState } from 'react';
import NewGameState from '../../../_types/NewGameState';
import RoundView from './Round.view';
// import * as yup from 'yup';
import { useGetUserInformationQuery } from '../../../../../../store/api/GraphQlQuery';
import { LOG, getLocalDate } from '../../../../../../common/Utilities';
import getKpis from '../../../../games-helpers/getKpis';
import RoundState from '../../../_types/RoundState';
import RewardType from '../../../_types/RewardType';
import getRewards from '../../../../games-helpers/getRewards';
import { isBefore, isEqual } from 'date-fns';
import RoundErrorMessagesState from '../../../_types/RoundErrorMessagesState';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  tabNo: number;
  setShouldDisableNextOrFinishButton: any;
}

const ROUND_INITIAL_ERRORS = {
  bracketName: '',
  startDate: '',
  endDate: '',
  actionCount: '',
  isActionCountCustom: '',
  KPIRuleID: '',
  tieKPIRuleID: '',
  useMultipleKPIs: '',
};

const RoundContainer: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
  tabNo,
  setShouldDisableNextOrFinishButton,
}) => {
  const roundValues = newGameState.bracket.roundValues[tabNo - 1];
  const [roundState, setRoundState] = useState<RoundState>({
    bracketName: roundValues?.bracketName ?? '',
    startDate: roundValues?.startDate ?? '',
    endDate: roundValues?.endDate ?? '',
    actionCount: roundValues?.actionCount ?? '10',
    isActionCountCustom: roundValues?.isActionCountCustom ?? false,
    KPIRuleID: roundValues?.KPIRuleID ?? '',
    tieKPIRuleID: roundValues?.tieKPIRuleID ?? '',
    useMultipleKPIs: roundValues?.useMultipleKPIs ?? false,
    rewardDisposition: roundValues?.rewardDisposition ?? 'winner',
    rewardWhen: roundValues?.rewardWhen ?? 'mission',
    rewardType: roundValues?.rewardType ?? '',
    rewardCurrency: roundValues?.rewardCurrency ?? 'reward',
    rewardID1: roundValues?.rewardID1 ?? '',
    rewardName1: roundValues?.rewardName1 ?? '',
    rewardID2: roundValues?.rewardID2 ?? '',
    rewardName2: roundValues?.rewardName2 ?? '',
    rewardID3: roundValues?.rewardID3 ?? '',
    rewardName3: roundValues?.rewardName3 ?? '',
    rewardIDp: roundValues?.rewardIDp ?? '',
    rewardNameP: roundValues?.rewardNameP ?? '',
    rewardID1t: roundValues?.rewardID1t ?? '',
    rewardName1t: roundValues?.rewardName1t ?? '',
    rewardID2t: roundValues?.rewardID2t ?? '',
    rewardName2t: roundValues?.rewardName2t ?? '',
    rewardID3t: roundValues?.rewardID3t ?? '',
    rewardName3t: roundValues?.rewardName3t ?? '',
    rewardIDpt: roundValues?.rewardIDpt ?? '',
    rewardNamePt: roundValues?.rewardNamePt ?? '',
    showCustomReward1: roundValues?.showCustomReward1 ?? false,
    showCustomReward2: roundValues?.showCustomReward2 ?? false,
    showCustomReward3: roundValues?.showCustomReward3 ?? false,
    rewardCount1: roundValues?.rewardCount1 ?? '',
    rewardCount1t: roundValues?.rewardCount1t ?? '',
    rewardCount2: roundValues?.rewardCount2 ?? '',
    rewardCount2t: roundValues?.rewardCount2t ?? '',
    rewardCount3: roundValues?.rewardCount3 ?? '',
    rewardCount3t: roundValues?.rewardCount3t ?? '',
    rewardCountP: roundValues?.rewardCountP ?? '',
    rewardLimit: roundValues?.rewardLimit ?? '',
    isInitialized: roundValues?.isInitialized ?? true,
    currentMissionId: roundValues?.currentMissionId ?? '',
    id: roundValues?.id ?? '',
    type: roundValues?.type ?? 'quest',
    name: roundValues?.name ?? '',
    description: roundValues?.description ?? '',
    search: roundValues?.search ?? '',
    imageName: roundValues?.imageName ?? '',
    imageType: roundValues?.imageType ?? '',
    createMission: roundValues?.createMission ?? false,
    pickAssignee: roundValues?.pickAssignee ?? false,
    uploadloadingPicture: roundValues?.uploadloadingPicture ?? false,
    uploadPictureTitle: roundValues?.rewardLimit ?? 'Upload Picture / Video',
    parentID: roundValues?.rewardLimit ?? '',
    parentName: roundValues?.rewardLimit ?? '',
    scope: roundValues?.rewardLimit ?? 'circle',
    assigneeID: roundValues?.rewardLimit ?? '',
    assigneeName: roundValues?.rewardLimit ?? '',
    ownerID: roundValues?.rewardLimit ?? '',
    ownerType: roundValues?.rewardLimit ?? '',
    questType: roundValues?.rewardLimit ?? 'notaquest',
    questOrder: roundValues?.rewardLimit ?? 'notaquest',
    launchDate: roundValues?.rewardLimit ?? '',
    location: roundValues?.rewardLimit ?? '',
    action: roundValues?.rewardLimit ?? '',
    actionPastTense: roundValues?.rewardLimit ?? '',
    actionTypePlural: roundValues?.rewardLimit ?? '',
    actionType: roundValues?.rewardLimit ?? '',
    missionRewardID1: roundValues?.rewardLimit ?? '',
    missionRewardID2: roundValues?.rewardLimit ?? '',
    missionRewardID3: roundValues?.rewardLimit ?? '',
    missionRewardIDp: roundValues?.rewardLimit ?? '',
    missionRewardID1t: roundValues?.rewardLimit ?? '',
    missionRewardID2t: roundValues?.rewardLimit ?? '',
    missionRewardID3t: roundValues?.rewardLimit ?? '',
    missionRewardIDpt: roundValues?.rewardLimit ?? '',
    missionRules: roundValues?.missionRules ?? [],
    tags: roundValues?.tags ?? [],
    orgID: roundValues?.rewardLimit ?? 'dev',
    autoAssign: roundValues?.autoAssign ?? true,
    copiedMissionID: roundValues?.rewardLimit ?? '',
    copiedMissionName: roundValues?.rewardLimit ?? '',
    copyMission: roundValues?.copyMission ?? false,
    KPIRuleName: roundValues?.rewardLimit ?? 'Installs Complete',
    tieKPIRuleName: roundValues?.rewardLimit ?? 'Installs Complete',
    trophies: roundValues?.trophies ?? [],
    isDeleted: roundValues?.isDeleted ?? false,
    useDealStage: roundValues?.useDealStage ?? true,
    actionCountTypeNum: roundValues?.actionCountTypeNum ?? 0,
    actionCountType: roundValues?.rewardLimit ?? 'count',
    categoryID: roundValues?.rewardLimit ?? '',
    flexFields: roundValues?.flexFields ?? [],
    useStepCounter: roundValues?.useStepCounter ?? false,
    assignToChildCircles: roundValues?.assignToChildCircles ?? true,
    isCircleOnly: roundValues?.isCircleOnly ?? false,
    isPublic: roundValues?.isPublic ?? false,
    managerPlay: roundValues?.managerPlay ?? true,
    contestType: roundValues?.rewardLimit ?? 'bracket_manual',
    bracket: roundValues?.bracket ?? [],
    minGoal: roundValues?.rewardLimit ?? '',
    useAutoGoal: roundValues?.useAutoGoal ?? false,
    autoGoalTimeframe: roundValues?.rewardLimit ?? 'W',
    autoGoalTimeframeCount: roundValues?.rewardLimit ?? '4',
    autoGoalFactor: roundValues?.rewardLimit ?? '100',
    minAutoGoal: roundValues?.rewardLimit ?? '1.0',
    roundGoalPrecision: roundValues?.rewardLimit ?? '0',
    useAutoCreate: roundValues?.useAutoCreate ?? false,
    autoCreateTimeframe: roundValues?.rewardLimit ?? 'W',
    autoCreateTimeframeCount: roundValues?.rewardLimit ?? '1',
    autoCreateStartDate: roundValues?.rewardLimit ?? '',
    autoCreateEndDate: roundValues?.rewardLimit ?? '',
    nextMissionID: roundValues?.rewardLimit ?? '',
    advanceType: roundValues?.rewardLimit ?? 'never',
    topNumberAdvance: roundValues?.topNumberAdvance ?? 0,
    roundValues: roundValues?.roundValues ?? [],
    currentTotalCompetitors: roundValues?.currentTotalCompetitors ?? 3,
    currentRounds: roundValues?.currentRounds ?? 2,
    currentRoundValues: roundValues?.currentRoundValues ?? [],
    showCustomGoal: roundValues?.showCustomGoal ?? false,
    rules: roundValues?.rules ?? [],
  });
  const { data: userData, isLoading } = useGetUserInformationQuery({});
  const [kpis, setKpis] = useState<
    { label: string; value: string; rule: any }[]
  >([]);
  const [rewards, setRewards] = useState<RewardType[]>([]);
  const [errorMessages, setErrorMessages] =
    useState<RoundErrorMessagesState>(ROUND_INITIAL_ERRORS);
  const onChangeRoundState = (newState: RoundState) => {
    setRoundState(newState);
  };
  const isErrorPresent = Object.values(errorMessages).some(
    (message) => message !== ''
  );
  const [shouldDisableNextButton, setShouldDisableNextButton] =
    useState(isErrorPresent);

  const onNextRound = () => {
    if (shouldDisableNextButton) return;

    onChangeNewGameState({
      ...newGameState,
      currentRound: newGameState.currentRound + 1,
    });
  };

  const shouldShowNextRoundButton = (): boolean => {
    return tabNo < newGameState.totalRounds;
  };

  const validate = () => {
    const errors = { ...errorMessages };

    // // Start Date validation 1
    // if (
    //   roundState.startDate &&
    //   isBefore(new Date(roundState.startDate), new Date())
    // ) {
    //   errors.startDate = 'Start Date cannot be earlier than the current time';
    //   setShouldDisableNextOrFinishButton(true);
    //   return errors;
    // } else {
    //   errors.startDate = '';
    //   setShouldDisableNextOrFinishButton(false);
    // }

    // Start Date validation
    const previousRoundValues = newGameState.bracket.roundValues[tabNo - 2];
    if (
      roundState.startDate &&
      previousRoundValues?.endDate &&
      isBefore(
        new Date(roundState.startDate),
        new Date(previousRoundValues?.endDate)
      )
    ) {
      errors.startDate = `Start Date cannot be earlier than the previous round's end date`;
      setShouldDisableNextOrFinishButton(true);
    } else {
      errors.startDate = '';
      setShouldDisableNextOrFinishButton(false);
    }

    // End Date validation
    if (
      roundState.endDate &&
      roundState.startDate &&
      isBefore(new Date(roundState.endDate), new Date(roundState.startDate))
    ) {
      errors.endDate = 'End Date should not be earlier than Start Date';
      setShouldDisableNextOrFinishButton(true);
    } else {
      errors.endDate = '';
      setShouldDisableNextOrFinishButton(false);
    }

    // Start Date and End Date validation, check if both are the same
    if (
      roundState.startDate &&
      roundState.endDate &&
      isEqual(new Date(roundState.startDate), new Date(roundState.endDate))
    ) {
      errors.endDate = 'Start Date and End Date should not be the same';
      setShouldDisableNextOrFinishButton(true);
    } else {
      errors.endDate = '';
      setShouldDisableNextOrFinishButton(false);
    }

    return errors;
  };

  useEffect(() => {
    const fetchKpis = async () => {
      if (isLoading || !userData) {
        return;
      }

      try {
        const kpis = await getKpis({
          me: userData.me,
        });

        setKpis(kpis);
      } catch (error) {
        LOG.error(error);
      }
    };

    const fetchRewards = async () => {
      if (isLoading || !userData) {
        return;
      }

      const rewards = await getRewards({
        endAfter: getLocalDate(),
        orgID: userData.me.orgID,
        startBefore: getLocalDate(),
        userID: userData.me.id,
      });
      setRewards(rewards);
    };

    fetchKpis();
    fetchRewards();
  }, [userData, isLoading]);

  useEffect(() => {
    const errors = validate();
    const isErrorPresent = Object.values(errors).some(
      (message) => message !== ''
    );

    setShouldDisableNextButton(isErrorPresent);
    setErrorMessages(errors);
  }, [roundState]);

  useEffect(() => {
    const newGameStateCopy = { ...newGameState };
    if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
      newGameStateCopy.bracket.roundValues[tabNo - 1] = roundState;
    } else {
      newGameStateCopy.bracket.roundValues.push(roundState);
    }
    onChangeNewGameState(newGameStateCopy);
  }, []);

  if (isLoading || !userData) {
    return null;
  }

  return (
    <RoundView
      tabNo={tabNo}
      kpis={kpis}
      roundState={roundState}
      newGameState={newGameState}
      onChangeRoundState={onChangeRoundState}
      onChangeNewGameState={onChangeNewGameState}
      shouldDisableNextButton={shouldDisableNextButton}
      rewards={rewards}
      onNextRound={onNextRound}
      shouldShowNextRoundButton={shouldShowNextRoundButton()}
      errorMessages={errorMessages}
    />
  );
};

export default RoundContainer;
