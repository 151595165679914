import styled from '@emotion/styled';

export const RootContainer = styled.div`
  width: 500px;
  background-color: #fff;
  margin-bottom: 4px;

  @media (min-width: 600px) {
    width: 760px;
  }
`;

export const ViewContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px 20px 0 20px;
  cursor: pointer;
`;

export const VerticalLine = styled.div`
  height: 100%;
  border: 1px solid #5c5c5c;
  width: 1px;
  margin-top: 10px;
  opacity: 0.7;
`;

export const ProfilePictureAndVerticalLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
