import React from 'react';
import Contestant from '../../../_types/Contestant';

interface Props {
  onSelect: (contestant: Contestant) => void;
  contestants: Contestant[];
  selectedContestants: string[];
}

const ContestantList: React.FC<Props> = ({
  contestants,
  onSelect,
  selectedContestants,
}) => {
  const filteredContestants: Contestant[] = contestants.filter(
    (contestant) =>
      !selectedContestants.some((selected) => selected === contestant.value)
  );

  if (filteredContestants.length === 0) {
    return <div>No contestants available</div>;
  }

  return (
    <div style={{ marginTop: 20 }}>
      {filteredContestants.map((contestant) => {
        return (
          <ContestantListItem
            key={contestant.value}
            onSelect={onSelect}
            contestant={contestant}
          />
        );
      })}
    </div>
  );
};

const ContestantListItem = ({
  contestant,
  onSelect,
}: {
  contestant: Contestant;
  onSelect: (contestant: Contestant) => void;
}) => {
  return (
    <div
      onClick={() => onSelect(contestant)}
      style={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
        alignItems: 'center',
        borderRadius: 12,
        backgroundColor: 'rgba(240, 240, 243, 0.5)',
        padding: '16px 20px',
      }}
    >
      <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
        <img
          src={contestant.avatar}
          width={40}
          height={40}
          style={{ objectFit: 'cover', borderRadius: 100 }}
        />
        <div style={{ fontWeight: 600 }}>{contestant.label}</div>
      </div>
    </div>
  );
};

export default ContestantList;
