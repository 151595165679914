import React from 'react';
import ImageThumbnail from './ImageThumbnail.view';
import NewGameState from '../../_types/NewGameState';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const ImageThumbnailContainer: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
}) => {
  return (
    <ImageThumbnail
      newGameState={newGameState}
      onChangeNewGameState={onChangeNewGameState}
    />
  );
};

export default ImageThumbnailContainer;
