import React from 'react';
import {
  NavigationButton,
  PageButton,
  PageButtonContainer,
  PaginationContainer,
} from './KPITable.styles';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onSelectPage: (page: number) => void;
  disabledNextPage: boolean;
  disabledPrevPage: boolean;
}

const Pagination = ({
  currentPage,
  onNextPage,
  onPreviousPage,
  onSelectPage,
  totalPages,
  disabledNextPage,
  disabledPrevPage,
}: PaginationProps) => {
  return (
    <PaginationContainer>
      <NavigationButton onClick={onPreviousPage} disabled={disabledPrevPage}>
        <ChevronLeft style={{ fontSize: 30 }} />
      </NavigationButton>
      <PageButtonContainer>
        {Array.from({ length: totalPages }, (_, i) => (
          <PageButton
            key={i}
            isSelected={currentPage === i}
            onClick={() => onSelectPage(i)}
          >
            {i + 1}
          </PageButton>
        ))}
      </PageButtonContainer>
      <NavigationButton onClick={onNextPage} disabled={disabledNextPage}>
        <ChevronRight style={{ fontSize: 30 }} />
      </NavigationButton>
    </PaginationContainer>
  );
};

export default Pagination;
