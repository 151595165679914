import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';

const DEFAULT_IMAGE_URL = '/images/default-avatar.png';

const UserProfilePicture = ({ imageName, size = 60 }) => {
  const [imageUrl, setImageUrl] = useState(null);

  const getMedia = async () => {
    try {
      if (imageName) {
        const url = await Storage.get(imageName);
        setImageUrl(url);
      } else {
        setImageUrl(DEFAULT_IMAGE_URL);
      }
    } catch (err) {
      setImageUrl(DEFAULT_IMAGE_URL);
    }
  };

  useEffect(() => {
    getMedia();
  }, [imageName]);

  if (!imageUrl) return null;

  return (
    <img
      src={imageUrl}
      alt="User Profile"
      style={{
        height: size,
        width: size,
        borderRadius: '100%',
        objectFit: 'cover',
      }}
    />
  );
};

export default UserProfilePicture;
