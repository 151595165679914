import { API } from 'aws-amplify';
import { getLocalDateTime } from '../../../common/Utilities';
import GET_LEADERBOARDS from '../queries/GET_LEADERBOARDS';

export const MAX_DATE = '2100-01-01';
export const MIN_DATE = '1900-01-01';

const getLeaderboards = async (args: { showCompleted: boolean }) => {
  const { showCompleted } = args;
  const now = getLocalDateTime();

  const result = await API.graphql({
    query: GET_LEADERBOARDS,
    variables: {
      request: JSON.stringify({
        startBetween: showCompleted ? [MIN_DATE, now] : [MIN_DATE, now],
        endBetween: showCompleted ? [MIN_DATE, now] : [now, MAX_DATE],
      }),
    },
  });

  // @ts-ignore
  const jsonRes = JSON.parse(result.data.getLeaderboards);
  return jsonRes.Items;
};

export default getLeaderboards;
