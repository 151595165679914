import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const Container = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 55vh;
  border-radius: 10px;
`;

export const TableContainer = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`;

export const TableHeadCell = styled.th`
  position: sticky;
  top: 0;
  background-color: #ff6a00;
  padding: 10px 20px 10px 20px;
  color: #fff;

  @media (min-width: 2560px) {
    padding: 20px 100px;
  }
`;

export const StickyTableHeadCell = styled.th<{ left: number }>`
  position: sticky;
  top: 0;
  left: ${({ left }) => `${left}px`};
  padding: 10px 20px 10px 20px;
  color: #fff;
  background-color: #ff6a00;
  z-index: 11;
`;

export const TableBodyRow = styled.tr`
  background-color: #fff;
  text-align: center;

  &:nth-child(even) {
    background-color: #f0f0f3;
  }
`;

export const TableBodyCell = styled.td`
  padding: 20px;

  @media (min-width: 2560px) {
    padding: 20px 100px;
  }
`;

export const StickyTableBodyCell = styled.td<{ left: number; isEven: boolean }>`
  position: sticky;
  left: ${({ left }) => `${left}px`};
  padding: 20px;
  background-color: ${({ isEven }) => (isEven ? '#fff' : '#F0F0F3')};
  z-index: 10;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;

export const PageButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const PageButton = styled(Button)<{ isSelected: boolean }>`
  color: ${({ isSelected }) => (isSelected ? '#FF6A00' : '#D0D2D8')};
  font-weight: bold;
  font-size: 16px;
  border: 1px solid purple;
  border-radius: 2px;
  border-width: 2px;
  border-color: ${({ isSelected }) => (isSelected ? '#FF6A00' : '#D0D2D8')};
  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? '#FF6A00' : '#D0D2D8'};
    color: #fff;
  }
`;

export const NavigationButton = styled(Button)`
  color: #ff6a00;
  &:disabled {
    color: #898989;
  }
`;
