import React from 'react';
import RoundsView from './Rounds.view';
import NewGameState from '../../_types/NewGameState';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  setShouldDisableNextOrFinishButton: any;
}

const RoundsContainer: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
  setShouldDisableNextOrFinishButton,
}) => {
  return (
    <RoundsView
      newGameState={newGameState}
      onChangeNewGameState={onChangeNewGameState}
      setShouldDisableNextOrFinishButton={setShouldDisableNextOrFinishButton}
    />
  );
};

export default RoundsContainer;
