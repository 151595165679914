import React from 'react';
import { ViewContainer } from './NormalDetails.styles';
import TopThree from './details-components/TopThree';
import RulesAndRewards from './details-components/RulesAndRewards';
import Rewards from './details-components/Rewards';
import Standings from './details-components/Standings';
import Game from '../../_types/Game';
interface Props {
  game: Game;
}

const NormalDetailsView = ({ game }: Props) => {
  return (
    <ViewContainer>
      <TopThree game={game} />
      <RulesAndRewards game={game} />
      <Rewards game={game} />
      <Standings game={game} />
    </ViewContainer>
  );
};

export default NormalDetailsView;
