import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  margin-top: 16px;
`;

export const GameTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  text-align: center;
`;

export const GameDate = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
`;
