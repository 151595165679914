import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { SideBarTab } from './PerformanceSideBar.types';
import { GamifyIcon } from '../../components/GamifyIcon';
import { Outlet, useNavigate, useLocation } from 'react-router';

export default function PerformanceSideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    '/performance/leaderboard'
  );

  // Need to navigate to leaderboard on initial click on the 'Performance' tab
  useEffect(() => {
    if (location.pathname === '/performance') {
      navigate('/performance/leaderboard');
    }
  }, [location]);

  const handleNavigate = (path: string) => {
    setActiveTab(path);
    navigate(path);
  };

  const sideBarTabs: SideBarTab[] = [
    {
      label: 'Leaderboard',
      val: 'leaderboard',
      icon: 'user',
      path: '/performance/leaderboard',
    },
    {
      label: 'Games',
      val: 'games',
      icon: 'about',
      path: '/performance/games',
    },
    {
      label: 'Rewards',
      val: 'rewards',
      icon: 'dollar',
      path: '/performance/rewards',
    },
  ];

  // @ts-ignore
  if (global.me.isAdmin) {
    sideBarTabs.push({
      label: 'Reports',
      val: 'reports',
      icon: 'kws',
      path: '/performance/reports',
    });
  }

  return (
    <>
      <Box
        style={{
          height: '100vh',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '88px',
        }}
      >
        <Box
          style={{
            height: '100%',
            width: 285,
            borderRight: '1px solid #7C7C7C',
          }}
        >
          <Box
            style={{
              marginTop: 50,
              marginLeft: 40,
            }}
          >
            <>
              <Typography style={{ fontSize: 20, fontWeight: 700 }}>
                Performance
              </Typography>
              {sideBarTabs.map((sideBarTab, i) => {
                return (
                  <Box
                    key={i}
                    sx={{ cursor: 'pointer' }}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: 'fit-content',
                      marginTop: 32,
                    }}
                    onClick={() => handleNavigate(sideBarTab.path)}
                  >
                    <Box
                      style={{
                        height: 24,
                        width: 24,
                        marginRight: 12,
                      }}
                    >
                      <GamifyIcon
                        icon={sideBarTab.icon}
                        color={
                          activeTab.includes(sideBarTab.val)
                            ? 'black'
                            : '#7C7C7C'
                        }
                        backgroundColor={'transparent'}
                        height={24}
                        width={24}
                      ></GamifyIcon>
                    </Box>
                    <Typography
                      style={{
                        fontSize: 16,
                        color: activeTab.includes(sideBarTab.val)
                          ? '#FF6A00'
                          : '#7C7C7C',
                        fontWeight: activeTab.includes(sideBarTab.val)
                          ? 600
                          : 400,
                      }}
                    >
                      {sideBarTab.label}
                    </Typography>
                  </Box>
                );
              })}
            </>
          </Box>
        </Box>
        <Box
          style={{
            width: '100%',
            overflowY: 'auto',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
