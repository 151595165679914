import React from 'react';
import { Box, Button, Skeleton } from '@mui/material';
import { GamifyIcon, EllipsisIcon } from '../../../components/GamifyIcon';
import ToggleMenu from '../../../common/ToggleMenu';

export default function ProfileAppointmentCardsSkeleton() {
  return (
    <>
      {(global.me.isAdmin ||
        global.me.functionalRoles?.includes('closer') ||
        global.me.functionalRoles?.includes('manager')) && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '2ch',
          }}
        >
          <Box
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <ToggleMenu
              initialActive="right"
              leftTitle="My Sets"
              rightTitle="My Appts"
              onLeftClick={() => {
                console.log('my sets');
              }}
              onRightClick={() => {
                console.log('my appts');
              }}
            ></ToggleMenu>
          </Box>
        </Box>
      )}
      {['', '', '', '', '', ''].map((appointment, i) => {
        console.log(appointment);
        return <AppointmentCardSkeleton key={i}></AppointmentCardSkeleton>;
      })}
    </>
  );
}

function AppointmentCardSkeleton() {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'white',
          padding: 15,
          border: '1px solid #D0D2D8',
          borderRadius: 8,
          marginBottom: 8,
        }}
      >
        <Box className={'appointments-list-item-left-container'}>
          <Box style={{ marginRight: 12 }}>
            <GamifyIcon icon={'calendar'}></GamifyIcon>
          </Box>
          <Box className={'appointments-list-item-left-text-container'}>
            <Skeleton
              variant={'rounded'}
              sx={{ bgcolor: 'black', marginTop: '2px', marginBottom: '2px' }}
              width={200}
              height={20.5}
            ></Skeleton>
            <Skeleton
              variant={'rounded'}
              sx={{ bgcolor: 'black', marginTop: '2px', marginBottom: '2px' }}
              width={125}
              height={20.5}
            ></Skeleton>
            <Skeleton
              variant={'rounded'}
              sx={{ bgcolor: 'black', marginTop: '1px', marginBottom: '1px' }}
              width={90}
              height={16}
            ></Skeleton>
            <Skeleton
              variant={'rounded'}
              sx={{ bgcolor: 'black', marginTop: '1px', marginBottom: '1px' }}
              width={90}
              height={16}
            ></Skeleton>
            <Skeleton
              variant={'rounded'}
              sx={{ bgcolor: 'black', marginTop: '1px', marginBottom: '1px' }}
              width={90}
              height={16}
            ></Skeleton>
          </Box>
        </Box>
        <Box
          style={{
            position: 'relative',
            height: 45,
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <Button className={'appointments-option-button'} disableRipple={true}>
            <EllipsisIcon color={'#868686'}></EllipsisIcon>
          </Button>
        </Box>
      </Box>
    </>
  );
}
