import gql from 'graphql-tag';
import { API } from 'aws-amplify';

const fetchStats = async (userId, dimension) => {
  // console.log('fetchStats: ', userId, dimension);
  let stagesOnly = false;
  if (dimension.endsWith(':current:current')) {
    stagesOnly = true;
  }

  const getPreferenceQuery = gql`
    query MyQuery($id: ID = "") {
      getPreferences(id: $id) {
        preferences
      }
    }
  `;

  let ourPreferences = [];
  // //
  // //  Get my preferences
  // //
  const userPreferenceID =
    global.me.orgID + ':KPIBoard:' + 'user' + ':' + global.me.id;
  let preferenceRes = await API.graphql({
    query: getPreferenceQuery,
    variables: { id: userPreferenceID },
  });

  if (preferenceRes.data.getPreferences === null) {
    //
    //  No preferences for the user, so get the org preferences
    //
    const orgPreferenceID =
      global.me.orgID + ':KPIBoard:' + 'org' + ':' + global.me.orgID;
    preferenceRes = await API.graphql({
      query: getPreferenceQuery,
      variables: { id: orgPreferenceID },
    });
  }

  if (preferenceRes.data.getPreferences != null) {
    ourPreferences = JSON.parse(preferenceRes.data.getPreferences.preferences);
  }

  const KPIQuery = gql`
    query MyQuery($orgID: ID!) {
      listKPIRules(
        filter: {
          orgID: { eq: $orgID }
          isDeleted: { eq: false }
          isHidden: { eq: false }
        }
        limit: 10000
      ) {
        items {
          id
          title
          sourceType
          action
          valueUnitPlural
          isDeleted
          triggerID
          format
          entitlement
          scorecardViewerRoles
          order
        }
      }
    }
  `;

  //
  //  Get all of the KPI's and trophies for this user
  //
  const queryListKPIs = gql`
    query MyQuery($userKey: ID!) {
      KPIValueAggsByUserKey(userKey: $userKey, limit: 10000) {
        items {
          id
          userKey
          dimensionID
          dimension
          val
          updatedAt
          KPIRule {
            id
            title
            sourceType
            action
            valueUnitPlural
            isDeleted
            isHidden
            triggerID
            format
            entitlement
            scorecardViewerRoles
            order
          }
        }
      }
    }
  `;

  try {
    // Use KPIQuery
    const KPIRes = await API.graphql({
      query: KPIQuery,
      variables: { orgID: global.me.orgID },
    });
    const KPIList = {};
    for (const KPI of KPIRes.data.listKPIRules.items) {
      if (
        !(
          stagesOnly &&
          KPI.sourceType !== 'stage' &&
          KPI.sourceType !== 'manual'
        )
      ) {
        KPI.isFound = false;
        KPIList[KPI.id] = KPI;
      }
    }

    // Use queryListKPIs

    // console.log('dimension: ', dimension);
    // console.log('userKey: ', userId + ':' + dimension);

    const res = await API.graphql({
      query: queryListKPIs,
      variables: { userKey: userId + ':person:' + dimension },
    });
    const rawKPIValues = res.data.KPIValueAggsByUserKey.items;

    //
    // Update our KPIList with the KPI's where the user has a value
    //
    let KPIValues = [];
    for (const kpi of rawKPIValues) {
      if (!(kpi.KPIRule.isHidden || kpi.KPIRule.isDeleted)) {
        if (
          !(
            stagesOnly &&
            kpi.KPIRule.sourceType !== 'stage' &&
            kpi.KPIRule.sourceType !== 'manual'
          )
        ) {
          KPIValues[KPIValues.length] = kpi;
        }
      }
    }
    for (const kpi of KPIValues) {
      kpi.isHidden = false;
      if (Object.keys(KPIList).includes(kpi.KPIRule.id)) {
        KPIList[kpi.KPIRule.id].isFound = true;
      } else {
        console.warn('Missing KPI Rule: ', kpi.KPIRule.id);
      }
    }

    // For every KPI that does NOT have a value, append that KPI to the KPI's for the user, but with a 0 value

    for (const key of Object.keys(KPIList)) {
      if (!KPIList[key].isFound) {
        const fakeKPI = {
          dimensionID: KPIList[key].id,
          dimension: 'person:' + dimension,
          val: 0,
          updatedAt: '',
          KPIRule: {
            id: KPIList[key].id,
            title: KPIList[key].title,
            isDeleted: KPIList[key].isDeleted,
            sourceType: KPIList[key].sourceType,
            valueUnitPlural: KPIList[key].valueUnitPlural,
            action: KPIList[key].action,
            triggerID: KPIList[key].triggerID,
            format: KPIList[key].format,
            entitlement: KPIList[key].entitlement,
            scorecardViewerRoles: KPIList[key].scorecardViewerRoles,
          },
        };

        KPIValues[KPIValues.length] = fakeKPI;
      }
    }
    //
    //  Hide any KPI's that the user shouldn't see
    //
    for (const kpi of KPIValues) {
      const KPI = kpi.KPIRule;
      // console.log('+++KPI: ', KPI);
      let canViewKPI = false;
      // console.log('isModerator: ', global.me.isModerator);
      if (
        global.me.isAdmin ||
        KPI.scorecardViewerRoles === null ||
        KPI.scorecardViewerRoles.includes('everyone')
      ) {
        //
        //  Admins can always view the KPI
        //  If scorecardViewerRoles are undefined, then everyone can view the KPI
        //
        canViewKPI = true;
      } else if (
        KPI.scorecardViewerRoles !== null &&
        KPI.scorecardViewerRoles.includes('moderator') &&
        global.me.isModerator
      ) {
        //
        //  Moderator role
        //
        canViewKPI = true;
      } else if (
        KPI.scorecardViewerRoles !== null &&
        KPI.scorecardViewerRoles.includes('owner') &&
        userId === global.me.id
      ) {
        //
        //  If the "owner" role is in the scorecardViewerRoles and the user's data that we're looking at is the current user,
        //  then the user can see the data
        //
        canViewKPI = true;
      }
      // console.log('canViewKPI: ', canViewKPI);
      if (!canViewKPI) {
        kpi.isHidden = true;
      }
    }

    let sortedKpis;
    if (ourPreferences.length === 0) {
      let hasOrderValue = false;
      for (const KPI of KPIValues) {
        if (KPI.KPIRule.order !== null) {
          hasOrderValue = true;
        } else {
          KPI.KPIRule.order = 99999;
        }
      }
      if (hasOrderValue) {
        //
        //  The KPI rules have an order column, so sort by the order, not the title
        //
        sortedKpis = KPIValues.sort((a, b) => {
          if (a.KPIRule.order < b.KPIRule.order) return -1;
          if (a.KPIRule.order > b.KPIRule.order) return 1;
          return 0;
        });
      } else {
        //
        //  The KPI rules do not have an order column, so sort by the title
        //
        sortedKpis = KPIValues.sort((a, b) => {
          if (a.KPIRule.title.toLowerCase() < b.KPIRule.title.toLowerCase()) {
            return -1;
          }
          if (a.KPIRule.title.toLowerCase() > b.KPIRule.title.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      }
    } else {
      const ourNewKPIList = [];

      for (const kpi of KPIValues) {
        const isRuleID = (element) => element.id === kpi.KPIRule.id;
        const index = ourPreferences.findIndex(isRuleID);
        if (index >= 0) {
          kpi.index = index;
          kpi.isHidden =
            ourPreferences[index].isHidden || ourPreferences[index].isHidden;
        } else {
          kpi.index = 1000;
          // kpi.isHidden = false;
        }
        if (kpi.isHidden) {
          kpi.index = 9999;
        }
        // console.log('!!!kpi.KPIRule.format: ', kpi.KPIRule.format);
        // console.log('!!!kpi.KPIRule.precision: ', kpi.KPIRule.precision);

        ourNewKPIList[ourNewKPIList.length] = kpi;
      }
      KPIValues = ourNewKPIList;

      sortedKpis = KPIValues.sort((a, b) => {
        return a.index - b.index;
      });
    }
    return sortedKpis;
  } catch (err) {
    console.error('fetchStats error: ', err);
  }
};

export default fetchStats;
