import React, { useEffect, useState } from 'react';
import {
  Box,
  Tooltip,
  Button,
  Backdrop,
  Snackbar,
  Switch,
  Alert,
  RadioGroup,
  Radio,
  TextField,
} from '@mui/material';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import { Text } from '@aws-amplify/ui-react';
import './StageDispositionConfig.css';
import { pinsList } from './pinsList';
const { v4: uuidv4 } = require('uuid');
const s3Prefix =
  'https://lyferize-public-statics.s3.us-west-2.amazonaws.com/mapmarkers/paiv/';

// const iconName2Url = (iconName) => `${s3Prefix}${iconName}-button.png`;
const iconName2Url = (iconName) =>
  `${s3Prefix}${iconName.replace(/'/g, '%E2%80%99')}-button.png`;

const emptyNewPin = {
  id: '',
  orgID: '',
  categoryID: '',
  title: '',
  isSetupDeal: false,
  requireDate: false,
  deleteType: 'always',
  order: '',
  iconName: '',
  iconType: '',
  img: '',
  iconColor: '',
  createdBy: '',
  lastUpdatedBy: '',
  createdAt: '',
  updatedAt: '',
  isDeleted: false,
};

export default function StageDispositionsConfig({ orgID }) {
  const [orgDispositions, setOrgDispositions] = useState(null);
  const [singlePin, setSinglePin] = useState(null);
  const [categoryID, setCategoryID] = useState(null);
  const [openEditor, setOpenEditor] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    show: false,
    message: '',
  });

  const getDispositions = async () => {
    const myQuery = gql`
      query MyQuery($orgID: ID!, $nextToken: String) {
        listStageDispositions(
          filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
          nextToken: $nextToken
        ) {
          items {
            id
            iconName
            isDeleted
            isDefault
            isNotSelectable
            isConversation
            orgID
            title
            categoryID
            order
            deleteType
            noRecording
            isSetupDeal
            requireDate
          }
          nextToken
        }
      }
    `;
    let nextToken = null;
    let allStageDispositions = [];
    do {
      const res = await API.graphql({
        query: myQuery,
        variables: {
          orgID,
          nextToken,
        },
      });
      const stageDispositions = res.data.listStageDispositions.items;
      allStageDispositions = allStageDispositions.concat(stageDispositions);
      nextToken = res.data.listStageDispositions.nextToken;
    } while (nextToken);

    const categoryQuery = gql`
      query MyQuery($id: ID!) {
        getStageCategory(id: $id) {
          title
        }
      }
    `;
    const tempDispoObj = {};
    for (const dispo of allStageDispositions) {
      if (!tempDispoObj[dispo.categoryID]) {
        const catRes = await API.graphql({
          query: categoryQuery,
          variables: { id: dispo.categoryID },
        });

        tempDispoObj[dispo.categoryID] = {
          title: catRes.data.getStageCategory.title,
          id: dispo.categoryID,
          pins: [],
        };
      }
      tempDispoObj[dispo.categoryID].pins.push({
        ...dispo,
        img: iconName2Url(dispo.iconName),
      });
    }
    setOrgDispositions(tempDispoObj);
    console.log(tempDispoObj);
  };

  const handleClick = (e, pin) => {
    const name = e.currentTarget.getAttribute('name');
    if (!name) {
      setCategoryID(pin);
    } else {
      setSinglePin(pin);
      setCategoryID(pin.categoryID);
    }
    setOpenEditor(true);
  };

  useEffect(() => {
    getDispositions();
  }, []);

  if (!orgDispositions) return <PinsLoadingAnimation />;
  //   if (orgDispositions) return <PinsLoadingAnimation />;
  return (
    <Box className="stage-dispo-configurator-main-container">
      {Object.values(orgDispositions)
        .sort((a, b) => a.title - b.title)
        .map((category) => (
          <Box key={category.title}>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: '1.5rem',
                marginLeft: '10px',
              }}
            >
              {category.title}
            </Text>
            <Box className="pins-shelf">
              {category.pins
                .sort((a, b) => a.order - b.order)
                .map((pin) => (
                  <Tooltip key={pin.id} title={pin.title} placement="top" arrow>
                    <img
                      className="single-pin-image"
                      style={{ width: '80px', height: '80px', margin: '5px' }}
                      src={pin.img}
                      name={pin.id}
                      onClick={(e) => handleClick(e, pin)}
                    />
                  </Tooltip>
                ))}
              <Tooltip title="Create Pin" placement="top" arrow>
                <Box
                  id="add-disposition-button"
                  className="single-pin-image"
                  onClick={(e) => handleClick(e, category.id)}
                >
                  +
                </Box>
              </Tooltip>
            </Box>
          </Box>
        ))}
      {openEditor && (
        <StageDispositionEditor
          singlePin={singlePin}
          categoryID={categoryID}
          orgDispositions={orgDispositions}
          openEditor={openEditor}
          orgID={orgID}
          updateOrgDispositions={(category, pin, newPin = false) => {
            if (newPin) {
              setOrgDispositions((p) => ({
                ...p,
                [category]: {
                  ...p[category],
                  pins: [...p[category].pins, pin],
                },
              }));
            } else {
              const pinsArray = orgDispositions[category].pins;
              const pinIndex = pinsArray.indexOf(
                pinsArray.find((el) => el.id === pin.id)
              );
              pinsArray.splice(pinIndex, 1, pin);
              setOrgDispositions((p) => ({
                ...p,
                [category]: {
                  ...p[category],
                  pins: pinsArray,
                },
              }));
            }
          }}
          removePin={(id) => {
            const pinsArray = orgDispositions[categoryID].pins;
            const index = pinsArray.indexOf(
              pinsArray.find((el) => el.id === id)
            );
            pinsArray.splice(index, 1);
            setOrgDispositions((p) => ({
              ...p,
              [categoryID]: {
                ...p[categoryID],
                pins: pinsArray,
              },
            }));
          }}
          closeEditor={() => {
            setSinglePin(null);
            setCategoryID(null);
            setOpenEditor(false);
          }}
          openSnackbar={(message) => {
            setSnackbarMessage({ show: true, message });
            setTimeout(() => {
              setSnackbarMessage({ show: false, message: '' });
            }, 5000);
          }}
        />
      )}
      <Snackbar open={snackbarMessage.show} autoHideDuration={6000}>
        <Alert severity="success" sx={{ width: '100%', padding: '15px 20px' }}>
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

function StageDispositionEditor(props) {
  const {
    singlePin,
    categoryID,
    openEditor,
    closeEditor,
    orgID,
    updateOrgDispositions,
    removePin,
    orgDispositions,
    openSnackbar,
  } = props;
  const [pinObj, setPinObj] = useState(null);
  const [ogPinObj, setOgPinObj] = useState(null);
  const [showPinsMenu, setShowPinsMenu] = useState(false);
  const [changed, setChanged] = useState(false);
  const [submitReady, setSubmitReady] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [duplicatePin, setDuplicatePin] = useState(false);
  const [confirmDefault, setConfirmDefault] = useState(false);

  useEffect(() => {
    if (singlePin) {
      setPinObj(singlePin);
      setOgPinObj(singlePin);
    } else {
      const temp = {
        ...emptyNewPin,
        // order: orgDispositions[categoryID].pins.length + 1,
        id: uuidv4(),
        categoryID,
        orgID,
      };
      setPinObj(temp);
      setOgPinObj(temp);
    }
  }, []);

  useEffect(() => {
    if (!pinObj) return;
    setChanged(JSON.stringify(pinObj) !== JSON.stringify(ogPinObj));
    setSubmitReady(pinObj.title.length && pinObj.img.length);
  }, [pinObj]);

  const handleChange = (e) => {
    const temp = { ...pinObj, [e.target.name]: e.target.value };
    setPinObj(temp);
  };

  const handlePinSelection = (pin) => {
    const temp = { ...pinObj, iconName: pin, img: iconName2Url(pin) };
    if (orgDispositions[categoryID].pins.find((el) => el.iconName === pin)) {
      setDuplicatePin(true);
    } else {
      setDuplicatePin(false);
    }
    setPinObj(temp);
    setShowPinsMenu(false);
  };

  const createNewPin = async () => {
    const newOrder = orgDispositions[categoryID].pins.length + 1;
    // setPinObj((p) => ({ ...p, order: newOrder }));
    const submitPin = {
      id: pinObj.id,
      orgID: pinObj.orgID,
      categoryID: pinObj.categoryID,
      title: pinObj.title,
      noRecording: pinObj.noRecording,
      isNotSelectable: pinObj.isNotSelectable,
      isSetupDeal: pinObj.isSetupDeal,
      requireDate: pinObj.requireDate,
      deleteType: pinObj.deleteType,
      order: newOrder,
      iconName: pinObj.iconName,
      isDeleted: false,
    };
    const myMutation = gql`
      mutation CreateStageDisposition(
        $input: CreateStageDispositionInput!
        $condition: ModelStageDispositionConditionInput
      ) {
        createStageDisposition(input: $input, condition: $condition) {
          id
          orgID
          categoryID
          title
          isSetupDeal
          isNotSelectable
          isConversation
          requireDate
          deleteType
          order
          iconName
          isDeleted
        }
      }
    `;
    try {
      await API.graphql({
        query: myMutation,
        variables: {
          input: {
            ...submitPin,
          },
        },
      });
    } catch (err) {
      console.log('we have a problem');
      console.log(err);
    }
    updateOrgDispositions(categoryID, { ...pinObj, order: newOrder }, true);
    openSnackbar(`Successfully Created ${pinObj.title} Pin`);
    closeEditor();
  };

  const saveChanges = async () => {
    const myMutation = gql`
      mutation UpdateStageDisposition($input: UpdateStageDispositionInput!) {
        updateStageDisposition(input: $input) {
          id
          title
          iconName
          isDeleted
          isDefault
          isSetupDeal
          isConversation
          isNotSelectable
          requireDate
          noRecording
          deleteType
        }
      }
    `;
    try {
      let currentDefault;
      if (pinObj.isDefault) {
        const { pins } = orgDispositions[pinObj.categoryID];
        currentDefault = pins.find((el) => el.isDefault && el.id !== pinObj.id);
      }
      if (currentDefault) {
        console.log('CURRENT DEFAULT - ', currentDefault);
        currentDefault.isDefault = false;
        await API.graphql({
          query: myMutation,
          variables: {
            input: { id: currentDefault.id, isDefault: false },
          },
        });
        updateOrgDispositions(currentDefault.categoryID, currentDefault);
      }
      await API.graphql({
        query: myMutation,
        variables: {
          input: {
            id: pinObj.id,
            title: pinObj.title,
            iconName: pinObj.iconName,
            isDeleted: pinObj.isDeleted,
            isSetupDeal: pinObj.isSetupDeal,
            isNotSelectable: pinObj.isNotSelectable,
            isDefault: pinObj.isDefault,
            requireDate: pinObj.requireDate,
            noRecording: pinObj.noRecording,
            deleteType: pinObj.deleteType,
          },
        },
      });
    } catch (err) {
      console.log('we have a problem');
      console.log(err);
    }
    updateOrgDispositions(categoryID, pinObj);
    openSnackbar(`Successfully Updated ${pinObj.title} Pin`);
    closeEditor();
  };

  const handlePinDelete = async (e) => {
    e.stopPropagation();
    const myMutation = gql`
      mutation UpdateStageDisposition($input: UpdateStageDispositionInput!) {
        updateStageDisposition(input: $input) {
          id
          isDeleted
        }
      }
    `;
    await API.graphql({
      query: myMutation,
      variables: {
        input: {
          id: pinObj.id,
          isDeleted: true,
        },
      },
    });
    removePin(pinObj.id);
    setConfirmDelete(false);
    openSnackbar(`Successfully Deleted ${pinObj.title} Pin`);
    closeEditor();
  };

  if (!pinObj) return null;
  return (
    <Backdrop
      open={openEditor}
      sx={{
        // marginTop: '-80px',
        backgroundColor: 'rgba(0,0,0,0.6)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box id="stage-disposition-editor-main-container">
        {showPinsMenu && (
          <Box id="all-pins-menu">
            <Box id="pin-options-grid">
              {pinsList.map((pin, i) => (
                <Tooltip key={i} title={pin} placement="top" arrow>
                  <img
                    className="selection-image"
                    src={iconName2Url(pin)}
                    onClick={() => handlePinSelection(pin)}
                  />
                </Tooltip>
              ))}
            </Box>
            <Button
              style={{ margin: '10px 0' }}
              variant="outlined"
              color="error"
              onClick={() => setShowPinsMenu(false)}
            >
              CANCEL
            </Button>
          </Box>
        )}
        <Box id="dispo-editor-topbar">
          <Box className="space-filler" />
          <Text style={{ fontSize: '1.4rem', color: 'white' }}>
            {singlePin ? singlePin.title : 'Create New Pin'}
          </Text>
          <Box id="close-editor-button" onClick={closeEditor}>
            X
          </Box>
        </Box>
        <Box id="pin-editor-form-container">
          <Box id="editor-image-box">
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {pinObj.img?.length ? (
                <>
                  <img className="pin-img" src={pinObj.img} />
                  {duplicatePin ? (
                    <Text style={{ color: 'red', fontSize: '0.9rem' }}>
                      This pin is already in use in this category
                    </Text>
                  ) : null}
                </>
              ) : (
                <Box
                  className="pin-img no-img hov"
                  onClick={() => setShowPinsMenu(true)}
                >
                  <Text>Select Pin Icon</Text>
                </Box>
              )}
              <Button
                variant="outlined"
                style={{ margin: '5px 0' }}
                onClick={() => setShowPinsMenu(true)}
              >
                {singlePin || pinObj.iconName?.length
                  ? 'Change Icon'
                  : 'Select Icon'}
              </Button>
            </Box>
          </Box>

          <TextField
            style={{ width: '90%' }}
            variant="outlined"
            value={pinObj.title}
            label="Title"
            name="title"
            onChange={handleChange}
          />
          <Box
            style={{
              margin: '10px 0',
              display: 'flex',
              justifyContent: 'space-around',
              width: '90%',
            }}
          >
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Text>Deal Setup</Text>
              <Switch
                checked={pinObj.isSetupDeal}
                onChange={() =>
                  setPinObj((p) => ({
                    ...p,
                    isSetupDeal: !p.isSetupDeal,
                  }))
                }
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Text>Require Date</Text>
              <Switch
                checked={pinObj.requireDate}
                onChange={() =>
                  setPinObj((p) => ({
                    ...p,
                    requireDate: !p.requireDate,
                  }))
                }
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Text>No Recording</Text>
              <Switch
                checked={pinObj.noRecording}
                onChange={() =>
                  setPinObj((p) => ({
                    ...p,
                    noRecording: !p.noRecording,
                  }))
                }
              ></Switch>
            </Box>
          </Box>
          <Box
            style={{
              margin: '10px 0',
              display: 'flex',
              justifyContent: 'space-around',
              width: '90%',
            }}
          >
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Text onClick={() => console.log(orgDispositions)}>
                Default Pin
              </Text>
              <Switch
                checked={Boolean(pinObj.isDefault)}
                onChange={() => {
                  const { pins } = orgDispositions[pinObj.categoryID];
                  const currentDefault = pins.find(
                    (el) => el.isDefault && el.id !== pinObj.id
                  );
                  if (currentDefault) {
                    console.log('GOt a current default -- ', currentDefault);
                    setConfirmDefault(currentDefault);
                  } else {
                    console.log('no current default');
                    setPinObj((p) => ({
                      ...p,
                      isDefault: !p.isDefault,
                    }));
                  }
                }}
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Text onClick={() => console.log(orgDispositions)}>
                Is Conversation
              </Text>
              <Switch
                checked={Boolean(pinObj.isConversation)}
                onChange={() => {
                  // console.log('no current default');
                  setPinObj((p) => ({
                    ...p,
                    isConversation: !p.isConversation,
                  }));
                }}
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Text onClick={() => console.log(orgDispositions)}>
                Not Selectable
              </Text>
              <Switch
                checked={Boolean(pinObj.isNotSelectable)}
                onChange={() => {
                  setPinObj((p) => ({
                    ...p,
                    isNotSelectable: !p.isNotSelectable,
                  }));
                }}
              />
            </Box>
          </Box>
          <RadioGroup
            sx={{
              flexWrap: 'wrap',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '10px',
            }}
            onChange={(e) =>
              setPinObj((p) => ({ ...p, deleteType: e.target.value }))
            }
            value={pinObj.deleteType}
          >
            <Text style={{ marginRight: '15px' }}>Delete Type:</Text>
            <Text
              style={{
                color: pinObj.deleteType === 'always' ? 'black' : 'gray',
              }}
            >
              Always
            </Text>
            <Radio value="always" />
            <Text
              style={{
                color: pinObj.deleteType === 'never' ? 'black' : 'gray',
              }}
            >
              Never
            </Text>
            <Radio value="never" />
          </RadioGroup>
          {singlePin ? (
            <Box>
              <Button
                style={{ marginRight: '10px' }}
                variant="outlined"
                color="success"
                disabled={!(submitReady && changed)}
                onClick={saveChanges}
              >
                Save Changes
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setConfirmDelete(true)}
              >
                Delete Pin
              </Button>
            </Box>
          ) : (
            <Button
              variant="outlined"
              color="success"
              disabled={!submitReady}
              onClick={createNewPin}
            >
              Save Pin
            </Button>
          )}
        </Box>
        <Backdrop
          open={confirmDelete}
          sx={{
            backgroundColor: 'rgba(0,0,0,0.6)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Box
            id="confirm-delete-pin-container"
            onClick={() => setConfirmDelete(false)}
          >
            <Text style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
              Are you sure you want to delete this pin?
            </Text>
            <Box id="delete-button-group">
              <Button
                variant="contained"
                color="error"
                onClick={handlePinDelete}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                onClick={() => setConfirmDelete(false)}
              >
                No
              </Button>
            </Box>
          </Box>
        </Backdrop>
        <Backdrop
          open={Boolean(confirmDefault)}
          sx={{
            backgroundColor: 'rgba(0,0,0,0.6)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Box
            id="confirm-delete-pin-container"
            onClick={() => setConfirmDelete(false)}
          >
            <Text style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
              {`${confirmDefault?.title} is the current default pin.`}
            </Text>
            <Text style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
              Are you sure you want to change the default pin?
            </Text>
            <Box id="delete-button-group">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setPinObj((p) => ({ ...p, isDefault: true }));
                  setConfirmDefault(null);
                }}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                onClick={() => setConfirmDefault(null)}
              >
                No
              </Button>
            </Box>
          </Box>
        </Backdrop>
      </Box>
    </Backdrop>
  );
}

function PinsLoadingAnimation() {
  const boxes = Array.from({ length: 8 }, (_, index) => (
    <Box
      key={index}
      className="loading-single-pin-box"
      style={{ animationDelay: `${index * 0.15}s` }}
    />
  ));
  return (
    <Box className="stage-dispo-configurator-main-container">
      <Box className="loading-catregory-name-box" />
      <Box className="pins-shelf">{boxes}</Box>
    </Box>
  );
}
