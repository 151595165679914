import { API } from 'aws-amplify';
import localToISODateFormat from './localToISODateFormat';
import LIST_CIRCLE_LEADERBOARD from '../queries/LIST_CIRCLE_LEADERBOARD';

export const MAX_DATE = '2100-01-01';
export const MIN_DATE = '1900-01-01';

const getListCircleLeaderboard = async (args: {
  orgID: string;
  showCompleted: boolean;
}) => {
  const { orgID, showCompleted } = args;

  const result = await API.graphql({
    query: LIST_CIRCLE_LEADERBOARD,
    variables: {
      orgID,
      startBetween: [MIN_DATE, localToISODateFormat(new Date())],
      endBetween: showCompleted
        ? [MIN_DATE, localToISODateFormat(new Date())]
        : [localToISODateFormat(new Date()), MAX_DATE],
    },
  });

  // @ts-ignore
  return result.data.listMissionByEndDate.items;
};

export default getListCircleLeaderboard;
