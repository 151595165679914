import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import NavBar from '../components/NavBar';

export default class Layout extends React.Component {
  render() {
    return (
      <Box>
        <NavBar />
        <Box>
          <Outlet />
        </Box>
        {/* <Helper /> */}
      </Box>
    );
  }
}
