import styled from '@emotion/styled';

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 30px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-family: Manrope;
  font-size: 15px;
  margin-bottom: 10px;
`;

export const FilterItemsContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 70vw;
`;

export const CustomFilterContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CustomSelect = styled.select`
  border-color: #d9d9d9;
  border-radius: 10px;
  padding: 10px 30px 10px 10px !important;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('/images/select-dropdown.svg');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
  background-color: #fff;
  z-index: 10;

  :focus {
    outline: none;
  }
`;

export const CustomFilterTitle = styled.div`
  font-weight: 700;
  font-family: Manrope;
  font-size: 15px;
  margin-bottom: 10px;
`;
