import React from 'react';
import RoundState from '../../../../_types/RoundState';
import { RulesAndRewardsViewContainer, SectionTitle } from '../Round.styles';
import RewardType from '../../../../_types/RewardType';
import ItemSelectInput from '../../../../custom-inputs/ItemSelectInput';
import IfPlacedInputs from './round-rewards-inputs/IfPlacedInputs';
import HitQuotaAndPlacedInputs from './round-rewards-inputs/HitQuotaAndPlacedInputs';
import CompanyActionInputs from './round-rewards-inputs/CompanyActionInputs';
import NewGameState from '../../../../_types/NewGameState';

interface Props {
  onChangeRoundState: (roundState: RoundState) => void;
  roundState: RoundState;
  rewards: RewardType[];
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  tabNo: number;
}

const RoundRewardsView: React.FC<Props> = ({
  onChangeRoundState,
  rewards,
  roundState,
  newGameState,
  onChangeNewGameState,
  tabNo,
}) => {
  return (
    <RulesAndRewardsViewContainer>
      <SectionTitle>Rewards</SectionTitle>
      <ItemSelectInput
        label="Reward Type"
        selectedItem={roundState.rewardCurrency}
        onSelect={(newSelectedItem: { label: string; value: any }) => {
          onChangeRoundState({
            ...roundState,
            rewardCurrency: newSelectedItem.value,
          });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardCurrency =
              newSelectedItem.value;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              rewardCurrency: newSelectedItem.value,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
        items={[
          { label: 'Reward store item', value: 'reward' },
          { label: 'Coins', value: 'points' },
        ]}
        numColumns={2}
      />
      <ItemSelectInput
        label="Rules"
        selectedItem={roundState.rewardWhen}
        onSelect={(newSelectedItem: { label: string; value: any }) => {
          onChangeRoundState({
            ...roundState,
            rewardWhen: newSelectedItem.value,
          });

          const newGameStateCopy = { ...newGameState };
          if (newGameStateCopy.bracket.roundValues[tabNo - 1]) {
            newGameStateCopy.bracket.roundValues[tabNo - 1].rewardWhen =
              newSelectedItem.value;
          } else {
            newGameStateCopy.bracket.roundValues.push({
              ...roundState,
              rewardWhen: newSelectedItem.value,
            });
          }
          onChangeNewGameState(newGameStateCopy);
        }}
        items={[
          {
            label: 'If placed (ex 1st,2nd)',
            value: 'mission',
          },
          { label: 'Hit Quota and Placed', value: 'mission_min' },
          {
            label: 'If Company Personal is met',
            value: 'goal',
          },
          {
            label: 'For every action (ex. $1/dial)',
            value: 'action',
          },
        ]}
        numColumns={2}
      />
      <IfPlacedInputs
        rewards={rewards}
        roundState={roundState}
        onChangeRoundState={onChangeRoundState}
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
        tabNo={tabNo}
      />
      <HitQuotaAndPlacedInputs
        rewards={rewards}
        roundState={roundState}
        onChangeRoundState={onChangeRoundState}
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
        tabNo={tabNo}
      />
      <CompanyActionInputs
        rewards={rewards}
        roundState={roundState}
        onChangeRoundState={onChangeRoundState}
        newGameState={newGameState}
        onChangeNewGameState={onChangeNewGameState}
        tabNo={tabNo}
      />
    </RulesAndRewardsViewContainer>
  );
};

export default RoundRewardsView;
