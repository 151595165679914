import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  margin-top: 40px;
`;

export const TopThreeContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

export const TopThreeCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const TopThreeCardImage = styled.img<{ position: number }>`
  width: ${({ position }) => (position === 1 ? '180px' : '120px')};
  height: ${({ position }) => (position === 1 ? '180px' : '120px')};
  border-radius: 12px;
  object-fit: cover;
`;
// export const TopThreeCardImage = styled.img<{ position: number }>`
//   width: ${({ position }) => (position === 1 ? '120px' : '80px')};
//   height: ${({ position }) => (position === 1 ? '120px' : '80px')};
//   border-radius: 12px;
//   object-fit: cover;
// `;

export const TopThreeCardImageDefault = styled.img<{ position: number }>`
  width: ${({ position }) => (position === 1 ? '120px' : '80px')};
  height: ${({ position }) => (position === 1 ? '120px' : '80px')};
  border-radius: 12px;
  object-fit: contain;
  background-color: #f0f0f3;
  padding: 20px;
`;

export const TopThreeCardPosition = styled.div`
  color: #ff6a00;
  font-weight: 600;
  font-size: 14px;
`;

export const TopThreeCardUserName = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

export const RulesAndRewardsContainer = styled.div`
  margin-top: 40px;
`;

export const RulesAndRewardsTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const RulesAndRewardsSectionTitle = styled.div`
  font-weight: 500;
  margin-top: 10px;
`;

export const RulesAndRewardsAboutItemContainer = styled.div`
  background-color: #f0f0f3;
  padding: 10px;
  border-radius: 10px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const RulesAndRewardsAboutItemText = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const RewardsContainer = styled.div`
  margin-top: 40px;
`;

export const RewardsTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const RewardsItemsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  overflow: auto;
  padding: 10px 0;
`;

export const RewardCardContainer = styled.div`
  position: relative;
`;

export const RewardCardImage = styled.img`
  border-radius: 10px;
  object-fit: cover;
  width: 300px;
  height: 200px;
`;

export const RewardCardDetailsContainer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 10px;
  color: #fff;
  z-index: 2;
`;

export const RewardCardName = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

export const RewardCardPlace = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 6px;
`;

export const RewardCardDarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 12px;
`;

export const StandingsContainer = styled.div`
  margin-top: 20px;
`;

export const StandingsTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const StandingHeaderContainer = styled.div`
  margin-top: 6px;
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  background-color: #ff6a00;
  border-radius: 10px;
`;

export const StandingHeader = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 700;
`;

export const StandingDetailContainer = styled.div<{ isEven: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  background-color: ${({ isEven }) => (isEven ? '#F0F0F3' : '#fff')};
  border-radius: 10px;
`;

export const StandingDetail = styled.div`
  color: #000;
  font-size: 12px;
`;
