import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import DealCardSkeleton from '../DealCardSkeleton';

export default function DealsKanbanSkeleton({
  pipelineTitle,
}: {
  pipelineTitle: string;
}) {
  const dealStages: any[] = [];
  const dealsByDealStage: object = {};

  for (let i = 0; i < 8; i++) {
    dealStages.push(i);
  }

  for (let i = 0; i < 8; i++) {
    // @ts-ignore
    dealsByDealStage[`${i}`] = dealStages;
  }

  return (
    <Box style={{ display: 'flex' }}>
      <Box
        style={{
          height: '100vh',
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '178px',
          // paddingTop: '88px',
          //   backgroundColor: '#F0F0F3',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            position: 'relative',
            width: '100%',
            paddingLeft: 60,
            paddingRight: 60,
          }}
        >
          <Box style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography
              style={{ paddingTop: '44px', fontSize: '36px', fontWeight: 700 }}
            >
              {`${pipelineTitle || ''} Pipeline`}
            </Typography>
          </Box>

          <Box className={'add-deal-button-skeleton'}>
            <Skeleton
              sx={{ bgcolor: '#ffffff' }}
              variant={'circular'}
              width={18}
              height={18}
            ></Skeleton>
            <Skeleton
              sx={{ bgcolor: '#ffffff', position: 'relative', left: 8 }}
              variant={'rounded'}
              width={83}
              height={18}
            ></Skeleton>
          </Box>
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginTop: 12,
            overflowX: 'auto',
            overflowY: 'hidden',
            height: 'fit-content',
            minHeight: 'fit-content',
          }}
        >
          <Box
            style={{
              paddingLeft: 60,
              display: 'grid',
              columnGap: '16px',
              //   minWidth: '450px',
              minWidth: 350,
              marginRight: 16,
            }}
            gridTemplateColumns={'repeat(1, minmax(0, 1fr))'}
          >
            <Box className={'deal-list-settings-container'}>
              <Typography className={'deal-settings-title-text'}>
                Who
              </Typography>
              <Box className={'deal-settings-dropdown-skeleton-container'}>
                <Skeleton
                  variant={'rounded'}
                  width={'100%'}
                  height={23}
                ></Skeleton>
              </Box>
            </Box>
          </Box>

          <Box
            style={{
              paddingRight: 60,
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <Box className="leadSearch-skeleton-container">
              <Skeleton variant={'rounded'} width={502} height={23}></Skeleton>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            position: 'relative',
            width: '100%',
            paddingLeft: 60,
            paddingRight: 60,
            paddingTop: 30,
            overflowX: 'auto',
          }}
        >
          {/* NOTE: The Boxes below add the 60px padding on either side of the kanban to make it look better when horizontally scrolling */}
          <Box
            style={{
              position: 'fixed',
              top: 180,
              left: 0,
              width: 60,
              height: '100%',
              //   backgroundColor: '#F0F0F3',
              backgroundColor: 'white',
              pointerEvents: 'none', // Ensure it doesn't interfere with scrolling or interaction
              zIndex: 1,
            }}
          />

          <Box
            style={{
              position: 'fixed',
              top: 180,
              right: 0,
              width: 60,
              height: '100%',
              //   backgroundColor: '#F0F0F3',
              backgroundColor: 'white',
              pointerEvents: 'none', // Ensure it doesn't interfere with scrolling or interaction
              zIndex: 1,
            }}
          />

          {dealStages.map((dealStage, i) => {
            if (i === 20) console.log(dealStage);
            return (
              <Box
                key={i}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#F0F0F3',
                  minWidth: 450,
                  width: 450,
                  padding: 16,
                  marginRight: 16,
                  boxSizing: 'content-box',
                  borderRadius: 8,
                }}
              >
                <Box style={{ display: 'flex' }}>
                  <Skeleton
                    sx={{ bgcolor: 'black', marginBottom: '16px' }}
                    variant={'rounded'}
                    width={150}
                    height={27}
                  ></Skeleton>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                  }}
                >
                  {/* @ts-ignore */}
                  {dealsByDealStage[`${i}`].map((deal, j) => {
                    if (j === 20) console.log(deal);
                    return (
                      <Box
                        key={j}
                        style={{
                          backgroundColor: 'white',
                          padding: 8,
                          borderRadius: 8,
                          border: '1px solid #D0D2D8',
                          marginBottom: 12,
                        }}
                      >
                        <DealCardSkeleton isOnKanban={true}></DealCardSkeleton>
                      </Box>
                    );
                  })}

                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: 12,
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: '#FF6A00',
                        width: '154px',
                        height: '48px',
                        padding: '12px 16px 12px 16px',
                        borderRadius: '8px',
                      }}
                    >
                      <Skeleton
                        sx={{ bgcolor: '#ffffff' }}
                        variant={'rounded'}
                        width={80}
                        height={18}
                      ></Skeleton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
