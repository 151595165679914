import React, { useState } from 'react';
import {
  Avatar,
  Backdrop,
  Box,
  CardMedia,
  InputAdornment,
  Typography,
} from '@mui/material';
import { SearchInput } from './StyledComponent';
import { Search } from '@mui/icons-material';
import { GamifyIcon } from '../../components/GamifyIcon';
import Fuse from 'fuse.js';

export default function LinkedTeamMembersModal(props) {
  const { teamMembers, teamName, setShowModal, showModal } = props;
  console.log('teamMembers: ', teamMembers);

  const [modalSearchString, setModalSearchString] = useState('');
  const [searchData, setSearchData] = useState([]);

  const options = {
    keys: ['name', 'title'],
    includeScore: true,
    threshold: 0.2,
  };

  const data = teamMembers;
  const fuse = new Fuse(data, options);

  const handleClearSearch = () => {
    setModalSearchString('');
  };

  const cancelIconAdornment =
    modalSearchString.length > 0 ? (
      <Box
        sx={{ cursor: 'pointer' }}
        className={'clear-input-button'}
        onClick={handleClearSearch}
      >
        <InputAdornment position="end">
          <GamifyIcon
            icon={'delete'}
            color={'black'}
            // backgroundColor={'white'}
          ></GamifyIcon>
        </InputAdornment>
      </Box>
    ) : (
      <></>
    );

  return (
    <Backdrop
      sx={{ zIndex: 100001 }}
      open={showModal}
      onClick={(e) => {
        e.stopPropagation();
        if (!e.target.closest('.linked-team-member-modal-container')) {
          setShowModal(false);
        }
      }}
    >
      <Box
        className={'linked-team-member-modal-container'}
        style={{
          backgroundColor: 'white',
          height: 'fit-content',
          maxHeight: '70vh',
          width: '772px',
          overflowY: 'auto',
          padding: '0px 24px 24px 24px',
          borderRadius: '8px',
        }}
      >
        <Box
          className={'am-lt-modal-top-container'}
          style={{
            // position: 'fixed',
            zIndex: 100002,
            // display: 'flex',
            // justifyContent: 'space-between',
            // alignItems: 'center',
            // backgroundColor: 'white',
            // width: '92%',
            // maxWidth: '724px',
            // paddingTop: '24px',
            // paddingBottom: '24px',
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 16,
            }}
          >
            <Typography
              style={{
                color: '#222428',
                fontSize: 18,
                fontWeight: 700,
                textAlign: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              {`${teamName} members`}
            </Typography>
            <Box
              //   className={'am-lt-modal-cancel-button'}
              style={{
                cursor: 'pointer',
                maxWidth: 'fit-content',
                backgroundColor: '#F0F0F3',
                borderRadius: 1000,
                weight: 32,
                height: 32,
              }}
              onClick={() => {
                setShowModal(false);
                setModalSearchString('');
                setSearchData([]);
              }}
              disableRipple={true}
            >
              {/* <BackIcon></BackIcon>
              <Typography
                style={{
                  color: '#0B0B0C',
                  fontWeight: 600,
                  fontSize: 16,
                  marginLeft: 16,
                }}
              >
                Cancel
              </Typography> */}
              <GamifyIcon icon={'newX'}></GamifyIcon>
            </Box>
          </Box>
          <SearchInput
            sx={{
              width: '100%',
              border: 'none',
              backgroundColor: '#F2F2F2',
              marginBottom: '16px',
              '& .MuiOutlinedInput-root': {
                width: '100%',
              },
              '& .MuiOutlinedInput-input': {
                position: 'relative',
                width: '100%',
                maxWidth: '627px',
              },
            }}
            id="search"
            name="new-search" // changed search to new-search to avoid Chrome autofill
            variant="outlined"
            placeholder="Search"
            value={modalSearchString}
            onChange={(e) => {
              //   console.log(
              //     'AMLTModal onChangeText e.target.value: ',
              //     e.target.value
              //   );
              const searchedData = fuse.search(e.target.value);
              //   console.log(
              //     'AMLTModal onChangeText searchedData: ',
              //     searchedData
              //   );

              const formattedData = searchedData.map((data) => ({
                ...data,
                ...data.item,
              }));

              setModalSearchString(e.target.value);
              setSearchData(formattedData);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    backgroundColor: 'transparent',
                    width: '10px',
                  }}
                >
                  <Search sx={{ color: '#868686' }} />
                </InputAdornment>
              ),
              endAdornment: cancelIconAdornment,
            }}
          />
          <Box style={{ marginVertical: 16 }}>
            <Typography
              style={{ fontSize: 14, fontWeight: 700, paddingBottom: 16 }}
            >
              {/* TO DO: decide if this is needed */}
              All (
              {modalSearchString.length > 0
                ? searchData.length
                : data.length > 0
                ? data.length
                : 0}
              )
            </Typography>
          </Box>
        </Box>

        <Box style={{ marginTop: 173 }}>
          <Box
            style={{
              //   minHeight: 233,
              marginHorizontal: 16,
            }}
          >
            {/* use searchData when a modalSearchString is inputted, use data otherwise */}
            {modalSearchString.length > 0 ? (
              searchData.length > 0 ? (
                <>
                  {searchData.map((item, i) => {
                    return (
                      <Box
                        key={i}
                        className={'am-lt-list-item-button'}
                        style={{
                          width: '100%',
                          padding: 0,
                          borderRadius: 8,
                        }}
                        // onClick={() => handleItemToggle(item)}
                        disableRipple={true}
                      >
                        <Box className={'am-lt-list-item'}>
                          <Box className={'am-lt-list-item-left-container'}>
                            {/* Display user/team avatar if there is one, otherwise display initials */}
                            {/* Account for image type to account for potential gifs */}
                            {item.avatarFile ? (
                              item.imageType === 'picture' ||
                              item.imageType.split('/')[0] === 'image' ? (
                                <Avatar
                                  src={item.avatarFile}
                                  sx={{ margin: '5px 12px 5px 5px' }}
                                />
                              ) : (
                                <CardMedia
                                  image={item.imageName}
                                  component="img"
                                  sx={{
                                    backgroundColor: 'black',
                                    borderRadius: 1000,
                                    height: 40,
                                    width: 40,
                                    objectFit: 'contain',
                                    margin: '5px 12px 5px 5px',
                                  }}
                                />
                              )
                            ) : (
                              <Avatar
                                sx={{
                                  backgroundColor: '#868686',
                                  margin: '5px 12px 5px 5px',
                                }}
                              >
                                {item.initials
                                  ? item.initials
                                  : item.name[0].toUpperCase()}
                              </Avatar>
                            )}
                            <Box
                              className={'am-lt-list-item-left-text-container'}
                            >
                              <Box style={{ display: 'flex' }}>
                                <Typography
                                  style={{
                                    color: '#222428',
                                    fontSize: 16,
                                    fontWeight: 600,
                                  }}
                                >
                                  {item.name}
                                </Typography>
                                {item.status === 'disabled' && (
                                  <Typography
                                    style={{
                                      // color: '#FF5C77',
                                      color: '#E35050',
                                      fontSize: 16,
                                      fontWeight: 600,
                                      marginLeft: 4,
                                    }}
                                  >
                                    (Access{' '}
                                    {item.status[0].toUpperCase() +
                                      item.status.slice(1)}
                                    )
                                  </Typography>
                                )}
                              </Box>
                              <Typography
                                style={{
                                  color: '#868686',
                                  fontSize: 14,
                                  fontWeight: 500,
                                }}
                              >
                                {item.title}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            className={'am-lt-list-item-right-container'}
                          ></Box>
                        </Box>
                      </Box>
                    );
                  })}
                </>
              ) : (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 82,
                  }}
                >
                  <Typography>
                    {'No members match this search, please try another'}
                  </Typography>
                </Box>
              )
            ) : data.length > 0 ? (
              <>
                {data.map((item, i) => {
                  return (
                    <Box
                      key={i}
                      className={'am-lt-list-item-button'}
                      style={{ width: '100%', padding: 0, borderRadius: 8 }}
                      //   onClick={() => handleItemToggle(item)}
                      disableRipple={true}
                    >
                      <Box className={'am-lt-list-item'}>
                        <Box className={'am-lt-list-item-left-container'}>
                          {/* Display user/team avatar if there is one, otherwise display initials */}
                          {/* Account for image type to account for potential gifs */}
                          {item.avatarFile ? (
                            item.imageType === 'picture' ||
                            item.imageType.split('/')[0] === 'image' ? (
                              <Avatar
                                src={item.avatarFile}
                                sx={{ margin: '5px 12px 5px 5px' }}
                              />
                            ) : (
                              <CardMedia
                                image={item.imageName}
                                component="img"
                                sx={{
                                  backgroundColor: 'black',
                                  borderRadius: 1000,
                                  height: 40,
                                  width: 40,
                                  objectFit: 'contain',
                                  margin: '5px 12px 5px 5px',
                                }}
                              />
                            )
                          ) : (
                            <Avatar
                              sx={{
                                backgroundColor: '#868686',
                                margin: '5px 12px 5px 5px',
                              }}
                            >
                              {item.initials
                                ? item.initials
                                : item.name[0].toUpperCase()}
                            </Avatar>
                          )}
                          <Box
                            className={'am-lt-list-item-left-text-container'}
                          >
                            <Box style={{ display: 'flex' }}>
                              <Typography
                                style={{
                                  color: '#222428',
                                  fontSize: 16,
                                  fontWeight: 600,
                                }}
                              >
                                {item.name}
                              </Typography>
                              {item.status === 'disabled' && (
                                <Typography
                                  style={{
                                    // color: '#FF5C77',
                                    color: '#E35050',
                                    fontSize: 16,
                                    fontWeight: 600,
                                    marginLeft: 4,
                                  }}
                                >
                                  (Access{' '}
                                  {item.status[0].toUpperCase() +
                                    item.status.slice(1)}
                                  )
                                </Typography>
                              )}
                            </Box>
                            <Typography
                              style={{
                                color: '#868686',
                                fontSize: 14,
                                fontWeight: 500,
                              }}
                            >
                              {item.title}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          className={'am-lt-list-item-right-container'}
                        ></Box>
                      </Box>
                    </Box>
                  );
                })}
              </>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 82,
                }}
              >
                <Typography>
                  {'No members match this search, please try another'}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
}
