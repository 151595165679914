import React, { useState } from 'react';
import NewGameView from './NewGame.view';
import useNewGame from './hook/useNewGame';

const NewGameContainer = () => {
  const {
    error,
    newGameState,
    loading,
    onChangeNewGameState,
    onSaveGame,
    shouldDisableNextOrFinishButton,
    errorMessages,
    setShouldDisableNextOrFinishButton,
  } = useNewGame();
  const steps = ['Step 1', 'Step 2', 'Step 3'];
  const [activeStep, setActiveStep] = useState(0);

  const onChangeActiveStep = (newActiveStep: number) => {
    if (loading || shouldDisableNextOrFinishButton) return;

    if (newActiveStep < 0 || newActiveStep >= steps.length) return;
    setActiveStep(newActiveStep);
  };

  return (
    <NewGameView
      steps={steps}
      activeStep={activeStep}
      onChangeActiveStep={onChangeActiveStep}
      newGameState={newGameState}
      onChangeNewGameState={onChangeNewGameState}
      onSaveGame={onSaveGame}
      loading={loading}
      error={error}
      errorMessages={errorMessages}
      shouldDisableNextOrFinishButton={shouldDisableNextOrFinishButton}
      setShouldDisableNextOrFinishButton={setShouldDisableNextOrFinishButton}
    />
  );
};

export default NewGameContainer;
