import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { API, Storage } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
// import fetchProfile from '../profile/fetchProfile';
import * as utils from '../../common/Utilities';
import Dropzone from 'react-dropzone';
import DisplayMedia from '../../components/DisplayMedia';
import { GamifyIcon } from '../../components/GamifyIcon';
import { GamifyToast } from '../../common/CustomToasts';
import {
  FormInput,
  // FormSelect,
  // FormLevelsInput,
  FormModalInput,
} from '../../common/FormInputs';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import TeamCard from '../profile/TeamCard.js';
import AddMemberLinkTeamModal from '../teams/AddMemberLinkTeamModal.js';
// import fetchTeamLevels from '../teams/fetchTeamLevels';
import UserFormPageSkeleton from './userSkeletons/UserFormPageSkeleton';
import {
  addUserAction,
  updateUserAction,
  updateProfileAction,
  selectAllUsers,
  selectAllProfiles,
} from '../../store/reducers/users';
import getUserAvatar from '../../common/utils/getUserAvatar';

export default function UserFormPage(props) {
  const {
    userId,
    user,
    setShowUserFormModal,
    // onTriggerUsersRefetch,
    focusCounter,
    setFocusCounter,
    isModal,
  } = props;

  // NOTE: The following is needed for if UserFormPage is used by itself at an 'edit/<userID>' route, but not anymore as a modal is used now
  // const { userId } = useParams();
  // const location = useLocation();
  // const [routeBackToUserStatsProfile, setRouteBackToUserStatsProfile] =
  //   useState(false);
  // const [routeBackToUserAboutProfile, setRouteBackToUserAboutProfile] =
  //   useState(false);
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxUsers = useSelector(selectAllUsers);
  const reduxProfiles = useSelector(selectAllProfiles);

  const [isLoading, setIsLoading] = useState(false);
  // const [teamLevels, setTeamLevels] = useState([]);
  // const [levelNum, setLevelNum] = useState(null);
  const [isFormDataLoading, setIsFormDataLoading] = useState(false);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);

  const newUser = {
    id: '',
    username: '',
    email: '',
    phoneNumber: '',
    name: '',
    birthdate: '',
    imageType: '',
    imageName: '',
    location: '',
    initials: '',
    pronoun: '',
    phoneNumberVisibility: 'private',
    nameVisibility: 'public',
    emailVisibility: 'private',
    birthdateVisibility: 'circles',
    locationVisibility: 'circles',
    pictureVisibility: 'circles',
    nonCirclePoints: '',
    uploadPictureTitle: 'Upload Picture / Video',
    title: '',
    avatarID: '',
    levelID: '',
    extID: '',
    functionalRoles: '',
    // role: 'member', // member, admin, moderator
    // functionalRoles: 'setter', // setter, closer, manager
    teams: [],
  };

  // const roleList = [
  //   { value: 'member', label: 'Member' },
  //   { value: 'admin', label: 'Admin' },
  //   { value: 'moderator', label: 'Moderator' },
  // ];

  // const functionalRoleList = [
  //   { value: 'setter', label: 'Setter' },
  //   { value: 'closer', label: 'Closer' },
  //   { value: 'manager', label: 'Manager' },
  // ];

  const [initialValues, setInitialValues] = useState(newUser);

  const phoneRegEx = /^\+?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

  // function formatPhoneNumber(phoneNumberString) {
  //   const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  //   const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  //   }
  //   return null;
  // }

  const userValidationSchema = yup.object().shape({
    name: yup
      .string()
      .label('Name')
      .required()
      .min(2, 'Your name is too short.  Please enter at least 2 characters')
      .max(32, 'Your name is too long.  Please enter less than 32 characters'),
    title: yup
      .string()
      .label('Title')
      .min(2, 'Your title is too short.  Please enter at least 2 characters')
      .max(32, 'Your title is too long.  Please enter less than 32 characters'),
    email: yup
      .string()
      .label('Email')
      .email('Please enter a valid email addresss')
      .required(),
    phoneNumber: yup
      .string()
      .label('Phone Number')
      .matches(phoneRegEx, 'Please enter a valid phone number')
      .required(),
    // title: yup.string(),
    // location: yup.string(),
    // role: yup.string().required('*Required'),
    // functionalRoles: yup.string(),
    // teams: yup.array(),
  });

  useEffect(() => {
    // NOTE: location state no longer needed for routing now that UserFormPage is used in a modal and not at a separate route at '/edit/<userID>'.
    // // use location state to determine whether user navigated from ProfileStatsPage, ProfileAboutPage, of UsersScreen for correct rerouting on cancel/update
    // if (location?.state?.statsProfile) setRouteBackToUserStatsProfile(true);
    // if (location?.state?.aboutProfile) setRouteBackToUserAboutProfile(true);

    async function prepareFormData() {
      setIsFormDataLoading(true);

      // NOTE: User level is not used, this doesn't need to be fetched anymore
      // const levels = await fetchTeamLevels();
      // setTeamLevels(levels);

      if (userId) {
        // const user = await fetchProfile(userId);

        // NOTE: user can be passed to UserFormModal as a prop now that a separate page at '/edit/<userID>' isn't used
        // const user = location?.state?.user;
        // console.log('Edit User user: ', user);

        const userFunctionalRoles = user.functionalRoles[0] || '';

        const ourUser = {
          id: user.id,
          username: user.username || '',
          email: user.email || '',
          phoneNumber: user.phoneNumber || '',
          name: user.name || '',
          birthdate: user.birthdate || '',
          imageName: user.imageName || '',
          imageType: user.imageType || '',
          location: user.location || '',
          initials: user.initials || '',
          pronoun: user.pronoun || '',
          phoneNumberVisibility: 'private',
          nameVisibility: 'public',
          emailVisibility: 'private',
          birthdateVisibility: 'circles',
          locationVisibility: 'circles',
          pictureVisibility: 'circles',
          nonCirclePoints: user.nonCirclePoints || '',
          uploadPictureTitle: 'Upload Picture / Video',
          title: user.title || '',
          avatar: user.avatarFile || user.avatar || '',
          avatarID: user.avatarID || '',
          levelID: user.levelID || '',
          extID: user.extID || '',
          role: user.role || '',
          functionalRoles: userFunctionalRoles || '',
          memberType: user.memberType || '',
          enabled: user.status === 'active',
          uploadingPicture: false,
          orgID: user.orgID || '',
        };

        setInitialValues(ourUser);

        // // use user.levelID of existing team to set current levelNum for Link Teams modal
        // for (const level of levels) {
        //   if (level.value === user.levelID) setLevelNum(level.levelNum);
        // }
      }

      setIsFormDataLoading(false);
    }

    prepareFormData();
  }, [userId]);

  async function doSubmit(values, actions) {
    // console.log('doSubmit args (values, actions): ', values, actions);
    actions.setSubmitting(true);
    setIsLoading(true);

    const ret = await createUser(actions, values);
    if (ret) {
      // Note: Doing this to ensure that the records are saved already, to avoid race condition
      setTimeout(() => {
        // route.params.onExit();

        // NOTE: No longer need to refetch due to use of redux store (addUserAction action dispatch is done in createUser)
        // onTriggerUsersRefetch();
        setShowUserFormModal(false);

        // NOTE: Use the navigates in the following code if switching back to using UserFormPage at '/edit/<userID>', no longer needed while the form is used in a modal
        if (userId) {
          GamifyToast.success(`${values.name} updated successfully`);
          // if (routeBackToUserStatsProfile) {
          //   // Back to the user stats profile
          //   navigate(`/people/stats/${userId}`);
          // } else if (routeBackToUserAboutProfile) {
          //   // Back to the user about profile
          //   navigate(`/people/about/${userId}`);
          // } else {
          //   // Back to the users screen if that's where user navigated from
          //   navigate('/people');
          // }
        } else {
          GamifyToast.success(
            `${values.name} created successfully. This user can now access Gamify`
          );
          // navigate(`/people`);
        }
      }, 500);
    }

    setTimeout(() => {
      actions.resetForm();
      setHasSubmitted(false);
      actions.setSubmitting(false);
      setIsLoading(false);
    }, 500);
  }

  async function updateUser(actions, values) {
    // console.log('updateUser values: ', values);

    try {
      // console.log('updateUser BEFORE await');
      await API.graphql({
        query: mutations.modifyPerson,
        variables: {
          person: JSON.stringify(values),
        },
      });
      // console.log('updateUser AFTER await');

      // // TO DO: add in onChangeRole and onChangeStatus (changeRole may be excluded to align with mobile)
      // await Promise.all([
      //   onChangeRole(values.role),
      //   onChangeStatus(user, values?.enabled),
      // ]);

      // onTriggerTableRefetch();

      // Need to update the user and profile that was in the redux state (if they exist), then dispatch the updateUser action using that updated user:
      const originalReduxUser = reduxUsers[values.id];

      if (originalReduxUser) {
        const updatedReduxUser = {
          ...originalReduxUser,
          ...values,
        };
        dispatch(updateUserAction(updatedReduxUser));
      }

      const originalReduxProfile = reduxProfiles[values.id];

      if (originalReduxProfile) {
        const updatedReduxProfile = {
          ...originalReduxProfile,
          user: {
            ...originalReduxProfile.user,
            ...values,
          },
        };
        dispatch(updateProfileAction(updatedReduxProfile));
      }

      return true;
    } catch (err) {
      console.error(err);
      GamifyToast.error('There was a problem updating this user.');

      return false;
    }
  }

  async function createUser(actions, values) {
    // console.log('createUser values: ', values);
    if (values.id !== '') {
      const ret = await updateUser(actions, values);
      return ret;
    }

    const names = values.name.split(' ');
    let initials = '';
    if (names.length > 1) {
      initials = names[0][0] + names[1][0];
    } else if (names.length === 1) {
      initials = names[0][0];
    }

    // console.log('phoneNumber: ', values.phoneNumber);
    const cleanedPhoneNumber = utils.cleanPhoneNumber(values.phoneNumber);
    // console.log('cleanedPhoneNumber: ', cleanedPhoneNumber);

    try {
      const user = {
        username: values.email,
        email: values.email,
        phoneNumber: cleanedPhoneNumber,
        name: values.name,
        birthdate: utils.graphqlDateFormat(values.birthdate) || '',
        imageName: values.imageName,
        imageType: values.imageType,
        location: values.location,
        initials,
        pronoun: values.pronoun == null ? '' : values.pronoun,
        phoneNumberVisibility: values.phoneNumberVisibility,
        emailVisibility: values.emailVisibility,
        birthdateVisibility: values.birthdateVisibility,
        locationVisibility: values.locationVisibility,
        pictureVisibility: values.pictureVisibility,
        title: values.title,
        avatarID: values.avatarID == null ? '' : values.avatarID,
        orgID: global.me.orgID,
        levelID: values.levelID,
        autoEnable: true,
        functionalRoles: values.functionalRoles,
      };

      // console.log('createUser user: ', user);
      const createPersonRes = await API.graphql({
        query: mutations.createPerson,
        variables: {
          person: JSON.stringify(user),
        },
      });
      const result = JSON.parse(createPersonRes.data.createPerson);
      // console.log('createUser createPersonRes: ', createPersonRes);

      // TO DO: add newly created user to teams selected
      await addUserToTeams(values.teams, result.user_id);

      // NOTE: May be best to return the following in result above, but this works for now
      // Add the user ID, avatar (only if there is a user.imageName), createdAt,'member' memberType in orgs, and status to the user object and add to the users redux store instead of refetching:
      user.id = result.user_id;

      user.status = 'active';

      const now = new Date();
      const timezoneOffset = now.getTimezoneOffset() * 60000;
      const adjustedDate = new Date(now.getTime() - timezoneOffset);
      user.createdAt = adjustedDate.toISOString();

      user.orgs = {};
      user.orgs.items = [];
      user.orgs.items.push({ status: 'accepted', memberType: 'member' });

      if (user.imageName) {
        user.avatar = await getUserAvatar(user.imageName, user.imageType, true);
      }

      dispatch(addUserAction(user));

      return true;
    } catch (err) {
      console.error('createUser(): error saving user' + ':' + err.message);
      GamifyToast.error('There was an error saving your user');

      return false;
    }
  }

  const addUserToTeams = async (teams, userId) => {
    // console.log('addProfileToTeams teams: ', teams);
    if (!teams.length) return;

    const requests = [];
    for (const team of teams) {
      requests.push(
        API.graphql({
          query: mutations.modifyCircleMember,
          variables: {
            circleID: team.id,
            personID: userId,
            status: 'accepted',
            role: '',
          },
        })
      );
    }

    const allPromise = Promise.all(requests);

    allPromise
      .then(() => {
        // onTriggerTeamRefetch();
      })
      .catch((error) => {
        console.error('addUserToTeams error: ', error);
      });
  };

  const handleCancel = () => {
    setCancelOpen(true);
  };

  if (isFormDataLoading) {
    return <UserFormPageSkeleton></UserFormPageSkeleton>;
  }

  // TO DO: Make css file for UserFormPage based off TeamFormPage.css and go through className properties and update team to user

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnMount={true} // Need this to validate before first submit attempt
      onSubmit={(values, actions) => {
        // console.log('TeamFormPage onSubmit');

        doSubmit(values, actions);
      }}
      validationSchema={userValidationSchema}
    >
      {(formikProps) => (
        <Box
          className={
            isModal ? 'team-form-modal-container' : 'team-form-page-container'
          }
        >
          {/* TO DO: use DynamicConfirmModal instead of CancelTeamFormModal */}
          <DynamicConfirmModal
            showDynamicConfirmModal={cancelOpen}
            setShowDynamicConfirmModal={setCancelOpen}
            zIndex={100002}
            title={'Leave without saving changes?'}
            subtitle={
              'If you leave without saving, your progress will be lost.'
            }
            leftAlignText={true}
            confirmButtonText={'Keep editing'}
            cancelButtonText={'Leave'}
            onConfirm={() => {
              setCancelOpen(false);
            }}
            onCancel={() => {
              setShowUserFormModal(false);
              setCancelOpen(false);
              formikProps.resetForm();

              // NOTE: The following is only needed if UserFormPage is used at a separate 'edit/<userID>' route, but it is now used in a modal
              // if (userId) {
              //   if (routeBackToUserStatsProfile) {
              //     // Back to the user stats profile
              //     navigate(`/people/stats/${userId}`);
              //   } else if (routeBackToUserAboutProfile) {
              //     // Back to the user about profile
              //     navigate(`/people/about/${userId}`);
              //   } else {
              //     // Back to the users screen if that's where user navigated from
              //     navigate('/people');
              //   }
              // } else {
              //   navigate(`/people`);
              // }
            }}
          ></DynamicConfirmModal>
          {/* <CancelTeamFormModal
        props={{ cancelOpen, setCancelOpen, teamId, routeBackToTeamPage }}
      ></CancelTeamFormModal> */}
          <Box
            className={
              isModal
                ? 'team-form-modal-top-container'
                : 'team-form-page-top-container'
            }
          >
            <Box
              className={
                isModal
                  ? 'team-form-modal-cancel-container'
                  : 'team-form-page-cancel-container'
              }
            >
              <Box className={'team-form-cancel-button-container'}>
                <Button
                  className={
                    isModal
                      ? 'team-form-modal-cancel-button'
                      : 'team-form-page-cancel-button'
                  }
                  onClick={() => {
                    if (formikProps.dirty) {
                      handleCancel();

                      // NOTE: Refocus counter reset to 0, so nivo admin user gets warned every first time they click in the extID field
                      setFocusCounter(0);
                    } else {
                      setShowUserFormModal(false);

                      // NOTE: Refocus counter reset to 0, so nivo admin user gets warned every first time they click in the extID field
                      setFocusCounter(0);

                      // NOTE: The following is only needed if TeamFormPage is used at a separate 'edit/<teamID>' route, but now it is used in a modal
                      // No changes that need to be saved, can cancel w/o the cancel modal
                      // if (userId) {
                      //   if (routeBackToUserStatsProfile) {
                      //     // Back to the user stats profile
                      //     navigate(`/people/stats/${userId}`);
                      //   } else if (routeBackToUserAboutProfile) {
                      //     // Back to the user about profile
                      //     navigate(`/people/about/${userId}`);
                      //   } else {
                      //     // Back to the users screen if that's where user navigated from
                      //     navigate('/people');
                      //   }
                      // } else {
                      //   // Back to the users screen
                      //   navigate('/people');
                      // }
                    }
                  }}
                  disableRipple={true}
                >
                  <GamifyIcon icon={'back'}></GamifyIcon>
                  <Typography
                    className={
                      isModal
                        ? 'team-form-modal-cancel-button-text'
                        : 'team-form-page-cancel-button-text'
                    }
                  >
                    Cancel
                  </Typography>
                </Button>
              </Box>
              <Box
                className={
                  isModal
                    ? 'team-form-modal-title-container'
                    : 'team-form-page-title-container'
                }
              >
                <Typography className={'team-form-title-text'}>
                  {userId ? `Edit Profile` : `New Profile`}
                </Typography>
              </Box>
              <Box className={'team-form-place-holder-container'}></Box>
            </Box>
          </Box>
          <Box
            className={
              isModal ? 'modal-team-form-container' : 'page-team-form-container'
            }
          >
            <ImagePreview
              formikProps={formikProps}
              isModal={isModal}
            ></ImagePreview>
            <AboutForm
              formikProps={formikProps}
              hasSubmitted={hasSubmitted}
              userId={userId}
              // teamLevels={teamLevels}
              // setLevelNum={setLevelNum}
              // roleList={roleList}
              // functionalRoleList={functionalRoleList}
              focusCounter={focusCounter}
              setFocusCounter={setFocusCounter}
            ></AboutForm>
            {/* No need to allow team adding after user is created, this is done in the ProfileAboutPage */}
            {!userId && <TeamsForm formikProps={formikProps}></TeamsForm>}
            {/* <MembersForm formikProps={formikProps}></MembersForm> */}
            {/* {levelNum !== null && levelNum !== teamLevels.length - 1 && (
              <LinkedTeamsForm
                formikProps={formikProps}
                levelNum={levelNum}
              ></LinkedTeamsForm>
            )} */}
            {/* <AdvancedOptionsForm
              formikProps={formikProps}
            ></AdvancedOptionsForm> */}
            <Box className={'save-team-button-container'}>
              <Button
                className={'save-team-button'}
                onClick={() => {
                  // console.log(
                  //   'TeamFormPage onClick formikProps.values: ',
                  //   formikProps.values
                  // );

                  setHasSubmitted(true);

                  // console.log(
                  //   'TeamFormPage onClick formikProps.isValid: ',
                  //   formikProps.isValid
                  // );

                  if (!formikProps.isValid) {
                    GamifyToast.error('Please fix all errors and try again');
                  }

                  formikProps.handleSubmit();
                }}
                disableRipple={true}
              >
                {isLoading ? (
                  <CircularProgress
                    style={{ color: 'white', height: 24, width: 24 }}
                  ></CircularProgress>
                ) : (
                  <Typography className={'save-team-button-text'}>
                    {/* TO DO: change text based on design */}
                    {userId ? 'Update profile' : 'Save changes'}
                  </Typography>
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

const onDropTeamImage = async (
  droppedFile,
  formikProps /* , setImageIsLoading */
) => {
  // setImageIsLoading(true);
  const formats = ['jpeg', 'jpg', 'png', 'gif'];
  const image = droppedFile[0]; // the imageFile
  console.log('onDropDealImage image: ', image);
  const imageName = image.name;
  if (!formats.includes(imageName.split('.').pop().toLowerCase())) {
    GamifyToast.error(
      'User image format must be one of the following - png, gif, jpeg'
    );
    return;
  }

  // generate random string and take image format to create new file name to match file names on deals in lyferize
  const imageNameFormat = imageName.split('.').pop().toLowerCase();
  const randomString = utils.generateRandomImageFileNameString();
  const newImageName = `${randomString}.${imageNameFormat}`;

  // upload image
  const uploadedImage = await Storage.put(newImageName, image, {
    contentType: image.type,
  });

  // set formikProps
  formikProps.setFieldValue('imageName', uploadedImage.key);
  formikProps.setFieldValue('imageType', image.type);
  // setImageIsLoading(false);
};

function onDeleteImage(formikProps) {
  formikProps.setFieldValue('avatar', '');
  formikProps.setFieldValue('imageName', '');
  formikProps.setFieldValue('imageType', '');
}

function AddImage({ formikProps, isModal /* , setImageIsLoading */ }) {
  return (
    <Box
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: isModal ? 'flex-start' : 'center',
        width: '100%',
        marginBottom: 24,
      }}
    >
      <Avatar style={{ height: 240, width: 240 }}>
        <Typography style={{ fontSize: 128 }}>
          {formikProps.values.initials ||
            utils.getInitials(formikProps.values.name)}
        </Typography>
      </Avatar>
      <Dropzone
        onDrop={(droppedFile) =>
          onDropTeamImage(droppedFile, formikProps /* , setImageIsLoading */)
        }
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            style={{
              position: 'absolute',
              bottom: 16,
              right: isModal ? 0 : 250,
              left: isModal ? 182 : 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              width: 40,
              minWidth: 40,
              height: 40,
              border: '1px solid #D0D2D8',
              borderRadius: 1000,
            }}
            sx={{
              cursor: 'pointer',
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <GamifyIcon icon={'edit'}></GamifyIcon>
          </Box>
        )}
      </Dropzone>
    </Box>
  );
}

function ImagePreview({ formikProps, isModal }) {
  const [imageName, setImageName] = useState(formikProps.values.imageName);
  // const [imageIsLoading, setImageIsLoading] = useState(false);
  // console.log('ImagePreview formikProps: ', formikProps);

  useEffect(() => {
    setImageName(formikProps.values.imageName);
  }, [formikProps.values.imageName]);

  // TO DO: build UserFormAddImageSkeleton
  // if (imageIsLoading) {
  //   return <UserFormAddImageSkeleton></UserFormAddImageSkeleton>;
  // }

  if (imageName) {
    return (
      <>
        <Box
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: isModal ? 'flex-start' : 'center',
            width: '100%',
            marginBottom: 24,
          }}
        >
          <DisplayMedia
            //   imageName={formikProps.values.imageName}
            imageName={imageName}
            imageType={formikProps.values.imageType}
            width={240}
            height={240}
            borderRadius={1000}
            // width={'100%'}
            // height={361}
            // borderRadius={'16px'}
          ></DisplayMedia>
          <Dropzone
            onDrop={(droppedFile) =>
              onDropTeamImage(droppedFile, formikProps /*, setImageIsLoading */)
            }
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                className={'team-form-file-upload-container'}
                style={{
                  position: 'absolute',
                  bottom: 16,
                  right: isModal ? 0 : 250,
                  left: isModal ? 182 : 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  width: 40,
                  minWidth: 40,
                  height: 40,
                  border: '1px solid #D0D2D8',
                  borderRadius: 1000,
                }}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <GamifyIcon icon={'edit'}></GamifyIcon>
              </Box>
            )}
          </Dropzone>
          <Box
            style={{
              position: 'absolute',
              bottom: 16,
              right: isModal ? 0 : 200,
              left: isModal ? 232 : 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              width: 40,
              minWidth: 40,
              height: 40,
              border: '1px solid #D0D2D8',
              borderRadius: 1000,
            }}
            sx={{
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              onDeleteImage(formikProps);
            }}
          >
            <GamifyIcon icon={'trash'}></GamifyIcon>
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <AddImage
        formikProps={formikProps}
        isModal={isModal}
        // setImageIsLoading={setImageIsLoading}
      ></AddImage>
    );
  }
}

function AboutForm({
  formikProps,
  hasSubmitted,
  userId,
  // teamLevels,
  // setLevelNum,
  // roleList,
  // functionalRoleList,
  focusCounter,
  setFocusCounter,
}) {
  console.log('AboutForm formikProps: ', formikProps);

  return (
    <Box
      style={{
        backgroundColor: '#F0F0F3',
        borderRadius: 8,
        paddingBottom: 0,
        marginBottom: 24,
      }}
    >
      <Box
        style={{
          width: '100%',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backgroundColor: '#D0D2D8',
          padding: 16,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography style={{ fontSize: 18, color: '#222428', fontWeight: 600 }}>
          About
        </Typography>
        <GamifyIcon icon={'about'} color="#323232" />
      </Box>
      <Box style={{ padding: '24px 16px 8px 16px' }}>
        <FormInput
          inputName={'name'}
          formikProps={formikProps}
          hasSubmitted={hasSubmitted}
          required={true}
        ></FormInput>
        <FormInput
          inputName={'title'}
          formikProps={formikProps}
          hasSubmitted={hasSubmitted}
        ></FormInput>
        {/* NOTE: User level is not used, so this input has been removed */}
        {/* NOTE (old): setLevelNum and levelNum only needed for Linking Teams, not assigning users to teams */}
        {/* <FormLevelsInput
          value={formikProps.values.levelID}
          fieldSetter={(value) => formikProps.setFieldValue('levelID', value)}
          hasSubmitted={hasSubmitted}
          linkedTeams={[]}
          teamLevels={teamLevels}
          // setLevelNum={setLevelNum}
          title={'Level'}
          required={false}
        ></FormLevelsInput> */}
        <FormInput
          inputName={'location'}
          formikProps={formikProps}
          hasSubmitted={hasSubmitted}
        ></FormInput>
        <FormInput
          inputName={'email'}
          formikProps={formikProps}
          hasSubmitted={hasSubmitted}
          required={true}
        ></FormInput>
        <FormInput
          inputName={'phoneNumber'}
          formikProps={formikProps}
          hasSubmitted={hasSubmitted}
          required={true}
        ></FormInput>
        {/* <FormSelect
          value={formikProps.values.functionalRoles}
          fieldSetter={(value) => {
            // console.log('value: ', value);
            if (value !== '') {
              formikProps.setFieldValue('functionalRoles', value);
            }
          }}
          selectList={functionalRoleList}
          title={'Role'}
        ></FormSelect>
        <FormSelect
          value={formikProps.values.role}
          fieldSetter={(value) => {
            // console.log('value: ', value);
            if (value !== '') {
              formikProps.setFieldValue('role', value);
            }
          }}
          selectList={roleList}
          title={'Access'}
        ></FormSelect> */}
        {/* For GG-150, Nivo wants admin to have edit access to extID and non-admin to view extID w/o editing */}
        {global.me.orgID === 'nivo' &&
          (global.me.isAdmin ? (
            <FormInput
              inputName={'extID'}
              formikProps={formikProps}
              hasSubmitted={hasSubmitted}
              focusCounter={focusCounter}
              setFocusCounter={setFocusCounter}
            />
          ) : userId ? (
            <Box
              style={{
                position: 'relative',
                width: '100%',
                marginBottom: 16,
              }}
            >
              <Typography
                onClick={() => console.log('THIS FIELD----->', 'extID')}
                style={{
                  fontSize: 12,
                  fontWeight: 700,
                  color: '#0B0B0C',
                  marginBottom: 8,
                }}
              >
                Ext ID
              </Typography>
              <Typography style={{ fontSize: 18 }}>
                {formikProps.values.extID}
              </Typography>
            </Box>
          ) : (
            <></>
          ))}
      </Box>
    </Box>
  );
}

function TeamsForm({ formikProps }) {
  console.log('TeamsForm formikProps: ', formikProps);
  const [showAddUserToTeamsModal, setShowAddUserToTeamsModal] = useState(false);
  // const [isTeamListLoading, setIsTeamListLoading] = useState(false);
  const [teamsShowAll, setTeamsShowAll] = useState(false);

  const onSubmitTeams = async (newTeams) => {
    // console.log('onSubmitTeams newTeams: ', newTeams);

    // setIsTeamListLoading(true);

    const numNewTeams = newTeams.length;

    // setTimeout(() => {
    //   onTriggerTeamRefetch();
    // }, 500);

    setTimeout(() => {
      if (numNewTeams === 0) {
        console.log(`${formikProps.values.name} not added to any new teams`);
      } else if (numNewTeams === 1) {
        // newTeams is only the teams the user is to be added to (the only team in this case), don't need to filter for the new team in adding user to multiple teams use cases
        const newTeam = newTeams[0];
        GamifyToast.success(
          `${formikProps.values.name} will be added to ${newTeam.name} after changes are saved and ${formikProps.values.name} is created`
        );
      } else {
        GamifyToast.success(
          `${formikProps.values.name} will be added to ${numNewTeams} teams after changes are saved and ${formikProps.values.name} is created`
        );
      }
      // setIsTeamListLoading(false);
    }, 750);
  };

  const onRemoveTeamFromState = (teamId) => {
    console.log('onRemoveTeamFromState teams: ', formikProps.values.teams);
    // setIsTeamListLoading(true);
    formikProps.setFieldValue(
      'teams',
      formikProps.values.teams.filter((team) => team.id !== teamId)
    );
    // setIsTeamListLoading(false);
  };

  return (
    <Box
      style={{
        backgroundColor: '#F0F0F3',
        borderRadius: 8,
        paddingBottom: 0,
        marginBottom: 24,
      }}
    >
      <Box
        style={{
          width: '100%',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backgroundColor: '#D0D2D8',
          padding: 16,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography style={{ fontSize: 18, color: '#222428', fontWeight: 600 }}>
          {`Teams (${formikProps.values.teams.length})`}
        </Typography>
        <GamifyIcon icon={'users'} color="#323232" />
      </Box>
      <Box style={{ padding: '24px 16px 8px 16px' }}>
        <FormModalInput
          onClick={() => setShowAddUserToTeamsModal(true)}
          gamifyIcon={'addUser'}
          label={`Add to Teams`}
          hasItems={formikProps.values.teams.length > 0}
        ></FormModalInput>
        {/* {isTeamListLoading && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom:
                formikProps.values.teams.length > 0 ? '8px' : '20px',
            }}
          >
            <CircularProgress style={{ color: 'black' }}></CircularProgress>
          </Box>
        )} */}
        {formikProps.values.teams
          .map((team, i) => (
            <>
              <TeamCard
                key={i}
                team={team}
                user={formikProps.values}
                // rolePositionObj={rolePositionObj}
                // setRolePositionObj={setRolePositionObj}
                onRemoveTeamFromState={onRemoveTeamFromState}
                // onTriggerRefetch={onTriggerRefetch}
                isOnUserForm={true}
              ></TeamCard>
            </>
          ))
          .slice(0, teamsShowAll ? formikProps.values.teams.length : 5)}
        {formikProps.values.teams.length > 5 && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: 16,
              marginBottom: 16,
            }}
          >
            <Box
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              style={{ width: 'fit-content', height: 'fit-contnet' }}
            >
              <Typography
                onClick={() => setTeamsShowAll(!teamsShowAll)}
                style={{ color: '#FF6A00', fontSize: 16, fontWeight: 500 }}
              >
                {!teamsShowAll ? 'Show All' : 'Hide All'}
              </Typography>
            </Box>
          </Box>
        )}
        <AddMemberLinkTeamModal
          title={`Add to Teams`}
          team={null} // not needed for adding members
          teamLevelNumIdMap={null} // not needed for adding members
          teamLevelNumNameMap={null} // not needed for adding members
          noAutoSelect={true}
          pickCircles={true}
          pickUsers={false}
          update={null}
          filter={global.me.orgID}
          itemValue={''}
          onSubmit={(newTeams) => {
            // console.log('AMLT Modal newTeams onSubmit: ', newTeams);

            formikProps.setFieldValue('teams', newTeams);

            onSubmitTeams(newTeams);
          }}
          hideInitialChecked
          initialActiveMembers={formikProps.values.teams}
          setShowModal={setShowAddUserToTeamsModal}
          showModal={showAddUserToTeamsModal}
        ></AddMemberLinkTeamModal>
      </Box>
    </Box>
  );
}
