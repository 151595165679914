import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { maintainProduct } from '../../../../graphql/mutations';
import { GamifyToast } from '../../../common/CustomToasts';
import { Product } from '../ProductsScreen/types';

interface MaintainProductInput {
  id: string;
  categoryID: string;
  name: string;
  description: string;
  listPrice: number | null;
  units: string | null;
  productCode: string;
  productFamily: string;
  frequency?: string;
  status?: string;
}

export const useMaintainProductMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (productInput: MaintainProductInput) => {
      const input = {
        // @ts-ignore
        orgID: global.me.orgID,
        ...productInput,
      };

      const result = await API.graphql(
        graphqlOperation(maintainProduct, {
          productInfo: JSON.stringify(input),
        })
      );

      return (result as any).data.maintainProduct as Product;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['products'] });
      GamifyToast.success('Product saved successfully');
    },
    onError: (error: Error) => {
      console.error('Error saving product:', error);
      GamifyToast.error('There was an error saving your product.');
    },
  });
};
