import { createApi } from '@reduxjs/toolkit/query/react';
import { API } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import Me from '../../_types/Me';
import AppSettings from '../../_types/AppSettings';

type ApiResponse = {
  data: {
    getMyInfo: string;
  };
};

type Data = {
  me: Me;
  appSettings: AppSettings;
};

const graphQlQuery = () => async (parameters: any) => {
  try {
    const result = await API.graphql({
      query: parameters.query,
      variables: parameters.variables,
    });

    return { data: result };
  } catch (error) {
    console.error(error);
    return { error: 'Error making GraphQL query: ' + error };
  }
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: graphQlQuery(),
  endpoints: (builder) => ({
    getUserInformation: builder.query({
      query: () => ({
        query: queries.getMyInfo,
        variables: {
          request: JSON.stringify({}),
        },
      }),
      transformResponse: (response: ApiResponse) =>
        JSON.parse(response.data.getMyInfo) as Data,
    }),
  }),
});

export const { useGetUserInformationQuery } = apiSlice;
