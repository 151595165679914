import { Header, Table } from '@tanstack/react-table';
import React from 'react';
import { Container, TableContainer } from './KPITable.styles';
import Pagination from './Pagination';
import { KPILeaderboardState } from '../_types';
import CenteredProgress from '../../../components/CenteredProgress';
import CenteredComponent from '../../../components/CenteredComponent';
import { Typography } from '@mui/material';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

interface KPITableViewProps {
  table: Table<any>;
  totalPages: number;
  currentPage: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onSelectPage: (page: number) => void;
  onSort: (header: Header<any, any>) => void;
  kpiLeaderboardState: KPILeaderboardState;
  disabledNextPage: boolean;
  disabledPrevPage: boolean;
}

const KPITableView = ({
  table,
  totalPages,
  currentPage,
  onNextPage,
  onPreviousPage,
  onSelectPage,
  onSort,
  kpiLeaderboardState,
  disabledNextPage,
  disabledPrevPage,
}: KPITableViewProps) => {
  if (kpiLeaderboardState.loading) return <CenteredProgress />;

  if (kpiLeaderboardState.error) {
    return (
      <CenteredComponent>
        <Typography fontSize={24}>
          Something went wrong fetching the data. Please try again later.
        </Typography>
      </CenteredComponent>
    );
  }

  if (!kpiLeaderboardState.results.length) {
    return (
      <CenteredComponent>
        <Typography fontSize={24}>No available data.</Typography>
      </CenteredComponent>
    );
  }

  return (
    <>
      <Container>
        <TableContainer>
          <TableHeader table={table} onSort={onSort} />
          <TableBody table={table} />
        </TableContainer>
      </Container>
      <Pagination
        currentPage={currentPage}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        onSelectPage={onSelectPage}
        totalPages={totalPages}
        disabledNextPage={disabledNextPage}
        disabledPrevPage={disabledPrevPage}
      />
    </>
  );
};

export default KPITableView;
