export default function getSentenceInitials(sentence = '') {
  const words = sentence.split(' ');
  let initials = '';

  if (words.length > 0) {
    initials += words[0].charAt(0);

    if (words.length > 1) {
      initials += words[1].charAt(0);
    }
  }

  return initials?.toUpperCase();
}
