import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  MenuItem,
  Select,
  FormControl,
  AccordionDetails,
} from '@mui/material';
import { CustomTextField } from './styledComponents';
import './CRMConfigV2.css';
import { typeSelectOptions, doorFieldDataOptions } from '../utils/tempsUtils';

const FlexFieldEditor = ({
  formName,
  field,
  fieldIndex,
  handleFieldChange,
  deleteField,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDeleteClick = () => {
    if (confirmDelete) {
      deleteField(formName, fieldIndex);
    } else {
      setConfirmDelete(true);
      setTimeout(() => setConfirmDelete(false), 5000);
    }
  };

  return (
    <AccordionDetails style={{ padding: '10px 20px' }}>
      <Box mb={2}>
        <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
          Heading
        </Typography>
        <CustomTextField
          value={field.heading}
          onChange={(e) =>
            handleFieldChange(formName, fieldIndex, 'heading', e.target.value)
          }
          fullWidth
          margin="normal"
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box width="48%">
          <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
            Field Name
          </Typography>
          <CustomTextField
            value={field.name}
            onChange={(e) =>
              handleFieldChange(formName, fieldIndex, 'name', e.target.value)
            }
            fullWidth
            margin="normal"
          />
        </Box>
        <Box width="50%">
          <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
            Label
          </Typography>
          <CustomTextField
            value={field.label}
            onChange={(e) =>
              handleFieldChange(formName, fieldIndex, 'label', e.target.value)
            }
            fullWidth
            margin="normal"
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box
          width={
            ['String', 'Notes', 'Number', 'Money'].includes(field.type)
              ? '48%'
              : '100%'
          }
        >
          <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
            Type
          </Typography>
          <FormControl fullWidth margin="normal">
            <Select
              className="square-select"
              value={field.type}
              onChange={(e) =>
                handleFieldChange(formName, fieldIndex, 'type', e.target.value)
              }
            >
              {typeSelectOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderTop: 'none',
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = 'lightgray')
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = 'white')
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {['String', 'Notes'].includes(field.type) && (
          <>
            <Box width="24%">
              <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
                Min Length
              </Typography>
              <CustomTextField
                type="number"
                value={field.minLength}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'minLength',
                    e.target.value
                  )
                }
                fullWidth
                margin="normal"
              />
            </Box>
            <Box width="24%">
              <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
                Max Length
              </Typography>
              <CustomTextField
                type="number"
                value={field.maxLength}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'maxLength',
                    e.target.value
                  )
                }
                fullWidth
                margin="normal"
              />
            </Box>
          </>
        )}
        {['Number', 'Money'].includes(field.type) && (
          <>
            <Box width="24%">
              <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
                Min Value
              </Typography>
              <CustomTextField
                type="number"
                value={field.minValue}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'minValue',
                    e.target.value
                  )
                }
                fullWidth
                margin="normal"
              />
            </Box>
            <Box width="24%">
              <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
                Max Value
              </Typography>
              <CustomTextField
                type="number"
                value={field.maxValue}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'maxValue',
                    e.target.value
                  )
                }
                fullWidth
                margin="normal"
              />
            </Box>
          </>
        )}
      </Box>
      {field.type === 'List' && (
        <Box mb={2}>
          <Typography style={{ fontSize: 'medium', fontWeight: 500 }}>
            List Items
          </Typography>
          {field.values.map((listItem, listIndex) => (
            <Box key={listIndex} display="flex" alignItems="center">
              <CustomTextField
                value={listItem.label}
                onChange={(e) => {
                  const updatedValues = [...field.values];
                  updatedValues[listIndex].label = e.target.value;
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'values',
                    updatedValues
                  );
                }}
                fullWidth
                margin="normal"
              />
              <CustomTextField
                value={listItem.id}
                onChange={(e) => {
                  const updatedValues = [...field.values];
                  updatedValues[listIndex].id = e.target.value;
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'values',
                    updatedValues
                  );
                }}
                fullWidth
                margin="normal"
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  const updatedValues = [...field.values];
                  updatedValues.splice(listIndex, 1);
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'values',
                    updatedValues
                  );
                }}
              >
                Delete
              </Button>
            </Box>
          ))}
          <Button
            variant="contained"
            onClick={() => {
              const updatedValues = [...field.values, { label: '', id: '' }];
              handleFieldChange(formName, fieldIndex, 'values', updatedValues);
            }}
          >
            Add List Item
          </Button>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            control={
              <Switch
                checked={field.required}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'required',
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            label="Required"
          />
          <FormControlLabel
            control={
              <Switch
                checked={field.hideLabel}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'hideLabel',
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            label="Hide Label on Form"
          />
          <FormControlLabel
            control={
              <Switch
                checked={field.displayField}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'displayField',
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            label="Display Field"
          />
          <FormControlLabel
            control={
              <Switch
                checked={field.pickerDisplayField}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'pickerDisplayField',
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            label="Display in Dropdown List"
          />
        </Box>
        <Box width="50%">
          <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
            Door Field Data
          </Typography>
          <FormControl fullWidth margin="normal">
            <Select
              className="square-select"
              value={field.pinMappingField}
              onChange={(e) =>
                handleFieldChange(
                  formName,
                  fieldIndex,
                  'pinMappingField',
                  e.target.value
                )
              }
            >
              {doorFieldDataOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid lightgray',
                    borderTop: 'none',
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = 'lightgray')
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = 'white')
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color={confirmDelete ? 'error' : 'secondary'}
          onClick={handleDeleteClick}
          style={{ marginLeft: 'auto' }}
        >
          {confirmDelete ? 'Confirm Delete' : 'Delete Field'}
        </Button>
      </Box>
    </AccordionDetails>
  );
};

export default FlexFieldEditor;
