import React from 'react';
import NewGameState from '../../_types/NewGameState';
import GameImageView from './GameImage.view';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const GameImageContainer: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
}) => {
  return (
    <GameImageView
      newGameState={newGameState}
      onChangeNewGameState={onChangeNewGameState}
    />
  );
};

export default GameImageContainer;
