import React from 'react';
import NewGameState from '../../_types/NewGameState';
import { RoundTab, RoundTabs, ViewContainer } from './Rounds.styles';
import {
  InputBodyContainer,
  InputTitleContainer,
  InputTitleText,
} from '../../NewGame.styles';
import Round from './Round';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
  setShouldDisableNextOrFinishButton: any;
}

const RoundsView: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
  setShouldDisableNextOrFinishButton,
}) => {
  if (newGameState.totalRounds <= 0) return null;
  return (
    <ViewContainer>
      <InputTitleContainer align="center" flexDirection="column">
        <InputTitleText>Rounds</InputTitleText>
        <div style={{ width: '100%', overflowX: 'auto', paddingBottom: 10 }}>
          {newGameState.totalRounds > 0 &&
          newGameState.bracket.totalCompetitors > 0 ? (
            <RoundTabs>
              {Array.from({ length: newGameState.totalRounds }).map(
                (_n, index) => {
                  return (
                    <RoundTab
                      key={index}
                      onClick={() => {
                        onChangeNewGameState({
                          ...newGameState,
                          currentRound: index + 1,
                        });
                      }}
                      isFirst={index === 0}
                      isLast={index === newGameState.totalRounds - 1}
                      isActive={newGameState.currentRound === index + 1}
                    >
                      {index + 1}
                    </RoundTab>
                  );
                }
              )}
            </RoundTabs>
          ) : null}
        </div>
      </InputTitleContainer>
      <InputBodyContainer>
        {Boolean(newGameState.totalRounds) &&
        newGameState.bracket.totalCompetitors > 0
          ? Array.from({ length: newGameState.totalRounds }).map((_, index) => {
              return (
                index + 1 === newGameState.currentRound && (
                  <Round
                    tabNo={index + 1}
                    newGameState={newGameState}
                    onChangeNewGameState={onChangeNewGameState}
                    setShouldDisableNextOrFinishButton={
                      setShouldDisableNextOrFinishButton
                    }
                  />
                )
              );
            })
          : null}
      </InputBodyContainer>
    </ViewContainer>
  );
};

export default RoundsView;
