import { API } from 'aws-amplify';
import {
  daysBetween,
  getLocalDateTime,
  secondsBetween,
} from '../../../common/Utilities';
import moment from 'moment';

const orgMemberQuery = `
  query MyQuery($userID: ID!, $orgID: ID!) {
    OrgMemberByUser(userID: $userID, orgID: { eq: $orgID }) {
      items {
        orgPoints
        lifetimeOrgPoints
      }
    }
  }
`;
const myQuery = `
  query MyQuery(
    $orgID: ID = "smarthaven"
    $today: String = "2024-05-07"
    $nextToken: String = null
    $startBefore: String
  ) {
    listRewardsByOrgName(
      orgID: $orgID
      filter: {
        isDeleted: { eq: false }
        launchDate: { le: $startBefore }
        validTo: { ge: $today }
      }
      nextToken: $nextToken
    ) {
      items {
        approverType
        cost
        costType
        createdAt
        createdBy
        description
        giftcardDisclaimer
        giftcardValue
        id
        imageName
        imageType
        isActive
        isDeleted
        lastUpdatedBy
        launchDate
        limitCount
        location
        maxGiftcardAmount
        minGiftcardAmount
        name
        nextExpireFeedBlast
        nextLaunchFeedBlast
        orgID
        ownerID
        ownerType
        permissions
        personLimit
        remainingCount
        rewardType
        tags
        tangoAccountID
        updatedAt
        utid
        validFrom
        validTo
        value
        valueType
      }
      nextToken
    }
  }
`;

const getRewards = async (args: {
  userID: string;
  orgID: string;
  startBefore: string;
  endAfter: string;
}) => {
  const { orgID, userID, endAfter, startBefore } = args;

  // console.log('getRewards START BEFORE: ', startBefore);
  // console.log('getRewards END AFTER: ', endAfter);

  const orgMemberQueryRes = await API.graphql({
    query: orgMemberQuery,
    variables: {
      userID,
      orgID,
      limit: 100000,
    },
  });
  const orgPoints =
    // @ts-ignore
    orgMemberQueryRes.data.OrgMemberByUser.items[0].orgPoints;
  const lifeTimeOrgPoints =
    // @ts-ignore
    orgMemberQueryRes.data.OrgMemberByUser.items[0].lifeTimeOrgPoints;

  const ourCircles = [];
  let ourRewards = [];
  // let _customRewardCount = 0;
  let nextToken = null;

  do {
    const listRewardsRes: any = await API.graphql({
      query: myQuery,
      variables: {
        // @ts-ignore
        orgID: global.me.orgID,
        startBefore,
        endAfter,
        nextToken,
      },
    });
    // @ts-ignore
    const rewards = listRewardsRes.data.listRewardsByOrgName.items;

    for (const reward of rewards) {
      if (!reward.isDeleted) {
        const now = getLocalDateTime();
        const validFrom = new Date(reward.validFrom);
        const gmtValidToDate = new Date(reward.validTo);
        const validTo = moment(gmtValidToDate)
          .add(1, 'day')
          .subtract(1, 'second');

        reward.remaingDaysAvailable = daysBetween(now, validTo);
        reward.daysBeforeAvailable = daysBetween(now, validFrom);
        reward.secondsBeforeStart = secondsBetween(now, validFrom);
        reward.remainingSecondsInMission = secondsBetween(now, validTo);

        reward.myCirclePoints = orgPoints;
        reward.purchaseCount = 0;
        reward.isVisible = true;
        if (reward.isVisible) {
          ourRewards[ourRewards.length] = reward;
        }
        // if (reward.utid !== '') {
        //   _customRewardCount += 1;
        // }
      }

      ourRewards = ourRewards.filter(
        (reward) => reward.remaingDaysAvailable > 0
      );

      ourCircles[0] = {
        name: '',
        circlePoints: orgPoints,
        id: '',
        isActive: true,
        lifetimeCirclePoints: lifeTimeOrgPoints,
        memberType: '',
        status: '',
        rewards: ourRewards,
      };
    }
    nextToken = listRewardsRes.data.listRewardsByOrgName.nextToken;
  } while (nextToken !== null);

  return ourCircles[0].rewards;
};

export default getRewards;
