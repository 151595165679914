import React, { useEffect, useState } from 'react';
import { Text } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import {
  Box,
  Button,
  ButtonGroup,
  Backdrop,
  Slider,
  TextField,
} from '@mui/material';
import './kpis.css';
import { useParams } from 'react-router';

export default function FormulaWizard(props) {
  const mathOperators = ['*', '/', '+', '-'];
  const compOptions = [
    ['=', 'eq'],
    ['≠', 'ne'],
    ['>', 'gt'],
    ['<', 'lt'],
    ['>=', 'ge'],
    ['<=', 'le'],
    ['Stars With', 'startswith'],
    ['Contains', 'contains'],
    ['Or', 'or'],
    ['And', 'and'],
  ];
  const subOptions = [
    ['Percent', '@percent+p'],
    ['Round', '@round+p'],
    ['Sum', '@sum'],
    ['Replace', '@replace'],
    ['Field', '@field+f'],
    ['Rule', '@rule+r'],
    ['Previous', '@previous+f'],
    ['Member Of', '@memeberof+c'],
  ];
  const { orgID } = useParams();
  const { closer, setFormula } = props;
  const [circles, setCircles] = useState(null);
  const [addMenu, setAddMenu] = useState('');
  const [newFormula, setNewFormula] = useState('');
  const [precision, setPrecision] = useState(0);
  const [extraInput, setExtraInput] = useState('');
  const [inputFieldName, setInputFieldName] = useState('');
  const [shortSave, setShortSave] = useState(null);
  const [formulaObjs, setFormulaObjs] = useState([]);
  //   const [ogFurmual, setOgFormula] = useState(props.formula);

  async function fetchCircles() {
    const myQuery = gql`
      query MyQuery($orgID: ID!) {
        listCirclesByOrg(
          orgID: $orgID
          filter: { isDeleted: { eq: false } }
          limit: 10000
        ) {
          items {
            name
            id
          }
        }
      }
    `;

    const circleData = await API.graphql({
      query: myQuery,
      variables: { orgID },
    });
    const circles = circleData.data.listCirclesByOrg.items;
    setCircles(circles);
  }

  useEffect(() => {
    fetchCircles();
  }, []);

  useEffect(() => {
    const temp = formulaObjs.map((el) => el.textVal).join(' ');
    setNewFormula(temp);
    if (shortSave) setShortSave(null);
    if (extraInput.length) setExtraInput('');
    if (addMenu.length) setAddMenu('');
    if (inputFieldName.length) setInputFieldName('');
  }, [formulaObjs]);

  const addMathOperator = (e) => {
    const val = e.currentTarget.getAttribute('name');
    const temp = [...formulaObjs, { textVal: val, type: 'math' }];
    setFormulaObjs(() => temp);
    return true;
  };

  const addComp = (e) => {
    const val = e.currentTarget.getAttribute('name');
    const temp = [...formulaObjs, { textVal: val, type: 'comp' }];
    setFormulaObjs(() => temp);
  };

  const addSub = (e) => {
    const val = e.currentTarget.getAttribute('name');
    if (val.split('+')[0] === val) {
      const temp = [...formulaObjs, { textVal: val, type: 'sub' }];
      setFormulaObjs(() => temp);
      return true;
    }
    setExtraInput(val.split('+')[1]);
    return setShortSave(val);
  };

  const saveSub = (val) => {
    const textVal = `${shortSave.split('+')[0]}(${val})`;
    const temp = [...formulaObjs, { textVal, type: 'sub' }];
    setFormulaObjs(() => temp);
    return true;
  };
  const saveRule = (e) => {
    const [id, title] = e.currentTarget.getAttribute('name').split('+');
    const temp = [
      ...formulaObjs,
      { textVal: `@rule(${id})`, type: 'sub', title },
    ];
    setFormulaObjs(temp);
    return true;
  };

  const saveMemberOf = (e) => {
    const name = e.currentTarget.getAttribute('name').split('+')[1];
    const temp = [
      ...formulaObjs,
      { textVal: `@memberof(${name})`, type: 'sub' },
    ];
    setFormulaObjs(temp);
    return true;
  };

  const saveField = () => {
    const temp = [
      ...formulaObjs,
      { textVal: `${shortSave.split('+')[0]}(${inputFieldName})`, type: 'sub' },
    ];
    setFormulaObjs(temp);
    return true;
  };

  const saveAndExit = () => {
    setFormula((prev) => ({ ...prev, formula: newFormula }));
    setFormulaObjs([]);
    closer(false);
  };

  return (
    <Backdrop
      sx={{
        backgroundColor: 'rgba(0,0,0,0.6)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={props.open}
    >
      <Box id="formula-wizard-box">
        <Box>
          <Box className="backdrop-close-button" onClick={() => closer(false)}>
            X
          </Box>
          <Text style={{ fornSize: '1.5rem', fontWeight: 'Bold' }}>
            FORMULA WIZARD
          </Text>
          <Text>Add:</Text>
          <ButtonGroup variant="contained" aria-label="outlined button group">
            <Button onClick={() => setAddMenu(() => 'comp')}>Comparison</Button>
            <Button onClick={() => setAddMenu(() => 'sub')}>
              Substitutions
            </Button>
            <Button onClick={() => setAddMenu(() => 'math')}>
              Mathematical Operators
            </Button>
          </ButtonGroup>
          {addMenu === 'math' && (
            <Box style={{ marginTop: '10px' }}>
              <Text>Operators</Text>
              <Box className="input-options-box">
                {mathOperators.map((op, i) => (
                  <Box
                    className="op-button math-option"
                    key={i}
                    name={op}
                    onClick={(e) => addMathOperator(e)}
                  >
                    {op}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {addMenu === 'comp' && (
            <Box style={{ marginTop: '10px' }}>
              <Text>Comparison</Text>
              <Box className="input-options-box">
                {compOptions.map((op, i) => (
                  <Box
                    className="op-button comp-option"
                    key={i}
                    name={op[1]}
                    onClick={(e) => addComp(e)}
                  >
                    {op[0]}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {addMenu === 'sub' && (
            <Box style={{ marginTop: '10px' }}>
              <Text>Comparison</Text>
              <Box className="input-options-box">
                {subOptions.map((op, i) => (
                  <Box
                    className={
                      shortSave
                        ? shortSave === op[1]
                          ? 'op-button sub-option'
                          : 'op-button disabled-op'
                        : 'op-button sub-option'
                    }
                    key={i}
                    name={op[1]}
                    onClick={(e) => addSub(e)}
                  >
                    {op[0]}
                  </Box>
                ))}
              </Box>
              {extraInput === 'p' && (
                <Box
                  style={{
                    borderRadius: '15px',
                    border: '1px solid lightgray',
                    marginTop: '10px',
                    padding: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}
                >
                  <Text>{`Precision: ${precision}`}</Text>
                  <Slider
                    style={{ width: '50%' }}
                    size="small"
                    value={precision}
                    onChange={(e) => setPrecision(e.target.value)}
                    min={0}
                    max={2}
                    step={1}
                    marks
                  />
                  <Button variant="outlined" onClick={() => saveSub(precision)}>
                    Add
                  </Button>
                </Box>
              )}
              {extraInput === 'r' && (
                <Box className="formula-rules-box">
                  {props.rulesProp.map((el, i) => (
                    <Box
                      key={i}
                      className="op-button sub-option"
                      name={`${el.id}+${el.title}`}
                      onClick={saveRule}
                    >
                      {el.title}
                    </Box>
                  ))}
                </Box>
              )}
              {extraInput === 'c' && (
                <Box className="formula-rules-box">
                  {circles.map((el, i) => (
                    <Box
                      key={i}
                      className="op-button sub-option"
                      name={`${el.id}+${el.name}`}
                      onClick={saveMemberOf}
                    >
                      {el.name}
                    </Box>
                  ))}
                </Box>
              )}
              {extraInput === 'f' && (
                <Box className="formula-rules-box flex-center">
                  <Text>Type Field Name</Text>
                  <TextField
                    value={inputFieldName}
                    onChange={(e) => setInputFieldName(e.target.value)}
                    placeholder="Filed Name"
                    style={{ width: '100%' }}
                  />
                  <Button variant="outlined" onClick={saveField}>
                    Add
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box>
          <Text>Formula</Text>
          <TextField
            value={newFormula}
            style={{ width: '100%' }}
            onChange={(e) => setNewFormula(e.target.value)}
          />
          <Box>
            <Button
              variant="outlined"
              onClick={saveAndExit}
              style={{ margin: '10px 15px 0 0' }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                setFormulaObjs((prev) => prev.slice(0, prev.length - 1))
              }
              style={{ margin: '10px 15px 0 0' }}
            >
              Undo
            </Button>
            <Button
              variant="outlined"
              onClick={() => setFormulaObjs([])}
              style={{ margin: '10px 15px 0 0' }}
            >
              Restart
            </Button>
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
}
