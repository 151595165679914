import { API } from 'aws-amplify';
import GET_BRACKET_ACTUALS from '../queries/GET_BRACKET_ACTUALS';
import BracketActuals from '../_types/BracketActuals';

type GetBracketActualsApiResponse = {
  data: {
    getBracketActuals: string;
  };
};

const getBracketActuals = async ({ missionID }: { missionID: string }) => {
  const results = (await API.graphql({
    query: GET_BRACKET_ACTUALS,
    variables: {
      missionID,
    },
  })) as GetBracketActualsApiResponse;

  const bracketActuals = JSON.parse(
    results.data.getBracketActuals
  ) as BracketActuals;

  return bracketActuals;
};

export default getBracketActuals;
