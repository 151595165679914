import styled from '@emotion/styled';

export const ViewContainer = styled.div`
  padding: 94px 100px 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f1f1; /* Your desired color */
  min-height: 100vh;
`;

export const FeedMessage = styled.div`
  font-size: 20px;
  font-family: Space Grotesk;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
`;
