import React, { useEffect, useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import Contestant from '../../../_types/Contestant';
import NewGameState from '../../_types/NewGameState';

const CustomSeed = ({
  onClickAdd,
  contestants,
  newGameState,
  seedIndex,
}: {
  onClickAdd: (args: { seedIndex: number }) => void;
  contestants: Contestant[];
  newGameState: NewGameState;
  seedIndex: number;
}) => {
  const [contestantData, setContestantData] = useState<Contestant | null>(null);

  useEffect(() => {
    (async () => {
      const contestantID = newGameState.competitors[seedIndex];

      if (contestantID) {
        const data = contestants.filter(
          (contestant) => contestant.value === contestantID
        )[0];

        setContestantData(data);
      } else {
        setContestantData(null);
      }
    })();
  }, [seedIndex]);

  return (
    <Box>
      <Box
        onClick={() => {
          onClickAdd({ seedIndex });
        }}
        style={{
          color: '#000',
          backgroundColor: '#fff',
          //   borderBottom: '2px solid #f0f0f3',
          fontWeight: 600,
          cursor: 'pointer',
        }}
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {contestantData ? (
            contestantData?.avatar ? (
              <Avatar
                src={contestantData.avatar}
                sx={{ height: '36px', width: '36px', marginRight: '12px' }}
              />
            ) : (
              <Avatar
                sx={{
                  height: '36px',
                  width: '36px',
                  backgroundColor: '#868686',
                  marginRight: '12px',
                }}
              >
                {contestantData.label[0].toUpperCase()}
              </Avatar>
            )
          ) : (
            <Avatar
              src={'/images/add-contestant.svg'}
              sx={{ height: '36px', width: '36px', marginRight: '12px' }}
            ></Avatar>
          )}
          <Typography style={{ fontWeight: 500 }}>
            {contestantData ? contestantData.label : 'Add'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface Props {
  totalNumberOfContestants: any;
  onClickAdd: (args: { seedIndex: number }) => void;
  contestants: Contestant[];
  newGameState: NewGameState;
}

const SelectBracketContestants: React.FC<Props> = ({
  totalNumberOfContestants,
  onClickAdd,
  contestants,
  newGameState,
}) => {
  // Need an empty array of num contestants length to map out inputs
  const [numContestantsLengthArr, setNumContestantsLengthArr] = useState(
    Array.from({ length: totalNumberOfContestants }, () => ({}))
  );

  useEffect(() => {
    setNumContestantsLengthArr(
      Array.from({ length: totalNumberOfContestants }, () => ({}))
    );
  }, [totalNumberOfContestants]);

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          borderBottom: '2px solid #f0f0f3',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '10%',
            padding: 8,
            borderRight: '2px solid #f0f0f3',
          }}
        >
          <Typography>Seed #</Typography>
        </Box>
        <Box style={{ width: '90%', padding: 8 }}>
          <Typography>Contestant</Typography>
        </Box>
      </Box>
      {numContestantsLengthArr.map((contestant, i) => (
        <Box
          key={i}
          style={{
            display: 'flex',
            width: '100%',
            borderBottom: '2px solid #f0f0f3',
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '10%',
              padding: 8,
              borderRight: '2px solid #f0f0f3',
            }}
          >
            <Typography>{i + 1}</Typography>
          </Box>
          <Box style={{ width: '90%', padding: 8 }}>
            <CustomSeed
              key={i}
              onClickAdd={onClickAdd}
              contestants={contestants}
              newGameState={newGameState}
              seedIndex={i}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SelectBracketContestants;
